import { Box, CircularProgress, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { boxStyle } from '../../constants/types'
import accommodationService from '../../services/accommodationService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'

export const cancellationModalText = (text) =>
	t(`accommodation.rental_unit.show.cancellationModal.${text}`)

export default function CancellationModal({
	showModal,
	data,
	rentalUnitId,
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [days, setDays] = useState(1)
	const navigate = useNavigate()

	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		if (showModal) {
			if (data?.cancellation_days != null) {
				setDays(data?.cancellation_days)
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (!days) {
			return
		}

		const details = {
			type: 'cancellation_days',
			cancellation_days: days,
		}
		setLoading(true)
		accommodationService
			.UpdateReantalUnitPoliciesAndRules(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('UpdateReantalUnitPoliciesAndRules', {
					action_category: 'PoliciesAndRules',
					action_outcome: 'success',
					interaction_element: 'PoliciesAndRules',
				})
				setLoading(false)
				NotificationManager.success(
					'Cancellation days has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('UpdateReantalUnitPoliciesAndRules', {
					action_category: 'PoliciesAndRules',
					action_outcome: 'fail',
					interaction_element: 'PoliciesAndRules',
				})
				setLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error(
					'An error occurred while updating cancellations days for accommodation module'
				)
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-col justify-between px-2 m-4 gap-4 relative">
						<p className="text-black1 text-20 font-semibold leading-[25px] py-2">
							{cancellationModalText('howManyDaysBeforeArrival')}
						</p>

						<p className="text-[#027A48] text-14 font-normal ">
							{cancellationModalText('recommended')}
						</p>
						<div className=" flex gap-3 w-fit rounded-full border-[#DDD] border-[1px] bg-white">
							<button
								className={`rounded-full py-[10px] px-[29px] ${
									days === 1 ? 'border-[1px] border-black1 bg-[#fcf5f3]' : ''
								}`}
								onClick={() => setDays(1)}
							>
								{cancellationModalText('oneDay')}
							</button>
							<button
								className={`rounded-full py-[10px] px-[29px] ${
									days === 5 ? 'border-[1px] border-black1 bg-[#fcf5f3]' : ''
								}`}
								onClick={() => setDays(5)}
							>
								{cancellationModalText('fiveDays')}
							</button>
							<button
								className={`rounded-full py-[10px] px-[29px] ${
									days === 14 ? 'border-[1px] border-black1 bg-[#fcf5f3]' : ''
								}`}
								onClick={() => setDays(14)}
							>
								{cancellationModalText('fourteenDays')}
							</button>
							<button
								className={`rounded-full py-[10px] px-[29px] ${
									days === 30 ? 'border-[1px] border-black1 bg-[#fcf5f3]' : ''
								}`}
								onClick={() => setDays(30)}
							>
								{cancellationModalText('thirtyDays')}
							</button>
						</div>
						<p className="text-[#707070] text-14 font-normal ">
							{cancellationModalText('guestsLoveFlexibility')}
						</p>

						<div className="flex gap-3">
							<button
								className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center py-[10px] text-cyan1"
								onClick={onClose}
							>
								{cancellationModalText('cancel')}
							</button>
							<button
								className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center py-[10px] text-white"
								onClick={onSubmit}
							>
								{loading ? (
									<CircularProgress size={16} />
								) : (
									cancellationModalText('save')
								)}
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
