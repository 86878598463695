import { Box, Paper } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageViewer from "react-simple-image-viewer";
import AvatarImage from '../../assets/images/messages/avatar_photo.png';
import CustomLoader from '../../components/loader';
import { MSG_TYPE_TEXT } from '../../constants';
import ChatHeader from './chat-header';
import ChatFooter from './footer/chat-footer';

const ChatSection = ({
	channel,
	message,
	handleSendMessage,
	conversation,
	loading = false,
}) => {
	const endOfMessagesRef = useRef(null)
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState([]);

	const openImageViewer = useCallback((imgs) => {
		setImages(imgs);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setImages([]);
		setIsViewerOpen(false);
	};

	const scrollToBottom = () => {
		endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
	}

	useEffect(() => {
		scrollToBottom()
	}, [conversation, channel])

	return (
		<Paper
			className="chat-section"
			elevation={3}
			sx={{
				padding: 2,
				height: '80vh',
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'auto',
				backgroundColor: 'white',
			}}
		>
			{loading ? (
				<CustomLoader />
			) : (
				<>
					<ChatHeader channel={channel} />

					<Box
						flexGrow={1}
						display="flex"
						flexDirection="column"
						overflow="auto"
						sx={{ marginTop: 2, marginBottom: 4 }}
					>
						{conversation.map((msg) => (
							<div key={msg.id} className="flex flex-col">
								<Box
									display="flex"
									flexDirection={msg.sentByMe ? 'row-reverse' : 'row'}
									alignItems="flex-start"
								>
									{!msg.sentByMe && (
										<Box
											sx={{
												width: 40,
												height: 40,
												marginRight: !msg.sentByMe ? 0 : 1,
												alignSelf: 'flex-end',
											}}
										>
											<img
												src={AvatarImage}
												alt="Avatar"
												style={{ borderRadius: '50%' }}
											/>
										</Box>
									)}
									{
										msg.type == MSG_TYPE_TEXT ?
											<Box
												sx={{
													bgcolor: msg.sentByMe ? '#B87BEB' : '#F5F5F5',
													color: msg.sentByMe ? '#FFFFFF' : '#000000',
													padding: 2,
													marginRight: !msg.sentByMe ? 0 : 1,
													marginLeft: !msg.sentByMe ? 1 : 0,
													borderRadius: 2,
													maxWidth: '75%',
													wordWrap: 'break-word',
													position: 'relative',
													mt: 2,
												}}
											>
												<span>{msg.content ?? msg?.text}</span>
											</Box>
											:
											<Box
												sx={{
													bgcolor: msg.sentByMe ? '#B87BEB' : '#F5F5F5',
													color: msg.sentByMe ? '#FFFFFF' : '#000000',
													padding: '8px',
													marginRight: !msg.sentByMe ? 0 : 1,
													marginLeft: !msg.sentByMe ? 1 : 0,
													borderRadius: '12px',
													maxWidth: '75%',
													wordWrap: 'break-word',
													position: 'relative',
													mt: 2,
												}}
											>
												<img
													//src={msg.content}
													src={msg.content}
													style={{ borderRadius: '12px', width: '100%', height: '120px', objectFit: 'cover' }}
													onClick={() => openImageViewer([msg.content])}
												/>
											</Box>
									}
								</Box>
								{msg.created_at && (
									<Box
										component="span"
										sx={{
											display: 'block',
											fontSize: '0.75rem',
											marginTop: 0.5,
											textAlign: msg.sentByMe ? 'right' : 'left',
											paddingLeft: !msg.sentByMe ? 6 : 0,
											marginBottom: '4px!important',
											marginRight: !msg.sentByMe ? 0 : 1,
											marginLeft: !msg.sentByMe ? 1 : 0,
										}}
									>
										{new Date(msg.created_at).toLocaleTimeString()}
									</Box>
								)}
							</div>
						))}
						<div ref={endOfMessagesRef} />
					</Box>
					{isViewerOpen && (
						<ImageViewer
							src={images}
							currentIndex={0}
							onClose={closeImageViewer}
							disableScroll={false}
							backgroundStyle={{
								backgroundColor: "rgba(0,0,0,0.9)"
							}}
							closeOnClickOutside={true}
						/>
					)}
					<ChatFooter
						onSendMsg={handleSendMessage}
						message={message}
						loading={loading}
					/>
				</>
			)}
		</Paper>
	)
}

export default ChatSection
