import { Card, CardContent } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { t } from 'i18next'
import React from 'react'

export const payrollsText = (text) => t(`employees.profile.PayrollsBox.${text}`)

export const Payrolls = ({ data, employees }) => {
	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-xl font-medium">
							{payrollsText('payrollGenerated')}
						</span>
					</div>
					<TableContainer component={Paper} className="mt-4">
						<Table aria-label="simple table">
							<TableHead className="bg-gray7">
								<TableRow>
									<TableCell
										className="!text-gray5 !text-left !font-medium !text-base"
										align="left"
									>
										{payrollsText('salary')}
									</TableCell>
									<TableCell
										className="!text-gray5 !text-left !font-medium !text-base"
										align="left"
									>
										{payrollsText('bonus')}
									</TableCell>
									<TableCell
										className="!text-gray5 !text-left !font-medium !text-base"
										align="right"
									>
										{payrollsText('deductions')}
									</TableCell>
									<TableCell
										className="!text-gray5 !text-left !font-medium !text-base"
										align="right"
									>
										{payrollsText('taxes')}
									</TableCell>
									<TableCell
										className="!text-gray5 !text-left !font-medium !text-base"
										align="right"
									>
										{payrollsText('netPay')}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((item, index) => (
									<TableRow key={index}>
										<TableCell
											align="left"
											className="py-3 !text-gray5 !text-left !text-base"
										>
											${item.salary}
										</TableCell>
										<TableCell
											align="left"
											className="py-3 !text-gray5 !text-left !text-base"
										>
											${item.bonus}
										</TableCell>
										<TableCell
											align="left"
											className="py-3 !text-gray5 !text-left !text-base"
										>
											{item.deduction ? `$${item.deduction}` : '-'}
										</TableCell>
										<TableCell
											align="left"
											className="py-3 !text-gray5 !text-left !text-base"
										>
											${item.taxes}
										</TableCell>
										<TableCell
											align="left"
											className="py-3 !text-gray5 !text-left !text-base"
										>
											${item.net_pay}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</div>
	)
}
