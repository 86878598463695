import { Box, CircularProgress, MenuItem, TextField } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import * as yup from 'yup'
import saleIcon from '../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../components/common/IconButton'
import { RInputDeclare } from '../../components/custom/RInputDeclare'
import { boxStyle } from '../../constants/types'
import EcommPlatformService from '../../services/ecommPlatformService'
import { isEmpty } from '../../utils/common'
import { PlatformTypes } from './common'


export const ecommModalText = (text) =>
	t(`ecommerce_platforms.new.${text}`)

export default function EcommPlatformModal({
	showModal,
	data,
	onClose,
}) {
	const [visible, setVisible] = useState(showModal)
	const [loading, setLoading] = useState(false);

	const validator = yup.object().shape({
		name: yup
			.string()
			.trim()
			.required('Name is required')
			.max(72, 'Max exceeded')
			.min(2, 'Min not met'),
		url: yup
			.string()
			.trim()
			.required('Url is required')
			.max(250, 'Max exceeded')
			.min(6, 'Min not met'),
		type: yup.string().required('Type is required'),
	})

	const formikProps = useFormik({
		initialValues: {
			name: '',
			url: '',
			type: '',
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})


	useEffect(() => {
		setVisible(showModal)

		if (data) {
			formikProps.setFieldValue('name', data?.name);
			formikProps.setFieldValue('url', data?.url);
			formikProps.setFieldValue('type', data?.type);
		}

		// eslint-disable-next-line
	}, [showModal, data])

	const onSubmit = (values) => {
		let storeData = {
			id: data?.id,
			...values
		}

		let promise = null
		if (data?.id == null) {
			promise = EcommPlatformService.Create(storeData)
		} else {
			promise = EcommPlatformService.Update(storeData)
		}

		setLoading(true);
		promise
			.then(() => {
				setLoading(false);
				if (data?.id == null) {
					NotificationManager.success(
						'Ecommerce platform created successfully',
						'Success',
						3000
					)
				} else {
					NotificationManager.success(
						'Ecommerce platform updated successfully',
						'Success',
						3000
					)
				}

				onClose(true)
			})
			.catch((err) => {
				setLoading(false);
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
				throw new Error('Problem on creating an ecommerce platform')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<CustomIconButton
						title={
							data?.id
								? ecommModalText('edit')
								: ecommModalText('title')
						}
						icon={saleIcon}
					/>
					<div className="flex flex-col px-024 pb-024 pt-2 gap-3 overflow-auto max-h-[60vh]">
						<div className="flex flex-col">
							<RInputDeclare
								className="!mb-2 !mt-0 text-base !text-black1 font-medium"
								value={ecommModalText('name')}
							/>
							<TextField
								error={formikProps.errors.name} 
								value={formikProps.values.name}
								size="small"
								onChange={(e) => formikProps.setFieldValue(
									'name',
									e.target.value
								)}
								placeholder={ecommModalText('enter_name')}
							/>
							{!isEmpty(formikProps.errors.name) && (
								<div className="text-red-600 text-12 mt-2">
									{formikProps.errors.name}
								</div>
							)}
						</div>
						<div className="flex flex-col">
							<RInputDeclare
								value={ecommModalText('url')}
								className="!mt-2 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								required
								size="small"
								placeholder={ecommModalText('url')}
								value={formikProps.values.url}
								error={formikProps.errors.url}
								onChange={(e) => {
									formikProps.setFieldValue(
										'url',
										e.target.value
									)
								}}
							/>
							{!isEmpty(formikProps.errors.url) && (
								<div className="text-red-600 text-12 mt-2">
									{formikProps.errors.url}
								</div>
							)}
						</div>
						<div className="flex flex-col">
							<RInputDeclare
								value={ecommModalText('type')}
								className="!mt-2 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								id="outlined-select"
								error={isEmpty(formikProps.errors.type)}
								select
								size="small"
								placeholder={ecommModalText('type')}
								value={formikProps.values.type}
								onChange={(e) => {
									formikProps.setFieldValue('type', e.target.value)
								}}
							>
								{PlatformTypes.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							{!isEmpty(formikProps.errors.type) && (
								<div className="text-red-600 text-12 mt-2">
									{formikProps.errors.type}
								</div>
							)}
						</div>
					</div>

					<div className="flex justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{ecommModalText('cancel')}
						</button>
						<button
							type="submit"
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => formikProps.handleSubmit()}
						>
							{loading ? <CircularProgress size={16} /> : ecommModalText('save')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
