export const heatmapData = [
   [
      0.8670516179414545, 0.7448932722990802, 0.02229695345745819, 0.7179156890126106, 0.9705117555201601, 0.8857599112582863, 0.11977565479866858
   ],
   [
      0.5275255258692455, 0.5128434240346749, 0.8994374135579091, 0.1417239016155143, 0.018587920254161006, 0.34110480167258306, 0.5304571186946494
   ],
   [
      0.9244672881092293, 0.3162946902529782, 0.03220280466391312, 0.46891321279030573, 0.8589099253685963, 0.5042458935588781, 0.07544199081849601
   ],
   [
      0.056202945746530064, 0.8182718310010739, 0.6229305567123655, 0.8331481941481216, 0.9279306716010454, 0.9394033889863045, 0.7467515034867727
   ]
];

export const peopleCounter = {
   realTime: 25,
   currentOccupancy: 22,
   trends: [
      { name: 'Aug', value: 10 },
      { name: 'Sep', value: 15 },
      { name: 'Oct', value: 20 },
      { name: 'Nov', value: 18 },
      { name: 'Dec', value: 22 },
      { name: 'Jan', value: 12 },
      { name: 'Feb', value: 15 },
      { name: 'Mar', value: 25 },
      { name: 'Apr', value: 18 },
   ],
}

export const demographicTrends = [
   { month: 'Aug', today: 6, yesterday: 6 },
   { month: 'Sep', today: 22, yesterday: 35 },
   { month: 'Oct', today: 14, yesterday: 25 },
   { month: 'Nov', today: 25, yesterday: 16 },
   { month: 'Dec', today: 7, yesterday: 7 },
   { month: 'Jan', today: 5, yesterday: 5 },
   { month: 'Feb', today: 10, yesterday: 16 },
   { month: 'Mar', today: 15, yesterday: 15 },
   { month: 'Apr', today: 20, yesterday: 35 },
];

export const ageDistributionData = [
   { name: '0-14', value: 25, past: 6 },
   { name: '15-24', value: 15, past: 13 },
   { name: '25-34', value: 20, past: 0 },
   { name: '35-64', value: 30, past: -2 },
   { name: '65+', value: 10, past: -6 },
];

export const ageDistributionTableCol = [{
   label: '',
   field: 'name',
   align: 'left'
}, {
   label: 'This month',
   field: 'value',
   align: 'left',
   cellComponent: ({ data }) => {
      return <span className="w-full text-center">{data}</span>
   },
},
{
   label: 'Past month',
   field: 'past',
   align: 'left',
   cellComponent: ({ data }) => {
      let displayValue;
      let color;

      if (data > 0) {
         color = "#16A249";
         displayValue = data;
      } else if (data < 0) {
         color = "#DC2828";
         displayValue = data;
      } else {
         color = "inherit";
         displayValue = "-";
      }

      return (
         <span className="w-full text-end" style={{ color }}>
            {displayValue}
         </span>
      );
   }

},];

export const footPathTraffic = [
   { month: 'Aug', value: 10 },
   { month: 'Sep', value: 15 },
   { month: 'Oct', value: 20 },
   { month: 'Nov', value: 18 },
   { month: 'Dec', value: 22 },
   { month: 'Jan', value: 16 },
   { month: 'Feb', value: 14 },
   { month: 'Mar', value: 21 },
   { month: 'Apr', value: 25 },
]