import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck as iconCheck, faClockRotateLeft as iconClock } from "@fortawesome/free-solid-svg-icons";

import Svg_chevron_right from '../../../assets/svgs/chevron-right.svg';
import v2GuestStandard from "../../../assets/svgs/v2-guest-standard.svg";

export default function RealTimeUpdateBlock() {
    const navigate = useNavigate();
    const { short_code } = useSelector(state => state.app);

    const onAction = () => {
        navigate(`/${short_code}/admin/rental/housekeeping/real-time-status-updates`);
    };

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row items-center p-0 mb-024'>
                <label className='font-medium text-lg text-black1'>Real-time status updates</label>
            </div>
            <div className='flex flex-col items-start p-0 w-full h-full bg-white rounded-12'>
                <div className='flex flex-col items-start flex-1 w-full shadow-lg_2 bg-white rounded-12 px-024 pt-024 pb-016'>
                    <div className='flex flex-col justify-center items-start p-0'>
                        <img src={v2GuestStandard} className='pr-012 w-14 h-14 mb-2' />
                        <div className='flex flex-row items-center p-0 h-6 mt-2'>
                            <FontAwesomeIcon icon={iconCheck} color='#73CB50' size='lg' className='pr-012' />
                            <label className='text-sm pl-08 text-green1'>All updates clear</label>
                            {/* <img className='pr-012' src={Svg_cancel} alt="" />
                            <label className='text-14 pl-08 text-red1'>Disconnected</label> */}
                        </div>
                        <label className='font-medium text-base mt-2'>
                            Notifications about&nbsp;
                            <span className='font-bold'>Booking-status</span>
                        </label>
                    </div>
                </div>
                <div
                    className='flex flex-row justify-between px-024 py-016 w-full h-[52px] cursor-pointer'
                    onClick={() => onAction()}
                >
                    <label className='text-black1 font-medium text-base'>View detail</label>
                    <img src={Svg_chevron_right} alt='' />
                </div>
            </div>
        </div>
    )
}
