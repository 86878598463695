import { CircularProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/discount_white_small.svg'
import { smallBoxStyle } from '../../constants/types'
import ReservationService from '../../services/reservationService'
import { RInputDeclare } from '../custom/RInputDeclare'

const applyPromoModalText = (text) =>
	t(`components.reservation.ApplyPromoModal.${text}`)

export default function ApplyPromoModal({
	showModal,
	onConfirm,
	onClose,
	data,
	guestId,
	reservationId,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [promotion, setPromotion] = useState('')
	const [loading, setLoading] = useState(false)
	const [promoData, setPromoData] = useState([])

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setPromotion('')
		}
		setVisible(showModal)
		if (guestId) {
			ReservationService.GetGuestValidPromotion(guestId)
				.then((res) => {
					let promo = res.promotions?.filter(
						(item) => item.discounts.length > 0
					)
					setPromoData(promo)
				})
				.catch((err) => {
					NotificationManager.error(err?.message ?? 'Something went wrong!')
				})
		}
	}, [showModal])

	const onSubmit = () => {
		if (promotion) {
			let payload = {
				promotion_id: promotion,
			}
			setLoading(true)
			ReservationService.ApplyPromo(reservationId, payload)
				.then(({ data }) => {
					setLoading(false)
					onConfirm()
					NotificationManager.success(
						data?.message ?? 'Apply Promo saved successfully'
					)
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(err?.error ?? 'Something went wrong!')
				})
		} else {
			setCheckValid(true)
		}
	}
	const onCloseModal = () => {
		onClose(false)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={smallBoxStyle} className="outline-none">
					<div
						className="flex flex-row items-center px-024 pt-024 pb-0 gap-3"
						style={{ marginBottom: '-20px' }}
					>
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{applyPromoModalText('applyPromo')}
						</label>
					</div>
					<div className="flex flex-col p-024">
						<RInputDeclare
							value={applyPromoModalText('availablePromotions')}
							className="!mt-4 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							size="small"
							error={checkValid && !promotion}
							select
							value={promotion}
							onChange={(event) => {
								setPromotion(event.target.value)
							}}
						>
							{promoData.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{option.title}
								</MenuItem>
							))}
						</TextField>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCloseModal}
						>
							{applyPromoModalText('cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								applyPromoModalText('save')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
