import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deletFromCart, getFoodCart } from '../utils/storage'
import AllCarts from '../views/orders/create-order/all-carts'
import { getCurrencySymbol } from '../views/orders/create-order/currency'

const SidebarPOSFood = (props) => {
	let navigate = useNavigate()

	const { updateCart, setUpdateCart } = props
	const { short_code } = useSelector((state) => state.app)

	const [flag, setFlag] = useState(false)
	const [cart, setCart] = useState([])

	let totalCost = 0

	cart?.forEach((item) => {
		totalCost += item.quantity * item.price
	})

	// Iterate through the array and calculate the total cost

	useEffect(() => {
		setCart(getFoodCart())
		setUpdateCart(false)

		// eslint-disable-next-line
	}, [updateCart])

	const deleteFromCart = (id) => {
		deletFromCart(id)
		setUpdateCart(true)
	}

	const redirectToPay = () => {
		navigate(`/${short_code}/admin/pos/food/pay`)
	}

	return (
		<div className="p-6 h-[100vh] bg-[#240b3b] relative min-w-[409px]">
			<div className="flex md:hidden cursor-pointer pb-4 border-b  border-[#393C49] items-center gap-2 ">
				<span className="text-white">
					<ChevronLeftOutlinedIcon />
				</span>
				<span
					className={`text-[18px] md:text-[20px] w-full text-center md:text-start text-white font-semibold `}
				>
					New Order #34562
				</span>
			</div>
			<p className="hidden md:block text-white text-20 font-semibold leading-[140%]">
				New Order #34562
			</p>
			<div className="flex border-b-[1px] border-[#393C49] gap-2 py-3">
				<button className="bg-white rounded-[5px] py-[7px] px-[12px] text-[#240b3b] text-14 font-semibold leading-[140%] w-fit">
					Dine in
				</button>
				<button className="text-white border-white border-[1px]  rounded-[5px] py-[7px] px-[12px] bg-[#240b3b] text-14 w-fit font-semibold leading-[140%]">
					To Go
				</button>
			</div>

			<div>
				<div
					className={`border-[#393C49]  py-3 overflow-auto  ${
						flag ? 'h-[61vh]' : 'h-[75vh]'
					} `}
				>
					<AllCarts
						cart={cart}
						deleteFromCart={deleteFromCart}
						totalCost={totalCost}
					/>
				</div>
			</div>

			<div className="w-full absolute bottom-6 px-6 right-0">
				<button
					onClick={() => {
						!flag ? setFlag(!flag) : redirectToPay()
					}}
					className={`${
						flag ? 'bg-[#027A48] text-white' : 'bg-white text-[#1F1D2B]'
					} w-full flex-1  rounded-[8px] p-[14px]  text-center     text-14 font-semibold leading-[140%] `}
				>
					Charge {getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
					{totalCost}
				</button>
			</div>
		</div>
	)
}

export default SidebarPOSFood
