import React from "react";
import CancellationNoShowTrends from "./cancellation-and-no-show-trends";
import MenuItemPerformanceReport from "./menu-item";
import PeakHoursAnalysis from "./peak-hours-analysis";
import ReservationConversionReport from "./reservation-conversion-report";
import ReservationLeadTimeAnalysis from "./reservation-lead-time";
import RevenueBySeatingAreaReport from "./revenue-by-seating-report";
import StaffEfficiencyReport from "./staff-efficiency-report";
import TableTurnoverReport from "./table-turnover-report";

export default function AdvancedReport() {

	return (
		<div className="flex flex-col h-full overflow-y-auto p-6">
			<div className='grid md:grid-cols-2 grid-cols-1 flex-col gap-3 md:gap-6'>
				<ReservationConversionReport />
				<TableTurnoverReport />
				<PeakHoursAnalysis />
				<StaffEfficiencyReport />
				<CancellationNoShowTrends />
				<RevenueBySeatingAreaReport />
				<MenuItemPerformanceReport />
				<ReservationLeadTimeAnalysis />
			</div>
		</div>
	);
}
