import React from 'react'

import { t } from 'i18next'
import plusIcon from '../../assets/svgs/plus.svg'
import searchIcon from '../../assets/svgs/search.svg'
import './index.css'

const getTranslation = (text) => t(`components.table.TableEmptyData.${text}`)

const TableEmptyData = ({
	content = '',
	btnLabel = '',
	secondBtn,
	btnIcon = true,
	tableList = false,
	onAction = () => { },
	upIcon,
}) => {


	return (
		<div className="flex flex-col items-center text-center my-4 ">
			<div className="bg-gray7 !p-2 rounded-full">
				<div className="bg-black1 !p-2 rounded-full">
					{upIcon ? (
						<span>{upIcon}</span>
					) : (
						<img src={searchIcon} alt={getTranslation('searchAltText')} />
					)}
				</div>
			</div>
			<span className="no-value-found">
				{getTranslation('no')} {content} {getTranslation('found')}
			</span>
			<span className="text-[#6D6D6D] text-base mt-1">
				{tableList ? (
					<span>
						{getTranslation('currentlyNo')} {content}{' '}
						{getTranslation('availableBasedOnFilter')}
						<br />
						{getTranslation('pleaseAddNewOne')}
					</span>
				) : (
					<span>
						{getTranslation('currentlyNo')} {content}{' '}
						{getTranslation('noContentAvailableOrAdded')}
						<br />
						{getTranslation('pleaseAddNewOne')}
					</span>
				)}
			</span>
			<div className="flex gap-2 !items-center mt-6">
				{btnLabel && (
					<div
						className="bg-black1 flex items-center px-6 py-2 text-white rounded-lg cursor-pointer"
						onClick={onAction}
					>
						{btnIcon && (
							<img
								src={plusIcon}
								alt={getTranslation('plusAltText')}
								className="mr-2"
							/>
						)}
						<span>{btnLabel}</span>
					</div>
				)}
				{secondBtn && <span>{secondBtn}</span>}
			</div>
		</div>
	)
}

export default TableEmptyData
