import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import WaitlistService from '../../services/waitlistService'
import CustomIconButton from '../common/IconButton'

import { t } from 'i18next'
import calendarIcon from '../../assets/svgs/calendar_white.svg'
import { RInputDeclare } from '../custom/RInputDeclare'

const getTranslation = (text) => t(`components.waitlist.estimateModal.${text}`)

export default function EstimateModal({ showModal, index, onClose }) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [estimate, setEstimate] = useState(0)

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setEstimate(0)
		}
		setVisible(showModal)
	}, [showModal])

	const handleUpdate = () => {
		if (estimate) {
			let payload = {
				id: index,
				estimated_wait_time: estimate,
			}
			WaitlistService.UpdateWaitTime(payload)
				.then((res) => {
					NotificationManager.success('Success')
					onClose()
				})
				.catch((e) => {
					NotificationManager.error('Failed')
				})
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<CustomIconButton
						icon={calendarIcon}
						title={getTranslation('newEstimatedWaitTimeTitle')}
					/>
					<div className="flex flex-col px-024 mb-4">
						<div className="flex flex-row gap-2 mb-2">
							<RInputDeclare
								value={getTranslation('addNewEstimatedWaitTime')}
								className="text-base !text-black1 font-medium !mb-0"
							/>
							<RInputDeclare
								value={getTranslation('required')}
								className="text-base !mb-0"
							/>
						</div>
						<TextField
							id="outlined-select"
							error={checkValid && !estimate}
							type="number"
							size="small"
							value={estimate}
							onChange={(event) => {
								setEstimate(event.target.value)
							}}
						/>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={handleUpdate}
						>
							{getTranslation('continue')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
