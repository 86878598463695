import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomInput from '../../../../components/custom/CustomInput';

import { t } from 'i18next'
import Svg_check from "../../../../assets/svgs/check_round.svg";
import shopifyIcon from '../../../../assets/svgs/shopify.svg'

export const shopifyText = (text) =>
	t(`account.storeIntegrations.shopify.view.${text}`)

const Shopify = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        accessToken: '', apiKey: '', apiSecret: '',
    })
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (field, value) => {
        setFormData(d => ({ ...d, [field]: value }));
    }

    const handleSubmit = () => {
        setSubmitted(true);

        let validated = true;
        Object.keys(formData).forEach(key => {
            if (!formData[key] || formData[key].length < 5)
                validated = false;
        });

        if (validated) {
            console.log('UberEats form data: ', formData);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
			<div
				className="sub-container h-full grid grid-cols-1 md:grid-cols-2 gap-5"
				style={{ height: 'calc(100vh - 76px - 68px)' }}
			>
				<div className="bg-white px-8 py-6 flex flex-col gap-5">
					<div className="flex flex-row items-center gap-4">
						<img src={shopifyIcon} className="w-16 h-16" alt="" />
					</div>
					<label className="font-medium text-xl">
						{shopifyText('shopify_integration_description')}
					</label>
					<>
						<div className={'flex justify-start items-center gap-3 mb-1'}>
							<img src={Svg_check} className={'w-6 h-6 object-contain'} />
							<div className="color-dark text-16 font-normal">
								{shopifyText('synced_inventory_levels')}
							</div>
						</div>
						<div className={'flex justify-start items-center gap-3 mb-1'}>
							<img src={Svg_check} className={'w-6 h-6 object-contain'} />
							<div className="color-dark text-16 font-normal">
								{shopifyText('real_time_data_prevention')}
							</div>
						</div>
					</>
					<label className="font-medium text-l">
						{shopifyText('shopify_manage_products_inventory_orders')}
					</label>
				</div>
				<div className="flex flex-col bg-white px-10 py-6">
					<label className="text-xl font-medium">
						{shopifyText('enter_shopify_credentials')}
					</label>
					<CustomInput
						label={shopifyText('access_token')}
						required
						name="accessToken"
						classes="mt-6"
						error={
							submitted &&
							(!formData.accessToken || formData.accessToken.length < 4)
						}
						value={formData.customerId}
						onChange={handleChange}
					/>
					<CustomInput
						label={shopifyText('api_key')}
						required
						name="apiKey"
						classes="mt-6"
						error={
							submitted && (!formData.apiKey || formData.apiKey.length < 4)
						}
						value={formData.clientId}
						onChange={handleChange}
					/>
					<CustomInput
						label={shopifyText('api_secret')}
						required
						name="apiSecret"
						classes="mt-6"
						error={
							submitted &&
							(!formData.apiSecret || formData.apiSecret.length < 4)
						}
						value={formData.clientSecret}
						onChange={handleChange}
					/>
					<div className="flex flex-row justify-between h-[48px] mt-6 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={() => handleCancel()}
						>
							{shopifyText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => handleSubmit()}
						>
							{shopifyText('submit')}
						</button>
					</div>
				</div>
			</div>
		)
}

export default Shopify;
