import MuiClose from '@mui/icons-material/Close'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import icon from '../../assets/svgs/table_white.svg'
import TableService from '../../services/tableService'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import { RInputDeclare } from '../custom/RInputDeclare'
import GenericModal from '../generic-modal'

const getTranslation = (text) => t(`components.table.splitModal.${text}`)

export default function SplitModal({ showModal, data, onClose, refresh }) {
	// Table Detail
	const [tables, setTables] = useState([])
	const [checkTableAdd, setCheckTableAdd] = useState(false)

	const [number, setNumber] = useState('')
	const [seats, setSeats] = useState(0)

	useEffect(() => {
		if (showModal) {
			setNumber('')
			setSeats(0)
			setTables([])
		}
		// eslint-disable-next-line
	}, [showModal])

	const handleConfirm = () => {
		if (data && tables.length) {
			let payload = {
				table_id: parseInt(data[0]),
				new_table_numbers: tables.map((item) => item.number),
				new_table_seats: tables.map((item) => parseInt(item.seats)),
			}
			TableService.SplitTable(payload)
				.then((res) => {
					NotificationManager.success('Success')
					refresh()
				})
				.catch((e) => {
					NotificationManager.error('failed')
				})
			onClose()
		} else {
			NotificationManager.warning('Please add data')
		}
	}

	const handleNewTableDetail = () => {
		if (number && seats > 0) {
			let tableArray = JSON.parse(JSON.stringify(tables))
			tableArray.push({ number: number, seats: seats })
			setNumber('')
			setSeats(0)
			setTables(tableArray)
			setCheckTableAdd(false)
		} else {
			setCheckTableAdd(true)
		}
	}
	const handleDeleteTableDetail = (e, item) => {
		let result = tables.filter((table) => table.id !== item.id)
		setTables(result)
	}

	const children = (
		<div className="flex flex-col overflow-auto max-h-[70vh]">
			{tables.map((item) => {
				return (
					<div
						className="flex flex-col p-024 rounded-xl border-gray-400 mb-4"
						key={item.number}
					>
						<div className="grid grid-cols-5 gap-4 ">
							<div className="col-start-1 col-span-2 flex flex-col">
								<RInputDeclare
									value={getTranslation('tableNumber')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField disabled id="filled-required" value={item.number} />
							</div>
							<div className="col-start-3 col-span-2 flex flex-col">
								<RInputDeclare
									value={getTranslation('seats')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField disabled id="filled-required" value={item.seats} />
							</div>
							<div className="col-start-5 col-span-1 flex justify-center items-end text-white">
								<Button
									variant="contained"
									className="!p-2 !w-[60px] !h-[60px] !rounded-full !bg-[#3e98a7]"
									onClick={(e) => handleDeleteTableDetail(e, item)}
								>
									<MuiClose fontSize="small" className="cursor-pointer" />
								</Button>
							</div>
						</div>
					</div>
				)
			})}

			<div className="flex flex-col p-024 border border-gray6 rounded-xl">
				<div className="flex flex-col col-span-5 gap-4">
					<div className="col-span-2 flex flex-col">
						<CustomFormInput
							title={getTranslation('newTableNumber')}
							required={true}
							error={checkTableAdd && !number}
							value={number}
							onChange={(e) => setNumber(e.target.value)}
							placeholder={getTranslation('enterNumber')}
						/>
					</div>
					<div className="col-span-2 flex flex-col">
						<CustomFormInput
							title={getTranslation('numberOfSeats')}
							required={true}
							error={checkTableAdd && !seats}
							label={getTranslation('required')}
							value={seats}
							type="number"
							onChange={(e) => setSeats(e.target.value)}
							placeholder={getTranslation('enterNrOfSeats')}
						/>
					</div>
				</div>

				<div className="col-span-1 flex justify-center items-center">
					<button
						className="border border-gray6 rounded-lg font-bold bg-white !p-3 !m-4 !px-12"
						onClick={handleNewTableDetail}
					>
						{getTranslation('add')}
					</button>
				</div>
			</div>
		</div>
	)

	return (
		<GenericModal
			open={showModal}
			onSubmit={handleConfirm}
			saveText={getTranslation('confirmSplit')}
			children={children}
			onClose={onClose}
			title={getTranslation('splitTable')}
			icon={icon}
		/>
	)
}
