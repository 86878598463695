import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { boxStyle } from '../../constants/types'
import StaffService from '../../services/staffService'
import { createUUID } from '../../utils/common'
import IconButton from '../common/IconButton'

export const scheduleConflictText = (text) =>
	t(`components.employee.ScheduleConflictModal.${text}`)

export default function ScheduleConflictModal({
	showModal,
	onClose,
	employeeId,
}) {
	const [visible, setVisible] = useState(showModal)
	const [data, setData] = useState([])

	useEffect(() => {
		setVisible(showModal)
		if (showModal) {
			loadData()
		}
	}, [showModal])

	const loadData = () => {
		// setData
		StaffService.ScheduleConflicts({ employee_id: employeeId })
			.then((res) => {
				setData(res.schedule_conflicts)
			})
			.catch((e) => {})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<IconButton title="Time-off Schedule Conflicts" />
					<div className="flex flex-col">
						<TableContainer component={Paper} className="mt-4">
							<Table aria-label="simple table">
								<TableHead className="bg-gray7">
									<TableRow key={createUUID()}>
										<TableCell
											className="!text-gray5 !text-left !font-medium !text-base"
											align="left"
										>
											{scheduleConflictText('date')}
										</TableCell>
										<TableCell
											className="!text-gray5 !text-left !font-medium !text-base"
											align="right"
										>
											{scheduleConflictText('start_time')}
										</TableCell>
										<TableCell
											className="!text-gray5 !text-left !font-medium !text-base"
											align="right"
										>
											{scheduleConflictText('end_time')}
										</TableCell>
										<TableCell
											className="!text-gray5 !text-left !font-medium !text-base"
											align="right"
										>
											{scheduleConflictText('status')}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row, index) => (
										<TableRow key={index}>
											<TableCell
												align="left"
												className="!py-3.5 !text-gray5 !text-left !text-base"
											>
												{row.date}
											</TableCell>
											<TableCell
												align="right"
												className="!py-3.5 !text-gray5 !text-left !text-base"
											>
												{row.start_time}
											</TableCell>
											<TableCell
												align="right"
												className="!py-3.5 !text-gray5 !text-left !text-base"
											>
												{row.end_time}
											</TableCell>
											<TableCell
												align="right"
												className="!py-3.5 !text-gray5 !text-left !text-base"
											>
												{row.status}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						{!data.length && (
							<div className="mt-[60px] align-middle text-gray-500">
								{' '}
								NO DATA{' '}
							</div>
						)}
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{scheduleConflictText('close')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
