import { CircularProgress, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import { boxStyle } from "../../constants/types";
import { RInputDeclare } from "../custom/RInputDeclare";

import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';
import icon from '../../assets/svgs/credit-card.svg';
import TemplateService from '../../services/templateService';

export const automaticModalText = (text) =>
	t(`components.guests.AddAutomaticModal.${text}`)


export default function AddAutomaticModal({
  showModal,
  onConfirm,
  onClose,
  templateList,
  data,
}) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const [checkValid, setCheckValid] = useState(false);
  useEffect(() => {
    if (showModal) {
      if (data?.id) {
        setTemplate(data?.template_id)
      } else {
        setTemplate(null);
        setCheckValid(false);
      }

    }
    setVisible(showModal);
  }, [showModal]);

  const onSubmit = () => {
    if ((template)) {
      let payload = {
        id: data?.id,
        reply_type: data?.value,
        template_id: template
      }
      if (data?.id) {
        setLoading(true);
        TemplateService.UpdateAutomaticReply(payload)
          .then(({ data }) => {
            setLoading(false);
            NotificationManager.success(data?.message ?? "Automatic reply is saved successfully");
            onConfirm();
          })
          .catch(err => {
            setLoading(false);
            if (typeof err?.error === 'string') {
              NotificationManager.error(err?.error);
            } else {
              const errorKeys = Object.keys(err?.error || {});
              if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const firstErrorMessage = err?.error[firstErrorKey][0];
                NotificationManager.error(firstErrorMessage || "Something went wrong!");
              } else {
                NotificationManager.error("Something went wrong!");
              }
            }

          })
      } else {
        setLoading(true);
        TemplateService.CreateAutomaticReply(payload)
          .then(({ data }) => {
            setLoading(false);
            NotificationManager.success(data?.message ?? "Automatic reply is saved successfully");
            onConfirm();
          })
          .catch(err => {
            setLoading(false);
            if (typeof err?.error === 'string') {
              NotificationManager.error(err?.error);
            } else {
              const errorKeys = Object.keys(err?.error || {});
              if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const firstErrorMessage = err?.error[firstErrorKey][0];
                NotificationManager.error(firstErrorMessage || "Something went wrong!");
              } else {
                NotificationManager.error("Something went wrong!");
              }
            }

          })
      }
    } else {
      setCheckValid(true);
    }
  };

  return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt={automaticModalText('icon_alt')} />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
						  {data?.modalTitle}
						</label>
					</div>
					<div className="flex flex-col px-024 mb-4">
						<span className="text-black1 !mb-2 text-xl font-medium">
						  {data?.title}
						</span>
						<span className="text-black1 text-l font-medium">
						  {data?.description}
						</span>
						<RInputDeclare
						  value={data?.optionLabel}
							className="!mt-6 !mb-4 !text-black1 font-medium"
						/>
						<TextField id="outlined-select" size="small" select>
							{data?.options.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.title}
								</MenuItem>
							))}
						</TextField>
						<RInputDeclare
							value={automaticModalText('template')}
							className="!mt-6 !mb-4 !text-black1 font-medium"
						/>
						<TextField
							id="outlined-select"
							size="small"
							error={checkValid && !template}
							select
							placeholder={automaticModalText('select_template')}
							value={template}
							onChange={(event) => {
								setTemplate(event.target.value)
							}}
						>
							{templateList.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{option.name}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{automaticModalText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : data?.id ? (
								automaticModalText('update')
							) : (
								automaticModalText('create')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
