import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/index.css";
import App from "./containers";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import './localisations/index';
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://113f6bbeab68c837ffb5b819f23ca15e@o4506317688995840.ingest.sentry.io/4506317690109952",
	integrations: [
		new Sentry.BrowserTracing(),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

reportWebVitals();
