import { Badge } from '@mui/material'

const bg = (type) => {
	if (type.toLocaleLowerCase().includes('prices')) {
		return 'bg-[#cd8438]'
	} else if (type.toLocaleLowerCase().includes('skus')) {
		return 'bg-[#F1C332]'
	} else if (type.toLocaleLowerCase().includes('stock')) {
		return 'bg-[#240b3b] !text-white'
	}
	return 'bg-[#D3D3D3]'
}

const getErrorBadge = (value = 0) => {
	return (
		<Badge
		// badgeContent={value}
		// color={value > 0 ? 'error' : 'default'}
		// variant="dot"
		>
			<span
				className={`p-2 rounded-full ${
					value > 0 ? 'bg-red-500 text-white' : 'bg-gray-400'
				}`}
				style={{
					width: '26px',
					textAlign: 'center',
					height: '26px',
					lineHeight: '10px',
				}}
			>
				{value}
			</span>
		</Badge>
	)
}

export const columns = (syncListText) => [
	{
		field: 'date',
		label: syncListText('date'),
		align: 'right',
	},
	{
		field: 'time_completed',
		label: syncListText('time_completed'),
		align: 'right',
	},
	{
		field: 'type',
		label: syncListText('type'),
		align: 'right',
		// TODO: should have a badge with proper icons
		cellComponent: ({ data = '' }) => (
			<div className={`bronze ${bg(data)}`}>
				<span>{data}</span>
			</div>
		),
	},

	{
		field: 'method',
		label: syncListText('method'),
		align: 'right',
	},
	{
		field: 'errors',
		label: syncListText('errors'),
		align: 'right',
		cellComponent: (res) => getErrorBadge(res?.data),
	},
	{
		field: 'third_party',
		label: syncListText('third_party'),
		align: 'center',
	},
]

export const filterOptions = [
	'All Syncs',
	'By Prices',
	'By Skus',
	'By Stock',
	'By Sales',
]

export const initialData = [
	{
		date: '2024-07-15',
		type: 'Prices Sync',
		time_completed: '16:40 PM',
		errors: 0,
		method: 'Manual - API',
		third_party: 'BookMaster',
	},
	{
		date: '2024-07-15',
		type: 'Skus Sync',
		time_completed: '04:30 AM',
		errors: 1,
		method: 'Manual - API',
		third_party: 'BookMaster',
	},
	{
		date: '2024-07-15',
		type: 'Stock Sync',
		time_completed: '04:10 AM',
		errors: 3,
		method: 'Automatic - API',
		third_party: 'myAlpha',
	},
	{
		date: '2024-07-15',
		type: 'Sales Sync',
		time_completed: '03:30 AM',
		errors: 1,
		method: 'Manual - CSV',
		third_party: 'WooCommerce',
	},
	{
		date: '2024-07-14',
		type: 'Prices Sync',
		time_completed: '23:24 PM',
		errors: 2,
		method: 'Automatic - API',
		third_party: 'WooCommerce',
	},
	{
		date: '2024-07-14',
		type: 'Stock Sync',
		time_completed: '22:34 PM',
		errors: 0,
		method: 'Manual - CSV',
		third_party: 'None',
	},
]

export const methodOptions = [
	{
		label: 'Automatic',
		value: 'automatic_api',
	},
	{
		label: 'Manual - API',
		value: 'manual_api',
	},
	{
		label: 'Manual - CSV',
		value: 'manual_csv',
	},
]

export const breadcrumbItems = [
	{
		label: 'Sync Management',
	},
	{ label: 'Synchronizations' },
]

export const addBreadcrumbItems = [
	...breadcrumbItems,
	{
		label: 'Add',
	},
]