import React, { useState } from "react";

import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { t } from 'i18next'
import AddAutomaticModal from "../../../components/guests/addAutomaticModal";

export const guestAutomaticText = (text) =>
	t(`guests.communicationPreferences.Automatic.${text}`)

export const Automatic = ({ data, templates, onRefresh }) => {
	const [isAddVisible, setAddVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)

	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
						<div className="flex flex-row items-center">
							<span className="text-black1 text-l font-medium">
								{guestAutomaticText('marketingAutomation')}
							</span>
						</div>
					</div>
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2 pb-4">
						<div className="flex flex-row items-center">
							<span className="text-gray-500 font-medium">
								{guestAutomaticText('marketingAutomationDescription')}
							</span>
						</div>
					</div>
					<List dense={false} className="mt-4">
						{(data ? data : []).map((row, index) => (
							<ListItem
								onClick={() => {
									setSelectedRow(row)
									setAddVisible(true)
								}}
								className="border-b"
								secondaryAction={
									<div className="items-center cursor-pointer mr-4">
										<span className="text-[#543c7f] text-l font-medium">
											{guestAutomaticText('manage')}
										</span>
									</div>
								}
							>
								<ListItemText primary={row.title} />
							</ListItem>
						))}
					</List>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
							{guestAutomaticText('noAutomaticReplies')}
						</div>
					)}
				</CardContent>
			</Card>
			<AddAutomaticModal
				data={selectedRow}
				showModal={isAddVisible}
				templateList={templates}
				onConfirm={() => onRefresh()}
				onClose={() => {
					setSelectedRow(null)
					setAddVisible(false)
				}}
			/>
		</div>
	)
}
