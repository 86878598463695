import { Card, CardContent } from '@mui/material'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'

import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Cookies from 'universal-cookie'
import plusIcon from '../../../assets/svgs/plus.svg'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import FeedbackButtons from '../../../components/feedback'
import TableEmptyData from '../../../components/table/TableEmptyData'
import orderService from '../../../services/orderService'
import retailService from '../../../services/retailService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import { isActionAllowed } from '../../menu/data'
import RenderOrderTable from '../tables/render-table'
import RetailTable from '../tables/retail-table'

// import './index.css';
export const orderTransformer = {
	new: { bgColor: '#f8dda7', textColor: '#94660c', readable: 'New' },
	processing: {
		bgColor: '#BCE8F1',
		textColor: '#31708F',
		readable: 'Processing',
	},
	order_cancelled: {
		bgColor: '#e5e5e5',
		textColor: '#777',
		readable: 'Cancelled',
	},
	order_completed: {
		bgColor: '#3C763D',
		textColor: '#fff',
		readable: 'Completed',
	},
	order_on_delivery: {
		bgColor: '#719571',
		textColor: '#fff',
		readable: 'On Delivery',
	},
}

const DeliveryOrders = () => {
	const { short_code } = useSelector((state) => state.app)
	const cookies = new Cookies()
	const navigate = useNavigate()

	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [date, setDate] = useState(new Date())
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		refreshData(date)
	}, [])

	const refreshData = (currentDate) => {
		if (
			venueType === 'sport_essentials' ||
			venueType === 'fashion_threads' ||
			venueType === 'pharmacy' ||
			venueType === 'home_decor'
		) {
			setLoaded(true)

			retailService
				.GetOrders()
				.then((res) => {
					setLoaded(true)
					setData(res?.orders || [])
				})
				.catch((err) => {
					setLoaded(true)
				})
		} else {
			let payload = {
				date: moment(currentDate).format('YYYY-MM-DD 23:59:59'),
			}
			orderService
				.GetAllDeliveryOrders(payload)
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('order service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'deliveries',
					})
					setLoaded(true)
					setData(res?.orders || [])
				})
				.catch(() => {
					setLoaded(true)
					identifyVenue(short_code)
					trackEvent('order service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'deliveries',
					})
				})
		}
	}

	const onChangeDate = (currentDate) => {
		setDate(currentDate)
		setLoaded(false)
		refreshData(currentDate)
	}

	return (
		<>
			{loaded ? (
				<div
					className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
					style={{ height: 'calc(100vh - 76px - 68px)' }}
				>
					<Card>
						<CardContent className="!p-0">
							<div className="flex justify-between items-center p-2 px-7">
								<div className="flex items-center">
									<span className="text-black1 text-xl font-medium">
										{venueType === 'sport_essentials' ||
										venueType === 'fashion_threads' ||
										venueType === 'pharmacy' ||
										venueType === 'home_decor'
											? 'Orders'
											: 'Delivery Orders'}
									</span>
								</div>
								<div className="flex items-center gap-4">
									{isActionAllowed(short_code) && (
										<div
											className={`bg-black1 flex px-6 py-2 text-white rounded-lg cursor-pointer`}
											onClick={() =>
												navigate(
													`/${short_code}/admin/delivery/delivery-orders/add`
												)
											}
										>
											<img src={plusIcon} alt="plus" className="mr-2" />
											<span>Add new order</span>
										</div>
									)}
									<FeedbackButtons type={'Orders Management'} />
									<CustomDateTimePicker
										onlyDate={true}
										classes="small"
										value={date}
										onChange={onChangeDate}
									/>
								</div>
							</div>
							{data.length > 0 ? (
								<div className="flex flex-col overflow-auto">
									<div className="flex flex-col overflow-auto">
										<Card>
											<CardContent className="!p-0">
												<div className="flex justify-between items-center px-6">
													<div className="flex items-center">
														<span className="text-black1 text-l font-medium mr-3">
															{venueType === 'sport_essentials' ||
															venueType === 'fashion_threads' ||
															venueType === 'pharmacy' ||
															venueType === 'home_decor'
																? 'Store Orders'
																: 'Delivery orders for your venue'}
														</span>
														<span className="text-base font-medium text-black1 px-2 bg-gray2 rounded-full">
															{data.length} orders
														</span>
													</div>
												</div>
												{venueType === 'sport_essentials' ||
												venueType === 'fashion_threads' ||
												venueType === 'pharmacy' ||
												venueType === 'home_decor' ? (
													<RetailTable
														{...{
															orderTransformer,
															data,
														}}
													/>
												) : (
													<RenderOrderTable
														{...{
															orderTransformer,
															data,
														}}
													/>
												)}
											</CardContent>
										</Card>
									</div>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={
											venueType === 'sport_essentials' ||
											venueType === 'fashion_threads' ||
											venueType === 'pharmacy' ||
											venueType === 'home_decor'
												? 'store orders'
												: 'delivery orders'
										}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</>
	)
}

export default DeliveryOrders
