import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

const InfoCard = ({
	title,
	infoFields = [],
	span = {
		xs: 12,
		sm: 6,
	},
}) => {
	return (
		<Card className="flex flex-col border rounded-lg mt-4">
			<CardContent>
				<Typography
					variant="h6"
					gutterBottom
					className="text-18 text-black1 font-medium"
				>
					{title}
				</Typography>
				<Grid container spacing={2}>
					{infoFields.map((field, index) => (
						<Grid item {...span} key={index}>
							<Typography
								variant="subtitle1"
								display="inline"
								style={{ fontWeight: 'bold' }}
								className="!text-gray-500 !text-left text-base"
							>
								{field.label}:{' '}
							</Typography>
							<Typography
								variant="body2"
								display="inline"
								className="!text-gray-500 !break-all flex-1 text-base"
							>
								{field.value}
							</Typography>
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	)
}

export default InfoCard
