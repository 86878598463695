import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import React from 'react'

import { t } from 'i18next'
import { isEmpty } from '../../../utils/common'

export const orderItemsText = (text) =>
	t(`reservations.reservationdetail.OrderItems.${text}`)

export default function OrderItems({ data }) {
	return (
		<div className="flex flex-col mt-4 w-full">
			<p className="mb-0 text-black1 text-lg font-medium">
				{orderItemsText('orderItems')}
			</p>
			<TableContainer component={Paper} className="mt-2">
				<Table aria-label="simple table">
					<TableHead className="bg-gray7 border-b">
						<TableRow>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="left"
							>
								{orderItemsText('no')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="left"
							>
								{orderItemsText('image')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="right"
							>
								{orderItemsText('name')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="right"
							>
								{orderItemsText('price')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="right"
							>
								{orderItemsText('quantity')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="right"
							>
								{orderItemsText('productTotal')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (
							<TableRow key={index}>
								<TableCell
									align="left"
									className="!py-3.5 !text-gray5 !text-left !text-base"
								>
									{row.id}
								</TableCell>
								<TableCell
									align="left"
									className="!py-3.5 !text-gray5 !text-left !text-base !font-bold"
								>
									{isEmpty(row.image_path) ? (
										<div
											className="w-9 h-9 bg-gray6"
											style={{ borderRadius: '5px' }}
										/>
									) : (
										<img
											src={row.image_path}
											style={{ borderRadius: '5px' }}
											className="w-9 h-9"
											alt="preview"
										/>
									)}
								</TableCell>
								<TableCell
									align="left"
									className="!py-3.5 !text-gray5 !text-left !text-base font-bold"
								>
									{row?.product?.title ?? '-'}
								</TableCell>
								<TableCell
									align="left"
									className="!py-3.5 !text-gray5 !text-left !text-base"
								>
									${row.product_total_price}
								</TableCell>
								<TableCell
									align="left"
									className="!py-3.5 !text-gray5 !text-left !text-base"
								>
									{row.product_quantity}
								</TableCell>
								<TableCell
									align="left"
									className="!py-3.5 !text-gray5 !text-left !text-base"
								>
									${row.product_total_price * row.product_quantity}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{!data?.length && (
				<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
					{orderItemsText('noProductsAssigned')}
				</div>
			)}
		</div>
	)
}
