import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import icon from '../../assets/svgs/table_white.svg'
import TableService from '../../services/tableService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select'
import { RInputDeclare } from '../custom/RInputDeclare'
import GenericModal from '../generic-modal'

const getTranslation = (text) => t(`components.table.tableModal.${text}`)

const sizeOptions = [
	{
		label: 'Small',
		value: 'small',
	},
	{
		label: 'Medium',
		value: 'medium',
	},
	{
		label: 'Large',
		value: 'large',
	},
]
const premiumTableBidOption = [
	{
		label: 'High bid wins',
		value: 'high_bid_wins',
	},
	{
		label: 'Choose winner manually',
		value: 'choose_winner_manually',
	},
]
export default function TableModal({ showModal, onClose, onRefresh }) {
	const [visible, setVisible] = useState(showModal)
	const [checkValid, setCheckValid] = useState(false)

	const [locationOptions, setLocationOptions] = useState([])
	const [shapeOptions, setShapeOptions] = useState([])
	const [number, setNumber] = useState('')
	const [name, setName] = useState('')
	const [location, setLocation] = useState('')
	const [seats, setSeats] = useState('')
	const [size, setSize] = useState('')
	const [shape, setShape] = useState('')
	const [showTableName, setShowTableName] = useState(false)
	const [showTableNumber, setShowTableNumber] = useState(false)
	const [showFloorplan, setShowFloorplan] = useState(false)
	const [hasPricing, setHasPricing] = useState(false)
	const [pricing, setPricing] = useState('')
	const [showPremiumTableBid, setShowPremiumTableBid] = useState(false)
	const [premiumTableBid, setPremiumTableBid] = useState('')
	const [min, setMin] = useState('')
	const [max, setMax] = useState('')
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		setVisible(showModal)
		if (showModal) {
			setCheckValid(false)
			setNumber('')
			setName('')
			setLocation('')
			setSeats('')
			setSize('')
			setShape('')

			TableService.GetLocations().then((res) => {
				identifyVenue(short_code)
				trackEvent('GetLocations', {
					action_category: 'retrieve',
					action_outcome: 'success',
					interaction_element: 'tableModal',
				})
				let newOptions = res.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				})
				setLocationOptions(newOptions)
			})

			TableService.GetShapes().then((res) => {
				let newOptions = res.map((item) => {
					return {
						value: item.id,
						label: item.shape,
					}
				})
				setShapeOptions(newOptions)
				identifyVenue(short_code)
				trackEvent('GetShapes', {
					action_category: 'retrieve',
					action_outcome: 'success',
					interaction_element: 'tableModal',
				})
			})
		}

		// eslint-disable-next-line
	}, [showModal])

	const onSubmit = () => {
		let isValid = true

		// Validate required fields
		if (!name) {
			isValid = false
			setCheckValid(true)

			NotificationManager.warning('Table naming is required', '')
		}

		// Validate required fields
		if (!location) {
			isValid = false
			setCheckValid(true)

			NotificationManager.warning('Table location is required', '')
		}

		// Validate required fields
		if (!seats) {
			isValid = false
			setCheckValid(true)

			NotificationManager.warning('Table number of seats are required!', '')
		}

		// Validate required fields
		if (!shape) {
			isValid = false
			setCheckValid(true)

			NotificationManager.warning('Table shape is required!', '')
		}

		// Validate required fields
		if (!size) {
			isValid = false
			setCheckValid(true)

			NotificationManager.warning('Table size is required!', '')
		}

		// Additional validation for pricing if has_pricing is true
		if (hasPricing && (!pricing || pricing.trim() === '')) {
			isValid = false
			// You can show an error message or handle it as needed
			NotificationManager.warning('Table pricing is required', '')
		}

		// Additional validation for premium table bid fields if show_premium_table_bid is true
		if (showPremiumTableBid) {
			if (
				!premiumTableBid ||
				premiumTableBid.trim() === '' ||
				!min ||
				min.trim() === '' ||
				!max ||
				max.trim() === ''
			) {
				isValid = false
				// You can show an error message or handle it as needed
				NotificationManager.warning('Bid values are required', '')
			}
		}

		if (isValid) {
			let payload = {
				number: number,
				name: name,
				location_id: location,
				seats: seats,
				shape: shape,
				size: size,
				show_table_name: showTableName,
				show_table_number: showTableNumber,
				show_floorplan: showFloorplan,
				has_pricing: hasPricing,
				pricing: hasPricing ? pricing : null,
				show_premium_table_bid: showPremiumTableBid,
				premium_table_bid: showPremiumTableBid ? premiumTableBid : null,
				min_bid: showPremiumTableBid ? min : null,
				max_bid: showPremiumTableBid ? max : null,
			}

			TableService.AddTable(payload)
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('AddTable', {
						action_category: 'add',
						action_outcome: 'success',
						interaction_element: 'tableModal',
					})
					NotificationManager.success('Success')
					onRefresh()
					onClose(true)
				})
				.catch((e) => {
					NotificationManager.error('Failed', '')
					throw new Error('An error occurred while adding a table')
				})
		} else {
			setCheckValid(true)
		}
	}

	const children = (
		<div className="grid grid-cols-2 gap-4 overflow-auto max-h-[60vh]">
			<CustomFormInput
				value={number}
				onChange={(e) => setNumber(e.target.value)}
				placeholder={getTranslation('number')}
				required={true}
			/>

			<CustomFormInput
				value={name}
				onChange={(e) => setName(e.target.value)}
				placeholder={getTranslation('name')}
				required={true}
			/>

			<CustomFormSelect
				setOptionValue={setLocation}
				placeholder={getTranslation('location')}
				value={location}
				options={locationOptions}
				className="!h-10"
			/>

			<CustomFormInput
				value={seats}
				onChange={(e) => setSeats(e.target.value)}
				placeholder={getTranslation('seats')}
				required={true}
			/>

			<CustomFormSelect
				setOptionValue={setSize}
				placeholder={getTranslation('size')}
				value={size}
				options={sizeOptions}
				className="!h-10"
			/>

			<CustomFormSelect
				setOptionValue={setShape}
				placeholder={getTranslation('shape')}
				value={shape}
				options={shapeOptions}
				className="!h-10"
			/>

			<div className="relative w-full flex flex-col border-[1px] border-[#e4e4e4] p-2 mt-6 rounded-[4px]">
				<p className="bg-white absolute top-[-12px] px-1 text-black1 font-medium">
					{getTranslation('tableVisibility')}
				</p>
				<FormControlLabel
					control={
						<Checkbox
							onClick={() => setShowTableName(!showTableName)}
							checked={showTableName}
						/>
					}
					label={getTranslation('showTableName')}
				/>
				<FormControlLabel
					control={
						<Checkbox
							onClick={() => setShowTableNumber(!showTableNumber)}
							checked={showTableNumber}
						/>
					}
					label={getTranslation('showTableNumber')}
				/>
				<FormControlLabel
					control={
						<Checkbox
							onClick={() => setShowFloorplan(!showFloorplan)}
							checked={showFloorplan}
						/>
					}
					label={getTranslation('showFloorplan')}
				/>
			</div>
			<div className="flex flex-col relative border-[1px] border-[#e4e4e4] p-2 mt-6 rounded-[4px]">
				<p className="bg-white absolute top-[-12px] px-1 text-black1 font-medium">
					{getTranslation('hasPricing')}
				</p>
				<FormControlLabel
					control={
						<Checkbox
							onClick={() => setHasPricing(!hasPricing)}
							checked={hasPricing}
						/>
					}
					label={getTranslation('hasPricingLabel')}
				/>
				{hasPricing && (
					<div className="flex flex-col">
						<RInputDeclare
							value={getTranslation('pricing')}
							className="!mt-2 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							required
							error={checkValid && !pricing}
							id="filled-required"
							label={getTranslation('required')}
							value={pricing}
							onChange={(e) => setPricing(e.target.value)}
						/>
					</div>
				)}
			</div>
			<div className="flex flex-col relative border-[1px] border-[#e4e4e4] p-2 mt-6 rounded-[4px]">
				<p className="bg-white absolute top-[-12px] px-1 text-black1 font-medium">
					{getTranslation('premiumTableBid')}
				</p>
				<FormControlLabel
					control={
						<Checkbox
							onClick={() => setShowPremiumTableBid(!showPremiumTableBid)}
							checked={showPremiumTableBid}
						/>
					}
					label={getTranslation('premiumTableBidLabel')}
				/>
				{showPremiumTableBid && (
					<div className="flex flex-col">
						<RInputDeclare
							value={getTranslation('winMethod')}
							className="!mt-2 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							error={checkValid && !premiumTableBid}
							select
							label={getTranslation('winMethodLabel')}
							value={premiumTableBid}
							onChange={(event) => setPremiumTableBid(event.target.value)}
						>
							{premiumTableBidOption.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<div className="flex flex-col mt-4">
							<RInputDeclare
								value={getTranslation('minBid')}
								className="!mt-0 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								error={checkValid && !min}
								id="filled-required"
								label={getTranslation('enterMinBidValue')}
								value={min}
								onChange={(e) => setMin(e.target.value)}
							/>
						</div>
						<div className="flex flex-col mt-4">
							<RInputDeclare
								value={getTranslation('maxBid')}
								className="!mt-0 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								error={checkValid && !max}
								id="filled-required"
								label={getTranslation('enterMaxBidValue')}
								value={max}
								onChange={(e) => setMax(e.target.value)}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)

	return (
		<GenericModal
			{...{ children, onClose, onSubmit, open: showModal, icon }}
			title={getTranslation('tableLabel')}
		/>
	)
}
