// data.js
export const busyPeriods = [
	{
		day: 'Monday',
		periods: [
			{ time: '10AM-12PM', status: 'Low' },
			{ time: '12PM-2PM', status: 'High' },
			{ time: '2PM-5PM', status: 'Low' },
			{ time: '5PM-8PM', status: 'Medium' },
			{ time: '8PM-Close', status: 'Low' },
		],
	},
	{
		day: 'Tuesday',
		periods: [
			{ time: '10AM-12PM', status: 'Low' },
			{ time: '12PM-2PM', status: 'Medium' },
			{ time: '2PM-5PM', status: 'Low' },
			{ time: '5PM-8PM', status: 'High' },
			{ time: '8PM-Close', status: 'Medium' },
		],
	},
]

export const busyPeriodsColumns = [
	{ field: 'day', label: 'Day', align: 'center' },
	{ field: '10AM-12PM', label: '10AM-12PM', align: 'center' },
	{ field: '12PM-2PM', label: '12PM-2PM', align: 'center' },
	{ field: '2PM-5PM', label: '2PM-5PM', align: 'center' },
	{ field: '5PM-8PM', label: '5PM-8PM', align: 'center' },
	{ field: '8PM-Close', label: '8PM-Close', align: 'center' },
]


export const transformBusyPeriodsData = (busyPeriods) => {
	return busyPeriods.map(dayData => {
		const dayRow = { day: dayData.day };
		dayData.periods.forEach(period => {
			dayRow[period.time] = period.status;
		});
		return dayRow;
	});
};
