import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import WaitlistService from '../../services/waitlistService'
import CustomIconButton from '../common/IconButton'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import waitlistIcon from '../../assets/svgs/waitlist_icon.svg'
import CustomDateTimePicker from '../custom/CustomDateTimePicker'

const getTranslation = (text) => t(`components.waitlist.updateWaitlist.${text}`)

export default function UpdateWaitlistModal({
	showModal,
	onConfirm,
	onClose,
	item,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)

	const [isVip, setVip] = useState(false)
	const [isRegular, setRegular] = useState(false)
	const [arrivalTime, setArrivalTime] = useState('')
	const [seatTime, setSeatTime] = useState('')
	const [leftTime, setLeftTime] = useState('')

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setVip(Boolean(item.is_vip))
			setRegular(Boolean(item.is_regular))
			setArrivalTime(item.arrival_time)
			setSeatTime(item.seat_time)
			setLeftTime(item.left_time)
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (arrivalTime && seatTime && arrivalTime && leftTime) {
			let payload = {
				id: item.id,
				is_vip: isVip,
				is_regular: isRegular,
				arrival_time: arrivalTime,
				left_time: leftTime,
				seat_time: seatTime,
			}
			WaitlistService.UpdateWaitlist(payload)
				.then((res) => {
					NotificationManager.success('Success')
					onConfirm()
					onClose()
				})
				.catch((e) => {
					NotificationManager.error('Failed')
				})
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<CustomIconButton
						icon={waitlistIcon}
						title={getTranslation('updateWaitlistTitle')}
					/>
					<div className="flex flex-col px-024 mb-4">
						<p>
							<Checkbox
								checked={isVip}
								onChange={(e) => setVip(e.target.checked)}
								sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
							/>{' '}
							{getTranslation('isVipLabel')}
						</p>
						<p>
							<Checkbox
								checked={isRegular}
								onChange={(e) => setRegular(e.target.checked)}
								sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
							/>{' '}
							{getTranslation('isRegularLabel')}
						</p>

						<div className="flex flex-row gap-2">
							<RInputDeclare
								value={getTranslation('arrivalTimeLabel')}
								className="text-base !text-black1 font-medium !mb-0"
							/>
							<RInputDeclare
								value={getTranslation('requiredLabel')}
								className="text-base !mb-0"
							/>
						</div>
						<CustomDateTimePicker
							placeholder={getTranslation('startTimePlaceholder')}
							value={arrivalTime}
							onChange={setArrivalTime}
						/>

						<div className="flex flex-row gap-2 mt-2">
							<RInputDeclare
								value={getTranslation('seatTimeLabel')}
								className="text-base !text-black1 font-medium !mb-0"
							/>
							<RInputDeclare
								value={getTranslation('requiredLabel')}
								className="text-base !mb-0"
							/>
						</div>
						<CustomDateTimePicker
							placeholder={getTranslation('startTimePlaceholder')}
							value={seatTime}
							onChange={setSeatTime}
						/>

						<div className="flex flex-row gap-2 mt-2">
							<RInputDeclare
								value={getTranslation('leftTimeLabel')}
								className="text-base !text-black1 font-medium !mb-0"
							/>
							<RInputDeclare
								value={getTranslation('requiredLabel')}
								className="text-base !mb-0"
							/>
						</div>
						<CustomDateTimePicker
							placeholder={getTranslation('startTimePlaceholder')}
							value={leftTime}
							onChange={setLeftTime}
						/>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('cancelButtonLabel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{getTranslation('confirmButtonLabel')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
