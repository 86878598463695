import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import CustomBarChart from '../../components/Charts/custom-barchart'
import GenericTable from '../../components/generic-table/generic-table'
import accommodationService from '../../services/accommodationService'
import DashboardService from '../../services/dashboardService'
import BookingTabs from './bookings-info/BookingTabs'
import Calendar from './calendar'
import { barChartList, bookingColumns, revenueBlock } from './data'

const getTranslation = (text) => t(`accommodation.${text}`)

const AccommodationDashboard = () => {
	const [dashboardData, setDashboardData] = useState()
	const [userWelcome, setUserWelcome] = useState('Admin')

	const { auth } = useSelector((state) => state)
	const getDashboardData = () => {
		DashboardService.GetAccommodationDashboard().then((res) => {
			setDashboardData(res)
		})
	}

	useEffect(() => {
		getDashboardData()
		if (auth && auth.user) {
			setUserWelcome(auth.user.name)
		}
	}, [auth])

	const weeks = [
		{ name: 'Week 1', value: 0 },
		{ name: 'Week 2', value: 0 },
		{ name: 'Week 3', value: 0 },
		{ name: 'Week 4', value: 0 },
	]

	dashboardData?.revenue_statistics.forEach((entry) => {
		const day = new Date(entry.date).getDate()
		const weekIndex = Math.floor((day - 1) / 7)
		if (weeks[weekIndex]) {
			weeks[weekIndex].value += parseInt(entry.revenue)
		}
	})

	const totalSum = weeks.reduce((sum, week) => sum + week.value, 0)

	const onDecline = (rowId) => {
		handleStatusChange({ id: rowId, status: "Cancelled" })
	}

	const onConfirm = (rowId) => {
		handleStatusChange({ id: rowId, status: "Confirmed" })
	}

	const handleStatusChange = (payload) => {
		accommodationService
			.ChangeBookingStatus(payload)
			.then((res) => {
				NotificationManager.success(
					getTranslation(
						payload.status === "Confirmed"
							? "booking_confirmed_success"
							: "booking_cancelled_success"
					),
					getTranslation("success"),
					3000
				)
				getDashboardData()
			})
			.catch((err) => {
				console.error(err)
				NotificationManager.error(getTranslation("booking_status_error"))
			})
	}

	return (
		<div className="p-6 flex flex-col gap-5">
			<div className="name">
				<h1>{getTranslation("welcome_message").replace('{{name}}', userWelcome)}</h1>
				<span>{getTranslation("dashboard_description")}</span>
			</div>

			<BookingTabs />

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
				{revenueBlock(dashboardData?.general_data).map((item, index) => (
					<div className="revenue-block" key={index}>
						<div className="flex items-center gap-2">
							<span>{item.icon}</span>
							<span className="title">{item.title}</span>
						</div>
						<span className="value">{item.value}</span>
					</div>
				))}
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
				<div className="statistics col-span-3 w-full">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">{getTranslation("revenue_stats")}</span>
						<div className="flex items-center gap-2 weekly">
							<span>{getTranslation("monthly")}</span>
						</div>
					</div>
					<div className="flex items-center gap-2">
						<span className="value">{totalSum} €</span>
						<span className="this-week">{getTranslation("total_revenue")}</span>
					</div>
					<div className="w-full">
						<CustomBarChart
							data={weeks}
							dataKey="name"
							barChartList={barChartList}
							showLegend={false}
							barSize={20}
							yaxis={true}
							cartesian={true}
							paddingBar={5}
							horizontalCartesian={true}
							showLabelCenter={false}
							radius={[10, 10, 0, 0]}
						/>
					</div>
				</div>

				<div className="statistics w-full col-span-2">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">{getTranslation("bookings_schedule")}</span>
						<div className="flex items-center gap-2 weekly">
							<span>{getTranslation("monthly")}</span>
						</div>
					</div>
					<div className="w-full">
						<Calendar bookingsData={dashboardData?.booking_schedule} />
					</div>
				</div>
			</div>

			<div className="statistics w-full col-span-2 !gap-0">
				<span className="bookings">{getTranslation("bookings")}</span>
				<GenericTable
					columns={bookingColumns}
					data={
						dashboardData?.booking_list?.map((item) => ({
							...item,
							total_amount: `${item.total_amount} ${dashboardData.primary_currency}`,
						})) ?? []
					}
					onEdit={() => { }}
					onConfirm={onConfirm}
					onDelete={onDecline}
				/>
			</div>
		</div>
	)
}

export default AccommodationDashboard
