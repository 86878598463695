import { bronzeIcon, goldIcon, platiniumIcon, silverIcon } from './icons'
import './index.css'

const getTierName = (tier = '') => {
	if (tier.includes('Bronze')) {
		return <span>{bronzeIcon}</span>
	} else if (tier.includes('Silver')) {
		return <span>{silverIcon}</span>
	} else if (tier.includes('Gold')) {
		return <span>{goldIcon}</span>
	} else if (tier.includes('Platinum')) {
		return <span>{platiniumIcon}</span>
	}
}

export const columns = (guestsViewText) => [
	{ label: guestsViewText('name'), field: 'name' },
	{ label: guestsViewText('email'), field: 'email' },
	{ label: guestsViewText('phone'), field: 'phone' },
	{ label: guestsViewText('registered'), field: 'registered_date' },
	{ label: guestsViewText('notes'), field: 'notes' },
	{
		label: guestsViewText('points'),
		field: 'points',
		cellComponent: (res) => (
			<div className="flex justify-center">{res?.data}</div>
		),
	},
	{
		label: guestsViewText('loyalty'),
		field: 'tier_name',
		cellComponent: (res) => getTierName(res?.data),
	},
	{
		label: guestsViewText('lastTouchpoint'),
		field: 'last_touchpoint',
		cellComponent: (res) => {
			const touchpoint = res?.data
			if (typeof touchpoint === 'object') {
				return (
					<div>
						<div className="last-touch-title">{touchpoint.relative}</div>
						<div style={{ color: '#B3B3B3', fontSize: 'small' }}>
							{touchpoint.exact}
						</div>
					</div>
				)
			}
			return (
				<div>
					<div>{touchpoint || 'No bookings yet'}</div>
				</div>
			)
		},
	},
	{
		label: guestsViewText('lastComm'),
		field: 'last_communication',
		cellComponent: (res) => {
			const communication = res?.data
			if (typeof communication === 'object') {
				return (
					<div>
						<div className="last-touch-title">{communication.relative}</div>
						<div style={{ color: '#B3B3B3', fontSize: 'small' }}>
							{communication.exact}
						</div>
					</div>
				)
			}
			return (
				<div>
					<div>{communication || 'No communications yet'}</div>
				</div>
			)
		},
	},
	{
		label: guestsViewText('action'),
		field: 'actions',
	},
]
