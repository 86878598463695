import React from 'react'
import TshirtIcon from '../../../assets/svgs/Bg.svg'
import DiscountIcon from '../../../assets/svgs/discount1.svg'
import { updateRetailCart } from '../../../utils/storage'

import './index.css'

const RetailCheckout = (props) => {
	const { list, setUpdateCart } = props

	const currencySymbols = {
		ALL: 'Lek',
		USD: '$',
		EUR: '€',
		// Add other currencies and their symbols here
	}

	const getCurrencySymbol = (currencyCode) => {
		return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
	}
	return (
		<div
			className={
				'relative  view-sales overflow-auto h-full w-full bg-[#F9F7F7]'
			}
		>
			<div className="border-b-[1px] border-[#D0D5DD] px-6  flex pb-4 w-full">
				<button className="border-[1px] bg-white font-semibold border-[#393C49] bg-transparent text-[#393C49] px-6 py-[14px] rounded-[8px]">
					ALL MENU
				</button>
				<button className=" font-semibold border-[#393C49] bg-transparent text-[#393C49] px-6 py-[14px] rounded-[8px]">
					COLD DISHES
				</button>

				<button className=" font-semibold border-[#393C49] bg-transparent text-[#393C49] px-6 py-[14px] rounded-[8px]">
					SOUP{' '}
				</button>
				<button className=" font-semibold border-[#393C49] bg-transparent text-[#393C49] px-6 py-[14px] rounded-[8px]">
					GRILL
				</button>
				<button className=" font-semibold border-[#393C49] bg-transparent text-[#393C49] px-6 py-[14px] rounded-[8px]">
					APPETIZER{' '}
				</button>
				<button className=" font-semibold border-[#393C49] bg-transparent text-[#393C49] px-6 py-[14px] rounded-[8px]">
					DESSERT
				</button>
			</div>
			<div className="flex flex-wrap gap-4 py-8 px-6">
				{list?.map((item, index) => {
					return (
						<div
							className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[344px] h-[118px] flex"
							onClick={() => {
								updateRetailCart(item)
								setUpdateCart(true)
							}}
						>
							<img className=" rounded-[8px] object-cover  " src={TshirtIcon} />
							<div className="flex-1 bg-white flex justify-between flex-col p-4 rounded-r-[8px]">
								<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
									{item?.title}{' '}
								</p>
								<p className="text-[#8E8E8E] text-14 font-semibold leading-[130%] ">
									x in stock
								</p>
								<div className="flex justify-between">
									{/*<p className="text-[#8E8E8E] text-14 font-semibold leading-[130%] ">*/}
									{/*	2 variants*/}
									{/*</p>*/}
									<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
										{getCurrencySymbol(item?.currency)}
										{item?.price}
									</p>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className="absolute  bottom-[24px] flex flex-wrap px-6 gap-8">
				<div className="retail-pos-discount-block rounded-[8px] border-[1px] border-[#E4E4E4] w-[192px]">
					<div className=" rounded-[8px] w-full object-cover h-[80px] bg-[#240b3b] align-middle">
						<img src={DiscountIcon} />
					</div>
					<div className="h-[40px] bg-white items-center flex justify-between flex-col p-4 rounded-b-[8px]">
						<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
							Discount
						</p>
					</div>
				</div>
				{/*<div className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[192px]">*/}
				{/*	<div className=" rounded-[8px] w-full object-cover h-[80px] bg-[#240b3b] align-middle">*/}
				{/*		<img src={RewardIcon} />*/}
				{/*	</div>*/}
				{/*	<div className="h-[40px] bg-white items-center flex justify-between flex-col p-4 rounded-b-[8px]">*/}
				{/*		<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">*/}
				{/*			Reward*/}
				{/*		</p>*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[192px]">*/}
				{/*	<div className=" rounded-[8px] w-full object-cover h-[80px] bg-[#240b3b] align-middle">*/}
				{/*		<img src={Giftcard} />*/}
				{/*	</div>*/}
				{/*	<div className="h-[40px] bg-white items-center flex justify-between flex-col p-4 rounded-b-[8px]">*/}
				{/*		<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">*/}
				{/*			Geft Cards*/}
				{/*		</p>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</div>
	)
}

export default RetailCheckout
