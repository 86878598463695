import { Grid } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomToggleButton from '../../../components/custom/CustomToggleButton'
import MenuProduct from '../../../components/menus/Product'
import GenericSearch from '../../../components/search/Search'
import GuestService from '../../../services/guestService'
import MenuService from '../../../services/menuService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import CustomerModal from './choose-customer'
import { gridItems } from './grid-items'
import NewOrderSidebar from './new-order-sidebar'
import PaymentMethod from './payment-method'

export const initialCustomerDetails = {
	customer: {
		first_name: '',
		last_name: '',
		phone: '',
		email: '',
		id: undefined,
	},
	shipping_address: {
		line1: '',
		line2: '',
		city: '',
		state: '',
		postal_code: '',
	},
}

const initialCategory = [
	{
		title: 'All',
		id: 0,
	},
]


const OrderView = () => {
	const { short_code } = useSelector((state) => state.app)

	const [products, setProducts] = useState([])
	const [categories, setCategories] = useState([])
	const [selectedCategory, setSelectedCategory] = useState(categories[0])
	const [selectedItems, setSelectedItems] = useState([])
	const [searchCategory, setSearchCategory] = useState('')
	const [searchItem, setSearchItem] = useState('')
	const [gridCol, setGridCol] = useState(3)
	const [openModal, setOpenModal] = useState(false)
	const [openPayment, setOpenPayment] = useState(false)
	const [customerDetails, setCustomerDetails] = useState(initialCustomerDetails)
	const [customersData, setCustomersData] = useState([])

	useEffect(() => {
		getAllCustomers()
	})

	const getAllCustomers = () => {
		GuestService.GetAll()
			.then((res) => {
				setCustomersData(res.data)
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'all guests',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'all guests',
				})
			})
	}

	const loadProducts = () => {
		if (selectedCategory && selectedCategory.title !== 'All') {
			let query = [`category=${selectedCategory.id}`]
			MenuService.getProducts(query.join('&'))
				.then((res) => {
					setProducts(res?.products ?? [])
					identifyVenue(short_code)
					trackEvent('menu service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'categories',
					})
				})
				.catch(() => {
					identifyVenue(short_code)
					trackEvent('menu service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'categories',
					})
				})
		} else {
			MenuService.getProducts()
				.then((res) => {
					setProducts(res?.products || [])
					identifyVenue(short_code)
					trackEvent('menu service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'products',
					})
				})
				.catch(() => {
					identifyVenue(short_code)
					trackEvent('menu service', {
						action_category: 'get',
						action_outcome: 'fail',
						interaction_element: 'products',
					})
				})
		}
	}

	const loadCategories = () => {
		MenuService.getCategories()
			.then((res) => {
				setCategories(res?.categories ?? [])
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'categories',
				})
			})
	}

	useEffect(() => {
		loadCategories()
	})

	useEffect(() => {
		loadProducts(selectedCategory?.id)
	}, [selectedCategory])

	const sidebarStyles = {
		height: '100%',
		overflowY: 'auto',
		borderRight: '1px solid #ccc',
		paddingRight: '10px',
	}

	const categoryContainerStyles = {
		maxHeight: '800px',
		overflowY: 'auto',
	}

	const addOrUpdateItem = (clickedItem) => {
		const existingItemIndex = selectedItems.findIndex(
			(item) => item.id === clickedItem.id
		)

		if (existingItemIndex !== -1) {
			const updatedItems = [...selectedItems]
			updatedItems[existingItemIndex].quantity += 1
			setSelectedItems(updatedItems)
		} else {
			setSelectedItems((prevItems) => [
				...prevItems,
				{ ...clickedItem, quantity: 1 },
			])
		}
	}

	const deleteFromCart = (id) => {
		setSelectedItems(selectedItems.filter((item) => item.id !== id))
	}

	const totalCost = () => {
		let sum = 0
		for (let index = 0; index < selectedItems.length; index++) {
			const element = selectedItems[index]
			sum += element.quantity * element.price
		}

		return sum
	}

	const selectedCustomer = useMemo(() => {
		return customersData.find((item) => item.id === customerDetails.customer.id)
	}, [customersData, customerDetails])

	return (
		<div className="flex gap-2 justify-between items-center">
			<Grid container spacing={2}>
				<Grid item xs={2} style={sidebarStyles}>
					<div className="flex flex-col gap-2 p-2">
						<GenericSearch
							search={searchCategory}
							setSearch={setSearchCategory}
							placeholder="Search Category"
						/>
						<div
							className="flex flex-col gap-2 p-2"
							style={categoryContainerStyles}
						>
							{[...initialCategory, ...categories].map((item) => {
								return (
									<div
										key={item}
										style={{ cursor: 'pointer' }}
										onClick={() => setSelectedCategory(item)}
										className={
											selectedCategory === item
												? 'list flex items-center text-[14px] gap-3 mx-4 rounded-lg duration-150 bg-black1 px-[18px] py-[14px] text-white'
												: 'list flex items-center text-[14px] mx-4 text-[#828487] gap-3 font-normal duration-150 px-[18px] py-[14px] hover:text-cyan1'
										}
									>
										<p>{item.title}</p>
									</div>
								)
							})}
						</div>
					</div>
				</Grid>
				<Grid item xs={6} style={sidebarStyles}>
					<div className="flex flex-col gap-2 p-2">
						<div className="flex items-center justify-between">
							<GenericSearch
								search={searchItem}
								setSearch={setSearchItem}
								placeholder="Search Item"
							/>
							<div className="flex">
								{gridItems.map((item) => (
									<CustomToggleButton
										label={item.label}
										classes="px-4 mx-2"
										onAction={() => setGridCol(item.grid)}
										active={gridCol === item.grid}
									/>
								))}
							</div>
						</div>
						<div
							className={`grid grid-cols-${gridCol} gap-2 p-2`}
							style={categoryContainerStyles}
						>
							{products.map((item) => (
								<MenuProduct
									data={item}
									onClick={() => addOrUpdateItem(item)}
									currency={item.currency}
								/>
							))}
						</div>
					</div>
				</Grid>
				<Grid item xs={4}>
					<NewOrderSidebar
						cart={selectedItems}
						deleteFromCart={deleteFromCart}
						title="New Order"
						totalCost={totalCost()}
						key="newOrderSidebar"
						onFinishTitle="Next"
						handleClick={() => setOpenModal(true)}
						isCustomerDisabled={selectedItems.length === 0}
						isOrderDisabled={
							(customerDetails.customer.first_name.length === 0 &&
								!selectedCustomer) ||
							openModal
						}
						handleOrder={() => setOpenPayment(true)}
					/>
				</Grid>
			</Grid>

			{openModal && (
				<CustomerModal
					onClose={() => setOpenModal(false)}
					visible={openModal}
					customerDetails={customerDetails}
					setCustomerDetails={setCustomerDetails}
					selectedCustomer={selectedCustomer}
					customersData={customersData}
				/>
			)}

			{openPayment && (
				<PaymentMethod
					onClose={() => setOpenPayment(false)}
					visible={openPayment}
					totalPrice={totalCost()}
					customerDetails={customerDetails}
					selectedItems={selectedItems}
				/>
			)}
		</div>
	)
}

export default OrderView
