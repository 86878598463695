import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import creditIcon from '../../assets/svgs/credit-card.svg'
import retailService from '../../services/retailService'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import CustomSelect from '../custom/CustomSelect'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select'
import GenericModal from '../generic-modal'

const getTranslation = (text) => t(`components.store.shippingZoneModal.${text}`)

const listShippingMethods = [
	{ title: 'Free Shipping', value: 'free_shipping' },
	{ title: 'Flat Rate', value: 'flat_rate' },
]

const listFreeshippingMethods = [
	{ title: 'None', value: false },
	// { title: 'A valid free shipping coupon', value: 'A valid free shipping coupon' },
	{ title: 'A minimum order amount', value: true },
	// { title: 'A minimum order ammount OR a coupon', value: 'A minimum order ammount OR a coupon' },
	// { title: 'A minimum order ammount AND a coupon', value: 'A minimum order ammount AND a coupon' },
]

export default function ShippingZoneModal({ showModal, data, onClose }) {
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState({})
	const [shippingMethods, setshippingMethods] = useState([])

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
				if (data.shipping_methods) {
					let shippingMethdosTmp = []
					data.shipping_methods.forEach((item) => {
						if (item.method_type == 'flat_rate') {
							setState({
								...state,
								flast_rate_cost: item.flast_rate_cost,
							})
						} else if (item.method_type == 'free_shipping') {
							setState({
								...state,
								has_minimum_order_amount: item.has_minimum_order_amount,
								minimum_order_amount: item.minimum_order_amount,
							})
						}
						shippingMethdosTmp.push(item.method_type)
					})
					setshippingMethods(shippingMethdosTmp)
				}
			} else {
				setState({})
				setshippingMethods([])
			}
		}
	}, [showModal])

	const onSubmit = () => {
		if (!(state?.zone_name && state?.region)) {
			return
		}
		if (shippingMethods.length === 0) {
			return
		}
		if (shippingMethods.some((item) => item === 'free_shipping')) {
			if (state?.has_minimum_order_amount === undefined) {
				return
			}
		}
		if (shippingMethods.some((item) => item === 'free_shipping')) {
			if (
				state?.has_minimum_order_amount === true &&
				!state?.minimum_order_amount
			) {
				return
			}
		}
		if (shippingMethods.some((item) => item === 'flat_rate')) {
			if (!state?.flast_rate_cost) {
				return
			}
		}
		let shippingMethdosTmp = []
		shippingMethods.forEach((item) => {
			if (item === 'flat_rate') {
				shippingMethdosTmp.push({
					flast_rate_cost: state.flast_rate_cost,
					method_type: item,
				})
			} else if (item === 'free_shipping') {
				shippingMethdosTmp.push({
					method_type: item,
					has_minimum_order_amount: state.has_minimum_order_amount,
					minimum_order_amount: state.minimum_order_amount,
				})
			}
		})
		let payload = {
			id: data?.id,
			zone_name: state.zone_name,
			region: state.region,
			shipping_methods: shippingMethdosTmp,
		}
		if (data?.id) {
			setLoading(true)
			retailService
				.udpateShppingZone(payload)
				.then(({ data }) => {
					setLoading(false)
					onClose(true)
					NotificationManager.success(
						data?.message ?? 'Shipping zone is saved successfully'
					)
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(err?.message ?? 'Something went wrong!')
				})
		} else {
			setLoading(true)
			retailService
				.createShppingZone(payload)
				.then(({ data }) => {
					setLoading(false)
					onClose(true)
					NotificationManager.success(
						data?.message ?? 'Shipping zone is saved successfully'
					)
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(err?.message ?? 'Something went wrong!')
				})
		}
	}

	const children = (
		<div className="flex flex-col gap-2 pt-0 mb-4">
			<CustomFormInput
				title={getTranslation('zoneName')}
				required={true}
				value={state?.zone_name}
				placeholder={getTranslation('enterZoneName')}
				onChange={(e) => setState({ ...state, zone_name: e.target.value })}
			/>

			<CustomFormInput
				title={getTranslation('region')}
				required={true}
				value={state?.region}
				placeholder={getTranslation('enterRegion')}
				onChange={(e) => setState({ ...state, region: e.target.value })}
			/>

			<RInputDeclare
				value={getTranslation('shippingMethods')}
				className="!mb-1 !mt-2 !text-black1 font-medium text-base"
			/>
			<CustomSelect
				values={listShippingMethods}
				value={listShippingMethods.filter(
					(item) => shippingMethods.findIndex((a) => a === item.value) !== -1
				)}
				isMultiple={true}
				placeholder={getTranslation('selectShippingMethods')}
				renderRow={(item) => <p>{item.title}</p>}
				renderValue={(item) => <span>{item?.title}</span>}
				handleChange={(v) => {
					let tmp = shippingMethods.slice(0)
					tmp.push(v.value)
					setshippingMethods(tmp)
				}}
				onRemoveItem={(v) => {
					let tmp = shippingMethods.slice(0)
					let index = tmp.findIndex((t) => t === v.value)
					if (index !== -1) {
						tmp.splice(index, 1)
					}
					setshippingMethods(tmp)
				}}
			/>

			{shippingMethods.some((item) => item === 'free_shipping') && (
				<CustomFormSelect
					title={getTranslation('freeShippingRequires')}
					value={state.has_minimum_order_amount}
					onChange={(event) => {
						setState({
							...state,
							has_minimum_order_amount: event.target.value,
						})
					}}
					options={listFreeshippingMethods.map((item) => ({
						label: item.title,
						value: item.value,
					}))}
				/>
			)}

			{shippingMethods.some((item) => item === 'free_shipping') && (
				<CustomFormInput
					required={true}
					value={state.minimum_order_amount}
					onChange={(e) =>
						setState({ ...state, minimum_order_amount: e.target.value })
					}
					title={getTranslation('minimumOrderAmount')}
					type="number"
				/>
			)}
			{shippingMethods.some((item) => item === 'flat_rate') && (
				<CustomFormInput
					required={true}
					value={state.flat_rate_cost}
					onChange={(e) =>
						setState({ ...state, flat_rate_cost: e.target.value })
					}
					title={getTranslation('flatRateCost')}
					type="number"
				/>
			)}
		</div>
	)

	return (
		<GenericModal
			open={showModal}
			icon={creditIcon}
			{...{ onClose, onSubmit, children, loading }}
			saveText={
				data?.id != null ? getTranslation('update') : getTranslation('create')
			}
			title={
				data?.id != null
					? getTranslation('editShippingZone')
					: getTranslation('addShippingZone')
			}
		/>
	)
}
