import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { getAppToken } from '../utils/storage'

function AuthMiddleware({ children }) {
	let location = useLocation()
	const { pricing_plan } = useSelector((state) => state.app)

	// TODO: 2nd release (fix this logic of protected routes based on pricing plans)
	// const isLinkInPlan = useMemo(()=>{

	// 	if (pricing_plan?.features == null || pricing_plan?.sub_features == null) {
	// 		return false;
	// 	}
	// 	const [e, base, feature_path, sub_feature_path] = location.pathname.split('/');
	//
	// 	if (isEmpty(feature_path)) {
	// 		return true;
	// 	}
	// 	else {
	// 		if (DEFAULT_FEATURE_LINKS.includes(feature_path)) {
	// 			return true;
	// 		}
	// 		const featureIndex = pricing_plan.features.findIndex(f => f.link == feature_path);
	// 		if (featureIndex == -1) {
	// 			return false;
	// 		}
	//
	// 		if (isEmpty(sub_feature_path)) {
	// 			return true;
	// 		}
	// 		else {
	// 			const subFeatureIndex = pricing_plan?.sub_features.findIndex(sf => (
	// 				sf.feature_id == pricing_plan.features[featureIndex].id &&
	// 				sf.link == sub_feature_path
	// 			));
	//
	// 			if (subFeatureIndex == -1) {
	// 				return false;
	// 			}
	// 			else {
	// 				return true;
	// 			}
	// 		}
	// 	}
	// 	return true;
	// }, [location.pathname, pricing_plan])

	if (!getAppToken()) {
		return <Navigate to={'/'} state={{ from: location }} replace />
	} else {
		// if (isLinkInPlan) {
		return children
		// }
		// return <Navigate to={"/404"} state={{ from: location }} replace />;
	}
}

export default AuthMiddleware
