import React, { useEffect, useState } from "react";
import { Map, Marker } from "google-maps-react";
import Geocode from "react-geocode";

import "./index.css";

const MapContainer = (props) => {
  const [map, setMap] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [lng, setLng] = useState( props?.longitude ?? 19.8146872);
  const [lat, setLat] = useState( props?.latitude ?? 41.3177519);

  const onMarkerClick = (props, marker, e) => {
    setActiveMarker(marker);
    setSelectedPlace(props);
    setShowingInfoWindow(true);
  };
  // useEffect(() => {
  //     getCoordinate(props.address);
  // }, [props.address, props.lat, props.lng]);
  // const getCoordinate = (address) => {
  //   Geocode.setApiKey("AIzaSyANrrODv_Yku21BTu398EtAjWcfn1U4VNY");
  //   // Geocode.
  //   Geocode.fromAddress(address).then(
  //
  //     (response) => {
  //       // TODO: fix why geocoding is not happening properly
  //       const { lat, lng } = response.result[0].geometry.location;
  //       setLng(lng);
  //       setLat(lat);
  //
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // };

  const onMarkerDragend = (coord, _, gm) => {
    // const lat = coord.lat();
    // const lng = coord.lng();
    console.log({ coord });

    // Now you can use the lat and lng values for reverse geocoding or any other purpose.
  };

  const onMapClicked = (props) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  return (
    <Map
      google={window.google}
      zoom={20}
      initialCenter={{ lat: lat, lng: lng }}
      onReady={(mapProps, map) => setMap(map)}
      onClick={onMapClicked}
      className="googlemap"
    >
      <Marker draggable={true}   onDragend={onMarkerDragend}  onClick={onMarkerClick} name={"Current location"} />
    </Map>
  );
};

export default MapContainer;

