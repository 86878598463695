import { IconButton } from '@mui/material'
import moment from 'moment'
import editIcon from '../../../assets/svgs/edit.svg'
import trashIcon from '../../../assets/svgs/trash.svg'

const status = ({ data }) => {
	return (
		<span
			className={`text-green2 text-base px-3 py-1 rounded-full ${
				data === 1 ? 'bg-[#ECFDF3]' : 'bg-[#ECFDF3]'
			}`}
		>
			●&nbsp;&nbsp;
			{data === 1 ? 'Active' : 'Inactive'}
		</span>
	)
}

export const columns = (
	categoryText,
	venueType,
	_curCategory,
	setDeleteModal,
	setShowModal
) => [
	{ field: 'no', label: categoryText('no'), align: 'left' },
	{ field: 'title', label: categoryText('name'), align: 'left' },
	{ field: 'description', label: categoryText('description'), align: 'left' },
	...(venueType === 'sport_essentials' ||
	venueType === 'fashion_threads' ||
	venueType === 'pharmacy' ||
	venueType === 'home_decor'
		? [
				{
					field: 'parent',
					label: categoryText('parent_category'),
					align: 'left',
				},
		  ]
		: []),
	{
		field: 'created_at',
		label: categoryText('created_at'),
		align: 'left',
		cellComponent: ({ data }) => moment(data).format('MMMM Do YYYY, h:mm:ss a'),
	},
	{
		field: 'status',
		label: categoryText('status'),
		align: 'left',
		cellComponent: status,
	},
	{
		field: 'actions',
		label: categoryText('actions'),
		align: 'left',
		cellComponent: ({ data }) => (
			<>
				<IconButton
					color="secondary"
					onClick={() => {
						_curCategory.current = data
						setDeleteModal(true)
					}}
				>
					<img src={trashIcon} alt="trash" />
				</IconButton>
				<IconButton
					color="secondary"
					onClick={() => {
						_curCategory.current = data
						setShowModal(true)
					}}
				>
					<img src={editIcon} alt="edit" />
				</IconButton>
			</>
		),
	},
]
