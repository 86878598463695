import { Card, CardContent } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import randomToken from 'random-token'
import React, { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { Link } from 'react-router-dom'
import EstimateModal from '../../../components/waitlist/estimateModal'
import GuestHistoryModal from '../../../components/waitlist/guestHistoryModal'
import NewWaitlistModal from '../../../components/waitlist/newWaitlist'
import UpdateWaitlistModal from '../../../components/waitlist/updateWaitlist'
import waitlistService from '../../../services/waitlistService'

import { useSelector } from 'react-redux'
import newWaitIcon from '../../../assets/svgs/new_wait.svg'
import plusIcon from '../../../assets/svgs/plus.svg'
import tableIcon from '../../../assets/svgs/table_black.svg'
import updateWaitIcon from '../../../assets/svgs/update_wait.svg'
import userIcon from '../../../assets/svgs/user_black.svg'
import AreYouSureModal from '../../../components/common/areYouSureModal'

export default function WaitList() {
	const [data, setData] = useState([])
	const { short_code } = useSelector((state) => state.app)

	const [historyVisible, setHistoryVisible] = useState(false)
	const [newVisible, setNewVisible] = useState(false)
	const [updateVisible, setUpdateVisible] = useState(false)
	const [estimateVisible, setEstimateVisible] = useState(false)
	const _curindex = useRef(null)
	const _curitem = useRef(null)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const [isAreYouSureModalOpen, setAreYouSureModalOpen] = useState(false)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		refreshData()
	}, [])

	const refreshData = () => {
		waitlistService.GetAll().then((res) => {
			setData(res)
		})
	}

	const handlePrioritize = () => {
		setAreYouSureModalOpen(true)
	}

	return (
		<div>
			<div className="flex flex-col h-full p-6 waitlist">
				<Card>
					<CardContent className="!p-0">
						<div className="flex flex-row justify-between items-center pl-4 pr-3 py-5">
							<div className="flex flex-row items-center">
								<span className="text-black1 text-xl font-medium">
									Waitlist Management
								</span>
							</div>
							<div className="flex flex-row gap-4 items-center">
								<div
									className="border border-black1 bg-white flex flex-row px-6 py-2 text-black1 text-sm rounded-lg cursor-pointer"
									onClick={handlePrioritize}
								>
									<span>Prioritize Guests</span>
								</div>
								<div
									className="border border-black1 bg-white flex flex-row px-6 py-2 text-black1 text-sm rounded-lg cursor-pointer"
									onClick={() => setHistoryVisible(true)}
								>
									<span>Guests History</span>
								</div>
								<div
									className="bg-black1 flex flex-row px-6 py-2 text-white text-sm whitespace-nowrap rounded-lg cursor-pointer"
									onClick={() => setNewVisible(true)}
								>
									<img src={plusIcon} alt="plus" className="mr-2" />
									<span>Add new waitlist</span>
								</div>
							</div>
						</div>
						<div className="flex flex-col px-6 pt-6 bg-[#F9F7F7]  overflow-auto">
							{data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<TableContainer component={Paper} className="mb-6">
										<Table
											sx={{ minWidth: 650 }}
											className="border !rounded-b-md"
											aria-label="simple table"
										>
											<TableHead>
												<TableRow
													key={randomToken(16)}
													className="!h-11 !bg-gray-100"
												>
													<TableCell
														className="!text-gray-500 !text-left font-medium"
														align="left"
													>
														Party Size
													</TableCell>
													<TableCell
														className="!text-gray-500 !text-left font-medium"
														align="left"
													>
														Estimated Wait Time
													</TableCell>
													<TableCell
														className="!text-gray-500 !text-left font-medium"
														align="right"
													>
														Reservation
													</TableCell>
													<TableCell
														className="!text-gray-500 !text-left font-medium"
														align="left"
													>
														Is Regular
													</TableCell>
													<TableCell
														className="!text-gray-500 !text-left font-medium w-24"
														align="right"
													>
														Action
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow key={randomToken(16)}>
													<TableCell align="left" className="!text-left">
														{row.party_size}
													</TableCell>
													<TableCell align="left" className="!text-left">
														{Math.floor(row.estimated_wait_time / 60)}&nbsp;
														{Math.floor(row.estimated_wait_time / 60) > 1
															? 'hours'
															: 'hour'}
														,&nbsp;
														{row.estimated_wait_time % 60}&nbsp;
														{row.estimated_wait_time % 60 > 1
															? 'minutes'
															: 'minute'}
													</TableCell>
													<TableCell align="left" className="!text-left ">
														<Link
															to={`/${short_code}/admin/reservation/reservations/${row.reservation_id}`}
															className="underline text-black1 text-base font-semibold"
															aria-disabled={!row.reservation_id}
														>
															View Reservation
														</Link>
													</TableCell>
													<TableCell align="left" className="!text-left">
														{row.is_vip ? 'NO' : 'YES'}
													</TableCell>
													<TableCell
														align="left"
														className="!text-left !flex flex-row"
													>
														<div
															className="cursor-pointer"
															onClick={(e) => {
																setEstimateVisible(true)
																_curindex.current = row.id
															}}
														>
															<img src={newWaitIcon} alt="newWait" />
														</div>
														<div
															className="cursor-pointer"
															onClick={(e) => {
																setUpdateVisible(true)
																_curitem.current = row
															}}
														>
															<img src={updateWaitIcon} alt="updateWait" />
														</div>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
										<div className="flex flex-col py-4 border border-t-0">
											<div className="flex flex-row justify-between items-center shadow-sm p-4 w-full">
												<div className="flex flex-row gap-5">
													<div className="flex items-center justify-center p-2 bg-gray-200 rounded-lg">
														<img src={userIcon} alt="user" />
													</div>
													<div className="flex flex-col">
														<span className="text-gray5 text-xs">Name</span>
														<span className="text-black1 text-md font-medium">
															{row.guest_name}
														</span>
													</div>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">Email</span>
													<span className="text-black1 text-md font-medium">
														{row.guest_email}
													</span>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">Phone</span>
													<span className="text-black1 text-md font-medium">
														{row.guest_phone}
													</span>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">
														Guest notified at
													</span>
													<span className="text-black1 text-md font-medium">
														{row.guest_notified_at
															? moment(row.guest_notified_at).format(
																	'YYYY-MM-DD hh:mm:ss'
															  )
															: '-'}
													</span>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">VIP status</span>
													<span className="text-black1 text-md font-medium">
														{row.is_vip ? 'YES' : 'NO'}
													</span>
												</div>
											</div>
											<div className="flex flex-row justify-between items-center shadow-sm p-4 w-full mt-5">
												<div className="flex flex-row gap-5">
													<div className="flex items-center justify-center p-2 bg-gray-200 rounded-lg">
														<img src={tableIcon} alt="table" />
													</div>
													<div className="flex flex-col">
														<span className="text-gray5 text-xs">Added at</span>
														<span className="text-black1 text-md font-medium">
															{moment(row.added_at).format(
																'YYYY-MM-DD hh:mm:ss'
															)}
														</span>
													</div>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">Seat time</span>
													<span className="text-black1 text-md font-medium">
														{row.seat_time
															? moment(row.seat_time).format(
																	'YYYY-MM-DD hh:mm:ss'
															  )
															: '-'}
													</span>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">
														Arrivial time
													</span>
													<span className="text-black1 text-md font-medium">
														{row.arrival_time
															? moment(row.arrival_time).format(
																	'YYYY-MM-DD hh:mm:ss'
															  )
															: '-'}
													</span>
												</div>
												<div className="flex flex-col">
													<span className="text-gray5 text-xs">Left time</span>
													<span className="text-black1 text-md font-medium">
														{row.left_time
															? moment(row.left_time).format(
																	'YYYY-MM-DD hh:mm:ss'
															  )
															: '-'}
													</span>
												</div>
											</div>
										</div>
									</TableContainer>
								))}
							{!data.length && (
								<div className="mt-[60px] align-middle text-gray-500">
									{' '}
									NO DATA{' '}
								</div>
							)}
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</div>
					</CardContent>
				</Card>
				{historyVisible && (
					<GuestHistoryModal
						showModal={historyVisible}
						onClose={() => setHistoryVisible(false)}
					/>
				)}
				{estimateVisible && (
					<EstimateModal
						showModal={estimateVisible}
						onClose={() => setEstimateVisible(false)}
						index={_curindex.current}
					/>
				)}
				{newVisible && (
					<NewWaitlistModal
						showModal={newVisible}
						onClose={() => setNewVisible(false)}
						onConfirm={refreshData}
					/>
				)}
				{updateVisible && (
					<UpdateWaitlistModal
						showModal={updateVisible}
						onClose={() => setUpdateVisible(false)}
						item={_curitem.current}
						onConfirm={refreshData}
					/>
				)}

				{isAreYouSureModalOpen && (
					<AreYouSureModal
						title="Prioritize guests"
						content="Are you sure you want to prioritize guests in the waitlist?"
						firstButton={'No, cancel'}
						secondButton={'Yes, I am'}
						showModal={isAreYouSureModalOpen}
						onClose={() => setAreYouSureModalOpen(false)}
						onSubmit={() =>
							waitlistService
								.PrioritizeGuest()
								.then((res) => {
									setData(res)
									NotificationManager.success('Success')
								})
								.catch((e) => {
									NotificationManager.error('Failed')
								})
						}
					/>
				)}
			</div>
		</div>
	)
}
