import { TextField } from '@mui/material'
import React from 'react'

import plusIcon from '../../assets/svgs/plus_gray.svg'
import trashIcon from '../../assets/svgs/trash.svg'
import { productText } from '../../views/menu/products'

const ProductOption = ({
	isAddition = false,
	data,
	enableDelete = true,
	onChange,
	onAdd = () => {},
	onRemove,
}) => {
	return (
		<div className="flex flex-row items-end w-full mb-4 gap-2">
			<div className="flex flex-col justify-start items-center flex-1">
				<label className="text-gray5 text-sm w-full mb-1">
					{productText('variation')}
				</label>
				<TextField
					required
					label=""
					size="small"
					value={data?.title}
					className="w-full"
					onChange={(e) => onChange({ ...data, title: e.target.value })}
					placeholder={productText('enterVariation')}
				/>
			</div>
			{isAddition === true && (
				<div className="flex flex-col justify-start items-center md:w-[160px] w-[80px] ml-2">
					<label className="text-gray5 text-sm w-full mb-1">
						{productText('price')}
					</label>
					<TextField
						required
						label=""
						size="small"
						type="number"
						value={data?.price}
						className="w-full"
						onChange={(e) => onChange({ ...data, price: e.target.value })}
						placeholder={productText('enterPrice')}
					/>
				</div>
			)}
			<img
				src={plusIcon}
				alt="plus"
				className="p-2 rounded-md border border-black1 h-10 cursor-pointer"
				onClick={() => onAdd()}
			/>
			<img
				src={trashIcon}
				alt="trash"
				className={`p-2 rounded-md border border-black1 h-10 cursor-pointer ${
					!enableDelete && 'bg-gray6'
				}`}
				onClick={() => enableDelete && onRemove()}
			/>
		</div>
	)
}

export default ProductOption
