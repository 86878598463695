import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import LoadingScreen from '../../components/custom/LoadingScreen'
import { SeatingArrangementView } from '../../components/table/SeatingArrangementView'
import NewSeatingArrangement from '../../components/table/newSeatingArrangement'
import TableService from '../../services/tableService'

import { t } from 'i18next'
import plusIcon from '../../assets/svgs/plus.svg'
import GenericPagination from '../../components/generic-table/generic-pagination'
import TableEmptyData from '../../components/table/TableEmptyData'

export const seatingArrangementText = (text) =>
	t(`tables.SeatingArrangement.${text}`)

export function SeatingArrangementScreen() {
	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [addModal, setAddModal] = useState(false)
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		refreshData()
	}, [])

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const refreshData = () => {
		TableService.GetSeatingArrangements()
			.then((res) => {
				setLoaded(true)
				setData(res.data)
			})
			.catch(() => {
				setLoaded(true)
			})
	}

	const handleCreate = () => {
		setAddModal(true)
	}

	return (
		<div>
			<div className="flex flex-col h-full">
				{loaded ? (
					<div className="flex flex-col p-6 overflow-auto">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center px-6 py-5">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{seatingArrangementText('seating_arrangement')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{data.length}{' '}
											{seatingArrangementText('seating', 'seating')}
										</span>
									</div>
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={handleCreate}
									>
										<img src={plusIcon} alt="search" className="mr-2" />
										<span>
											{seatingArrangementText('add_seating_arrangement')}
										</span>
									</div>
								</div>
								{data.length > 0 ? (
									<>
										<SeatingArrangementView data={data} refresh={refreshData} />

										<GenericPagination
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											count={data.length}
											rowsPerPage={rowsPerPage}
											page={page}
											setPage={setPage}
										/>
									</>
								) : (
									<div className="border border-x-0 border-b-0 mt-5 mb-10">
										<TableEmptyData
											content={seatingArrangementText(
												'seating_arrangements',
												'Seating Arrangements'
											)}
											btnLabel={seatingArrangementText(
												'add_seating_arrangement'
											)}
											onAction={handleCreate}
										/>
									</div>
								)}
							</CardContent>
						</Card>
					</div>
				) : (
					<LoadingScreen />
				)}
			</div>
			<NewSeatingArrangement
				showModal={addModal}
				onClose={() => setAddModal(false)}
				refresh={refreshData}
			/>
		</div>
	)
}
