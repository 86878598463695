import * as Config from './config';

import login1Img from '../assets/images/login/login_1.png';
import login2Img from '../assets/images/login/login_2.png';
import login3Img from '../assets/images/login/login_3.png';
import login4Img from '../assets/images/login/login_4.png';
import login5Img from '../assets/images/login/login_5.png';
import login6Img from '../assets/images/login/login_6.png';


import AcmImg from '../assets/images/login/accommodation_venue_admin_bg.png';
import EvImg from '../assets/images/login/entertainment_venue_admin_bg.png';
import FbImg from '../assets/images/login/login_2.png';
import RmImg from '../assets/images/login/retail_management_venue_admin_bg.png';

import forkIcon from '../assets/svgs/fork.svg';
import forkWhiteIcon from '../assets/svgs/fork_white.svg';

// Food & Beverage
import cupIcon from '../assets/svgs/cup.svg';
import cupWhiteIcon from '../assets/svgs/cup_white.svg';
import deskIcon from '../assets/svgs/desk.svg';
import deskWhiteIcon from '../assets/svgs/desk_white.svg';
import foodFoodTruckWhiteIcon from '../assets/svgs/food-truck-white.svg';
import foodFoodTruckIcon from '../assets/svgs/food-truck.svg';
import juiceIcon from '../assets/svgs/juice.svg';
import juiceWhiteIcon from '../assets/svgs/juice_white.svg';
import FoodBakeriesWhite from "../assets/svgs/navbaricons/bakery-white.svg";
import FoodBakeries from "../assets/svgs/navbaricons/bakery.svg";
import FoodBuffetsWhite from "../assets/svgs/navbaricons/buffet-white.svg";
import FoodBuffets from "../assets/svgs/navbaricons/buffet.svg";
import FoodCateringServicesWhite from "../assets/svgs/navbaricons/catering-white.svg";
import FoodCateringServices from "../assets/svgs/navbaricons/catering.svg";
import FoodFastFoodJointsWhite from "../assets/svgs/navbaricons/ff-joints-white.svg";
import FoodFastFoodJoints from "../assets/svgs/navbaricons/ff-joints.svg";
import FoodFoodStallsWhite from "../assets/svgs/navbaricons/foodstall-white.svg";
import FoodFoodStalls from "../assets/svgs/navbaricons/foodstall.svg";
import FoodIceCreamParlorsWhite from "../assets/svgs/navbaricons/icecream-white.svg";
import FoodIceCreamParlors from "../assets/svgs/navbaricons/icecream.svg";
import FoodJuiceBarsWhite from "../assets/svgs/navbaricons/juicebars-white.svg";
import FoodJuiceBars from "../assets/svgs/navbaricons/juicebars.svg";
import NewMenuCoffeShopsWhite from "../assets/svgs/navbaricons/new-menu-coffe-shops-white.svg";
import NewMenuCoffeShops from "../assets/svgs/navbaricons/new-menu-coffe-shops.svg";
import NewMenuDelisWhite from "../assets/svgs/navbaricons/new-menu-delis-white.svg";
import NewMenuDelis from "../assets/svgs/navbaricons/new-menu-delis.svg";
import NewMenuFoodCourtsWhite from "../assets/svgs/navbaricons/new-menu-foodcourts-white.svg";
import NewMenuFoodCourts from "../assets/svgs/navbaricons/new-menu-foodcourts.svg";
import NewMenuPizzaWhite from "../assets/svgs/navbaricons/new-menu-pizza-white.svg";
import NewMenuPizza from "../assets/svgs/navbaricons/new-menu-pizza.svg";
import FoodSupperClubsWhite from "../assets/svgs/navbaricons/party-white.svg";
import FoodSupperClubs from "../assets/svgs/navbaricons/party.svg";
import FoodPopUpRestaurantsWhite from "../assets/svgs/navbaricons/popup-white.svg";
import FoodPopUpRestaurants from "../assets/svgs/navbaricons/popup.svg";
import FoodFoodHallsWhite from "../assets/svgs/navbaricons/stall-white.svg";
import FoodFoodHalls from "../assets/svgs/navbaricons/stall.svg";
import teaIcon from '../assets/svgs/tea.svg';
import teaWhiteIcon from '../assets/svgs/tea_white.svg';


// Retail Management
import RetailBoutiquesAndSpecialtyStoresWhite from "../assets/svgs/navbaricons/boutique-white.svg";
import RetailBoutiquesAndSpecialtyStores from "../assets/svgs/navbaricons/boutique.svg";
import RetailAutoPartsStoresWhite from "../assets/svgs/navbaricons/car-white.svg";
import RetailAutoPartsStores from "../assets/svgs/navbaricons/car.svg";
import RetailRetailChainsWhite from "../assets/svgs/navbaricons/chain-white.svg";
import RetailRetailChains from "../assets/svgs/navbaricons/chain.svg";
import RetailElectronicsStoresWhite from "../assets/svgs/navbaricons/electronics-white.svg";
import RetailElectronicsStores from "../assets/svgs/navbaricons/electronics.svg";
import RetailGroceryStoresWhite from "../assets/svgs/navbaricons/groceries-white.svg";
import RetailGroceryStores from "../assets/svgs/navbaricons/groceries.svg";
import RetailLiquorStoresWhite from "../assets/svgs/navbaricons/liquior-white.svg";
import RetailLiquorStores from "../assets/svgs/navbaricons/liquior.svg";
import RetailPetSupplyStoresWhite from "../assets/svgs/navbaricons/pet-white.svg";
import RetailPetSupplyStores from "../assets/svgs/navbaricons/pet.svg";
import RetailPharmaciesWhite from "../assets/svgs/navbaricons/pharma-white.svg";
import RetailPharmacies from "../assets/svgs/navbaricons/pharma.svg";
import RetailFashionWhite from "../assets/svgs/navbaricons/retail-fashion-white.svg";
import RetailFashion from "../assets/svgs/navbaricons/retail-fashion.svg";
import RetailHomeWhite from "../assets/svgs/navbaricons/retail-home-white.svg";
import RetailHome from "../assets/svgs/navbaricons/retail-home.svg";
import RetailSportWhite from "../assets/svgs/navbaricons/retail-sport-white.svg";
import RetailSport from "../assets/svgs/navbaricons/retail-sport.svg";
import RetailOnlineRetailersWhite from "../assets/svgs/navbaricons/retail-white.svg";
import RetailOnlineRetailers from "../assets/svgs/navbaricons/retail.svg";

// Accommodation
import accommodationCozyRetreatWhiteIcon from '../assets/svgs/accommodation-cozy-retreat-white.svg';
import accommodationCozyRetreatIcon from '../assets/svgs/accommodation-cozy-retreat.svg';
import accommodationHostelWhiteIcon from '../assets/svgs/accommodation-hostel-white.svg';
import accommodationHostelIcon from '../assets/svgs/accommodation-hostel.svg';
import accommodationHotelChainWhiteIcon from '../assets/svgs/accommodation-hotelchain-white.svg';
import accommodationHotelChainIcon from '../assets/svgs/accommodation-hotelchain.svg';
import accommodationVacationRentalWhiteIcon from '../assets/svgs/accommodation-vacation-white.svg';
import accommodationVacationRentalIcon from '../assets/svgs/accommodation-vacation.svg';
import apartIcon from '../assets/svgs/apart_dark.svg';
import apartWhiteIcon from '../assets/svgs/apart_white.svg';
import AccommodationLuxuryResortsWhite from "../assets/svgs/navbaricons/luxuryresort-white.svg";
import AccommodationLuxuryResorts from "../assets/svgs/navbaricons/luxuryresort.svg";
import NewMenuAptWhite from "../assets/svgs/navbaricons/new-menu-apt-white.svg";
import NewMenuApt from "../assets/svgs/navbaricons/new-menu-apt.svg";
import NewMenuBedAndBreakfastWhite from "../assets/svgs/navbaricons/new-menu-bedandbreakfast-white.svg";
import NewMenuBedAndBreakfast from "../assets/svgs/navbaricons/new-menu-bedandbreakfast.svg";
import NewMenuCampGroundWhite from "../assets/svgs/navbaricons/new-menu-campground-white.svg";
import NewMenuCampGround from "../assets/svgs/navbaricons/new-menu-campground.svg";
import NewMenuCapsuleHotelsWhite from "../assets/svgs/navbaricons/new-menu-capsule-hotels-white.svg";
import NewMenuCapsuleHotels from "../assets/svgs/navbaricons/new-menu-capsule-hotels.svg";
import NewMenuHostelWhite from "../assets/svgs/navbaricons/new-menu-hostel-white.svg";
import NewMenuHostel from "../assets/svgs/navbaricons/new-menu-hostel.svg";
import NewMenuHotelWhite from "../assets/svgs/navbaricons/new-menu-hotel-white.svg";
import NewMenuHotel from "../assets/svgs/navbaricons/new-menu-hotel.svg";
import NewMenuMotelWhite from "../assets/svgs/navbaricons/new-menu-motel-white.svg";
import NewMenuMotel from "../assets/svgs/navbaricons/new-menu-motel.svg";
import NewMenuRVParksWhite from "../assets/svgs/navbaricons/new-menu-rvparks-white.svg";
import NewMenuRVParks from "../assets/svgs/navbaricons/new-menu-rvparks.svg";

// Entertainment Venues
import SportArcadesAndGameCentersWhite from "../assets/svgs/navbaricons/aracde-white.svg";
import SportArcadesAndGameCenters from "../assets/svgs/navbaricons/aracde.svg";
import SportConcertVenuesAndTheatersWhite from "../assets/svgs/navbaricons/concert-white.svg";
import SportConcertVenuesAndTheaters from "../assets/svgs/navbaricons/concert.svg";
import NewMenuCinemaWhite from "../assets/svgs/navbaricons/new-menu-cinema-white.svg";
import NewMenuCinema from "../assets/svgs/navbaricons/new-menu-cinema.svg";
import NewMenuGolfWhite from "../assets/svgs/navbaricons/new-menu-golf-white.svg";
import NewMenuGolf from "../assets/svgs/navbaricons/new-menu-golf.svg";
import NewMenuMuseumWhite from "../assets/svgs/navbaricons/new-menu-museum-white.svg";
import NewMenuMuseum from "../assets/svgs/navbaricons/new-menu-museum.svg";
import SportAmusementParksWhite from "../assets/svgs/navbaricons/park-white.svg";
import SportAmusementParks from "../assets/svgs/navbaricons/park.svg";
import SportSkiResortsWhite from "../assets/svgs/navbaricons/ski-white.svg";
import SportSkiResorts from "../assets/svgs/navbaricons/ski.svg";
import SportBowlingWhite from "../assets/svgs/navbaricons/sport-bowling-white.svg";
import SportBowling from "../assets/svgs/navbaricons/sport-bowling.svg";
import SportGymWhite from "../assets/svgs/navbaricons/sport-gym-white.svg";
import SportGym from "../assets/svgs/navbaricons/sport-gym.svg";
import SportSportsComplexesWhite from "../assets/svgs/navbaricons/sportcomplex-white.svg";
import SportSportsComplexes from "../assets/svgs/navbaricons/sportcomplex.svg";

// For future
import healthcareDentalWhiteIcon from '../assets/svgs/healthcare-dental-white.svg';
import healthcareDentalIcon from '../assets/svgs/healthcare-dental.svg';

export { Config };

export const bgImages = {
  restaurant: login2Img,
  hotel: login1Img,
  bistro: login5Img,
  golf_venue: login6Img,
  bar: login3Img,
  pub_club: login5Img,
  cafe: login4Img,
}

export const industryImages = {
  fb: FbImg,
  accommodation: AcmImg,
  entertainment_venue: EvImg,
  retail_management: RmImg,
}

export const serviceInfo = {

  // Food Icons
  restaurant: {
    icon: forkWhiteIcon,
    darkIcon: forkIcon
  },
  bistro: {
    icon:  cupWhiteIcon,
    darkIcon: cupIcon
  },
  cafe: {
    icon: teaWhiteIcon,
    darkIcon: teaIcon
  },
  bar: {
    icon: deskWhiteIcon,
    darkIcon: deskIcon
  },
  pub_club: {
    icon: juiceWhiteIcon,
    darkIcon: juiceIcon
  },
  food_truck: {
    icon: foodFoodTruckWhiteIcon,
    darkIcon: foodFoodTruckIcon
  },
  fine_dining: {
    icon: forkWhiteIcon,
    darkIcon: forkIcon
  },
  bakery: {
    icon: FoodBakeriesWhite,
    darkIcon: FoodBakeries
  },
  catering_service: {
    icon: FoodCateringServicesWhite,
    darkIcon: FoodCateringServices
  },
  fast_food: {
    icon: FoodFastFoodJointsWhite,
    darkIcon: FoodFastFoodJoints
  },
  ice_cream_parlor: {
    icon: FoodIceCreamParlorsWhite,
    darkIcon: FoodIceCreamParlors
  },
  food_stall: {
    icon: FoodFoodStallsWhite,
    darkIcon: FoodFoodStalls
  },
  buffet: {
    icon: FoodBuffetsWhite,
    darkIcon: FoodBuffets
  },
  pop_up_dining: {
    icon: FoodPopUpRestaurantsWhite,
    darkIcon: FoodPopUpRestaurants
  },
  juice_bar: {
    icon: FoodJuiceBarsWhite,
    darkIcon: FoodJuiceBars
  },
  food_hall: {
    icon: FoodFoodHallsWhite,
    darkIcon: FoodFoodHalls
  },
  supper_club: {
    icon: FoodSupperClubsWhite,
    darkIcon: FoodSupperClubs
  },
  pizzeria: {
    icon: NewMenuPizzaWhite,
    darkIcon: NewMenuPizza
  },
  coffee_shop: {
    icon: NewMenuCoffeShopsWhite,
    darkIcon: NewMenuCoffeShops
  },
  delis: {
    icon: NewMenuDelisWhite,
    darkIcon: NewMenuDelis
  },
  food_court: {
    icon: NewMenuFoodCourtsWhite,
    darkIcon: NewMenuFoodCourts
  },

  // Sports & Entertainment Icons
  golf_venue: {
    icon: NewMenuGolfWhite,
    darkIcon: NewMenuGolf
  },
  bowling: {
    icon: SportBowlingWhite,
    darkIcon: SportBowling
  },
  beach_bar: {
    icon: SportBowlingWhite,
    darkIcon: SportBowling
  },
  gym: {
    icon: SportGymWhite,
    darkIcon: SportGym
  },
  arcade_and_game_center: {
    icon: SportArcadesAndGameCentersWhite,
    darkIcon: SportArcadesAndGameCenters
  },
  sports_arena: {
    icon: SportSportsComplexesWhite,
    darkIcon: SportSportsComplexes
  },
  concert_hall_and_theater: {
    icon: SportConcertVenuesAndTheatersWhite,
    darkIcon: SportConcertVenuesAndTheaters
  },
  amusement_and_theme_park: {
    icon: SportAmusementParksWhite,
    darkIcon: SportAmusementParks
  },
  ski_resort: {
    icon: SportSkiResortsWhite,
    darkIcon: SportSkiResorts
  },
  museum: {
    icon: NewMenuMuseumWhite,
    darkIcon: NewMenuMuseum
  },
  cinema: {
    icon: NewMenuCinemaWhite,
    darkIcon: NewMenuCinema
  },
  // Retail Icons
  sport_essentials: {
    icon: RetailSportWhite,
    darkIcon: RetailSport
  },
  home_decor: {
    icon: RetailHomeWhite,
    darkIcon: RetailHome
  },
  fashion_threads: {
    icon: RetailFashionWhite,
    darkIcon: RetailFashion
  },
  pharmacy: {
    icon: RetailPharmaciesWhite,
    darkIcon: RetailPharmacies
  },
  retail_chain: {
    icon: RetailRetailChainsWhite,
    darkIcon: RetailRetailChains
  },
  specialty_store: {
    icon: RetailBoutiquesAndSpecialtyStoresWhite,
    darkIcon: RetailBoutiquesAndSpecialtyStores
  },
  online_shop: {
    icon: RetailOnlineRetailersWhite,
    darkIcon: RetailOnlineRetailers
  },
  grocery_store: {
    icon: RetailGroceryStoresWhite,
    darkIcon: RetailGroceryStores
  },
  electronics_store: {
    icon: RetailElectronicsStoresWhite,
    darkIcon: RetailElectronicsStores
  },
  auto_motorcycle_parts: {
    icon: RetailAutoPartsStoresWhite,
    darkIcon: RetailAutoPartsStores
  },
  liquor_store: {
    icon: RetailLiquorStoresWhite,
    darkIcon: RetailLiquorStores
  },
  pet_store: {
    icon: RetailPetSupplyStoresWhite,
    darkIcon: RetailPetSupplyStores
  },

  // Accommodation Icons
  hotel: {
    icon: NewMenuHotelWhite,
    darkIcon: NewMenuHotel
  },
  cozy_retreat: {
    icon: accommodationCozyRetreatWhiteIcon,
    darkIcon: accommodationCozyRetreatIcon
  },
  hostel: {
    icon: accommodationHostelWhiteIcon,
    darkIcon: accommodationHostelIcon
  },
  hotel_chain: {
    icon: accommodationHotelChainWhiteIcon,
    darkIcon: accommodationHotelChainIcon
  },
  vacation_rental: {
    icon: accommodationVacationRentalWhiteIcon,
    darkIcon: accommodationVacationRentalIcon
  },
  motel: {
    icon: NewMenuMotelWhite,
    darkIcon: NewMenuMotel
  },
  bed_and_breakfast: {
    icon: NewMenuBedAndBreakfastWhite,
    darkIcon: NewMenuBedAndBreakfast
  },
  service_apartment: {
    icon: NewMenuAptWhite,
    darkIcon: NewMenuApt
  },
  family_resort: {
    icon: apartWhiteIcon,
    darkIcon: apartIcon
  },
  capsule_hotel: {
    icon: NewMenuCapsuleHotelsWhite,
    darkIcon: NewMenuCapsuleHotels
  },
  luxury_resort: {
    icon: AccommodationLuxuryResortsWhite,
    darkIcon: AccommodationLuxuryResorts
  },
  youth_hostel: {
    icon: NewMenuHostelWhite,
    darkIcon: NewMenuHostel
  },
  campground: {
    icon: NewMenuCampGroundWhite,
    darkIcon: NewMenuCampGround
  },
  rv_park: {
    icon: NewMenuRVParksWhite,
    darkIcon: NewMenuRVParks
  },

  // Healthcare Icons
  dental_clinic: {
    icon: healthcareDentalWhiteIcon,
    darkIcon: healthcareDentalIcon
  },
};

export const ROLE_MANAGER = 1;
export const ROLE_OWNER = 2;
export const ROLE_HOTEL_OWNER = 5;
export const ROLE_HOTEL_MANAGER = 6;
export const ROLE_HOTEL_Restaurant_Manager = 9;
export const ROLE_GOLF_OWNER = 13;
export const ROLE_GOLF_MANAGER = 14;
export const ROLE_RETAIL_OWNER = 18;  // The person who owns the retail store
export const ROLE_RETAIL_MANAGER = 19;  // Responsible for overall operations, often reports to the owner
export const ROLE_VACATION_RENTAL_OWNER = 25;
export const ROLE_VACATION_RENTAL_MANAGER = 26;

export const MSG_TYPE_TEXT = 'text';
export const MSG_TYPE_EMOJI = 'emoji';
export const MSG_TYPE_IMG = 'image';

export const ORDER_METHOD = [
  'Delivery',
  'Pickup',
  'Reserve',
  'Delivery-Pickup',
  'Delivery-Reserve',
  'Pickup-Reserve',
  'Delivery-Pickup-Reserve'
];
