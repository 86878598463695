import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import saleIcon from '../../assets/svgs/sale_white.svg'
import { boxStyle } from '../../constants/types'
import beachbarService from '../../services/beachbar-service'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import CustomIconButton from '../common/IconButton'
import { RInputDeclare } from '../custom/RInputDeclare'

export const areaModalText = (text) =>
	t(`components.beachBar.areaModal.${text}`)

export default function AreaModal({
	showModal,
	setShowModal,
	onClose,
	recordDetails,
	setRecordDetails,
	getAllAreas,
}) {
	const [loading, setLoading] = useState(false)
	const [area, setArea] = useState(recordDetails?.unique_code)
	const { short_code } = useSelector((state) => state.app)

	const createBeachArea = () => {
		setLoading(true)
		beachbarService
			.createBeachbarArea({
				unique_code: area,
			})
			.then(() => {
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'beachbar',
				})
				NotificationManager.success(
					'Area has been created successfully',
					'Success',
					3000
				)
				setLoading(false)
				setShowModal(false)
				setRecordDetails(undefined)
				getAllAreas()
			})
			.catch((error) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'beachbar',
				})
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
			})
	}

	const updateBeachArea = (id) => {
		setLoading(true)
		beachbarService
			.updateBeachbarArea({
				unique_code: area,
				id,
			})
			.then(() => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'beachbar',
				})
				NotificationManager.success(
					'Area has been updated successfully',
					'Success',
					3000
				)
				setShowModal(false)
				setRecordDetails(undefined)
				getAllAreas()
			})
			.catch((error) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'beachbar',
				})
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
			})
	}

	const onClick = () => {
		if (recordDetails) {
			updateBeachArea(recordDetails.id)
		} else {
			createBeachArea()
		}
	}

	return (
		<div>
			<Modal
				open={showModal}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<CustomIconButton
						icon={saleIcon}
						title={
							recordDetails
								? areaModalText('edit_beach_bar_area')
								: areaModalText('new_beach_bar_area')
						}
					/>
					<div className="flex flex-col pt-0 p-024 mb-4">
						<RInputDeclare
							value={areaModalText('area_code')}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							required
							size="small"
							defaultValue={recordDetails?.unique_code}
							value={area?.unique_code}
							placeholder={areaModalText('enter_area_code')}
							onChange={(e) => setArea(e.target.value)}
						/>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{areaModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onClick}
						>
							{loading ? <CircularProgress size={16} /> : areaModalText('save')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
