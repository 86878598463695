import { faBars as menuIcon } from "@fortawesome/fontawesome-free-solid";
import { faBarsStaggered as menuOpenedIcon } from "@fortawesome/free-solid-svg-icons";
import StarBorderIcon from '@mui/icons-material/StarBorder'; // for outlined star
import { Box, Button, styled } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { visibleMenu } from "../store/modules/menuStore";


import { serviceInfo } from "../constants";
import { KEYS } from "../utils/storage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Svg_appicon from '../assets/images/logo.png';
import v2HelpIcon from '../assets/svgs/v2-help-standard.svg';
import v2OrderAndPayStandard from "../assets/svgs/v2-orderpay-white.svg";
import v2ReferralGreen from "../assets/svgs/v2-referal-white.svg";
import HelpfulModal from "../components/helpfulModal";
import { loadChatHistory } from "../store/actions/chatbot";

const UpgradeButton = styled(Button)(() => ({
	color: '#240b3b', // Normal text color
	backgroundColor: '#edeff8', // Normal background color
	'&:hover': {
		backgroundColor: '#240b3b', // replace with your color
		color: 'white', // Text color on hover
	},
	marginLeft: "6px",
	textTransform: "capitalize",
	fontWeight: "bold",
	fontSize: "15px"
}));

const StarWrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginRight: "2px",
	'& .MuiSvgIcon-root': {
		fontSize: '16px', // smaller size for the stars
	},
});
const Navbar = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const cookies = new Cookies();
	const restName = cookies.get(KEYS.VENUE_BASIC_INFO);

	const barTitle = useSelector(state => state.menu.bar);
	const barId = useSelector(state => state.menu.barId);
	const menuTitle = useSelector(state => state.menu.menu);
	const visible = useSelector(state => state.menu.visible);

	const { short_code, restaurant_list, is_trial_mode, subscription_plan } = useSelector(state => state.app);

	const [restOne, setRestOne] = useState(null);
	const [helpVisible, setHelpVisible] = useState(false);

	const [position, setPosition] = useState(0);
	const [isAnimating, setIsAnimating] = useState(true);
	const [canShowUpgrade, setCanShowUpgrade] = useState(false);
	const [canShowRefer, setCanShowRefer] = useState(false);

	// freemium (show timer, show upgrade, show refer)
	// starter (show upgrade, show refer)
	// growth (show upgrade, show refer)
	// enterprise (show upgrade)
	// custom (-)

	const redirectToRefer = () => {
		navigate(`/${short_code}/admin/marketing/referrals`);
	};

	const redirectToPlans = () => {
		navigate(`/${short_code}/admin/settings/subscription`);
	};


	useEffect(() => {
		let interval;
		let autoStopTimer;

		if (isAnimating) {
			interval = setInterval(() => {
				setPosition(prevPosition => (prevPosition === 0 ? -5 : 0));
			}, 350);

			// Set a timer to stop animation after 120 seconds
			autoStopTimer = setTimeout(() => {
				setIsAnimating(false);
				setPosition(0);
			}, 120000); // 120 seconds
		}

		return () => {
			clearInterval(interval);
			clearTimeout(autoStopTimer);
		};
	}, [isAnimating]);

	const iconStyle = {
		cursor: 'pointer',
		transform: `translateY(${position}px)`,
		transition: 'transform 0.5s ease-in-out', // Adjust for smoothness
		marginRight: '5px'
	};

	const iconStyleNoAnimation = {
		cursor: 'pointer',
		marginRight: '5px'
	};

	const handleIconClick = () => {
		setIsAnimating(!isAnimating);
		setPosition(0);
	};

	useEffect(() => {

		setCanShowRefer(subscription_plan?.name === 'Discover' || subscription_plan?.name === 'Launch' || subscription_plan?.name === 'Elevate');
		setCanShowUpgrade(subscription_plan?.name !== 'Enterprise');


		dispatch(loadChatHistory());
	}, [])

	useEffect(() => {
		if (restaurant_list && restaurant_list.length > 0) {
			const filtered = restaurant_list.find(item => item.short_code === short_code)
			setRestOne(filtered)
		}
	}, [short_code])

	const navigateToVenues = () => {
		navigate(`/${short_code}/admin/venues/venues`);
	}

	const handleToggleMenu = () => {
		if (barId !== 4 && barId !== 5) {
			dispatch(visibleMenu());
		}
	}

	return (
		// NAVBAR
		<>
			{is_trial_mode && (
				<div className="flex">
					<Box
						style={{ fontSize: '15px' }}
						className="flex relative h-[48px] z-50 bg-[#edeff8] border-b-2 border-[#E4E4E4] w-full justify-center py-3 px-[1%] text-[#19191C]"
					>
						You are on {subscription_plan?.name} Plan trial mode!
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<div onClick={redirectToPlans}>
								<UpgradeButton>
									<StarWrapper>
										<StarBorderIcon />
									</StarWrapper>
									Upgrade
								</UpgradeButton>
							</div>
						</Box>
					</Box>
				</div>
			)}
			<div className="flex">
				<div className="flex relative h-[76px] z-50 bg-white border-b-2 border-[#E4E4E4] w-full justify-between py-4 px-[1%] text-[#19191C]">
					{/* LOGO */}
					<div className="left inline-flex items-center">
						<div
							className="flex justify-center items-center cursor-pointer logo mx-6"
							onClick={() => handleToggleMenu()}
						>
							{/* <img src={menuIcon} className="w-8 h-8 mr-5" alt="" /> */}
							{visible ? (
								<FontAwesomeIcon icon={menuOpenedIcon} className="fa-lg" />
							) : (
								<FontAwesomeIcon icon={menuIcon} className="fa-lg" />
							)}
						</div>
						<img src={Svg_appicon} className="w-[185px] h-[31px]" alt="" />
					</div>
					<div className="right flex items-center gap-4">
						{/*<div className="px-1 rounded-[10px] bg-gray7 w-full" style={{background: "transparent", display: "flex"}}>*/}
						{/*	/!* Calendar Section *!/*/}
						{/*	<div className="flex-shrink-0">*/}
						{/*		<Tooltip title="Freemium Plan: 26 days remaining to experience VenueBoost for free." arrow>*/}
						{/*		<div className="flex flex-row justify-between justify-center items-center w-full p-2">*/}
						{/*			<div className="cursor-pointer">*/}
						{/*				<div style={iconStyle} onClick={handleIconClick}>*/}
						{/*					<img src={calendarIcon} alt="icon"/>*/}
						{/*				</div>*/}
						{/*			</div>*/}
						{/*			<div className="flex flex-row justify-start items-center">*/}
						{/*				<div className="text-xs font-bold cursor-pointer">*/}
						{/*					- 26 Days*/}
						{/*				</div>*/}
						{/*			</div>*/}
						{/*		</div>*/}
						{/*		</Tooltip>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{canShowUpgrade && (
							<div
								className="px-1 pr-4 rounded-[5px] bg-gray7 w-full"
								style={{
									background:
										'linear-gradient(to right, #240B3B, #3D1E59, #BC14FE)',
									display: 'flex',
								}}
							>
								{/* Upgrade Section */}
								<div className="flex-shrink-0">
									<Tooltip
										title="Elevate your experience - Upgrade for enhanced benefits!"
										arrow
									>
										<div className="flex flex-row w-full p-2">
											<div className="cursor-pointer">
												<div
													style={iconStyleNoAnimation}
													onClick={handleIconClick}
												>
													<img src={v2OrderAndPayStandard} alt="icon" />
												</div>
											</div>
											<div className="flex flex-row justify-start items-center">
												<div
													onClick={redirectToPlans}
													className="text-xs font-bold cursor-pointer text-white"
												>
													Upgrade
												</div>
											</div>
										</div>
									</Tooltip>
								</div>
							</div>
						)}
						{canShowRefer && (
							<div className="rounded-[5px] bg-[#3D1E59] w-full flex">
								{/* Refer Section */}
								<div className="flex-shrink-0">
									<Tooltip
										title="Empower your VenueBoost experience! Refer and unlock extra features. Learn more in your account settings."
										arrow
									>
										<div className="flex flex-row w-full p-2">
											<div className="cursor-pointer">
												<div
													style={iconStyleNoAnimation}
													onClick={handleIconClick}
												>
													<img src={v2ReferralGreen} alt="icon" />
												</div>
											</div>
											<div className="flex flex-row justify-start items-center">
												<div
													onClick={redirectToRefer}
													className="text-xs font-bold cursor-pointer text-white"
												>
													Refer
												</div>
											</div>
										</div>
									</Tooltip>
								</div>
							</div>
						)}
						{/*<img src={venueIcon} alt="venue" className="w-8 h-8 cursor-pointer" onClick={() => navigateToVenues()} />*/}
						{/* <LangSelector /> */}
						{/* <HelpOutline
							className="cursor-pointer !text-3xl text-gray5"
							onClick={() => setHelpVisible(true)}
						/> */}


						{/*Temporary Disable*/}
						{/*<div*/}
						{/*	className="flex justify-center items-center bg-white rounded-[5px] w-8 h-8 p-1 min-w-[2rem] cursor-pointer border-[#3D1E59] border"*/}
						{/*	onClick={() => setHelpVisible(true)}*/}
						{/*>*/}
						{/*	<img src={v2HelpIcon} alt="fork" className="w-4 h-4" />*/}
						{/*</div>*/}

						<div className="flex flex-row gap-2 items-center  flex-shrink-0">
							{restOne && (
								<>
									<div className="flex justify-center items-center bg-[#3D1E59] rounded-[5px] w-8 h-8 p-1">
										<img
											src={serviceInfo[restOne.venue_type.short_name].icon}
											alt="fork"
											className="w-4 h-4"
										/>
									</div>
									<div className="flex flex-col">
										<span className="text-black1 font-bold text-sm">
											{restOne.name}
										</span>
										<span className="text-gray5 text-xxs">{restName.role}</span>
									</div>
								</>
							)}
						</div>
						<HelpfulModal
							showModal={helpVisible}
							onClose={() => setHelpVisible(false)}
						/>
					</div>
				</div>
			</div>
		</>
	)
};
export default Navbar;
