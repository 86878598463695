import React, { useState } from 'react'
import SelectFilter from './select-filter'

export const getThisMonth = () => {
	const date = new Date()
	const start = new Date(date.getFullYear(), date.getMonth(), 1)
	const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
	const monthName = date.toLocaleString('default', { month: 'long' })
	const year = date.getFullYear()

	const formatDate = (date) => {
		return date.toLocaleDateString('en-CA') // Format as yyyy-mm-dd
	}

	return {
		start: formatDate(start),
		end: formatDate(end),
		monthName,
		year,
	}
}

const MonthDropdown = ({ selectedValue, setSelectedValue, className }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const handleChange = (selectedOption) => {
		setSelectedValue(selectedOption)
		setDropdownOpen(false)
	}

	const thisMonth = getThisMonth()
	const thisMonthOption = [
		{
			label: `This Month (${thisMonth.monthName} ${thisMonth.year})`,
			value: thisMonth,
		},
	]

	return (
		<SelectFilter
			toggleDropdown={toggleDropdown}
			dropdownOpen={dropdownOpen}
			items={thisMonthOption}
			selectedItem={
				selectedValue.label ||
				`This Month (${selectedValue.monthName} ${selectedValue.year})`
			}
			onChange={handleChange}
		/>
	)
}

export default MonthDropdown
