import { API_BRANDS, API_RETAIL } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const getBrands = async (params) => {
	let api = {
		content_type: 'application/json',
		url: `${API_RETAIL}/${API_BRANDS}`,
		method: 'GET',
	}
	return apiFactory(api, {}, params)
}

const deleteBrands = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_RETAIL}/${API_BRANDS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const updateBrands = async (id, payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_RETAIL}/${API_BRANDS}/${id}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const createBrands = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_RETAIL}/${API_BRANDS}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

export default {
	getBrands,
	createBrands,
	updateBrands,
	deleteBrands,
}
