import moment from "moment";

export const createUUID = () => {
  const s = [];
  const hexDigits = '0123456789ABCDEF';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  const uuid = s.join('');
  return uuid;
};

export const calcAverageOrderValue = (total_amount, num_orders) => {
  if (total_amount == null || num_orders == null) {
    return 0;
  }
  if (num_orders == 0) {
    return 0;
  }
  return Math.ceil(total_amount / num_orders);
};

export const calcGrowth = (curValue, prevValue) => {
  if (curValue == null || prevValue == null) {
    return 0;
  }
  const diff = curValue - prevValue;
  if (prevValue == 0) {
    if (curValue != 0) {
      return 100;
    }
    return 0;
  }
  return Math.ceil((diff * 100) / prevValue);
};

export const isEmpty = (string) => {
  if (string != null && string.length > 0) {
    return false;
  }
  return true;
};

export const ucFirst = (str) => {
  if (str === null || str === '') {
    return '';
  }
  return str.split(' ').map(s => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()).join(' ');
};

export const validateEmailAddress = (email) => {
  let reg =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const validatePhoneNumber = (phone) => {
  let reg =  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  return reg.test(phone);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getDateTimeFromTimeSpan(timespan) {
  return moment("2000-1-1 " + timespan);
}
