import apiFactory from "../utils/apiFactory";
import {API_ACCOUNTS, API_RETAIL, API_VENUE} from "../constants/API";
import venueApiFactory from "../utils/venueApiFactory";

export default class VenueService {
    static CheckManageVenue = async (type) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/check-manage-venue`,
          method: "POST",
        };
        return apiFactory(api, {type: type});
      }

      static GetCalendarReservations = async (payload) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/facilities/availability/calendar`,
          method: "POST",
        };
        return apiFactory(api, payload);
      }

      static CheckWebProfile = async (type) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/check-web-profile`,
          method: "GET",
        };
        return apiFactory(api, {type: type});
      }

      static GetUsageCreditFeatureHistory = async (type) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/usage-credits-history`,
          method: "GET",
        };
        return apiFactory(api, {type: type});
      }

  static WalletHistory = async (type) => {
    let api = {
      content_type: "application/json",
      url: `${API_ACCOUNTS.url}/wallet-history`,
      method: "GET",
    };
    return apiFactory(api, {type: type});
  }

  static CheckVenueConfiguration = async (type) => {
    let api = {
      content_type: "application/json",
      url: `${API_ACCOUNTS.url}/venue-configuration`,
      method: "GET",
    };
    return apiFactory(api, {type: type});
  }

    static saveVenueConfiguration = async (payload) => {
        let api = {
            content_type: "application/json",
            url: `${API_ACCOUNTS.url}/venue-configuration`,
            method: "POST",
        };
        return apiFactory(api, payload);
    };

  static createConnect = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_ACCOUNTS.url}/venue-create-connect-account`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };


    static uploadFacilitiesPhoto = async (payload) => {
        let api = {
          content_type: "multipart/form-data",
          url: `${API_ACCOUNTS.url}/facilities/upload-photo`,
          method: "POST",
        };
        return apiFactory(api, payload);
      }

      // upload-photo
      static uploadPhoto = async (payload) => {
        let api = {
          content_type: "multipart/form-data",
          url: `${API_ACCOUNTS.url}/upload-photo`,
          method: "POST",
        };
        return apiFactory(api, payload);
      }

      static deleteFacilitiesPhoto = async (id) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/photo/${id}`,
          method: "DELETE",
        };
        return apiFactory(api);
      }

      static UpdateVenueInformation = async (payload) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/facilities/information/update`,
          method: "PUT",
        };
        return apiFactory(api, payload);
      }

      static UpdateWebProfileInformation = async (payload) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/white-label/web-profile/update`,
          method: "PUT",
        };
        return apiFactory(api, payload);
      }
      static UpdateVenueAvailability = async (payload) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/facilities/availability/update`,
          method: "PUT",
        };
        return apiFactory(api, payload);
      }

      static UpdateVenueWhiteLabelAvailability = async (payload) => {
        let api = {
          content_type: "application/json",
          url: `${API_ACCOUNTS.url}/white-label/availability/update`,
          method: "PUT",
        };
        return apiFactory(api, payload);
      }
     
      static GetReservationsForCalendar = async (payload) => {
        let api = {
          content_type: "application/json",
          url: `${API_VENUE}/calendar-availability`,
          method: "POST",
        };
        return apiFactory(api, payload);
      }

      static GetAllVenues = async () => {
        let api = {
          content_type: "application/json",
          url: `${API_VENUE}/all-by-owner`,
          method: "GET",
        };
        return apiFactory(api);
      }

      static Create = async (rawData) => {
        let api = {
          content_type: "application/json",
          url: `${API_VENUE}/create`,
          method: "POST",
        };
        return apiFactory(api, rawData);
      };

      static PauseVenue = async (rawData, shortCode) => {
        let api = {
          content_type: "application/json",
          url: `${API_VENUE}/pause`,
          method: "POST",
        };
        return venueApiFactory(api, rawData, shortCode);
      };


  static CustomPricingContactSales = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_ACCOUNTS.url}/custom-pricing-contact-sales`,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

      static ReactivateVenue = async (shortCode) => {
        let api = {
          content_type: "application/json",
          url: `${API_VENUE}/reactivate`,
          method: "POST",
        };
        return venueApiFactory(api, null, shortCode);
      };
}