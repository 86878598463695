import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import StaffService from '../../services/staffService'
import IconButton from '../common/IconButton'

import { t } from 'i18next'
import calendarIcon from '../../assets/svgs/calendar_white.svg'
import { RInputDeclare } from '../custom/RInputDeclare'

export const addScheduleModalText = (text) =>
	t(`components.employee.AddScheduleModal.${text}`)

export default function AddScheduleModal({ showModal, onClose, employeeId }) {
	const [visible, setVisible] = useState(false)
	const [data, setData] = useState({})
	const [date, setDate] = useState(new Date())
	const [start, setStart] = useState('10:00')
	const [end, setEnd] = useState('11:00')

	useEffect(() => {
		setVisible(showModal)
		if (showModal) {
			loadData()
		}
	}, [showModal])

	const loadData = () => {
		StaffService.ReadOne(employeeId).then((res) => {
			setData(res?.data)
		})
	}

	const onSubmit = () => {
		let payload = {
			employee_id: employeeId,
			date: moment(date).format('YYYY-MM-DD'),
			start_time: start + ':00',
			end_time: end + ':00',
		}

		// schedule
		StaffService.Schedule(payload)
			.then((res) => {
				NotificationManager.success(
					'Time-off Schedule created successfully',
					''
				)
				onClose(false)
			})
			.catch(() => {
				NotificationManager.error('Failed', '')
				throw new Error('Problem on creating the time-off schedule')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<IconButton
						icon={calendarIcon}
						title={addScheduleModalText('add_time_off_request')}
					/>
					<div className="flex flex-col pb-2 px-024">
						<div className="flex flex-col">
							<div className="flex flex-row gap-2">
								<RInputDeclare
									value={addScheduleModalText('start_date')}
									className="text-base !text-black1 font-medium !mb-2"
								/>
								<RInputDeclare
									value={addScheduleModalText('required')}
									className="text-base !mb-2"
								/>
							</div>
							<div className="mr-2 rounded-24 py-2 border-gray-400 w-full off-modal">
								<DatePicker
									selected={date}
									onChange={setDate}
									className="!w-full outline-none !text-left"
									placeholder={addScheduleModalText('select_date')}
								/>
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex flex-row gap-2">
								<RInputDeclare
									value={addScheduleModalText('start_time')}
									className="text-base !text-black1 font-medium !mb-2"
								/>
								<RInputDeclare
									value={addScheduleModalText('required')}
									className="text-base !mb-2"
								/>
							</div>
							<div className="mr-2 rounded-24 py-2 border-gray-400 w-full off-modal">
								<TimePicker
									onChange={setStart}
									value={start}
									clockIcon={false}
								/>
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex flex-row gap-2">
								<RInputDeclare
									value={addScheduleModalText('end_time')}
									className="text-base !text-black1 font-medium !mb-2"
								/>
								<RInputDeclare
									value={addScheduleModalText('required')}
									className="text-base !mb-2"
								/>
							</div>
							<div className="mr-2 rounded-24 py-2 border-gray-400 w-full off-modal">
								<TimePicker onChange={setEnd} value={end} clockIcon={false} />
							</div>
						</div>
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{addScheduleModalText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{addScheduleModalText('continue')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
