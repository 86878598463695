import React from 'react';

import { t } from 'i18next'
import plusIcon from '../../assets/svgs/plus.svg'
import searchIcon from '../../assets/svgs/search.svg'

export const hotelText = (text) => t(`components.hotel.${text}`)

const HotelEmptyData = (props) => {
    const { content = '', onAction = () => {} } = props

		return (
			<div className="flex flex-col items-center text-center py-4 bg-white">
				<div className="bg-gray7 !p-2 rounded-full">
					<div className="bg-black1 !p-2 rounded-full">
						<img src={searchIcon} alt="search" />
					</div>
				</div>
				<span className="text-black1 text-lg">
					{hotelText('hotelContentNotConfigured', { content })}
				</span>
				<span className="text-gray5 text-base mt-6 w-[450px]">
					{hotelText('showcaseExceptionalContent', { content })}
				</span>
				<div
					className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer mt-4 mb-8"
					onClick={() => onAction()}
				>
					<img src={plusIcon} alt="plus" className="mr-2" />
					<span>{hotelText('configure')}</span>
				</div>
			</div>
		)
}

export default HotelEmptyData;
