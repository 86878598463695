import { API_INGREDIENTS } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const getIngredients = async () => {
	let api = {
		content_type: 'application/json',
		url: API_INGREDIENTS,
		method: 'GET',
	}
	return apiFactory(api, {})
}

const deleteIngredient = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_INGREDIENTS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const updateIngredient = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: API_INGREDIENTS,
		method: 'PUT',
	}
	return apiFactory(api, payload)
}

const createIngredient = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: API_INGREDIENTS,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

export default {
	getIngredients,
	createIngredient,
	updateIngredient,
	deleteIngredient,
}
