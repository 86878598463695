import React, { useMemo } from "react";

const radians = Math.PI / 180;

export default function PieChartLabel({
  cx,
  cy,
  midAngle,
  outerRadius,
  color,
  endAngle,
  startAngle,
  tooltipPayload,
  tooltipPosition,
}) {
  const filterData = useMemo(() => tooltipPayload[0], [tooltipPayload]);

  const coordinates = useMemo(() => {
    const sin = Math.sin(-radians * midAngle);
    const cos = Math.cos(-radians * midAngle);
    // (cx,cy) - center coordinates
    //(mx,my) - point on the outer radius. Positioned in the middle of the arc
    const mx = cx + outerRadius * cos;
    const my = cy + outerRadius * sin;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 30;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return {
      sin,
      cos,
      sx,
      sy,
      mx,
      my,
      ex,
      ey,
      textAnchor,
    };
  }, [midAngle, outerRadius, tooltipPosition, endAngle, startAngle]);

  if (Math.abs(endAngle - startAngle) < 5) {
    return null;
  }
  return (
    <g>
      <path
        d={`M${coordinates.sx},${coordinates.sy}L${coordinates.mx},${coordinates.my}L${coordinates.ex},${coordinates.ey}`}
        stroke={color}
        fill="none"
      />
      <circle
        cx={coordinates.ex}
        cy={coordinates.ey}
        r={2}
        fill={filterData.payload.fill}
        stroke="none"
      />
      <text
        className="flex items-center gap-2 rounded-md text-sm font-bold"
        x={coordinates.ex + (coordinates.cos >= 0 ? 1 : -1) * 5}
        y={coordinates.ey}
        fill={filterData.payload.fill}
        textAnchor={coordinates.textAnchor}
        dominantBaseline="central"
      >
        {filterData.name}
      </text>
    </g>
  );
}
