import ImgLogo from '../../assets/images/chatbot.png';
import apiFactory from '../../utils/apiFactory';
import { KEYS, getStorageKey, setStorageKey } from '../../utils/storage';
import { SET_CHAT_LAST_MSG, SET_CHAT_LOADING, SET_CHAT_MESSAGES } from './types';
// import moment from 'moment';
import { API_CHAT, API_VB_ASSISTANT_ASK } from '../../constants/apiConfig';
import Cookies from "universal-cookie";

export const setChatLoading = (loading) => ({
    type: SET_CHAT_LOADING,
    payload: loading
});
const cookies = new Cookies();
const venueType = cookies.get(KEYS.VENUE_TYPE);
let introMessage = '';

if (venueType === 'restaurant') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage your restaurant's operations and enhance the dining experience.";
} else if (venueType === 'bistro') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help elevate the charm and efficiency of your bistro.";
} else if (venueType === 'cafe') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help make managing your cafe a breeze and create a cozy ambiance.";
} else if (venueType === 'bar') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your ally to help manage your bar operations and keep the drinks flowing smoothly.";
} else if (venueType === 'pub_club') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help enhance the nightlife experience at your pubs and clubs.";
} else if (venueType === 'food_truck') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help boost your food truck business for foodies on the go.";
} else if (venueType === 'fine_dining') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help add a touch of elegance to the operations of your fine dining establishment.";
} else if (venueType === 'bakery') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help manage bakery operations with precision and flair.";
} else if (venueType === 'catering_service') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your secret ingredient to help ensure seamless catering service excellence.";
} else if (venueType === 'fast_food') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help streamline your fast-food operations and serve up efficiency.";
} else if (venueType === 'ice_cream_parlor') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your delightful companion to help manage your ice cream parlor and keep the scoops coming.";
} else if (venueType === 'food_stall') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you run a successful food stall and serve up tasty treats.";
} else if (venueType === 'buffet') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage buffet operations with ease and ensure a feast for all.";
} else if (venueType === 'pop_up_dining') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help you create memorable pop-up dining experiences.";
} else if (venueType === 'juice_bar') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help manage your juice bar and keep the juices flowing.";
} else if (venueType === 'food_hall') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your trusted ally for managing the operations of your food hall.";
} else if (venueType === 'supper_club') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you infuse elegance into your supper club and ensure unforgettable evenings.";
} else if (venueType === 'pizzeria') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help manage your pizzeria operations and deliver pizza perfection.";
} else if (venueType === 'coffee_shop') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your go-to companion for making your coffee shop a thriving hub for caffeine lovers.";
} else if (venueType === 'delis') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage your deli with efficiency and deliciousness.";
} else if (venueType === 'food_court') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your ally to help manage food court operations smoothly.";
} else if (venueType === 'golf_venue') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage your golf course and elevate the game-day experience.";
} else if (venueType === 'bowling') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you strike up the fun at your bowling alley.";
} else if (venueType === 'gym') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help you manage your gym and fitness center operations.";
} else if (venueType === 'arcade_and_game_center') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your go-to companion for enhancing the fun at your arcade and game center.";
} else if (venueType === 'sports_arena') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage your sports arena and make game day unforgettable.";
} else if (venueType === 'concert_hall_and_theater') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you create magical moments at your concert hall and theater.";
} else if (venueType === 'amusement_and_theme_park') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your magical companion to help you enhance the thrills at your amusement and theme park.";
} else if (venueType === 'ski_resort') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage the slopes at your ski resort and ensure an unforgettable winter experience.";
} else if (venueType === 'museum') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help you create immersive experiences at your museum.";
} else if (venueType === 'cinema') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your cinematic ally to help you deliver top-notch movie experiences at your cinema.";
} else if (venueType === 'hotel') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage your hotel operations and ensure memorable guest stays.";
} else if (venueType === 'cozy_retreat') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your companion to help you create a tranquil and cozy retreat.";
} else if (venueType === 'hostel') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you provide efficient hostel services to travelers.";
} else if (venueType === 'hotel_chain') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help you maintain consistency and excellence across your hotel chain.";
} else if (venueType === 'vacation_rental') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your ally to help streamline vacation rental management and create memorable stays.";
} else if (venueType === 'luxury_resort') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you maintain the highest standards at your luxury resort.";
} else if (venueType === 'family_resort') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you make family resort vacations fun and memorable.";
} else if (venueType === 'service_apartment') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner for hassle-free service apartment management.";
} else if (venueType === 'bed_and_breakfast') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you provide warm hospitality at your bed and breakfast.";
} else if (venueType === 'motel') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your trusted ally for managing your motel and ensuring guest comfort.";
} else if (venueType === 'capsule_hotel') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help streamline operations at your capsule hotel.";
} else if (venueType === 'youth_hostel') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your youthful companion for efficient youth hostel management.";
} else if (venueType === 'campground') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you create memorable experiences at your campground.";
} else if (venueType === 'rv_park') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help enhance the RV park experience for travelers.";
} else if (venueType === 'sport_essentials') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help you gear up for success in your sport essential store.";
} else if (venueType === 'home_decor') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you style and manage your home décor store with ease.";
} else if (venueType === 'fashion_threads') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your fashion-forward ally for boutique management.";
} else if (venueType === 'retail_chain') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you maintain retail excellence across your chain of stores.";
} else if (venueType === 'specialty_store') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner to help you excel in the unique offerings of your specialty store.";
} else if (venueType === 'online_shop') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your online success partner for managing your e-commerce shop.";
} else if (venueType === 'grocery_store') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you efficiently manage your grocery store.";
} else if (venueType === 'electronics_store') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your gadget guru for electronics store management.";
} else if (venueType === 'pharmacy') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your ally for managing healthcare essentials in your pharmacy.";
} else if (venueType === 'auto_motorcycle_parts') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you provide auto expertise at your parts store.";
} else if (venueType === 'liquor_store') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, your partner for success in the world of liquor and spirits.";
} else if (venueType === 'pet_store') {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, ready to help you create a paws and play paradise at your pet store.";
} else {
    introMessage = "Hi there! 👋 I'm VenueBoost Assistant, here to help you manage your restaurant's operations and enhance the dining experience.";
}

const INTRO_MSG = {

    id: new Date().getTime(),
    text: introMessage,
    option_required: false,
    answered: false,
    greetings: true,
    user: {
        id: 'system',
        avatar: ImgLogo,
        full_name: 'VenueBoost'
    },
    createdAt: new Date(),
    created_at: new Date().getTime(),
}
const STARTED_MSG = {
    id: new Date().getTime() + 10,
    text: "Here are some ways to get started.",
    options: [
        'See helpful tips', // response to click the icon on top right
        'Talk to a human', // todo check how this us done
    ],
    option_required: true,
    answered: false,
    greetings: true,
    user: {
        id: 'system',
        avatar: ImgLogo,
        full_name: 'VenueBoost'
    },
    createdAt: new Date(),
    created_at: new Date().getTime(),
};

// Check if first time is not by a feature, use general VenueBoost HELP_MESSAGE, what is VenueBoost, and maybe features based on industry


// const HELP_MSG = {
//     id: new Date().getTime() + 20,
//     text: "Below are some curated articles and resources designed to optimize your VenueBoost experience and venue management",
//     options: [
//         'What is VenueBoost?',
//         'How to manage your staff on VenueBoost',
//         'How to manage reservations on VenueBoost',
//     ],
//     option_required: true,
//     answered: false,
//     greetings: true,
//     user: {
//         id: 'system',
//         avatar: ImgLogo,
//         full_name: 'VenueBoost'
//     },
//     createdAt: new Date(),
//     created_at: new Date().getTime(),
// };

const HELP_MSG = {
    id: new Date().getTime() + 20,
    text: "You've entered the Tables Management Feature. How can I assist you with your table management needs?",
    options: [
        'How can I add tables to my venue?',
        'Is it possible to view tables in a floor plan layout within VenueBoost?',
        'What types of tables are supported in VenueBoost?',
    ],
    option_required: true,
    answered: false,
    greetings: true,
    user: {
        id: 'system',
        avatar: ImgLogo,
        full_name: 'VenueBoost'
    },
    createdAt: new Date(),
    created_at: new Date().getTime(),
};


export const loadChatHistory = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        setStorageKey(KEYS.CHAT_HISTORY, [INTRO_MSG, STARTED_MSG, HELP_MSG]);
        let messages = [INTRO_MSG, STARTED_MSG, HELP_MSG];
        let lastMsg = getStorageKey(KEYS.CHAT_LAST_MSG);
        if (messages != null) {
            let cpy = messages.slice(0);
            cpy.sort((a, b) => a.created_at - b.created_at)
            dispatch({
                type: SET_CHAT_MESSAGES,
                payload: cpy.reverse()
            })
        }
        else {
            setStorageKey(KEYS.CHAT_HISTORY, [INTRO_MSG, STARTED_MSG, HELP_MSG]);
            dispatch({
                type: SET_CHAT_MESSAGES,
                payload: [INTRO_MSG, STARTED_MSG, HELP_MSG]
            })
        }
        if (lastMsg) {
            dispatch({
                type: SET_CHAT_LAST_MSG,
                payload: lastMsg
            })
        }
        else {
            setStorageKey(KEYS.CHAT_LAST_MSG, HELP_MSG);
            dispatch({
                type: SET_CHAT_LAST_MSG,
                payload: HELP_MSG
            })
        }
        resolve(messages);
    });
};


export const sendMessage = (answer) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        dispatch(setChatLoading(true)); 
        const messages = getStorageKey(KEYS.CHAT_HISTORY);
        const lastMessage = getStorageKey(KEYS.CHAT_LAST_MSG);
        const chatPredifend = getStorageKey(KEYS.CHAT_PREDEFINED);
        let predefined = 1;
        if (chatPredifend) {
            predefined = parseInt(chatPredifend) + 1;
            setStorageKey(KEYS.CHAT_PREDEFINED, predefined);
        } else {
            setStorageKey(KEYS.CHAT_PREDEFINED, 1);
        }
        if (predefined > 3) {
            setStorageKey(KEYS.CHAT_PREDEFINED, 1);
        }
        let tmpCpy = messages.slice(0);
        const index = tmpCpy.findIndex(m => m.id === lastMessage?.id);
        if (index !== -1) {
            tmpCpy[index] = {
                ...tmpCpy[index],
                answered: true,
            }
        }
        tmpCpy.push({
            ...answer
        });

        setStorageKey(KEYS.CHAT_HISTORY, tmpCpy);
        setStorageKey(KEYS.CHAT_LAST_MSG, {
            ...answer,
            id: 'system_' + new Date().getTime(),
            createdAt: new Date(),
            created_at: new Date().getTime(),
            user: {
                id: 'system',
                avatar: ImgLogo,
                full_name: 'VenueBoost'
            }
        });
        dispatch({
            type: SET_CHAT_MESSAGES,
            payload: tmpCpy.reverse()
        });
        dispatch({
            type: SET_CHAT_LAST_MSG,
            payload: {
                ...answer,
                id: 'system_' + new Date().getTime(),
                createdAt: new Date(),
                created_at: new Date().getTime(),
                user: {
                    id: 'system',
                    avatar: ImgLogo,
                    full_name: 'VenueBoost'
                }
        }})
                try {
									let question = await sendChatQuestion({
										question: answer.text,
									})
									const chatAnswer = {
										id: 'system_' + new Date().getTime(),
										text: question.response,
										question: {
											answers:
												predefined === 3 ? question.suggested_questions : null,
										},
										option_required:
											predefined === 3
												? question?.suggested_questions?.length > 0
													? true
													: false
												: true,
										answered:
											predefined === 3
												? question?.suggested_questions?.length > 0
													? false
													: true
												: false,
									}
									const lastMsg = {
										...chatAnswer,
										id: 'system_' + new Date().getTime(),
										createdAt: new Date(),
										created_at: new Date().getTime(),
										user: {
											id: 'system',
											avatar: ImgLogo,
											full_name: 'VenueBoost',
										},
									}
									tmpCpy.push(lastMsg)
									tmpCpy.sort((a, b) => a.created_at - b.created_at)

									setStorageKey(KEYS.CHAT_HISTORY, tmpCpy)
									setStorageKey(KEYS.CHAT_LAST_MSG, lastMsg)
									dispatch({
										type: SET_CHAT_MESSAGES,
										payload: tmpCpy.reverse(),
									})
									// dispatch({
									//     type: SET_CHAT_LAST_MSG,
									//     payload: lastMsg
									// })
									dispatch(setChatLoading(false))
									resolve()
								} catch (error) {
									dispatch(setChatLoading(false))
									resolve()
								}
        resolve();
        dispatch(setChatLoading(false));
    });
};


export const clearChatHistory = () => (dispatch,) => {
    return new Promise(async (resolve, reject) => {
        setStorageKey(KEYS.CHAT_HISTORY, [INTRO_MSG, STARTED_MSG, HELP_MSG]);
        setStorageKey(KEYS.CHAT_LAST_MSG, HELP_MSG);
        dispatch({
            type: SET_CHAT_MESSAGES,
            payload: [INTRO_MSG, STARTED_MSG, HELP_MSG]
        });
        dispatch({
            type: SET_CHAT_LAST_MSG,
            payload: HELP_MSG
        })
    });
};

export const sendChatQuestion = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ API_CHAT, url: (API_CHAT.url), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};


export const vbAssistantAskQuestion = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ API_VB_ASSISTANT_ASK, url: (API_VB_ASSISTANT_ASK.url), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};
