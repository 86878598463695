import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    ImageList,
    ImageListItem,
    MenuItem,
    Modal,
    TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import AssistantButton from '../../../components/assistantButton'
import { ImgDragUpload } from '../../../components/common/ImgDragUpload'
import CustomSelect from '../../../components/custom/CustomSelect'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'
import { getRegisterConfig } from '../../../services/restaurantService'
import VenueService from '../../../services/venuService'
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot'
import { isEmpty } from '../../../utils/common'
import { KEYS } from '../../../utils/storage'

export const storeGeneralText = (text) =>
	t(`account.storeSettings.storeGeneral.${text}`)

const sellTypeList = [
	{ title: 'Sell to all countries', value: 'all' },
	{ title: 'Sell to all countries except for..', value: 'except' },
	{ title: 'Sell to specific countries', value: 'specific' },
]

const shipTypeList = [
	{ title: 'Ship to all countries', value: 'all' },
	{ title: 'Ship to all countries except for..', value: 'except' },
	{ title: 'Ship to specific countries', value: 'specific' },
]

const currencyList = [
	{ title: 'Lek', value: 'ALL' },
	{ title: 'Dollar', value: 'USD' },
	{ title: 'Euro', value: 'EUR' },
]

const countryList = [
	{ title: 'Albania', value: 'Albania' },
	{ title: 'USA', value: 'USA' },
	{ title: 'Italy', value: 'Italy' },
	{ title: 'Kosovo', value: 'Kosovo' },
	{ title: 'North Macedonia', value: 'North Macedonia' },
	{ title: 'Montenegro', value: 'Montenegro' },
]

const tags = [
	{ title: 'Apparel', value: 'Apparel' },
	{ title: 'Electronics', value: 'Electronics' },
	{ title: 'Home', value: 'Home' },
	{ title: 'Beauty', value: 'Beauty' },
	{ title: 'Jewelry', value: 'Jewelry' },
	{ title: 'Toys', value: 'Toys' },
	{ title: 'Sports', value: 'Sports' },
	{ title: 'Books', value: 'Books' },
	{ title: 'Health', value: 'Health' },
	{ title: 'Handmade', value: 'Handmade' },
	{ title: 'Footwear', value: 'Footwear' },
	{ title: 'Bags', value: 'Bags' },
	{ title: 'Kitchen', value: 'Kitchen' },
	{ title: 'Pets', value: 'Pets' },
	{ title: 'Music', value: 'Music' },
	{ title: 'Vintage', value: 'Vintage' },
	{ title: 'Garden', value: 'Garden' },
	{ title: 'Children', value: 'Children' },
	{ title: 'Sustainable', value: 'Sustainable' },
	{ title: 'Decor', value: 'Decor' },
	{ title: 'Gifts', value: 'Gifts' },
	{ title: 'Gourmet', value: 'Gourmet' },
	{ title: 'Men', value: 'Men' },
	{ title: 'Women', value: 'Women' },
	{ title: 'Kids', value: 'Kids' },
	{ title: 'Discount', value: 'Discount' },
	{ title: 'Designer', value: 'Designer' },
	{ title: 'Vegan', value: 'Vegan' },
	{ title: 'Organic', value: 'Organic' },
	{ title: 'Local', value: 'Local' },
	{ title: 'Seasonal', value: 'Seasonal' },
	{ title: 'Trending', value: 'Trending' },
	{ title: 'Luxury', value: 'Luxury' },
	{ title: 'Pop-up', value: 'Pop-up' },
	{ title: 'DIY', value: 'DIY' },
	{ title: 'Travel', value: 'Travel' },
	{ title: 'Cultural', value: 'Cultural' },
	{ title: 'Ethnic', value: 'Ethnic' },
	{ title: 'Subscription', value: 'Subscription' },
	{ title: 'Adventure', value: 'Adventure' },
	{ title: 'watches', value: 'watches' },
	{ title: 'glasses', value: 'glasses' },
	{ title: 'swatch', value: 'swatch' },
	{ title: 'swiss made', value: 'swiss made' },
	{ title: 'automatic', value: 'automatic' },
	{ title: 'quartz', value: 'quartz' },
	{ title: 'chronometer', value: 'quartz' },
	{ title: 'tachymetre', value: 'tachymetre' },
	{ title: 'flik flak', value: 'flik flak' },
	{ title: 'water resistent', value: 'water resistent' },
	{ title: 'water proof', value: 'water proof' },
]

const pharmacyTags = [
	{ title: 'Prescription Medications', value: 'Prescription Medications' },
	{
		title: 'Over-the-counter Medications',
		value: 'Over-the-counter Medications',
	},
	{ title: 'Health Consultations', value: 'Health Consultations' },
	{ title: 'Vaccinations', value: 'Vaccinations' },
	{ title: 'Medical Supplies', value: 'Medical Supplies' },
	{ title: 'Compounding Pharmacy', value: 'Compounding Pharmacy' },
	{ title: 'Geriatric Care', value: 'Geriatric Care' },
	{ title: 'Pediatric Care', value: 'Pediatric Care' },
	{ title: 'Diabetes Management', value: 'Diabetes Management' },
	{ title: 'Health Insurance', value: 'Health Insurance' },
	{ title: 'Cash Payment', value: 'Cash Payment' },
	{ title: 'Credit/Debit Cards', value: 'Credit/Debit Cards' },
	{ title: 'Medication Delivery', value: 'Medication Delivery' },
	{ title: 'Health Education', value: 'Health Education' },
	{ title: 'First Aid', value: 'First Aid' },
	{ title: 'Nutritional Supplements', value: 'Nutritional Supplements' },
	{ title: 'Wellness Products', value: 'Wellness Products' },
	{ title: 'Beauty Products', value: 'Beauty Products' },
	{ title: 'Pain Management', value: 'Pain Management' },
	{ title: 'Allergy Relief', value: 'Allergy Relief' },
	{ title: 'Home Health Care', value: 'Home Health Care' },
	{ title: 'Asthma Control', value: 'Asthma Control' },
	{ title: 'Blood Pressure Monitoring', value: 'Blood Pressure Monitoring' },
	{ title: 'Chronic Disease Management', value: 'Chronic Disease Management' },
	{ title: 'Pharmacist Consultation', value: 'Pharmacist Consultation' },
	{ title: 'Medication Adherence', value: 'Medication Adherence' },
	{ title: 'Senior Care', value: 'Senior Care' },
	{ title: 'Patient Counseling', value: 'Patient Counseling' },
	{ title: 'Flu Shots', value: 'Flu Shots' },
	{ title: 'COVID-19 Vaccinations', value: 'COVID-19 Vaccinations' },
	{ title: 'Travel Vaccinations', value: 'Travel Vaccinations' },
	{ title: 'Pet Medications', value: 'Pet Medications' },
	{ title: 'Veterinary Prescriptions', value: 'Veterinary Prescriptions' },
	{ title: 'Fertility Medications', value: 'Fertility Medications' },
	{
		title: 'Hormone Replacement Therapy',
		value: 'Hormone Replacement Therapy',
	},
	{ title: 'Smoking Cessation', value: 'Smoking Cessation' },
	{ title: 'Antibiotics', value: 'Antibiotics' },
	{ title: 'Antidepressants', value: 'Antidepressants' },
	{ title: 'Antihistamines', value: 'Antihistamines' },
	{ title: 'Antinauseants', value: 'Antinauseants' },
	{ title: 'Antivirals', value: 'Antivirals' },
	{ title: 'Antiemetics', value: 'Antiemetics' },
	{ title: 'Antifungals', value: 'Antifungals' },
	{ title: 'Antidiarrheals', value: 'Antidiarrheals' },
	{ title: 'Antipyretics', value: 'Antipyretics' },
	{ title: 'Antiparasitics', value: 'Antiparasitics' },
	{ title: 'Antipsychotics', value: 'Antipsychotics' },
	{ title: 'Antirheumatics', value: 'Antirheumatics' },
	{ title: 'Antiseptics', value: 'Antiseptics' },
	{ title: 'Antitussives', value: 'Antitussives' },
	{ title: 'Anticoagulants', value: 'Anticoagulants' },
	{ title: 'Anticonvulsants', value: 'Anticonvulsants' },
	{ title: 'Antiemetics', value: 'Antiemetics' },
	{ title: 'Antiepileptics', value: 'Antiepileptics' },
	{ title: 'Antifibrinolytics', value: 'Antifibrinolytics' },
	{ title: 'Antihypertensives', value: 'Antihypertensives' },
	{ title: 'Antimicrobials', value: 'Antimicrobials' },
	{ title: 'Antineoplastics', value: 'Antineoplastics' },
	{ title: 'Antipruritics', value: 'Antipruritics' },
	{ title: 'Antipsoriatics', value: 'Antipsoriatics' },
	{ title: 'Antipyretics', value: 'Antipyretics' },
	{ title: 'Antispasmodics', value: 'Antispasmodics' },
	{ title: 'Antivirals', value: 'Antivirals' },
	{ title: 'Bactericides', value: 'Bactericides' },
	{ title: 'Cardiovascular Medications', value: 'Cardiovascular Medications' },
	{ title: 'Cholinergics', value: 'Cholinergics' },
	{ title: 'Corticosteroids', value: 'Corticosteroids' },
	{ title: 'Decongestants', value: 'Decongestants' },
	{ title: 'Dermatological Medications', value: 'Dermatological Medications' },
	{ title: 'Diagnostic Agents', value: 'Diagnostic Agents' },
	{
		title: 'Gastrointestinal Medications',
		value: 'Gastrointestinal Medications',
	},
	{ title: 'Hematological Medications', value: 'Hematological Medications' },
	{ title: 'Hormonal Medications', value: 'Hormonal Medications' },
	{ title: 'Immunological Medications', value: 'Immunological Medications' },
	{
		title: 'Musculoskeletal Medications',
		value: 'Musculoskeletal Medications',
	},
	{ title: 'Neurological Medications', value: 'Neurological Medications' },
	{ title: 'Ophthalmic Medications', value: 'Ophthalmic Medications' },
	{ title: 'Otic Medications', value: 'Otic Medications' },
	{ title: 'Respiratory Medications', value: 'Respiratory Medications' },
	{ title: 'Urological Medications', value: 'Urological Medications' },
]

const paymentOptions = [
	{ title: 'Credit Card', value: 'Credit Card' },
	{ title: 'Debit Card', value: 'Debit Card' },
	{ title: 'Bank Transfer', value: 'Bank Transfer' },
	{ title: 'Cash on Delivery', value: 'Cash on Delivery' },
	{ title: 'Cash', value: 'Cash' },
	{ title: 'Visa', value: 'Visa' },
	{ title: 'Mastercard', value: 'Mastercard' },
	{ title: 'Maestro', value: 'Maestro' },
]

const StoreGeneral = ({ data, onSubmitForm }) => {
	const [states, setStates] = useState([])
	const [cities, setCities] = useState([])
	const [state_id, setStateId] = useState(null)
	const [selling_location, set_selling_location] = useState('')
	const [shipping_location, set_shipping_location] = useState('')
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const navigate = useNavigate()

	const validator = yup.object().shape({
		address_line1: yup
			.string()
			.trim()
			.required('Address Line 1 is required')
			.max(250, 'Max exceeded')
			.min(6, 'Min not met'),
		//address_line1: yup.string(),
		state: yup.string().required('State is required'),
		city: yup.string().required('City is required'),
		postcode: yup.string().required('Postal code is required'),
		selling_location: yup
			.string()
			.trim()
			.required('Selling location is required'),
		selling_countries:
			selling_location === 'except' || selling_location === 'specific'
				? yup.array().required().min(1, 'Please select selling country')
				: yup.array(),
		shipping_location: yup
			.string()
			.trim()
			.required('Shipping location is required'),
		shipping_countries:
			shipping_location === 'except' || shipping_location === 'specific'
				? yup.array().required().min(1, 'Please select shipping country')
				: yup.array(),
		enable_coupon: yup.boolean(),
		currency: yup.string().trim().required('Currency is required'),
	})

	useEffect(() => {
		loadRegisterConfig()
	}, [])

	useEffect(() => {
		if (Object.keys(data).length > 0) {
			const address = data?.address ? data?.address[0] : {}
			formikProps.setFieldValue('currency', data?.currency)
			formikProps.setFieldValue('address_line1', address?.address_line1)
			formikProps.setFieldValue('address_line2', address?.address_line2)
			formikProps.setFieldValue('state', address?.state)
			formikProps.setFieldValue('city', address?.city)
			formikProps.setFieldValue('postcode', address?.postcode)
			formikProps.setFieldValue(
				'selling_location',
				data?.selling_location ?? []
			)
			formikProps.setFieldValue(
				'shipping_location',
				data?.shipping_location ?? []
			)
			formikProps.setFieldValue(
				'selling_countries',
				data?.selling_countries ?? []
			)
			formikProps.setFieldValue(
				'shipping_countries',
				data?.shipping_countries ?? []
			)
			formikProps.setFieldValue(
				'enable_coupon',
				data?.enable_coupon == 1 ? true : false
			)
			set_selling_location(data?.selling_location)
			set_shipping_location(data?.shipping_location)
			formikProps.setFieldValue('tags', data?.tags ?? [])
			formikProps.setFieldValue('payment_options', data?.payment_options ?? [])
			formikProps.setFieldValue('neighborhood', data?.neighborhood)
			formikProps.setFieldValue('description', data?.description)
			formikProps.setFieldValue('additional', data?.additional)
			formikProps.setFieldValue('main_tag', data?.main_tag)

			if (data.gallery.length > 0) {
				const tempImages = [...images]
				data.gallery.forEach((item, index) => {
					tempImages[index] = item
				})
				setImages(tempImages)
			}
		}
	}, [data])

	const [images, setImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	])
	const [loading, setLoading] = useState(false)
	const formikProps = useFormik({
		// initialValues: { first_name: '', last_name: '', email: '', cuisine_types: [], amenities: [] },
		initialValues: {
			address_line1: '',
			address_line2: '',
			state: '',
			city: '',
			postcode: '',
			selling_location: '',
			shipping_location: '',
			currency: '',
			enable_coupon: false,
			selling_countries: [],
			shipping_countries: [],
			payment_options: [],
			tags: [],
			neighborhood: '',
			description: '',
			additional: '',
			main_tag: '',
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const onSubmit = (values) => {
		window.scrollTo(0, 0)

		const payload = {
			...values,
		}
		onSubmitForm(payload)
	}

	const onUploadImage = (image, index) => {
		let formData
		formData = new FormData()
		formData.append('type', 'gallery')
		if (image?.type === 'file' && image?.url != null) {
			formData.append('photo', image.file)
		}
		setLoading(true)
		VenueService.uploadPhoto(formData)
			.then((res) => {
				// hope response has the new image, or at least id
				NotificationManager.success('Image has been uploaded successfully')
				navigate(0)
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
			})
	}

	const onCancelImage = (index) => {
		setLoading(true)
		VenueService.deleteFacilitiesPhoto(images[index].photo_id)
			.then((res) => {
				setLoading(false)
				if (images[index]) {
					const tempImages = [...images]
					tempImages[index] = null
					setImages(tempImages)
				}
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Unable do delete Photo')
			})
	}

	const loadRegisterConfig = () => {
		getRegisterConfig()
			.then((data) => {
				setStates(data?.states || [])
				setCities(data?.cities || [])
			})
			.catch((error) => {
				throw new Error('Problem on getRegisterConfig')
			})
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'store_settings_description'
		if (aiField === 'Description') {
			askFor = 'additional_store_settings_information'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)

		if (aiField === 'Description') {
			formikProps.setFieldValue('description', generated)
		}

		if (aiField === 'Additional information') {
			formikProps.setFieldValue('additional', generated)
		}

		setAiQuery('')
		setGenerated('')
	}

	if (loading) {
		return <LoadingScreen />
	}

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-6 pt-2 gap-10">
					<div className="relative started-form">
						<div>
							<p className="text-base text-gray5 mt-6 mb-2">
								<span className="text-black1 text-xl font-medium">
									{storeGeneralText('store_address')}
								</span>
							</p>
							<span className="text-gray5 text-16 font-medium">
								{storeGeneralText('store_address_description')}
							</span>
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('address_line_1')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										disabled={true}
										size="small"
										placeholder={storeGeneralText('address_line_1')}
										value={formikProps.values.address_line1}
										error={formikProps.errors.address_line1}
										onChange={(e) => {
											formikProps.setFieldValue('address_line1', e.target.value)
										}}
									/>
									{!isEmpty(formikProps.errors.address_line1) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.address_line1}
										</div>
									)}
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('address_line_2')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										disabled={true}
										size="small"
										placeholder={storeGeneralText('address_line_2')}
										value={
											formikProps.values.address_line2 === 'null'
												? ''
												: formikProps.values.address_line2 ?? ''
										}
										error={formikProps.errors.address_line2}
										onChange={(e) => {
											formikProps.setFieldValue('address_line2', e.target.value)
										}}
									/>
									{!isEmpty(formikProps.errors.address_line2) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.address_line2}
										</div>
									)}
								</div>
								<div>
									<RInputDeclare
										value={storeGeneralText('state')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<CustomSelect
										disabled={true}
										values={states}
										value={formikProps.values.state}
										renderRow={(item) => <span>{item?.name}</span>}
										className="!z-[5] !mt-2 !bg-white !border-gray6 !h-10"
										placeholder={storeGeneralText('state')}
										handleChange={(v) => {
											formikProps.setFieldValue('state', v.name)
											setStateId(v.id)
										}}
									/>
									{!isEmpty(formikProps.errors.state) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.state}
										</div>
									)}
								</div>
								<div>
									<RInputDeclare
										value={storeGeneralText('city')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<CustomSelect
										values={cities
											.filter((c) => c.states_id === state_id)
											.map((c) => c.name)}
										value={formikProps.values.city}
										className="!z-[4] !bg-white !mt-2 !border-gray6 !h-10"
										placeholder={storeGeneralText('city')}
										handleChange={(v) => {
											formikProps.setFieldValue('city', v)
										}}
										disabled={true}
									/>
									{!isEmpty(formikProps.errors.city) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.city}
										</div>
									)}
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('postal_code')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<TextField
										disabled={true}
										required
										size="small"
										placeholder={storeGeneralText('postal_code')}
										value={formikProps.values.postcode}
										error={formikProps.errors.postcode}
										onChange={(e) => {
											formikProps.setFieldValue('postcode', e.target.value)
										}}
									/>
									{!isEmpty(formikProps.errors.postcode) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.postcode}
										</div>
									)}
								</div>
							</div>
						</div>
						<div>
							<p className="text-base text-gray5 mt-10 mb-2">
								<span className="text-black1 text-xl font-medium">
									{storeGeneralText('selling_location')}
								</span>
							</p>
							<span className="text-gray5 text-16 font-medium">
								{storeGeneralText('selling_location_description')}
							</span>
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-6 bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('selling_location')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										id="outlined-select"
										size="small"
										error={formikProps.errors.selling_location}
										select
										value={formikProps.values.selling_location}
										onChange={(event) => {
											formikProps.setFieldValue(
												'selling_location',
												event.target.value
											)
											set_selling_location(event.target.value)
										}}
									>
										{sellTypeList.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.title}
											</MenuItem>
										))}
									</TextField>
									{!isEmpty(formikProps.errors.selling_location) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.selling_location}
										</div>
									)}
								</div>
								{selling_location === 'except' ||
								selling_location === 'specific' ? (
									<div className="flex flex-col">
										<RInputDeclare
											value={storeGeneralText('selling_countries')}
											className="!mt-0 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={countryList}
											value={
												formikProps.values.selling_countries == null ||
												formikProps.values.selling_countries.length == 0
													? []
													: countryList.filter(
															(item) =>
																formikProps.values.selling_countries.findIndex(
																	(a) => a == item.value
																) != -1
													  )
											}
											isMultiple={true}
											placeholder={storeGeneralText('selling_countries')}
											renderRow={(item) => <p>{item.title}</p>}
											renderValue={(item) => <span>{item?.title}</span>}
											handleChange={(v) => {
												let tmp = formikProps.values.selling_countries.slice(0)
												tmp.push(v.value)
												formikProps.setFieldValue('selling_countries', tmp)
											}}
											onRemoveItem={(v) => {
												let tmp = formikProps.values.selling_countries.slice(0)
												let index = tmp.findIndex((t) => t == v.value)
												if (index != -1) {
													tmp.splice(index, 1)
												}
												formikProps.setFieldValue('selling_countries', tmp)
											}}
										/>
									</div>
								) : null}
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('shipping_locations')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										id="outlined-select"
										size="small"
										error={formikProps.errors.shipping_location}
										select
										value={formikProps.values.shipping_location}
										onChange={(event) => {
											formikProps.setFieldValue(
												'shipping_location',
												event.target.value
											)
											set_shipping_location(event.target.value)
										}}
									>
										{shipTypeList.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.title}
											</MenuItem>
										))}
									</TextField>
									{!isEmpty(formikProps.errors.shipping_location) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.shipping_location}
										</div>
									)}
								</div>
								{shipping_location === 'except' ||
								shipping_location === 'specific' ? (
									<div className="flex flex-col">
										<RInputDeclare
											value={storeGeneralText('shipping_countries')}
											className="!mt-0 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={countryList}
											value={
												formikProps.values.shipping_countries == null ||
												formikProps.values.shipping_countries.length == 0
													? []
													: countryList.filter(
															(item) =>
																formikProps.values.shipping_countries.findIndex(
																	(a) => a == item.value
																) != -1
													  )
											}
											isMultiple={true}
											placeholder={storeGeneralText('shipping_countries')}
											renderRow={(item) => <p>{item.title}</p>}
											renderValue={(item) => <span>{item?.title}</span>}
											handleChange={(v) => {
												let tmp = formikProps.values.shipping_countries.slice(0)
												tmp.push(v.value)
												formikProps.setFieldValue('shipping_countries', tmp)
											}}
											onRemoveItem={(v) => {
												let tmp = formikProps.values.shipping_countries.slice(0)
												let index = tmp.findIndex((t) => t == v.value)
												if (index != -1) {
													tmp.splice(index, 1)
												}
												formikProps.setFieldValue('shipping_countries', tmp)
											}}
										/>
									</div>
								) : null}
								<div className="flex mt-4 flex-col">
									<span className="text-gray5 text-16 font-medium">
										Coupons can be applied from the cart and checkout pages
									</span>
									<RInputDeclare
										value={storeGeneralText('enable_coupons')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={formikProps.values.enable_coupon}
										style={{ width: 'fit-content' }}
										onChange={(e) =>
											formikProps.setFieldValue(
												'enable_coupon',
												e.target.checked
											)
										}
										className="mt-2"
									/>
								</div>
							</div>
						</div>
						<div>
							<p className="text-base text-gray5 mt-8 mb-2">
								<span className="text-black1 text-xl font-medium">
									{storeGeneralText('currency_options')}
								</span>
							</p>
							<span className="text-gray5 text-16 font-medium">
								{storeGeneralText('currency_options_description')}
							</span>
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-2 bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('currency')}
										className="!mt-4 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										id="outlined-select"
										size="small"
										error={formikProps.errors.currency}
										select
										value={formikProps.values.currency}
										onChange={(event) => {
											formikProps.setFieldValue('currency', event.target.value)
										}}
									>
										{currencyList.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.title}
											</MenuItem>
										))}
									</TextField>
									{!isEmpty(formikProps.errors.currency) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.currency}
										</div>
									)}
								</div>
							</div>
						</div>
						<div>
							<p className="text-base text-gray5 mt-10 mb-2">
								<span className="text-black1 text-xl font-medium">
									{storeGeneralText('store_web_profile')}
								</span>
							</p>
							<span className="text-gray5 text-16 font-medium">
								{storeGeneralText('store_web_profile_description')}
							</span>
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('store_tags')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<CustomSelect
										values={venueType === 'pharmacy' ? pharmacyTags : tags}
										value={
											formikProps.values.tags == null ||
											formikProps.values.tags.length === 0
												? []
												: venueType === 'pharmacy'
												? pharmacyTags.filter(
														(item) =>
															formikProps.values.tags.findIndex(
																(a) => a === item.value
															) !== -1
												  )
												: tags.filter(
														(item) =>
															formikProps.values.tags.findIndex(
																(a) => a === item.value
															) !== -1
												  )
										}
										isMultiple={true}
										placeholder={storeGeneralText('store_tags')}
										renderRow={(item) => <p>{item.title}</p>}
										renderValue={(item) => <span>{item?.title}</span>}
										handleChange={(v) => {
											let tmp = formikProps.values.tags.slice(0)
											tmp.push(v.value)
											formikProps.setFieldValue('tags', tmp)
										}}
										onRemoveItem={(v) => {
											let tmp = formikProps.values.tags.slice(0)
											let index = tmp.findIndex((t) => t === v.value)
											if (index !== -1) {
												tmp.splice(index, 1)
											}
											formikProps.setFieldValue('tags', tmp)
										}}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('payment_methods_accepted')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<CustomSelect
										values={paymentOptions}
										value={
											formikProps.values.payment_options == null ||
											formikProps.values.payment_options.length === 0
												? []
												: paymentOptions.filter(
														(item) =>
															formikProps.values.payment_options.findIndex(
																(a) => a === item.value
															) !== -1
												  )
										}
										isMultiple={true}
										placeholder={storeGeneralText('payment_methods_accepted')}
										renderRow={(item) => <p>{item.title}</p>}
										renderValue={(item) => <span>{item?.title}</span>}
										handleChange={(v) => {
											let tmp = formikProps.values.payment_options.slice(0)
											tmp.push(v.value)
											formikProps.setFieldValue('payment_options', tmp)
										}}
										onRemoveItem={(v) => {
											let tmp = formikProps.values.payment_options.slice(0)
											let index = tmp.findIndex((t) => t === v.value)
											if (index !== -1) {
												tmp.splice(index, 1)
											}
											formikProps.setFieldValue('payment_options', tmp)
										}}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('description')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										size="small"
										maxRows={5}
										minRows={5}
										multiline
										value={formikProps.values.description}
										error={formikProps.errors.description}
										placeholder={storeGeneralText('description')}
										style={{ background: '#fff' }}
										sx={{
											'& .MuiOutlinedInput-notchedOutline': {
												border: '1px solid #d9d9d9!important',
											},
										}}
										onChange={(e) => {
											formikProps.setFieldValue('description', e.target.value)
										}}
									/>
									<div className="flex flex-row justify-end mt-2">
										<AssistantButton
											onAction={() => handleOpenAIModal('Description')}
										/>
									</div>
									{!isEmpty(formikProps.errors.description) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.description}
										</div>
									)}
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('additional_information')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										maxRows={5}
										minRows={5}
										multiline
										size="small"
										value={formikProps.values.additional}
										error={formikProps.errors.additional}
										placeholder={storeGeneralText('additional_information')}
										style={{ background: '#fff' }}
										sx={{
											'& .MuiOutlinedInput-notchedOutline': {
												border: '1px solid #d9d9d9!important',
											},
										}}
										onChange={(e) => {
											formikProps.setFieldValue('additional', e.target.value)
										}}
									/>
									<div className="flex flex-row justify-end mt-2">
										<AssistantButton
											onAction={() =>
												handleOpenAIModal('Additional information')
											}
										/>
									</div>
									{!isEmpty(formikProps.errors.additional) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.additional}
										</div>
									)}
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('main_tag')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										size="small"
										placeholder={storeGeneralText('main_tag')}
										value={formikProps.values.main_tag}
										error={formikProps.errors.main_tag}
										onChange={(e) => {
											formikProps.setFieldValue('main_tag', e.target.value)
										}}
									/>
									{!isEmpty(formikProps.errors.main_tag) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.main_tag}
										</div>
									)}
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={storeGeneralText('neighborhood')}
										className="!mt-0 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										size="small"
										placeholder={storeGeneralText('neighborhood')}
										value={formikProps.values.neighborhood}
										error={formikProps.errors.neighborhood}
										onChange={(e) => {
											formikProps.setFieldValue('neighborhood', e.target.value)
										}}
									/>
									{!isEmpty(formikProps.errors.neighborhood) && (
										<div className="text-red-600 text-12 mt-2">
											{formikProps.errors.neighborhood}
										</div>
									)}
								</div>
							</div>
						</div>
						<Card
							className="mt-6"
							style={{ boxShadow: 'rgba(0, 0, 0, 0.1) -1px -13px 10px -5px' }}
						>
							<p
								style={{ padding: '20px 15px 20px 15px' }}
								className="text-black1 font-medium text-[20px]"
							>
								{storeGeneralText('gallery')}
							</p>
							<CardContent style={{ background: '#f6f6f8' }}>
								<div className="relative started-form">
									<div className="flex-col mt-0 rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col h-full mt-0 py-4">
											<ImageList variant="quilted" cols={6}>
												{images.map((item, index) => (
													<ImageListItem
														key={'img_uploader' + index}
														className="pl-2 pb-2"
													>
														<ImgDragUpload
															backgroundColor="#fff"
															image={item ? { url: item?.photo_path } : null}
															onCancel={() => onCancelImage(index)}
															onChange={(img) => onUploadImage(img, index)}
															label={storeGeneralText('gallery_description')}
															classes="max-h-[180px] p-2 mb-2 mt-2"
														/>
													</ImageListItem>
												))}
											</ImageList>
										</div>
									</div>
								</div>
							</CardContent>
						</Card>
						<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
							<button
								type="button"
								className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							>
								{storeGeneralText('clear')}
							</button>
							<button
								type="submit"
								className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
								onClick={() => {
									formikProps.handleSubmit()
								}}
							>
								{storeGeneralText('save')}
							</button>
						</div>
					</div>
				</div>
			</CardContent>
			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{storeGeneralText('vb_ai_assistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									placeholder={storeGeneralText('ai_query_placeholder')}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{storeGeneralText('ai_query_instruction')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-transparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												storeGeneralText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={storeGeneralText('result_placeholder')}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{storeGeneralText('add_to_field', { aiField })}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</Card>
	)
}

export default StoreGeneral
