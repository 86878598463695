import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import CustomIconButton from '../../components/common/IconButton'
import { boxStyle } from '../../constants/types'

import { t } from 'i18next'
import infoIcon from '../../assets/svgs/info_white.svg'

export const resCancelText = (text) =>
	t(`reservations.ReservationCancelModal.${text}`)

export default function ReservationCancelModal({
	showModal,
	onClose,
	onSubmit,
}) {
	return (
		<Modal
			open={showModal}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={{ ...boxStyle, width: 450 }} className="outline-none">
				<CustomIconButton
					icon={infoIcon}
					title={resCancelText('cancelReservation')}
				/>
				<div className="px-6 mb-4">
					<span className="text-gray5 text-sm">
						{resCancelText('cancelReservationPrompt')}
					</span>
				</div>

				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6 text-sm">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{resCancelText('goBack')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onSubmit}
					>
						{resCancelText('yesCancel')}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
