import { CalendarMonth } from '@mui/icons-material'
import AddLinkIcon from '@mui/icons-material/AddLink'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
	Card,
	CardContent,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from '@mui/material'
import { saveAs } from 'file-saver'
import { t } from 'i18next'
import { createEvents } from 'ics'
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import accommodationService from '../../../../services/accommodationService'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import AvailabilityCalendar from './availabilityCalendar'
import AvailabilityModal from './availabilityModal'
import ConnectCalendarModal from './connectCalendarModal'

const mergeArray = (arr1, arr2) => {
	const mergedArray = []

	// Merge the first array (arr1)
	for (const item1 of arr1) {
		const mergedItem = {
			id: item1.id,
			venue_id: item1.venue_id,
			rental_unit_id: item1.rental_unit_id,
			guest_id: item1.guest_id,
			guest_nr: item1.guest_nr,
			start_date: item1.start_date,
			end_date: item1.end_date,
			total_amount: item1.total_amount,
			discount_price: item1.discount_price,
			status: item1.status,
			paid_with: item1.paid_with,
			prepayment_amount: item1.prepayment_amount,
			created_at: item1.created_at,
			stripe_payment_id: item1.stripe_payment_id,
			subtotal: item1.subtotal,
			title: 'Booking',
			description: '',
			event_description: '',
			type: '',
			summary: '',
		}

		mergedArray.push(mergedItem)
	}

	// Merge the second array (arr2)
	for (const item2 of arr2) {
		const mergedItem = {
			id: item2.id,
			venue_id: item2.venue_id,
			rental_unit_id: item2.rental_unit_id,
			guest_id: '',
			guest_nr: '',
			start_date: item2.start_date,
			end_date: item2.end_date,
			total_amount: '',
			discount_price: '',
			status: '',
			paid_with: '',
			prepayment_amount: '',
			created_at: item2.created_at,
			stripe_payment_id: null,
			subtotal: '',
			title: item2.title,
			description: item2.description,
			event_description: item2.event_description,
			type: item2.type,
			summary: item2.summary,
		}

		mergedArray.push(mergedItem)
	}

	return mergedArray
}

export const availabilityText = (text) =>
	t(`accommodation.rental_unit.show.availability.${text}`)

function Availability({ data, rentalUnitId, icsLink }) {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	const monthOptions = []
	for (let i = 0; i < months.length; i++) {
		monthOptions.push({
			value: i + 1,
			label: months[i] + ' ' + new Date().getFullYear(),
		})
	}

	const [filter, setFilter] = useState('all')
	const filterOptions = [
		{
			value: 'all',
			label: 'All',
		},
		{
			value: 'Venue_boost_booking',
			label: 'VenueBoost Bookings',
		},
		{
			value: 'import_ics_booking',
			label: '3rd Party Bookings',
		},
	]

	const [month, setMonth] = useState(new Date().getMonth() + 1)
	const [calendarDate, setCalendarDate] = useState(new Date())
	const [modalEvents, setModalEvents] = useState([])
	const [events, setEvents] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [bookingData, setBookingData] = useState('')
	const [bookingsArray, setBookingsArray] = useState([])
	const [loading, setLoading] = useState(true)
	const { short_code } = useSelector((state) => state.app)
	const [hasCopied, setHasCopied] = useState(false)
	const [showConnCalModal, setShowConnCalModal] = useState(false)

	const getThirdPartyBooking = (filterType, monthValue) => {
		const payload = {
			month: monthValue,
			type: filterType,
		}
		accommodationService
			.GetThirdPartyBooking(payload, rentalUnitId)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Party booking', {
					action_category: 'get party booking',
					action_outcome: 'success',
					interaction_element: 'booking',
				})
				setBookingsArray(mergeArray(res?.bookings, res?.thirdPartyBookings))
				setBookingData(res)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('Party booking', {
					action_category: 'get party booking',
					action_outcome: 'fail',
					interaction_element: 'booking',
				})
				console.error('Problem on GetThirdPartyBooking:', err)
			})
	}


	useEffect(() => {
		getThirdPartyBooking(filter, month)
	}, [filter, month, rentalUnitId])

	const setNewMonth = (newMonth) => {
		setMonth(newMonth)
		const newDate = new Date(calendarDate.getFullYear(), newMonth - 1, 1)
		setCalendarDate(newDate)
	}

	const handleFilterChange = (event) => {
		setFilter(event.target.value)
	}

	const onDaySelect = (day) => {
		const displayEvents = events.filter((item) => item.day === day)
		if (displayEvents.length > 0) {
			setModalEvents(displayEvents)
		}
	}

	const exportIcs = () => {
		const icsEvents = []

		bookingData?.bookings?.forEach((booking) => {
			const startDateParts = booking.start_date.split('-').map(Number)
			const endDateParts = booking.end_date.split('-').map(Number)
			const event = {
				start: startDateParts,
				end: endDateParts,
				title: 'Booking',
			}
			icsEvents.push(event)
		})

		const { error, value } = createEvents(icsEvents)

		if (error) {
			console.error(error)
			return
		}

		const blob = new Blob([value], { type: 'text/calendar' })
		saveAs(blob, 'bookings.ics')
	}

	const handleCopyIcal = () => {
		// Copy the iCal link to clipboard without fetching new data
		navigator.clipboard
			.writeText(icsLink)
			.then(() => {
				setHasCopied(true)
				setTimeout(() => setHasCopied(false), 1500)
				NotificationManager.success(
					`The calendar .ics file is copied successfully. You can now use it to sync your bookings across platforms.`
				)
			})
			.catch((error) => {
				console.error('Failed to copy: ', error)
				NotificationManager.error(
					`Failed to copy link. Please try again.`
				)
			})
	}

	const handleConnectCalendar = () => {

	}

	return (
		<>
			<div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
				<div className="h-full right-0 p-6 z-20 flex flex-col">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5 pb-5">
								<div className="flex flex-row items-center w-[100%]">
									<span className="text-black1 text-s font-medium">
										{availabilityText('welcomeMessage')}
									</span>
								</div>
							</div>
							<div className="flex flex-row justify-between items-center pl-4 pr-4 pt-5 pb-5">
								<div className="flex flex-row items-center gap-2">
									<div>
										<FormControl className="h-full w-full">
											<InputLabel className="!text-black">
												{availabilityText('filter')}
											</InputLabel>
											<Select
												value={filter}
												onChange={handleFilterChange}
												label={availabilityText('filter')}
												sx={{ minWidth: '200px', height: "40px", borderRadius: "6px" }}
											>r
												<MenuItem value="all">
													{availabilityText('all')}
												</MenuItem>
												<MenuItem value="Venue_boost_booking">
													{availabilityText('venueBoostBookings')}
												</MenuItem>
												<MenuItem value="import_ics_booking">
													{availabilityText('thirdPartyBookings')}
												</MenuItem>
											</Select>
										</FormControl>
									</div>
									<TextField
										id="outlined-select"
										select
										className="w-[150px]"
										value={month}
										onChange={(event) => setNewMonth(event.target.value)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<CalendarMonth />
												</InputAdornment>
											),
										}}
										sx={{
											width: '150px',
											height: '40px',
											borderRadius: "6px",
											'.MuiInputBase-root': { height: '40px' },
											'.MuiSelect-select': { height: '40px', display: 'flex', alignItems: 'center' }
										}}
									>
										{monthOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
								<div className="flex flex-row items-center gap-2">
									{/*	<div className="flex flex-row items-center justify-center w-[180px] h-[58px]">
										<div
											className="bg-black1 flex flex-row items-center justify-center gap-5 w-[180px] h-[58px] mx-2 px-6 py-2 text-white rounded-lg cursor-pointer"
											onClick={() => setShowModal(true)}
										>
											<span>{availabilityText('syncCalendar')}</span>
										</div>
									</div> */}
									{/* {filter !== 'import_ics_booking' && (
										<div className="flex flex-row items-center justify-center w-[180px] h-[58px]">
											<div
												className="bg-black1 flex flex-row items-center justify-center gap-5 w-[180px] h-[58px] mx-2 px-6 py-2 text-white rounded-lg cursor-pointer"
												onClick={() => exportIcs()}
											>
												<span>{availabilityText('exportIcal')}</span>
											</div>
										</div>
									)} */}

									<button onClick={handleCopyIcal} className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5 cursor-pointer'>
										<ContentCopyIcon /> <span>{availabilityText('copyCalendarLink')}</span>
									</button>
									<button onClick={() => setShowConnCalModal(true)} className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5 cursor-pointer'>
										<AddLinkIcon /> <span>{availabilityText('connect_calendar')}</span>
									</button>
								</div>
							</div>
							<Card className="pb-4 pr-4 pl-4 pt-0 h-full">
								<CardContent className="!p-0">
									<Toaster position="top-right" />
									<AvailabilityCalendar
										events={events}
										monthValue={calendarDate}
										onChange={onDaySelect}
										showModal={showModal}
										data={bookingsArray}
									/>
								</CardContent>
							</Card>
						</CardContent>
					</Card>
				</div>
			</div>

			{showModal && (
				<AvailabilityModal
					showModal={showModal}
					setShowModal={setShowModal}
					rentalUnitId={rentalUnitId}
					data={data}
				/>
			)}
			{
				showConnCalModal &&
				<ConnectCalendarModal
					rentalUnitId={rentalUnitId}
					icsLink={icsLink}
					showModal={showConnCalModal}
					onClose={() => setShowConnCalModal(false)}
				/>
			}
		</>
	)
}

export default Availability
