import React, { useEffect } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import cashIcon from '../../../assets/svgs/cash.svg'
import plusIcon from '../../../assets/svgs/plus.svg'
import share_link from '../../../assets/svgs/share_link.svg'
import cardIcon from '../../../assets/svgs/v2-dollar-payment-link.svg'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'

import { t } from 'i18next'
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import GeneratedLinkModal from '../../../components/orderpay/GeneratedLinkModal'
import PaymentLinkModal from '../../../components/orderpay/PaymentLinkModal'
import TableEmptyData from '../../../components/table/TableEmptyData'
import PaymentLinkService from '../../../services/paymentLinkService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export const paymentLinksText = (text) =>
	t(`orderandpay.paymentLinks.view.${text}`)

const PaymentLinks = () => {
	const { short_code } = useSelector((state) => state.app)

	const [data, setData] = useState([
		// { type: 'Cash on venue', party_size: '4 guests', created: '2023-06-10 16:30', exipers_at: '2023-06-10 16:30', user_time: '12', total: '$1200' }
	])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [newModal, setNewModal] = useState(false)
	const [generatedModal, setGeneratedModal] = useState(null)
	const [loaded, setLoaded] = useState(false)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const [date, setDate] = useState(new Date())

	useEffect(() => {
		refreshData(date)
	}, [])

	const refreshData = (currentDate) => {
		let payload = {
			date: moment(currentDate).format('YYYY-MM-DD 23:59:59'),
		}
		PaymentLinkService.ReadAll(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('payment service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'payments',
				})
				setLoaded(true)
				setData(res?.data || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('payment service', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'payments',
				})
				setLoaded(true)
			})
	}

	const onChangeDate = (currentDate) => {
		setDate(currentDate)
		setLoaded(false)
		refreshData(currentDate)
	}

	const showLinkModal = (data) => {
		setNewModal(false)
		if (data) {
			setGeneratedModal(data)
		}
	}

	return (
		<>
			{loaded ? (
				<div
					className="sub-container view-sales overflow-auto py-8 px-4 h-full"
					style={{ height: 'calc(100vh - 76px - 68px)' }}
				>
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center p-4 pl-7 pr-7">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{paymentLinksText('payment_links')}
									</span>
								</div>
								<div className="flex flex-row items-center gap-4">
									<CustomDateTimePicker
										onlyDate={true}
										classes="small"
										value={date}
										onChange={onChangeDate}
									/>

									<div
										className="bg-black1 flex flex-row px-4 text-white rounded-lg cursor-pointer"
										style={{
											paddingTop: '0.65rem',
											paddingBottom: '0.65rem',
										}}
										onClick={() => setNewModal(true)}
									>
										<img src={plusIcon} alt="search" className="mr-2" />
										<span>{paymentLinksText('generate_new')}</span>
									</div>
								</div>
							</div>
							{data.length ? (
								<div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
									<div
										className="flex flex-col overflow-auto"
										style={{
											boxShadow:
												'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
										}}
									>
										<Card>
											<CardContent className="!p-0">
												<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
													<div className="flex flex-row items-center">
														<span className="text-black1 text-l font-medium">
															{paymentLinksText('payment_links_history')}
														</span>
													</div>
												</div>
												<TableContainer component={Paper} className="mt-4">
													<Table aria-label="simple table">
														<TableHead className="bg-gray7">
															<TableRow>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="left"
																>
																	{paymentLinksText('type')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="left"
																>
																	{paymentLinksText('pay_structure')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="left"
																>
																	{paymentLinksText('party_size')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="left"
																>
																	{paymentLinksText('price_per_person')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{paymentLinksText('total')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{paymentLinksText('created')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{paymentLinksText('expires_at')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{paymentLinksText('used_times')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{paymentLinksText('link')}
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{data.map((item, index) => (
																<TableRow key={index}>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		<div className="flex flex-row justify-start align-center">
																			<div className="flex flex-col justify-start align-center">
																				<div
																					style={{ padding: '0.3rem' }}
																					className="bg-gray7 rounded-full"
																				>
																					<div
																						style={{ padding: '0.3rem' }}
																						className="bg-gray7 rounded-full"
																					>
																						<img
																							style={{ width: '20px' }}
																							src={
																								item.payment_type === 'card'
																									? cardIcon
																									: cashIcon
																							}
																							alt="icon"
																						/>
																					</div>
																				</div>
																			</div>
																			<p className="flex flex-col justify-start align-center text-center p-2">
																				{item.payment_type}
																			</p>
																		</div>
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.payment_structure}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.party_size}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.price_per_person
																			? `$` + item.price_per_person
																			: 0}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		${item.total}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.start_time}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.end_time}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.used_times}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-black1 font-bold !text-left !text-base"
																	>
																		<div className="flex flex-row justify-start align-center cursor-pointer">
																			<a href={item.link} target="_blank">
																				<p className="!text-black1 font-bold mr-2">
																					VB
																				</p>
																			</a>
																			<a href={item.link} target="_blank">
																				<img src={share_link} alt="icon" />
																			</a>
																		</div>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
													<TablePagination
														rowsPerPageOptions={[10, 25, 100]}
														component="div"
														count={data.length}
														rowsPerPage={rowsPerPage}
														page={page}
														className="!p-2"
														onPageChange={handleChangePage}
														onRowsPerPageChange={handleChangeRowsPerPage}
													/>
												</TableContainer>
											</CardContent>
										</Card>
									</div>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={paymentLinksText('payment_links')}
										btnLabel={paymentLinksText('new_payment_link')}
										onAction={() => setNewModal(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}

			<PaymentLinkModal
				showModal={newModal}
				onConfirm={showLinkModal}
				onClose={() => setNewModal(false)}
				onRefresh={null}
			/>
			<GeneratedLinkModal
				data={generatedModal}
				showModal={generatedModal != null}
				onClose={() => setGeneratedModal(null)}
				onRefresh={null}
			/>
		</>
	)
}

export default PaymentLinks
