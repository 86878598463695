import { API_BOWLING_LANES } from "../constants/API";
import apiFactory from "../utils/apiFactory";

const getBowlingLanes = async (param) => {
  let api = {
    content_type: "application/json",
    url: `${API_BOWLING_LANES}`,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const getBowlingLane = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_BOWLING_LANES}/${id}`,
    method: "GET",
  };
  return apiFactory(api);
};

const deleteBowlingLane = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_BOWLING_LANES}/${id}`,
    method: "DELETE",
  };
  return apiFactory(api);
};

const updateBowlingLane = async (payload, id) => {
  let api = {
    content_type: "application/json",
    url: `${API_BOWLING_LANES}/${id}`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const createBowlingLane = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_BOWLING_LANES}`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

export default {
  getBowlingLanes,
  getBowlingLane,
  deleteBowlingLane,
  updateBowlingLane,
  createBowlingLane
};
