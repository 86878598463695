import { API_BLOGS, API_BLOG_CATEGORIES } from "../constants/API";
import apiFactory from "../utils/apiFactory";

const getBlogCategories = async (query) => {
  let api = {
    content_type: 'application/json',
    url: `${API_BLOG_CATEGORIES}`,
    method: 'GET',
    query,
  }
  return apiFactory(api)
}

const createBlogCategory = async (payload) => {
  let api = {
    content_type: 'application/json',
    url: `${API_BLOG_CATEGORIES}`,
    method: 'POST',
  }
  return apiFactory(api, payload)
}

const updateBlogCategory = async (id, payload) => {
  let api = {
    content_type: 'application/json',
    url: `${API_BLOG_CATEGORIES}/${id}`,
    method: 'PUT',
  }
  return apiFactory(api, payload)
}

const delBlogCategory = async (id) => {
  let api = {
    content_type: 'application/json',
    url: `${API_BLOG_CATEGORIES}/${id}`,
    method: 'DELETE',
  }
  return apiFactory(api)
}

const getBlogs = async (query) => {
  let api = {
    content_type: 'application/json',
    url: `${API_BLOGS}`,
    method: 'GET',
    query,
  }
  return apiFactory(api)
}

const createBlog = async (payload) => {
  let api = {
    content_type: 'multipart/form-data',
    url: `${API_BLOGS}`,
    method: 'POST',
  }
  return apiFactory(api, payload)
}

const updateBlog = async (id, payload) => {
  let api = {
    content_type: 'multipart/form-data',
    url: `${API_BLOGS}/${id}`,
    method: 'POST',
  }
  return apiFactory(api, payload)
}

const delBlog = async (id) => {
  let api = {
    content_type: 'application/json',
    url: `${API_BLOGS}/${id}`,
    method: 'DELETE',
  }
  return apiFactory(api)
}

export default {
  getBlogCategories,
  createBlogCategory,
  updateBlogCategory,
  delBlogCategory,
  getBlogs,
  createBlog,
  updateBlog,
  delBlog
};
