import { API_PAYMENT_LINKS } from "../constants/API";
import apiFactory from "../utils/apiFactory";

export default class PaymentLinkService {
    static ReadAll = async params => {
        let api = {
          content_type: "application/json",
          url: API_PAYMENT_LINKS,
          method: "GET",
        };
        return apiFactory(api, {}, params);
      };
    static ReadOne = async (index) => {
        let api = {
          content_type: "application/json",
          url: `${API_PAYMENT_LINKS}/${index}`,
          method: "GET",
        };
        return apiFactory(api);
      };
    //   static Update = async (id, rawData) => {
    //     let api = {
    //       content_type: "application/json",
    //       url: `${API_PAYMENT_LINKS}/${id}`,
    //       method: "PUT",
    //     };
    //     return apiFactory(api);
    //   };
      static Create = async (rawData) => {
        let api = {
          content_type: "application/json",
          url: API_PAYMENT_LINKS,
          method: "POST",
        };
        return apiFactory(api, rawData);
      };
}