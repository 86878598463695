import { t } from 'i18next'
import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import EditSeatingArrangement from '../../components/table/editSeatingArrangement'
import TableService from '../../services/tableService'
import AreYouSureModal from '../common/areYouSureModal'
import GenericTable from '../generic-table/generic-table'

const getTranslation = (text) =>
	t(`components.table.SeatingArrangementView.${text}`)

export function SeatingArrangementView({
	data = [],
	tableShow = true,
	refresh,
}) {
	const [editModal, setEditModal] = useState(false)
	const [currentItem, setCurrentItem] = useState({})
	const [isAreYouSureModalOpen, setAreYouSureModalOpen] = useState(false)
	const [currentSeatingArrangement, setSeatingArrangement] = useState({})

	const { short_code } = useSelector((state) => state.app)

	const onEdit = (item) => {
		setEditModal(true)
		setCurrentItem(item)
	}
	const onDelete = (item) => {
		setSeatingArrangement(item)
		setAreYouSureModalOpen(true)
	}

	const columns = [
		{
			field: 'reservation',
			label: getTranslation('reservation'),
			align: 'left',
			cellComponent: ({ data }) => (
				<a
					href={`/${short_code}/admin/reservation/reservations/${data.id}`}
					className="underline text-black1 text-base font-semibold"
				>
					{data.id}
				</a>
			),
		},
		{
			field: 'time',
			label: getTranslation('timeframe'),
			align: 'left',
		},
		{
			field: 'guests',
			label: getTranslation('guests'),
			align: 'left',
			cellComponent: ({ data }) => data.length,
		},
		{
			field: 'table',
			label: getTranslation('table'),
			align: 'left',
			className: tableShow ? '' : 'hidden',
			cellComponent: ({ data }) => `${data.name} / ${data.number}`,
		},
		{
			field: 'dining_location',
			label: getTranslation('diningLocation'),
			align: 'left',
		},
		{
			field: 'actions',
			label: getTranslation('actions'),
			align: 'right',
		},
	]

	const onSubmit = () =>
		TableService.DeleteSeatingArrangement(currentSeatingArrangement)
			.then(() => {
				NotificationManager.success(getTranslation('deletedSuccessfully'))
				refresh()
			})
			.catch((e) => {
				NotificationManager.error(getTranslation('failed'), '')
			})

	return (
		<>
			<GenericTable
				data={data}
				columns={columns}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
			<EditSeatingArrangement
				showModal={editModal}
				onClose={() => setEditModal(false)}
				data={currentItem}
				refresh={refresh}
			/>
			{isAreYouSureModalOpen && (
				<AreYouSureModal
					title={getTranslation('deleteSeatingArrangementTitle')}
					content={getTranslation('deleteSeatingArrangementContent')}
					firstButton={getTranslation('noCancel')}
					secondButton={getTranslation('yesIAm')}
					showModal={isAreYouSureModalOpen}
					onClose={() => setAreYouSureModalOpen(false)}
					onSubmit={onSubmit}
				/>
			)}
		</>
	)
}
