import React from 'react';

import { t } from 'i18next'
import Cookies from 'universal-cookie'
import plusIcon from '../../assets/svgs/plus.svg'
import searchIcon from '../../assets/svgs/search.svg'
import { isAccommodation } from '../../helpers/venueHelpers'
import { KEYS } from '../../utils/storage'

export const programEmptyText = (text) =>
	t(`loyalty.programs.programEmpty.${text}`)

const ProgramsEmptyData = (props) => {
	const { btnLabel = '', onAction = () => {} } = props

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	return (
		<div className="flex flex-col items-center text-center mt-4">
			<div className="bg-gray7 p-2 rounded-full">
				<div className="bg-black1 p-2 rounded-full">
					<img src={searchIcon} alt={programEmptyText('searchIcon')} />
				</div>
			</div>
			<span className="text-black1 text-lg">
				{programEmptyText('noLoyaltyProgram')}
			</span>
			<span className="text-gray5 text-base mt-6">
				{programEmptyText('noLoyaltyProgramMessage')}
			</span>
			<span className="text-gray3 text-sm mt-3">
				{programEmptyText('guestsCompletionMessage', {
					venueType: isAccommodation(venueType) ? 'booking' : 'reservation',
				})}
			</span>
			<button
				className="bg-black1 flex flex-row items-center px-6 py-2 text-white rounded-lg cursor-pointer mt-4"
				onClick={onAction}
			>
				<img
					src={plusIcon}
					alt={programEmptyText('addLoyaltyProgramIcon')}
					className="mr-2"
				/>
				<span>{programEmptyText('addLoyaltyProgram')}</span>
			</button>
		</div>
	)
}

export default ProgramsEmptyData;
