import React, { useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Card, CardContent, Checkbox } from '@mui/material';
import { useState } from 'react';
import * as yup from 'yup';
import CustomTimePicker from '../custom/CustomTimePicker/CustomTimePicker';
import { RInputDeclare } from '../custom/RInputDeclare';

import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import VenueService from '../../services/venuService';
import { availabilityText } from '../../views/accommodation/rentalUnit/show/availability';
import LoadingScreen from '../custom/LoadingScreen';

// import './index.css';
const validator = yup.object().shape({
	neighborhood: yup
		.string()
		.trim()
		.required('Neighborhood is required')
		.max(72, 'Max exceeded')
		.min(2, 'Min not met'),
	golf_style: yup.string().trim().required('Golf Style is required'),
	parking_details: yup
		.string()
		.trim()
		.required('Parking details is required')
		.max(72, 'Max exceeded'),
	field_m2: yup
		.string()
		.trim()
		.required('M2 is required')
		.max(72, 'Max exceeded'),
	description: yup
		.string()
		.trim()
		.required('Description is required')
		.max(72, 'Max exceeded')
		.min(5, 'Min not met'),
	additional: yup
		.string()
		.trim()
		.required('Additional is required')
		.max(72, 'Max exceeded'),
	main_tag: yup
		.string()
		.trim()
		.required('Main Tag is required')
		.max(72, 'Max exceeded'),
	payment_options: yup.array().required().min(1, 'Please select payment type'),
	tags: yup.array().required().min(1, 'Please select tag'),
})

const Availability = ({ title }) => {
	const api_type = 'golf'
	const [loading, setLoading] = useState(true)

	// checkbox
	const [monday, setMonday] = useState(false)
	const [tuesday, setTuesday] = useState(false)
	const [wendsday, setWendsday] = useState(false)
	const [thursday, setThursday] = useState(false)
	const [friday, setFriday] = useState(false)
	const [saturday, setSaturday] = useState(false)
	const [sunday, setSunday] = useState(false)

	// start times
	const [mondayStartTime, setMondayStartTime] = useState(new Date())
	const [tuesdayStartTime, setTuesdayStartTime] = useState(new Date())
	const [wendsdayStartTime, setWendsdayStartTime] = useState(new Date())
	const [thursdayStartTime, setThursdayStartTime] = useState(new Date())
	const [fridayStartTime, setFridayStartTime] = useState(new Date())
	const [saturdayStartTime, setSaturdayStartTime] = useState(new Date())
	const [sundayStartTime, setSundayStartTime] = useState(new Date())

	// end time
	const [mondayEndTime, setMondayEndTime] = useState(new Date())
	const [tuesdayEndTime, setTuesdayEndTime] = useState(new Date())
	const [wendsdayEndTime, setWendsdayEndTime] = useState(new Date())
	const [thursdayEndTime, setThursdayEndTime] = useState(new Date())
	const [fridayEndTime, setFridayEndTime] = useState(new Date())
	const [saturdayEndTime, setSaturdayEndTime] = useState(new Date())
	const [sundayEndTime, setSundayEndTime] = useState(new Date())

	useEffect(() => {
		getDetails()
	}, [])

	const getDetails = () => {
		// todo: we need to check lane availability
		setLoading(false)
	}

	const onSubmitAvailibilty = () => {
		const payload = {
			type: api_type,
			availability: [],
		}
		if (monday)
			payload.availability.push({
				day_of_week: 'Monday',
				open_time: moment(mondayStartTime).format('HH:mm'),
				close_time: moment(mondayEndTime).format('HH:mm'),
			})
		if (tuesday)
			payload.availability.push({
				day_of_week: 'Tuesday',
				open_time: moment(tuesdayStartTime).format('HH:mm'),
				close_time: moment(tuesdayEndTime).format('HH:mm'),
			})
		if (wendsday)
			payload.availability.push({
				day_of_week: 'Wednesday',
				open_time: moment(wendsdayStartTime).format('HH:mm'),
				close_time: moment(wendsdayEndTime).format('HH:mm'),
			})
		if (thursday)
			payload.availability.push({
				day_of_week: 'Thursday',
				open_time: moment(thursdayStartTime).format('HH:mm'),
				close_time: moment(thursdayEndTime).format('HH:mm'),
			})
		if (friday)
			payload.availability.push({
				day_of_week: 'Friday',
				open_time: moment(fridayStartTime).format('HH:mm'),
				close_time: moment(fridayEndTime).format('HH:mm'),
			})
		if (saturday)
			payload.availability.push({
				day_of_week: 'Saturday',
				open_time: moment(saturdayStartTime).format('HH:mm'),
				close_time: moment(saturdayEndTime).format('HH:mm'),
			})
		if (sunday)
			payload.availability.push({
				day_of_week: 'Sunday',
				open_time: moment(sundayStartTime).format('HH:mm'),
				close_time: moment(sundayEndTime).format('HH:mm'),
			})

		setLoading(true)
		VenueService.UpdateVenueAvailability(payload)
			.then((res) => {
				NotificationManager.success(
					'Golf Availability has been saved successfully'
				)
				setLoading(false)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				setLoading(false)
				throw new Error('Problem on updating Golf Availability')
			})
	}

	if (loading) {
		return <LoadingScreen />
	}
	return (
		<div
			className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card className="mt-6">
				<p
					style={{ padding: '20px 15px 20px 15px' }}
					className="text-black1 font-medium text-[20px]"
				>
					{title} {availabilityText('availability')}
				</p>
				<CardContent style={{ background: '#f6f6f8' }}>
					<div className="flex flex-col">
						<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('mon')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={monday}
										onChange={(e) => setMonday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={mondayStartTime}
										onChange={setMondayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={mondayEndTime}
										onChange={setMondayEndTime}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('fri')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={friday}
										onChange={(e) => setFriday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={fridayStartTime}
										onChange={setFridayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={fridayEndTime}
										onChange={setFridayEndTime}
									/>
								</div>
							</div>
						</div>

						<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('tue')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={tuesday}
										onChange={(e) => setTuesday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={tuesdayStartTime}
										onChange={setTuesdayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={tuesdayEndTime}
										onChange={setTuesdayEndTime}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('sat')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={saturday}
										onChange={(e) => setSaturday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={saturdayStartTime}
										onChange={setSaturdayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={saturdayEndTime}
										onChange={setSaturdayEndTime}
									/>
								</div>
							</div>
						</div>

						<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('wed')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={wendsday}
										onChange={(e) => setWendsday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={wendsdayStartTime}
										onChange={setWendsdayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={wendsdayEndTime}
										onChange={setWendsdayEndTime}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('sun')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={sunday}
										onChange={(e) => setSunday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={sundayStartTime}
										onChange={setSundayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={sundayEndTime}
										onChange={setSundayEndTime}
									/>
								</div>
							</div>
						</div>

						<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
							<div className="flex flex-row justify-start center-align p-2 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('thu')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<Checkbox
										checked={thursday}
										onChange={(e) => setThursday(e.target.checked)}
										className="mt-2"
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('open_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={thursdayStartTime}
										onChange={setThursdayStartTime}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={availabilityText('end_time')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<CustomTimePicker
										classes="bg-white"
										value={thursdayEndTime}
										onChange={setThursdayEndTime}
									/>
								</div>
							</div>
							<div className="flex flex-col justify-start center-align p-2">
								<div className="flex flex-row justify-center align-center h-[48px] mt-4">
									<button
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={onSubmitAvailibilty}
									>
										{availabilityText('update')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default Availability
