import React, { useEffect, useState } from 'react'
import NavbarPOS from './NavbarPOS'
// import Sidebar from "./Sidebar";
import FooterPOS from './FooterPOS'
import './index.css'

import { useSelector } from 'react-redux'
import useDebounce from '../components/Hooks/useDebounce'
import menuService from '../services/menuService'
import { isEmpty } from '../utils/common'
import { identifyVenue, trackEvent } from '../utils/mixpanelUtil'
import FoodCheckoutMobile from '../views/POS/FoodCheckoutMobile'

export default function LayoutPOSMobile() {
	const { short_code } = useSelector((state) => state.app)

	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)

	const [search, setSearch] = useState('')
	const debouncedValue = useDebounce(search, 900)

	const [updateCart, setUpdateCart] = useState(false)

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [debouncedValue])

	const refreshData = () => {
		let query = []
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}

		menuService
			.getProducts(query.join('&'), short_code)
			.then((res) => {
				setLoaded(true)
				setData(res?.products || [])
				identifyVenue(short_code)
				trackEvent('Products List POS', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch((err) => {
				setLoaded(true)
				identifyVenue(short_code)
				trackEvent('Products List POS', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'product',
				});
				throw new Error("Problem retrieving Products List for POS Food mobile");
			})
	}
	return (
		<div className="flex flex-row w-full h-full">
			<div className="flex flex-col w-full justify-between flex-1 h-full">
				<div>
					<NavbarPOS />
				</div>
				<div className="flex flex-1 h-full">
					<FoodCheckoutMobile list={data} />
				</div>
				<div>
					<FooterPOS />
				</div>
			</div>
		</div>
	)
}
