import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Button, Card, CardContent, IconButton } from '@mui/material'
// import { NotificationManager } from "react-notifications";

import ReservationService from '../../../services/reservationService'
import TableService from '../../../services/tableService'
import AssignOrderModal from '../../../components/reservation/AssignOrderModal'

import AssignOrderConfirmModal from '../../../components/reservation/AssignOrderConfirmModal'

import plusIcon from '../../../assets/svgs/plus.svg'
import checkIcon from '../../../assets/svgs/check_green.svg'
import closeIcon from '../../../assets/svgs/close_red.svg'
import { GuestOrderDetailBlock } from './GuestOrderDetailBlock'
import { OrderHistoryBlock } from './OrderHistoryBlock'
import OrderInfo from './OrderInfo'
import OrderSummary from './OrderSummary'
import OrderItems from './OrderItems'
import { NotificationManager } from 'react-notifications'
import orderService from '../../../services/orderService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { useSelector } from 'react-redux'

// import PaymentPromo from "./PaymentPromo";
// import OrderSummary from "./OrderSummary";
// import OrderItems from "./OrderItems";

const OrderDetailScreen = () => {
	const { short_code } = useSelector((state) => state.app)

	let navigate = useNavigate()
	const { id: orderId } = useParams()
	const [tableData, setTableData] = useState({})
	const [showOrderModal, setShowOrderModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [order, setOrder] = useState({})
	const [confirm, setConfirm] = useState(false)

	useEffect(() => {
		orderService
			.ReadOne(orderId)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('order service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venues',
				})
				setOrder(res.data)
				let table_id = res.data.table_id
				TableService.GetAll()
					.then((res) => {
						identifyVenue(short_code)
						trackEvent('table service', {
							action_category: 'get',
							action_outcome: 'success',
							interaction_element: 'tables',
						})
						let raworderData = res?.tables.find((o) => o.id === table_id)
						if (raworderData) {
							setTableData(raworderData)
						}
					})
					.catch((e) => {
						identifyVenue(short_code)
						trackEvent('table service', {
							action_category: 'get',
							action_outcome: 'success',
							interaction_element: 'tables',
						})
					})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('order service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venues',
				})
			})
		// eslint-disable-next-line
	}, [])

	const handleOrderSubmit = (data) => {
		setShowOrderModal(false)
		setOrder(data)
		// setShowConfirmModal(true);
		ReservationService.AssignOrder(orderId, data)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('reservation service', {
					action_category: 'put',
					action_outcome: 'success',
					interaction_element: 'assign order',
				})
				NotificationManager.success(
					res?.message ?? 'Order submited successfully'
				)
				// call use refresh or confirm Order
			})
			.catch((e) => {
				identifyVenue(short_code)
				trackEvent('reservation service', {
					action_category: 'put',
					action_outcome: 'success',
					interaction_element: 'assign order',
				})
				NotificationManager.error('Failed', '')
			})
	}

	return (
		<div className="flex flex-col h-full p-6 h-full">
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 py-5">
						<div className="flex flex-row items-center">
							<span className="text-black1 text-xl font-medium">
								Order Details
							</span>
						</div>
					</div>
					<div className="flex flex-col px-2 py-4 bg-[#F9F7F7] overflow-auto">
						<div
							className=" flex flex-col py-4 px-5"
							style={{
								height: 'calc(100vh - 76px - 68px - 78px - 64px - 32px)',
							}}
						>
							<OrderInfo data={tableData} />
							<div className="flex flex-row gap-8 mt-4 w-full">
								{/* <TableInfo data={orderData} /> */}
								<GuestOrderDetailBlock data={order} />
								<OrderHistoryBlock data={order?.history ?? []} />
							</div>
							{/* <ReservationOrders data={reservationData.orders || []}/> */}
							{!confirm && (
								<div className="flex flex-row gap-8 mt-4 w-full">
									<div className="flex flex-col w-[65%] gap-4">
										<OrderSummary data={order} />
									</div>
									<OrderItems data={order} />
								</div>
							)}
							<div style={{ minHeight: 40 }} />
						</div>
					</div>
				</CardContent>
			</Card>
			{showOrderModal && (
				<AssignOrderModal
					reservation_id={orderId}
					data={order}
					showModal={showOrderModal}
					onClose={(refresh) => {
						setShowOrderModal(false)
					}}
					onSubmit={handleOrderSubmit}
				/>
			)}
			{/* {showConfirmModal &&
				<AssignOrderConfirmModal
					reservation_id={reservationId}
					data = {order}
					showModal={showConfirmModal}
					onClose={(refresh) => {
						setShowConfirmModal(false)
					}}
				/>
			} */}
			{showConfirmModal && (
				<AssignOrderConfirmModal
					reservation_id={'reservationId'}
					data={{
						selectedProducts: [{ title: 'Pasta', quantity: 1, price: 100 }],
					}}
					showModal={showConfirmModal}
					onClose={(refresh) => {
						setShowConfirmModal(false)
					}}
				/>
			)}
		</div>
	)
}
export default OrderDetailScreen
