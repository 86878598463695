import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CustomSelect from '../../../components/custom/CustomSelect'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'

import { Campaign, CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { NotificationManager } from 'react-notifications'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'
import MarketingService from '../../../services/marketingService'

import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../../helpers/venueHelpers'

import { t } from 'i18next'
import Cookies from 'universal-cookie'
import { endUsersText } from '.'
import AssistantButton from '../../../components/assistantButton'
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot'
import { KEYS } from '../../../utils/storage'
import { productText } from '../../menu/products'

export const endUsersModalText = (text) =>
	t(`marketing.endusercards.EndUserCardsModal.${text}`)

export default function EndUserCardsModal({
	showModal,
	data,
	guests = [],
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	// Format the guests array into id, value pairs
	const formattedGuests = guests.map((guest) => ({
		id: guest.id,
		value: guest.name,
	}))
	const [state, setState] = useState({
		available: 1,
	})

	const cookies = new Cookies()

	const venueType = cookies.get(KEYS.VENUE_TYPE)

	let guestString = 'Select guest'
	if (isAccommodation(venueType)) {
		guestString = 'Select guest'
	}
	if (isFB(venueType)) {
		guestString = 'Select guest'
	}
	if (isEntertainmentVenue(venueType)) {
		guestString = 'Select customer'
	}
	if (isRetailVenue(venueType)) {
		guestString = 'Select customer'
	}

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setIsEdit(true)
				setState(data)
				setState({ ...data, guestName: data.guest.id })
			}
		}
		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const onChangeIssuedAt = (currentDate) => {
		setState({ ...state, issued_at: currentDate })
	}

	const onChangeExpireDate = (currentDate) => {
		setState({ ...state, expiration_date: currentDate })
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'user_card_note'
		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		setState({ ...state, notes: generated })
		setAiQuery('')
		setGenerated('')
	}

	const onSubmit = () => {
		const { issued_at, expiration_date } = state

		if (issued_at && expiration_date) {
			if (state?.id) {
				setLoading(true)
				MarketingService.UpdateEndUserCards(state)
					.then((res) => {
						onClose(true)
						setLoading(false)
					})
					.catch((res) => {
						NotificationManager.error('Failed', '')
						setLoading(false)
					})
			} else {
				setLoading(true)
				MarketingService.CreateEndUserCards(state)
					.then((res) => {
						onClose(true)
						setLoading(false)
					})
					.catch(() => {
						NotificationManager.error('Failed', '')
						setLoading(false)
						throw new Error(endUsersText('problem_endusers'))
					})
			}
		}
	}

	const handleChangeGuest = (value, index) => {
		setState((v) => ({ ...v, guestName: value, guest_id: guests[index].id }))
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-2 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 text-white p-2 rounded-full">
								<Campaign />
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{data?.id != null
									? endUsersModalText('updateCard')
									: endUsersModalText('generateCard')}
							</label>
						</div>
					</div>
					<div className="flex flex-col pb-0 p-024 mb-4">
						{!isEdit && (
							<>
								<RInputDeclare
									value={guestString}
									className="!mt-0 !mb-2 !text-black1 font-medium"
								/>
								<CustomSelect
									values={
										guests.length > 0 ? guests.map((item) => item.name) : []
									}
									value={state?.guestName}
									placeholder={guestString}
									height={'!h-10'}
									className={'!h-10 border !border-gray6'}
									handleChange={handleChangeGuest}
								/>
							</>
						)}

						<RInputDeclare
							value={endUsersModalText('issueDate')}
							className="!mt-4 !mb-0 !text-black1 font-medium"
						/>
						<CustomDateTimePicker
							onlyDate={true}
							value={state.issued_at}
							onChange={onChangeIssuedAt}
						/>

						<RInputDeclare
							value={endUsersModalText('expirationDate')}
							className="!mt-4 !mb-0 !text-black1 font-medium"
						/>
						<CustomDateTimePicker
							onlyDate={true}
							value={state.expiration_date}
							onChange={onChangeExpireDate}
						/>

						<RInputDeclare
							value={endUsersModalText('notes')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							size="small"
							multiline
							minRows={2}
							value={state?.notes}
							placeholder={endUsersModalText('enterNotes')}
							className="!text-slate-700"
							onChange={(e) => setState({ ...state, notes: e.target.value })}
						/>

						<div className="flex flex-row justify-end mt-2">
							<AssistantButton
								onAction={() => handleOpenAIModal('Card notes')}
							/>
						</div>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{endUsersModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								`${
									state?.id
										? endUsersModalText('update')
										: endUsersModalText('create')
								}`
							)}
						</button>
					</div>
				</Box>
			</Modal>
			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{endUsersModalText('aiAssistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{endUsersModalText('aiTip')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-tranparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												endUsersModalText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{productText('add_to')} {aiField}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
