import React from 'react'
import SidebarPOSFood from '../../../containers/SidebarPOSFood'

const PlaceFoodOrderMobile = () => {
   return (
      <div>
         <SidebarPOSFood />
      </div>
   )
}

export default PlaceFoodOrderMobile
