import v2AccountSubscriptionStandard from '../../../../assets/svgs/v2-account-subscription-standard.svg'
import v2AccountSubscriptionWhite from '../../../../assets/svgs/v2-account-subscription-white.svg'
import v2AccountVenueSpaceStandard from '../../../../assets/svgs/v2-account-venue-space-standard.svg'
import v2AccountVenueSpaceWhite from '../../../../assets/svgs/v2-account-venue-space-white.svg'
import v2AdvancedAccountStandard from '../../../../assets/svgs/v2-advanced-account-standard.svg'
import v2AdvancedAccountWhite from '../../../../assets/svgs/v2-advanced-account-white.svg'
import v2AnalyticsStandard from '../../../../assets/svgs/v2-analytics-standard.svg'
import v2AnalyticsWhite from '../../../../assets/svgs/v2-analytics-white.svg'
import v2CampaignStandard from '../../../../assets/svgs/v2-campaign-standard.svg'
import v2CampaignWhite from '../../../../assets/svgs/v2-campaign-white.svg'
import v2CustomerInsightStandard from '../../../../assets/svgs/v2-customer_insights-standard.svg'
import v2CustomerInsightWhite from '../../../../assets/svgs/v2-customer_insights-white.svg'
import v2DashboardStandard from '../../../../assets/svgs/v2-dashboard-standard.svg'
import v2DashboardWhite from '../../../../assets/svgs/v2-dashboard-white.svg'
import v2DeliveryPickupStandard from '../../../../assets/svgs/v2-deliverypickup-standard.svg'
import v2DeliveryPickupWhite from '../../../../assets/svgs/v2-deliverypickup-white.svg'
import v2DiningStandard from '../../../../assets/svgs/v2-dining-standard.svg'
import v2DiningWhite from '../../../../assets/svgs/v2-dining-white.svg'
import v2DiscountStandard from '../../../../assets/svgs/v2-discount-standard.svg'
import v2DiscountWhite from '../../../../assets/svgs/v2-discount-white.svg'
import v2EmailStandard from '../../../../assets/svgs/v2-email-standard.svg'
import v2EmailWhite from '../../../../assets/svgs/v2-email-white.svg'
import v2GuestStandard from '../../../../assets/svgs/v2-guest-standard.svg'
import v2GuestWhite from '../../../../assets/svgs/v2-guest-white.svg'
import v2GuestsEnrolledStandard from '../../../../assets/svgs/v2-guests-enrolled-standard.svg'
import v2GuestsEnrolledWhite from '../../../../assets/svgs/v2-guests-enrolled-white.svg'
import v2IntegrationStandard from '../../../../assets/svgs/v2-integration-standard.svg'
import v2IntegrationWhite from '../../../../assets/svgs/v2-integration-white.svg'
import v2LoyaltyStandard from '../../../../assets/svgs/v2-loyalty-standard..svg'
import v2LoyaltyWhite from '../../../../assets/svgs/v2-loyalty-white.svg'
import v2LoyaltyProgramStandard from '../../../../assets/svgs/v2-loyaltyprogram-standart.svg'
import v2LoyaltyProgramWhite from '../../../../assets/svgs/v2-loyaltyprogram-white.svg'
import v2MarketingStandard from '../../../../assets/svgs/v2-marketing-standard.svg'
import v2MarketingWhite from '../../../../assets/svgs/v2-marketing-white.svg'
import v2MenuCategoryStandard from '../../../../assets/svgs/v2-menu-category-standard.svg'
import v2MenuCategoryWhite from '../../../../assets/svgs/v2-menu-category-white.svg'
import v2MenuInventoryStandard from '../../../../assets/svgs/v2-menu-inventory-standard.svg'
import v2MenuInventoryWhite from '../../../../assets/svgs/v2-menu-inventory-white.svg'
import v2MenuProductStandard from '../../../../assets/svgs/v2-menu-product-standard.svg'
import v2MenuProductWhite from '../../../../assets/svgs/v2-menu-product-white.svg'
import v2MenuStandard from '../../../../assets/svgs/v2-menu-standard.svg'
import v2MenuWhite from '../../../../assets/svgs/v2-menu-white.svg'
import v2OrderPaymentLinkStandard from '../../../../assets/svgs/v2-orderpay-paymentlinks-standard.svg'
import v2OrderPaymentLinkWhite from '../../../../assets/svgs/v2-orderpay-paymentlinks-white.svg'
import v2OrderAndPayStandard from '../../../../assets/svgs/v2-orderpay-standard.svg'
import v2OrderAndPayWhite from '../../../../assets/svgs/v2-orderpay-white.svg'
import v2ReferralStandard from '../../../../assets/svgs/v2-referal-standard.svg'
import v2ReferralWhite from '../../../../assets/svgs/v2-referal-white.svg'
import v2ReportStandard from '../../../../assets/svgs/v2-report-standard.svg'
import v2ReportWhite from '../../../../assets/svgs/v2-report-white.svg'
import v2ReservationStandard from '../../../../assets/svgs/v2-reservation-standard.svg'
import v2ReservationWhite from '../../../../assets/svgs/v2-reservation-white.svg'
import v2SeatingArrangementsStandard from '../../../../assets/svgs/v2-seating-arrangements-standard.svg'
import v2SeatingArrangementsWhite from '../../../../assets/svgs/v2-seating-arrangements-white.svg'
import v2SettingStandard from '../../../../assets/svgs/v2-setting-standard.svg'
import v2SettingWhite from '../../../../assets/svgs/v2-setting-white.svg'
import v2StaffStandard from '../../../../assets/svgs/v2-staff-standard.svg'
import v2StaffWhite from '../../../../assets/svgs/v2-staff-white.svg'
import v2TableAvailableStandard from '../../../../assets/svgs/v2-table-available-standard.svg'
import v2TableAvailableWhite from '../../../../assets/svgs/v2-table-available-white.svg'
import v2TableStandard from '../../../../assets/svgs/v2-table-standard.svg'
import v2TableWhite from '../../../../assets/svgs/v2-table-white.svg'
import v2TOStandard from '../../../../assets/svgs/v2-timeoff-standard.svg'
import v2TOWhite from '../../../../assets/svgs/v2-timeoff-white.svg'
import v2WaitlistStandard from '../../../../assets/svgs/v2-waitlist-standard.svg'
import v2WaitlistWhite from '../../../../assets/svgs/v2-waitlist-white.svg'
import { getFoodTranslation } from '../bakery'

export const FoodStallSidebar = [
	{
		img: v2DashboardStandard,
		imgActive: v2DashboardWhite,
		key: 1,
		name: 'dashboard',
		text: getFoodTranslation('dashboard'),
		default: true,
		menus: [
			{
				img: v2DashboardStandard,
				imgActive: v2DashboardWhite,
				name: 'venue-dashboard',
				text: getFoodTranslation('venue-dashboard'),
				key: 1,
			},
			{
				img: v2AnalyticsStandard,
				imgActive: v2AnalyticsWhite,
				name: 'analytics',
				text: getFoodTranslation('analytics'),
				key: 2,
			},
			{
				img: v2CustomerInsightStandard,
				imgActive: v2CustomerInsightWhite,
				name: 'insights',
				text: getFoodTranslation('insights'),
				key: 3,
			},
		],
	},
	{
		img: v2MenuStandard,
		imgActive: v2MenuWhite,
		key: 2,
		name: 'menu',
		text: getFoodTranslation('menu'),
		menus: [
			{
				img: v2MenuStandard,
				imgActive: v2MenuWhite,
				name: 'menu',
				text: getFoodTranslation('menu'),
				key: 1,
			},
			{
				img: v2MenuProductStandard,
				imgActive: v2MenuProductWhite,
				name: 'items',
				text: getFoodTranslation('items'),
				key: 2,
			},
			{
				img: v2MenuCategoryStandard,
				imgActive: v2MenuCategoryWhite,
				name: 'categories',
				text: getFoodTranslation('categories'),
				key: 3,
			},
			{
				img: v2MenuInventoryStandard,
				imgActive: v2MenuInventoryWhite,
				name: 'inventory',
				text: getFoodTranslation('inventory'),
				key: 4,
			},
		],
	},
	{
		img: v2ReservationStandard,
		imgActive: v2ReservationWhite,
		key: 3,
		name: 'reservation',
		text: getFoodTranslation('reservation'),
		menus: [
			{
				img: v2ReservationStandard,
				imgActive: v2ReservationWhite,
				name: 'reservations',
				text: getFoodTranslation('reservations'),
				key: 1,
			},
			{
				img: v2WaitlistStandard,
				imgActive: v2WaitlistWhite,
				name: 'waitlist',
				text: getFoodTranslation('waitlist'),
				key: 2,
			},
			{
				img: v2DiningStandard,
				imgActive: v2DiningWhite,
				name: 'dining-space-locations',
				text: getFoodTranslation('dining-space-locations'),
				key: 3,
			},
			{
				img: v2SeatingArrangementsStandard,
				imgActive: v2SeatingArrangementsWhite,
				name: 'seating-arrangement',
				text: getFoodTranslation('seating-arrangement'),
				key: 4,
			},
			{
				img: v2MarketingStandard,
				imgActive: v2MarketingWhite,
				name: 'reservation-calendar',
				text: getFoodTranslation('reservation-calendar'),
				key: 5,
			},
		],
	},
	{
		img: v2TableStandard,
		imgActive: v2TableWhite,
		key: 4,
		name: 'table',
		text: getFoodTranslation('table'),
		menus: [
			{
				img: v2TableStandard,
				imgActive: v2TableWhite,
				name: 'tables',
				text: getFoodTranslation('tables'),
				key: 1,
			},
			{
				img: v2TableAvailableStandard,
				imgActive: v2TableAvailableWhite,
				name: 'available',
				text: getFoodTranslation('available'),
				key: 2,
			},
		],
	},
	{
		img: v2GuestStandard,
		imgActive: v2GuestWhite,
		key: 5,
		name: 'guests',
		text: getFoodTranslation('guests'),
		menus: [
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'guests',
				text: getFoodTranslation('guests'),
				key: 1,
			},
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'communication-preferences',
				text: getFoodTranslation('communication-preferences'),
				key: 2,
			},
			{
				img: v2OrderAndPayStandard,
				imgActive: v2OrderAndPayWhite,
				name: 'surveys-and-ratings',
				text: getFoodTranslation('surveys-and-ratings'),
				key: 3,
			},
		],
	},
	{
		img: v2StaffStandard,
		imgActive: v2StaffWhite,
		key: 6,
		name: 'staff',
		text: getFoodTranslation('staff'),
		menus: [
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'employees',
				text: getFoodTranslation('employees'),
				key: 1,
			},
			{
				img: v2TOStandard,
				imgActive: v2TOWhite,
				name: 'time-off-requests',
				text: getFoodTranslation('time-off-requests'),
				key: 2,
			},
			{
				img: v2IntegrationStandard,
				imgActive: v2IntegrationWhite,
				name: 'staff-integrations',
				text: getFoodTranslation('staff-integrations'),
				key: 3,
			},
		],
	},
	{
		img: v2MarketingStandard,
		imgActive: v2MarketingWhite,
		key: 7,
		name: 'marketing',
		text: getFoodTranslation('marketing'),
		menus: [
			{
				img: v2DiscountStandard,
				imgActive: v2DiscountWhite,
				name: 'promotions',
				text: getFoodTranslation('promotions'),
				key: 1,
			},
			{
				img: v2EmailStandard,
				imgActive: v2EmailWhite,
				name: 'emails',
				text: getFoodTranslation('emails'),
				key: 2,
			},
			{
				img: v2ReferralStandard,
				imgActive: v2ReferralWhite,
				name: 'referrals',
				text: getFoodTranslation('referrals'),
				key: 3,
			},
			{
				img: v2CampaignStandard,
				imgActive: v2CampaignWhite,
				name: 'campaigns',
				text: getFoodTranslation('campaigns'),
				key: 4,
			},
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'enduser-card',
				text: getFoodTranslation('enduser-card'),
				key: 5,
			},
			{
				img: v2OrderAndPayStandard,
				imgActive: v2OrderAndPayWhite,
				name: 'wallet',
				text: getFoodTranslation('wallet'),
				key: 6,
			},
		],
	},
	{
		img: v2ReportStandard,
		imgActive: v2ReportWhite,
		key: 8,
		name: 'reporting',
		text: getFoodTranslation('reporting'),
		menus: [
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'staff-reporting',
				text: getFoodTranslation('staff-reporting'),
				key: 1,
			},
			{
				img: v2AnalyticsStandard,
				imgActive: v2AnalyticsWhite,
				name: 'sales-reporting',
				text: getFoodTranslation('sales-reporting'),
				key: 2,
			},
			{
				img: v2WaitlistStandard,
				imgActive: v2WaitlistWhite,
				name: 'waitlist-reporting',
				text: getFoodTranslation('waitlist-reporting'),
				key: 3,
			},
			{
				img: v2TableAvailableStandard,
				imgActive: v2TableAvailableWhite,
				name: 'tables-reporting',
				text: getFoodTranslation('tables-reporting'),
				key: 4,
			},
			{
				img: v2AdvancedAccountStandard,
				imgActive: v2AdvancedAccountWhite,
				name: 'advanced',
				text: getFoodTranslation('advanced'),
				key: 5,
			},
		],
	},
	{
		img: v2SettingStandard,
		imgActive: v2SettingWhite,
		key: 10,
		name: 'settings',
		text: getFoodTranslation('settings'),
		menus: [
			{
				img: v2AccountSubscriptionStandard,
				imgActive: v2AccountSubscriptionWhite,
				name: 'subscription',
				text: getFoodTranslation('subscription'),
				key: 1,
			},
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'account',
				text: getFoodTranslation('account'),
				key: 2,
			},
			{
				img: v2AccountVenueSpaceStandard,
				imgActive: v2AccountVenueSpaceWhite,
				name: 'space',
				text: getFoodTranslation('space'),
				key: 3,
			},
			{
				img: v2AdvancedAccountStandard,
				imgActive: v2AdvancedAccountWhite,
				name: 'web',
				text: getFoodTranslation('web'),
				key: 4,
			},
			{
				img: v2TableStandard,
				imgActive: v2TableWhite,
				name: 'hygiene-standard',
				text: getFoodTranslation('hygiene-standard'),
				key: 5,
			},
		],
	},
	{
		img: v2LoyaltyStandard,
		imgActive: v2LoyaltyWhite,
		key: 11,
		name: 'loyalty',
		text: getFoodTranslation('loyalty'),
		menus: [
			{
				img: v2LoyaltyProgramStandard,
				imgActive: v2LoyaltyProgramWhite,
				name: 'programs',
				text: getFoodTranslation('programs'),
				key: 1,
			},
			{
				img: v2GuestsEnrolledStandard,
				imgActive: v2GuestsEnrolledWhite,
				name: 'guests-enrolled',
				text: getFoodTranslation('guests-enrolled'),
				key: 2,
			},
		],
	},
	{
		img: v2OrderAndPayStandard,
		imgActive: v2OrderAndPayWhite,
		key: 14,
		name: 'order-and-pay',
		text: getFoodTranslation('order-and-pay'),
		menus: [
			{
				img: v2DeliveryPickupStandard,
				imgActive: v2DeliveryPickupWhite,
				name: 'order-and-pay-orders',
				text: getFoodTranslation('order-and-pay-orders'),
				key: 1,
			},
			{
				img: v2OrderPaymentLinkStandard,
				imgActive: v2OrderPaymentLinkWhite,
				name: 'payment-links',
				text: getFoodTranslation('payment-links'),
				key: 2,
			},
		],
	},
]
