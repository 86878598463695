import apiFactory from "../utils/apiFactory";
import { API_RESERVATION, API_RESERVATION_FILTER } from "../constants/API";

export default class ReservationService {
  static Create = async (rawData) => {
    let api = {
      content_type: "application/json",
      url: API_RESERVATION,
      method: "POST",
    };
    return apiFactory(api, rawData);
  };
  static ReadAll = async () => {
    let api = {
      content_type: "application/json",
      url: API_RESERVATION,
      method: "GET",
    };
    return apiFactory(api);
  };
  static ReadOne = async (index) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${index}`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static Update = async (id, rawData) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}`,
      method: "PUT",
    };
    return apiFactory(api);
  };
  static Cancel = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}/confirm`,
      method: "PATCH",
    };
    return apiFactory(api, {confirmed : "2"});
  };
  
  static Confirm = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}/confirm`,
      method: "PATCH",
    };
    return apiFactory(api, {confirmed : "1"});
  };

  static Filter = async (param) => {
    let api = {
      content_type: "application/json",
      url: API_RESERVATION_FILTER,
      method: "GET",
    };
    return apiFactory(api, {}, param);
  };

  static ApplyPromo = async (id, payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}/apply-promo`,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  };

  static ProvidePaymentMethod = async (id, payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}/provide-payment-method`,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  };

  static AssignOrder = async (id,payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}/assign-order`,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  };

  static GetGuestValidPromotion = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_RESERVATION}/${id}/guest-valid-promotions`,
      method: "GET",
    };
    return apiFactory(api);
  };
}
