import { ContentCopyOutlined } from '@mui/icons-material'
import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router'
import LoadingScreen from '../../../../components/custom/LoadingScreen'
import accommodationService from '../../../../services/accommodationService'
import AccommodationForm from './accommodationForm'
import FacilitiesForm from './facilitiesForm'
import NameAndLocationForm from './nameAndLocationForm'
import PhotosForm from './photosForm'

import { useSearchParams } from 'react-router-dom'
import PricingAndCalendarForm from './pricingAndCalendarForm'
// import RoomAmenitiesForm from "./roomAmenitiesForm";
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import Availability from './availability'
import PoliciesAndRulesForm from './policiesAndRulesForm'

export const rentalShowText = (text) =>
	t(`accommodation.rental_unit.show.view.${text}`)

const RentalUnit = () => {
	const { id } = useParams()
	const [data, setData] = useState({})
	const [icsLink, setIcsLink] = useState()
	const [facilities, setFacilities] = useState([])
	const [beds, setBeds] = useState([])
	const [languages, setlangaues] = useState([])
	const [whiteLabelUrl, setWhiteLabelUrl] = useState('')
	const [loaded, setLoaded] = useState(true)
	const [index, setIndex] = useState(0)
	const [searchParams, setSearchParams] = useSearchParams()
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		getPageDetails()
		const hasParam = searchParams.get('tab')

		if (hasParam) {
			setIndex(parseInt(hasParam))
		} else {
			setIndex(0)
		}
	}, [])

	const getPageDetails = () => {
		setLoaded(false)
		accommodationService
			.GetRentalUnit(id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('GetRentalUnits', {
					action_category: 'retrieve',
					action_outcome: 'success',
					interaction_element: 'page open',
				})
				setLoaded(true)
				setData(res?.rental_unit);
				setIcsLink(res?.ics_url);
				setWhiteLabelUrl(res?.url)
				setFacilities(res.facilities ?? [])
				setBeds(res.bed_types ?? [])
				setlangaues(res.languages ?? [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('GetRentalUnits', {
					action_category: 'retrieve',
					action_outcome: 'fail',
					interaction_element: 'page open',
				})
				setLoaded(true)
				NotificationManager.error(
					err?.message ?? 'Failed to get store settings detail'
				)
				throw new Error('Failed to get store settings detail')
			})
	}

	const onTabClicked = (event, index) => {
		setIndex(index)
	}

	const onCopyText = ({ url, label }) => {
		if (url) {
			navigator.clipboard.writeText(url)
			NotificationManager.success(
				`${label} is copied successfully and can be shared everywhere`
			)
		}
	}

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{data?.name_and_location?.name ?? rentalShowText('no_name')}
									</span>
								</div>
								{whiteLabelUrl ? (
									<div className="flex flex-row justify-start items-center px-2">
										<div className="!text-black1 font-medium text-base">
											<a
												className="text-black1 text-base"
												style={{ color: '#009688' }}
												href={whiteLabelUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												{whiteLabelUrl}
											</a>
										</div>
										<div
											className="ml-4 cursor-pointer"
											onClick={() =>
												onCopyText({
													url: whiteLabelUrl,
													label: rentalShowText('link'),
												})
											}
										>
											<ContentCopyOutlined />
										</div>
									</div>
								) : null}
							</div>
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2 mt-0 mb-2">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-16 font-medium">
										{rentalShowText('hub_description')}
									</span>
								</div>
							</div>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									variant="scrollable"
									value={index}
									onChange={onTabClicked}
									className="pt-0 pr-4 pl-4 pb-0"
									textColor="inherit"
									TabIndicatorProps={{
										style: {
											backgroundColor: '#666A73',
										},
									}}
								>
									<Tab label={rentalShowText('general_info')} />
									<Tab label={rentalShowText('accommodation_layout')} />
									<Tab label={rentalShowText('facilities_services')} />
									<Tab label={rentalShowText('photos')} />
									<Tab label={rentalShowText('policies_rules')} />
									<Tab label={rentalShowText('pricing_calendar')} />
									<Tab label={rentalShowText('availability')} />
								</Tabs>
								<Panel value={index} index={0}>
									<NameAndLocationForm
										data={data?.name_and_location}
										rentalUnitId={id}
										languages={languages}
									/>
								</Panel>
								<Panel value={index} index={1}>
									<AccommodationForm
										data={data?.accommodation_setup}
										rentalUnitId={id}
										beds={beds}
									/>
								</Panel>
								<Panel value={index} index={2}>
									<FacilitiesForm
										data={data?.photos_and_facilities}
										rentalUnitId={id}
										facilities={facilities}
									/>
								</Panel>

								<Panel value={index} index={3}>
									<PhotosForm
										data={data?.photos_and_facilities}
										rentalUnitId={id}
										facilities={facilities}
									/>
								</Panel>
								<Panel value={index} index={4}>
									<PoliciesAndRulesForm
										data={data?.policies_and_rules}
										rentalUnitId={id}
										currency={data?.name_and_location?.currency}
									/>
								</Panel>

								<Panel value={index} index={5}>
									<PricingAndCalendarForm
										data={data?.pricing_and_calendar}
										rentalUnitId={id}
										currency={data?.name_and_location?.currency}
									/>
								</Panel>
								<Panel value={index} index={6}>
									<Availability
										data={data?.accommodation_setup}
										rentalUnitId={id}
										icsLink={icsLink}
									/>
								</Panel>
							</Box>
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}

const Panel = (props) => (
	<div
		hidden={props.value !== props.index}
		className="row-span-2 bg-white rounded-12 m-4 border border-gray-200"
	>
		<div>{props.children}</div>
	</div>
)

export default RentalUnit
