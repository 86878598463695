import { Card, CardContent, TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Cookies from 'universal-cookie'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import TableEmptyData from '../../../components/table/TableEmptyData'
import LoyaltyService from '../../../services/loyaltyService'
import { createUUID } from '../../../utils/common'
import { KEYS } from '../../../utils/storage'

export const enrollGuestsText = (text) =>
	t(`loyalty.enrolledGuests.view.${text}`)

export default function GuestsEnrolledScreen() {
	const [data, setData] = useState([])
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [loaded, setLoaded] = useState(false)
	const cookies = new Cookies()
	const programId = cookies.get(KEYS.LOYALTY_PROGRAM_ID)
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		// setData
		LoyaltyService.getEnrolledGuests(programId)
			.then((res) => {
				setLoaded(true)
				setData(res.data)
			})
			.catch((err) => {
				setLoaded(true)
			})
	}, [])

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								{!(
									venueType === 'sport_essentials' ||
									venueType === 'pharmacy' ||
									venueType === 'fashion_threads' ||
									venueType === 'home_decor'
								) && (
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{enrollGuestsText('guestsEnrolled')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{data.length} {enrollGuestsText('guests')}
										</span>
									</div>
								)}
								{(venueType === 'sport_essentials' ||
									venueType === 'pharmacy' ||
									venueType === 'fashion_threads' ||
									venueType === 'home_decor') && (
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{enrollGuestsText('customersEnrolled')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{data.length} {enrollGuestsText('customers')}
										</span>
									</div>
								)}
							</div>
							{data.length ? (
								<div className="flex flex-col overflow-auto max-h-[calc(100vh-80px)] max-w-[calc(100vw-225px)]">
									<TableContainer component={Paper} className="mt-4">
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow key={createUUID()}>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{enrollGuestsText('name')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{enrollGuestsText('email')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{enrollGuestsText('phone')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{enrollGuestsText('dateEnrolled')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{data.map((row, index) => (
													<TableRow key={index} onClick={() => {}}>
														<TableCell
															align="left"
															className="!py-3.5 !text-gray5 !text-left !text-base !font-bold"
														>
															{row.name}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															{row.email}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															{row.phone}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															{row.pivot?.created_at
																? moment(row.pivot.created_at).format(
																		enrollGuestsText('dateFormat')
																  )
																: ''}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={data.length}
										rowsPerPage={rowsPerPage}
										page={page}
										className="!p-2"
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={enrollGuestsText('enrolledGuests')}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
