import React from 'react';
import { useNavigate } from 'react-router-dom';

import { t } from 'i18next'
import { useSelector } from 'react-redux';
import Svg_cancel from "../../../assets/svgs/cancel.svg";
import Svg_chevron_right from '../../../assets/svgs/chevron-right.svg'
import booksIcon from '../../../assets/svgs/quick_book.svg'

export const quickBooksBlockText = (text) =>
	t(`employees.staffIntegrations.quickBooksBlock.${text}`)

export default function QuickBooksBlock() {
    const navigate = useNavigate();
    const { short_code } = useSelector(state => state.app);

    const onAction = () => {
        navigate(`/${short_code}/admin/staff/staff-integrations/quick-books`);
    };

    return (
			<div className="flex flex-col">
				<div className="flex flex-row items-center p-0 mb-024">
					<label className="font-medium text-lg text-black1">
						{quickBooksBlockText('quickbooks_title')}
					</label>
				</div>
				<div className="flex flex-col items-start p-0 w-full h-full bg-white rounded-12">
					<div className="flex flex-col items-start flex-1 w-full shadow-lg_2 bg-white rounded-12 px-024 pt-024 pb-016">
						<div className="flex flex-col justify-center items-start p-0">
							<img src={booksIcon} className="w-20 h-20 mb-4" alt="" />
							<div className="flex flex-row items-center p-0 h-6 mt-2">
								{/* Uncomment and update the code as needed for a connected state */}
								{/* <FontAwesomeIcon icon={iconCheck} color='#73CB50' size='lg' className='pr-012' /> */}
								{/* <label className='text-sm pl-08 text-green1'>{quickBooksBlockText('connected')}</label> */}
								<img className="pr-012" src={Svg_cancel} alt="" />
								<label className="text-14 pl-08 text-red1">
									{quickBooksBlockText('disconnected')}
								</label>
							</div>
							<label className="font-medium text-base mt-2">
								{quickBooksBlockText('quickbooks_description')}
							</label>
						</div>
					</div>
					<div
						className="flex flex-row justify-between px-024 py-016 w-full h-[52px] cursor-pointer"
						onClick={onAction}
					>
						<label className="text-black1 font-medium text-base">
							{quickBooksBlockText('view_detail')}
						</label>
						<img src={Svg_chevron_right} alt="" />
					</div>
				</div>
			</div>
		)
}
