import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'
import { rightBoxStyle } from '../../constants/types'
// import icon from "@mui/icons-material/Feedback";
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Svg_close from '../../assets/svgs/close.svg'
import { feedbackText } from '.'

export default function QuickInfoModal({
	showModal,
	onConfirm,
	onClose,
	type,
}) {
	const [visible, setVisible] = useState(false)

	const [quickReservationsInfoData, setQuickReservationsInfoData] = useState([
		{
			title: feedbackText('reservations_list'),
			description: feedbackText('reservations_feature_info'),
			steps: 5,
			steps_completed: 1,
		},
		{
			title: feedbackText('creating_reservation'),
			description: feedbackText('create_reservation_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('reservation_details'),
			description: feedbackText('reservation_details_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('assigning_orders'),
			description: feedbackText('assign_orders_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('enrolling_guests_promotions'),
			description: feedbackText('enroll_guests_promotions_info'),
			steps: null,
			steps_completed: null,
		},
	])

	const [quickMenuManageInfoData, setQuickMenuManageInfoData] = useState([
		{
			title: feedbackText('menus'),
			description: feedbackText('menu_management_description'),
			steps: 5,
			steps_completed: 1,
		},
		{
			title: feedbackText('create_menu'),
			description: feedbackText('menu_curate'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('menu_filter_search'),
			description: feedbackText('menu_filter_info'),
			steps: null,
			steps_completed: null,
		},
	])

	const [quickStoreSettingsInfoData, setStoreSettingsInfoData] = useState([
		{
			title: feedbackText('general_details'),
			description: feedbackText('general_details_info'),
			steps: 7,
			steps_completed: null,
		},
		{
			title: feedbackText('address'),
			description: feedbackText('address_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('selling_shipping_locations'),
			description: feedbackText('selling_shipping_locations_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('galleries'),
			description: feedbackText('galleries_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('suppliers'),
			description: feedbackText('suppliers_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('payments_supported'),
			description: feedbackText('payments_supported_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('notification_email'),
			description: feedbackText('notification_email_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('shipping_zone'),
			description: feedbackText('shipping_zone_info'),
			steps: null,
			steps_completed: null,
		},
	])

	const [quickCustomersInfoData, setCustomersInfoData] = useState([
		{
			title: feedbackText('manage_customers'),
			description: feedbackText('manage_customers_info'),
			steps: 4,
			steps_completed: null,
		},
		{
			title: feedbackText('check_customer_orders'),
			description: feedbackText('check_customer_orders_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('check_customer_details'),
			description: feedbackText('check_customer_details_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('your_customer_details'),
			description: feedbackText('your_customer_details_info'),
			steps: null,
			steps_completed: null,
		},
	])

	const [quickRentalUnitsInfoData, setQuickRentalUnitsInfoData] = useState([
		{
			title: feedbackText('manage_rental_units'),
			description: feedbackText('manage_rental_units_info'),
			steps: 6,
			steps_completed: null,
		},
		{
			title: feedbackText('calendar_availability'),
			description: feedbackText('calendar_availability_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('general_info'),
			description: feedbackText('general_info_rental'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('accommodation_layout'),
			description: feedbackText('accommodation_layout_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('facilities_services'),
			description: feedbackText('facilities_services_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('photos'),
			description: feedbackText('photos_info_rental'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('policies_rules'),
			description: feedbackText('policies_rules_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('pricing_calendar'),
			description: feedbackText('pricing_calendar_info'),
			steps: null,
			steps_completed: null,
		},
	])
	const [quickOrdersManagementInfoData, setQuickOrdersManagementInfoData] =
		useState([
			{
				title: feedbackText('list_orders'),
				description: feedbackText('list_orders_info'),
				steps: 4,
				steps_completed: null,
			},
			{
				title: feedbackText('manage_order_details'),
				description: feedbackText('manage_order_details_info'),
				steps: null,
				steps_completed: null,
			},
			{
				title: feedbackText('change_order_status'),
				description: feedbackText('change_order_status_info'),
				steps: null,
				steps_completed: null,
			},
			{
				title: feedbackText('track_order_status'),
				description: feedbackText('track_order_status_info'),
				steps: null,
				steps_completed: null,
			},
		])

	const [quickGuestsManagementInfoData, setQuickGuestsManagementInfoData] =
		useState([
			{
				title: feedbackText('list_venue_guests'),
				description: feedbackText('list_venue_guests_info'),
				steps: 4,
				steps_completed: null,
			},
			{
				title: feedbackText('detailed_guest_info'),
				description: feedbackText('detailed_guest_info_info'),
				steps: 4,
				steps_completed: null,
			},
			{
				title: feedbackText('guest_priority_status'),
				description: feedbackText('guest_priority_status_info'),
				steps: 4,
				steps_completed: null,
			},
		])

	const [
		quickCommunicationPreferencesInfoData,
		setCommunicationPreferencesInfoData,
	] = useState([
		{
			title: feedbackText('message_templates'),
			description: feedbackText('create_message_templates_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('update_message_templates'),
			description: feedbackText('update_message_templates_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('manage_message_templates'),
			description: feedbackText('manage_message_templates_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('automatic_replies_email'),
			description: feedbackText('automatic_replies_email_info'),
			steps: null,
			steps_completed: null,
		},
		{
			title: feedbackText('automatic_replies_sms'),
			description: feedbackText('automatic_replies_sms_info'),
			steps: null,
			steps_completed: null,
		},
	])

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal swipe-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ backgroundColor: 'transparent' }}
			>
				<Box
					sx={rightBoxStyle}
					className="outline-none overflow-auto max-h-[120vh]"
				>
					<div
						className="flex flex-row justify-between px-024 pb-016 pt-024"
						style={{ borderBottom: '1px solid #E4E4E4' }}
					>
						<div>
							<label className="text-20 font-semibold text-black1">
								{type} - {feedbackText('HELPFUL_INFO')}
							</label>
							<label className="text-24 font-semibold ml-2"></label>
						</div>
						<div onClick={onClose} className="cursor-pointer">
							<img src={Svg_close} alt="" />
						</div>
					</div>
					<div className="flex flex-col p-024">
						{type === 'Reservations' &&
							quickReservationsInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Menu Management' &&
							quickMenuManageInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Rental Units' &&
							quickRentalUnitsInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Customers' &&
							quickCustomersInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Orders Management' &&
							quickOrdersManagementInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Store Settings' &&
							quickStoreSettingsInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Guests Management' &&
							quickGuestsManagementInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}

						{type === 'Communication Preferences' &&
							quickCommunicationPreferencesInfoData.map((item, index) => (
								<div className="flex flex-col justify-start space-center my-4 border-b">
									<div className="flex flex-row w-full justify-between space-center">
										<span className="text-black1 font-bold text-base mt-1">
											{item.title}
										</span>
										<div>
											<InfoIcon />
										</div>
									</div>
									<span className="text-black1 text-base my-2">
										{item.description}
									</span>
									<div className="mt-1 pl-4"></div>
								</div>
							))}
					</div>
				</Box>
			</Modal>
		</div>
	)
}
