import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import usersIcon from '../../../assets/svgs/users.svg'

export const guestDetailBlockText = (text) =>
	t(`reservations.reservationdetail.GuestDetailBlock.${text}`)

export function GuestDetailBlock({ data }) {
	const [mainGuest, setMainGuest] = useState(null)
	const [otherGuests, setOtherGuests] = useState(null)
	useEffect(() => {
		const additionalGuests = []
		const index = data.findIndex((option) => option.is_main === 1)
		const noMainIndex = data.findIndex((option) => option.is_main === 0)

		if (index !== -1) {
			setMainGuest(data[index])
		}

		if (noMainIndex !== -1) {
			additionalGuests.push(data[noMainIndex])
			setOtherGuests(additionalGuests)
		}
	}, [data])

	if (mainGuest) {
		return (
			<div className="flex flex-col mt-4 w-[65%]">
				<p className="mb-2 text-black1 text-lg font-medium">
					{guestDetailBlockText('guestInfo')}
				</p>
				<div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
					<div className="p-4 bg-[#F9F7F7] rounded-lg">
						<img src={usersIcon} alt="desk" />
					</div>
					<div className="flex-1 flex flex-row gap-10">
						<div className="flex flex-col gap-5">
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('name')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{mainGuest.name}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('phone')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{mainGuest.phone}
								</span>
							</div>
						</div>
						<div className="flex flex-col gap-5">
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('email')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{mainGuest.email}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('notes')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{mainGuest.notes ?? '-'}
								</span>
							</div>
						</div>
						<div className="flex flex-col gap-5">
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('guestStatus')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('regular')}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('address')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{mainGuest.address ?? '-'}
								</span>
							</div>
						</div>
					</div>
				</div>
				{otherGuests?.length > 0 && (
					<>
						<div className="flex flex-row bg-white rounded-lg pl-8 items-center gap-8">
							<p>{guestDetailBlockText('additionalGuests')}</p>
						</div>
						{otherGuests?.map((item, index) => (
							<div
								className="flex flex-row bg-white rounded-lg pl-8 pt-4 pb-4 items-center gap-8"
								key={index}
							>
								<div className="flex-1 flex flex-row gap-10">
									<div className="flex flex-col gap-3">
										<span className="text-gray5 text-sm font-normal">
											{guestDetailBlockText('name')}
										</span>
										<span className="text-gray5 text-base font-bold">
											{item.name}
										</span>
									</div>
									<div className="flex flex-col gap-3">
										<span className="text-gray5 text-sm font-normal">
											{guestDetailBlockText('email')}
										</span>
										<span className="text-gray5 text-base font-bold">
											{item.email ?? '-'}
										</span>
									</div>
									<div className="flex flex-col gap-3">
										<span className="text-gray5 text-sm font-normal">
											{guestDetailBlockText('phone')}
										</span>
										<span className="text-gray5 text-base font-bold">
											{item.phone ?? '-'}
										</span>
									</div>
								</div>
							</div>
						))}
					</>
				)}
			</div>
		)
	} else {
		return (
			<div className="flex flex-col mt-4 w-[65%]">
				<p className="mb-2 text-black1 text-lg font-medium">
					{guestDetailBlockText('guestInfo')}
				</p>
				<div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
					<div className="p-4 bg-[#F9F7F7] rounded-lg">
						<img src={usersIcon} alt="desk" />
					</div>
					<div className="flex-1 flex flex-row gap-10">
						<div className="flex flex-col gap-5">
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('name')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('noGuestName')}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('phone')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('noGuestPhone')}
								</span>
							</div>
						</div>
						<div className="flex flex-col gap-5">
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('email')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('noGuestEmail')}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('notes')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('noNotes')}
								</span>
							</div>
						</div>
						<div className="flex flex-col gap-5">
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('vipStatus')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('noGuestStatus')}
								</span>
							</div>
							<div className="flex flex-col gap-1">
								<span className="text-gray5 text-sm font-normal">
									{guestDetailBlockText('address')}
								</span>
								<span className="text-gray5 text-base font-bold">
									{guestDetailBlockText('noGuestAddress')}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
