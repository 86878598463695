import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import ReferralsService from '../../../services/referralsService'

import { t } from 'i18next'
import Cookies from 'universal-cookie'
import referralIconWhite from '../../../assets/svgs/referral-white.svg'
import referralIcon from '../../../assets/svgs/referral.svg'
import TableEmptyData from '../../../components/table/TableEmptyData'
import { getCurrencySymbolHelper } from '../../../helpers/venueHelpers'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import ManageReferrals from './ManageReferrals'

export const referralText = (text) => t(`marketing.referrals.view.${text}`)

export default function Referrals() {
	const [referral_code, setCode] = useState(null)
	const [referral_url, setReferralUrl] = useState(null)
	const [referral_history, setHistory] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [isCopied, setCopied] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [useReferralsfor, setUseReferralsFor] = useState(false)

	const { subscription_plan, short_code } = useSelector((state) => state.app)

	const cookies = new Cookies()

	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)

	const currency = cookieBasicInfo.currency ?? 'USD'

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		loadData()
	}, [])

	const loadData = () => {
		ReferralsService.getReferrals()
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('payment service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'referals',
				})
				setLoaded(true)
				setCode(data?.referral_code)
				setReferralUrl(data?.referral_link)
				setHistory(data?.referrals || [])
				setUseReferralsFor(data?.use_referrals_for)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('payment service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'referals',
				})
				setLoaded(true)
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
				throw new Error(referralText('problem_referals'))
			})
	}

	const onCopy = () => {
		if (referral_code) {
			setCopied(true)
			try {
				// Ensure referral_url starts with "https://"
				const fullUrl =
					referral_url.startsWith('http://') ||
					referral_url.startsWith('https://')
						? referral_url
						: `https://${referral_url}`
				navigator.clipboard.writeText(fullUrl)
			} catch (e) {
				console.log(e)
			}
			setTimeout(() => {
				setCopied(false)
			}, 2000)
		}
	}

	if (referral_code == null) {
		return null
	}
	return (
		<>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row items-center justify-between p-7">
								<div className="flex flex-row items-center">
									<div className="bg-gray2 p-3 rounded-lg mr-6">
										<img
											src={referralIcon}
											alt={referralText('referral')}
											className="w-6 h-6"
										/>
									</div>
									<div className="flex flex-col justify-center items-start">
										<p className="text-base text-black1 font-medium">
											{referralText('your_referral_code')}
										</p>
										<div className="flex flex-row justify-center items-center mt-1">
											<div className="text-xl text-black1 font-medium mr-5">
												{referral_code}
											</div>
											{isCopied ? (
												<div className="bg-gray2 px-3 py-1 rounded-full text-black1 text-14 font-medium">
													{referralText('copied')}
												</div>
											) : (
												<div
													className="bg-gray2 px-3 py-1 rounded-full cursor-pointer text-cyan1 font-semibold text-14"
													onClick={onCopy}
												>
													{referralText('copy')}
												</div>
											)}
										</div>
									</div>
									<div className="w-[80px]" />
									<div className="flex flex-col justify-center items-start">
										<p className="text-base text-black1 font-medium">
											{referralText('number_of_times_used')}
										</p>
										<div className="mt-1 text-xl text-black1 font-medium">
											{referral_history.length} / ∞
										</div>
									</div>
								</div>
								<div className="flex flex-row justify-start items-center gap-4">
									<div
										className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setShowModal(true)}
									>
										<img
											src={referralIconWhite}
											alt={referralText('manage')}
											className="mr-2"
										/>
										<span>{referralText('manage')}</span>
									</div>
								</div>
							</div>
							{referral_history.length ? (
								<>
									<TableContainer component={Paper}>
										<Table
											sx={{ minWidth: 650 }}
											aria-label={referralText('simple_table')}
										>
											<TableHead className="bg-gray7">
												<TableRow>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{referralText('status')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{referralText('last_updated')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{referralText('venue_registered')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{referralText('earnings')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{referral_history
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((row, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base"
															>
																<span
																	className={`${
																		row.referral_status === 'registered'
																			? 'bg-green2'
																			: 'bg-yellow1'
																	} px-2 py-0.5 w-max text-white text-sm font-bold rounded`}
																>
																	{referralText(row.referral_status)}
																</span>
															</TableCell>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base"
															>
																{row.used_time
																	? moment(row.used_time).format(
																			'MMMM Do YYYY, h:mm:ss a'
																	  )
																	: referralText('n_a')}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base"
															>
																{row.restaurant_name}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base"
															>
																{row.earnings ?? 0}
																{getCurrencySymbolHelper(currency) ??
																	referralText('currency_symbol')}
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={referral_history.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</>
							) : (
								<div className="border border-x-0 border-b-0 mb-10">
									<TableEmptyData content={referralText('referrals')} />
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}

			{showModal && (
				<ManageReferrals
					showModal={showModal}
					onClose={(refresh) => {
						setShowModal(false)
					}}
					useReferralsfor={useReferralsfor}
					subscriptionPlan={subscription_plan}
				/>
			)}
		</>
	)
}
