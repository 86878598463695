import { API_BEACHBAR } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const getBeachbarAreas = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_BEACHBAR}/area`,
		method: 'GET',
	}
	return apiFactory(api, {})
}

const getBeachbarConfig = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_BEACHBAR}/configuration`,
		method: 'GET',
	}
	return apiFactory(api, {})
}

const deleteBeachbarArea = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_BEACHBAR}/area/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const updateBeachbarArea = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_BEACHBAR}/area`,
		method: 'PUT',
	}
	return apiFactory(api, payload)
}

const createBeachbarArea = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_BEACHBAR}/area`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const createBeachbarConfiguration = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_BEACHBAR}/configuration`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

export default {
	getBeachbarAreas,
	createBeachbarArea,
	updateBeachbarArea,
	deleteBeachbarArea,
	createBeachbarConfiguration,
	getBeachbarConfig,
}
