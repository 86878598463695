import { CircularProgress } from '@mui/material';
import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="absolute inset-0 text-gray5 bg-gray6 opacity-60 flex justify-center items-center">
      <CircularProgress size={50} />
    </div>
  )
}

export default LoadingScreen;
