import React from 'react'

import { t } from 'i18next'
import deskIcon from '../../../assets/svgs/table_black.svg'

export const tableInfoText = (text) =>
	t(`reservations.reservationdetail.TableInfo.${text}`)

export default function TableInfo({ data }) {
	return (
		<div className="flex flex-col mt-4 w-[35%]">
			<p className="mb-2 text-black1 text-lg font-medium">
				{tableInfoText('tableInfo')}
			</p>
			<div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
				<div className="p-4 bg-[#F9F7F7] rounded-lg">
					<img src={deskIcon} alt="desk" />
				</div>
				<div className="flex-1 flex flex-row gap-16">
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{tableInfoText('name')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.name ?? tableInfoText('notAvailable')}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{tableInfoText('shape')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.shape ?? tableInfoText('notAvailable')}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{tableInfoText('seats')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.seats ?? tableInfoText('notAvailable')}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{tableInfoText('size')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.size ?? tableInfoText('notAvailable')}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
