import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./index.css";

export default function LayoutEmpty() {
  return (
    <div className="flex flex-col w-full h-full">
      <Outlet />
    </div>
  );
}
