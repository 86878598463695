import { createSlice } from "@reduxjs/toolkit";
// import { current } from "@reduxjs/toolkit";

export const menuStore = createSlice({
	name: "counter",
	initialState: {
		visible: true,
		bar: "",
		menu: "",
		barId: 1,
		menuId: 1,
		path: "",
		routes: [],
	},
	reducers: {
		visibleMenu : (state) => {
			state.visible = !state.visible;
		},
		setVisibleMenu : (state, action) => {
			state.visible = action.payload;
		},
		setBar : (state, action) => {
			state.bar = action.payload;
		},
		setMenu : (state, action) => {
			state.menu = action.payload;
		},
		setBarId : (state, action) => {
			state.barId = action.payload;
		},
		setMenuId : (state, action) => {
			state.menuId = action.payload;
		},
		setRoutes: (state, action) => {
			state.routes = action.payload;
		},
		setAsAccount: (state, action) => {
			state.barId = 8;
			state.menuId = 2;
		},
	},
});

// Action creators are generated for each case reducer function
export const { visibleMenu, setVisibleMenu, setPath, setRoutes, setAsAccount,setBar, setBarId, setMenu,setMenuId } = menuStore.actions;
export default menuStore.reducer;
