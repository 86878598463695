import { Card, CardContent, IconButton } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import editIcon from '../../../assets/svgs/edit.svg'
import GenericTable from '../../../components/generic-table/generic-table'
import EmailModal from '../../../components/store/emailModal'
import { createUUID } from '../../../utils/common'
export const storeEmailsText = (text) =>
	t(`account.storeSettings.storeEmails.${text}`)

const StoreEmails = ({ recipient, onRefresh }) => {
	const [isModalVisible, setModalVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)

	const handleEdit = (row) => {
		setSelectedRow(row)
		setModalVisible(true)
	}

	const columns = [
		{ field: 'email', label: storeEmailsText('email_column'), align: 'left' },
		{
			field: 'contentType',
			label: storeEmailsText('content_type_column'),
			align: 'left',
		},
		{
			field: 'recipient',
			label: storeEmailsText('recipient_column'),
			align: 'left',
		},
		{
			field: 'actions',
			label: storeEmailsText('actions_column'),
			align: 'left',
			cellComponent: ({ data }) => (
				<IconButton color="secondary" onClick={() => handleEdit(data)}>
					<img src={editIcon} alt="edit" />
				</IconButton>
			),
		},
	]

	const data = [
		{
			id: createUUID(),
			email: 'New Order',
			contentType: 'text/html',
			recipient: recipient ?? '-',
		},
	]

	return (
		<div className="w-full">
			<Card>
				<CardContent className="!p-0">
					<div className="justify-between items-center pl-4 pr-3 pt-5">
						<p className="text-black1 text-xl font-medium">
							{storeEmailsText('email_notifications')}
						</p>
						<p className="text-black1 text-16 font-medium">
							{storeEmailsText('email_notifications_description')}
						</p>
					</div>
					<GenericTable data={data} columns={columns} onEdit={handleEdit} />
				</CardContent>
			</Card>
			<EmailModal
				data={selectedRow}
				showModal={isModalVisible}
				onClose={(refresh) => {
					setModalVisible(false)
					setSelectedRow(null)
					if (refresh) {
						onRefresh()
					}
				}}
			/>
		</div>
	)
}

export default StoreEmails
