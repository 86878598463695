import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import GenericTable from '../../../components/generic-table/generic-table'
import AddMessageTemplateModal from '../../../components/guests/addMessageTemplateModal'
import ConfirmDeleteTemplateModal from '../../../components/guests/confirmDeleteTemplateModal'
import TemplateService from '../../../services/templateService'

export const messageTemplatesText = (text) =>
	t(`guests.communicationPreferences.MessageTemplates.${text}`)

export const MessageTemplates = ({ data, onRefresh }) => {
	const [isAddVisible, setAddVisible] = useState(false)
	const [deleteRowId, setDeleteRowId] = useState(null)
	const [selectedRow, setSelectedRow] = useState(null)

	const confirmDeleteMessageTemplate = () => {
		TemplateService.DeleteAutomaticReply(deleteRowId)
			.then((data) => {
				NotificationManager.success(data.message ?? 'Successfully deleted')
				onRefresh()
				setDeleteRowId(null)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	const columns = [
		{
			field: 'name',
			label: messageTemplatesText('name'),
			align: 'left',
		},
		{
			field: 'description',
			label: messageTemplatesText('content'),
			align: 'left',
		},
		{
			field: 'type',
			label: messageTemplatesText('type'),
			align: 'left',
		},
		{
			field: 'actions',
			label: messageTemplatesText('actions'),
			align: 'center',
		},
	]

	const handleDelete = (id) => {
		setDeleteRowId(id)
	}

	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
						<div className="flex flex-row items-center">
							<span className="text-black1 text-l font-medium">
								{messageTemplatesText('manageGuestMessageTemplates')}
							</span>
						</div>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
							onClick={() => {
								setSelectedRow(null)
								setAddVisible(true)
							}}
						>
							<span>{messageTemplatesText('createNewTemplate')}</span>
						</div>
					</div>
					<GenericTable
						data={data || []}
						columns={columns}
						onDelete={handleDelete}
					/>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
							{messageTemplatesText('noMessageTemplates')}
						</div>
					)}
				</CardContent>
			</Card>
			<AddMessageTemplateModal
				showModal={isAddVisible}
				data={selectedRow}
				onClose={() => {
					setSelectedRow(null)
					setAddVisible(false)
				}}
				onConfirm={() => onRefresh()}
			/>
			<ConfirmDeleteTemplateModal
				showModal={deleteRowId != null}
				onClose={() => setDeleteRowId(null)}
				onSubmit={() => confirmDeleteMessageTemplate()}
			/>
		</div>
	)
}
