import DeleteIcon from '@mui/icons-material/Delete'
import {
	Card,
	CardContent, Checkbox,
	CircularProgress,
	IconButton,
	TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import cardIcon from '../../../assets/svgs/v2-email-standard.svg'
import CustomSwitch from '../../../components/custom/CustomSwitch'
import CustomTimePicker from '../../../components/custom/CustomTimePicker/CustomTimePicker'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../../helpers/venueHelpers'
import VenueService from '../../../services/venuService'
import WhiteLabelService from '../../../services/whiteLabelService'
import { getDateTimeFromTimeSpan, isEmpty } from '../../../utils/common'
import { KEYS } from '../../../utils/storage'
import ContactsTable from './contacts'
import SubscribersTable from './subscribers'

const getTranslation = (text) =>
	t(`account.venueWebProfile.AdditionalProfile.${text}`)

const validator = yup.object().shape({
	facebook_link: yup.string().url('It is not valid url').notRequired(),
	twitter_link: yup.string().url('It is not valid url').notRequired(),
	instagram_link: yup.string().url('It is not valid url').notRequired(),
	pinterest_link: yup.string().url('It is not valid url').notRequired(),
	linkedin_link: yup.string().url('It is not valid url').notRequired(),
	tiktok_link: yup.string().url('It is not valid url').notRequired(),
	vt_link: yup.string().url('It is not valid url').notRequired(),
})

export default function AdditionalProfile() {
	const [loading, setLoading] = useState(false)
	const [loading2, setLoading2] = useState(false)
	const [bookingSites, setBookingSites] = useState([])
	const [subscribers, setSubscribers] = useState([])
	const [headerLinks, setHeaderLinks] = useState([])
	const [contacts, setContacts] = useState([])

	const [canEnableNewsletter, setCanEnableNewsletter] = useState(false)
	const cookies = new Cookies()

	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const columnsSubscribers = [
		{ field: 'id', label: 'No' },
		{ field: 'email', label: 'Email' },
		{
			field: 'created_at',
			label: 'Subscribed At',
			cellComponent: ({ data }) => {
				return <span>{moment(data).format('MMMM Do YYYY, h:mm:ss a')}</span>
			}
		},
		{
			field: 'subscribed',
			label: 'Subscribed',
			cellComponent: ({ data }) => {
				return <span
					className={`${data.subscribed === 1
						? 'bg-green-500'
						: data.subscribed === 0
							? 'bg-red-500'
							: 'bg-yellow-500'
						} px-2 py-0.5 w-max text-white text-sm font-bold rounded`}
				>
					{data.subscribed === 1
						? 'Subscribed'
						: data.subscribed === 0
							? 'Unsubscribed'
							: 'Pending'}
				</span>
			}
		},
	]
	const contactSubmissions = [
		{ field: 'id', label: 'No' },
		{ field: 'full_name', label: 'Full Name' },
		{ field: 'email', label: 'Email' },
		{ field: 'phone', label: 'Phone' },
		{ field: 'subject', label: 'Subject' },
		{ field: 'content', label: 'Content'},
		{
			field: 'contacted_at',
			label: 'Contacted At',
			cellComponent: ({ data }) => {
				return <span>{moment(data).format('MMMM Do YYYY, h:mm:ss a')}</span>
			}
		},
	]

	// checkbox
	const [monday, setMonday] = useState(false)
	const [tuesday, setTuesday] = useState(false)
	const [wendsday, setWendsday] = useState(false)
	const [thursday, setThursday] = useState(false)
	const [friday, setFriday] = useState(false)
	const [saturday, setSaturday] = useState(false)
	const [sunday, setSunday] = useState(false)

	// start times
	const [mondayStartTime, setmondayStartTime] = useState(new Date())
	const [tuesdayStartTime, settuesdayStartTime] = useState(new Date())
	const [wendsdayStartTime, setwendsdayStartTime] = useState(new Date())
	const [thursdayStartTime, setthursdayStartTime] = useState(new Date())
	const [fridayStartTime, setfridayStartTime] = useState(new Date())
	const [saturdayStartTime, setsaturdayStartTime] = useState(new Date())
	const [sundayStartTime, setsundayStartTime] = useState(new Date())

	// end time
	const [mondayEndTime, setmondayEndTime] = useState(new Date())
	const [tuesdayEndTime, settuesdayEndTime] = useState(new Date())
	const [wendsdayEndTime, setwendsdayEndTime] = useState(new Date())
	const [thursdayEndTime, setthursdayEndTime] = useState(new Date())
	const [fridayEndTime, setfridayEndTime] = useState(new Date())
	const [saturdayEndTime, setsaturdayEndTime] = useState(new Date())
	const [sundayEndTime, setsundayEndTime] = useState(new Date())

	const handleCanEnableNewsletter = (value) => {
		setCanEnableNewsletter(value)
		const details = {
			show_newsletter: value,
		}

		WhiteLabelService.update(details)
			.then((res) => {
				if (value) {
					NotificationManager.success(
						'Newsletter visibility enabled successfully',
						'Success',
						3000
					)
				}

				if (!value) {
					NotificationManager.success(
						'Newsletter visibility disabled successfully',
						'Success',
						3000
					)
				}
			})
			.catch((err) => {
				setCanEnableNewsletter(!value)
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else if (err?.message) {
					NotificationManager.error(err?.message)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
			})
	}

	const formikProps = useFormik({
		initialValues: {
			facebook_link: '',
			twitter_link: '',
			instagram_link: '',
			linkedin_link: '',
			tiktok_link: '',
			vt_link: '',
			pinterest_link: '',
			social_media_label_text: '',
			showAdditionalInfo: false,
			contact_page_main_title_string: '',
			contact_page_toplabel_string: '',
			contact_page_address_string: '',
			contact_page_phone_string: '',
			contact_page_email_string: '',
			contact_page_open_hours_string: '',
			contact_page_form_subtitle_string: '',
			contact_page_form_submit_btn_txt: '',
			contact_page_fullname_label_string: '',
			contact_page_phone_number_label_string: '',
			contact_page_phone_number_show: false,
			contact_page_email_label_string: '',
			contact_page_subject_label_string: '',
			contact_page_subject_show: false,
			contact_page_content_label_string: '',
			contact_page_content_show: false,
			contact_page_opening_hours_enable: false,
			contact_page_address_value: '',
			contact_page_email_value: '',
			contact_page_phone_value: '',
			contact_page_enable: false,
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false,
	})
	const formikProps2 = useFormik({
		initialValues: {
			call_us_text: '',
			subscribe_label_text: '',
			support_phone: '',
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit2(values),
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false,
	})

	const socialMediaNotEmpty =
		!formikProps.values.facebook_link &&
		!formikProps.values.twitter_link &&
		!formikProps.values.instagram_link &&
		!formikProps.values.linkedin_link &&
		!formikProps.values.tiktok_link &&
		!formikProps.values.vt_link &&
		!formikProps.values.pinterest_link &&
		!formikProps.values.social_media_label_text
	useEffect(() => {
		getData()
	}, [])

	const onSubmitAvailability = () => {
		const payload = {
			availability: [],
		}
		if (monday)
			payload.availability.push({
				day_of_week: 'Monday',
				open_time: moment(mondayStartTime).format('HH:mm'),
				close_time: moment(mondayEndTime).format('HH:mm'),
			})
		if (tuesday)
			payload.availability.push({
				day_of_week: 'Tuesday',
				open_time: moment(tuesdayStartTime).format('HH:mm'),
				close_time: moment(tuesdayEndTime).format('HH:mm'),
			})
		if (wendsday)
			payload.availability.push({
				day_of_week: 'wendsday',
				open_time: moment(wendsdayStartTime).format('HH:mm'),
				close_time: moment(wendsdayEndTime).format('HH:mm'),
			})
		if (thursday)
			payload.availability.push({
				day_of_week: 'Thursday',
				open_time: moment(thursdayStartTime).format('HH:mm'),
				close_time: moment(thursdayEndTime).format('HH:mm'),
			})
		if (friday)
			payload.availability.push({
				day_of_week: 'Friday',
				open_time: moment(fridayStartTime).format('HH:mm'),
				close_time: moment(fridayEndTime).format('HH:mm'),
			})
		if (saturday)
			payload.availability.push({
				day_of_week: 'Saturday',
				open_time: moment(saturdayStartTime).format('HH:mm'),
				close_time: moment(saturdayEndTime).format('HH:mm'),
			})
		if (sunday)
			payload.availability.push({
				day_of_week: 'Sunday',
				open_time: moment(sundayStartTime).format('HH:mm'),
				close_time: moment(sundayEndTime).format('HH:mm'),
			})

		setLoading(true)
		VenueService.UpdateVenueWhiteLabelAvailability(payload)
			.then((res) => {
				NotificationManager.success(
					'Opening Hours have been updated successfully'
				)
				setLoading(false)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				setLoading(false)
				throw new Error('Problem to update Opening Hours')
			})
	}

	const getData = () => {
		setLoading(true);
		WhiteLabelService.get()
			.then((res) => {
				formikProps.setFieldValue('facebook_link', res.facebook_link || '')
				formikProps.setFieldValue('twitter_link', res.twitter_link || '')
				formikProps.setFieldValue('instagram_link', res.instagram_link || '')
				formikProps.setFieldValue('tiktok_link', res.tiktok_link || '')
				formikProps.setFieldValue('vt_link', res.vt_link || '')
				formikProps.setFieldValue('linkedin_link', res.linkedin_link || '')
				formikProps.setFieldValue('pinterest_link', res.pinterest_link || '')
				formikProps.setFieldValue(
					'social_media_label_text',
					res.social_media_label_text || ''
				)
				formikProps2.setFieldValue('call_us_text', res.call_us_text || '')
				formikProps2.setFieldValue(
					'subscribe_label_text',
					res.subscribe_label_text || ''
				)
				formikProps2.setFieldValue('support_phone', res.support_phone || '')
				formikProps.setFieldValue(
					'contact_page_main_title_string',
					res.contact_page_main_title_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_toplabel_string',
					res.contact_page_toplabel_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_address_string',
					res.contact_page_address_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_phone_string',
					res.contact_page_phone_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_email_string',
					res.contact_page_email_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_open_hours_string',
					res.contact_page_open_hours_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_form_subtitle_string',
					res.contact_page_form_subtitle_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_form_submit_btn_txt',
					res.contact_page_form_submit_btn_txt || ''
				)
				formikProps.setFieldValue(
					'contact_page_fullname_label_string',
					res.contact_page_fullname_label_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_phone_number_label_string',
					res.contact_page_phone_number_label_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_phone_number_show',
					res.contact_page_phone_number_show || false
				)
				formikProps.setFieldValue(
					'contact_page_email_label_string',
					res.contact_page_email_label_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_subject_label_string',
					res.contact_page_subject_label_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_subject_show',
					res.contact_page_subject_show || false
				)
				formikProps.setFieldValue(
					'contact_page_content_label_string',
					res.contact_page_content_label_string || ''
				)
				formikProps.setFieldValue(
					'contact_page_content_show',
					res.contact_page_content_show || false
				)
				formikProps.setFieldValue(
					'contact_page_opening_hours_enable',
					res.contact_page_opening_hours_enable || false
				)
				formikProps.setFieldValue(
					'contact_page_address_value',
					res.contact_page_address_value || ''
				)
				formikProps.setFieldValue(
					'contact_page_email_value',
					res.contact_page_email_value || ''
				)
				formikProps.setFieldValue(
					'contact_page_phone_value',
					res.contact_page_phone_value || ''
				)
				formikProps.setFieldValue(
					'contact_page_enable',
					res.contact_page_enable || false
				)

				res.availability?.forEach((item) => {
					if (item.day_of_week == 'Monday') {
						setMonday(true)
						setmondayStartTime(getDateTimeFromTimeSpan(item.open_time))
						setmondayEndTime(getDateTimeFromTimeSpan(item.close_time))
					} else if (item.day_of_week == 'Tuesday') {
						setTuesday(true)
						settuesdayStartTime(getDateTimeFromTimeSpan(item.open_time))
						settuesdayEndTime(getDateTimeFromTimeSpan(item.close_time))
					} else if (item.day_of_week == 'wendsday') {
						setWendsday(true)
						setwendsdayStartTime(getDateTimeFromTimeSpan(item.open_time))
						setwendsdayEndTime(getDateTimeFromTimeSpan(item.close_time))
					} else if (item.day_of_week == 'Thursday') {
						setThursday(true)
						setthursdayStartTime(getDateTimeFromTimeSpan(item.open_time))
						setthursdayEndTime(getDateTimeFromTimeSpan(item.close_time))
					} else if (item.day_of_week == 'Friday') {
						setFriday(true)
						setfridayStartTime(getDateTimeFromTimeSpan(item.open_time))
						setfridayEndTime(getDateTimeFromTimeSpan(item.close_time))
					} else if (item.day_of_week == 'Saturday') {
						setSaturday(true)
						setsaturdayStartTime(getDateTimeFromTimeSpan(item.open_time))
						setsaturdayEndTime(getDateTimeFromTimeSpan(item.close_time))
					} else if (item.day_of_week == 'Sunday') {
						setSunday(true)
						setsundayStartTime(getDateTimeFromTimeSpan(item.open_time))
						setsundayEndTime(getDateTimeFromTimeSpan(item.close_time))
					}
				})
				setCanEnableNewsletter(res?.show_newsletter === 1 ? true : false)

				setBookingSites(res.booking_sites || [])
				setHeaderLinks(res.header_links || [])
				setSubscribers(res.subscribers || [])
				setContacts(res.contacts || [])
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
			})
	}

	const handleBookingSiteChange = (index, type, event) => {
		const cpy = bookingSites.slice(0)
		if (index < cpy.length) {
			if (type === 'url') {
				cpy[index].url = event.target.value
			} else {
				cpy[index].name = event.target.value
			}
		} else {
			const item = {}
			if (type === 'url') {
				item.url = event.target.value
			} else {
				item.name = event.target.value
			}
			cpy.push(item)
		}

		setBookingSites(cpy)
	}

	const handleRemoveBookingSite = (index) => {
		const newItems = bookingSites.filter((_, i) => i !== index)
		setBookingSites(newItems)
	}

	const handleAddBookingSite = () => {
		setBookingSites([...bookingSites, { name: '', url: '' }])
	}

	const handleHeaderLinksChange = (index, event) => {
		const cpy = headerLinks.slice(0)
		if (index < cpy.length) {
			cpy[index].name = event.target.value
		} else {
			const item = { name: event.target.value }
			cpy.push(item)
		}

		setHeaderLinks(cpy)
	}

	const handleRemoveHeaderLink = (index) => {
		const newItems = headerLinks.filter((_, i) => i !== index)
		setHeaderLinks(newItems)
	}

	const handleAddHeaderLink = () => {
		setHeaderLinks([...headerLinks, { name: '' }])
	}

	const onSubmit = (values) => {
		const filteredBSItems = bookingSites.filter(
			(bs, i) => !isEmpty(bs.url) || !isEmpty(bs.name)
		)
		const filteredHLItems = headerLinks.filter((bs, i) => !isEmpty(bs.name))
		const data = {
			booking_sites: filteredBSItems,
			header_links: filteredHLItems,
			...values,
		}
		setLoading(true)
		WhiteLabelService.update(data)
			.then((res) => {
				NotificationManager.success(
					'Data has been updated successfully!',
					'Success',
					3000
				)
				setLoading(false)
			})
			.catch((err) => {
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else if (err?.message) {
					NotificationManager.error(err?.message)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
				setLoading(false)
			})
	}

	const onSubmit2 = (values) => {
		setLoading2(true)
		WhiteLabelService.update(values)
			.then((res) => {
				NotificationManager.success(
					'Data has been updated successfully!',
					'Success',
					3000
				)
				setLoading2(false)
			})
			.catch((err) => {
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else if (err?.message) {
					NotificationManager.error(err?.message)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
				setLoading2(false)
			})
	}


	if (loading) {
		return <LoadingScreen />
	}

	return (
		<div
			className="overflow-auto"
			style={{ height: 'calc(100vh - 76px - 68px)', margin: 0, padding: 0 }}
		>
			<div className="grid sm:grid-cols-1 grid-cols-1 bg-[#F9F7F7] p-6 gap-6">
				<Card className="mt-0">
					<CardContent className="!p-0">
						<div className="w-full rounded-4xl p-6 gap-10">
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col ">
									<p className={'text-base text-gray5 mb-2'}>
										<span className="text-black1 text-xl font-medium">
											{getTranslation('manageSocialMediaLinks')}
										</span>
									</p>
									<span className="text-gray5 text-16 font-medium">
										{isRetailVenue(venueType) &&
											getTranslation('manageSocialMediaPresence')}
										{isAccommodation(venueType) &&
											getTranslation('manageSocialMediaPresence')}
										{isEntertainmentVenue(venueType) &&
											getTranslation('manageSocialMediaPresence')}
										{isFB(venueType) &&
											getTranslation('manageSocialMediaPresence')}
									</span>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('facebookPage')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.facebook_link}
												error={formikProps.errors.facebook_link}
												placeholder={getTranslation('facebookUrlPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'facebook_link',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('instagramProfile')}
												className="!mt-0 !mb-2  !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.instagram_link}
												error={formikProps.errors.instagram_link}
												placeholder={getTranslation('instagramUrlPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'instagram_link',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('xComAccount')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.twitter_link}
												error={formikProps.errors.twitter_link}
												placeholder={getTranslation('xComUrlPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'twitter_link',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('linkedinPage')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.linkedin_link}
												error={formikProps.errors.linkedin_link}
												placeholder={getTranslation('linkedinUrlPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'linkedin_link',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('pinterestAccount')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.pinterest_link}
												error={formikProps.errors.pinterest_link}
												placeholder={getTranslation('pinterestUrlPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'pinterest_link',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('tiktokAccount')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.tiktok_link}
												error={formikProps.errors.tiktok_link}
												placeholder={getTranslation('tiktokUrlPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'tiktok_link',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-1 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('socialMediaLinksSection')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.social_media_label_text}
												error={formikProps.errors.social_media_label_text}
												placeholder={getTranslation('provideLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'social_media_label_text',
														e.target.value
													)
												}}
											/>
										</div>

										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('virtualTourLinkOptional')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.vt_link}
												error={formikProps.errors.vt_link}
												placeholder={getTranslation('provideVirtualTourLink')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue('vt_link', e.target.value)
												}}
											/>
										</div>
									</div>
									<div className="flex justify-end">
										<div className="flex w-1/2 justify-between mt-6 h-[48px] gap-8">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												disabled={loading || socialMediaNotEmpty}
												onClick={() => {
													formikProps.handleSubmit()
												}}
											>
												{loading ? (
													<CircularProgress size={16} />
												) : (
													getTranslation('updateButton')
												)}
											</button>
										</div>
									</div>
								</div>
								<div className="flex flex-col ">
									<p className={'text-base text-gray5 mb-2'}>
										<span className="text-black1 text-xl font-medium">
											{getTranslation('contactAndNewsletter')}
										</span>
									</p>
									<span className="text-gray5 text-16 font-medium">
										{isRetailVenue(venueType) &&
											getTranslation('contactSupportText')}
										{isAccommodation(venueType) &&
											getTranslation('contactSupportText')}
										{isFB(venueType) && getTranslation('contactSupportText')}
										{isEntertainmentVenue(venueType) &&
											getTranslation('contactSupportText')}
									</span>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('callUsText')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps2.values.call_us_text}
												error={formikProps2.errors.call_us_text}
												placeholder={getTranslation('callUsTextPlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps2.setFieldValue(
														'call_us_text',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('supportPhone')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps2.values.support_phone}
												error={formikProps2.errors.support_phone}
												placeholder={getTranslation('supportPhonePlaceholder')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps2.setFieldValue(
														'support_phone',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<p className={'text-base text-gray5 mt-4'}>
										<RInputDeclare
											value={getTranslation('newsletter')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
									</p>
									<span className="text-gray5 text-16 mb-2 font-medium">
										{isRetailVenue(venueType) &&
											getTranslation('newsletterText')}
										{isAccommodation(venueType) &&
											getTranslation('newsletterText')}
										{isFB(venueType) && getTranslation('newsletterText')}
										{isEntertainmentVenue(venueType) &&
											getTranslation('newsletterText')}
									</span>
									<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg">
										<div className="flex flex-row gap-4">
											<div className="bg-gray2 p-3 rounded-lg">
												<img
													src={cardIcon}
													alt="referral"
													className="w-6 h-6"
												/>
											</div>
											<div className="flex flex-col">
												<div>
													<span className="text-xl font-medium text-black1 mr-3">
														{getTranslation('showNewsletter')}
													</span>
												</div>
												<span className="text-gray5 text-sm">
													{isRetailVenue(venueType) &&
														getTranslation('showNewsletterText')}
													{isAccommodation(venueType) &&
														getTranslation('showNewsletterText')}
													{isFB(venueType) &&
														getTranslation('showNewsletterText')}
													{isEntertainmentVenue(venueType) &&
														getTranslation('showNewsletterText')}
												</span>
											</div>
										</div>
										<div className="flex flex-row items-center gap-3">
											{canEnableNewsletter ? (
												<span className="text-sm font-medium text-[#027A48]">
													{getTranslation('onLabel')}
												</span>
											) : (
												<span className="text-sm font-medium text-gray5">
													{getTranslation('offLabel')}
												</span>
											)}
											<CustomSwitch
												active={canEnableNewsletter}
												onClick={handleCanEnableNewsletter}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-1 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('subscribeSectionLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps2.values.subscribe_label_text}
												error={formikProps2.errors.subscribe_label_text}
												placeholder={getTranslation(
													'provideSubscribeSectionLabel'
												)}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps2.setFieldValue(
														'subscribe_label_text',
														e.target.value
													)
												}}
											/>
										</div>
									</div>

									<div className="flex justify-end">
										<div className="flex w-1/2 justify-between mt-6 h-[48px] gap-8">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												disabled={loading2}
												onClick={() => {
													formikProps2.handleSubmit()
												}}
											>
												{loading2 ? (
													<CircularProgress size={16} />
												) : (
													getTranslation('updateButton')
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</CardContent>
				</Card>

				<Card className="mt-0">
					<CardContent className="!p-0">
						<div className="w-full rounded-4xl p-6 gap-10">
							<div className="grid md:grid-cols-1 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col">
									<p className={'text-base text-gray5 mb-2'}>
										<span className="text-black1 text-xl font-medium">
											{getTranslation('generalContactPageCustomizations')}
										</span>
									</p>
									<span className="text-gray5 text-16 font-medium">
										{isRetailVenue(venueType) &&
											getTranslation('customizeContactPage.retailVenue')}
										{isAccommodation(venueType) &&
											getTranslation('customizeContactPage.accommodationVenue')}
										{isEntertainmentVenue(venueType) &&
											getTranslation('customizeContactPage.entertainmentVenue')}
										{isFB(venueType) &&
											getTranslation('customizeContactPage.FBVenue')}
									</span>
									<div className="grid md:grid-cols-1 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactPageTitle')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_main_title_string
												}
												error={
													formikProps.errors.contact_page_main_title_string
												}
												placeholder={getTranslation('contactPageTitle')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_main_title_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactPageLeftSideTitle')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_form_subtitle_string
												}
												error={
													formikProps.errors.contact_page_form_subtitle_string
												}
												placeholder={getTranslation('contactPageLeftSideTitle')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_form_subtitle_string',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-1 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactPageSubtitle')}
												className="!mt-0 !mb-2  !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_toplabel_string}
												error={formikProps.errors.contact_page_toplabel_string}
												placeholder={getTranslation('contactPageSubtitle')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_toplabel_string',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactPageAddressLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_address_string}
												error={formikProps.errors.contact_page_address_string}
												placeholder={getTranslation('contactPageAddressLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_address_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('venueAddress')}
												className="!mt-0 !mb-2  !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_address_value}
												error={formikProps.errors.contact_page_address_value}
												placeholder={getTranslation('venueAddress')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_address_value',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactPagePhoneLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_phone_string}
												error={formikProps.errors.contact_page_phone_string}
												placeholder={getTranslation('contactPagePhoneLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_phone_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('venueContactPhoneNumber')}
												className="!mt-0 !mb-2  !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_phone_value}
												error={formikProps.errors.contact_page_phone_value}
												placeholder={getTranslation('venueContactPhoneNumber')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_phone_value',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactPageEmailLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_email_string}
												error={formikProps.errors.contact_page_email_string}
												placeholder={getTranslation('contactPageEmailLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_email_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('venueContactEmail')}
												className="!mt-0 !mb-2  !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={formikProps.values.contact_page_email_value}
												error={formikProps.errors.contact_page_email_value}
												placeholder={getTranslation('venueContactEmail')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_email_value',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('venueAvailabilityHoursLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_open_hours_string
												}
												error={
													formikProps.errors.contact_page_open_hours_string
												}
												placeholder={getTranslation(
													'venueAvailabilityHoursLabel'
												)}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_open_hours_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('contactFormSubmitButtonTitle')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_form_submit_btn_txt
												}
												error={
													formikProps.errors.contact_page_form_submit_btn_txt
												}
												placeholder={getTranslation(
													'contactFormSubmitButtonTitle'
												)}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_form_submit_btn_txt',
														e.target.value
													)
												}}
											/>
										</div>
									</div>
									<div className="flex justify-end">
										<div className="flex w-1/2 justify-between mt-6 h-[48px] gap-8">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												disabled={loading || socialMediaNotEmpty}
												onClick={() => {
													formikProps.handleSubmit()
												}}
											>
												{loading ? (
													<CircularProgress size={16} />
												) : (
													getTranslation('update')
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="grid md:grid-cols-1 mt-10 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col">
									<p className={'text-base text-gray5 mb-2'}>
										<span className="text-black1 text-xl font-medium">
											{getTranslation('formCustomizations')}
										</span>
									</p>
									<span className="text-gray5 text-16 font-medium">
										{getTranslation('tailorFormToSuitYourNeeds')}
									</span>
									<div className="grid md:grid-cols-4 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('formFullNameLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_fullname_label_string
												}
												error={
													formikProps.errors.contact_page_fullname_label_string
												}
												placeholder={getTranslation('formFullNameLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_fullname_label_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('formEmailLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_email_label_string
												}
												error={
													formikProps.errors.contact_page_email_label_string
												}
												placeholder={getTranslation('formEmailLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_email_label_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('enableContactPageForm')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.contact_page_enable}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'contact_page_enable',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('enableOpeningHoursSection')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={
													formikProps.values.contact_page_opening_hours_enable
												}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'contact_page_opening_hours_enable',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('formPhoneNumberLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values
														.contact_page_phone_number_label_string
												}
												error={
													formikProps.errors
														.contact_page_phone_number_label_string
												}
												placeholder={getTranslation('formPhoneNumberLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_phone_number_label_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation(
													'showPhoneNumberFieldOnContactPage'
												)}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={
													formikProps.values.contact_page_phone_number_show
												}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'contact_page_phone_number_show',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('formSubjectLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_subject_label_string
												}
												error={
													formikProps.errors.contact_page_subject_label_string
												}
												placeholder={getTranslation('formSubjectLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_subject_label_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('showSubjectFieldOnContactPage')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.contact_page_subject_show}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'contact_page_subject_show',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									</div>
									<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('formMessageLabel')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												value={
													formikProps.values.contact_page_content_label_string
												}
												error={
													formikProps.errors.contact_page_content_label_string
												}
												placeholder={getTranslation('formMessageLabel')}
												sx={{
													'& .MuiOutlinedInput-notchedOutline': {
														border: '1px solid #d9d9d9!important',
													},
												}}
												style={{ background: '#fff' }}
												onChange={(e) => {
													formikProps.setFieldValue(
														'contact_page_content_label_string',
														e.target.value
													)
												}}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('showMessageFieldOnContactPage')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.contact_page_content_show}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'contact_page_content_show',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									</div>
									<div className="flex justify-end">
										<div className="flex w-1/2 justify-between mt-6 h-[48px] gap-8">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												disabled={loading || socialMediaNotEmpty}
												onClick={() => {
													formikProps.handleSubmit()
												}}
											>
												{loading ? (
													<CircularProgress size={16} />
												) : (
													getTranslation('update')
												)}
											</button>
										</div>
									</div>
								</div>
							</div>

							<ContactsTable
								columns={contactSubmissions}
								contactsList={contacts}
								venueType={venueType}
							/>
						</div>
					</CardContent>
				</Card>

				<Card>
					<CardContent>
						<p className={'text-base text-gray5 mb-2'}>
							<span className="text-black1 text-xl font-medium">
								{isRetailVenue(venueType) &&
									getTranslation('manageMenuLinks.retailVenue')}
								{isAccommodation(venueType) &&
									getTranslation('manageMenuLinks.accommodationVenue')}
								{isEntertainmentVenue(venueType) &&
									getTranslation('manageMenuLinks.entertainmentVenue')}
								{isFB(venueType) && getTranslation('manageMenuLinks.FBVenue')}
							</span>
						</p>
						<span className="text-gray5 text-16 font-medium">
							{isRetailVenue(venueType) &&
								getTranslation('reorganizeWebsiteMenuLinks.retailVenue')}
							{isEntertainmentVenue(venueType) &&
								getTranslation('reorganizeWebsiteMenuLinks.entertainmentVenue')}
							{isFB(venueType) &&
								getTranslation('reorganizeWebsiteMenuLinks.FBVenue')}
							{isAccommodation(venueType) &&
								getTranslation('reorganizeWebsiteMenuLinks.accommodationVenue')}
						</span>

						{isAccommodation(venueType) && (
							<div className="grid grid-cols-2 gap-2 mt-6">
								<div className="flex flex-col">
									<RInputDeclare
										value={getTranslation('bookingSites')}
										className={`!mt-0 !mb-2 !text-black1 font-medium text-base`}
									/>
									{bookingSites.map((item, index) => (
										<div key={index} className="flex gap-2 items-center mb-2">
											<TextField
												size="small"
												value={item.name}
												placeholder={`Booking Site #${index + 1}`}
												onChange={(e) =>
													handleBookingSiteChange(index, 'name', e)
												}
												className="flex-grow"
												style={{ background: '#fff' }}
											/>
											<TextField
												size="small"
												value={item.url}
												placeholder={`URL #${index + 1}`}
												onChange={(e) =>
													handleBookingSiteChange(index, 'url', e)
												}
												className="flex-grow"
												style={{ background: '#fff' }}
											/>
											<IconButton
												onClick={() => handleRemoveBookingSite(index)}
												size="small"
												className="ml-2"
											>
												<DeleteIcon />
											</IconButton>
										</div>
									))}
									<button
										className="rounded-md font-bold bg-cyan1 text-white mt-2 p-2"
										onClick={handleAddBookingSite}
									>
										{getTranslation('addNewBookingSiteRow')}
									</button>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={getTranslation('websiteMenuItems')}
										className={`!mt-0 !mb-2 !text-black1 font-medium text-base`}
									/>
									{headerLinks.map((item, index) => (
										<div key={index} className="flex items-center mb-2">
											<TextField
												size="small"
												value={item.name}
												placeholder={`Item #${index + 1}`}
												onChange={(e) => handleHeaderLinksChange(index, e)}
												className="flex-grow"
												style={{ background: '#fff' }}
											/>
											<IconButton
												onClick={() => handleRemoveHeaderLink(index)}
												size="small"
												className="ml-2"
											>
												<DeleteIcon />
											</IconButton>
										</div>
									))}
									<button
										className="rounded-md font-bold bg-cyan1 text-white mt-2 p-2"
										onClick={handleAddHeaderLink}
									>
										{getTranslation('addNewMenuLinkItem')}
									</button>
								</div>
							</div>
						)}

						{(isRetailVenue(venueType) ||
							isEntertainmentVenue(venueType) ||
							isFB(venueType)) && (
							<div className="grid grid-cols-1 gap-2 mt-6">
								<div className="flex flex-col">
									<RInputDeclare
										value={getTranslation('headerLinks')}
										className={`!mt-0 !mb-2 !text-black1 font-medium text-base`}
									/>
									{headerLinks.map((item, index) => (
										<div key={index} className="flex items-center mb-2">
											<TextField
												size="small"
												value={item.name}
												placeholder={`Name #${index + 1}`}
												onChange={(e) => handleHeaderLinksChange(index, e)}
												className="flex-grow"
												style={{ background: '#fff' }}
											/>
											<IconButton
												onClick={() => handleRemoveHeaderLink(index)}
												size="small"
												className="ml-2"
											>
												<DeleteIcon />
											</IconButton>
										</div>
									))}
									<button
										className="rounded-md font-bold bg-cyan1 text-white mt-2 p-2"
										onClick={handleAddHeaderLink}
									>
										{getTranslation('addNew')}
									</button>
								</div>
							</div>
						)}

						<div className="flex justify-end">
							<div className="flex w-1/2 justify-between mt-8 h-[48px] gap-8">
								<button
									className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
									onClick={() => {
										formikProps.handleSubmit()
									}}
								>
									{loading ? (
										<CircularProgress size={16} />
									) : (
										getTranslation('updateButton')
									)}
								</button>
							</div>
						</div>
					</CardContent>
				</Card>

				<Card>
					<CardContent className="!p-0">
						<SubscribersTable
							columns={columnsSubscribers}
							subscribersList={subscribers}
							venueType={venueType}
						/>
					</CardContent>
				</Card>

				{!isEntertainmentVenue(venueType) && (
					<Card className="mt-6">
						<p
							style={{ padding: '20px 15px 20px 15px' }}
							className="text-black1 font-medium text-[20px]"
						>
							{getTranslation('manageOpeningHours')}
						</p>
						<CardContent style={{ background: '#f6f6f8' }}>
							<div className="flex flex-col">
								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.mon')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={monday}
												onChange={(e) => setMonday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={mondayStartTime}
												onChange={setmondayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={mondayEndTime}
												onChange={setmondayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.fri')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={friday}
												onChange={(e) => setFriday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={fridayStartTime}
												onChange={setfridayStartTime}
											/>
										</div>
										<div class="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={fridayEndTime}
												onChange={setfridayEndTime}
											/>
										</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.tue')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={tuesday}
												onChange={(e) => setTuesday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={tuesdayStartTime}
												onChange={settuesdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={tuesdayEndTime}
												onChange={settuesdayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.sat')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={saturday}
												onChange={(e) => setSaturday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={saturdayStartTime}
												onChange={setsaturdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={saturdayEndTime}
												onChange={setsaturdayEndTime}
											/>
										</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.wed')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={wendsday}
												onChange={(e) => setWendsday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={wendsdayStartTime}
												onChange={setwendsdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={wendsdayEndTime}
												onChange={setwendsdayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.sun')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={sunday}
												onChange={(e) => setSunday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={sundayStartTime}
												onChange={setsundayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={sundayEndTime}
												onChange={setsundayEndTime}
											/>
										</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('daysOfTheWeek.thu')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={thursday}
												onChange={(e) => setThursday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={thursdayStartTime}
												onChange={setthursdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={getTranslation('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={thursdayEndTime}
												onChange={setthursdayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-col justify-start center-align p-2">
										<div className="flex flex-row justify-center align-center h-[48px] mt-4">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												onClick={onSubmitAvailability}
											>
												{getTranslation('updateButton')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>
				)}
			</div>
		</div>
	)
}
