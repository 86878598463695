import { Card, CardContent } from '@mui/material'
import React from 'react'
import CustomLineChart from '../../../Charts/custom-line-chart'
import TableEmptyData from '../../../table/TableEmptyData'

export default function OrdersChart({data}) {
	const lineChartList = [
		{ dataKey: 'order_count', name: 'Number of Orders', color: '#8884d8' },
	]

	return (
		<Card>
			<CardContent className="!p-4">
				<div className="w-full mb-5">
					<span className=" font-bold text-18 text-black1 leading-6 ">Orders</span>
					<div className="text-12">
						Orders graphical view
					</div>
				</div>
				{data.length > 0 ? (
					<CustomLineChart
						data={data}
						dataKey="date"
						lineChartList={lineChartList}
						showLegend={false}
						height={300}
						showTooltip={true}
					/>
				) : (
					<div className="border border-x-0 border-b-0 mb-10">
						<TableEmptyData content="orders trends" />
					</div>
				)}
			</CardContent>
		</Card>
	)
}
