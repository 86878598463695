import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/dollar_white.svg'
import { boxStyle } from '../../constants/types'
import PaymentLinkService from '../../services/paymentLinkService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import CustomDateTimePicker from '../custom/CustomDateTimePicker'
import { RInputDeclare } from '../custom/RInputDeclare'

const paymentOptions = [
	{
		label: 'Cash',
		value: 'cash',
	},
	{
		label: 'Card',
		value: 'card',
	},
]
const paymentStructureOptions = [
	{
		label: 'Per Person',
		value: 'per_person',
	},
	{
		label: 'Total',
		value: 'total',
	},
]

export const paymentLinkModalText = (text) =>
	t(`orderandpay.paymentLinks.paymentLinkModal.${text}`)

export default function PaymentLinkModal({ showModal, onConfirm, onClose }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [paymentType, setPaymentType] = useState('')
	const [paymentStructure, setPaymentStructure] = useState('')
	const [partySize, setPartySize] = useState('')
	const [startTime, setStartTime] = useState('')
	const [endTime, setEndTime] = useState('')
	const [total, setTotal] = useState('')
	const [pricePerPerson, setPricePerPerson] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setPaymentType('')
			setPaymentStructure('')
			setPartySize('')
			setStartTime('')
			setEndTime('')
			setTotal('')
			setPricePerPerson('')
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (paymentType && paymentStructure && partySize && total) {
			if (paymentStructure === 'per_person' && !pricePerPerson) {
				setCheckValid(true)
				return
			}
			let payload = {
				payment_type: paymentType,
				payment_structure: paymentStructure,
				party_size: partySize,
				amount: total,
				start_time: startTime,
				end_time: endTime,
				price_per_person:
					paymentStructure === 'per_person' ? pricePerPerson : null,
			}
			setLoading(true)
			PaymentLinkService.Create(payload)
				.then(({ data }) => {
					identifyVenue(short_code)
					trackEvent('payment service', {
						action_category: 'create',
						action_outcome: 'success',
						interaction_element: 'payments',
					})
					setLoading(false)
					onConfirm({ data: data?.link })
					onClose()
					NotificationManager.success(
						data?.message ?? 'Payment Link is saved successfully'
					)
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('payment service', {
						action_category: 'create',
						action_outcome: 'fail',
						interaction_element: 'payments',
					})
					setLoading(false)
					if (typeof err?.error === 'string') {
						NotificationManager.error(err?.error)
					} else {
						const errorKeys = Object.keys(err?.error || {})
						if (errorKeys.length > 0) {
							const firstErrorKey = errorKeys[0]
							const firstErrorMessage = err?.error[firstErrorKey][0]
							NotificationManager.error(
								firstErrorMessage || 'Something went wrong!'
							)
						} else {
							NotificationManager.error('Something went wrong!')
						}
					}
				})
		} else {
			setCheckValid(true)
		}
	}
	const onCloseModal = () => {
		onClose(null)
	}

	const onChangeStartTime = (currentDate) => {
		setStartTime(currentDate)
	}

	const onChangeEndTime = (currentDate) => {
		setEndTime(currentDate)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div
						className="flex flex-row items-center px-024 pt-024 pb-0 gap-3"
						style={{ marginBottom: '-20px' }}
					>
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{paymentLinkModalText('modal_title')}
						</label>
					</div>
					<div className="flex flex-col p-024">
						<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<RInputDeclare
									value={paymentLinkModalText('payment_type')}
									className="!mt-4 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									error={checkValid && !paymentType}
									select
									value={paymentType}
									onChange={(event) => setPaymentType(event.target.value)}
								>
									{paymentOptions.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</div>
							<div className="flex flex-col">
								<RInputDeclare
									value={paymentLinkModalText('payment_structure')}
									className="!mt-4 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									error={checkValid && !paymentStructure}
									select
									value={paymentStructure}
									onChange={(event) => setPaymentStructure(event.target.value)}
								>
									{paymentStructureOptions.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</div>
						</div>
						<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<RInputDeclare
									value={paymentLinkModalText('party_size')}
									className="!mt-4 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									type="number"
									size="small"
									error={checkValid && !partySize}
									id="filled-required"
									value={partySize}
									onChange={(e) => setPartySize(e.target.value)}
									inputProps={{
										inputProps: { min: 0 },
									}}
								/>
							</div>
							<div className="flex flex-col">
								<RInputDeclare
									value={paymentLinkModalText('total')}
									className="!mt-4 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									type="number"
									size="small"
									error={checkValid && !total}
									id="filled-required"
									value={total}
									onChange={(e) => setTotal(e.target.value)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<p className="text-[14px] !text-black1 font-bold">$</p>
											</InputAdornment>
										),
										inputProps: { min: 0 },
									}}
								/>
							</div>
						</div>
						<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<RInputDeclare
									value={paymentLinkModalText('start_time')}
									className="!mt-4 !mb-0 !text-black1 font-medium text-base"
								/>
								<CustomDateTimePicker
									onlyDate={false}
									value={startTime}
									onChange={onChangeStartTime}
								/>
							</div>
							<div className="flex flex-col">
								<RInputDeclare
									value={paymentLinkModalText('end_time')}
									className="!mt-4 !mb-0 !text-black1 font-medium text-base"
								/>
								<CustomDateTimePicker
									onlyDate={false}
									value={endTime}
									onChange={onChangeEndTime}
								/>
							</div>
						</div>
						{paymentStructure === 'per_person' && (
							<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={paymentLinkModalText('price_per_person')}
										className="!mt-4 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required={paymentStructure === 'per_person'}
										type="number"
										size="small"
										error={checkValid && !pricePerPerson}
										id="filled-required"
										value={pricePerPerson}
										onChange={(e) => setPricePerPerson(e.target.value)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<p className="text-[14px] !text-black1 font-bold">
														$
													</p>
												</InputAdornment>
											),
											inputProps: { min: 0 },
										}}
									/>
								</div>
								<div className="flex flex-col"></div>
							</div>
						)}
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCloseModal}
						>
							{paymentLinkModalText('cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								paymentLinkModalText('generate')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
