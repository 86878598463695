import { faEye as iconEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   Card,
   CardContent,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
} from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

// import plusIcon from "../../../../assets/svgs/plus.svg";
import { t } from 'i18next'
import searchIcon from '../../../../assets/svgs/search.svg'
import { createUUID } from '../../../../utils/common'

export const realTimeStatusUpdatesText = (text) =>
	t(
		`accommodation.rental_unit.rentalHousekeeping.realTimeStatusUpdates.${text}`
	)

export default function RealTimeStatusUpdates() {
	const [realtimeList] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	return (
		<div className="flex flex-col h-full">
			<div className="flex flex-col h-full p-6">
				<Card>
					<CardContent className="!p-0">
						<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2">
							<div className="flex flex-row items-center py-2">
								<span className="text-black1 text-xl font-medium">
									{realTimeStatusUpdatesText('realtime_status_updates')}
								</span>
								<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
									{realtimeList.length} {realTimeStatusUpdatesText('notifications')}
								</span>
							</div>
						</div>
						{realtimeList.length ? (
							<>
								<div className="flex flex-col overflow-auto max-h-[70vh]">
									<TableContainer component={Paper} className="mt-2">
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead className="bg-gray7">
												<TableRow key={createUUID()}>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{realTimeStatusUpdatesText('booking_id')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{realTimeStatusUpdatesText('room_id')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{realTimeStatusUpdatesText('notified_at')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{realTimeStatusUpdatesText('mark_as_read')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{realtimeList
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((row, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.bookingId}
															</TableCell>

															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.roomId}
															</TableCell>

															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{moment(row.notifiedAt).format(
																	'DD MMM YYYY, hh:mm A'
																)}
															</TableCell>

															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																<FontAwesomeIcon
																	icon={iconEye}
																	size="lg"
																	className="pr-012 text-gray3"
																/>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
								<TablePagination
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={realtimeList.length}
									rowsPerPage={rowsPerPage}
									page={page}
									className="!p-2"
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</>
						) : (
							<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
								<div className="bg-gray7 !p-2 rounded-full">
									<div className="bg-black1 !p-2 rounded-full">
										<img src={searchIcon} alt="search" />
									</div>
								</div>
								<span className="text-black1 text-lg">
									{realTimeStatusUpdatesText('no_realtime_updates')}
								</span>
								<span className="text-gray5 text-base mt-1">
									{realTimeStatusUpdatesText('realtime_updates_description')}
								</span>
							</div>
						)}
					</CardContent>
				</Card>
			</div>
		</div>
	)
}
