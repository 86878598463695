import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/assign-guest.svg'
import GuestService from '../../services/guestService'
import TableService from '../../services/tableService'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select'
import GenericModal from '../generic-modal'

const getTranslation = (text) => t(`components.table.AssignGuestModal.${text}`)

export default function AssignGuestModal({ showModal, onClose, refresh }) {
	const [guest, setGuest] = useState()
	const [table, setTable] = useState()
	const [guestOptions, setGuestOptions] = useState([])
	const [tableOptions, setTableOptions] = useState([])

	useEffect(() => {
		if (showModal && table && guest) {
			setGuest(0)
			setTable(0)

			let payload = {
				start_time: moment().format('YYYY-MM-DD 05:00:00'),
				end_time: moment().format('YYYY-MM-DD 23:00:00'),
			}
			TableService.GetAvailables(payload).then((res) => {
				if (res.data) {
					setTableOptions(
						res.data.map((item) => {
							return {
								value: item.id,
								label: item.name,
							}
						})
					)

					refresh()
				}
			})

			GuestService.GetGuestsWithoutTable().then((res) => {
				setGuestOptions(
					res.data.map((item) => {
						return {
							start_time: moment(new Date()).format('YYYY-MM-DD 05:00'),
							end_time: moment(new Date()).format('YYYY-MM-DD 23:00'),
							id: item.id,
							guest_ids: [item.id],
							value: item.id,
							label: item.name,
						}
					})
				)
			})
		}
	}, [showModal, table, guest, refresh])

	const onSubmit = () => {
		if (guest && table) {
			let guestDetail = guestOptions.find((o) => o.id === guest)
			if (guestDetail) {
				let payload = {
					guest_ids: guestDetail.guest_ids,
					table_id: table,
				}
				TableService.AssignGuests(payload)
					.then(() => {
						NotificationManager.success(
							'New reservation created successfully',
							''
						)
					})
					.catch((e) => {
						NotificationManager.error('Failed', '')
					})
			}
			onClose()
		}
	}

	const children = (
		<div className="flex flex-col gap-6">
			<CustomFormSelect
				setOptionValue={setTable}
				value={table}
				placeholder={getTranslation('table')}
				options={tableOptions}
				required={true}
			/>

			<CustomFormSelect
				setOptionValue={setGuest}
				value={guest}
				placeholder={getTranslation('guest')}
				options={guestOptions}
				required={true}
			/>
		</div>
	)

	return (
		<GenericModal
			{...{ icon, open: showModal, children, onClose, onSubmit }}
			title={getTranslation('assignGuest')}
		/>
	)
}
