import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { t } from 'i18next'
import referralIcon from '../../../assets/svgs/referral.svg'
import searchIcon from '../../../assets/svgs/search.svg'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import ConvertCredits from './ConvertCredits'

export const featuresByPlanText = (text) =>
	t(`settings.featuresByPlan.view.${text}`)

export default function FeaturesByPlan() {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const allFeatures = [
		{
			feature: 'Streamlined Reservations',
			default: 10,
			used: 0,
			remaining: 10,
		},
		{
			feature: 'Inventory Management',
			default: 10,
			used: 0,
			remaining: 10,
		},
		{
			feature: 'Staff Management',
			default: 10,
			used: 0,
			remaining: 10,
		},
		{
			feature: 'Guests Management',
			default: 10,
			used: 0,
			remaining: 10,
		},
		{
			feature: 'Analytics & Reporting',
			default: 'Limited',
			used: '-',
			remaining: '-',
		},
		{
			feature: 'Guests Surveys & Ratings',
			default: 10,
			used: 0,
			remaining: 10,
		},
		{
			feature: 'Menu Management',
			default: 10,
			used: 0,
			remaining: 10,
		},
	]

	const [loaded, setLoaded] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		loadData()
	}, [])

	const loadData = () => {
		setLoaded(true)
		// MarketingService.GetGuestsWithoutCard()
		// 	.then(res => {
		// 		setCallEnd(v => ({ ...v, guest: true }))
		// 		setGuests(res.data);
		// 	})
		// 	.catch(error => {
		// 		setCallEnd(v => ({ ...v, guest: true }))
		// 		NotificationManager.error((error.message || 'Something went wrong!'), 'Error', 3000);
		// 	})
		//
		// MarketingService.GetEndUserCards()
		// 	.then(res => {
		// 		setCallEnd(v => ({ ...v, endusercard: true }))
		// 		setEndUserCards(res.data);
		// 	})
		// 	.catch((error) => {
		// 		setCallEnd(v => ({ ...v, endusercard: true }))
		// 		NotificationManager.error((error.message || 'Something went wrong!'), 'Error', 3000);
		// 	})
	}

	const refreshData = () => {
		// MarketingService.GetGuestsWithoutCard()
		// 	.then(res => {
		// 		setCallEnd(v => ({ ...v, guest: true }))
		// 		setGuests(res.data);
		// 	})
		// 	.catch(error => {
		// 		setCallEnd(v => ({ ...v, guest: true }))
		// 		NotificationManager.error((error.message || 'Something went wrong!'), 'Error', 3000);
		// 	})
		//
		// MarketingService.GetEndUserCards()
		// 	.then(res => {
		// 		setCallEnd(v => ({ ...v, endusercard: true }))
		// 		setEndUserCards(res.data);
		// 	})
		// 	.catch((error) => {
		// 		setCallEnd(v => ({ ...v, endusercard: true }))
		// 		NotificationManager.error((error.message || 'Something went wrong!'), 'Error', 3000);
		// 	})
	}

	return (
		<>
			{loaded ? (
				<>
					<div className="p-6 mb-4">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-row items-center justify-between p-7 ">
									<div className="flex flex-row items-center">
										<div className="bg-gray2 p-3 rounded-lg mr-6">
											<img
												src={referralIcon}
												alt="referral"
												className="w-6 h-6"
											/>
										</div>
										<div className="flex flex-col justify-center items-start">
											<p className="text-base text-black1 font-medium">
												{featuresByPlanText('title')}
											</p>
											<div className="flex flex-row justify-center items-center mt-1">
												<div className="text-xl text-black1 font-medium mr-5">
													{featuresByPlanText('all_plan_features')}
												</div>
											</div>
										</div>
										<div className="w-[80px]" />
									</div>
									<div className="flex flex-row justify-start items-center gap-4"></div>
								</div>
							</CardContent>
							<CardContent className="!p-0">
								{allFeatures.length ? (
									<>
										<div className="flex flex-col overflow-auto max-h-[70vh]">
											<TableContainer component={Paper} className="">
												<Table sx={{ minWidth: 650 }} aria-label="simple table">
													<TableHead className="bg-gray7">
														<TableRow>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="left"
															>
																{featuresByPlanText('feature_column')}
															</TableCell>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																{featuresByPlanText('default')}
															</TableCell>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																{featuresByPlanText('used')}
															</TableCell>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																{featuresByPlanText('remaining')}
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{allFeatures
															.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															.map((row, index) => (
																<TableRow key={index}>
																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base !font-bold cursor-pointer"
																	>
																		{row.feature}
																	</TableCell>
																	<TableCell
																		align="left"
																		className={`py-3.5 text-base`}
																	>
																		<span
																			className={`px-2 py-0.5 w-max !text-gray5 text-sm font-bold rounded`}
																		>
																			{row.default}
																		</span>
																	</TableCell>
																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base"
																	>
																		{row.used}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base"
																	>
																		{row.remaining}
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={allFeatures.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</>
								) : (
									<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
										<div className="bg-gray7 !p-2 rounded-full">
											<div className="bg-black1 !p-2 rounded-full">
												<img src={searchIcon} alt="search" />
											</div>
										</div>
										<span className="text-black1 text-lg">
											{featuresByPlanText('no_wallet_history')}
										</span>
										<span className="text-gray5 text-base mt-1">
											{featuresByPlanText('no_wallet_history_message')}
										</span>
									</div>
								)}
							</CardContent>
						</Card>
					</div>
				</>
			) : (
				<LoadingScreen />
			)}

			{showModal && (
				<ConvertCredits
					showModal={showModal}
					onClose={(refresh) => {
						if (refresh === true) {
							refreshData()
						}
						setShowModal(false)
					}}
				/>
			)}
		</>
	)
}
