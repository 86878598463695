import v2AccountSubscriptionStandard from '../../../../assets/svgs/v2-account-subscription-standard.svg'
import v2AccountSubscriptionWhite from '../../../../assets/svgs/v2-account-subscription-white.svg'
import v2AccountVenueSpaceStandard from '../../../../assets/svgs/v2-account-venue-space-standard.svg'
import v2AccountVenueSpaceWhite from '../../../../assets/svgs/v2-account-venue-space-white.svg'
import v2AnalyticsStandard from '../../../../assets/svgs/v2-analytics-standard.svg'
import v2AnalyticsWhite from '../../../../assets/svgs/v2-analytics-white.svg'
import v2CampaignStandard from '../../../../assets/svgs/v2-campaign-standard.svg'
import v2CampaignWhite from '../../../../assets/svgs/v2-campaign-white.svg'
import v2CustomerInsightStandard from '../../../../assets/svgs/v2-customer_insights-standard.svg'
import v2CustomerInsightWhite from '../../../../assets/svgs/v2-customer_insights-white.svg'
import v2DashboardStandard from '../../../../assets/svgs/v2-dashboard-standard.svg'
import v2DashboardWhite from '../../../../assets/svgs/v2-dashboard-white.svg'
import v2DeliveryPickupStandard from '../../../../assets/svgs/v2-deliverypickup-standard.svg'
import v2DeliveryPickupWhite from '../../../../assets/svgs/v2-deliverypickup-white.svg'
import v2DiscountStandard from '../../../../assets/svgs/v2-discount-standard.svg'
import v2DiscountWhite from '../../../../assets/svgs/v2-discount-white.svg'
import v2EmailStandard from '../../../../assets/svgs/v2-email-standard.svg'
import v2EmailWhite from '../../../../assets/svgs/v2-email-white.svg'
import v2GuestStandard from '../../../../assets/svgs/v2-guest-standard.svg'
import v2GuestWhite from '../../../../assets/svgs/v2-guest-white.svg'
import v2GuestsEnrolledStandard from '../../../../assets/svgs/v2-guests-enrolled-standard.svg'
import v2GuestsEnrolledWhite from '../../../../assets/svgs/v2-guests-enrolled-white.svg'
import v2GymStandard from '../../../../assets/svgs/v2-gym-standard.svg'
import v2GymWhite from '../../../../assets/svgs/v2-gym-white.svg'
import v2IntegrationStandard from '../../../../assets/svgs/v2-integration-standard.svg'
import v2IntegrationWhite from '../../../../assets/svgs/v2-integration-white.svg'
import v2LoyaltyStandard from '../../../../assets/svgs/v2-loyalty-standard..svg'
import v2LoyaltyWhite from '../../../../assets/svgs/v2-loyalty-white.svg'
import v2LoyaltyProgramStandard from '../../../../assets/svgs/v2-loyaltyprogram-standart.svg'
import v2LoyaltyProgramWhite from '../../../../assets/svgs/v2-loyaltyprogram-white.svg'
import v2MarketingStandard from '../../../../assets/svgs/v2-marketing-standard.svg'
import v2MarketingWhite from '../../../../assets/svgs/v2-marketing-white.svg'
import v2MenuCategoryStandard from '../../../../assets/svgs/v2-menu-category-standard.svg'
import v2MenuCategoryWhite from '../../../../assets/svgs/v2-menu-category-white.svg'
import v2MenuInventoryStandard from '../../../../assets/svgs/v2-menu-inventory-standard.svg'
import v2MenuInventoryWhite from '../../../../assets/svgs/v2-menu-inventory-white.svg'
import v2MenuProductStandard from '../../../../assets/svgs/v2-menu-product-standard.svg'
import v2MenuProductWhite from '../../../../assets/svgs/v2-menu-product-white.svg'
import v2MenuStandard from '../../../../assets/svgs/v2-menu-standard.svg'
import v2MenuWhite from '../../../../assets/svgs/v2-menu-white.svg'
import v2OrderPaymentLinkStandard from '../../../../assets/svgs/v2-orderpay-paymentlinks-standard.svg'
import v2OrderPaymentLinkWhite from '../../../../assets/svgs/v2-orderpay-paymentlinks-white.svg'
import v2OrderAndPayStandard from '../../../../assets/svgs/v2-orderpay-standard.svg'
import v2OrderAndPayWhite from '../../../../assets/svgs/v2-orderpay-white.svg'
import v2ReferralStandard from '../../../../assets/svgs/v2-referal-standard.svg'
import v2ReferralWhite from '../../../../assets/svgs/v2-referal-white.svg'
import v2ReservationStandard from '../../../../assets/svgs/v2-reservation-standard.svg'
import v2ReservationWhite from '../../../../assets/svgs/v2-reservation-white.svg'
import v2SettingStandard from '../../../../assets/svgs/v2-setting-standard.svg'
import v2SettingWhite from '../../../../assets/svgs/v2-setting-white.svg'
import v2StaffStandard from '../../../../assets/svgs/v2-staff-standard.svg'
import v2StaffWhite from '../../../../assets/svgs/v2-staff-white.svg'
import v2TableStandard from '../../../../assets/svgs/v2-table-standard.svg'
import v2TableWhite from '../../../../assets/svgs/v2-table-white.svg'
import v2TOStandard from '../../../../assets/svgs/v2-timeoff-standard.svg'
import v2TOWhite from '../../../../assets/svgs/v2-timeoff-white.svg'
import { getSportTranslation } from '../amusement_and_theme_park'

export const GymSidebar = (featureList, subscription_plan) => {
	const canShowReferral =
		subscription_plan?.name === 'Discover' ||
		subscription_plan?.name === 'Launch' ||
		subscription_plan?.name === 'Elevate'
	const additionalFeatureList = ['Gym', 'Settings']

	// Note: this should be returned by backend
	const additionalSubFeatures = [
		'Dashboard',
		'Analytics & Reporting',
		'Customer Insights',
		'Categories',
		'Products/Items',
		'Communication Preferences',
		'Employees',
		'Time-off requests',
		'Integrations',
		'Promotions',
		'Emails',
		'Referrals',
		'Campaigns',
		'Wallet',
		'Programs',
		'Customers Enrolled',
		'Payment Links',
		'Subscription',
		'Customizable Brand Profile',
		'Account',
		'Hygiene Standards',
		'Bookings',
		'Manage',
		'Bookings (Availability)',
		'Classes',
		'Configurations',
		'Customers',
	]

	const otherMappings = {
		'Entertainment Membership Program': 'Cards',
		'Guest Surveys and Ratings': 'Surveys and Ratings',
		'Payment Links': 'Order & Pay',
		'Loyalty and Retention': 'Loyalty',
		'Inventory Management': 'Inventory',
		'Bookings Management': 'Bookings',
	}

	// Function to check if a subfeature is included based on the featureList, additionalSubFeatures, or otherMappings
	const isSubFeatureIncluded = (subFeatureText) => {
		// First, check if the subFeatureText is directly included
		if (
			featureList?.includes(subFeatureText) ||
			additionalSubFeatures?.includes(subFeatureText)
		) {
			return true
		}

		// Then, check in otherMappings
		return Object.entries(otherMappings).some(([key, value]) => {
			return (
				value === subFeatureText &&
				(featureList?.includes(key) || additionalSubFeatures?.includes(key))
			)
		})
	}

	const isFeatureIncluded = (feature) => {
		return (
			featureList?.includes(feature) ||
			additionalFeatureList?.includes(feature) ||
			Object.keys(otherMappings).some(
				(key) => otherMappings[key] === feature && featureList.includes(key)
			)
		)
	}

	const GymSidebarData = [
		{
			img: v2DashboardStandard,
			imgActive: v2DashboardWhite,
			key: 1,
			name: 'dashboard',
			text: getSportTranslation('dashboard'),
			default: true,
			menus: [
				{
					img: v2DashboardStandard,
					imgActive: v2DashboardWhite,
					name: 'venue-dashboard',
					text: getSportTranslation('venue-dashboard'),
					key: 1,
				},
				{
					img: v2AnalyticsStandard,
					imgActive: v2AnalyticsWhite,
					name: 'analytics',
					text: getSportTranslation('analytics'),
					key: 2,
				},
				{
					img: v2CustomerInsightStandard,
					imgActive: v2CustomerInsightWhite,
					name: 'insights',
					text: getSportTranslation('insights'),
					key: 3,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2GymStandard,
			imgActive: v2GymWhite,
			key: 2,
			name: 'gym',
			text: getSportTranslation('gym'),
			menus: [
				{
					img: v2GymStandard,
					imgActive: v2GymWhite,
					name: 'manage',
					text: getSportTranslation('manage'),
					key: 1,
				},
				{
					img: v2MarketingStandard,
					imgActive: v2MarketingWhite,
					name: 'availability',
					text: getSportTranslation('availability'),
					key: 2,
				},
				{
					img: v2TableStandard,
					imgActive: v2TableWhite,
					name: 'classes',
					text: getSportTranslation('classes'),
					key: 3,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2ReservationStandard,
			imgActive: v2ReservationWhite,
			key: 3,
			name: 'ev-booking',
			text: getSportTranslation('ev-booking'),
			menus: [
				{
					img: v2ReservationStandard,
					imgActive: v2ReservationWhite,
					name: 'bookings',
					text: getSportTranslation('bookings'),
					key: 1,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2GuestStandard,
			imgActive: v2GuestWhite,
			key: 4,
			name: 'customers',
			text: getSportTranslation('customers'),
			menus: [
				{
					img: v2GuestStandard,
					imgActive: v2GuestWhite,
					name: 'customers',
					text: getSportTranslation('customers'),
					key: 1,
				},
				{
					img: v2GuestStandard,
					imgActive: v2GuestWhite,
					name: 'communication-preferences',
					text: getSportTranslation('communication-preferences'),
					key: 2,
				},
				{
					img: v2GuestStandard,
					imgActive: v2GuestWhite,
					name: 'surveys-and-ratings',
					text: getSportTranslation('surveys-and-ratings'),
					key: 3,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2MenuStandard,
			imgActive: v2MenuWhite,
			key: 5,
			name: 'menu',
			text: getSportTranslation('menu'),
			menus: [
				{
					img: v2MenuProductStandard,
					imgActive: v2MenuProductWhite,
					name: 'items',
					text: getSportTranslation('items'),
					key: 1,
				},
				{
					img: v2MenuCategoryStandard,
					imgActive: v2MenuCategoryWhite,
					name: 'categories',
					text: getSportTranslation('categories'),
					key: 2,
				},
				{
					img: v2MenuInventoryStandard,
					imgActive: v2MenuInventoryWhite,
					name: 'inventory',
					text: getSportTranslation('inventory'),
					key: 3,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2StaffStandard,
			imgActive: v2StaffWhite,
			key: 6,
			name: 'staff',
			text: getSportTranslation('staff'),
			menus: [
				{
					img: v2StaffStandard,
					imgActive: v2StaffWhite,
					name: 'employees',
					text: getSportTranslation('employees'),
					key: 1,
				},
				{
					img: v2TOStandard,
					imgActive: v2TOWhite,
					name: 'time-off-requests',
					text: getSportTranslation('time-off-requests'),
					key: 2,
				},
				{
					img: v2IntegrationStandard,
					imgActive: v2IntegrationWhite,
					name: 'staff-integrations',
					text: getSportTranslation('staff-integrations'),
					key: 3,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2MarketingStandard,
			imgActive: v2MarketingWhite,
			key: 7,
			name: 'marketing',
			text: getSportTranslation('marketing'),
			menus: [
				{
					img: v2DiscountStandard,
					imgActive: v2DiscountWhite,
					name: 'promotions',
					text: getSportTranslation('promotions'),
					key: 1,
				},
				{
					img: v2EmailStandard,
					imgActive: v2EmailWhite,
					name: 'emails',
					text: getSportTranslation('emails'),
					key: 2,
				},
				{
					img: v2ReferralStandard,
					imgActive: v2ReferralWhite,
					name: 'referrals',
					text: getSportTranslation('referrals'),
					key: 3,
					show: canShowReferral,
				},
				{
					img: v2CampaignStandard,
					imgActive: v2CampaignWhite,
					name: 'campaigns',
					text: getSportTranslation('campaigns'),
					key: 4,
				},
				{
					img: v2StaffStandard,
					imgActive: v2StaffWhite,
					name: 'enduser-card',
					text: getSportTranslation('enduser-card'),
					key: 5,
				},
				{
					img: v2OrderAndPayStandard,
					imgActive: v2OrderAndPayWhite,
					name: 'wallet',
					text: getSportTranslation('wallet'),
					key: 6,
				},
			].filter(
				(menu) => isSubFeatureIncluded(menu.text) && menu.show !== false
			),
		},
		{
			img: v2SettingStandard,
			imgActive: v2SettingWhite,
			key: 8,
			name: 'settings',
			text: getSportTranslation('settings'),
			menus: [
				{
					img: v2AccountSubscriptionStandard,
					imgActive: v2AccountSubscriptionWhite,
					name: 'subscription',
					text: getSportTranslation('subscription'),
					key: 1,
				},
				{
					img: v2GuestStandard,
					imgActive: v2GuestWhite,
					name: 'account',
					text: getSportTranslation('account'),
					key: 2,
				},
				{
					img: v2AccountVenueSpaceStandard,
					imgActive: v2AccountVenueSpaceWhite,
					name: 'space',
					text: getSportTranslation('space'),
					key: 3,
				},
				{
					img: v2TableStandard,
					imgActive: v2TableWhite,
					name: 'hygiene-standard',
					text: getSportTranslation('hygiene-standard'),
					key: 4,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2LoyaltyStandard,
			imgActive: v2LoyaltyWhite,
			key: 9,
			name: 'loyalty',
			text: getSportTranslation('loyalty'),
			menus: [
				{
					img: v2LoyaltyProgramStandard,
					imgActive: v2LoyaltyProgramWhite,
					name: 'programs',
					text: getSportTranslation('programs'),
					key: 1,
				},
				{
					img: v2GuestsEnrolledStandard,
					imgActive: v2GuestsEnrolledWhite,
					name: 'guests-enrolled',
					text: getSportTranslation('guests-enrolled'),
					key: 2,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
		{
			img: v2OrderAndPayStandard,
			imgActive: v2OrderAndPayWhite,
			key: 10,
			name: 'order-and-pay',
			text: getSportTranslation('order-and-pay'),
			menus: [
				{
					img: v2DeliveryPickupStandard,
					imgActive: v2DeliveryPickupWhite,
					name: 'order-and-pay-orders',
					text: getSportTranslation('order-and-pay-orders'),
					key: 1,
				},
				{
					img: v2OrderPaymentLinkStandard,
					imgActive: v2OrderPaymentLinkWhite,
					name: 'payment-links',
					text: getSportTranslation('payment-links'),
					key: 2,
				},
			].filter((menu) => isSubFeatureIncluded(menu.text)),
		},
	]


	// Filtered GymSidebar to include only the items listed in featureList
	return GymSidebarData.filter((item) => isFeatureIncluded(item.text))
}
