import {
    Box,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { t } from 'i18next'
import Cookies from 'universal-cookie'
import creditIcon from '../../assets/svgs/credit-card.svg'
import InformationIcon from '../../assets/svgs/info-icon.svg'
import { boxStyle } from '../../constants/types'
import {
    isAccommodation,
    isEntertainmentVenue,
    isFB,
    isRetailVenue,
} from '../../helpers/venueHelpers'
import { createUUID } from '../../utils/common'
import { KEYS } from '../../utils/storage'

const getTranslation = (text) =>
	t(`components.subscriptions.featuresModal.${text}`)

export default function FeaturesModal({
	showModal,
	onClose,
	features,
	planName,
}) {
	const [visible, setVisible] = useState(false)

	const [featuresList, setFeaturesList] = useState([])
	const [page, setPage] = useState(0)

	const [rowsPerPage, setRowsPerPage] = useState(10)

	// const cookies = new Cookies()
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		setVisible(showModal)
		// check from list if name of feature is Analytics & Reporting or Dashboard & Revenue, omit it
		features = features?.filter(
			(item) =>
				item.name !== 'Analytics & Reporting' &&
				item.name !== 'Dashboard & Revenue'
		)
		setFeaturesList(features)
	}, [showModal])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 800 }} className="outline-none">
					<div className={'overflow-auto max-h-[75vh]'}>
						<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
							<div className="p-2 bg-gray7 rounded-full">
								<div className="bg-black1 p-2 rounded-full">
									<img src={creditIcon} alt="icon" />
								</div>
							</div>
							<label className="text-20 font-semibold text-black1">
								<b>{planName}</b> - {getTranslation('planFeatures')}
							</label>
						</div>
						<div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
							{planName === 'Discover' && (
								<span>{getTranslation('discoverPlanDescription')}</span>
							)}
							{planName === 'Launch' && (
								<span>{getTranslation('launchPlanDescription')}</span>
							)}
							{planName === 'Elevate' && (
								<span>{getTranslation('elevatePlanDescription')}</span>
							)}
							{planName === 'Optimize' && (
								<span>{getTranslation('optimizePlanDescription')}</span>
							)}
							{planName === 'Enterprise' && (
								<span>{getTranslation('enterprisePlanDescription')}</span>
							)}
						</div>

						{planName === 'Discover' && (
							<div className="flex flex-col pt-4 px-024">
								{featuresList?.length && (
									<>
										<div className="flex flex-col overflow-auto max-h-[70vh] border">
											<TableContainer component={Paper}>
												<Table sx={{ minWidth: 650 }} aria-label="simple table">
													<TableHead className="bg-gray7">
														<TableRow key={createUUID()}>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																Feature
															</TableCell>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																Credits
															</TableCell>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																Used
															</TableCell>
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																Remaining
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{featuresList
															.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															.map((row, index) => (
																<TableRow key={index}>
																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base"
																	>
																		{row.name}
																	</TableCell>

																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base"
																	>
																		{row.usage_credit}
																	</TableCell>

																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base"
																	>
																		{row.credit_used}
																	</TableCell>

																	<TableCell
																		align="left"
																		className="!py-3.5 !text-gray5 !text-left !text-base"
																	>
																		{row.usage_credit - row.credit_used}
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</>
								)}
							</div>
						)}

						<div className="flex flex-col pt-4 px-024">
							{planName === 'Discover' && (
								<div className="w-full border border-grey-300 rounded-2xl p-0 flex">
									<div className="mt-6 px-6 w-full">
										<p className="text-16 mb-6 font-bold">
											{getTranslation('discoverIncludes')}
										</p>
										{isFB(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'manageStreamlineBookingProcess'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('streamlinedReservationsLimit')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('organizeVenueMenu')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('menuManagementLimit')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('realTimeUpdates')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('detailedAnalytics')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicAnalyticsReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageGuestProfiles')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicGuestManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('promotionsEmailsCampaigns')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicMarketingStrategy')}
													</p>
												</div>
											</>
										)}

										{isEntertainmentVenue(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageBookingProcess')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('bookingsManagementLimit')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('organizeUpdateVenueItems')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('itemsManagementLimit')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('realTimeStockUpdates')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('inventoryManagementLimit')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'detailedPerformanceInsights'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('analyticsReportingBasic')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageCustomerProfiles')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customerManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('promotionsEmailsCampaigns')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('marketingTools')}
													</p>
												</div>
											</>
										)}
									</div>
								</div>
							)}

							{planName === 'Launch' && (
								<div className="w-full border border-grey-300 rounded-2xl p-0 flex">
									<div className="mt-6 px-6 w-full">
										<p className="text-16 mb-6 font-bold">Launch includes:</p>
										{isFB(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageBookingTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedStreamlinedReservations')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('organizeMenuTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullMenuManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('trackStockTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageTeamTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageGuestProfilesTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('guestManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('insightsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicAnalyticsReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('promotionsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingStrategyTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('loyaltyProgramTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('loyaltyRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('easyPaymentsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('paymentLinks')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('collectFeedbackTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicGuestSurveysAndRatings')}
													</p>
												</div>
											</>
										)}

										{isEntertainmentVenue(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentManageBookingTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedBookingsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentTrackStockTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentOrganizeItemsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullItemsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentBusinessInsightsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('analyticsAndReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentManageCustomerProfilesTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customerManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentManageTeamTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentPromotionsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentCustomerLoyaltyTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicLoyaltyRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'entertainmentEasyPaymentsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicPaymentLinks')}
													</p>
												</div>
											</>
										)}
										{isAccommodation(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationBusinessInsightsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('analyticsAndReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationManageBookingTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedBookingsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationManageUnitsRoomsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedUnitsRoomsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationTrackStockTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationOrganizeItemsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullItemsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationManageGuestProfilesTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('guestManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationManageTeamTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationPromotionsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationCustomerLoyaltyTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicLoyaltyRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationEasyPaymentsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicPaymentLinks')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'accommodationCollectFeedbackTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicGuestSurveysAndRatings')}
													</p>
												</div>
											</>
										)}
										{isRetailVenue(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'retailBusinessInsightsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('dashboardAndRevenueAnalytics')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'retailManageOperationsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicStoreManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailManageOrdersTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedOrderManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailTrackStockTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'retailOrganizeStoreItemsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullProductsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailManageTeamTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailPromotionsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'retailCustomerLoyaltyTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicLoyaltyRetentionProgram')}
													</p>
												</div>
											</>
										)}

										<hr
											className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
											style={{ borderColor: '#827c5d' }}
										/>
										<p className="text-16 mb-2 font-bold">
											{getTranslation('customizationLabel')}:
										</p>

										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('customizePlatformTooltip')}
											>
												<img
													src={InformationIcon}
													className="cursor-pointer w-5 h-5 mt-0"
												/>
											</Tooltip>
											<p className="text-16 mb-6">
												{isFB(venueType) && getTranslation('fbUIChangesFee')}
												{isAccommodation(venueType) &&
													getTranslation('accommodationUIChangesFee')}
												{isEntertainmentVenue(venueType) &&
													getTranslation('entertainmentUIChangesFee')}
												{isRetailVenue(venueType) &&
													getTranslation('retailUIChangesFee')}
											</p>
										</div>

										<p className="text-16 mb-2 font-bold">
											{getTranslation('communicationTemplatesLabel')}:
										</p>

										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('personalizeTemplatesTooltip')}
											>
												<img
													src={InformationIcon}
													className="cursor-pointer w-5 h-5"
												/>
											</Tooltip>
											<p
												className="text-16 mb-6"
												style={{ textWrap: 'balance' }}
											>
												{isFB(venueType) && getTranslation('fbTemplateFee')}
												{isAccommodation(venueType) &&
													getTranslation('accommodationTemplateFee')}
												{isEntertainmentVenue(venueType) &&
													getTranslation('entertainmentTemplateFee')}
												{isRetailVenue(venueType) &&
													getTranslation('retailTemplateFee')}
											</p>
										</div>

										<p className="text-16 mb-2 font-bold">
											{getTranslation('supportLabel')}:
										</p>

										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('supportTooltip')}
											>
												<img
													src={InformationIcon}
													className="cursor-pointer w-5 h-5 mt-0"
												/>
											</Tooltip>
											<p
												className="text-16 mb-6"
												style={{ textWrap: 'balance' }}
											>
												{getTranslation('emailSupportResponse')}
											</p>
										</div>
									</div>
								</div>
							)}
							{planName === 'Elevate' && (
								<div className="w-full border border-grey-300 rounded-2xl p-0 flex">
									<div className="mt-6 px-6 w-[100%]">
										<p className="text-16 mb-6 font-bold">Elevate includes:</p>
										{isFB(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbManageBookingTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedStreamlinedReservations')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbOrganizeMenuTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullMenuManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbTrackStockTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbManageTeamTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'fbManageGuestProfilesTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('guestManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbPromotionsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingStrategyTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbCustomerLoyaltyTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('loyaltyAndRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbEasyPaymentsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('paymentLinks')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('fbFeedbackTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('guestSurveysAndRatings')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('fbAutomateMarketingTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('marketingAutomation')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'fbAffiliatePartnershipsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('affiliatePartnerships')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('fbAdvancedAnalyticsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedAnalyticsAndReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'fbDeliveryOrdersManagementTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('deliveryOrdersManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'fbAdvancedGuestBehaviorAnalyticsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedGuestBehaviorAnalytics')}
													</p>
												</div>
											</>
										)}

										{isRetailVenue(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailManageStoreTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicStoreManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailManageOrdersTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedOrderManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailTrackStockTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'retailOrganizeStoreItemsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullProductsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailManageTeamTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('retailPromotionsTooltip')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'retailCustomerLoyaltyTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicLoyaltyAndRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'retailAutomateMarketingTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('marketingAutomation')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'retailAffiliatePartnershipsTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicAffiliatePartnerships')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'retailAdvancedDashboardTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'advancedDashboardAndRevenueAnalytics'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'retailCustomerLoyaltyProgramTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('retailCustomerLoyaltyProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'retailCustomerSurveysTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customerSurveysAndRatings')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'retailInventorySynchronizationTooltip'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('consistentInventory')}
													</p>
												</div>
											</>
										)}
									</div>
								</div>
							)}
							{planName === 'Optimize' && (
								<div className="w-full border border-grey-300 rounded-2xl p-0 flex">
									<div className="mt-6 px-6 w-[100%]">
										<p className="text-16 mb-6 font-bold">
											Optimize includes:{' '}
										</p>
										{isFB(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Manage and streamline your booking process efficiently. Limit: 25/month for Discover; Unlimited for other plans.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Unlimited Streamlined Reservations
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															"Efficiently organize and update your venue's menu."
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Full Menu Management
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Keep track of your stock levels with real-time updates. Low stock alerts included.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Full Inventory Management
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Efficiently manage your team with integrated staff scheduling and time tracking. Available from Launch plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Staff Management
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Manage guest profiles and preferences effectively.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Guest Management
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Access tools for promotions, emails, and campaigns to boost your marketing efforts. Basic tools in Launch; Advanced from Elevate.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Enhanced Marketing Strategy tools
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Build customer loyalty with customizable retention programs. Available from Launch plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Loyalty and Retention Program
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Facilitate easy payments with secure, shareable payment links. Available from Launch plan'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Payment Links
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={
															'Collect valuable feedback directly from your customers. Available from Launch plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Guest Surveys and Ratings
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Automate your marketing activities for efficiency and consistency. Available from Elevate plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Marketing Automation
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Expand your reach through strategic affiliate partnerships. Available from Elevate plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Affiliate Partnerships
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Gain insights into your business performance with detailed analytics. Advanced analytics available from Elevate plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Advanced Analytics & Reporting
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Streamline your delivery process with comprehensive order management tools. Available from Elevate plan.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Delivery Orders management
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Unlock deeper insights into customer preferences and behaviors.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Advanced Guest Behavior Analytics
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Offer premium dining experiences with table bidding options. Exclusive to Optimize and Enterprise plans.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Premium Tables with Pricing / Bidding and Floorplan
														Visibility
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Enhance customer retention with a tailored dining loyalty program.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>

													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Dining Guest Loyalty Program
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															"Personalize your VenueBoost interface with your brand's unique identity."
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														Customizable Brand Profile
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={
															'Accept payments securely in-person at your venue place.'
														}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														In-Person Payments
													</p>
												</div>
											</>
										)}

										{isEntertainmentVenue(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageBookingProcess')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedBookingsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('trackStockLevels')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('organizeAndUpdateItems')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullItemsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageCustomerProfiles')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customerManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageTeam')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('buildLoyaltyPrograms')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicLoyaltyAndRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('facilitateEasyPayments')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('paymentLinks')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('automateMarketing')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('marketingAutomation')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('gainBusinessInsights')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedAnalyticsAndReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('elevateCustomerExperience')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('entertainmentMembershipProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('gatherValuableInsights')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'advancedCustomerSurveysAndRatings'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('unlockDeepInsights')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'advancedCustomerBehaviorAnalytics'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('elevateMarketingEfforts')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'personalizeVenueBoostInterface'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customizableBrandProfile')}
													</p>
												</div>
												<div class="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('acceptPaymentsInPerson')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('inPersonPayments')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('expandBusinessReach')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedAffiliatePartnerships')}
													</p>
												</div>
											</>
										)}

										{isAccommodation(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'manageBookingProcessAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'unlimitedBookingsManagementAccommodation'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageUnitsRooms')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('unlimitedUnitsRoomsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'trackStockLevelsAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('organizeAndUpdateItems')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullItemsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'manageGuestProfilesAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('guestManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageTeamAccommodation')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'toolsForMarketingAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'buildLoyaltyProgramsAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('loyaltyAndRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('facilitateEasyPayments')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('paymentLinks')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('collectValuableFeedback')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('guestSurveysAndRatings')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'automateMarketingAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('marketingAutomation')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'gainBusinessInsightsAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedAnalyticsAndReporting')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('iCalIntegration')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('iCalIntegration')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'deeperInsightsAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedGuestBehaviorAnalytics')}
													</p>
												</div>
												<div class="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('enhanceGuestRetention')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('accommodationGuestLoyaltyProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('streamlineHousekeeping')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-5"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('housekeepingManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'expandBusinessReachAccommodation'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedAffiliatePartnerships')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('personalizeVenueBoost')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customizableBrandProfile')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('acceptPaymentsInPerson')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('inPersonPayments')}
													</p>
												</div>
											</>
										)}

										{isRetailVenue(venueType) && (
											<>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('trackStockLevelsRetail')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullInventoryManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation(
															'organizeAndUpdateStoreItems'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('fullProductsManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('manageTeam')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('staffManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('toolsForMarketing')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('enhancedMarketingTools')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="top"
														title={getTranslation('buildLoyaltyPrograms')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicLoyaltyAndRetentionProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('automateMarketing')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('marketingAutomation')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'strategicAffiliatePartnerships'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('basicAffiliatePartnerships')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'advancedDashboardRevenueAnalytics'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'advancedDashboardAndRevenueAnalytics'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('buildCustomerRelationships')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('retailCustomerLoyaltyProgram')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('gatherValuableInsights')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customerSurveysAndRatings')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('synchronizeInventory')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('consistentInventory')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('optimizeOrderProcessing')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedOrderManagement')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('harnessDataPower')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'centralizedAnalyticsForMultiBrandRetailers'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'personalizeVenueBoostInterface'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('customizableBrandProfile')}
													</p>
												</div>
												<div class="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('acceptPaymentsInPerson')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('inPersonPayments')}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation(
															'advancedCustomerBehaviorAnalytics'
														)}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation(
															'advancedCustomerBehaviorAnalytics2'
														)}
													</p>
												</div>
												<div className="flex flex-row-reverse justify-between gap-2">
													<Tooltip
														arrow
														placement="bottom"
														title={getTranslation('optimizeStoreOperations')}
													>
														<img
															src={InformationIcon}
															className="cursor-pointer w-5 h-5 mt-0"
														/>
													</Tooltip>
													<p
														className="text-16 mb-6"
														style={{ textWrap: 'balance' }}
													>
														{getTranslation('advancedStoreManagement')}
													</p>
												</div>
											</>
										)}

										<hr
											className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
											style={{ borderColor: '#827c5d' }}
										/>
										<p className="text-16 mb-2 font-bold">
											{getTranslation('customization')}
										</p>

										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('enhancePlatform')}
											>
												<img
													src={InformationIcon}
													className="cursor-pointer w-5 h-5 mt-0"
												/>
											</Tooltip>
											<p className="text-16 mb-6">
												{isFB(venueType) &&
													getTranslation('majorFeatureIntegrationFB')}
												{isAccommodation(venueType) &&
													getTranslation(
														'majorFeatureIntegrationAccommodation'
													)}
												{isEntertainmentVenue(venueType) &&
													getTranslation(
														'majorFeatureIntegrationEntertainment'
													)}
												{isRetailVenue(venueType) &&
													getTranslation('majorFeatureIntegrationRetail')}
											</p>
										</div>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('discountTooltip')}
											>
												<img src={InformationIcon} className="w-5 h-5 mt-0" />
											</Tooltip>
											<p className="text-16 mb-6">
												{getTranslation('discountOptimization')}
											</p>
										</div>

										<p className="text-16 mb-2 font-bold">
											{getTranslation('support')}
										</p>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('directPhoneSupport')}
											>
												<img
													src={InformationIcon}
													className="cursor-pointer w-5 h-5 mt-0"
												/>
											</Tooltip>
											<p
												className="text-16 mb-6"
												style={{ textWrap: 'balance' }}
											>
												{getTranslation('phoneAndEmailSupport')}
											</p>
										</div>
									</div>
								</div>
							)}
							{planName === 'Enterprise' && (
								<div className="w-full border border-grey-300 rounded-2xl p-0 flex">
									<div className="mt-6 px-6 w-[100%]">
										<p className="text-16 mb-6 font-bold">
											{getTranslation('enterpriseIncludes')}
										</p>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('personalizedService')}
											>
												<img
													src={InformationIcon}
													className="cursor-pointer w-5 h-5 mt-4"
												/>
											</Tooltip>
											<p className="text-16 mb-6">
												{getTranslation('dedicatedAccountManager')}
											</p>
										</div>
										<div className="flex flex-row-reverse justify-between gap-2">
											<img src={InformationIcon} className="w-5 h-5 mt-0" />
											<p className="text-16 mb-6">
												{getTranslation('customIntegrationsAndDevelopment')}
											</p>
										</div>
										<div className="flex flex-row-reverse justify-between gap-2">
											<img src={InformationIcon} className="w-5 h-5 mt-0" />
											<p className="text-16 mb-6">
												{getTranslation('premiumSupportAndTraining')}
											</p>
										</div>

										<hr
											className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
											style={{ borderColor: '#827c5d' }}
										/>
										<p className="text-16 mb-2 font-bold">
											{getTranslation('price')}
										</p>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('priceTooltip')}
											>
												<img src={InformationIcon} className="w-5 h-5 mt-0" />
											</Tooltip>
											<p
												className="text-16 mb-6"
												style={{ textWrap: 'balance' }}
											>
												{isAccommodation(venueType) &&
													getTranslation('customPricingAccommodation')}
												{isRetailVenue(venueType) &&
													getTranslation('customPricingRetail')}
												{isEntertainmentVenue(venueType) &&
													getTranslation('customPricingEntertainment')}
												{isFB(venueType) && getTranslation('customPricingFB')}
											</p>
										</div>

										<p className="text-16 mb-2 font-bold">
											{getTranslation('customization')}
										</p>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('customizationTooltip')}
											>
												<img src={InformationIcon} className="w-5 h-5 mt-0" />
											</Tooltip>
											<p className="text-16 mb-6">
												{getTranslation('customFeatureDevelopment')}
											</p>
										</div>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip
												arrow
												placement="top"
												title={getTranslation('discountTooltip')}
											>
												<img src={InformationIcon} className="w-5 h-5 mt-0" />
											</Tooltip>
											<p className="text-16 mb-6">
												{getTranslation('discountEnterprise')}
											</p>
										</div>
										<p className="text-16 mb-2 font-bold">
											{getTranslation('support')}
										</p>
										<div className="flex flex-row-reverse justify-between gap-2">
											<Tooltip arrow placement="top" title={''}>
												<img src={InformationIcon} className="w-5 h-5 mt-0" />
											</Tooltip>
											<p
												className="text-16 mb-6"
												style={{ textWrap: 'balance' }}
											>
												{getTranslation('prioritySupport')}
											</p>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1 w-20"
							onClick={onClose}
						>
							{getTranslation('close')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
