import { Card, CardContent, IconButton } from '@mui/material'
import { t } from 'i18next'
import moment from 'moment'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import editIcon from '../../../assets/svgs/edit.svg'
import plusIcon from '../../../assets/svgs/plus.svg'
import trashIcon from '../../../assets/svgs/trash.svg'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import SupplierModal from '../../../components/store/supplierModal'
import retailService from '../../../services/retailService'

export const storeSuppliersText = (text) =>
	t(`account.storeSettings.storeSuppliers.${text}`)

const StoreSuppliers = ({ data, onRefresh }) => {
	const [isModalVisible, setModalVisible] = useState(false)
	const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleEdit = (row) => {
		setSelectedRow(row)
		setModalVisible(true)
	}

	const handleDelete = (id) => {
		setSelectedRow(data.find((row) => row.id === id))
		setDeleteModalVisible(true)
	}

	const columns = [
		{ field: 'name', label: storeSuppliersText('name'), align: 'left' },
		{
			field: 'created_at',
			label: storeSuppliersText('registered_on'),
			align: 'left',
			cellComponent: ({ data }) =>
				data ? moment(data).format('MMMM Do YYYY, h:mm:ss a') : '',
		},
		{
			field: 'actions',
			label: storeSuppliersText('actions'),
			align: 'right',
			cellComponent: ({ data, row }) => (
				<>
					<IconButton
						color="secondary"
						className="h-12"
						onClick={() => handleDelete(row.id)}
					>
						<img src={trashIcon} alt="trash" />
					</IconButton>
					<IconButton color="secondary" onClick={() => handleEdit(row)}>
						<img src={editIcon} alt="edit" />
					</IconButton>
				</>
			),
		},
	]

	const handlePageChange = (_, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<div className="w-full">
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
						<div className="">
							<span className="text-black1 text-xl font-medium">
								{storeSuppliersText('suppliers_title')}
							</span>
							<p className="text-black1 text-16 font-medium">
								{storeSuppliersText('suppliers_description')}
							</p>
						</div>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
							onClick={() => {
								setModalVisible(true)
								setSelectedRow(null)
							}}
						>
							<img src={plusIcon} alt="plus" className="mr-2" />
							<span>{storeSuppliersText('add_supplier_button')}</span>
						</div>
					</div>
					<GenericTable
						data={data.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)}
						columns={columns}
						onEdit={handleEdit}
						onDelete={handleDelete}
					/>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
							{storeSuppliersText('no_suppliers_found')}
						</div>
					)}
				</CardContent>
				<GenericPagination
					count={data.length}
					page={page}
					rowsPerPage={rowsPerPage}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
					setPage={setPage}
				/>
				<SupplierModal
					data={selectedRow}
					showModal={isModalVisible}
					onClose={(refresh) => {
						setModalVisible(false)
						setSelectedRow(null)
						if (refresh) {
							onRefresh()
						}
					}}
				/>
				<AreYouSureModal
					title={storeSuppliersText('delete_supplier_title')}
					content={storeSuppliersText('delete_supplier_content')}
					firstButton={storeSuppliersText('no_cancel')}
					secondButton={storeSuppliersText('yes_i_am')}
					showModal={isDeleteModalVisible}
					onClose={() => setDeleteModalVisible(false)}
					onSubmit={() => {
						retailService.deleteSupplier(selectedRow.id).then(() => {
							NotificationManager.success('Successfully deleted')
							setSelectedRow(null)
							onRefresh()
						})
					}}
				/>
			</Card>
		</div>
	)
}

export default StoreSuppliers
