import { RemoveRedEyeOutlined } from '@mui/icons-material'

const status = (data = '', orderTransformer) => (
	<div
		className="status-table w-1/2 font-bold"
		style={{
			backgroundColor: `${orderTransformer[data]?.bgColor}`,
		}}
	>
		<span
			style={{
				color: orderTransformer[data]?.textColor,
			}}
			className="text-[12px]"
		>
			{data}
		</span>
	</div>
)

export const columnsRenderTable = (orderTransformer, render = true) => [
	{ field: 'order_number', label: 'Order', align: 'center' },
	{
		field: 'created_at',
		label: 'Date',
		align: 'center',
		cellComponent: ({ data }) => (
			<div className="flex flex-row justify-start items-center gap-2">
				<RemoveRedEyeOutlined fontSize={'small'} />
				{data}
			</div>
		),
	},
	{ field: 'order_for', label: 'Order for', align: 'center' },
	{
		field: 'status',
		label: 'Status',
		align: 'center',
		cellComponent: ({ data }) => status(data, orderTransformer),
	},
	{
		field: 'total_amount',
		label: 'Total',
		align: 'center',
		cellComponent: ({ data, row }) => `${row?.currency ?? '-'} ${data}`,
	},
	...(render
		? [
				{
					field: 'payment_method',
					label: 'Pay with',
					align: 'end',
					cellComponent: ({ data }) =>
						data.stripe_payment_id ? 'Card' : 'Cash',
				},
		  ]
		: []),
	{
		field: 'actions',
		label: 'Actions',
		align: 'end',
	},
]

export const transformedDataRenderTable = (displayedData = []) =>
	displayedData.map((item) => ({
		...item,
		payment_method: item.stripe_payment_id ? 'Card' : 'Cash',
	}))
