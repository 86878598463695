import axios from "axios";
import { API_BOOST_CORE_URL, APP_KEY } from "../constants/config";
import {
  KEYS,
  getAppToken,
  setAppToken,
  clearAppToken,
  setVenueShortcode,
  setVenueBasicInfo,
  setCookieVenueType, setLoyaltyProgramId, removeStorageKey
} from "../utils/storage";
import { API_TOKEN_REFRESH } from "../constants/API";
import { isEmpty } from "./common";
import Cookies from "universal-cookie";
import {navigate} from "use-history";

export default async (api, data = {}, venue_short_code = '', filter = {}) => {
  const headers = { "Content-Type": api.content_type };
  const cookies = new Cookies();

  const callAPI = async () => {
    // get api authentication token from cookie
    // let venue_short_code;
    try {
      const token = getAppToken();
    //   venue_short_code = cookies.get(KEYS.VENUE_SHORT_CODE)
      headers.Authorization = `Bearer ${token}`;
    } catch (e) {
      // cannot find token from cookie
    }

    let queryStringParams = undefined;
    if (filter) {
      queryStringParams = Object.keys(filter)
          .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(filter[key]))
          .join("&");
    }

    return axios({
      url: `${API_BOOST_CORE_URL}${api.url}?SN-BOOST-CORE-ADMIN-API-KEY=${APP_KEY}&venue_short_code=${venue_short_code}`  + (queryStringParams ? ('&' + queryStringParams) : '') + (!isEmpty(api.query) ? ('&' + api.query) : ''),
      method: api.method,
      data: data,
      headers: headers
    });
  };

  const refreshToken = async () => {
    let refreshHeader = { "Content-Type": api.content_type };
    let token = getAppToken();

    refreshHeader.Authorization = `Bearer ${token}`;
    let config = {
      url: `${API_BOOST_CORE_URL}${API_TOKEN_REFRESH}?SN-BOOST-CORE-ADMIN-API-KEY=${APP_KEY}`,
      method: "POST",
      headers: refreshHeader,
    };

    try {

      let res = await axios(config);
      let newToken = res.data.access_token;

      // set new token
      setAppToken(newToken);
    } catch (e) {
      // invalid user or token, sign out ?
      console.error("REFRESH TOKEN ERROR ", e);
    }
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await callAPI();
      resolve(response.data);
    } catch (error) {
      // if token is invalid
      if (error?.response?.data?.message === "Invalid token") {
        setAppToken(null);
        setVenueShortcode(null);
        setVenueBasicInfo(null);
        setCookieVenueType(null);
        setLoyaltyProgramId(null);
        clearAppToken();
        removeStorageKey(KEYS.IS_TRIAL_MODE);
        removeStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL);
        removeStorageKey(KEYS.PLAN_FEATURES);
        removeStorageKey(KEYS.SUBSCRIPTION_PLAN)

        window.location.reload();
      }
      // if token is expired
      else if (error?.response?.data?.message === "Expired token") {
        await refreshToken();
        const response = await callAPI();
        resolve(response.data);
      }
      else {
        reject(error?.response?.data);
      }
    }
  });
};
