import React, { useState } from 'react'
import SelectFilter from '../../../components/dropdowns/select-filter'
import { plusIcon, searchIcon } from './icons'
import './index.css'

const GuestFilters = ({
	guestsViewText,
	setOpen,
	selectedTier,
	setSelectedTier,
	setSearch,
	search,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const selectTier = (tier) => {
		setSelectedTier(tier)
		setDropdownOpen(false)
	}

	const tiers = [
		'Bronze Tier',
		'Platinum Tier',
		'Gold Tier',
		'Silver Tier',
		'All Tiers',
	].sort()

	return (
		<div className="filter-container grid grid-cols-3 items-center gap-4">
			<div className="col-span-2 grid grid-cols-5 gap-2 w-full">
				<div
					style={{ height: '40px' }}
					className="col-span-3 input-search relative w-full"
				>
					<span className="search-icon">{searchIcon}</span>
					<input
						type="search"
						placeholder={guestsViewText('placeholder')}
						className="w-full text-[15px]"
						onChange={(e) => setSearch(e.target.value)}
						value={search}
					/>
				</div>
				<SelectFilter
					onChange={selectTier}
					selectedItem={selectedTier}
					toggleDropdown={toggleDropdown}
					dropdownOpen={dropdownOpen}
					items={tiers}
				/>
			</div>
			<div className="w-full flex justify-end">
				<div className="flex items-center gap-2">
					<button className="add-btn">
						<div
							className="flex items-center gap-1"
							onClick={() => setOpen(true)}
						>
							<span>{plusIcon}</span>
							<span className="text-white">{guestsViewText('add_guest')}</span>
						</div>
					</button>
					{/*<span>{exportIcon}</span>*/}
				</div>
			</div>
		</div>
	)
}

export default GuestFilters
