import React from 'react';
import ImgPlaceholder from '../../assets/images/img_placeholder.png';
import { isEmpty } from '../../utils/common'

const MenuProduct = ({ data, onClick, classes = '', currency = null }) => {
	return (
		<div
			className={`flex flex-col border rounded-lg mt-2 p-4 cursor-pointer justify-center items-center mx-3 ${classes}`}
			onClick={onClick}
		>
			<img
				className="w-full h-[122px] rounded-lg"
				style={{ objectFit: 'cover' }}
				src={isEmpty(data?.image_path) ? ImgPlaceholder : data?.image_path}
				alt={data.title}
			/>
			<div className="flex flex-col pt-1 text-center">
				<div className="text-base font-bold text-gray5">{data?.title}</div>
				<div className=" mt-1 text-base font-bold text-black1">
					{currency} &nbsp;{parseInt(data?.price)}
				</div>
			</div>
		</div>
	)
}

export default MenuProduct;