import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from '@mui/material';
import { useFormik } from "formik";
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import Checkicon from "../../../../assets/svgs/check_green.svg";
import InfoIcon from '../../../../assets/svgs/info_black.svg';
import LoadingScreen from '../../../../components/custom/LoadingScreen';
import { RInputDeclare } from '../../../../components/custom/RInputDeclare';
import { isEmpty } from '../../../../utils/common';

import { useSelector } from 'react-redux';
import accommodationService from '../../../../services/accommodationService';

import { CloseOutlined } from '@mui/icons-material';
import { t } from 'i18next';
import moment from 'moment';
import CancellationModal from "../../../../components/accommodation/cancellationModal";
import PrepaymentModal from "../../../../components/accommodation/prepaymentModal";
import AreYouSureModal from '../../../../components/common/areYouSureModal';
import CustomTimePicker from "../../../../components/custom/CustomTimePicker/CustomTimePicker";
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil';

export const policiesAndRulesFormText = (text) =>
	t(`accommodation.rental_unit.show.policiesAndRulesForm.${text}`)

const PoliciesAndRulesForm = ({ data, rentalUnitId, currency }) => {
	const navigate = useNavigate()
	const [editModal, setEditModal] = useState(false)
	const [openPrepaymentModal, setOpenPrepaymentModal] = useState(false)

	const [feesAndCharges, setFeesAndCharges] = useState([])
	const [customRules, setCustomRules] = useState([])
	const [updatingRules, setUpdatingRules] = useState(false)
	const [updatingRulesExtra, setUpdatingRulesExtra] = useState(false)
	const [
		updatingAccommodationAdditionalCharge,
		setUpdatingAccommodationAdditionalCharge,
	] = useState(false)
	const [
		updatingAccommodationAdditionalCustomRule,
		setUpdatingAccommodationAdditionalCustomRule,
	] = useState(false)
	const [
		updatingFirstAccommodationAdditionalCharge,
		setUpdatingFirstAccommodationAdditionalCharge,
	] = useState(false)
	const { short_code } = useSelector((state) => state.app)

	const timeStringToDateTime = (timeString) => {
		const currentDate = new Date()
		const [hours, minutes, seconds] = timeString.split(':')
		return new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate(),
			parseInt(hours),
			parseInt(minutes),
			parseInt(seconds)
		)
	}

	const [checkinFrom, setCheckinFrom] = useState(
		data?.accommodation_rules
			? timeStringToDateTime(data?.accommodation_rules?.check_in_from)
			: new Date()
	)
	const [checkinUntil, setCheckinUntil] = useState(
		data?.accommodation_rules
			? timeStringToDateTime(data?.accommodation_rules?.check_in_until)
			: new Date()
	)
	const [checkoutFrom, setCheckoutFrom] = useState(
		data?.accommodation_rules
			? timeStringToDateTime(data?.accommodation_rules?.checkout_from)
			: new Date()
	)
	const [checkoutUntil, setCheckoutUntil] = useState(
		data?.accommodation_rules
			? timeStringToDateTime(data?.accommodation_rules?.checkout_until)
			: new Date()
	)

	const [keyPickup, setKeyPickup] = useState(
		data?.accommodation_rules ? data?.accommodation_rules?.key_pick_up : null
	)

	const [wifiDetail, setWifiDetail] = useState(
		data?.accommodation_rules ? data?.accommodation_rules?.wifi_detail : null
	)

	const [checkinMethod, setCheckInMethod] = useState(
		data?.accommodation_rules
			? data?.accommodation_rules?.check_in_method
			: null
	)

	const [checkoutMethod, setCheckoutMethod] = useState(
		data?.accommodation_rules
			? data?.accommodation_rules?.check_out_method
			: null
	)

	const [phoneRequired, setPhoneRequired] = useState(
		data?.accommodation_rules ? data?.accommodation_rules?.guest_phone : null
	)

	const [idCardRequired, setIdCardRequired] = useState(
		data?.accommodation_rules
			? data?.accommodation_rules?.guest_identification
			: null
	)

	const handleInputChange = (id, value) => {
		// Create a copy of the feesAndCharges object
		const updatedFeesAndCharges = { ...feesAndCharges }

		// Update the amount property for the specific ID
		updatedFeesAndCharges[id].amount = value

		// Update the state with the modified object
		setFeesAndCharges(updatedFeesAndCharges)
	}

	useEffect(() => {
		if (data?.accommodation_rules) {
			accommodationRulesFormikProps.setFieldValue(
				'smoking_allowed',
				data?.accommodation_rules?.smoking_allowed === 1 ? true : false
			)
			accommodationRulesFormikProps.setFieldValue(
				'pets_allowed',
				data?.accommodation_rules?.pets_allowed === 1 ? true : false
			)
			accommodationRulesFormikProps.setFieldValue(
				'parties_allowed',
				data?.accommodation_rules?.parties_allowed === 1 ? true : false
			)
		}

		if (data?.additional_fees_and_charges) {
			const feesAndChargesArray = data?.additional_fees_and_charges.map(
				(item) => ({
					id: item.id,
					name: item.name,
					description: item.description,
					amount: item.amount, // You can add other properties here if needed
				})
			)
			setFeesAndCharges(feesAndChargesArray)
		}

		if (data?.custom_accommodations_rules) {
			const CustomAdditionalRulesArray = data?.custom_accommodations_rules.map(
				(item) => ({
					id: item.id,
					name: item.name,
				})
			)
			setCustomRules(CustomAdditionalRulesArray)
		}
	}, [data])

	const [loading, setLoading] = useState(false)
	const [showDeleteRule, setShowDeleteRule] = useState(false)
	const [selectedRule, setSelectedRule] = useState(null)

	const accommodationCustomChargeAndFeeFormikProps = useFormik({
		initialValues: {
			custom_name: null,
			custom_description: null,
			custom_amount: null,
		},
		validationSchema: yup.object().shape({
			custom_name: yup
				.string()
				.trim()
				.required('Custom fee naming is required')
				.max(250, 'Max exceeded')
				.min(1, 'Min not met'),
			custom_description: yup
				.string()
				.trim()
				.required('Custom fee explanation is required')
				.max(250, 'Max exceeded')
				.min(1, 'Min not met'),
			custom_amount: yup.number().required('Custom fee amount is required'),
		}),
		onSubmit: (values) => onSubmitAccommodationChargeAndFeeCreate(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const accommodationCustomRulesFormikProps = useFormik({
		initialValues: {
			name: null,
		},
		validationSchema: yup.object().shape({
			name: yup
				.string()
				.trim()
				.required('Custom rule naming is required')
				.max(250, 'Max exceeded')
				.min(1, 'Min not met'),
		}),
		onSubmit: (values) => onSubmitAccommodationCustomRuleCreate(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const accommodationRulesFormikProps = useFormik({
		initialValues: {
			smoking_allowed: false,
			pets_allowed: false,
			parties_allowed: false,
		},
		onSubmit: (values) => onSubmitAccommodationRules(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const onSubmitAccommodationCustomRuleCreate = (values) => {
		setUpdatingAccommodationAdditionalCustomRule(true)
		const details = {
			name: values.name,
		}
		accommodationService
			.CustomRuleCreate(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('custom rule create', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'rule',
				})
				setUpdatingAccommodationAdditionalCustomRule(false)
				NotificationManager.success(
					'Custom rule has been created successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('custom rule create', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'rule',
				})
				setUpdatingAccommodationAdditionalCustomRule(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on CustomRuleCreate')
			})
	}

	const onSubmitAccommodationChargeAndFeeCreate = (values) => {
		setUpdatingAccommodationAdditionalCharge(true)
		const details = {
			custom_name: values.custom_name,
			custom_description: values.custom_description,
			custom_amount: values.custom_amount,
		}
		accommodationService
			.AdditionalFeeAndChargeCreate(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('fee and charge', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'rule',
				})
				setUpdatingAccommodationAdditionalCharge(false)
				NotificationManager.success(
					'Custom fee has been created successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('fee and charge', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'rule',
				})
				setUpdatingAccommodationAdditionalCharge(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on AdditionalFeeAndChargeCreate')
			})
	}

	const onSubmitAccommodationChargeAndFeeUpdate = () => {
		setUpdatingFirstAccommodationAdditionalCharge(true)
		accommodationService
			.AdditionalFeeAndChargeUpdate(feesAndCharges, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('fee and charge', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'rule',
				})
				setUpdatingFirstAccommodationAdditionalCharge(false)
				NotificationManager.success(
					'Custom fees have been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('fee and charge', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'rule',
				})
				setUpdatingFirstAccommodationAdditionalCharge(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on AdditionalFeeAndChargeUpdate')
			})
	}

	const onSubmitAccommodationRules = (values) => {
		setUpdatingRules(true)
		const details = {
			type: 'accommodation_rules',
			smoking_allowed: values.smoking_allowed,
			pets_allowed: values.pets_allowed,
			parties_allowed: values.parties_allowed,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'Accomodation',
				})
				setUpdatingRules(false)
				NotificationManager.success(
					'Accommodation Rules has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'Accomodation',
				})
				setUpdatingRules(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitAccomodationSetup')
			})
	}

	const onSubmitAccommodationRulesExtra = () => {
		setUpdatingRulesExtra(true)
		const details = {
			type: 'accommodation_rules_extra',
			check_in_from: moment(checkinFrom).format('HH:mm'),
			check_in_until: moment(checkinUntil).format('HH:mm'),
			checkout_from: moment(checkoutFrom).format('HH:mm'),
			checkout_until: moment(checkoutUntil).format('HH:mm'),
			guest_phone: phoneRequired === true || phoneRequired === 1 ? '1' : '2',
			guest_identification:
				idCardRequired === true || idCardRequired === 1 ? '1' : '2',
			key_pick_up: keyPickup,
			check_in_method: checkinMethod,
			check_out_method: checkoutMethod,
			wifi_detail: wifiDetail,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'Accomodation',
				})
				setUpdatingRulesExtra(false)
				NotificationManager.success(
					'Accommodation Rules has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'Accomodation',
				})
				setUpdatingRulesExtra(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on Accommodation Rules')
			})
	}

	if (loading) {
		return <LoadingScreen />
	}

	return (
		<>
			<Card>
				<CardContent className="!p-0">
					<div className="w-full rounded-4xl p-6 pt-2 gap-10">
						<div className="relative started-form">
							<div className="flex flex-row gap-12">
								<div className="w-[60%]">
									<div className="p-[22px] relative rounded-[10px] border-[#E0E0E0] border-[1px]">
										<button
											onClick={() => setEditModal(true)}
											className="absolute right-5 top-3 text-14 text-[#707070] font-normal p-3 border-[#E0E0E0] border-[1px] rounded-md"
										>
											{policiesAndRulesFormText('edit')}
										</button>
										<p className="text-black1 text-20 font-semibold leading-[15px] py-2">
											{policiesAndRulesFormText('cancellationPolicy')}
										</p>

										<p className="text-[#027A48] text-16 font-normal py-2">
											{policiesAndRulesFormText('moreBookings')}{' '}
											{data?.cancellation_days ?? 30} -day{' '}
											{policiesAndRulesFormText('dayCancellationPolicy')}
										</p>
										<div className="flex flex-col gap-4 text-17 py-2">
											<div className="flex gap-4">
												<img src={Checkicon} />
												<p className="text-[#707070]">
													{policiesAndRulesFormText('freeCancellation')}
												</p>
											</div>
											<div className="flex gap-4">
												<img src={Checkicon} />
												<p className="text-[#707070]">
													{policiesAndRulesFormText('feeWaived')}
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="w-[40%] flex flex-col gap-12 mb-4">
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('navigatingCancellations')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText(
												'cancellationPolicyDescription'
											)}
										</p>
									</div>
								</div>
							</div>

							<div className="flex flex-row gap-12">
								<div className="w-[60%] ">
									<div className="p-[22px] relative rounded-[10px] border-[#E0E0E0] border-[1px]">
										<button
											onClick={() => setOpenPrepaymentModal(true)}
											className="absolute right-5 top-3 text-14 text-[#707070] font-normal p-3 border-[#E0E0E0] border-[1px] rounded-md"
										>
											{policiesAndRulesFormText('edit')}
										</button>
										<p className="text-black1 text-20 font-semibold leading-[15px] py-2">
											{policiesAndRulesFormText('prepaymentPolicy')}
										</p>

										<p className="text-[#027A48] text-16 font-normal py-2">
											{policiesAndRulesFormText('likelyToSecureBookings')}
										</p>
										<div className="flex flex-col gap-4 text-17 py-2">
											<div className="flex gap-4">
												<img src={Checkicon} alt="Check icon" />
												<p className="text-[#707070]">
													{policiesAndRulesFormText(
														'flexibilityPrepaymentPercentage'
													)}
												</p>
											</div>
											<div className="flex gap-4">
												<img src={Checkicon} alt="Check icon" />
												<p className="text-[#707070]">
													{policiesAndRulesFormText('improveCashFlow')}
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="w-[40%] flex flex-col gap-12">
									<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('prepaymentPolicyHeading')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('prepaymentPolicyDescription')}
										</p>
									</div>
								</div>
							</div>

							<hr className="mb-8 mt-4" />
							<div className="flex flex-row gap-12">
								<div
									className="flex flex-col w-[60%] rounded-[10px] border-[#E0E0E0] border-[1px]"
									style={{ padding: '22px', paddingTop: '10px' }}
								>
									<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
										{policiesAndRulesFormText('additionalFeesCharges')}
									</p>
									{Object.keys(feesAndCharges).map((id) => {
										const row = feesAndCharges[id]
										return (
											<div
												className="flex row mb-4"
												style={{ justifyContent: 'space-between' }}
											>
												<div style={{ lineHeight: '20px' }}>
													<p className={'font-bold'}>{row.name}</p>
													<span>{row.description}</span>
												</div>
												<TextField
													required
													size="small"
													value={row.amount}
													onChange={(e) =>
														handleInputChange(id, e.target.value)
													}
													className={'w-[20%]'}
												/>
											</div>
										)
									})}

									<div className="flex flex-row justify-between mt-2 h-[48px] gap-8">
										<button
											type="submit"
											className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
											onClick={() => {
												onSubmitAccommodationChargeAndFeeUpdate()
											}}
										>
											{updatingFirstAccommodationAdditionalCharge ? (
												<CircularProgress size={16} />
											) : (
												policiesAndRulesFormText('update')
											)}
										</button>
									</div>

									<div>
										<p className="text-black1 text-18 font-semibold leading-[35px] p-0 mt-4">
											{policiesAndRulesFormText('addCustomFees')}
										</p>

										<div
											style={{
												padding: '22px',
												paddingTop: '5px',
												paddingBottom: '5px',
											}}
											className="rounded-t-[10px] border-[#E0E0E0] border-[1px]"
										>
											<div className="flex flex-col">
												<RInputDeclare
													value={policiesAndRulesFormText('feeTitle')}
													className="!mt-4 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													size="small"
													value={
														accommodationCustomChargeAndFeeFormikProps.values
															.custom_name
													}
													onChange={(e) =>
														accommodationCustomChargeAndFeeFormikProps.setFieldValue(
															'custom_name',
															e.target.value
														)
													}
												/>
												{!isEmpty(
													accommodationCustomChargeAndFeeFormikProps.errors
														.custom_name
												) && (
													<div className="text-red-600 text-12 mt-2">
														{
															accommodationCustomChargeAndFeeFormikProps.errors
																.custom_name
														}
													</div>
												)}

												<RInputDeclare
													value={policiesAndRulesFormText('feeExplanation')}
													className="!mt-4 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													size="small"
													multiline
													minRows={2}
													value={
														accommodationCustomChargeAndFeeFormikProps.values
															.custom_description
													}
													onChange={(e) =>
														accommodationCustomChargeAndFeeFormikProps.setFieldValue(
															'custom_description',
															e.target.value
														)
													}
												/>
												{!isEmpty(
													accommodationCustomChargeAndFeeFormikProps.errors
														.custom_description
												) && (
													<div className="text-red-600 text-12 mt-2">
														{
															accommodationCustomChargeAndFeeFormikProps.errors
																.custom_description
														}
													</div>
												)}

												<RInputDeclare
													value={policiesAndRulesFormText('feeValue')}
													className="!mt-4 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													type="number"
													size="small"
													value={
														accommodationCustomChargeAndFeeFormikProps.values
															.custom_amount
													}
													onChange={(e) =>
														accommodationCustomChargeAndFeeFormikProps.setFieldValue(
															'custom_amount',
															e.target.value
														)
													}
												/>
												{!isEmpty(
													accommodationCustomChargeAndFeeFormikProps.errors
														.custom_amount
												) && (
													<div className="text-red-600 text-12 mt-2">
														{
															accommodationCustomChargeAndFeeFormikProps.errors
																.custom_amount
														}
													</div>
												)}
											</div>
											<div className="flex flex-row justify-between h-[48px] m-4 gap-4">
												<button
													type="submit"
													className="rounded-lg border-[#E0E0E0] bg-cyan1 text-white border-[1px]  font-bold grow h-full flex justify-center items-center"
													onClick={() =>
														accommodationCustomChargeAndFeeFormikProps.handleSubmit()
													}
												>
													{updatingAccommodationAdditionalCustomRule ? (
														<CircularProgress size={16} />
													) : (
														policiesAndRulesFormText('addFee')
													)}
												</button>
											</div>
										</div>
									</div>

									<hr className="mb-4 mt-4" />
									<div>
										<p className="text-base text-gray5 my-4">
											<span className="text-black1 text-20 font-bold">
												{policiesAndRulesFormText('houseRules')}
											</span>
										</p>
									</div>

									<div className="flex flex-row gap-12">
										<div className="w-[100%] ">
											<div>
												<p className="text-black1 text-18 font-semibold leading-[35px]">
													{policiesAndRulesFormText('selectRules')}
												</p>
												<FormGroup>
													<FormControlLabel
														value={
															accommodationRulesFormikProps.values
																.smoking_allowed
														}
														onChange={(e) =>
															accommodationRulesFormikProps.setFieldValue(
																'smoking_allowed',
																e.target.checked
															)
														}
														control={
															<Checkbox
																checked={
																	accommodationRulesFormikProps.values
																		.smoking_allowed
																}
															/>
														}
														label={policiesAndRulesFormText('smokingAllowed')}
													/>
													<FormControlLabel
														value={
															accommodationRulesFormikProps.values.pets_allowed
														}
														onChange={(e) =>
															accommodationRulesFormikProps.setFieldValue(
																'pets_allowed',
																e.target.checked
															)
														}
														control={
															<Checkbox
																checked={
																	accommodationRulesFormikProps.values
																		.pets_allowed
																}
															/>
														}
														label={policiesAndRulesFormText('petsAllowed')}
													/>
													<FormControlLabel
														value={
															accommodationRulesFormikProps.values
																.parties_allowed
														}
														onChange={(e) =>
															accommodationRulesFormikProps.setFieldValue(
																'parties_allowed',
																e.target.checked
															)
														}
														control={
															<Checkbox
																checked={
																	accommodationRulesFormikProps.values
																		.parties_allowed
																}
															/>
														}
														label={policiesAndRulesFormText('partiesAllowed')}
													/>
												</FormGroup>

												<div className="flex flex-row justify-between mt-4 mb-4 h-[48px] gap-8">
													<button
														type="submit"
														className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
														onClick={() =>
															accommodationRulesFormikProps.handleSubmit()
														}
													>
														{updatingRules ? (
															<CircularProgress size={16} />
														) : (
															policiesAndRulesFormText('update')
														)}
													</button>
												</div>

												<div>
													{customRules.length > 0 && (
														<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
															{policiesAndRulesFormText('customRules')}
														</p>
													)}
													{customRules.map((rule) => {
														return (
															<div
																className="flex row mb-6"
																style={{ justifyContent: 'space-between' }}
															>
																<div style={{ lineHeight: '20px' }}>
																	<p>{rule.name}</p>
																</div>
																<IconButton
																	onClick={() => {
																		setShowDeleteRule(true)
																		setSelectedRule(rule)
																	}}
																	style={{
																		width: 20,
																		height: 20,
																		backgroundColor: '#fff',
																		border: '1px solid #CBD5E1',
																		borderRadius: '5px',
																	}}
																>
																	<CloseOutlined
																		style={{
																			color: '#707070',
																			width: '10px',
																			height: '10px',
																		}}
																	/>
																</IconButton>
															</div>
														)
													})}
													<p className="text-black1 text-18 font-semibold leading-[35px] p-0 mt-4">
														{policiesAndRulesFormText('addCustomRules')}
													</p>

													<div
														style={{
															padding: '22px',
															paddingTop: '5px',
															paddingBottom: '5px',
														}}
														className="rounded-t-[10px] border-[#E0E0E0] border-[1px]"
													>
														<div className="flex flex-col">
															<RInputDeclare
																value={policiesAndRulesFormText('rule')}
																className="!mt-4 !mb-2 !text-black1 font-medium text-base"
															/>
															<TextField
																required
																size="small"
																value={
																	accommodationCustomRulesFormikProps.values
																		.name
																}
																onChange={(e) =>
																	accommodationCustomRulesFormikProps.setFieldValue(
																		'name',
																		e.target.value
																	)
																}
															/>
															{!isEmpty(
																accommodationCustomRulesFormikProps.errors.name
															) && (
																<div className="text-red-600 text-12 mt-2">
																	{
																		accommodationCustomRulesFormikProps.errors
																			.name
																	}
																</div>
															)}
														</div>
														<div className="flex flex-row justify-between h-[48px] m-4 gap-4">
															<button
																type="submit"
																className="rounded-lg border-[#E0E0E0] bg-cyan1 text-white border-[1px] font-bold grow h-full flex justify-center items-center"
																onClick={() =>
																	accommodationCustomRulesFormikProps.handleSubmit()
																}
															>
																{updatingAccommodationAdditionalCustomRule ? (
																	<CircularProgress size={16} />
																) : (
																	policiesAndRulesFormText('addRule')
																)}
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="w-[40%] flex flex-col gap-12">
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('additionalFeesChargesHeading')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText(
												'additionalFeesChargesDescription'
											)}
										</p>
									</div>
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('addCustomFeesHeading')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('addCustomFeesDescription')}
										</p>
									</div>
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('houseRulesHeading')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('houseRulesDescription')}
										</p>
									</div>
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('addCustomRulesHeading')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('addCustomRulesDescription')}
										</p>
									</div>
								</div>
							</div>

							<hr className="mb-8 mt-4" />
							<div className="flex flex-row gap-12">
								<div className="flex flex-col w-[60%] p-[22px] rounded-[10px] border-[#E0E0E0] border-[1px]">
									<div>
										<p className="text-black1 text-18 font-semibold leading-[35px]">
											{policiesAndRulesFormText('guestRequirements')}
										</p>
										<FormGroup>
											<FormControlLabel
												value={phoneRequired}
												onChange={(e) => setPhoneRequired(e.target.checked)}
												control={<Checkbox checked={phoneRequired} />}
												label={policiesAndRulesFormText('phoneNumber')}
											/>
											<FormControlLabel
												value={idCardRequired}
												onChange={(e) => setIdCardRequired(e.target.checked)}
												control={<Checkbox checked={idCardRequired} />}
												label={policiesAndRulesFormText('idCardOrPassport')}
											/>
										</FormGroup>
									</div>
									<hr className="mb-4 mt-4" />
									<div className="flex gap-14">
										<div className="flex flex-1 flex-col">
											<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
												{policiesAndRulesFormText('checkInFrom')}
											</p>
											<CustomTimePicker
												classes="bg-white"
												value={checkinFrom}
												onChange={setCheckinFrom}
											/>
										</div>
										<div className="flex flex-1 flex-col">
											<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
												{policiesAndRulesFormText('until')}
											</p>
											<CustomTimePicker
												classes="bg-white"
												value={checkinUntil}
												onChange={setCheckinUntil}
											/>
										</div>
									</div>
									<div className="flex gap-14 mt-4">
										<div className="flex flex-1 flex-col">
											<p className="text-black1 text-18 font-semibold leading-[35px] py-1">
												{policiesAndRulesFormText('checkOutFrom')}
											</p>
											<CustomTimePicker
												classes="bg-white"
												value={checkoutFrom}
												onChange={setCheckoutFrom}
											/>
										</div>
										<div className="flex flex-1 flex-col">
											<p className="text-black1 text-18 font-semibold leading-[35px] py-1">
												{policiesAndRulesFormText('until')}
											</p>
											<CustomTimePicker
												classes="bg-white"
												value={checkoutUntil}
												onChange={setCheckoutUntil}
											/>
										</div>
									</div>

									<hr className="mb-4 mt-8" />
									<p className="text-black1 text-18 font-semibold leading-[35px] py-1">
										{policiesAndRulesFormText('keyPickup')}
									</p>
									<div className="flex flex-col">
										<TextField
											required
											rows={2}
											multiline
											size="medium"
											placeholder={policiesAndRulesFormText('keyPickupDetails')}
											value={keyPickup}
											onChange={(e) => setKeyPickup(e.target.value)}
										/>
									</div>
									<p className="text-black1 text-18 mt-4 font-semibold leading-[35px] py-1">
										{policiesAndRulesFormText('checkInMethod')}
									</p>
									<div className="flex flex-col">
										<TextField
											required
											rows={2}
											multiline
											size="medium"
											placeholder={policiesAndRulesFormText(
												'checkInMethodDetails'
											)}
											value={checkinMethod}
											onChange={(e) => setCheckInMethod(e.target.value)}
										/>
									</div>
									<p className="text-black1 text-18 mt-4 font-semibold leading-[35px] py-1">
										{policiesAndRulesFormText('checkOutMethod')}
									</p>
									<div className="flex flex-col">
										<TextField
											required
											rows={2}
											multiline
											size="medium"
											placeholder={policiesAndRulesFormText(
												'checkOutMethodDetails'
											)}
											value={checkoutMethod}
											onChange={(e) => setCheckoutMethod(e.target.value)}
										/>
									</div>
									<p className="text-black1 mt-4 text-18 font-semibold leading-[35px] py-1">
										{policiesAndRulesFormText('wifiDetails')}
									</p>
									<div className="flex flex-col">
										<TextField
											required
											rows={2}
											multiline
											size="medium"
											placeholder={policiesAndRulesFormText(
												'wifiDetailsPlaceholder'
											)}
											value={wifiDetail}
											onChange={(e) => setWifiDetail(e.target.value)}
										/>
									</div>
									<div className="flex flex-row justify-between mt-4 mb-4 h-[48px] gap-8">
										<button
											type="submit"
											className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
											onClick={() => onSubmitAccommodationRulesExtra()}
										>
											{updatingRulesExtra ? (
												<CircularProgress size={16} />
											) : (
												policiesAndRulesFormText('update')
											)}
										</button>
									</div>
								</div>

								<div className="w-[40%] flex flex-col gap-12">
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('guestRequirements')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('guestRequirementsDetails')}
										</p>
									</div>
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('checkInCheckOut')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('checkInCheckOutDetails')}
										</p>
									</div>
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="Info icon" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{policiesAndRulesFormText('managingKeyDetails')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{policiesAndRulesFormText('managingKeyDetailsDetails')}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			{editModal && (
				<CancellationModal
					showModal={editModal}
					data={data}
					rentalUnitId={rentalUnitId}
					onClose={(refresh) => {
						setEditModal(false)
					}}
				/>
			)}
			{openPrepaymentModal && (
				<PrepaymentModal
					showModal={openPrepaymentModal}
					data={data}
					rentalUnitId={rentalUnitId}
					onClose={(refresh) => {
						setOpenPrepaymentModal(false)
					}}
				/>
			)}

			{showDeleteRule && (
				<AreYouSureModal
					title={policiesAndRulesFormText('removeHouseRule')}
					content={policiesAndRulesFormText('removeHouseRuleConfirmation')}
					firstButton={policiesAndRulesFormText('noCancel')}
					secondButton={policiesAndRulesFormText('yesIAm')}
					showModal={showDeleteRule}
					onClose={() => setShowDeleteRule(false)}
					onSubmit={() =>
						accommodationService
							.CustomRuleDelete(selectedRule.id, rentalUnitId)
							.then((data) => {
								NotificationManager.success(
									policiesAndRulesFormText('ruleDeletedSuccess'),
									policiesAndRulesFormText('success'),
									3000
								)
								setTimeout(() => {
									navigate(
										`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`
									)
									navigate(0)
								}, 1000)
							})
							.catch((error) => {
								NotificationManager.error(
									error.message || policiesAndRulesFormText('somethingWentWrong'),
									policiesAndRulesFormText('error'),
									3000
								)
								throw new Error(policiesAndRulesFormText('problemOnRuleDelete'))
							})
					}
				/>
			)}
		</>
	)
}

export default PoliciesAndRulesForm;
