import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Dropdown from 'react-dropdown'

import { Card, CardContent, TablePagination } from '@mui/material'
import { useSelector } from 'react-redux'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import NewStaffExpense from '../../../components/report/staff/newStaffExpense'
import NewStaffPerformance from '../../../components/report/staff/newStaffPerformance'
import TableEmptyData from '../../../components/table/TableEmptyData'
import ReportService from '../../../services/reportService'
import StaffService from '../../../services/staffService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export default function StaffReport() {
	const { short_code } = useSelector((state) => state.app)
	const [performanceData, setPerformanceData] = useState([])
	const [performancePage, setPermancePage] = React.useState(0)
	const [performanceRowsPerPage, setPermanceRowsPerPage] = React.useState(10)

	const [expenseData, setExpenseData] = useState([])
	const [expensePage, setExpensePage] = React.useState(0)
	const [expenseRowsPerPage, setExpenseRowsPerPage] = React.useState(10)

	const [performanceModal, setPerformanceModal] = useState(false)
	const [expenseModal, setExpenseModal] = useState(false)
	const [employee, setEmployee] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [callEnd, setCallEnd] = useState({ performance: false, expense: false })

	const [employeeOptions, setEmployeeOptions] = useState([])
	useEffect(() => {
		StaffService.ReadAll().then((res) => {
			setEmployeeOptions(
				res.data.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				})
			)
		})

		refreshData()
	}, [])

	useEffect(() => {
		if (callEnd.performance && callEnd.expense) {
			setLoaded(true)
		}
	}, [callEnd])

	const handleChangePagePermance = (event, newPage) => {
		setPermancePage(newPage)
	}

	const handleChangeRowsPerPagePermance = (event) => {
		setPermanceRowsPerPage(+event.target.value)
		setPermancePage(0)
	}

	const handleChangePageExpense = (event, newPage) => {
		setExpensePage(newPage)
	}

	const handleChangeRowsPerPageExpense = (event) => {
		setExpenseRowsPerPage(+event.target.value)
		setExpensePage(0)
	}

	const refreshData = () => {
		ReportService.GetPerformances()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('report service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'performances',
				})
				setCallEnd((v) => ({ ...v, performance: true }))
				setPerformanceData(res.data)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('report service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'performances',
				})
				setCallEnd((v) => ({ ...v, performance: true }))
			})

		ReportService.GetExpenses()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('report service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'expenses',
				})
				setCallEnd((v) => ({ ...v, expense: true }))
				setExpenseData(res.data)
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('report service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'expenses',
				})
				setCallEnd((v) => ({ ...v, expense: true }))
			})
	}

	const confirmAdd = () => {
		refreshData()
	}

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full  overflow-auto p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										Performance Report
									</span>
								</div>
								<div className="flex flex-row items-center gap-4">
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setPerformanceModal(true)}
									>
										<span>Create performance report</span>
									</div>
									<div className="flex flex-row items-center my-2">
										<Dropdown
											options={employeeOptions}
											onChange={setEmployee}
											value={employee}
											placeholder="Select Guest"
											prepareDisplay="Select Guest"
											className="w-[172px] !rounded-l-lg rounded-r-lg reser-select"
										/>
										<button
											className="text-black1 bg-white border border-[#c4c4c4] border-l-0 rounded-r-lg font-semibold text-16 p-[9px] px-6 "
											onClick={() => setEmployee('')}
										>
											All
										</button>
									</div>
								</div>
							</div>
							{performanceData.length ? (
								<div className="flex flex-col overflow-auto">
									<TableContainer component={Paper} className="mt-2">
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead className="bg-gray7">
												<TableRow>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														Employee
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Attendance
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Punctuality
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Productivity
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Performance Date
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{performanceData.map((row, index) => (
													<TableRow key={index}>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base font-bold"
														>
															{
																employeeOptions.find(
																	(o) => o.value === row.employee_id
																)?.label
															}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base"
														>
															{row.attendance}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base "
														>
															{row.punctuality}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base "
														>
															{row.productivity}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base "
														>
															{row.performance_date}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={performanceData.length}
										rowsPerPage={performanceRowsPerPage}
										page={performancePage}
										onPageChange={handleChangePagePermance}
										onRowsPerPageChange={handleChangeRowsPerPagePermance}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-2 mb-10">
									<TableEmptyData
										content="report"
										btnLabel="Create performance report"
										btnIcon={false}
										onAction={() => setPerformanceModal(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>

					<Card className="!mt-6">
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										Expense Report
									</span>
								</div>
								<div className="flex flex-row items-center gap-4">
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setExpenseModal(true)}
									>
										<span>Create expense report</span>
									</div>
									<div className="flex flex-row items-center my-2">
										<Dropdown
											options={employeeOptions}
											onChange={setEmployee}
											value={employee}
											placeholder="Select Guest"
											prepareDisplay="Select Guest"
											className="w-[172px] !rounded-l-lg rounded-r-lg reser-select"
										/>
										<button
											className="text-black1 bg-white border border-[#c4c4c4] border-l-0 rounded-r-lg font-semibold text-16 p-[9px] px-6 "
											onClick={() => setEmployee('')}
										>
											All
										</button>
									</div>
								</div>
							</div>
							{expenseData.length ? (
								<div className="flex flex-col overflow-auto ">
									<TableContainer component={Paper} className="mt-2">
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead className="bg-gray7">
												<TableRow>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base font-bold"
														align="left"
													>
														Employee
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Type
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Amount
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Date
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														Status
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{expenseData.map((row, index) => (
													<TableRow key={index}>
														<TableCell align="left" className="!text-center">
															{
																employeeOptions.find(
																	(o) => o.value === row.employee_id
																)?.label
															}
														</TableCell>
														<TableCell align="left" className="!text-center">
															{row.type}
														</TableCell>
														<TableCell align="left" className="!text-center ">
															{row.amount}
														</TableCell>
														<TableCell align="left" className="!text-center ">
															{row.date}
														</TableCell>
														<TableCell align="left" className="!text-center ">
															{row.status}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={expenseData.length}
										rowsPerPage={expenseRowsPerPage}
										page={expensePage}
										onPageChange={handleChangePageExpense}
										onRowsPerPageChange={handleChangeRowsPerPageExpense}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-2 mb-10">
									<TableEmptyData
										content="report"
										btnLabel="Create expense report"
										btnIcon={false}
										onAction={() => setExpenseModal(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>

					<NewStaffPerformance
						showModal={performanceModal}
						onClose={() => setPerformanceModal(false)}
						onConfirm={() => confirmAdd()}
					/>
					<NewStaffExpense
						showModal={expenseModal}
						onClose={() => setExpenseModal(false)}
						onConfirm={() => {
							confirmAdd()
						}}
					/>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
