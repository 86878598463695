import React from "react";
import GeneralToolTip from "./general-tooltip";

const BarChartTooltip = (props) => {
  const { tooltipPayload } = props;

  const hoveredItem = tooltipPayload && tooltipPayload[0];

  if (hoveredItem) {
    const colorValue = hoveredItem.color ?? "";
    const toolTipPayloadValue = `${hoveredItem?.name}: ${hoveredItem.value}`;

    return (
      <GeneralToolTip
        color={colorValue}
        field={toolTipPayloadValue}
        bgColor={hoveredItem.fill}
      />
    );
  }
  return null;
};

export default BarChartTooltip;
