import React, { useEffect, useState } from "react";
import { Button, IconButton, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Paper, TablePagination } from "@mui/material";
import moment from "moment";

import detailIcon from '../../../assets/svgs/detail.svg';
import vb_svg from '../../../assets/svgs/v2-vb-table-icon.svg';
import uber_svg from '../../../assets/svgs/ubsereats_white.svg';
export default function OrderInfo({ data }) {
    return (
        <div className="flex flex-col">
            <p className=' mb-2 text-black1 text-lg font-medium'>Details Info</p>
            <div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
                <div className="p-4 bg-[#F9F7F7] rounded-lg">
                    <img src={detailIcon} alt="detail" />
                </div>
                <div className="flex-1 flex flex-row" style={{gap: "5rem"}}>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Order Id</span>
                            <span className="text-gray5 text-base font-bold">{data?.id}</span>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Total</span>
                            <span className="text-gray5 text-base font-bold">$ {data?.total ?? 0}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Customer</span>
                            <span className="text-gray5 text-base font-bold">{data?.customer_name ?? 'N/A'}</span>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Subotal</span>
                            <span className="text-gray5 text-base font-bold">$ {data?.subtotal ?? 0}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Source</span>
                            <span className="text-gray5 text-base font-bold">
                                {data.source == 'vb' ?
                                    <div className="flex flex-row justify-start align-center cursor-pointer">
                                        <img src={vb_svg} alt="icon" className="w-[20px] h-[25px] mr-2" />
                                        <p>{data.source_name}</p>
                                    </div>
                                    : data.source == 'uber' ? <div className="flex flex-row justify-start align-center cursor-pointer">
                                        <img src={uber_svg} alt="icon" className="w-[20px] h-[25px] mr-2" />
                                        <p>{data.source_name}</p>
                                    </div> :
                                        null}
                            </span>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Discount</span>
                            <span className="text-[#027A48] text-base font-normal">{data?.dicount ? `- $$ ${data?.dicount}` : ''}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Date</span>
                            <span className="text-gray5 text-base font-bold">{data?.start_time ? moment(data.start_time).format('MMMM Do YYYY, h:mm:ss a') : ''}</span>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Payment Method</span>
                            {/* <span className="text-gray5 text-base font-bold">{data.confirmed === 1 ? "YES" : "NO"}</span> */}
                            <span className="text-text text-base font-bold">{data?.payment_method ?? 'N/A'}</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <span className="text-gray5 text-sm font-normal">Status</span>
                            <div className="font-semibold text-[16px] text-cyan1 bg-[#ECFDF3] border align-center rounded-[10px] cursor-pointer px-[16px]">
                                <div className="flex justify-center items-center text-[14px] font-normal text-[#19191C]">
                                    <span className="mr-[8px] rounded-[6px] w-[2px] h-[2px] bg-[#12B76A] outline outline-[#12B76A]"></span>
                                    <div className="flex justify-center items-center text-[14px] font-normal text-[#027A48]">
                                        Completed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
