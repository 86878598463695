/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import Composer from './Composer';
import Send from './Send';
import Color from './Color';

export default class InputToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: 'absolute'
    };
  }

  renderActions() {
    if (this.props.renderActions) {
      return this.props.renderActions(this.props);
    }
    return null;
  }

  renderSend() {
    if (this.props.renderSend) {
      return this.props.renderSend(this.props);
    }
    return <Send {...this.props} />;
  }

  renderComposer() {
    if (this.props.renderComposer) {
      return this.props.renderComposer(this.props);
    }

    return <Composer {...this.props} />;
  }

  renderAccessory() {
    return null;
  }

  render() {
    return (
      <div
        style={{
          ...styles.container,
          ...this.props.containerStyle, 
        }}>
        <div style={{ ...styles.primary, ...this.props.primaryStyle }}>
          {this.renderActions()}
          {this.renderComposer()}
          {this.renderSend()}
        </div>
        {this.renderAccessory()}
      </div>
    );
  }
}

const styles = {
  container: {
    borderTopWidth: 1,
    borderTopColor: Color.defaultColor,
    backgroundColor: Color.white,
    bottom: 0,
    left: 0,
    right: 0
  },
  primary: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  accessory: {
    height: 44
  },
  chatFooter: { width: '100%', height: 33, marginBottom: 12 }
};

InputToolbar.defaultProps = {
  renderAccessory: null,
  renderActions: null,
  renderSend: null,
  renderComposer: null,
  containerStyle: {},
  primaryStyle: {},
  accessoryStyle: {},
  onPressActionButton: () => { }
};

InputToolbar.propTypes = {
  composerHeight: PropTypes.number,
  renderAccessory: PropTypes.func,
  renderActions: PropTypes.func,
  renderSend: PropTypes.func,
  renderComposer: PropTypes.func,
  onPressActionButton: PropTypes.func,
  onCancelQuote: PropTypes.func,
  containerStyle: PropTypes.object,
  primaryStyle: PropTypes.object,
  accessoryStyle: PropTypes.object,
  quote_msg: PropTypes.shape({
    text: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
      full_name: PropTypes.string
    })
  }),
  images: PropTypes.array,
  default_msgs: PropTypes.array,
  onRemoveImage: PropTypes.func,
  onPressDefaultMsg: PropTypes.func
};
