import { API_SUBSCRIPTION_CONFIRM_SUBSCRIPTION, API_SUBSCRIPTION_CREATE_CHECKOUT_SESSION, API_SUBSCRIPTION_GET_SUBSCRIPTION } from "../constants/API";
import apiFactory from "../utils/apiFactory";

const getSubscription = async () => {
  let api = {
    content_type: "application/json",
    url: API_SUBSCRIPTION_GET_SUBSCRIPTION,
    method: "GET",
  };
  return apiFactory(api);
};

const changeSubscription = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_SUBSCRIPTION_CREATE_CHECKOUT_SESSION}`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const confirmSubscription = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_SUBSCRIPTION_CONFIRM_SUBSCRIPTION}`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

export default {
  getSubscription,
  changeSubscription,
  confirmSubscription
};
