import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import accommodationService from '../../../services/accommodationService';
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil';
import BookingCard from './BookingCard';

const getTranslation = (text) => t(`accommodation.${text}`);

const isCurrentlyHosting = (booking) => {
   const today = new Date();
   const checkInDate = new Date(booking.check_in_date);
   const checkOutDate = new Date(booking.check_out_date);
   return checkInDate <= today && checkOutDate >= today;
};

const isArrivingSoon = (booking) => {
   const today = new Date();
   const checkInDate = new Date(booking.check_in_date);
   return checkInDate > today;
};

const BookingTabs = ({ payload, short_code }) => {
   const [bookings, setBookings] = useState({
      currentlyHosting: [],
      arrivingSoon: [],
      pendingReview: [],
      upcoming: []
   });
   const [loading, setLoading] = useState(true);
   const [activeTab, setActiveTab] = useState('currentlyHosting');

   useEffect(() => {
      setLoading(true);

      accommodationService
         .GetBooking(payload)
         .then((res) => {
            identifyVenue(short_code);
            trackEvent('GetBooking', {
               action_category: 'booking',
               action_outcome: 'success',
               interaction_element: 'booking',
            });

            const fetchedBookings = res?.bookings?.data || [];
            const currentlyHosting = fetchedBookings.filter(isCurrentlyHosting);
            const arrivingSoon = fetchedBookings.filter(isArrivingSoon);

            setBookings((state) => ({ ...state, currentlyHosting, arrivingSoon }));
            setLoading(false);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   }, [payload, short_code]);

   if (loading) {
      return <div>{getTranslation('loading')}</div>;
   }

   const tabs = [
      {
         label: getTranslation('currently_hosting'),
         key: 'currentlyHosting',
         count: bookings.currentlyHosting.length,
         bookingLabel: <span className="text-red">{getTranslation('currently_hosting')}</span>
      },
      {
         label: getTranslation('arriving_soon'),
         key: 'arrivingSoon',
         count: bookings.arrivingSoon.length,
         bookingLabel: <span className="text-yellow-800">{getTranslation('arriving_soon')}</span>
      },
      {
         label: getTranslation('upcoming'),
         key: 'upcoming',
         count: bookings.upcoming.length,
         bookingLabel: <span className="text-yellow-800">{getTranslation('upcoming')}</span>
      },
      {
         label: getTranslation('pending_review'),
         key: 'pendingReview',
         count: bookings.pendingReview.length,
         bookingLabel: <span className="text-yellow-800">{getTranslation('pending_review')}</span>
      },
   ];

   return (
      <div>
         <div className="flex gap-4 mb-4">
            {tabs.map((tab) => (
               <button
                  key={tab.key}
                  className={`px-3 py-1 font-semibold rounded-[40px] border-[1px] ${activeTab.key === tab.key ? 'border-[3px] border-gray1' : 'border-gray1'}`}
                  onClick={() => setActiveTab(tab)}
               >
                  {tab.label} ({tab.count})
               </button>
            ))}
         </div>

         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
            {activeTab.key === 'currentlyHosting' && (
               <>
                  {bookings.currentlyHosting.length ? (
                     bookings.currentlyHosting.map((booking) => (
                        <BookingCard key={booking.id} booking={booking} bookingStatus={activeTab.bookingLabel} />
                     ))
                  ) : (
                        <div className="w-full text-center text-gray-500">{getTranslation('no_current_bookings')}</div>
                  )}
               </>
            )}
            {activeTab.key === 'arrivingSoon' && (
               <>
                  {bookings.arrivingSoon.length ? (
                     bookings.arrivingSoon.map((booking) => (
                        <BookingCard key={booking.id} booking={booking} bookingStatus={activeTab.bookingLabel} />
                     ))
                  ) : (
                        <div className="w-full text-center text-gray-500">{getTranslation('no_arriving_soon_bookings')}</div>
                  )}
               </>
            )}
         </div>
      </div>
   );
};

export default BookingTabs;
