import Cookies from 'universal-cookie'
import LoginService from '../../services/loginService'
import {
	KEYS,
	removeStorageKey,
	setAppToken,
	setCookieVenueType,
	setLoyaltyProgramId,
	setStorageKey,
	setVenueBasicInfo,
	setVenueShortcode,
} from '../../utils/storage'
import {
	SET_IS_TRIAL_MODE,
	SET_PLAN_FEATURES,
	SET_RESTAURANT_LIST,
	SET_SHOW_UPGRADE_FROM_TRIAL,
	SET_SUBSCRIPTION_PLAN,
	SET_USER_PROFILE,
	SET_VENUE_TYPE,
} from './types'

export const login = (email, password) => (dispatch) => {
	return new Promise(async (resolve, reject) => {
		LoginService.login(email, password)
			.then(async (data) => {
				dispatch({ type: SET_USER_PROFILE, payload: data.user })
				setAppToken(data.access_token)
				setVenueShortcode(data.user.restaurants[0].short_code)
				setVenueBasicInfo({
					id: data.user.restaurants[0].id,
					name: data.user.restaurants[0].name,
					role: data.user.employee[0].role.name,
					reservation_start_time:
						data.user.restaurants[0].reservation_start_time,
					reservation_end_time: data.user.restaurants[0].reservation_end_time,
					allow_reservation_from:
						data.user.restaurants[0].allow_reservation_from,
					has_hotel_restaurant: data.user.restaurants[0].has_hotel_restaurant,
					plan_id: data.user.restaurants[0].plan_id,
					currency: data.user.restaurants[0].currency,
				})
				setCookieVenueType(data.user.restaurants[0].venue_type.short_name)
				setLoyaltyProgramId(data.user?.restaurants[0]?.loyalty_programs[0]?.id)

				// Dispatch and save to storage the new status to update the application's state
				dispatch({
					type: SET_IS_TRIAL_MODE,
					payload: data.user?.restaurants[0]?.subscription.is_trial_mode,
				})
				setStorageKey(
					KEYS.IS_TRIAL_MODE,
					data.user?.restaurants[0]?.subscription.is_trial_mode
				)

				dispatch({
					type: SET_SHOW_UPGRADE_FROM_TRIAL,
					payload:
						data.user?.restaurants[0]?.subscription.show_upgrade_from_trial,
				})
				setStorageKey(
					KEYS.SHOW_UPGRADE_FROM_TRIAL,
					data.user?.restaurants[0]?.subscription.show_upgrade_from_trial
				)
				dispatch({
					type: SET_PLAN_FEATURES,
					payload: data.user?.restaurants[0]?.subscription.features,
				})
				setStorageKey(
					KEYS.PLAN_FEATURES,
					data.user?.restaurants[0]?.subscription.features
				)

				dispatch({
					type: SET_SUBSCRIPTION_PLAN,
					payload: data.user?.restaurants[0]?.subscription.plan,
				})
				setStorageKey(
					KEYS.SUBSCRIPTION_PLAN,
					data.user?.restaurants[0]?.subscription.plan
				)
				resolve(data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export const logout = () => (dispatch) => {
	return new Promise(async (resolve, reject) => {
		LoginService.logout()
			.then(async (data) => {
				dispatch({ type: SET_USER_PROFILE, payload: null })
				setAppToken(null)
				setVenueShortcode(null)
				setVenueBasicInfo(null)
				setCookieVenueType(null)
				setLoyaltyProgramId(null)

				// Dispatch and save to storage the new status to update the application's state
				dispatch({
					type: SET_IS_TRIAL_MODE,
					payload: false,
				})
				removeStorageKey(KEYS.IS_TRIAL_MODE)

				dispatch({
					type: SET_SHOW_UPGRADE_FROM_TRIAL,
					payload: false,
				})
				removeStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL)
				dispatch({
					type: SET_PLAN_FEATURES,
					payload: [],
				})
				removeStorageKey(KEYS.PLAN_FEATURES)

				dispatch({
					type: SET_SUBSCRIPTION_PLAN,
					payload: {},
				})
				removeStorageKey(KEYS.SUBSCRIPTION_PLAN)

				resolve(data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

// Urgent fix that on refresh here you need to stay at the cookie preserved short code
export const getUserProfile = () => (dispatch) => {
	const cookies = new Cookies()
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)
	return new Promise(async (resolve, reject) => {
		LoginService.getUserProfile()
			.then(async (data) => {
				dispatch({ type: SET_USER_PROFILE, payload: data.user })
				dispatch({ type: SET_RESTAURANT_LIST, payload: data.user.restaurants })
				dispatch({
					type: SET_VENUE_TYPE,
					payload: {
						short_code: data.user.restaurants.find(
							(r) => r.id === cookieBasicInfo.id
						).short_code,
						venue_type: data.user.restaurants.find(
							(r) => r.id === cookieBasicInfo.id
						).venue_type.short_name,
						venue_name: data.user.restaurants.find(
							(r) => r.id === cookieBasicInfo.id
						).name,
					},
				})
				resolve(data.user)
				setVenueShortcode(
					data.user.restaurants.find((r) => r.id === cookieBasicInfo.id)
						.short_code
				)
				setVenueBasicInfo({
					id: data.user.restaurants.find((r) => r.id === cookieBasicInfo.id).id,
					name: data.user.restaurants.find((r) => r.id === cookieBasicInfo.id)
						.name,
					reservation_start_time: data.user.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					).reservation_start_time,
					reservation_end_time: data.user.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					).reservation_end_time,
					allow_reservation_from: data.user.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					).allow_reservation_from,
					has_hotel_restaurant: data.user.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					).has_hotel_restaurant,
					plan_id: data.user.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					).plan_id,
					role: data.user.employee[0].role.name,
					currency: data.user.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					).currency,
				})
				setCookieVenueType(
					data.user.restaurants.find((r) => r.id === cookieBasicInfo.id)
						.venue_type.short_name
				)
				setLoyaltyProgramId(
					data.user?.restaurants.find((r) => r.id === cookieBasicInfo.id)
						?.loyalty_programs[0]?.id
				)

				// Dispatch and save to storage the new status to update the application's state
				dispatch({
					type: SET_IS_TRIAL_MODE,
					payload: data.user?.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					)?.subscription.is_trial_mode,
				})
				setStorageKey(
					KEYS.IS_TRIAL_MODE,
					data.user?.restaurants.find((r) => r.id === cookieBasicInfo.id)
						?.subscription.is_trial_mode
				)

				dispatch({
					type: SET_SHOW_UPGRADE_FROM_TRIAL,
					payload: data.user?.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					)?.subscription.show_upgrade_from_trial,
				})
				setStorageKey(
					KEYS.SHOW_UPGRADE_FROM_TRIAL,
					data.user?.restaurants.find((r) => r.id === cookieBasicInfo.id)
						?.subscription.show_upgrade_from_trial
				)
				dispatch({
					type: SET_PLAN_FEATURES,
					payload: data.user?.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					)?.subscription.features,
				})
				setStorageKey(
					KEYS.PLAN_FEATURES,
					data.user?.restaurants.find((r) => r.id === cookieBasicInfo.id)
						?.subscription.features
				)

				dispatch({
					type: SET_SUBSCRIPTION_PLAN,
					payload: data.user?.restaurants.find(
						(r) => r.id === cookieBasicInfo.id
					)?.subscription.plan,
				})
				setStorageKey(
					KEYS.SUBSCRIPTION_PLAN,
					data.user?.restaurants.find((r) => r.id === cookieBasicInfo.id)
						?.subscription.plan
				)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
