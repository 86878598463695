import React from 'react'
import { isCursorPointer } from '../../helpers/is-cursor-pointer'

export default function ConfirmationFooter({
	onClose,
	onSubmit,
	title,
	isDisabled,
}) {
	return (
		<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
			<button
				className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
				onClick={onClose}
			>
				Cancel
			</button>
			<button
				disabled={isDisabled}
				className={`rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white ${isCursorPointer(
					isDisabled ?? false
				)}`}
				onClick={onSubmit}
			>
				{title ?? 'Create'}
			</button>
		</div>
	)
}
