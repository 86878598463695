import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomBarChart from '../../components/Charts/custom-barchart'
import GenericPagination from '../../components/generic-table/generic-pagination'
import ReservationService from '../../services/reservationService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import ReservationTable from '../reservations/reservation-table'
import ReservationCancelModal from '../reservations/ReservationCancelModal'
import ReservationConfirmModal from '../reservations/ReservationConfirmModal'
import Calendar from './calendar'
import { barChartList, reservationBlock } from './data'

const RestaurantDashboard = () => {
	const [dashboardData, setDashboardData] = useState()
	const [userWelcome, setUserWelcome] = useState('Admin')
	const [reservations, setReservations] = useState([])
	const [reservation, setReservation] = useState({})
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [showCancelModal, setShowCancelModal] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const { short_code } = useSelector((state) => state.app)
	const { auth } = useSelector((state) => state)
	const navigate = useNavigate()

	const getReservations = () => {
		ReservationService.Filter()
			.then((res) => {
				let allReservations = JSON.parse(JSON.stringify(res.data))
				setReservations(allReservations.filter((o) => o.confirmed !== 2))
				identifyVenue(short_code)
				trackEvent('Reservations', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('Reservations', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'screen',
				})
			})
	}

	const getDashboardData = () => {}

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		getReservations()
	}, [])

	useEffect(() => {
		getDashboardData()
		if (auth && auth.user) {
			setUserWelcome(auth.user.name)
		}
	}, [auth])

	const weeks = [
		{ name: 'Week 1', value: 0 },
		{ name: 'Week 2', value: 0 },
		{ name: 'Week 3', value: 0 },
		{ name: 'Week 4', value: 0 },
	]

	dashboardData?.revenue_statistics.forEach((entry) => {
		const day = new Date(entry.date).getDate()
		const weekIndex = Math.floor((day - 1) / 7)
		weeks[weekIndex].value += parseInt(entry.revenue)
	})

	const totalSum = weeks.reduce((sum, week) => sum + week.value, 0)

	const handleReservationDetail = (item) => {
		navigate(`/${short_code}/admin/reservation/reservations/${item?.id}`)
	}

	const onConfirm = () => {
		ReservationService.Confirm(reservation)
			.then(() => {
				NotificationManager.success('Successfully confirmed')
				getReservations()
				setShowConfirmModal(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	const onCancel = () => {
		ReservationService.Cancel(reservation)
			.then(() => {
				NotificationManager.success('Successfully cancelled')
				getReservations()
				setShowCancelModal(false)
			})
			.catch(() => {
				NotificationManager.error('Failed', '')
			})
	}

	return (
		<div className="p-6 flex flex-col gap-5">
			<div className="name">
				<h1>Welcome, {userWelcome}! 👋</h1>
				<span>Here is your dashboard, where you can track everything</span>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
				{reservationBlock().map((item, index) => (
					<div className="revenue-block" key={index}>
						<div className="flex items-center gap-2">
							<span>{item.icon}</span>
							<span className="title">{item.title}</span>
						</div>
						<span className="value">{item.value}</span>
					</div>
				))}
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
				<div className="statistics col-span-3 w-full">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">Revenue Statistics</span>
						<div className="flex items-center gap-2 weekly">
							<span>Monthly</span>
						</div>
					</div>
					<div className="flex items-center gap-2">
						<span className="value">{totalSum} €</span>
						<span className="this-week">this month</span>
					</div>
					<div className="w-full">
						<CustomBarChart
							data={weeks}
							dataKey="name"
							barChartList={barChartList}
							showLegend={false}
							barSize={20}
							yaxis={true}
							cartesian={true}
							paddingBar={5}
							horizontalCartesian={true}
							showLabelCenter={false}
							radius={[10, 10, 0, 0]}
						/>
					</div>
				</div>

				<div className="statistics w-full col-span-2">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">Reservations Schedule</span>
						<div className="flex items-center gap-2 weekly">
							<span>Monthly</span>
						</div>
					</div>
					<div className="w-full">
						<Calendar bookingsData={dashboardData?.booking_schedule} />
					</div>
				</div>
			</div>

			<div className="w-full col-span-2 bg-white flex flex-col py-6 pl-6 rounded-12">
				<span className="bookings">Reservations</span>
				<ReservationTable
					{...{
						handleReservationDetail,
						setReservation,
						setShowConfirmModal,
						setShowCancelModal,
						reservations,
						page,
						rowsPerPage,
					}}
				/>

				<GenericPagination
					rowsPerPage={rowsPerPage}
					page={page}
					setPage={setPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					count={reservations.length}
					onPageChange={handleChangePage}
				/>
			</div>

			<ReservationCancelModal
				onClose={() => setShowCancelModal(false)}
				onSubmit={onCancel}
				showModal={showCancelModal}
			/>

			<ReservationConfirmModal
				showModal={showConfirmModal}
				onSubmit={onConfirm}
				onClose={() => setShowConfirmModal(false)}
			/>
		</div>
	)
}

export default RestaurantDashboard
