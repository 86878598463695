import { TextField } from '@mui/material'
import React from 'react'

const CustomMUIInput = ({
	placeholder = '',
	value,
	onChange,
	fullWidth = true,
	margin = 'none',
	required = false,
	disabled = false,
	error = false,
	type = 'text',
	name
}) => {
	return (
		<div className="h-12">
			<TextField
				size="small"
				className="h-full"
				sx={{
					height: '100%',
				}}
				{...{
					disabled,
					error,
					type,
					required,
					fullWidth,
					placeholder,
					value,
					onChange,
					margin, name
				}}
			/>
		</div>
	)
}

export default CustomMUIInput
