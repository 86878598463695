import { Card, CardContent } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import AreYouSureModal from '../../../components/common/areYouSureModal'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import MarketingService from '../../../services/marketingService'
import EndUserCardsModal from './EndUserCardsModal'

import { t } from 'i18next'
import Cookies from 'universal-cookie'
import plusIcon from '../../../assets/svgs/plus.svg'
import referralIcon from '../../../assets/svgs/referral.svg'
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../../helpers/venueHelpers'
import { KEYS } from '../../../utils/storage'

export const endUsersText = (text) => t(`marketing.endusercards.view.${text}`)

export default function EndUserCards() {
	const _curCard = useRef(null)
	const [guests, setGuests] = useState([])
	const [endUserCards, setEndUserCards] = useState([])
	const [selected, setSelected] = useState('')

	const [showModal, setShowModal] = useState(false)
	const [deleteOpen, setDeleteModalOpen] = useState(false)
	const [confirmVerify, setConfirmVerify] = useState(false)

	const [loaded, setLoaded] = useState(false)
	const [callEnd, setCallEnd] = useState({ guest: false, endusercard: false })

	const cookies = new Cookies()

	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)

	useEffect(() => {
		loadData()
	}, [])

	useEffect(() => {
		if (callEnd.guest && callEnd.endusercard) {
			setLoaded(true)
		}
	}, [callEnd])

	const loadData = () => {
		MarketingService.GetGuestsWithoutCard()
			.then((res) => {
				setCallEnd((v) => ({ ...v, guest: true }))
				setGuests(res.data)
			})
			.catch((error) => {
				setCallEnd((v) => ({ ...v, guest: true }))
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
			})

		MarketingService.GetEndUserCards()
			.then((res) => {
				setCallEnd((v) => ({ ...v, endusercard: true }))
				setEndUserCards(res.data)
			})
			.catch((error) => {
				setCallEnd((v) => ({ ...v, endusercard: true }))
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
				throw new Error(endUsersText('problem_endusers'))
			})
	}

	const refreshData = () => {
		MarketingService.GetGuestsWithoutCard()
			.then((res) => {
				setCallEnd((v) => ({ ...v, guest: true }))
				setGuests(res.data)
			})
			.catch((error) => {
				setCallEnd((v) => ({ ...v, guest: true }))
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
			})

		MarketingService.GetEndUserCards()
			.then((res) => {
				setCallEnd((v) => ({ ...v, endusercard: true }))
				setEndUserCards(res.data)
			})
			.catch((error) => {
				setCallEnd((v) => ({ ...v, endusercard: true }))
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
				throw new Error(endUsersText('problem_endusers'))
			})
	}

	return (
		<>
			{loaded ? (
				<div className="p-6 mb-4">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row items-center justify-between p-7 ">
								<div className="flex flex-row items-center">
									<div className="bg-gray2 p-3 rounded-lg mr-6">
										<img
											src={referralIcon}
											alt="referral"
											className="w-6 h-6"
										/>
									</div>
									<div className="flex flex-col justify-center items-start">
										{isRetailVenue(venueType) && (
											<p className="text-base text-black1 font-medium">
												{endUsersText('programRetail')}
											</p>
										)}
										{isFB(venueType) && (
											<p className="text-base text-black1 font-medium">
												{endUsersText('programDining')}
											</p>
										)}
										{isEntertainmentVenue(venueType) && (
											<p className="text-base text-black1 font-medium">
												{endUsersText('programEntertainment')}
											</p>
										)}
										{isAccommodation(venueType) && (
											<p className="text-base text-black1 font-medium">
												{endUsersText('programAccommodation')}
											</p>
										)}
										<div className="flex flex-row justify-center items-center mt-1">
											<div className="text-xl text-black1 font-medium mr-5">
												{cookieBasicInfo.name}
											</div>
										</div>
									</div>
									<div className="w-[80px]" />
									<div className="flex flex-col justify-center items-start">
										<p className="text-base text-black1 font-medium">
											{endUsersText('cardsGenerated')}
										</p>
										<div className="mt-3 text-xl text-black1 font-medium">
											{endUserCards.length} / ∞
										</div>
									</div>
								</div>
								<div className="flex flex-row justify-start items-center gap-4">
									<div
										className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => {
											_curCard.current = null
											setShowModal(true)
										}}
									>
										<img src={plusIcon} alt="plus" className="mr-2" />
										<span>{endUsersText('generateCard')}</span>
									</div>
								</div>
							</div>
						</CardContent>
						<CardContent className="!p-0">
							{/* Rest of the component code */}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
			{showModal && (
				<EndUserCardsModal
					data={_curCard.current}
					showModal={showModal}
					guests={guests}
					onClose={(refresh) => {
						if (refresh === true) {
							refreshData()
						}
						setShowModal(false)
					}}
				/>
			)}
			{deleteOpen && (
				<AreYouSureModal
					title="Delete this card"
					content="Are you sure you want to delete this card?"
					firstButton={'No, go back'}
					secondButton={'Yes, I am'}
					showModal={deleteOpen}
					onClose={() => setDeleteModalOpen(false)}
					onSubmit={() => {
						setDeleteModalOpen(false)
						MarketingService.DeleteEndUserCards(selected)
							.then(({ data }) => {
								refreshData()
								NotificationManager.success(
									data?.message ?? 'Card is deleted successfully'
								)
							})
							.catch((err) => {
								NotificationManager.error(
									err?.message ?? 'Something went wrong!'
								)
							})
					}}
				/>
			)}
			{confirmVerify && (
				<AreYouSureModal
					title="Verify card"
					content="Are you sure you want to verify this card?"
					firstButton={'No, go back'}
					secondButton={'Yes, I am'}
					showModal={confirmVerify}
					onClose={() => setConfirmVerify(false)}
					onSubmit={() => {
						setConfirmVerify(false)
						MarketingService.ChangeEndUserCardsVerification({ id: selected })
							.then(() => {
								setEndUserCards((v) =>
									v.map((item) => {
										if (item.id === selected) return { ...item, is_verified: 1 }
										else return item
									})
								)
								NotificationManager.success('Card are verified successfully')
							})
							.catch((err) => {
								NotificationManager.error(
									err?.message ?? 'Something went wrong!'
								)
							})
					}}
				/>
			)}
		</>
	)
}
