export const entranceExitLogsCol = [
   {
      label: 'Name',
      field: 'name',
      align: 'left'
   },
   {
      label: 'Age',
      field: 'age',
      align: 'left'
   },
   {
      label: 'Gender',
      field: 'gender',
      align: 'left'
   },
   {
      label: 'Status',
      field: 'status',
      align: 'center',
      cellComponent: ({ data }) => {
         const statusStyle = data === 'Entered'
            ? { color: '#088B3A', backgroundColor: '#DFF5E1', padding: '5px', borderRadius: '4px' }
            : { color: '#EB5842', backgroundColor: '#FDE6E7', padding: '5px', borderRadius: '4px' };
         return <div style={{ ...statusStyle, width: "100%", textAlign: "center" }}>{data}</div>;
      }
   },
   {
      label: 'Entered',
      field: 'entered',
      align: 'center'
   },
   {
      label: 'Exited',
      field: 'exited',
      align: 'center',
      cellComponent: ({ data }) => {
         return <span>{data}</span>
      }
   }
];

export const entranceExitLogsData = [
   { name: 'Jena Smith', age: 36, gender: 'Female', status: 'Entered', entered: 'May 26, 2024 14:00', exited: 'May 26, 2024 14:00' },
   { name: 'Lena Versos', age: 25, gender: 'Female', status: 'Excited', entered: 'May 26, 2024 14:00', exited: 'May 26, 2024 14:00' },
   { name: 'Paula Oreo', age: 22, gender: 'Female', status: 'Excited', entered: 'May 26, 2024 14:00', exited: 'May 26, 2024 14:00' }
];




export const staffAttendanceCol = [
   {
      label: 'Name',
      field: 'name',
      align: 'left'
   },
   {
      label: 'Role',
      field: 'role',
      align: 'left'
   },
   {
      label: 'Status',
      field: 'status',
      align: 'center',
      cellComponent: ({ data }) => {
         const statusColorMap = {
            'Present': { color: '#41E32C', backgroundColor: '#DFF5E1' },
            'Late': { color: '#FFB238', backgroundColor: '#FFF3D3' },
            'Absent': { color: '#ED1C24', backgroundColor: '#FDE6E7' }
         };
         const statusStyle = { ...statusColorMap[data], padding: '5px', borderRadius: '4px', width: "100%", textAlign: "center" };
         return <div style={statusStyle}>{data}</div>;
      }
   },
   {
      label: 'Check-in Time',
      field: 'check_in',
      align: 'center'
   },
   {
      label: 'Check-out Time',
      field: 'check_out',
      align: 'center',
      cellComponent: ({ data }) => {
         return <span>{data}</span>
      }
   }
];

export const staffAttendanceData = [
   { name: 'Jena Smith', role: 'Housekeeping Staff', status: 'Present', check_in: 'May 26, 2024 14:00', check_out: 'May 26, 2024 14:00' },
   { name: 'Lena Versos', role: 'Housekeeping Staff', status: 'Late', check_in: 'May 26, 2024 14:00', check_out: 'May 26, 2024 14:00' },
   { name: 'Paula Oreo', role: 'Housekeeping Staff', status: 'Absent', check_in: 'May 26, 2024 14:00', check_out: 'May 26, 2024 14:00' }
];



