import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import CustomMonthCalendar from "../../../components/custom/CustomMonthCalendar/CustomMonthCalendar";
import { CalendarMonth } from "@mui/icons-material";
import ReservetionsEventsModal from "../../../components/reservation/ReservetionsEventsModal";
import LoadingScreen from "../../../components/custom/LoadingScreen";
import VenueService from "../../../services/venuService";

// import './index.css';

const BowlingAvailability = ({ title }) => {
  const api_type = "bowling";
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthOptions = [];
  for (let i = 0; i < months.length; i++) {
    monthOptions.push({
      value: i + 1,
      label: months[i] + " " + new Date().getFullYear(),
    });
  }
  const [month, setMonth] = useState(1);
  const [modalEvents, setModalEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    var month = new Date().getMonth();
    setMonth(month + 1);
    getCaledarDetails(month + 1);
  }, []);

  const getCaledarDetails = (month) => {
    const payload = {
      type: api_type,
      year: new Date().getFullYear(),
      month: month,
    };
    VenueService.GetCalendarReservations(payload)
      .then((res) => {
        setLoading(false);
        setEvents(res);
      })
      .catch((err) => {
        setLoading(false);
        throw new Error("Problem on GetCalendarReservations for Bowling");
      });
  };

  const onDaySelect = (day) => {
    const displayEvents = events.filter((item) => item.day == day);
    if (displayEvents.length > 0) {
      setModalEvents(displayEvents);
    }
  };

  const setNewMonth = (newMonth) => {
    setMonth(newMonth);
    let cd = calendarDate;
    cd.setMonth(newMonth - 1);
    setCalendarDate(cd);
    setLoading(true);
    getCaledarDetails(newMonth);
  };

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
        <div className="h-full right-0 p-6 z-20 flex flex-col">
          <Card>
            <CardContent className="!p-0">
              <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5 pb-5">
                <div className="flex flex-row items-center">
                  <span className="text-black1 text-s font-medium">
                    Welcome to the {title} Venue Bookings Calendar! This
                    interactive calendar provides an overview of the booked
                    bookings for each day, allowing you to effortlessly
                    manage and access detailed information on each reservation
                    by simply clicking on the respective dates.
                  </span>
                </div>
                <div className="flex flex-row items-center gap-4 ">
                  <TextField
                    id="outlined-select"
                    select
                    className="w-[150px]"
                    value={month}
                    onChange={(event) => setNewMonth(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonth />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {monthOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <Card className="pb-4 pr-4 pl-4 pt-0 h-full">
                <CardContent className="!p-0">
                  <CustomMonthCalendar
                    events={events}
                    monthValue={calendarDate}
                    onChange={onDaySelect}
                  />
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </div>
      </div>
      <ReservetionsEventsModal
        showModal={modalEvents.length > 0}
        data={modalEvents}
        onClose={() => setModalEvents([])}
        onRefresh={null}
      />
    </>
  );
};

export default BowlingAvailability;
