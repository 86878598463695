import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const getTranslation = (text) =>
	t(`account.venueWebProfile.brandCustomize.${text}`)

const BrandCustomize = () => {
	let navigate = useNavigate()

	const { short_code } = useSelector((state) => state.app)

	const redirectToAdditionalInformation = () => {
		navigate(`/${short_code}/admin/settings/web/additional-information`)
	}

	const redirectToBrandProfile = () => {
		navigate(`/${short_code}/admin/settings/customizable-brand`)
	}

	const redirectToThemeSelector = () => {
		navigate(`/${short_code}/admin/settings/web/themes`)
	}

	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-l font-medium">
							{getTranslation('customizeVenuesAppearance')}
						</span>
					</div>
					{short_code !== "BYB2929SCDE" &&
						<><div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<svg
									width="38"
									height="38"
									viewBox="0 0 58 58"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ marginTop: 5 }}
								>
									<path
										d="M19.0312 26.2206C18.3063 26.2206 17.7726 25.9085 17.4302 25.2841C17.0878 24.6598 17.0979 24.0456 17.4604 23.4414L27.55 7.31019C27.8722 6.7463 28.3757 6.46436 29.0604 6.46436C29.7451 6.46436 30.2486 6.7463 30.5708 7.31019L40.6604 23.4414C41.0229 24.0456 41.033 24.6598 40.6906 25.2841C40.3483 25.9085 39.8146 26.2206 39.0896 26.2206H19.0312ZM42.6542 53.1664C39.6736 53.1664 37.1764 52.1595 35.1625 50.1456C33.1486 48.1317 32.1417 45.6345 32.1417 42.6539C32.1417 39.6734 33.1486 37.1762 35.1625 35.1623C37.1764 33.1484 39.6736 32.1414 42.6542 32.1414C45.6347 32.1414 48.1319 33.1484 50.1458 35.1623C52.1597 37.1762 53.1667 39.6734 53.1667 42.6539C53.1667 45.6345 52.1597 48.1317 50.1458 50.1456C48.1319 52.1595 45.6347 53.1664 42.6542 53.1664ZM9.0625 51.656C8.53889 51.656 8.1059 51.4848 7.76354 51.1425C7.42118 50.8001 7.25 50.3671 7.25 49.8435V35.1019C7.25 34.5782 7.42118 34.1453 7.76354 33.8029C8.1059 33.4605 8.53889 33.2894 9.0625 33.2894H23.8042C24.3278 33.2894 24.7608 33.4605 25.1031 33.8029C25.4455 34.1453 25.6167 34.5782 25.6167 35.1019V49.8435C25.6167 50.3671 25.4455 50.8001 25.1031 51.1425C24.7608 51.4848 24.3278 51.656 23.8042 51.656H9.0625Z"
										fill="#3d1e59"
									/>
								</svg>
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{getTranslation('brandCustomization')}
									</span>
								</div>
								<span className="text-gray5 text-m">
									{getTranslation('manageBrandTheme')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							<div
								className={`bg-black1 text-white justify-center flex flex-row px-6 py-1 rounded-lg cursor-pointer`}
								style={{ minWidth: '130px' }} // Adjust the width as needed
								onClick={() => redirectToBrandProfile()}
							>
								<span className="cursor-pointer">
									{getTranslation('customizeButton')}
								</span>
							</div>
						</div>
					</div>
					{/* Mine Row  */}
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<svg
									width="38"
									height="38"
									viewBox="0 0 58 58"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ marginTop: 5 }}
								>
									<path
										d="M19.0312 26.2206C18.3063 26.2206 17.7726 25.9085 17.4302 25.2841C17.0878 24.6598 17.0979 24.0456 17.4604 23.4414L27.55 7.31019C27.8722 6.7463 28.3757 6.46436 29.0604 6.46436C29.7451 6.46436 30.2486 6.7463 30.5708 7.31019L40.6604 23.4414C41.0229 24.0456 41.033 24.6598 40.6906 25.2841C40.3483 25.9085 39.8146 26.2206 39.0896 26.2206H19.0312ZM42.6542 53.1664C39.6736 53.1664 37.1764 52.1595 35.1625 50.1456C33.1486 48.1317 32.1417 45.6345 32.1417 42.6539C32.1417 39.6734 33.1486 37.1762 35.1625 35.1623C37.1764 33.1484 39.6736 32.1414 42.6542 32.1414C45.6347 32.1414 48.1319 33.1484 50.1458 35.1623C52.1597 37.1762 53.1667 39.6734 53.1667 42.6539C53.1667 45.6345 52.1597 48.1317 50.1458 50.1456C48.1319 52.1595 45.6347 53.1664 42.6542 53.1664ZM9.0625 51.656C8.53889 51.656 8.1059 51.4848 7.76354 51.1425C7.42118 50.8001 7.25 50.3671 7.25 49.8435V35.1019C7.25 34.5782 7.42118 34.1453 7.76354 33.8029C8.1059 33.4605 8.53889 33.2894 9.0625 33.2894H23.8042C24.3278 33.2894 24.7608 33.4605 25.1031 33.8029C25.4455 34.1453 25.6167 34.5782 25.6167 35.1019V49.8435C25.6167 50.3671 25.4455 50.8001 25.1031 51.1425C24.7608 51.4848 24.3278 51.656 23.8042 51.656H9.0625Z"
										fill="#3d1e59"
									/>
								</svg>
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{getTranslation('themeCustomization')}
									</span>
								</div>
								<span className="text-gray5 text-m">
									{getTranslation('manageBrandTheme')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							<div
								className={`bg-black1 text-white justify-center flex flex-row px-6 py-1 rounded-lg cursor-pointer`}
								style={{ minWidth: '130px' }} // Adjust the width as needed
								onClick={() => redirectToThemeSelector()}
							>
								<span className="cursor-pointer">
									{getTranslation('themeButton')}
								</span>
							</div>
						</div>
							</div></>}
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<svg
									width="38"
									height="38"
									viewBox="0 0 58 58"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ marginTop: 5 }}
								>
									<path
										d="M8.4585 48.3334C7.49183 48.3334 6.646 47.9709 5.921 47.2459C5.196 46.5209 4.8335 45.6751 4.8335 44.7084V13.2917C4.8335 12.3251 5.196 11.4792 5.921 10.7542C6.646 10.0292 7.49183 9.66675 8.4585 9.66675H49.5418C50.5085 9.66675 51.3543 10.0292 52.0793 10.7542C52.8043 11.4792 53.1668 12.3251 53.1668 13.2917V44.7084C53.1668 45.6751 52.8043 46.5209 52.0793 47.2459C51.3543 47.9709 50.5085 48.3334 49.5418 48.3334H8.4585ZM29.0002 29.7251C29.2016 29.7251 29.3727 29.6949 29.5137 29.6345C29.6547 29.574 29.8057 29.5036 29.9668 29.423L48.9377 17.0376C49.139 16.9167 49.2901 16.7556 49.3908 16.5542C49.4915 16.3529 49.5418 16.1313 49.5418 15.8897C49.5418 15.3661 49.3102 14.9532 48.847 14.6511C48.3838 14.349 47.9106 14.3591 47.4272 14.6813L29.0002 26.4626L10.6335 14.6813C10.1502 14.3591 9.66683 14.339 9.1835 14.6209C8.70016 14.9029 8.4585 15.3056 8.4585 15.8292C8.4585 16.0709 8.51891 16.3025 8.63975 16.524C8.76058 16.7456 8.90155 16.9167 9.06266 17.0376L28.0335 29.423C28.1946 29.5036 28.3456 29.574 28.4866 29.6345C28.6276 29.6949 28.7988 29.7251 29.0002 29.7251Z"
										fill="#3d1e59"
									/>
								</svg>
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{getTranslation('contactAndSocial')}
									</span>
								</div>
								<span className="text-gray5 text-sm">
									{getTranslation('manageContactInfo')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							<div
								className={`bg-black1 flex justify-center  flex-row px-6 py-1 text-white rounded-lg cursor-pointer`}
								style={{ minWidth: '130px' }} // Adjust the width as needed
								onClick={() => redirectToAdditionalInformation()}
							>
								<span className="cursor-pointer">
									{getTranslation('manageButton')}
								</span>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default BrandCustomize
