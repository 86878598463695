import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import * as React from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import './index.css'

export default function CustomDateTimePicker({
	placeholder = '',
	value = '',
	onlyDate = false,
	classes = '',
	label = '',
	width = 'normal',
	disabled = false,
	onChange = () => { },
	height = '!h-10'
}) {
	const handleChange = (v) => {
		onChange(
			`${v.year()}-${v.month() + 1}-${v.date()} ${v.hour()}:${v.minute()}`
		)
	}

	return (
		<div className={`custom-datepicker ${classes} ${width}`}>
			<LocalizationProvider dateAdapter={AdapterDayjs} className={height}>
				<DemoContainer
					components={['DateTimePicker', 'DatePicker']}
					className={height}
				>
					{onlyDate ? (
						<DatePicker
							label={label}
							value={value ? dayjs(value) : null}
							onChange={(v) => onChange(v.format('YYYY-MM-DD'))}
							className={height}
							placeholder={placeholder}
						/>
					) : (
						<DateTimePicker
							placeholder={placeholder}
								className={height}
							defaultValue={value ? dayjs(value) : null}
							onChange={(v) => handleChange(v)}
							disabled={disabled}
						/>
					)}
				</DemoContainer>
			</LocalizationProvider>
		</div>
	)
}
