import { API_CHAT } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class ChatService {
	static getChats = async (query) => {
		let api = {
			content_type: 'application/json',
			url: API_CHAT,
			method: 'GET',
			query,
		}
		return apiFactory(api, {})
	}

	static getChatUser = async (id) => {
		let api = {
			content_type: 'application/json',
			url: `${API_CHAT}/messages/${id}`,
			method: 'GET',
		}
		return apiFactory(api, {})
	}

	static startConversation = async (rawData) => {
		let api = {
			content_type: 'application/json',
			url: `${API_CHAT}/start-conversation`,
			method: 'POST',
		}
		return apiFactory(api, rawData)
	}

	static createConversation = async (payload) => {
		let api = {
			content_type: 'multipart/form-data',
			url: `${API_CHAT}/messages`,
			method: 'POST',
		}
		return apiFactory(api, payload)
	}
}
