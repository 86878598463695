import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import AccommodationVacationRentalWhiteIcon from '../../../../assets/svgs/v2-reservation-white.svg'
import { boxStyle } from '../../../../constants/types'
import accommodationService from '../../../../services/accommodationService'
import { bookingListText } from '../list'

const BookingModal = ({ open, handleClose, selectedRow }) => {
	const [loading, setLoading] = useState(false);

	const onDecline = () => {
		handleStatusChange("Cancelled");

	}

	const onConfirm = () => {
		handleStatusChange("Confirmed");
	}

	const handleStatusChange = (status) => {
		accommodationService
			.ChangeBookingStatus({ id: selectedRow?.id, status: status })
			.then((res) => {
				NotificationManager.success(
					`Booking has been ${status} successfully`,
					'Success',
					3000
				)
				setLoading(false)
			})
			.catch((err) => {
				console.error(err);
				NotificationManager.error('Failed changing Booking status')
				setLoading(false);
			});
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			className="booking-detail-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none !max-h-[70vh] overflow-y-auto">
				<div className="flex flex-row items-center px-024 pt-3 pb-2 gap-3">
					<div className="p-2 bg-gray7 rounded-full">
						<div className="bg-black1 p-2 rounded-full">
							<img
								src={AccommodationVacationRentalWhiteIcon}
								alt="icon"
								className="object-cover h-[23px] w-[23px]"
							/>
						</div>
					</div>
					<label className="text-20 font-semibold text-black1">
						{bookingListText('booking_details')}
					</label>
				</div>
				<div className="flex flex-col px-024 pb-3">
					<div className="mb-5 pt-0">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('rental_unit')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.rental_unit?.name}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('primary_guest')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={
								selectedRow?.guest?.name +
								', ' +
								selectedRow?.guest?.email +
								', ' +
								selectedRow?.guest?.phone
							}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('nr_guests')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.guest_nr}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('check_in_date')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.check_in_date}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('check_out_date')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.check_out_date}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('total_amount')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.total_amount}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('subtotal')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.subtotal}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('discount_price')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.discount_price}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('status')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.status}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('paid_with')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.paid_with}
							disabled
						/>
					</div>

					<div className="mb-5">
						<p className="!mt-0 !mb-2 !text-black1 font-medium text-base">
							{bookingListText('prepayment_amount')}
						</p>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							value={selectedRow?.prepayment_amount}
							disabled
						/>
					</div>
				</div>
				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
					<button
						className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onDecline}
					>
						{t('decline')}
					</button>
					<button
						className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onConfirm}
					>
						{loading ? <CircularProgress size={16} /> : t('confirm')}
					</button>
				</div>
			</Box>
		</Modal>
	)
}

export default BookingModal
