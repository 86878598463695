import React from "react";
import "./index.css";

const GeneralToolTip = (props) => {
  const { field, name, bgColor } = props;

  const style = {
    color: "white",
    listStyleType: "initial",
    backgroundColor: bgColor,
  };

  return (
    <div className="no-border">
      <div className="tooltip-body">
        <div className="tooltip-content" style={{ backgroundColor: bgColor }}>
          <ul>
            {name && (
              <li style={style} className="bold-text">
                <p>{name}</p>
              </li>
            )}
            <li style={style} className="bold-text">
              <p>{field}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GeneralToolTip;
