import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { devicesViewText } from '.'
import devicesIcon from '../../../assets/icons/deviceImage.png'
import CustomIconButton from '../../../components/common/IconButton'
import CustomMUISelect from '../../../components/custom/CustomSelect/custom-select'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'
import DeviceService from '../../../services/device-service'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { brandData, deviceStatusData, typeData } from './data'

export default function AddEditDeviceModal({
	selectedDevice,
	onClose,
	open,
	getDevices,
}) {
	const { short_code } = useSelector((state) => state.app)

	const initialValue = useMemo(() => {
		return {
			device_nickname: '',
			type: '',
			brand: '',
			location: '',
			description: '',
			device_status: '',
		}
	}, [])

	const [device, setDevice] = useState(initialValue)

	const { device_nickname, type, brand, location, device_status } = device

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (selectedDevice !== undefined) {
			setDevice(selectedDevice)
		} else {
			setDevice(initialValue)
		}
	}, [open, selectedDevice, initialValue])

	const onSubmit = () => {
		setLoading(true)

		const payload = {
			device_nickname,
			type,
			brand,
			location,
			device_status,
		}

		if (selectedDevice !== undefined) {
			DeviceService.updateDevice(payload, selectedDevice.id)
				.then(() => {
					setLoading(false)
					getDevices()
					setDevice(initialValue)
					identifyVenue(short_code)
					trackEvent('update device', {
						action_category: 'put',
						action_outcome: 'success',
						interaction_element: 'devices',
					})
					NotificationManager.success(
						'device has been updated successfully',
						'Success',
						3000
					)
					onClose()
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(
						err?.errors?.email?.[0] ??
							'An error occurred while updating the device.',
						'Error',
						3000
					)

					identifyVenue(short_code)
					trackEvent('update device', {
						action_category: 'put',
						action_outcome: 'error',
						interaction_element: 'devices',
					})
				})
		} else {
			DeviceService.createDevice(payload)
				.then(() => {
					setLoading(false)
					getDevices()
					setDevice(initialValue)
					identifyVenue(short_code)
					trackEvent('create device', {
						action_category: 'post',
						action_outcome: 'success',
						interaction_element: 'devices',
					})
					NotificationManager.success(
						'device has been added successfully',
						'Success',
						3000
					)
					onClose()
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(
						err?.errors?.email?.[0] ??
							'An error occurred while adding the device.',
						'Error',
						3000
					)

					identifyVenue(short_code)
					trackEvent('create device', {
						action_category: 'post',
						action_outcome: 'error',
						interaction_element: 'devices',
					})
				})
		}
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="class-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{ ...boxStyle, width: 600 }}
				className="outline-none px-024 pb-024"
			>
				<CustomIconButton
					px={false}
					icon={devicesIcon}
					title={
						selectedDevice?.id != null
							? devicesViewText('edit_device')
							: devicesViewText('add_device')
					}
				/>

				<div className="flex flex-col gap-4 py-0">
					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('device_nickname')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<TextField
							required
							size="small"
							value={device_nickname}
							placeholder={devicesViewText('enter_device_nickname')}
							onChange={(e) =>
								setDevice({ ...device, device_nickname: e.target.value })
							}
							margin="none"
							fullWidth
						/>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('type')}
							className="!my-0 !py-0 !text-black1 font-medium text-base"
						/>
						<CustomMUISelect
							width="w-full"
							placeholder="Enter device type"
							data={typeData}
							value={type}
							onChange={(e) => setDevice({ ...device, type: e.target.value })}
						/>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('brand')}
							className="!my-0 !py-0 !text-black1 font-medium text-base"
						/>
						<CustomMUISelect
							width="w-full"
							placeholder="Enter brand name"
							value={brand}
							data={brandData}
							onChange={(e) => setDevice({ ...device, brand: e.target.value })}
						/>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('location')}
							className="!mt-0 !mb-[6px] !py-0 !text-black1 font-medium text-base"
						/>
						<TextField
							size="small"
							required
							value={location}
							placeholder={devicesViewText('enter_location')}
							onChange={(e) =>
								setDevice({ ...device, location: e.target.value })
							}
							margin="none"
							fullWidth
						/>
					</div>

					<div className="flex flex-col py-0">
						<RInputDeclare
							value={devicesViewText('device_status')}
							className="!my-0 !py-0 !text-black1 font-medium text-base"
						/>
						<CustomMUISelect
							width="w-full"
							placeholder="Enter device status"
							value={device_status}
							data={deviceStatusData}
							onChange={(e) =>
								setDevice({ ...device, device_status: e.target.value })
							}
						/>
					</div>
				</div>

				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{devicesViewText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onSubmit}
					>
						{loading ? <CircularProgress size={16} /> : devicesViewText('save')}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
