import { Customized, Text } from "recharts";

export const XAxisLineChart = ({ x, y, payload, visibleTicksCount, index }) => {
  const rotateLabels = visibleTicksCount > 8;
  const textWidth = rotateLabels || visibleTicksCount - 1 === index ? 120 : 150;
  return (
    <CustomizedTextWithTooltip
      x={x}
      y={y}
      textWidth={textWidth}
      rotateLabel={rotateLabels}
      value={payload.value}
    />
  );
};

const getAttributeFromCondition = (conditon, first, second) => {
  return conditon ? first : second;
};

const CustomizedTextWithTooltip = ({ value, x, y, rotateLabel, textWidth }) => {
  return value ? (
    <Customized
      component={
        <>
          <title>{value}</title>
          <Text
            x={x}
            y={y}
            fontSize={getAttributeFromCondition(rotateLabel, 8, 10.5)}
            verticalAnchor="start"
            textAnchor={getAttributeFromCondition(rotateLabel, "end", "middle")}
            angle={getAttributeFromCondition(rotateLabel, -40, 0)}
            width={textWidth}
            maxLines={getAttributeFromCondition(rotateLabel, 1, 2)}
          >
            {value}
          </Text>
        </>
      }
    />
  ) : (
    <></>
  );
};

export const compactNumberFormatter = (value) =>
  new Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
  }).format(value);
