import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import DashboardService from '../../../services/dashboardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import UpgradedFromTrial from '../../../views/sales/upgradedFromTrial'
import { foodDeliveryText } from './FoodDeliveryAnalyticDashboard'

const FoodDeliveryDashboard = ({ showUpgradedFromTrialProp = false }) => {
	const { short_code } = useSelector((state) => state.app)

	const [netSalesData, setNetSalesData] = useState([])
	const [orders, setOrders] = useState([])
	const [topCategories, setTopCategories] = useState([])
	const [topProducts, setTopProducts] = useState([])

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [showUpgradedFromTrial, setShowUpgradedFromTrial] = useState(
		showUpgradedFromTrialProp
	)

	const netSalesOptions = {
		stroke: {
			show: true,
			colors: ['#543c7f'],
		},
		chart: {
			id: 'apexchart-01',
		},
		xaxis: {
			categories: [],
		},
		yaxis: {
			min: 0,
			labels: {
				formatter: (val) => `${netSalesData?.currency ?? ''} ${val.toFixed(0)}`,
			},
		},
		fill: {
			colors: ['#240b3b'],
		},
		dataLabels: {
			style: {
				colors: ['#00000000'],
			},
		},
	}

	const ordersOptions = {
		stroke: {
			show: true,
			colors: ['#543c7f'],
		},
		chart: {
			id: 'apexchart-01',
		},
		xaxis: {
			categories: [],
		},
		yaxis: {
			min: 0,
			labels: {
				formatter: (val) => `${orders?.currency ?? ''} ${val.toFixed(0)}`,
			},
		},
		fill: {
			colors: ['#240b3b'],
		},
		dataLabels: {
			style: {
				colors: ['#00000000'],
			},
		},
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		getNateSalesData()
		getOrdersData()
		getTopCategories()
		getTopProducts()
	}, [])

	const getNateSalesData = () => {
		let payload = {
			type: 'sales',
		}
		DashboardService.GetDashboard(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'dashboard',
				})
				setNetSalesData(res)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'dashboard',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const getOrdersData = () => {
		let payload = {
			type: 'orders',
		}
		DashboardService.GetDashboard(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'dashboard',
				})
				setOrders(res)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'dashboard',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const getTopCategories = () => {
		let payload = {
			type: 'categories',
		}
		DashboardService.GetDashboard(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'dashboard top categories',
				})
				setTopCategories(res)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'dashboard top categories',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const getTopProducts = () => {
		let payload = {
			type: 'products',
		}
		DashboardService.GetDashboard(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'dashboard top products',
				})
				setTopProducts(res)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'dashboard top products',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}
	return (
		<div
			className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 py-5">
						<span className="text-black1 text-xl font-medium">
							{foodDeliveryText('dashboard')}
						</span>
					</div>
					<div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-col grow bg-white rounded-12 px-0">
									<div className="flex flex-row justify-between items-baseline w-full border-b pl-6 shadow-sm py-6">
										<label className="font-medium text-xl">
											{foodDeliveryText('net_sales')}
										</label>
									</div>
									<div className="px-6">
										<Chart
											className="mt-4"
											options={{
												...netSalesOptions,
												xaxis: { categories: netSalesData?.xaxis?.categories },
											}}
											series={[
												{
													name: netSalesData?.series?.[0]?.name,
													data: netSalesData?.series?.[0]?.data,
												},
											]}
											type="line"
											width={'100%'}
											height={322}
										/>
									</div>
								</div>
							</CardContent>
						</Card>
						<Card className="mt-6">
							<CardContent className="!p-0">
								<div className="flex flex-col grow bg-white rounded-12 px-0">
									<div className="flex flex-row justify-between items-baseline w-full border-b pl-6 shadow-sm py-6">
										<label className="font-medium text-xl">
											{foodDeliveryText('orders')}
										</label>
									</div>
									<div className="px-6">
										<Chart
											className="mt-4"
											options={{
												...ordersOptions,
												xaxis: { categories: orders?.xaxis?.categories },
											}}
											series={[
												{
													name: orders?.series?.[0]?.name,
													data: orders?.series?.[0]?.data,
												},
											]}
											type="line"
											width={'100%'}
											height={322}
										/>
									</div>
								</div>
							</CardContent>
						</Card>
						<div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-5">
							<Card className="">
								<CardContent className="!p-0">
									<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
										<div className="flex flex-row items-center">
											<span className="text-black1 text-xl font-medium">
												{foodDeliveryText('top_menu')}
											</span>
										</div>
									</div>
									<div className="flex flex-col overflow-auto">
										<TableContainer component={Paper} className="mt-4">
											<Table aria-label="simple table">
												<TableHead className="bg-gray7">
													<TableRow>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															{foodDeliveryText('category')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															{foodDeliveryText('nr_orders')}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{topCategories.map((item, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																<b>{item.title}</b>
															</TableCell>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.order_count}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
											<TablePagination
												rowsPerPageOptions={[10, 25, 100]}
												component="div"
												count={topCategories.length}
												rowsPerPage={rowsPerPage}
												page={page}
												className="!p-2"
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</TableContainer>
									</div>
								</CardContent>
							</Card>
							<Card>
								<CardContent className="!p-0">
									<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
										<div className="flex flex-row items-center">
											<span className="text-black1 text-xl font-medium">
												{foodDeliveryText('top_products')}
											</span>
										</div>
									</div>
									<div className="flex flex-col overflow-auto">
										<TableContainer component={Paper} className="mt-4">
											<Table aria-label="simple table">
												<TableHead className="bg-gray7">
													<TableRow>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															{foodDeliveryText('product')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															{foodDeliveryText('nr_orders')}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{topProducts.map((item, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																<b>{item.title}</b>
															</TableCell>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.order_count}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
											<TablePagination
												rowsPerPageOptions={[10, 25, 100]}
												component="div"
												count={topProducts.length}
												rowsPerPage={rowsPerPage}
												page={page}
												className="!p-2"
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</TableContainer>
									</div>
								</CardContent>
							</Card>
						</div>
					</div>
				</CardContent>
			</Card>
			{showUpgradedFromTrial && (
				<UpgradedFromTrial
					showModal={showUpgradedFromTrial}
					onClose={() => setShowUpgradedFromTrial(false)}
				/>
			)}
		</div>
	)
}

export default FoodDeliveryDashboard
