import React, { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './index.css'

const CustomCalendar = ({ bookingsData = [] }) => {
	const [selectedDate, setSelectedDate] = useState(new Date())

	const tileClassName = ({ date, view }) => {
		if (view === 'month') {
			const dateStr = date.toISOString().split('T')[0]
			const booking = bookingsData.find((item) => item.date === dateStr)

			if (booking) {
				return 'highlight'
			} else if (date.toDateString() === new Date().toDateString()) {
				return 'today'
			}
		}
	}

	const onChange = (date) => {
		setSelectedDate(date)
	}

	return (
		<div className="calendar-container">
			<Calendar
				onChange={onChange}
				value={selectedDate}
				tileClassName={tileClassName}
			/>
		</div>
	)
}

export default CustomCalendar
