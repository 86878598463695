import moment from 'moment'
import React from 'react'
import { calcAverageOrderValue, calcGrowth } from '../../../../utils/common'

export const revenueIcon = (
	<svg
		width="57"
		height="58"
		viewBox="0 0 57 58"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.246094"
			y="0.844238"
			width="56.5641"
			height="56.5641"
			rx="11.2586"
			fill="#240B3B"
		/>
		<g clipPath="url(#clip0_499_2548)">
			<path
				d="M40.9714 20.2373H16.0825C15.611 20.2373 15.1588 20.4246 14.8254 20.758C14.492 21.0914 14.3047 21.5436 14.3047 22.0151V36.2373C14.3047 36.7088 14.492 37.161 14.8254 37.4944C15.1588 37.8278 15.611 38.0151 16.0825 38.0151H40.9714C41.4429 38.0151 41.895 37.8278 42.2284 37.4944C42.5618 37.161 42.7491 36.7088 42.7491 36.2373V22.0151C42.7491 21.5436 42.5618 21.0914 42.2284 20.758C41.895 20.4246 41.4429 20.2373 40.9714 20.2373ZM40.9714 25.5706C40.0614 25.4969 39.209 25.0961 38.5717 24.4425C37.9345 23.7889 37.5554 22.9266 37.5047 22.0151H40.9714V25.5706ZM40.9714 27.0106V31.304C39.6914 31.3808 38.4846 31.9263 37.5813 32.8362C36.6779 33.7462 36.1412 34.9569 36.0736 36.2373H20.9625C20.8974 34.9582 20.3641 33.7476 19.4642 32.8363C18.5643 31.9249 17.3607 31.3763 16.0825 31.2951V26.9929C17.3697 26.915 18.5824 26.3633 19.4869 25.4441C20.3913 24.5249 20.9233 23.3034 20.9802 22.0151H36.0914C36.1479 23.3005 36.6774 24.5197 37.5781 25.4385C38.4789 26.3572 39.6873 26.9108 40.9714 26.9929V27.0106ZM19.5491 22.0151C19.4984 22.9266 19.1194 23.7889 18.4821 24.4425C17.8448 25.0961 16.9924 25.4969 16.0825 25.5706V22.0151H19.5491ZM16.0825 32.7351C16.9838 32.8073 17.8293 33.2005 18.4654 33.8431C19.1016 34.4858 19.4861 35.3353 19.5491 36.2373H16.0825V32.7351ZM37.5047 36.2373C37.5678 35.3353 37.9523 34.4858 38.5884 33.8431C39.2245 33.2005 40.07 32.8073 40.9714 32.7351V36.2373H37.5047Z"
				fill="white"
			/>
			<path
				d="M28.5258 22.7705C25.4413 22.7705 22.9258 25.6238 22.9258 29.1261C22.9258 32.6283 25.4413 35.4816 28.5258 35.4816C31.6102 35.4816 34.1258 32.6283 34.1258 29.1261C34.1258 25.6238 31.6102 22.7705 28.5258 22.7705ZM28.5258 34.0505C26.2236 34.0505 24.348 31.8372 24.348 29.1172C24.348 26.3972 26.2236 24.1838 28.5258 24.1838C30.828 24.1838 32.7036 26.3972 32.7036 29.1172C32.7036 31.8372 30.828 34.0594 28.5258 34.0594V34.0505Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_499_2548">
				<rect
					width="32"
					height="32"
					fill="white"
					transform="translate(12.5273 13.1262)"
				/>
			</clipPath>
		</defs>
	</svg>
)

const noBooking = (
	<svg
		width="58"
		height="58"
		viewBox="0 0 58 58"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.8125"
			y="0.844238"
			width="56.5641"
			height="56.5641"
			rx="11.2586"
			fill="#240B3B"
		/>
		<path
			d="M18.4258 23.793C18.4258 20.0223 18.4258 18.1356 19.5978 16.965C20.7684 15.793 22.6551 15.793 26.4258 15.793H31.7591C35.5298 15.793 37.4164 15.793 38.5871 16.965C39.7591 18.1356 39.7591 20.0223 39.7591 23.793V34.4596C39.7591 38.2303 39.7591 40.117 38.5871 41.2876C37.4164 42.4596 35.5298 42.4596 31.7591 42.4596H26.4258C22.6551 42.4596 20.7684 42.4596 19.5978 41.2876C18.4258 40.117 18.4258 38.2303 18.4258 34.4596V23.793Z"
			stroke="white"
			strokeWidth="2"
		/>
		<path
			d="M39.6231 34.4597H23.6231C22.3831 34.4597 21.7631 34.4597 21.2538 34.5957C20.5755 34.7776 19.9571 35.1348 19.4607 35.6315C18.9643 36.1281 18.6073 36.7467 18.4258 37.4251"
			stroke="white"
			strokeWidth="2"
		/>
		<path
			d="M23.7578 22.4595H34.4245M23.7578 27.1261H30.4245"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

const totalBookings = (
	<svg
		width="57"
		height="58"
		viewBox="0 0 57 58"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.078125"
			y="0.844238"
			width="56.5641"
			height="56.5641"
			rx="11.2586"
			fill="#240B3B"
		/>
		<path
			d="M29.8414 18.6096H33.6914C34.3589 18.6096 34.999 18.8747 35.471 19.3467C35.9429 19.8186 36.2081 20.4588 36.2081 21.1262V38.4596C36.2081 39.127 35.9429 39.7671 35.471 40.2391C34.999 40.7111 34.3589 40.9762 33.6914 40.9762H21.6914C21.0239 40.9762 20.3838 40.7111 19.9119 40.2391C19.4399 39.7671 19.1747 39.127 19.1747 38.4596V21.1262C19.1747 20.4588 19.4399 19.8186 19.9119 19.3467C20.3483 18.9102 20.9287 18.6506 21.5414 18.614V27.3929V27.681L21.7774 27.5158L25.6914 24.776L29.6054 27.5158L29.8414 27.681V27.3929V18.6096ZM25.7774 22.8433L25.6914 22.7831L25.6054 22.8433L23.1747 24.5448V18.6096H28.2081V24.5448L25.7774 22.8433ZM33.6914 16.9762H21.6914C20.5908 16.9762 19.5352 17.4135 18.7569 18.1917C17.9786 18.97 17.5414 20.0256 17.5414 21.1262V38.4596C17.5414 39.5602 17.9786 40.6158 18.7569 41.394C19.5352 42.1723 20.5908 42.6096 21.6914 42.6096H33.6914C34.7921 42.6096 35.8476 42.1723 36.6259 41.394C37.4042 40.6158 37.8414 39.5602 37.8414 38.4596V21.1262C37.8414 20.0256 37.4042 18.97 36.6259 18.1917C35.8476 17.4135 34.7921 16.9762 33.6914 16.9762Z"
			fill="white"
			stroke="white"
			strokeWidth="0.3"
		/>
	</svg>
)

const guests = (
	<svg
		width="58"
		height="58"
		viewBox="0 0 58 58"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.503906"
			y="0.844238"
			width="56.5641"
			height="56.5641"
			rx="11.2586"
			fill="#240B3B"
		/>
		<g clipPath="url(#clip0_499_2566)">
			<path
				d="M28.7847 28.2374C30.0154 28.2374 31.2184 27.8725 32.2416 27.1888C33.2648 26.5051 34.0624 25.5333 34.5333 24.3963C35.0043 23.2594 35.1275 22.0083 34.8874 20.8013C34.6473 19.5943 34.0547 18.4856 33.1845 17.6154C32.3143 16.7452 31.2056 16.1526 29.9986 15.9125C28.7916 15.6724 27.5405 15.7957 26.4036 16.2666C25.2666 16.7376 24.2948 17.5351 23.6111 18.5583C22.9274 19.5816 22.5625 20.7846 22.5625 22.0152C22.5625 23.6654 23.2181 25.2481 24.3849 26.415C25.5518 27.5819 27.1345 28.2374 28.7847 28.2374ZM28.7847 17.5707C29.6638 17.5707 30.523 17.8314 31.2539 18.3198C31.9848 18.8081 32.5545 19.5023 32.8909 20.3144C33.2272 21.1265 33.3153 22.0201 33.1438 22.8823C32.9723 23.7444 32.549 24.5363 31.9274 25.1579C31.3059 25.7795 30.5139 26.2028 29.6518 26.3742C28.7897 26.5457 27.896 26.4577 27.0839 26.1213C26.2718 25.7849 25.5777 25.2153 25.0893 24.4844C24.6009 23.7535 24.3403 22.8942 24.3403 22.0152C24.3403 20.8365 24.8085 19.706 25.642 18.8725C26.4755 18.039 27.606 17.5707 28.7847 17.5707Z"
				fill="white"
			/>
			<path
				d="M39.8705 34.7884C38.445 33.2818 36.7272 32.0817 34.8221 31.2615C32.917 30.4413 30.8646 30.0183 28.7905 30.0183C26.7163 30.0183 24.664 30.4413 22.7589 31.2615C20.8538 32.0817 19.136 33.2818 17.7105 34.7884C17.4014 35.1185 17.2298 35.554 17.2305 36.0062V40.6818C17.2305 41.1533 17.4178 41.6054 17.7512 41.9388C18.0846 42.2722 18.5368 42.4595 19.0082 42.4595H38.5638C39.0353 42.4595 39.4875 42.2722 39.8209 41.9388C40.1543 41.6054 40.3416 41.1533 40.3416 40.6818V36.0062C40.3447 35.5552 40.1763 35.1199 39.8705 34.7884ZM38.5638 40.6818H19.0082V35.9973C20.268 34.6708 21.7845 33.6145 23.4655 32.8927C25.1464 32.1709 26.9567 31.7986 28.786 31.7986C30.6154 31.7986 32.4256 32.1709 34.1066 32.8927C35.7875 33.6145 37.304 34.6708 38.5638 35.9973V40.6818Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_499_2566">
				<rect
					width="32"
					height="32"
					fill="white"
					transform="translate(12.7852 13.1262)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export const revenueBlock = (data) => {
	if (data) {
		return [
			{
				title: 'Total Revenue',
				value: `${data.total_revenue}€`,
				icon: revenueIcon,
			},
			{
				title: 'No. Bookings',
				value: data.no_of_bookings,
				icon: noBooking,
			},
			{
				title: 'Today Bookings',
				value: data?.today_bookings,
				icon: totalBookings,
			},
			{
				title: 'Guests',
				value: data.guests?.length ?? 0,
				icon: guests,
			},
		]
	}
	return []
}

export const dataBlocks = (data) => {
	return [
		{
			title: 'Total number of clients',
			value: data?.num_clients ?? 0,
			growth: calcGrowth(data?.num_clients, data?.num_clients_last_month),
			icon: revenueIcon,
		},
		{
			title: 'Total number of orders',
			value: data?.num_orders ?? 0,
			growth: calcGrowth(data?.num_orders, data?.num_orders_last_month),
			icon: noBooking,
		},
		{
			title: 'Total sales amount',
			value: `${data?.sales_amount ?? 0} Lek`,
			growth: calcGrowth(data?.sales_amount, data?.sales_amount_last_month),
			icon: totalBookings,
		},
		{
			title: 'Average Order Value (AOV)',
			value: `${calcAverageOrderValue(data?.sales_amount, data?.num_orders)} Lek`,
			growth: calcGrowth(calcAverageOrderValue(data?.sales_amount, data?.num_orders), calcAverageOrderValue(data?.sales_amount_last_month, data?.num_orders_last_month)),
			icon: guests,
		},
	]
}


export const OrdersData = [
	{
		id: 1,
		order_num: '#3980',
		client: 'Ensya Rexihepi',
		date: '07/24/2024 18:32',
		payment: 'Cash',
		price: '500 Lek',
		status: 'new',
	},
	{
		id: 2,
		order_num: '#3981',
		client: 'Ensya Rexihepi',
		date: '07/25/2024 08:32',
		payment: 'Cash',
		price: '500 Lek',
		status: 'order_completed',
	},
	{
		id: 3,
		order_num: '#3982',
		client: 'Ensya Rexihepi',
		date: '07/25/2024 14:32',
		payment: 'Cash',
		price: '500 Lek',
		status: 'processing',
	},
	{
		id: 4,
		order_num: '#3983',
		client: 'Ensya Rexihepi',
		date: '07/27/2024 09:30',
		payment: 'Cash',
		price: '600 Lek',
		status: 'order_cancelled',
	},
	{
		id: 5,
		order_num: '#3984',
		client: 'Ensya Rexihepi',
		date: '07/27/2024 19:30',
		payment: 'Cash',
		price: '500 Lek',
		status: 'order_cancelled',
	},
	{
		id: 6,
		order_num: '#3985',
		client: 'Ensya Rexihepi',
		date: '07/28/2024 19:30',
		payment: 'Cash',
		price: '700 Lek',
		status: 'order_on_delivery',
	},
]

const orderTransformer = {
	new: { bgColor: '#f8dda7', textColor: '#94660c', readable: 'New' },
	processing: {
		bgColor: '#BCE8F1',
		textColor: '#31708F',
		readable: 'Processing',
	},
	order_cancelled: {
		bgColor: '#e5e5e5',
		textColor: '#777',
		readable: 'Cancelled',
	},
	order_completed: {
		bgColor: '#3C763D',
		textColor: '#fff',
		readable: 'Completed',
	},
	order_on_delivery: {
		bgColor: '#719571',
		textColor: '#fff',
		readable: 'On Delivery',
	},
}
export const OrdersColumns = [
	{ label: 'Order No.', field: 'order_number', align: 'left' },
	{ label: 'Client', field: 'customer_name', align: 'left' },
	{
		label: 'Date', field: 'updated_at', align: 'left',
		cellComponent: ({ data }) => moment(data).format('MMMM Do YYYY, h:mm:ss a'),
	},
	{ label: 'Payment', field: 'name', align: 'left' },
	{
		label: 'Total', field: 'total_amount', align: 'left',
		cellComponent: ({ data }) => `${data} Lek`,
	},
	{
		label: 'Status',
		field: 'status',
		align: 'left',
		cellComponent: (item) => {
			return (
				<div className='w-full flex justify-end ml-5'>
					<div
						style={{
							backgroundColor: `${orderTransformer[item.data]?.bgColor}`,
						}}
						className={`font-semibold text-[16px] text-cyan1 border align-center rounded-[10px] cursor-pointer px-[16px] w-[140px]`}
					>
						<span
							className={`flex justify-center items-center text-[14px] font-bold p-2`}
							style={{
								color: orderTransformer[item.data]?.textColor,
							}}
						>
							{orderTransformer[item.data]?.readable}
						</span>
					</div>
				</div>
			)
		},
	},
]

export const barChartList = [
	{ dataKey: 'value', name: 'Value', color: '#240B3B' },
]

export const ordersCityBarChartList = [
	{ dataKey: 'order_count', name: 'Order Count', color: '#eab606' },
]

export const ordersByCitys = [
	{ city: 'Berat', value: 100 },
	{ city: 'Elbasan', value: 300 },
	{ city: 'Gjirokaster', value: 200 },
	{ city: 'Korce', value: 600 },
	{ city: 'Lushnje', value: 450 },
	{ city: 'Permet', value: 90 },
	{ city: 'Peqin', value: 260 },
	{ city: 'Sarande', value: 560 },
]


export const BestSellerProductsData = [
	{
		id: 1,
		image_path: 'https://via.placeholder.com/600x600',
		name: "Toy's Delight white breakfast plate",
		brand: 'Villeroy & Boch',
		stock: 25,
	},
	{
		id: 2,
		image_path: 'https://via.placeholder.com/600x600',
		name: "Toy's Delight white dinner plate",
		brand: 'Villeroy & Boch',
		stock: 25,
	},
	{
		id: 3,
		image_path: 'https://via.placeholder.com/600x600',
		name: "Petite Fleur",
		brand: 'Villeroy & Boch',
		stock: 25,
	},
	{
		id: 4,
		image_path: 'https://via.placeholder.com/600x600',
		name: "Petite Fleur",
		brand: 'Villeroy & Boch',
		stock: 25,
	},
	{
		id: 5,
		image_path: 'https://via.placeholder.com/600x600',
		name: "Signature clear S",
		brand: 'Villeroy & Boch',
		stock: 25,
	},
]
