import { CircularProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useDispatch } from 'react-redux'
import 'react-time-picker/dist/TimePicker.css'
import saleIcon from '../../assets/svgs/sale_white.svg'
import { boxStyle } from '../../constants/types'
import LoginService from '../../services/loginService'
import { getUserProfile } from '../../store/actions/auth'
import { RInputDeclare } from '../custom/RInputDeclare'

export const emailChangeCodeText = (text) =>
	t(`components.account.EmailChangeCodeModal.${text}`)

export default function EmailChangeCodeModal({ showModal, onClose }) {
	const dispatch = useDispatch()
	const [visible, setVisible] = useState(false)
	const [code, setCode] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setVisible(showModal)
		setCode('')
	}, [showModal])

	const onSubmit = () => {
		const payload = {
			code: code,
		}

		setLoading(true)
		LoginService.verifyChangeEmail(payload)
			.then((data) => {
				setLoading(false)
				dispatch(getUserProfile())
				onClose(true)
				NotificationManager.success(
					'Email has been updated successfully',
					'Success',
					3000
				)
			})
			.catch((error) => {
				setLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem updating email during the change of email')
			})
	}

	return (
		<div>
			<Modal open={visible} onClose={onClose} className="verifycode-modal">
				<Box sx={boxStyle} className="outline-none max-w-[460px]">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={saleIcon} alt={emailChangeCodeText('alt_icon')} />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{emailChangeCodeText('email_verification')}
						</label>
					</div>
					<div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
						{emailChangeCodeText('verification_message')}
					</div>
					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<RInputDeclare
									value={emailChangeCodeText('verification_code')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									value={code}
									placeholder={emailChangeCodeText('enter_code')}
									onChange={(e) => setCode(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{emailChangeCodeText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								emailChangeCodeText('submit')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
