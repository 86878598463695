import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, MenuItem, Modal, TextField } from '@mui/material'
import { useFormik } from 'formik';
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from 'react-redux'
import * as yup from "yup";
import creditIcon from "../../../../assets/svgs/credit-card.svg";
import uploadIcon from "../../../../assets/svgs/upload.svg";
import { RInputDeclare } from "../../../../components/custom/RInputDeclare";
import { boxStyle } from "../../../../constants/types";
import accommodationService from "../../../../services/accommodationService";
import { isEmpty } from "../../../../utils/common";
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'

export const availabilityModalText = (text) =>
	t(`accommodation.rental_unit.show.availabilityModal.${text}`)

const types = [
  { title: "Airbnb", value: "Airbnb" },
  { title: "Booking.com", value: "Booking.com" },
  { title: "VRBO", value: "VRBO" },
];

function AvailabilityModal({ showModal, setShowModal, rentalUnitId, data }) {
  	const { short_code } = useSelector((state) => state.app)

		const avalibalityModal = useFormik({
			initialValues: {
				title: null,
				description: null,
				type: null,
				file: null,
				venue_id: null,
				rental_unit_id: null,
				url: null,
			},
			validationSchema: yup.object().shape({
				title: yup.string().trim().required('The title field is required.'),
				type: yup.string().trim().required('The type field is required.'),
				description: yup
					.string()
					.trim()
					.required('The description field is required.'),
				// file: yup.mixed()
			}),
			onSubmit: (values) => availabilityModalSubmit(values),
			validateOnChange: false,
			validateOnBlur: true,
			validateOnMount: false,
		})
		useEffect(() => {
			if (data?.accommodation_details) {
				avalibalityModal.setFieldValue(
					'venue_id',
					data?.accommodation_details?.venue_id
				)
				avalibalityModal.setFieldValue(
					'rental_unit_id',
					data?.accommodation_details?.rental_unit_id ?? 1
				)
			}
		}, [data])

		const availabilityModalSubmit = (values) => {
			const details = {
				title: values.title,
				description: values.description,
				type: values.type,
				file: values.file,
				url: values.url,
				venue_id: values.venue_id,
				rental_unit_id: values.rental_unit_id,
			}

			accommodationService
				.StoreThirdPartyBooking(details, rentalUnitId)
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('Party booking', {
						action_category: 'get third party booking',
						action_outcome: 'success',
						interaction_element: 'booking',
					})
					setShowModal(false)
					toast.success('File Upload Successfully', {
						style: {
							backgroundColor: '#4CAF50', // green color for background
							color: '#FFFFFF', // white color for text
						},
						duration: 5000,
					})
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('Party booking', {
						action_category: 'get third party booking',
						action_outcome: 'fail',
						interaction_element: 'booking',
					})
					setShowModal(false)
					toast.error('There is an error occured with file Upload', {
						style: {
							backgroundColor: '#ff0000ee', // green color for background
							color: '#FFFFFF', // white color for text
						},
						duration: 5000,
					})
					throw new Error('There is an error occured with file Upload')
				})
		}
  const [showUpload, setShowUpload] = useState(false);
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      avalibalityModal.setFieldValue("file", event.target.files[0]);
      setShowUpload(true);
    } else {
      // If no file selected, clear the 'file' field
      avalibalityModal.setFieldValue("file", null);
      setShowUpload(false);
    }
  };
  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const handleCloseModel = () => {
    setShowModal(false);
  };
  const handleUploadChange = (event) => {
    const selectedValue = event.target.value;
    avalibalityModal.setFieldValue(
      "type",
      event.target.value
    );
    if (selectedValue) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  };

  return (
		<>
			<div>
				<Modal
					open={showModal}
					onClose={handleCloseModel}
					className="category-modal"
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={boxStyle} className="outline-none h-auto min-h-[8rem]">
						<div className="flex flex-row items-center justify-between gap-3 px-024 pb-016 pt-024">
							<div className="flex flex-row items-center gap-3">
								<div className="p-2 rounded-full bg-gray7">
									<div className="p-2 rounded-full bg-black1">
										<img src={creditIcon} alt="icon" />
									</div>
								</div>
								<label className="font-semibold text-20 text-black1">
									{availabilityModalText('sync_calendar')}
								</label>
							</div>
							<CloseIcon
								onClick={handleCloseModel}
								style={{ cursor: 'pointer' }}
							/>
						</div>

						<div className="flex flex-col pt-0 p-024 mb-0">
							<RInputDeclare
								value={availabilityModalText('title')}
								className="!mt-0 !mb-2 !text-black1 font-medium text-base"
							/>
							<div className="flex ">
								<TextField
									required
									size="small"
									placeholder={availabilityModalText('enter_title')}
									value={avalibalityModal.values.title}
									error={avalibalityModal.errors.title}
									className="flex flex-1"
									onChange={(e) => {
										avalibalityModal.setFieldValue('title', e.target.value)
									}}
								/>
							</div>
							{!isEmpty(avalibalityModal.errors.title) && (
								<div className="mt-2 text-red-600 text-12">
									{avalibalityModal.errors.title}
								</div>
							)}
							<RInputDeclare
								value={availabilityModalText('description')}
								className="!mt-4 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								required
								size="small"
								multiline
								minRows={3}
								value={avalibalityModal.values.description}
								error={avalibalityModal.errors.description}
								placeholder={availabilityModalText('enter_description')}
								onChange={(e) => {
									avalibalityModal.setFieldValue('description', e.target.value)
								}}
							/>
							{!isEmpty(avalibalityModal.errors.description) && (
								<div className="mt-2 text-red-600 text-12">
									{avalibalityModal.errors.description}
								</div>
							)}
						</div>
						<div className="px-6 mb-5">
							<RInputDeclare
								value={availabilityModalText('type')}
								className="!mt-4 !mb-2 !text-black1 font-medium text-base"
							/>
							<div className="flex flex-col mt-2">
								<TextField
									id="outlined-select"
									size="small"
									error={avalibalityModal.errors.type}
									select
									value={avalibalityModal.values.type}
									onChange={handleUploadChange}
								>
									{types.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.title}
										</MenuItem>
									))}
								</TextField>
								{!isEmpty(avalibalityModal.errors.type) && (
									<div className="mt-2 text-red-600 text-12">
										{avalibalityModal.errors.type}
									</div>
								)}
							</div>
						</div>
						<div className="flex flex-row justify-between gap-4 px-6">
							<input
								type="file"
								ref={fileInputRef}
								style={{ display: 'none' }}
								onChange={handleFileChange}
								accept=".ics"
							/>
							{showUpload && (
								<div className={'w-full '}>
									<TextField
										className="mb-2 w-full flex flex-row justify-between gap-4"
										size="small"
										placeholder={availabilityModalText('enter_ics_file_url')}
										value={avalibalityModal.values.url}
										onChange={(e) => {
											avalibalityModal.setFieldValue('url', e.target.value)
										}}
									/>
									<div className="h-[6.5rem] mt-4 w-full flex flex-row justify-between gap-4">
										<div
											className={`h-full w-full flex flex-col cursor-pointer justify-center !pt-5 !pb-3 items-center relative border border-gray-300 rounded-lg`}
										>
											<IconButton
												className="!bg-black1 p-2"
												onClick={handleIconClick}
											>
												<img
													src={uploadIcon}
													alt="icon"
													style={{ width: '24px', height: '24px' }}
												/>
											</IconButton>
											<span className="py-2 text-base text-center text-black1">
												{avalibalityModal.values.file
													? avalibalityModal.values.file.name
													: availabilityModalText('upload_ics_file')}
											</span>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="gap-4 px-6">
							{avalibalityModal.values.type && (
								<>
									{!isEmpty(avalibalityModal.errors.file) && (
										<div className="mt-2 text-red-600 text-12">
											{avalibalityModal.errors.file}
										</div>
									)}
								</>
							)}
						</div>
						<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
							<button
								onClick={avalibalityModal.handleSubmit}
								className="flex items-center justify-center h-full font-bold text-white rounded-lg bg-cyan1 grow"
							>
								{availabilityModalText('upload')}
							</button>
						</div>
					</Box>
				</Modal>
				<Toaster position="top-right" />
			</div>
		</>
	)
} 

export default AvailabilityModal;