import { CircularProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/credit-card.svg'
import { boxStyle } from '../../constants/types'
import VenueService from '../../services/venuService'
import { RInputDeclare } from '../custom/RInputDeclare'

const getTranslation = (text) =>
	t(`components.subscriptions.subscriptionContactSales.${text}`)

const alCityOptions = [{ name: 'Tirane', value: 'Tirane' }]

const cityOptions = [
	{ name: 'Manhattan', value: 'Manhattan' },
	{ name: 'New York City', value: 'New York City' },
	{ name: 'Brooklyn', value: 'Brooklyn' },
	{ name: 'Queens', value: 'Queens' },
	{ name: 'The Bronx', value: 'The Bronx' },
	{ name: 'Staten Island', value: 'Staten Island' },
	{ name: 'Albany', value: 'Albany' },
	{ name: 'Buffalo', value: 'Buffalo' },
	{ name: 'Rochester', value: 'Rochester' },
	{ name: 'Syracuse', value: 'Syracuse' },
	{ name: 'Yonkers', value: 'Yonkers' },
	{ name: 'White Plains', value: 'White Plains' },
	{ name: 'Albion', value: 'Albion' },
	{ name: 'Alden', value: 'Alden' },
	{ name: 'Amherst', value: 'Amherst' },
	{ name: 'Arcade', value: 'Arcade' },
	{ name: 'Attica', value: 'Attica' },
	{ name: 'Avon', value: 'Avon' },
	{ name: 'Batavia', value: 'Batavia' },
	{ name: 'Blasdell', value: 'Blasdell' },
	// Add the remaining cities here
]

const industryList = [
	{ value: 'Food', title: 'Food & Beverage' },
	{ value: 'Sport & Entertainment', title: 'Entertainment Venues' },
	{ value: 'Accommodation', title: 'Accommodation' },
	{ value: 'Retail', title: 'Retail Management' },
	// { value: "Healthcare", title: "Healthcare" },
]

const categoryList = {
	Food: [
		{ value: 'restaurant', title: 'Restaurant' },
		{ value: 'bistro', title: 'Bistro' },
		{ value: 'cafe', title: 'Cafe' },
		{ value: 'bar', title: 'Bar' },
		{ value: 'pub_club', title: 'Pub & Club' },
		{ value: 'food_truck', title: 'Food Truck' },
		{ value: 'fine_dining', title: 'Fine Dining' },
		{ value: 'bakery', title: 'Bakery' },
		{ value: 'catering_service', title: 'Catering Service' },
		{ value: 'fast_food', title: 'Fast Food' },
		{ value: 'ice_cream_parlor', title: 'Ice Cream Parlor' },
		{ value: 'food_stall', title: 'Food Stall' },
		{ value: 'buffet', title: 'Buffet' },
		{ value: 'pop_up_dining', title: 'Pop-Up Dining' },
		{ value: 'juice_bar', title: 'Juice Bar' },
		{ value: 'food_hall', title: 'Food Hall' },
		{ value: 'supper_club', title: 'Supper Club' },
		{ value: 'pizzeria', title: 'Pizzeria' },
		{ value: 'coffee_shop', title: 'Coffee Shop' },
		{ value: 'delis', title: 'Delis' },
		{ value: 'food_court', title: 'Food Court' },
	],
	'Sport & Entertainment': [
		{ value: 'golf_venue', title: 'Golf Course' },
		{ value: 'bowling', title: ' Bowling Alley' },
		{ value: 'gym', title: 'Gym & Fitness Center' },
		{ value: 'arcade_and_game_center', title: 'Arcade & Game Center' },
		{ value: 'sports_arena', title: 'Sports Arena' },
		{ value: 'concert_hall_and_theater', title: 'Concert Hall & Theater' },
		{ value: 'amusement_and_theme_park', title: 'Amusement & Theme Park' },
		{ value: 'ski_resort', title: 'Ski Resort' },
		{ value: 'museum', title: 'Museum' },
		{ value: 'cinema', title: 'Cinema' },
	],
	Accommodation: [
		{ value: 'hotel', title: 'Hotel' },
		{ value: 'cozy_retreat', title: 'Cozy Retreat' },
		{ value: 'hostel', title: 'Hostel' },
		{ value: 'hotel_chain', title: 'Hotel Chain' },
		{ value: 'vacation_rental', title: 'Vacation Rental' },
		{ value: 'luxury_resort', title: ' Luxury Resort' },
		{ value: 'family_resort', title: 'Family Resorts' },
		{ value: 'service_apartment', title: 'Service Apartment' },
		{ value: 'bed_and_breakfast', title: 'Bed and Breakfast' },
		{ value: 'motel', title: 'Motel' },
		{ value: 'capsule_hotel', title: 'Capsule Hotel' },
		{ value: 'youth_hostel', title: 'Youth Hostel' },
		{ value: 'campground', title: 'Campground' },
		{ value: 'rv_park', title: 'RV Park' },
	],
	Retail: [
		{ value: 'sport_essentials', title: 'Sporting Goods Store' },
		{ value: 'home_decor', title: 'Home Décor Store' },
		{ value: 'fashion_threads', title: 'Fashion Boutique' },
		{ value: 'retail_chain', title: 'Retail Chain' },
		{ value: 'specialty_store', title: 'Specialty Store' },
		{ value: 'online_shop', title: 'Online Shop' },
		{ value: 'grocery_store', title: 'Grocery Store' },
		{ value: 'electronics_store', title: 'Electronics Store' },
		{ value: 'pharmacy', title: 'Pharmacy' },
		{ value: 'auto_motorcycle_parts', title: 'Auto Parts Store' },
		{ value: 'liquor_store', title: 'Liquor Store' },
		{ value: 'pet_store', title: 'Pet Store' },
	],
	// Healthcare: [
	//     { value: "hospital", title: "Hospital" },
	//     { value: "dental_clinic", title: "Dental Clinic" },
	// ],
}

export default function SubscriptionContactSales({
	showModal,
	onConfirm,
	onClose,
	data,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [loading, setLoading] = useState(false)

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [contactEmail, setContactEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [howCanHelpYou, setHowCanHelpYou] = useState('')

	useEffect(() => {
		if (showModal) {
			setCheckValid(false)
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		// validate

		if (!firstName) {
			NotificationManager.info('Please enter first name')
			return
		}

		if (!lastName) {
			NotificationManager.info('Please enter last name')
			return
		}

		if (!contactEmail) {
			NotificationManager.info('Please enter contact email')
			return
		}

		if (contactEmail && firstName && lastName) {
			let payload = {
				contact_email: contactEmail,
				first_name: firstName,
				last_name: lastName,
				phone: phone,
			}
			setLoading(true)
			VenueService.CustomPricingContactSales(payload)
				.then(({ data }) => {
					setLoading(false)
					onClose()
					NotificationManager.success(
						data?.message ??
							'Thank you for your interested in our Enterprise Plan. Our team will contact you soon to discuss custom and tailored pricing options.'
					)
				})
				.catch((err) => {
					setLoading(false)
					if (typeof err?.error === 'string') {
						NotificationManager.error(err?.error)
					} else {
						const errorKeys = Object.keys(err?.error || {})
						if (errorKeys.length > 0) {
							const firstErrorKey = errorKeys[0]
							const firstErrorMessage = err?.error[firstErrorKey][0]
							NotificationManager.error(
								firstErrorMessage || 'Something went wrong!'
							)
						} else {
							NotificationManager.error('Something went wrong!')
						}
					}
				})
		} else {
			setCheckValid(true)
		}
	}
	const onCloseModal = () => {
		onClose(null)
	}
	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{getTranslation('contactUs')}
						</label>
					</div>
					<div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
						{getTranslation('contactConsultingTeam')}
					</div>
					<div className="flex flex-col p-024">
						<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<div className="!mt-0 !mb-2">
									<RInputDeclare
										value={getTranslation('firstName')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<span style={{ color: '#F44336', marginLeft: '2px' }}>*</span>
								</div>
								<TextField
									required
									type="text"
									size="small"
									error={checkValid && !firstName}
									id="filled-required"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									inputProps={{}}
								/>
							</div>
							<div className="flex flex-col">
								<div className="!mt-0 !mb-2">
									<RInputDeclare
										value={getTranslation('lastName')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<span style={{ color: '#F44336', marginLeft: '2px' }}>*</span>
								</div>
								<TextField
									required
									type="text"
									size="small"
									error={checkValid && !lastName}
									id="filled-required"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									inputProps={{}}
								/>
							</div>
						</div>
						<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
							<div className="flex flex-col mt-4">
								<div className="!mt-0 !mb-2">
									<RInputDeclare
										value={getTranslation('email')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<span style={{ color: '#F44336', marginLeft: '2px' }}>*</span>
								</div>
								<TextField
									required
									type="text"
									size="small"
									error={checkValid && !contactEmail}
									id="filled-required"
									value={contactEmail}
									onChange={(e) => setContactEmail(e.target.value)}
									inputProps={{}}
								/>
							</div>
							<div className="flex flex-col">
								<RInputDeclare
									value={getTranslation('phone')}
									className="!mt-4 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									type="text"
									size="small"
									error={checkValid && !phone}
									id="filled-required"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									inputProps={{}}
								/>
							</div>
						</div>
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<RInputDeclare
									value={getTranslation('howCanOurTeamHelpYou')}
									className="!mt-4 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									type="text"
									size="small"
									multiline={true}
									minRows={4}
									error={checkValid && !howCanHelpYou}
									id="filled-required"
									value={howCanHelpYou}
									onChange={(e) => setHowCanHelpYou(e.target.value)}
									inputProps={{}}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCloseModal}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								getTranslation('submit')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
