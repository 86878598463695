import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Svg_close from '../../../assets/svgs/close_sm.svg'

import { boxStyle } from '../../../constants/types'

import { t } from 'i18next'
import moment from 'moment'
import calendarIcon from '../../../assets/svgs/calendar_gray.svg'
import discountIcon from '../../../assets/svgs/discount.svg'
import reservationIcon from '../../../assets/svgs/reservation.svg'
import typeIcon from '../../../assets/svgs/type.svg'
import valueIcon from '../../../assets/svgs/value.svg'

export const discountViewModalText = (text) =>
	t(`marketing.promotions.DiscountViewModal.${text}`)

export default function DiscountViewModal({ showModal, data, onClose }) {
	const [visible, setVisible] = useState(false)
	const [state, setState] = useState({})

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
			}
		}
		setVisible(showModal)
	}, [showModal])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none p-8">
					<div
						className={`flex flex-col ${
							data?.promotion && 'border-b border-0 border-gray-400 mb-5 pb-7'
						}`}
					>
						<div className="flex flex-row justify-between">
							<div>
								<label className="text-xl font-medium text-black1 mr-5">
									{discountViewModalText('discount_no')} {data?.id}{' '}
									{discountViewModalText('details')}
								</label>
								<label className="text-base text-black1 bg-gray2 ml-2 px-3 py-1 rounded-full">
									{data?.promotion
										? discountViewModalText('linked_with_promo')
										: discountViewModalText('not_linked')}
								</label>
							</div>
							<div
								className="bg-gray2 p-2 rounded-full cursor-pointer"
								onClick={onClose}
							>
								<img src={Svg_close} alt="" />
							</div>
						</div>
						<span className="text-gray5 text-base">
							{data?.promotion
								? discountViewModalText('linked')
								: discountViewModalText('not_linked')}
						</span>
						<div className="grid grid-cols-2">
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={typeIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{discountViewModalText('type')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.type === 'fixed'
											? discountViewModalText('fixed')
											: discountViewModalText('percentage')}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={valueIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{discountViewModalText('value')}
									</span>
									<span className="text-gray-500 text-sm">
										{data.type === 'fixed' ? '$' : '%'} {data.value}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={calendarIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{discountViewModalText('start_time')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.start_time
											? moment(data.start_time).format(
													discountViewModalText('date_format')
											  )
											: ''}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={calendarIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{discountViewModalText('end_time')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.end_time
											? moment(data.end_time).format(
													discountViewModalText('date_format')
											  )
											: ''}
									</span>
								</div>
							</div>
						</div>
						<div className="flex flex-row items-center gap-4 mt-5">
							<div className="p-2 bg-gray2 rounded-lg">
								<img src={reservationIcon} alt="icon" />
							</div>
							<div className="flex flex-col">
								<span className="text-gray-700 font-medium text-base">
									{discountViewModalText('reservation_count')}
								</span>
								<span className="text-gray-500 text-sm">
									{data?.reservation_count}
								</span>
							</div>
						</div>
					</div>
					{data?.promotion && (
						<div className="flex flex-col mt-5">
							<div className="flex flex-row justify-between">
								<div>
									<label className="text-xl font-medium text-black1 mr-5">
										{discountViewModalText('promo')} {data?.promotion?.id}
									</label>
								</div>
							</div>
							<div className="grid grid-cols-2">
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={typeIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{discountViewModalText('title')}
										</span>
										<span className="text-gray-500 text-sm">
											{data?.promotion?.title}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={discountIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{discountViewModalText('discount')}
										</span>
										<span className="text-gray-500 text-sm">ID#{data?.id}</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={calendarIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{discountViewModalText('start_time')}
										</span>
										<span className="text-gray-500 text-sm">
											{data?.promotion?.start_time
												? moment(data?.promotion?.start_time).format(
														discountViewModalText('date_format')
												  )
												: ''}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={calendarIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{discountViewModalText('end_time')}
										</span>
										<span className="text-gray-500 text-sm">
											{data?.promotion?.end_time
												? moment(data?.promotion?.end_time).format(
														discountViewModalText('date_format')
												  )
												: ''}
										</span>
									</div>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{discountViewModalText('description')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.promotion?.description}
									</span>
								</div>
							</div>
						</div>
					)}
				</Box>
			</Modal>
		</div>
	)
}
