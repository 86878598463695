import { Card, CardContent } from '@mui/material'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { promotionsText } from '.'
import plusIcon from '../../../assets/svgs/plus.svg'
import searchIcon from '../../../assets/svgs/search.svg'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import PromotionModal from './PromotionModal'
import PromotionViewModal from './PromotionViewModal'

const PromotionsTable = ({
	promoData = [],
	_curPromotion,
	venueType,
	couponData,
	refreshData,
	discountData = [],
}) => {
	const [showModal, setShowModal] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [viewModal, setViewModal] = useState(false)

	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	const goToCalendar = () => {
		navigate(`/${short_code}/admin/marketing/promotions/calendar`)
	}

	const handleEdit = (row) => {
		_curPromotion.current = row
		setShowModal(true)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const columns = [
		{ label: promotionsText('no'), field: 'no', align: 'left' },
		{ label: promotionsText('title'), field: 'title', align: 'left' },
		{
			label: promotionsText('description'),
			field: 'description',
			align: 'left',
		},
		{
			label: promotionsText('coupon'),
			field: 'coupon',
			align: 'left',
			visible: [
				'restaurant',
				'sport_essentials',
				'pharmacy',
				'home_decor',
				'fashion_threads',
			].includes(venueType),
		},
		{ label: promotionsText('discount'), field: 'discount', align: 'left' },
		{ label: promotionsText('start_date'), field: 'start_date', align: 'left' },
		{ label: promotionsText('end_date'), field: 'end_date', align: 'left' },
		{ label: promotionsText('actions'), field: 'actions', align: 'center' },
	]

	const mappedData = promoData.map((row, index) => ({
		...row,
		no: index + 1,
		title: row.title,
		description: row.description,
		coupon: row.coupons[0]?.id ? `ID-C#${row.coupons[0]?.id}` : 'N/A',
		discount: row.discounts[0]?.id ? `ID-D#${row.discounts[0]?.id}` : '-',
		start_date: moment(row.start_time).format(promotionsText('date_format')),
		end_date: moment(row.end_time).format(promotionsText('date_format')),
		actions: row,
	}))

	const displayedData = useMemo(() => {
		const start = page * rowsPerPage
		const end = start + rowsPerPage
		return mappedData.slice(start, end)
	}, [mappedData, page, rowsPerPage])

	return (
		<Card className="max-h-[80vh]">
			<CardContent className="!p-0">
				<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
					<div className="flex flex-row items-center">
						<span className="text-black1 text-xl font-medium">
							{promotionsText('promotions')}
						</span>
						<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
							{promoData.length} {promotionsText('promotions')}
						</span>
					</div>
					<div className="flex flex-row justify-start items-center gap-4">
						<div
							className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
							onClick={goToCalendar}
						>
							<span>{promotionsText('availability')}</span>
						</div>
						<div
							className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
							onClick={() => {
								_curPromotion.current = null
								setShowModal(true)
							}}
						>
							<img
								src={plusIcon}
								alt={promotionsText('plus')}
								className="mr-2"
							/>
							<span>{promotionsText('add_new_promotion')}</span>
						</div>
					</div>
				</div>
				{promoData.length > 0 ? (
					<>
						<GenericTable
							data={displayedData}
							columns={columns}
							onEdit={handleEdit}
						/>
						<GenericPagination
							count={promoData.length}
							page={page}
							rowsPerPage={rowsPerPage}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							setPage={setPage}
						/>
					</>
				) : (
					<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
						<div className="bg-gray7 !p-2 rounded-full">
							<div className="bg-black1 !p-2 rounded-full">
								<img src={searchIcon} alt={promotionsText('search')} />
							</div>
						</div>
						<span className="text-black1 text-lg">
							{promotionsText('no_promotions_found')}
						</span>
						<span className="text-gray5 text-base mt-1">
							{promotionsText('no_promotions_description')}
						</span>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer mt-4"
							onClick={() => {
								_curPromotion.current = null
								setShowModal(true)
							}}
						>
							<img
								src={plusIcon}
								alt={promotionsText('plus')}
								className="mr-2"
							/>
							<span>{promotionsText('add_new_promotion')}</span>
						</div>
					</div>
				)}
			</CardContent>

			{showModal && (
				<PromotionModal
					data={_curPromotion.current}
					showModal={showModal}
					coupons={
						couponData.length
							? couponData
									.filter((item) => item.status === 1)
									.map((item) => ({
										label: `ID-C#${item?.id}`,
										value: `ID-C#${item?.id}`,
									}))
							: []
					}
					discounts={
						discountData.length > 0
							? discountData
									.filter((item) => item.status === 1)
									.map((item) => ({
										label: `ID-D#${item?.id}`,
										value: `ID-D#${item?.id}`,
									}))
							: []
					}
					onClose={(refresh) => {
						if (refresh === true) {
							refreshData()
						}
						setShowModal(false)
					}}
				/>
			)}

			{viewModal && (
				<PromotionViewModal
					data={_curPromotion.current}
					showModal={viewModal}
					onClose={() => setViewModal(false)}
				/>
			)}
		</Card>
	)
}

export default PromotionsTable
