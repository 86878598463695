import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { Box, IconButton, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { boxStyle } from '../../constants/types'
import MenuService from '../../services/menuService'
import CustomIconButton from '../common/IconButton'
import CustomSelect from '../custom/CustomSelect'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import calendarIcon from '../../assets/svgs/calendar_white.svg'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'

const assignOrderModalText = (text) =>
	t(`components.reservation.AssignOrderModal.${text}`)

const ProductQuantity = ({ data, onChange, onRemove }) => {
	return (
		<div className="flex flex-row items-center justify-between w-full my-2">
			<div className="flex flex-row items-center">
				{/* <img src={`https://core.sn-boost.com/storage/${data?.image_path}`} alt="product" /> */}
				<img
					src={data?.image_path}
					alt="product"
					className="w-11 h-11 rounded-xl mr-4"
				/>
				<div className="flex flex-col">
					<label className="text-black text-sm font-bold w-full mb-1">
						{data?.title}
					</label>
					<label className="text-black text-sm font-normal w-full">
						${data?.price} x{' '}
					</label>
				</div>
			</div>
			<div className="">
				{data?.quantity > 0 && (
					<>
						<IconButton
							style={{ backgroundColor: 'var(--color-neutral)' }}
							className="w-9 h-9"
							onClick={() =>
								onChange({
									...data,
									quantity: data?.quantity - 1 > 1 ? data?.quantity - 1 : 1,
								})
							}
						>
							<RemoveOutlined className="text-white" />
						</IconButton>
						<label className="text-black text-sm font-bold w-full mx-3 ">
							{data?.quantity}
						</label>
					</>
				)}
				<IconButton
					style={{ backgroundColor: 'var(--color-neutral)' }}
					className="w-9 h-9"
					onClick={() => onChange({ ...data, quantity: data?.quantity + 1 })}
				>
					<AddOutlined className="text-white" />
				</IconButton>
			</div>
		</div>
	)
}

export default function AssignOrderModal({
	showModal,
	reservation_id,
	data,
	onClose,
	onSubmit,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [products, setProducts] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])

	useEffect(() => {
		setVisible(showModal)
		if (showModal) {
			loadProducts()
		}
	}, [showModal])

	const loadProducts = () => {
		MenuService.getProducts()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				setProducts(res?.products || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'products',
				})
			})
	}

	const handleSubmit = () => {
		if (selectedProducts.length === 0) {
			return NotificationManager.info('Please select products!')
		}
		if (
			selectedProducts.findIndex(
				(item) => item.quantity == null || item.quantity <= 0
			) !== -1
		) {
			return NotificationManager.info(
				'Please enter valid quantity for all products!'
			)
		}

		let total_amount = 0
		selectedProducts.forEach((item) => {
			total_amount = parseFloat(total_amount + item.price * item.quantity)
		})

		const payload = {
			reservation_id: parseInt(reservation_id),
			guest_count: data?.guest_count,
			total: total_amount,
			subtotal: total_amount,
			promotion_id: data?.promotion_id,
			products: selectedProducts,
		}
		onSubmit(payload)
	}

	const onRemoveProduct = (v) => {
		let tmp = selectedProducts.slice(0)
		let index = tmp.findIndex((t) => t.id === v.id)
		if (index !== -1) {
			tmp.splice(index, 1)
		}
		setSelectedProducts(tmp)
	}

	const handleChangeQuantity = (data, index) => {
		let tmp = selectedProducts.slice(0)
		tmp[index] = { ...data }
		setSelectedProducts(tmp)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<CustomIconButton
						icon={calendarIcon}
						title={assignOrderModalText('assignOrder')}
					/>
					<div className="flex flex-col px-024 mb-4">
						<RInputDeclare
							value={assignOrderModalText('products')}
							className="text-16 !text-black1 font-medium"
						/>
						<CustomSelect
							values={products}
							value={selectedProducts}
							isMultiple={true}
							placeholder={assignOrderModalText('selectProducts')}
							renderRow={(item) => <p>{item.title}</p>}
							renderValue={(item) => <span>{item?.title}</span>}
							handleChange={(v) => {
								let tmp = selectedProducts.slice(0)
								let index = tmp.findIndex((t) => t.id === v.id)
								if (index === -1) {
									tmp.push({ ...v, quantity: 1 })
								}
								setSelectedProducts(tmp)
							}}
							onRemoveItem={onRemoveProduct}
						/>
						{selectedProducts.length > 0 && (
							<div className="bg-blue2 p-4 my-4 rounded-xl">
								<div className="flex flex-row items-center w-full">
									<img
										src={selectedProducts[0]?.image_path}
										alt="product"
										className="w-20 h-20 rounded-xl mr-4"
									/>
									<div className="flex flex-col w-full">
										<label className="text-black text-sm font-bold w-full mb-1">
											{selectedProducts[0].title}
										</label>
										<div className="flex flex-row items-center justify-between w-full">
											<div className="flex flex-col justify-start">
												<label className="text-black text-xs font-normal w-full">
													{assignOrderModalText('price')}
												</label>
												<label className="text-black text-sm font-bold text-red1 w-full">
													${selectedProducts[0].price} x{' '}
												</label>
											</div>
											<div className="flex flex-row items-center">
												{selectedProducts[0].quantity > 0 && (
													<>
														<IconButton
															style={{
																backgroundColor: 'var(--color-neutral)',
															}}
															className="w-9 h-9"
															onClick={() =>
																handleChangeQuantity(
																	{
																		...selectedProducts[0],
																		quantity:
																			selectedProducts[0].quantity - 1 > 1
																				? selectedProducts[0].quantity - 1
																				: 1,
																	},
																	0
																)
															}
														>
															<RemoveOutlined className="text-white" />
														</IconButton>
														<label className="text-black text-sm font-bold w-full mx-3 ">
															{selectedProducts[0].quantity}
														</label>
													</>
												)}
												<IconButton
													style={{ backgroundColor: 'var(--color-neutral)' }}
													className="w-9 h-9"
													onClick={() =>
														handleChangeQuantity(
															{
																...selectedProducts[0],
																quantity: selectedProducts[0].quantity + 1,
															},
															0
														)
													}
												>
													<AddOutlined className="text-white" />
												</IconButton>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{selectedProducts.length > 0 && (
							<label className="text-black text-base font-bold w-full my-4">
								{assignOrderModalText('additional')}
							</label>
						)}
						{selectedProducts.length > 1 &&
							selectedProducts
								.slice(1)
								.map((product, index) => (
									<ProductQuantity
										key={product.id}
										data={product}
										onChange={(data) => handleChangeQuantity(data, index + 1)}
										onRemove={onRemoveProduct}
									/>
								))}
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-8 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{assignOrderModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={handleSubmit}
						>
							{assignOrderModalText('continue')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
