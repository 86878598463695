import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import GuestService from '../../services/guestService'
import ReservationService from '../../services/reservationService'
import TableService from '../../services/tableService'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import calendarIcon from '../../assets/svgs/calendar_white.svg'
import CustomDateTimePicker from '../custom/CustomDateTimePicker'

const getTranslation = (text) =>
	t(`components.table.newSeatingArrangement.${text}`)

export default function NewSeatingArrangement({
	showModal,
	onConfirm,
	onClose,
	data,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)

	const [guest, setGuest] = useState(0)
	const [table, setTable] = useState(0)
	const [start, setStart] = useState('')
	const [end, setEnd] = useState('')

	const [guestList, setGuestList] = useState([])
	const [guestOptions, setGuestOptions] = useState([])
	const [tableOptions, setTableOptions] = useState([])

	useEffect(() => {
		if (showModal) {
			setCheckValid(false)
			setGuest(0)
			setTable(0)
			setStart('')
			setEnd('')

			GuestService.GetGuestsWithoutTable().then((res) => {
				setGuestList(res.data)
				setGuestOptions(
					res.data
						.filter((item) => item.is_main)
						.map((item) => {
							return {
								value: item.id,
								label: item.name,
							}
						})
				)
			})
		}
		setVisible(showModal)
	}, [showModal])

	useEffect(() => {
		const detail = guestList.find((o) => o.id === guest)
		if (detail) {
			let reservationId = detail.pivot?.reservation_id
			if (reservationId) {
				ReservationService.ReadOne(reservationId).then((res) => {
					let startTime = res.data.start_time
					let endTime = res.data.end_time

					let payload = {
						start_time: startTime,
						end_time: endTime,
					}
					TableService.GetAvailables(payload).then((res) => {
						if (res.data) {
							setTableOptions(
								res.data.map((item) => {
									return {
										value: item.id,
										label: item.name,
									}
								})
							)
						}
						setStart(startTime)
						setEnd(endTime)
					})
				})
			}
		}
		// eslint-disable-next-line
	}, [guest])

	const onSubmit = () => {
		if (!guest) {
			NotificationManager.warning('Guest is required!')
			setCheckValid(true)
		}

		if (!table) {
			NotificationManager.warning('Table is required!')
			setCheckValid(true)
		}

		if (!start) {
			NotificationManager.warning('Start time is required!')
			setCheckValid(true)
		}

		if (!end) {
			NotificationManager.warning('End time is required!')
			setCheckValid(true)
		}
		if (guest && table && start && end) {
			//			onConfirm({ name, description });
			let payload = {
				guest_ids: [guest],
				table_id: table,
				start_time: moment(start).format('YYYY-MM-DD hh:mm'),
				end_time: moment(end).format('YYYY-MM-DD hh:mm'),
			}

			let detail = guestList.find((o) => o.id === guest)
			if (detail) {
				let reservationId = detail.pivot.reservation_id
				payload.guest_ids.push(
					...guestList
						.filter(
							(item) =>
								item.pivot.reservation_id === reservationId && !item.is_main
						)
						.map((item) => item.id)
				)
			}

			TableService.CreateSeatingArrangement(payload)
				.then((res) => {
					NotificationManager.success(
						'New reservation created successfully',
						''
					)
					onClose()
					refresh()
				})
				.catch((e) => {
					NotificationManager.error('Failed', '')
				})
			onClose()
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={calendarIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{getTranslation('newSeatingArrangement')}
						</label>
					</div>
					<div className="flex flex-col px-024 mb-4">
						<RInputDeclare
							value={getTranslation('guest')}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							error={checkValid && !guest}
							select
							label={getTranslation('required')}
							size="small"
							value={guest}
							onChange={(event) => {
								setGuest(event.target.value)
							}}
						>
							{guestOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare
							value={getTranslation('table')}
							className="!mt-0 !mb-2 !mt-6 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							error={checkValid && !table}
							select
							size="small"
							label={getTranslation('required')}
							value={table}
							onChange={(event) => {
								setTable(event.target.value)
							}}
						>
							{tableOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare
							value={getTranslation('startTime')}
							className="!mt-0 !mb-0 !mt-6 !text-black1 font-medium text-base"
						/>
						<CustomDateTimePicker
							placeholder={getTranslation('startTime')}
							value={start}
							onChange={setStart}
						/>

						<RInputDeclare
							value={getTranslation('endTime')}
							className="!mt-0 !mb-0 !mt-6 !text-black1 font-medium text-base"
						/>
						<CustomDateTimePicker
							placeholder={getTranslation('endTime')}
							value={end}
							onChange={setEnd}
						/>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{getTranslation('create')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
