import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import TableEmptyData from '../../../components/table/TableEmptyData'

export const tableReservationText = (text) =>
	t(`tables.tableDetail.TableReservationsBox.${text}`)

export const TableReservations = ({ data = [] }) => {
	const { short_code } = useSelector((state) => state.app)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const columns = [
		{ field: 'id', label: tableReservationText('id', '#ID'), align: 'center' },
		{
			field: 'start_time',
			label: tableReservationText('start_time', 'Start time'),
			align: 'center',
		},
		{
			field: 'end_time',
			label: tableReservationText('end_time', 'End time'),
			align: 'center',
		},
		{
			field: 'guest_count',
			label: tableReservationText('guests', 'Guests'),
			align: 'center',
		},
		{
			field: 'confirmed',
			label: tableReservationText('confirmed', 'Confirmed'),
			align: 'center',
		},
	]

	const processedData = data.map((row) => ({
		id: (
			<a
				href={`/${short_code}/admin/reservation/reservations/${row.id}`}
				className="underline text-black1 text-base font-semibold"
			>
				{row.id}
			</a>
		),
		start_time: row.start_time,
		end_time: row.end_time,
		guest_count: row.guest_count,
		confirmed:
			row.confirmed === 1
				? tableReservationText('yes', 'YES')
				: tableReservationText('no', 'NO'),
	}))

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row items-center px-6 py-5">
						<span className="text-black1 text-xl font-medium">
							{tableReservationText('table_reservations', 'Table Reservations')}
						</span>
					</div>
					{processedData.length > 0 ? (
						<>
							<GenericTable
								data={processedData.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)}
								columns={columns}
								className="mt-4"
							/>

							<GenericPagination
								count={data.length}
								page={page}
								rowsPerPage={rowsPerPage}
								onPageChange={handlePageChange}
								onRowsPerPageChange={handleRowsPerPageChange}
								setPage={setPage}
							/>
						</>
					) : (
						<TableEmptyData content={tableReservationText('no_reservations')} />
					)}
				</CardContent>
			</Card>
		</div>
	)
}
