import { Card, CardContent, MenuItem, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import { ImgDragUpload } from '../../components/common/ImgDragUpload'
import CustomSelect from '../../components/custom/CustomSelect'
import LoadingScreen from '../../components/custom/LoadingScreen'
import { RInputDeclare } from '../../components/custom/RInputDeclare'
import { isRetailVenue } from '../../helpers/venueHelpers'
import { getRegisterConfig, update } from '../../services/restaurantService'
import { getUserProfile } from '../../store/actions/auth'
import { isEmpty } from '../../utils/common'
import { KEYS } from '../../utils/storage'

const industryList = [
	{ value: 'Food', title: 'Food & Beverage' },
	{ value: 'Sport & Entertainment', title: 'Entertainment Venues' },
	{ value: 'Accommodation', title: 'Accommodation' },
	{ value: 'Retail', title: 'Retail Management' },
	// { value: "Healthcare", title: "Healthcare" },
]

const usCurrencies = ['USD']
const otherCountriesCurrencies = ['USD', 'EUR', 'ALL']

const venueTypeList = {
	Food: [
		{ value: 'restaurant', title: 'Restaurant' },
		{ value: 'bistro', title: 'Bistro' },
		{ value: 'cafe', title: 'Cafe' },
		{ value: 'bar', title: 'Bar' },
		{ value: 'pub_club', title: 'Pub & Club' },
		{ value: 'food_truck', title: 'Food Truck' },
		{ value: 'fine_dining', title: 'Fine Dining' },
		{ value: 'bakery', title: 'Bakery' },
		{ value: 'catering_service', title: 'Catering Service' },
		{ value: 'fast_food', title: 'Fast Food' },
		{ value: 'ice_cream_parlor', title: 'Ice Cream Parlor' },
		{ value: 'food_stall', title: 'Food Stall' },
		{ value: 'buffet', title: 'Buffet' },
		{ value: 'pop_up_dining', title: 'Pop-Up Dining' },
		{ value: 'juice_bar', title: 'Juice Bar' },
		{ value: 'food_hall', title: 'Food Hall' },
		{ value: 'supper_club', title: 'Supper Club' },
		{ value: 'pizzeria', title: 'Pizzeria' },
		{ value: 'coffee_shop', title: 'Coffee Shop' },
		{ value: 'delis', title: 'Delis' },
		{ value: 'food_court', title: 'Food Court' },
	],
	'Sport & Entertainment': [
		{ value: 'golf_venue', title: 'Golf Course' },
		{ value: 'bowling', title: 'Bowling Alley' },
		{ value: 'gym', title: 'Gym & Fitness Center' },
		{ value: 'arcade_and_game_center', title: 'Arcade & Game Center' },
		{ value: 'sports_arena', title: 'Sports Arena' },
		{ value: 'concert_hall_and_theater', title: 'Concert Hall & Theater' },
		{ value: 'amusement_and_theme_park', title: 'Amusement & Theme Park' },
		{ value: 'ski_resort', title: 'Ski Resort' },
		{ value: 'museum', title: 'Museum' },
		{ value: 'cinema', title: 'Cinema' },
	],
	Accommodation: [
		{ value: 'hotel', title: 'Hotel' },
		{ value: 'cozy_retreat', title: 'Cozy Retreat' },
		{ value: 'hostel', title: 'Hostel' },
		{ value: 'hotel_chain', title: 'Hotel Chain' },
		{ value: 'vacation_rental', title: 'Vacation Rental' },
		{ value: 'luxury_resort', title: 'Luxury Resort' },
		{ value: 'family_resort', title: 'Family Resorts' },
		{ value: 'service_apartment', title: 'Service Apartment' },
		{ value: 'bed_and_breakfast', title: 'Bed and Breakfast' },
		{ value: 'motel', title: 'Motel' },
		{ value: 'capsule_hotel', title: 'Capsule Hotel' },
		{ value: 'youth_hostel', title: 'Youth Hostel' },
		{ value: 'campground', title: 'Campground' },
		{ value: 'rv_park', title: 'RV Park' },
	],
	Retail: [
		{ value: 'sport_essentials', title: 'Sporting Goods Store' },
		{ value: 'home_decor', title: 'Home Décor Store' },
		{ value: 'fashion_threads', title: 'Fashion Boutique' },
		{ value: 'retail_chain', title: 'Retail Chain' },
		{ value: 'specialty_store', title: 'Specialty Store' },
		{ value: 'online_shop', title: 'Online Shop' },
		{ value: 'grocery_store', title: 'Grocery Store' },
		{ value: 'electronics_store', title: 'Electronics Store' },
		{ value: 'pharmacy', title: 'Pharmacy' },
		{ value: 'auto_motorcycle_parts', title: 'Auto Parts Store' },
		{ value: 'liquor_store', title: 'Liquor Store' },
		{ value: 'pet_store', title: 'Pet Store' },
	],
	// Healthcare: [
	//     { value: "hospital", title: "Hospital" },
	//     { value: "dental_clinic", title: "Dental Clinic" },
	// ],
}

export const accountText = (text) => t(`account.view.${text}`)

const Account = () => {
	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.auth)
	const cookies = new Cookies()
	// const venueType = cookies.get(KEYS.VENUE_TYPE);

	const [cuisine_types, setCuisineTypes] = useState([])
	const [venue_types, setVenueTypes] = useState([])
	const [states, setStates] = useState([])
	const [cities, setCities] = useState([])
	const [countries, setCountries] = useState([])
	const [loading, setLoading] = useState(false)
	const [logo_image, setLogoImage] = useState(null)
	const [cover_image, setCoverImage] = useState(null)
	const [venueType, setVenueType] = useState() //  cookies.get(KEYS.VENUE_TYPE);
	const [countryName, setCountryName] = useState(null)
	const [stateName, setStateName] = useState('')
	const [cityName, setCityName] = useState('')

	const [id, setId] = useState(null)

	const validator = yup.object().shape({
		restaurant_name: yup
			.string()
			.trim()
			.required('First name is required')
			.max(72, 'Max exceeded')
			.min(2, 'Min not met'),
		restaurant_email: yup
			.string()
			.trim()
			.required('Email is required')
			.max(72, 'Max exceeded')
			.min(5, 'Min not met')
			.matches(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				'Invalid email'
			),
		phone: yup
			.string()
			.trim()
			.required('Phone number is required')
			.max(20, 'Max exceeded')
			.min(5, 'Min not met'),
		// .matches(/^([2-9]\d{2}[2-9]\d{2}\d{4}){1}$/, 'Invalid US number'),
		website: yup
			.string()
			.trim()
			.matches(
				/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				'Invalid Website'
			),
		cuisine_types:
			venueType === 'restaurant'
				? yup.array().required().min(1, 'Please select cuisine types')
				: yup.array(),
		// amenities: venueType === 'restaurant' ?  yup.array().required().min(1, 'Please select amenities') : yup.array().notRequired(),
		pricing:
			venueType === 'restaurant'
				? yup.string().trim().required('Pricing Range is required')
				: yup.string().notRequired(),
		venue_type: yup.string(),
		venue_industry: yup.string().required('Venue Industy is required'),
		capacity: yup.string(),
		address_line1: yup
			.string()
			.trim()
			.required('Address Line 1 is required')
			.max(250, 'Max exceeded')
			.min(6, 'Min not met'),
		state: yup.string().required('State is required'),
		city: yup.string().required('City is required'),
		postcode: yup.string().required('Postal code is required'),
	})

	const formikProps = useFormik({
		// initialValues: { first_name: '', last_name: '', email: '', cuisine_types: [], amenities: [] },
		initialValues: {
			restaurant_name: '',
			restaurant_email: '',
			phone: '',
			email: '',
			website: '',
			pricing: '',
			currency: '',
			cuisine_types: [],
			venue_type: '',
			venue_industry: '',
			capacity: '',
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const addressInputRef = useRef(null)

	useEffect(() => {
		let autocomplete

		if (!window.google || !window.google.maps || !window.google.maps.places) {
			console.error('Google Maps API not loaded yet.')
			return
		}

		autocomplete = new window.google.maps.places.Autocomplete(
			addressInputRef.current
		)
		autocomplete.addListener('place_changed', () => {
			const selectedPlace = autocomplete.getPlace()
			if (selectedPlace && selectedPlace.geometry) {
				const { location } = selectedPlace.geometry
				formikProps.setFieldValue('latitude', location.lat())
				formikProps.setFieldValue('longitude', location.lng())

				// Also set the address in Formik's state. This ensures that the input value is set.
				formikProps.setFieldValue(
					'address_line1',
					selectedPlace.formatted_address
				)
			} else {
				console.log('No geometry data found for selected place.')
			}
		})

		return () => {
			if (autocomplete) {
				window.google.maps.event.clearInstanceListeners(autocomplete)
			}
		}
	})

	useEffect(() => {
		setVenueType(cookies.get(KEYS.VENUE_TYPE))
		loadRegisterConfig()
		dispatch(getUserProfile())
	}, [])

	useEffect(() => {
		if (user?.restaurant) {
			formikProps.setFieldValue('restaurant_name', user?.restaurant.name)
			formikProps.setFieldValue('restaurant_email', user?.restaurant.email)
			formikProps.setFieldValue('phone', user?.restaurant.phone_number)
			formikProps.setFieldValue('pricing', user?.restaurant.pricing)
			formikProps.setFieldValue('currency', user?.restaurant.currency)
			formikProps.setFieldValue('capacity', user?.restaurant.capacity)
			formikProps.setFieldValue(
				'venue_industry',
				user?.restaurant.venue_industry?.name
			)
			formikProps.setFieldValue('website', user?.restaurant.website)
			formikProps.setFieldValue(
				'cuisine_types',
				user?.restaurant.cuisine_types || []
			)
			// formikProps.setFieldValue('amenities', user?.restaurant.amenities || []);
			formikProps.setFieldValue(
				'venue_type',
				user?.restaurant.venue_type.short_name
			)

			formikProps.setFieldValue(
				'address_line1',
				user?.restaurant.address?.address_line1
			)
			formikProps.setFieldValue(
				'address_line2',
				user?.restaurant.address?.address_line2 === 'null'
					? ''
					: user?.restaurant.address?.address_line2
			)
			formikProps.setFieldValue('state', user?.restaurant.address?.state_id)
			formikProps.setFieldValue('city', user?.restaurant.address?.city_id)
			formikProps.setFieldValue('country', user?.restaurant.address?.country_id)
			setCountryName(user?.restaurant.address?.country)
			setStateName(user?.restaurant.address?.state)
			setCityName(user?.restaurant.address?.city)
			formikProps.setFieldValue('postcode', user?.restaurant.address?.postcode)
			setId(user?.restaurant?.id)
			if (!isEmpty(user?.restaurant.logo)) {
				setLogoImage({
					type: 'url',
					url: user?.restaurant.logo,
				})
			}
			if (!isEmpty(user?.restaurant.cover)) {
				setCoverImage({
					type: 'url',
					url: user?.restaurant.cover,
				})
			}
		}
	}, [user])

	const loadRegisterConfig = () => {
		getRegisterConfig()
			.then((data) => {
				setCuisineTypes(data?.cuisine_types || [])
				// setAmenities(data?.amenities || []);
				setVenueTypes(data?.venue_types || [])
				setStates(data?.states || [])
				setCities(data?.cities || [])
				setCountries(data?.countries || [])
			})
			.catch((error) => {
				throw new Error('Problem on getRegisterConfig')
			})
	}

	const onSubmit = (values) => {
		window.scrollTo(0, 0)

		const payload = {
			...values,
		}

		let formData
		formData = new FormData()

		for (const key in payload) {
			formData.append(key, payload[key])
		}
		if (id) {
			formData.append('id', id)
		}
		if (formikProps.values.cuisine_types != null) {
			formData.append(
				'cuisine_types',
				JSON.stringify(formikProps.values.cuisine_types)
			)
		}

		if (logo_image?.type === 'file' && logo_image?.url != null) {
			// let tmp = logo_image.url.split(',');
			// if (tmp.length > 1) {
			//   payload.logo_image = tmp[1];
			// }
			formData.append('logo_image', logo_image.file)
		} else {
			if (logo_image?.url === null) {
				return NotificationManager.info('Please upload a Logo', 'Warn', 3000)
			}
		}

		if (cover_image?.type === 'file' && cover_image?.url != null) {
			// let tmp = cover_image.url.split(',');
			// if (tmp.length > 1) {
			//   payload.cover_image = tmp[1];
			// }
			formData.append('cover_image', cover_image.file)
		}

		setLoading(true)
		update(formData)
			.then((data) => {
				setLoading(false)
				dispatch(getUserProfile())
				NotificationManager.success(
					'Profile has been updated successfully',
					'Success',
					3000
				)
			})
			.catch((error) => {
				setLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on updating the profile')
			})
	}

	if (loading) {
		return <LoadingScreen />
	}
	return (
		<div
			className={'h-full p-6 overflow-y-auto'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card>
				<CardContent className="!p-0">
					<div className="relative w-full !rounded-lg p-6 gap-10">
						<div className="flex flex-col justify-center pr-10 mb-8">
							<div className="text-xl font-medium text-black1">
								{accountText('account_info')}
							</div>
						</div>
						<div className="relative started-form">
							<p className={'text-base text-gray5 mt-0 mb-2'}>
								{accountText('general_images')}
							</p>
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col mt-2 bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col justify-center">
									<div className="text-black text-medium text-base my-4">
										{accountText('logo_image')}
									</div>
									<ImgDragUpload
										image={logo_image}
										classes="py-6"
										onChange={setLogoImage}
									/>
								</div>
								<div className="flex flex-col justify-center">
									<div className="text-black text-medium text-base my-4">
										{accountText('cover_image')}
									</div>
									<ImgDragUpload
										image={cover_image}
										classes="py-12 w-full"
										onChange={setCoverImage}
									/>
								</div>
							</div>
							<div className="flex flex-col mt-8">
								<p className={'text-base text-gray5 mt-0 mb-2'}>
									{accountText('general_info')}
								</p>
								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 pt-4">
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('venue_name')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.restaurant_name}
											error={formikProps.errors.restaurant_name}
											placeholder={accountText('venue_name')}
											onChange={(e) => {
												formikProps.setFieldValue(
													'restaurant_name',
													e.target.value
												)
											}}
										/>
										{!isEmpty(formikProps.errors.restaurant_name) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.restaurant_name}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('venue_email')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.restaurant_email}
											error={formikProps.errors.restaurant_email}
											placeholder={accountText('venue_email')}
											onChange={(e) => {
												formikProps.setFieldValue(
													'restaurant_email',
													e.target.value
												)
											}}
										/>
										{!isEmpty(formikProps.errors.restaurant_email) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.restaurant_email}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('phone_number')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											placeholder={accountText('phone_number')}
											value={formikProps.values.phone}
											error={formikProps.errors.phone}
											onChange={(e) => {
												formikProps.setFieldValue('phone', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.phone) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.phone}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('website')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											placeholder={accountText('website')}
											value={formikProps.values.website}
											error={formikProps.errors.website}
											onChange={(e) => {
												formikProps.setFieldValue('website', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.website) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.website}
											</div>
										)}
									</div>
									{venueType === 'restaurant' && (
										<div className="flex flex-col">
											<RInputDeclare
												value={accountText('cuisine_types')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomSelect
												values={cuisine_types}
												value={
													formikProps.values.cuisine_types == null ||
													formikProps.values.cuisine_types.length === 0
														? []
														: cuisine_types.filter(
																(item) =>
																	formikProps.values.cuisine_types.findIndex(
																		(a) => a === item.id
																	) !== -1
														  )
												}
												isMultiple={true}
												className={' !z-[5] !bg-white !border-gray6 !h-10'}
												placeholder={accountText('cuisine_types')}
												renderRow={(item) => <p>{item.name}</p>}
												renderValue={(item) => <span>{item?.name}</span>}
												handleChange={(v) => {
													let tmp = formikProps.values.cuisine_types.slice(0)
													tmp.push(v.id)
													formikProps.setFieldValue('cuisine_types', tmp)
												}}
												onRemoveItem={(v) => {
													let tmp = formikProps.values.cuisine_types.slice(0)
													let index = tmp.findIndex((t) => t === v.id)
													if (index !== -1) {
														tmp.splice(index, 1)
													}
													formikProps.setFieldValue('cuisine_types', tmp)
												}}
											/>
											{!isEmpty(formikProps.errors.cuisine_types) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.cuisine_types}
												</div>
											)}
										</div>
									)}
									{venueType === 'restaurant' && (
										<div className="flex flex-col">
											<RInputDeclare
												value={accountText('pricing_range')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomSelect
												values={['$', '$$', '$$$']}
												value={formikProps.values.pricing}
												className={' !z-[4] !bg-white !border-gray6 !h-10'}
												placeholder={accountText('pricing_range')}
												handleChange={(v) => {
													formikProps.setFieldValue('pricing', v)
												}}
											/>
											{!isEmpty(formikProps.errors.pricing) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.pricing}
												</div>
											)}
										</div>
									)}
									{/* More conditions and fields based on venueType */}
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('venue_industry')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											id="outlined-select"
											size="small"
											error={!formikProps.values.venue_industry}
											select
											value={formikProps.values.venue_industry}
											onChange={(event) => {
												formikProps.setFieldValue(
													'venue_industry',
													event.target.value
												)
											}}
										>
											{industryList.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.title}
												</MenuItem>
											))}
										</TextField>
										{!isEmpty(formikProps.errors.venue_industry) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.venue_industry}
											</div>
										)}
									</div>
									<div>
										<RInputDeclare
											value={accountText('venue_type')}
											className="!mt-0 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={
												formikProps.values.venue_industry
													? venueTypeList[formikProps.values.venue_industry]
													: []
											}
											value={
												formikProps.values.venue_industry
													? venueTypeList[
															formikProps.values.venue_industry
													  ]?.find(
															(item) =>
																item.value === formikProps.values.venue_type
													  )?.title
													: ''
											}
											className={'!bg-white !mt-2 !border-gray6 !h-10'}
											placeholder={'Select venue type'}
											renderRow={(item) => <p>{item.title}</p>}
											handleChange={(v) => {
												formikProps.setFieldValue('venue_type', v.value)
												setVenueType(v.value)
											}}
										/>
										{!isEmpty(formikProps.errors.venue_type) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.venue_type}
											</div>
										)}
									</div>
									{/* More fields based on venueType */}
									{venueType === 'restaurant' && (
										<div className="flex flex-col">
											<RInputDeclare
												value={accountText('capacity')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<TextField
												required
												size="small"
												type="number"
												placeholder={accountText('capacity')}
												value={formikProps.values.capacity}
												error={formikProps.errors.capacity}
												onChange={(e) => {
													formikProps.setFieldValue('capacity', e.target.value)
												}}
											/>
											{!isEmpty(formikProps.errors.capacity) && (
												<div className="text-red-600 text-12 mt-2">
													{formikProps.errors.capacity}
												</div>
											)}
										</div>
									)}
									{venueType !== 'vacation_rental' &&
										!isRetailVenue(venueType) && (
											<div className="flex flex-col">
												<RInputDeclare
													value={accountText('currency')}
													className="!mt-0 !mb-2 !text-black1 font-medium text-base"
												/>
												<CustomSelect
													values={
														countryName === 'USA'
															? usCurrencies
															: otherCountriesCurrencies
													}
													value={formikProps.values.currency}
													className={' !z-[4] !bg-white !border-gray6 !h-10'}
													placeholder={accountText('currency')}
													handleChange={(v) => {
														formikProps.setFieldValue('currency', v)
													}}
												/>
												{!isEmpty(formikProps.errors.currency) && (
													<div className="text-red-600 text-12 mt-2">
														{formikProps.errors.currency}
													</div>
												)}
											</div>
										)}
								</div>
							</div>
							<div>
								<p className={'text-base text-gray5 mt-10 mb-2'}>
									{accountText('address_details')}
								</p>
								<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('address_line1')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<input
											className={`w-full h-[40px] border outline-none py-0 px-4`}
											placeholder={accountText('address_line1')}
											ref={addressInputRef}
											type={'text'}
											style={{ borderRadius: '4px' }}
											value={formikProps.values.address_line1}
											onChange={(e) => {
												formikProps.setFieldValue(
													'address_line1',
													e.target.value
												)
											}}
										/>
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('address_line2')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											placeholder={accountText('address_line2')}
											value={formikProps.values.address_line2}
											error={formikProps.errors.address_line2}
											onChange={(e) => {
												formikProps.setFieldValue(
													'address_line2',
													e.target.value
												)
											}}
										/>
										{!isEmpty(formikProps.errors.address_line2) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.address_line2}
											</div>
										)}
									</div>
									<div>
										<RInputDeclare
											value={accountText('country')}
											className="!mt-0 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={countries}
											value={countryName}
											renderRow={(item) => <span>{item?.name}</span>}
											className={'!z-[5] !mt-2 !bg-white !border-gray6 !h-10'}
											placeholder={accountText('country')}
											handleChange={(v) => {
												formikProps.setFieldValue('country', v.id)
												setCountryName(v.name)
												formikProps.setFieldValue('state', null)
												formikProps.setFieldValue('city', null)
												setStateName('')
												setCityName('')
											}}
										/>
										{!isEmpty(formikProps.errors.country) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.country}
											</div>
										)}
									</div>
									<div>
										<RInputDeclare
											value={accountText('state')}
											className="!mt-0 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={
												countries?.find(
													(c) => c.id === formikProps.values.country
												)?.states
											}
											value={stateName}
											renderRow={(item) => <span>{item?.name}</span>}
											className={'!z-[5] !mt-2 !bg-white !border-gray6 !h-10'}
											placeholder={accountText('state')}
											handleChange={(v) => {
												setStateName(v.name)
												formikProps.setFieldValue('state', v.id)
												formikProps.setFieldValue('city', null)
												setCityName('')
											}}
										/>
										{!isEmpty(formikProps.errors.state) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.state}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('city')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											id="outlined-select"
											size="small"
											select
											value={formikProps.values.city}
											onChange={(event) => {
												formikProps.setFieldValue('city', event.target.value)
											}}
											className={'!z-[4] !bg-white !h-10'}
											SelectProps={{
												MenuProps: {
													className: 'select-menu',
												},
											}}
											placeholder={accountText('city')}
										>
											{formikProps.values.state &&
												countries
													?.find((c) => c.id === formikProps.values.country)
													?.states?.find(
														(s) => s.id === formikProps.values.state
													)
													?.cities.map((city) => (
														<MenuItem key={city.id} value={city.id}>
															{city.name}
														</MenuItem>
													))}
										</TextField>
										{!isEmpty(formikProps.errors.city) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.city}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={accountText('postal_code')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											placeholder={accountText('postal_code')}
											value={formikProps.values.postcode}
											error={formikProps.errors.postcode}
											onChange={(e) => {
												formikProps.setFieldValue('postcode', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.postcode) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.postcode}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
								<button
									type="button"
									className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
								>
									{accountText('clear_button')}
								</button>
								<button
									type="submit"
									className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
									onClick={() => {
										formikProps.handleSubmit()
									}}
								>
									{accountText('update_button')}
								</button>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default Account
