import {
	Card,
	CardContent,
	CircularProgress,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	TextField,
} from '@mui/material'

import { AddOutlined, CloseOutlined } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import InfoIcon from '../../../../assets/svgs/info_black.svg'
import AddEditRoomModal from '../../../../components/accommodation/addEditRoomModal'
import LoadingScreen from '../../../../components/custom/LoadingScreen'
import accommodationService from '../../../../services/accommodationService'
import { isEmpty } from '../../../../utils/common'
import './index.css'

import { t } from 'i18next'
import AccommodationVacationRentalStandardIcon from '../../../../assets/svgs/accommodation-vacation-standard.svg'
import AreYouSureModal from '../../../../components/common/areYouSureModal'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'

const accommodation_types = [
	{ title: 'Entire home', value: 'Entire home' },
	{ title: 'Apartment', value: 'Apartment' },
]

const RoomItem = (props) => {
	return (
		<div className="flex  gap-[18px]">
			<div className="flex-1 rounded-[5px] border-[1px] border-[#cbd5e1] text-17 font-bold leading-[35px] text-black1 px-4 py-3">
				{props.title} <span className="text-[#707070]">- {props.content}</span>
			</div>
			<IconButton
				onClick={() => props.onShowRoom()}
				style={{
					width: 60,
					height: 60,
					backgroundColor: '#fff',
					border: '1px solid #CBD5E1',
					borderRadius: '5px',
				}}
			>
				<img
					src={AccommodationVacationRentalStandardIcon}
					alt="plus"
					className="p-2 h-10 cursor-pointer"
				/>
			</IconButton>
			<IconButton
				onClick={() => props.onDeleteRoom()}
				style={{
					width: 60,
					height: 60,
					backgroundColor: '#fff',
					border: '1px solid #CBD5E1',
					borderRadius: '5px',
				}}
			>
				<CloseOutlined
					style={{ color: '#707070', width: '30px', height: '30px' }}
				/>
			</IconButton>
		</div>
	)
}

export const accomodationFormText = (text) =>
	t(`accommodation.rental_unit.show.accommodationForm.${text}`)

const AccommodationForm = ({ data, rentalUnitId, beds }) => {
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [updatingDetails, setUpdatingDetails] = useState(false)
	const [rooms, setRooms] = useState([])
	const [showDeleteRoom, setShowDeleteRoom] = useState(false)
	const [selectedRoom, setSelectedRoom] = useState(null)

	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		if (data?.accommodation_details) {
			accommodationDetailsFormikProps.setFieldValue(
				'guest_limit',
				data?.accommodation_details?.guest_limit ?? 1
			)
			accommodationDetailsFormikProps.setFieldValue(
				'bathroom_count',
				data?.accommodation_details?.bathroom_count ?? 1
			)
			accommodationDetailsFormikProps.setFieldValue(
				'square_metres',
				data?.accommodation_details?.square_metres
			)
		}

		accommodationDetailsFormikProps.setFieldValue(
			'year_built',
			data?.year_built
		)

		accommodationDetailsFormikProps.setFieldValue(
			'unit_floor',
			data?.unit_floor
		)

		accommodationDetailsFormikProps.setFieldValue(
			'accommodation_type',
			data?.accommodation_type
		)

		if (data?.rooms) {
			setRooms(data?.rooms ?? [])
		}
	}, [data])

	const accommodationDetailsFormikProps = useFormik({
		initialValues: {
			guest_limit: null,
			bathroom_count: null,
			square_metres: null,
			year_built: null,
			unit_floor: null,
			accommodation_type: null,
		},
		validationSchema: yup.object().shape({
			guest_limit: yup.number().min(1).required(),
			square_metres: yup.string().trim().required('Square meters is required'),
		}),
		onSubmit: (values) => onSubmitAccommodationDetails(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const onSubmitAccommodationDetails = (values) => {
		setUpdatingDetails(true)
		const details = {
			type: 'accommodation_details',
			guest_limit: values.guest_limit,
			bathroom_count: values.bathroom_count,
			square_metres: values.square_metres,
			accommodation_type: values.accommodation_type,
			year_built: values.year_built,
			unit_floor: values.unit_floor,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Accomodation Setup', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'AccomodationSetup',
				})
				setUpdatingDetails(false)
				NotificationManager.success(
					'Accommodation Details has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=1`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Accomodation Setup', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'AccomodationSetup',
				})
				setUpdatingDetails(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem updating Accommodation Details')
			})
	}

	if (loading) {
		return <LoadingScreen />
	}

	return (
		<>
			;<Card>
				<CardContent className="!p-0">
					<div className="w-full rounded-4xl p-6 pt-2 gap-10">
						<div className="relative started-form">
							<p className={'text-base text-gray5 my-4'}>
								<span className="text-black1 text-20 font-bold">
									{accomodationFormText('accommodation_details')}
								</span>
							</p>
							<div className="flex flex-row gap-12">
								<div className="flex flex-col w-[60%] gap-8">
									<div
										className="flex flex-col p-[22px] rounded-[10px] border-[#E0E0E0] border-[1px]"
										style={{ padding: '22px', paddingTop: '10px' }}
									>
										<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
											{accomodationFormText('accommodationType')}
										</p>
										<div className="flex flex-col">
											<TextField
												id="outlined-select"
												size="small"
												error={
													accommodationDetailsFormikProps.errors
														.accommodation_type
												}
												select
												value={
													accommodationDetailsFormikProps.values
														.accommodation_type
												}
												onChange={(event) => {
													accommodationDetailsFormikProps.setFieldValue(
														'accommodation_type',
														event.target.value
													)
												}}
											>
												{accommodation_types.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.title}
													</MenuItem>
												))}
											</TextField>
											{!isEmpty(
												accommodationDetailsFormikProps.errors
													.accommodation_type
											) && (
												<div className="text-red-600 text-12 mt-2">
													{
														accommodationDetailsFormikProps.errors
															.accommodation_type
													}
												</div>
											)}
										</div>

										<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-1">
											{accomodationFormText('unitFloor')}
										</p>
										<div className="flex flex-col">
											<TextField
												required
												size="medium"
												placeholder={accomodationFormText(
													'unitFloorPlaceholder'
												)}
												value={
													accommodationDetailsFormikProps.values.unit_floor
												}
												error={
													accommodationDetailsFormikProps.errors.unit_floor
												}
												onChange={(e) => {
													accommodationDetailsFormikProps.setFieldValue(
														'unit_floor',
														e.target.value
													)
												}}
											/>
											{!isEmpty(
												accommodationDetailsFormikProps.errors.unit_floor
											) && (
												<div className="text-red-600 text-12 mt-2">
													{accommodationDetailsFormikProps.errors.unit_floor}
												</div>
											)}
										</div>

										<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-2">
											{accomodationFormText('yearBuilt')}
										</p>
										<div className="flex flex-col">
											<TextField
												required
												size="medium"
												placeholder={accomodationFormText(
													'yearBuiltPlaceholder'
												)}
												value={
													accommodationDetailsFormikProps.values.year_built
												}
												error={
													accommodationDetailsFormikProps.errors.year_built
												}
												onChange={(e) => {
													accommodationDetailsFormikProps.setFieldValue(
														'year_built',
														e.target.value
													)
												}}
											/>
											{!isEmpty(
												accommodationDetailsFormikProps.errors.year_built
											) && (
												<div className="text-red-600 text-12 mt-2">
													{accommodationDetailsFormikProps.errors.year_built}
												</div>
											)}
										</div>

										<p className="text-black1 text-18 mt-4 font-semibold leading-[35px] py-2">
											{accomodationFormText('unitSize')}
										</p>
										<div className="flex flex-row">
											<TextField
												required
												size="medium"
												placeholder={accomodationFormText('enterValue')}
												value={
													accommodationDetailsFormikProps.values.square_metres
												}
												error={
													accommodationDetailsFormikProps.errors.square_metres
												}
												className="flex flex-1"
												onChange={(e) => {
													accommodationDetailsFormikProps.setFieldValue(
														'square_metres',
														e.target.value
													)
												}}
											/>
											<FormControl>
												<Select value={false}>
													<MenuItem value={false}>
														{accomodationFormText('m2')}
													</MenuItem>
												</Select>
											</FormControl>
										</div>
										{!isEmpty(
											accommodationDetailsFormikProps.errors.square_metres
										) && (
											<div className="text-red-600 text-12 mt-2">
												{accommodationDetailsFormikProps.errors.square_metres}
											</div>
										)}

										<p className="text-black1 text-18 mt-4 font-semibold leading-[35px] py-2">
											{accomodationFormText('howManyGuests')}
										</p>
										<div className="flex flex-col">
											<div className="flex flex-row justify-start gap-0">
												<div
													className="flex flex-row justify-center align-center rounded-l-[5px] px-8 py-4 border-[#E0E0E0] border-[1px] cursor-pointer"
													onClick={() =>
														accommodationDetailsFormikProps.values.guest_limit >
															1 &&
														accommodationDetailsFormikProps.setFieldValue(
															'guest_limit',
															accommodationDetailsFormikProps.values
																.guest_limit - 1
														)
													}
												>
													<div className="text-base font-bold">-</div>
												</div>
												<div className="flex flex-row justify-center align-center px-8 py-4 border-[#E0E0E0] border-[1px] cursor-pointer">
													<div className="text-base font-bold text-black1">
														{accommodationDetailsFormikProps.values.guest_limit}
													</div>
												</div>
												<div
													className="flex flex-row justify-center align-center rounded-r-[5px] px-8 py-4 border-[#E0E0E0] border-[1px] cursor-pointer"
													onClick={() =>
														accommodationDetailsFormikProps.setFieldValue(
															'guest_limit',
															accommodationDetailsFormikProps.values
																.guest_limit + 1
														)
													}
												>
													<div className="text-base font-bold">+</div>
												</div>
											</div>
										</div>
										<div className="flex flex-row justify-between mt-6 mb-2 h-[48px] gap-8">
											<button
												type="submit"
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												onClick={() => {
													accommodationDetailsFormikProps.handleSubmit()
												}}
											>
												{updatingDetails ? (
													<CircularProgress size={16} />
												) : (
													accomodationFormText('update')
												)}
											</button>
										</div>
									</div>
									<div
										style={{
											padding: '22px',
											paddingTop: '5px',
											marginTop: '10px',
											paddingBottom: '25px',
										}}
										className="flex flex-col rounded-[10px] border-[#E0E0E0] border-[1px]"
									>
										<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
											{accomodationFormText('roomsAndSpaces')}
										</p>
										<div className="flex flex-col gap-5">
											{rooms.map((item, index) => (
												<RoomItem
													key={index}
													title={item.name}
													onDeleteRoom={() => {
														setShowDeleteRoom(true)
														setSelectedRoom(item)
													}}
													onShowRoom={() => {
														setShowModal(true)
														setSelectedRoom(item)
													}}
													content={item.beds
														.map(
															(bedItem) =>
																`${bedItem?.pivot.quantity} ${bedItem.name}`
														)
														.join(', ')}
												/>
											))}
										</div>
										<div
											className="flex mt-4 items-center gap-5 cursor-pointer"
											onClick={() => {
												setShowModal(true)
												setSelectedRoom(null)
											}}
										>
											<IconButton
												style={{
													width: 38,
													height: 38,
													backgroundColor: '#fff',
													border: '1px solid #027A48',
													borderRadius: '19px',
												}}
											>
												<AddOutlined
													style={{
														color: '#027A48',
														width: '20px',
														height: '20px',
													}}
												/>
											</IconButton>
											<p className="text-[#027A48] text-17 font-semibold">
												{accomodationFormText('addRoom')}
											</p>
										</div>
									</div>
								</div>

								<div className="w-[40%] flex flex-col gap-12">
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="info" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{accomodationFormText('specifyOtherDetails')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{accomodationFormText('specifyOtherDetailsDesc')}
										</p>
									</div>
									<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
										<div className="absolute left-[22px] top-[22px]">
											<img className="w-8 h-8" src={InfoIcon} alt="info" />
										</div>

										<h4 className="text-black1 text-18 font-bold leading-7">
											{accomodationFormText('roomsAndSleepingArrangements')}
										</h4>
										<p className="text-16 text-[#666A73] font-normal leading-7">
											{accomodationFormText('roomsAndSleepingArrangementsDesc')}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			{
				showModal && (
					<AddEditRoomModal
						beds={beds}
						rentalUnitId={rentalUnitId}
						showModal={showModal}
						selectedRoom={selectedRoom}
						onClose={(refresh) => {
							setShowModal(false)
						}}
					/>
				)
			}

			{
				showDeleteRoom && (
					<AreYouSureModal
						title={accomodationFormText('deleteRoomTitle')}
						content={accomodationFormText('deleteRoomContent')}
						firstButton={accomodationFormText('noCancel')}
						secondButton={accomodationFormText('yesIAm')}
						showModal={showDeleteRoom}
						onClose={() => setShowDeleteRoom(false)}
						onSubmit={() =>
							accommodationService
								.DeleteRentalUnitAccommodationRoom(
									selectedRoom.id,
									rentalUnitId
								)
								.then((data) => {
									identifyVenue(short_code)
									trackEvent('Delete Rental Unit', {
										action_category: 'delete',
										action_outcome: 'success',
										interaction_element: 'rental_unit',
									})
									NotificationManager.success(
										accomodationFormText('successMessage'),
										accomodationFormText('successTitle'),
										3000
									)
									setTimeout(() => {
										navigate(
											`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=1`
										)
										navigate(0)
									}, 1000)
								})
								.catch((error) => {
									identifyVenue(short_code)
									trackEvent('Delete Rental Unit', {
										action_category: 'delete',
										action_outcome: 'fail',
										interaction_element: 'rental_unit',
									})
									NotificationManager.error(
										error.message || accomodationFormText('errorMessage'),
										accomodationFormText('errorTitle'),
										3000
									)
									throw new Error(accomodationFormText('errorDeletingRoom'))
								})
						}
					/>
				)
			}
		</>
	)
}

export default AccommodationForm;
