import { API_DASHBOARD } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class DashboardService {
	static GetAverageTableOccupancy = async (params) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/average-table-occupancy`,
			method: 'GET',
		}
		return apiFactory(api, {}, params)
	}

	static GetAccommodationDashboard = async (params) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/accommodation`,
			method: 'GET',
		}
		return apiFactory(api, {}, params)
	}

	static GetReservationWeekly = async () => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/weekly-reservations`,
			method: 'GET',
		}
		return apiFactory(api)
	}
	static GetTableTurnaroundTime = async (params) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/table-turnaround-time`,
			method: 'GET',
		}
		return apiFactory(api, {}, params)
	}
	static GetReservationSourceAnalysis = async (params) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/reservation-source-analysis`,
			method: 'GET',
		}
		return apiFactory(api, {}, params)
	}

	static GetGuestAttendance = async (params) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/analytics/guest-attendance`,
			method: 'GET',
		}
		return apiFactory(api, {}, params)
	}

	static GetPopularItems = async () => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/analytics/popular-items`,
			method: 'GET',
		}
		return apiFactory(api)
	}

	static GetTopGuests = async () => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/customer-insights/top-guests`,
			method: 'GET',
		}
		return apiFactory(api)
	}

	static GetGuestsOverview = async (query) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/customer-insights/guests-overview`,
			method: 'GET',
			query,
		}
		return apiFactory(api)
	}

	static GetRevenue = async (param) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/food-delivery-revenue`,
			method: 'GET',
		}
		return apiFactory(api, {}, param)
	}

	static GetDashboard = async (param) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/food-delivery-dashboard`,
			method: 'GET',
		}
		return apiFactory(api, {}, param)
	}

	static getGuestsOverview = async (query) => {
		let api = {
			content_type: 'application/json',
			url: `${API_DASHBOARD}/customer-insights/guests-overview`,
			method: 'GET',
			query,
		}
		return apiFactory(api, {})
	}
}
