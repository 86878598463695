import React, { useEffect, useState } from "react";
import { Modal, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Paper, Checkbox } from "@mui/material";

import { boxStyle } from "../../constants/types";
import { createUUID } from "../../utils/common";
import tableIcon from "../../assets/svgs/table_white.svg";

export default function TableShapeModal({ showModal, onClose }) {
    const [visible, setVisible] = useState(false);

    const [tableShapes, setTableShapes] = useState([
        { id: 1, shape: 'Round', description: 'Good for conversation and encourages intimacy.\n' +
                'Ideal for small to medium-sized groups', min_chair: 1, max_chair: 4},
        { id: 2, shape: 'Square', description: 'Great for group seating and easy to push together.\n' +
                ' Allows for a variety of seating configurations', min_chair: 1, max_chair: 8},
        { id: 3, shape: 'Rectangular', description: 'Can accommodate larger groups and encourages conversation\n' +
                'between people sitting across from each other.', min_chair: 1, max_chair: 14},
        { id: 4, shape: 'Oval', description: 'Similar to round tables, but can seat more people. Ideal for family-style dining.', min_chair: 1, max_chair: 10},
        { id: 5, shape: 'Booths', description: 'Comfortable and private seating option that encourages intimacy. Great for couples and small groups', min_chair: 1, max_chair: 2},
        { id: 6, shape: 'Communal', description: 'Promotes a sense of community and encourages conversation between strangers. Ideal for large groups and busy restaurants.', min_chair: 1, max_chair: 2},
        { id: 7, shape: 'Bar', description: 'Allows for casual seating and promotes social interaction with bartenders and other customers. Great for solo diners and small groups.', min_chair: 1, max_chair: 6},
        { id: 8, shape: 'High-Top or Pub Tables', description: 'Tall tables, often used in bars or casual dining areas. They encourage a more relaxed atmosphere and social interaction. ', min_chair: 1, max_chair: 6},
        { id: 9, shape: 'U-shape', description: 'U-shaped tables are typically used for larger groups or events, and can help to create a more inclusive dining experience. They consist of several tables arranged in a U-shape, with guests seated around the perimeter.', min_chair: 1, max_chair: 10},
        { id: 10, shape: 'Hexagonal', description: 'Hexagonal tables have six sides and can be arranged in various configurations. They are unique and can accommodate different group sizes.', min_chair: 1, max_chair: 4},
        { id: 11, shape: 'Triangle', description: 'Triangle-shaped tables are often used in cozy corners or for smaller groups. They encourage conversation between guests.', min_chair: 1, max_chair: 3},
        { id: 12, shape: 'Octagonal', description: 'Octagonal tables have eight sides and are suitable for larger groups or events. They provide a sense of exclusivity.', min_chair: 1, max_chair: 4},
        { id: 13, shape: 'Picnic Tables', description: 'Outdoor seating options often found in parks or casual dining areas. They are ideal for communal dining. ', min_chair: 1, max_chair: 2},
        { id: 14, shape: 'Horseshoe', description: ' Similar to the U-shape, horseshoe tables are used for events and meetings. They allow for better interaction among participants.', min_chair: 1, max_chair: 10},
        { id: 15, shape: 'Conference Tables', description: 'Large, rectangular or oval tables used for meetings or events. They can accommodate a significant number of people.', min_chair: 1, max_chair: 12},
        { id: 16, shape: 'Cocktail Tables', description: 'Tall, small tables used for standing and mingling. They are often found at cocktail parties and events.', min_chair: 1, max_chair: 4},
        { id: 17, shape: 'Kid\'s Tables', description: 'Smaller, kid-sized tables and chairs for families with children. These tables are designed to make dining more comfortable for kids.', min_chair: 1, max_chair: 4},
        { id: 18, shape: 'Outdoor Bistro Tables', description: ' Small, often round or square tables used on outdoor patios for intimate dining. ', min_chair: 1, max_chair: 4},
    ])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    useEffect(() => {
        setVisible(showModal);
    }, [showModal]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChange = product_id => {
        setTableShapes(v => v.map(item => {
            if (item.id === product_id) {
                return { ...item, synced: !item.synced }
            } else {
                return item
            }
        }))
    }

    return (
        <div>
            <Modal
                open={visible}
                onClose={onClose}
                className="category-modal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...boxStyle, width: 900}} className="outline-none">
                    <div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
                        <div className="p-2 bg-gray7 rounded-full">
                            <div className="bg-black1 p-2 rounded-full">
                                <img src={tableIcon} alt="icon" />
                            </div>
                        </div>
                        <label className="text-20 font-semibold text-black1">Different table shapes</label>
                    </div>
                    <div className="flex flex-col pt-0 px-024">
                        {tableShapes.length &&
                            <>
                                <div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead className="bg-gray7">
                                                <TableRow key={createUUID()}>
                                                    <TableCell
                                                        className="!text-gray5 !text-left !font-medium !text-base"
                                                        align="right"
                                                    >
                                                        Shape
                                                    </TableCell>
                                                    <TableCell
                                                        className="!text-gray5 !text-left !font-medium !text-base"
                                                        align="right"
                                                    >
                                                        Description
                                                    </TableCell>
                                                    <TableCell
                                                        className="!text-gray5 !text-left !font-medium !text-base"
                                                        align="right"
                                                    >
                                                        Min. Chairs
                                                    </TableCell>
                                                    <TableCell
                                                        className="!text-gray5 !text-left !font-medium !text-base"
                                                        align="right"
                                                    >
                                                        Max. Chairs
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableShapes
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    .map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell
                                                                align="left"
                                                                className="!py-3.5 !text-gray5 !text-left !text-base"
                                                            >
                                                                {row.shape}
                                                            </TableCell>

                                                            <TableCell
                                                                align="left"
                                                                className="!py-3.5 !text-gray5 !text-left !text-base"
                                                            >
                                                                {row.description}
                                                            </TableCell>

                                                            <TableCell
                                                                align="left"
                                                                className="!py-3.5 !text-gray5 !text-left !text-base"
                                                            >
                                                                {row.min_chair}
                                                            </TableCell>

                                                            <TableCell
                                                                align="left"
                                                                className="!py-3.5 !text-gray5 !text-left !text-base"
                                                            >
                                                                {row.max_chair}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </>
                        }
                    </div>

                    <div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
                        <button
                            className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1 w-20"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        {/* <button className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white" onClick={onSubmit}>
                     {loading ? <CircularProgress size={16} /> : data?.id != null ? 'Update' : 'Create'}
                  </button> */}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
