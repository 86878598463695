import { Card, CardContent } from '@mui/material'
import React, { useState } from 'react'
import { walletText } from '.'
import referralIcon from '../../../assets/svgs/referral.svg'
import searchIcon from '../../../assets/svgs/search.svg'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'

const SubscriptionsTable = ({
	featureUsageCredits = [],
	featureUsageCreditBalance,
}) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const columns = [
		{ label: walletText('credits'), field: 'amount' },
		{ label: walletText('transaction_type'), field: 'transaction_type' },
		{ label: walletText('date'), field: 'date' },
		{ label: walletText('referral'), field: 'referral' },
		{ label: walletText('note'), field: 'note' },
	]

	const mapTransactionType = (type) => {
		switch (type) {
			case 'increase':
				return (
					<span className="bg-green-500 px-2 py-0.5 w-max text-white text-sm font-bold rounded">
						INCREASE
					</span>
				)
			case 'decrease':
				return (
					<span className="bg-red-500 px-2 py-0.5 w-max text-white text-sm font-bold rounded">
						DECREASE
					</span>
				)
			case 'expired':
				return (
					<span className="bg-yellow-500 px-2 py-0.5 w-max text-white text-sm font-bold rounded">
						EXPIRED
					</span>
				)
			default:
				return <span>{type.toUpperCase()}</span>
		}
	}

	const mappedData = featureUsageCredits.map((row) => ({
		...row,
		transaction_type: mapTransactionType(row.transaction_type),
	}))

	return (
		<div className="p-6 mb-4">
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row items-center justify-between p-7">
						<div className="flex flex-row items-center">
							<div className="bg-gray2 p-3 rounded-lg mr-6">
								<img
									src={referralIcon}
									alt={walletText('referral')}
									className="w-6 h-6"
								/>
							</div>
							<div className="flex flex-col justify-center items-start">
								<p className="text-base text-black1 font-medium">
									{walletText('features_usage_credit')}
								</p>
								<div className="flex flex-row justify-center items-center mt-1">
									<div className="text-xl text-black1 font-medium mr-5">
										{walletText('credits_balance_overview')}
									</div>
								</div>
							</div>
							<div className="w-[80px]" />
							<div className="flex flex-col justify-center items-start">
								<p className="text-base text-black1 font-medium">
									{walletText('balance')}
								</p>
								<div className="mt-1 text-xl text-black1 font-medium">
									{featureUsageCreditBalance} {walletText('credits')}
								</div>
							</div>
						</div>
					</div>
				</CardContent>
				<CardContent className="!p-0">
					{featureUsageCredits.length > 0 ? (
						<>
							<div className="flex flex-col overflow-auto max-h-[70vh]">
								<GenericTable data={mappedData} columns={columns} />
							</div>
							<GenericPagination
								count={featureUsageCredits.length}
								page={page}
								rowsPerPage={rowsPerPage}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</>
					) : (
						<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
							<div className="bg-gray7 !p-2 rounded-full">
								<div className="bg-black1 !p-2 rounded-full">
									<img src={searchIcon} alt={walletText('search')} />
								</div>
							</div>
							<span className="text-black1 text-lg">
								{walletText('no_feature_usage_credits')}
							</span>
							<span className="text-gray5 text-base mt-1">
								{walletText('no_feature_usage_credits_available')}
							</span>
						</div>
					)}
				</CardContent>
			</Card>
		</div>
	)
}

export default SubscriptionsTable
