export const peakHoursColumns = [
	{ field: 'hour', label: 'Hour', align: 'center' },
	{ field: 'avgGuests', label: 'Avg. Guests', align: 'center' },
	{ field: 'revenue', label: 'Revenue', align: 'center' },
]

export const peakHoursData = [
	{ hour: '12:00', avgGuests: 20, revenue: 500 },
	{ hour: '13:00', avgGuests: 30, revenue: 750 },
	{ hour: '14:00', avgGuests: 25, revenue: 625 },
	// ... more hourly data
]
