import { Card, CardContent } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NotificationManager } from 'react-notifications';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomDateTimePicker from '../../components/custom/CustomDateTimePicker';
import ReservationModal from '../../components/reservation/reservationModal';
import GuestService from '../../services/guestService';
import ReservationService from '../../services/reservationService';
import TableService from '../../services/tableService';
import './index.css';

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import plusIcon from '../../assets/svgs/plus.svg';
import v2Cancel from '../../assets/svgs/v2-cancel.svg';
import LoadingScreen from '../../components/custom/LoadingScreen';
import FeedbackButtons from '../../components/feedback';
import GenericPagination from '../../components/generic-table/generic-pagination';
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil';
import ReservationCancelModal from './ReservationCancelModal';
import ReservationConfirmModal from './ReservationConfirmModal';

import SelectFilter from '../../components/dropdowns/select-filter';
import ReservationTable from './reservation-table';

export const reservationsText = (text) => t(`reservations.view.${text}`);

const ReservationsScreen = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [reservations, setReservations] = useState([]);
	const [isReserveModelOpen, setReserveModelOpen] = useState(false);
	const [guestOptions, setGuestOptions] = useState([]);
	const [tables, setTables] = useState([]);
	const [guest, setGuest] = useState();
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [loaded, setLoaded] = useState(false);
	const [callEnd, setCallEnd] = useState({ table: false, guest: false });
	const [reservation, setReservation] = useState({});
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const { short_code } = useSelector((state) => state.app);
	const [dropdownOpen, setDropdownOpen] = useState(false)



	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		getReservationData()
		TableService.GetAll()
			.then((res) => {
				setCallEnd((v) => ({ ...v, table: true }))
				setTables(res?.tables)
			})
			.catch(() => {
				setCallEnd((v) => ({ ...v, table: true }))
				throw new Error(
					'An error occurred while fetching tables for reservations'
				)
			})
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (callEnd.guest && callEnd.table) {
			setLoaded(true)
		}
	}, [callEnd])

	useEffect(() => {
		if (date && guest) {
			getFilteredData(date, guest)
		} else if (date) {
			getFilteredData(date)
		}
	}, [date, guest])

	const resetGuestOptions = () => {
		GuestService.GetAll()
			.then((res) => {
				setCallEnd((v) => ({ ...v, guest: true }))
				let newGuestOptions = res.data
					.filter((o) => o.is_main)
					.map((item) => {
						return { value: item.id, label: item.name }
					})
				setGuestOptions([
					{ value: null, label: reservationsText('all') },
					...newGuestOptions,
				])
				getFilteredData(date, guest)
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'all guests',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'all guests',
				})
				setCallEnd((v) => ({ ...v, guest: true }))
				throw new Error(
					'An error occurred while fetching guests for reservations'
				)
			})
	}
	const handleReservationDetail = (item) => {
		navigate(`${location.pathname}/${item.id}`)
	}
	const handleNewReservation = () => {
		setReserveModelOpen(true)
		setReservation(undefined)
	}
	const getReservationData = () => {
		resetGuestOptions()
	}

	const getFilteredData = async (param_date, param_guest) => {
		let param = {}
		param.start_date = moment(param_date).format('YYYY-MM-DD')
		if (param_guest && param_guest.value) {
			param.guest_id = param_guest.value
		}
		ReservationService.Filter(param)
			.then((res) => {
				let allReservations = JSON.parse(JSON.stringify(res.data))
				setReservations(allReservations.filter((o) => o.confirmed !== 2))
				identifyVenue(short_code)
				trackEvent('Reservations', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('Reservations', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'screen',
				})
			})
	}

	const onConfirm = () => {
		ReservationService.Confirm(reservation)
			.then(() => {
				NotificationManager.success('Successfully confirmed')
				getReservationData()
				setShowConfirmModal(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	const onCancel = () => {
		ReservationService.Cancel(reservation)
			.then(() => {
				NotificationManager.success('Successfully cancelled')
				getReservationData()
				setShowCancelModal(false)
			})
			.catch(() => {
				NotificationManager.error('Failed', '')
			})
	}

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	return (
		<>
			<div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
				{loaded ? (
					<div className="h-full right-0 p-6 z-20 flex flex-col">
						<Card>
							<CardContent className="!p-0">
								<div className="flex justify-between items-center pt-2 px-6">
									<div className="flex items-center">
										<span className="text-black1 text-xl font-medium">
											{reservationsText('reservations')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{reservations.length}{' '}
											{reservationsText('reservationsCount')}
										</span>
									</div>
									<FeedbackButtons type={reservationsText('reservations')} />
								</div>

								<div
									className="w-fit mt-0 flex justify-between items-center px-6"
									style={{ width: '-webkit-fill-available' }}
								>
									<div className="flex items-center gap-2">
										<SelectFilter
											items={guestOptions}
											selectedItem={
												guest ? guest.label : reservationsText('all_guests')
											}
											onChange={setGuest}
											toggleDropdown={toggleDropdown}
											dropdownOpen={dropdownOpen}
											className="mt-[6px] h-10"
										/>
										<CustomDateTimePicker
											date={date}
											handleDateChange={(newDate) =>
												setDate(moment(newDate).format('YYYY-MM-DD'))
											}
											onlyDate={true}
										/>
										<div>
											<img
												onClick={() => {
													setGuest(null)
													setDate(moment().format('YYYY-MM-DD'))
												}}
												className="cursor-pointer"
												src={v2Cancel}
												alt=""
											/>
										</div>
									</div>
									<div
										onClick={handleNewReservation}
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
									>
										<img src={plusIcon} alt="plus" className="h-6 w-6" />
										<span className="ml-2 text-white font-medium">
											{reservationsText('addNewReservation')}
										</span>
									</div>
								</div>

								<div className="!p-0">
									<ReservationTable
										{...{
											handleReservationDetail,
											setReservation,
											setShowConfirmModal,
											setShowCancelModal,
											reservations,
											page,
											rowsPerPage,
										}}
									/>

									<GenericPagination
										rowsPerPage={rowsPerPage}
										page={page}
										setPage={setPage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										count={reservations.length}
										onPageChange={handleChangePage}
									/>
								</div>
							</CardContent>
						</Card>
					</div>
				) : (
					<LoadingScreen />
				)}
				<ReservationModal
					isModelOpen={isReserveModelOpen}
					setModelOpen={setReserveModelOpen}
					tables={tables}
					handleClose={() => setReserveModelOpen(false)}
					date={date}
					getReservationData={getReservationData}
				/>

				<ReservationCancelModal
					onClose={() => setShowCancelModal(false)}
					onSubmit={onCancel}
					showModal={showCancelModal}
				/>

				<ReservationConfirmModal
					showModal={showConfirmModal}
					onSubmit={onConfirm}
					onClose={() => setShowConfirmModal(false)}
				/>
			</div>
		</>
	)
};

export default ReservationsScreen;
