import {
	faChevronDown as iconDown,
	faChevronUp as iconUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import helpfullStandardIcon from '../../assets/svgs/more-settings/helpfull-articles.svg'
import productRoadmapIcon from '../../assets/svgs/more-settings/product-roadmap.svg'
import logoutWhiteIcon from '../../assets/svgs/v2-logout.svg'

const MoreSettings = ({
	visibleMenu = false,
	setMoreSettingVisible,
	moreSettingVisible,
	isVisionTrack,
	visible,
	handleCloseUserMenu,
}) => {
	return (
		<div className="w-full p-5">
			<div
				className={`bg-[#240B3B] w-full flex flex-col items-center  rounded-[20px] ${
					visibleMenu ? 'px-4' : ''
				}`}
			>
				<div
					className={`text-16 font-semibold flex items-center text-[#E39DFF] w-full py-3 hover:cursor-pointer `}
					onClick={() => setMoreSettingVisible((v) => !v)}
				>
					<span
						className={`flex items-center w-full ${
							visibleMenu ? 'justify-start' : 'justify-center'
						}`}
					>
						<span
							className={`text-[#240B3B] bg-[#E39DFF] w-[15px] h-[15px] text-20 flex items-center justify-center text-center ${
								visibleMenu ? 'mr-4' : ''
							}`}
						>
							{moreSettingVisible ? '-' : '+'}
						</span>
						{visibleMenu && !isVisionTrack && 'More Options'}
					</span>
					{visibleMenu && !isVisionTrack && (
						<FontAwesomeIcon
							icon={visible ? iconUp : iconDown}
							className="fa-xs"
						/>
					)}
				</div>
				{moreSettingVisible && (
					<>
						<a
							className={`text-14 font-normal flex  gap-2 items-center text-[#E1E1E1] w-full py-3 ${
								visibleMenu ? 'justify-start' : 'justify-center'
							}`}
							href="#"
						>
							<img
								src={helpfullStandardIcon}
								alt="logout"
								className="min-w-[20px]"
							/>
							{visibleMenu && !isVisionTrack && 'Helpful Articles'}
						</a>
						<a
							className={`text-14 font-normal flex gap-2 items-center text-[#E1E1E1] w-full py-3 ${
								visibleMenu ? 'justify-start' : 'justify-center'
							}`}
							href="#"
						>
							<img
								src={productRoadmapIcon}
								alt="logout"
								className="min-w-[20px]"
							/>
							{visibleMenu && !isVisionTrack && 'Product Roadmap'}
						</a>
						<div
							className={`text-14 font-normal flex justify-start gap-2 items-center text-[#E1E1E1] w-full py-3 hover:cursor-pointer ${
								visibleMenu ? 'justify-start' : 'justify-center'
							}`}
							onClick={() => handleCloseUserMenu()}
						>
							<img
								src={logoutWhiteIcon}
								alt="logout"
								className="min-w-[20px]"
							/>
							{visibleMenu && !isVisionTrack && 'Log Out'}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default MoreSettings
