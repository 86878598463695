import { Card, CardContent, CircularProgress, Tooltip } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TBDIcon from '../../assets/svgs/TBD.svg'
import Svg_chevron_right from '../../assets/svgs/chevron-right.svg'
import InformationIcon from '../../assets/svgs/info-icon.svg'
import FeaturesModal from '../../components/subscriptions/featuresModal'
import SubscriptionContactSales from '../../components/subscriptions/subscriptionContactSales'
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../helpers/venueHelpers'
import LoginService from '../../services/loginService'
import subscriptionService from '../../services/subscriptionService'
import {
	SET_IS_TRIAL_MODE,
	SET_PLAN_FEATURES,
	SET_SHOW_UPGRADE_FROM_TRIAL,
	SET_SUBSCRIPTION_PLAN,
	SET_USER_PROFILE,
} from '../../store/actions/types'
import { isEmpty } from '../../utils/common'
import {
	KEYS,
	clearAppToken,
	removeStorageKey,
	setAppToken,
	setCookieVenueType,
	setLoyaltyProgramId,
	setVenueBasicInfo,
	setVenueShortcode,
} from '../../utils/storage'

export const subscriptionsText = (text) => t(`account.subscriptions.${text}`)

const pricingData = {
	food: {
		monthly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 49,
			launchSubtitle: subscriptionsText('food.launchSubtitle'),
			elevate: 129,
			elevateSubtitle: subscriptionsText('food.elevateSubtitle'),
			optimize: 249,
			optimizeSubtitle: subscriptionsText('food.optimizeSubtitle'),
		},
		yearly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 490,
			launchSubtitle: subscriptionsText('food.launchSubtitle'),
			elevate: 1290,
			elevateSubtitle: subscriptionsText('food.elevateSubtitle'),
			optimize: 2490,
			optimizeSubtitle: subscriptionsText('food.optimizeSubtitle'),
		},
	},
	sport_entertainment: {
		monthly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 79,
			launchSubtitle: subscriptionsText('sport_entertainment.launchSubtitle'),
			elevate: 169,
			elevateSubtitle: subscriptionsText('sport_entertainment.elevateSubtitle'),
			optimize: 329,
			optimizeSubtitle: subscriptionsText('sport_entertainment.optimizeSubtitle'),
		},
		yearly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 790,
			launchSubtitle: subscriptionsText('sport_entertainment.launchSubtitle'),
			elevate: 1690,
			elevateSubtitle: subscriptionsText('sport_entertainment.elevateSubtitle'),
			optimize: 3290,
			optimizeSubtitle: subscriptionsText('sport_entertainment.optimizeSubtitle'),
		},
	},
	accommodation: {
		monthly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 59,
			launchSubtitle: subscriptionsText('accommodation.launchSubtitle'),
			elevate: 149,
			elevateSubtitle: subscriptionsText('accommodation.elevateSubtitle'),
			optimize: 299,
			optimizeSubtitle: subscriptionsText('accommodation.optimizeSubtitle'),
		},
		yearly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 590,
			launchSubtitle: subscriptionsText('accommodation.launchSubtitle'),
			elevate: 1490,
			elevateSubtitle: subscriptionsText('accommodation.elevateSubtitle'),
			optimize: 2990,
			optimizeSubtitle: subscriptionsText('accommodation.optimizeSubtitle'),
		},
	},
	retail: {
		monthly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 69,
			launchSubtitle: subscriptionsText('retail.launchSubtitle'),
			elevate: 159,
			elevateSubtitle: subscriptionsText('retail.elevateSubtitle'),
			optimize: 319,
			optimizeSubtitle: subscriptionsText('retail.optimizeSubtitle'),
		},
		yearly: {
			free: subscriptionsText('accessEssentialFeatures'),
			freeSubtitle: subscriptionsText('freeSubtitle'),
			launch: 690,
			launchSubtitle: subscriptionsText('retail.launchSubtitle'),
			elevate: 1590,
			elevateSubtitle: subscriptionsText('retail.elevateSubtitle'),
			optimize: 3190,
			optimizeSubtitle: subscriptionsText('retail.optimizeSubtitle'),
		},
	},
}


const Subscriptions = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const cookies = new Cookies()
	const [loading, setLoading] = useState(false)
	const [subscriptionData, setSubscriptionData] = useState(null)
	const [billingMethod, setBillingMethod] = useState('monthly')

	// const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const {
		short_code,
	} = useSelector((state) => state.app);

	const [startingTrial1, setStartingTrial1] = useState(false)
	const [startingTrial2, setStartingTrial2] = useState(false)
	const [startingTrial3, setStartingTrial3] = useState(false)
	const [selectedVenueType, setSelectedVenueType] = useState('food')
	const [contactSalesModal, setContactSalesModal] = useState(false)
	const [featuresModal, setFeaturesModal] = useState(false)

	// When the billing method is changed
	const onBillingMethodChange = (method) => {
		setBillingMethod(method)
	}

	useEffect(() => {
		if (isAccommodation(venueType)) {
			setSelectedVenueType('accommodation')
		} else if (isFB(venueType)) {
			setSelectedVenueType('food')
		} else if (isRetailVenue(venueType)) {
			setSelectedVenueType('retail')
		} else if (isEntertainmentVenue(venueType)) {
			setSelectedVenueType('sport_entertainment')
		}
	}, [venueType])

	const suggestedPlan = 'none'

	const currentPricing = pricingData[selectedVenueType][billingMethod]

	// For the free plan
	const freePrice = currentPricing ? currentPricing.free : 0
	const freeSubtitle = currentPricing ? currentPricing.freeSubtitle : 0

	// For the launch plan
	const launchPrice = currentPricing ? currentPricing.launch : 0
	const launchSubtitle = currentPricing ? currentPricing.launchSubtitle : ''

	// For the elevate plan
	const elevatePrice = currentPricing ? currentPricing.elevate : 0
	const elevateSubtitle = currentPricing ? currentPricing.elevateSubtitle : ''

	// For the optimize plan
	const optimizePrice = currentPricing ? currentPricing.optimize : 0
	const optimizeSubtitle = currentPricing ? currentPricing.optimizeSubtitle : ''

	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search)

		if (query.get('success')) {
			confirmSubscription(query.get('session_id'))
		} else if (query.get('canceled')) {
			NotificationManager.error('Order cancelled', 'Info', 3000)
			setTimeout(() => {
				reloadWithoutQueryParams()
			}, 3000)
		} else {
			loadSubscription()
		}
	}, [])

	const reloadWithoutQueryParams = () => {
		navigate(window.location.pathname)
	}

	const isCurrentPlan = (plan_id) => {
		if (
			!isEmpty(subscriptionData?.subscription_id) &&
			subscriptionData?.plan_id == plan_id
		) {
			return true
		}
		return false
	}

	const onStartTrial = (product) => {
		//
		// // Assuming 'billingMethod' is either "monthly" or "yearly"
		// const priceId = getPricingPlanId(product, billingMethod);
		//
		// const stripeData = {
		// 	email: onboardingEmail,
		// 	price_id: priceId
		// };
		//
		// if (product === 'Launch') {
		// 	setStartingTrial1(true);
		// }
		//
		// if (product === 'Elevate') {
		// 	setStartingTrial2(true);
		// }
		//
		// if (product === 'Optimize') {
		// 	setStartingTrial3(true);
		// }
		//
		// createCheckoutSession(stripeData)
		// 	.then((data) => {
		// 		document.location.href = data?.url
		//
		// 		if (product === 'Launch') {
		// 			setStartingTrial1(false);
		// 		}
		//
		// 		if (product === 'Elevate') {
		// 			setStartingTrial2(false);
		// 		}
		//
		// 		if (product === 'Optimize') {
		// 			setStartingTrial3(false);
		// 		}
		// 	})
		// 	.catch((err) => {
		// 		if (product === 'Launch') {
		// 			setStartingTrial1(false);
		// 		}
		//
		// 		if (product === 'Elevate') {
		// 			setStartingTrial2(false);
		// 		}
		//
		// 		if (product === 'Optimize') {
		// 			setStartingTrial3(false);
		// 		}
		//
		// 		if (typeof err?.error === 'string') {
		// 			NotificationManager.error(err?.error);
		// 		} else {
		// 			const errorKeys = Object.keys(err?.error || {});
		// 			if (errorKeys.length > 0) {
		// 				const firstErrorKey = errorKeys[0];
		// 				const firstErrorMessage = err?.error[firstErrorKey][0];
		// 				NotificationManager.error(firstErrorMessage || "Something went wrong!");
		// 			} else {
		// 				NotificationManager.error("Something went wrong!");
		// 			}
		// 		}
		//
		// 		throw new Error("An error occurred while subscribing with trial");
		//
		// 	})
	}

	const loadSubscription = () => {
		subscriptionService
			.getSubscription()
			.then((data) => {
				const returnData = data?.data

				if (!returnData?.is_active) {
					LoginService.logout()
						.then((res) => {
							dispatch({ type: SET_USER_PROFILE, payload: null })
							setAppToken(null)
							setVenueShortcode(null)
							setVenueBasicInfo(null)
							setCookieVenueType(null)
							setLoyaltyProgramId(null)

							// Dispatch and save to storage the new status to update the application's state
							dispatch({
								type: SET_IS_TRIAL_MODE,
								payload: false,
							})
							removeStorageKey(KEYS.IS_TRIAL_MODE)

							dispatch({
								type: SET_SHOW_UPGRADE_FROM_TRIAL,
								payload: false,
							})
							removeStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL)
							dispatch({
								type: SET_PLAN_FEATURES,
								payload: [],
							})
							removeStorageKey(KEYS.PLAN_FEATURES)

							dispatch({
								type: SET_SUBSCRIPTION_PLAN,
								payload: {},
							})
							removeStorageKey(KEYS.SUBSCRIPTION_PLAN)
						})
						.catch((e) => {
							throw new Error('Problem on logout')
						})
						.finally(() => {
							navigate('../')
							clearAppToken()
						})
				}

				setLoading(false)

				setBillingMethod(
					returnData.plan.recurring === 'Monthly' ? 'monthly' : 'yearly'
				)

				const formattedAmount = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency:
						returnData.plan.currency === 'usd'
							? 'USD'
							: returnData.plan.currency, // Change this to the appropriate currency code
				}).format(returnData.plan.price / 100)

				setSubscriptionData({
					is_active: returnData.is_active,
					is_trial_mode: returnData.is_trial_mode,
					next_billing_date: returnData.next_billing_date,
					cancel_at: returnData.cancel_at,
					plan_name: returnData.plan.name,
					plan_cycle: returnData.plan.recurring,
					plan_price: formattedAmount,
					plan_currency:
						returnData.plan.currency === 'usd' ? '$' : returnData.plan.currency,
					trial_end: returnData.trial_end,
					plan_features: returnData.features,
				})
			})
			.catch((error) => {
				setLoading(false)
				const message = error.message || 'Something went wrong!'
				NotificationManager.error(message, 'Info', 3000)
			})
	}

	const confirmSubscription = (session_id) => {
		subscriptionService
			.confirmSubscription({
				session_id: session_id,
			})
			.then((data) => {
				setLoading(false)
				setSubscriptionData({
					subscription_id: data.subscription_id,
					plan_type: data.plan_type,
					plan_id: data.plan_id,
				})
				NotificationManager.success(
					'Subscription confirmed successfully!',
					3000
				)
				setTimeout(() => {
					reloadWithoutQueryParams()
				}, 3000)
			})
			.catch((error) => {
				setLoading(false)
				const message = error.message || 'Something went wrong!'
				NotificationManager.error(message, 'Info', 3000)
			})
	}

	const onSubmit = (plan_id) => {
		window.scrollTo(0, 0)

		subscriptionService
			.changeSubscription({
				plan_id: plan_id,
				mode: billingMethod,
			})
			.then((data) => {
				setLoading(false)
				document.location.href = data?.url
			})
			.catch((error) => {
				setLoading(false)
				const message = error.message || 'Something went wrong!'
				NotificationManager.error(message, 'Info', 3000)
			})
	}

	return (
		// <div className={'h-full bg-white overflow-y-auto'} style={{ height: 'calc(100vh - 76px - 68px)' }}>
		<div
			className={'h-full p-6 overflow-y-auto'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<div className="grid md:grid-cols-2 grid-cols-1 gap-10">
				<div className="flex flex-col">
					<div className="flex flex-row items-center p-0 mb-4">
						<label className="font-medium text-lg text-black1">
							{subscriptionsText('currentPlan')}
						</label>
					</div>
					<div className="flex flex-col items-start p-0 w-full h-full bg-white rounded-12">
						<div className="flex flex-col items-start flex-1 w-full shadow-lg_2 bg-white rounded-12 px-024 pt-024 pb-016">
							{/* Header Section */}
							<div className="flex justify-between items-center pb-2 w-full">
								<div className="text-xl font-semibold">
									{subscriptionData?.plan_name} {subscriptionData?.plan_cycle}
								</div>
								<button
									className="text-[#240b3b] hover:bg-[#f4f4f4] px-4 py-2 rounded"
									style={{
										boxShadow:
											'rgba(47, 47, 47, 0.04) 0px 0px 1px 0px, rgba(47, 47, 47, 0.12) 0px 1px 4px 0px',
										fontWeight: 'bold',
									}}
								>
									{subscriptionsText('changePlan')}
								</button>
							</div>

							{/* Payment and Annual Plan Section */}
							<div className="flex justify-between items-center py-2 w-full">
								<div className="text-l">{subscriptionsText('payment')}</div>
								<div className="text-2xl font-bold">
									{subscriptionData?.plan_price}
									<span className="text-base text-gray-500">
										{' '}
										/{' '}
										{subscriptionData?.plan_cycle === 'Monthly'
											? subscriptionsText('month')
											: subscriptionsText('year')}
									</span>
								</div>
								<button className="text-[#240b3b] font-bold px-4 py-2 rounded bg-transparent hover:bg-[#240b3b] hover:text-white transition-colors duration-300">
									{subscriptionData?.plan_name === 'Discover' && ''}
									{subscriptionData?.plan_name !== 'Discover' &&
										subscriptionData?.plan_cycle === 'Monthly' &&
										subscriptionsText('saveWithAnnualPlan')}
									{subscriptionData?.plan_name !== 'Discover' &&
										subscriptionData?.plan_cycle === 'Yearly' &&
										subscriptionsText('switchToMonthlyPlan')}
								</button>
							</div>

							{/* Next Payment Section */}
							{subscriptionData?.plan_name !== 'Discover' && (
								<div className="flex justify-between items-center py-2 w-full">
									<div className="text-l">
										{subscriptionsText('nextPayment')}
									</div>
									{subscriptionData?.next_billing_date &&
										!subscriptionData?.is_trial_mode &&
										!subscriptionData?.cancel_at && (
											<div className="text-green-700 mr-2">
												{subscriptionsText('renewalOn')}{' '}
												<b>{subscriptionData?.next_billing_date}</b>
											</div>
										)}
									{subscriptionData?.cancel_at && (
										<div className="text-red-500 mr-2">
											{subscriptionsText('subscriptionCancelledAt')}{' '}
											<b>{subscriptionData?.cancel_at}</b>
										</div>
									)}
									{subscriptionData?.is_trial_mode &&
										!subscriptionData?.cancel_at && (
											<div className="text-red-500 mr-2">
												{subscriptionsText('trialExpiresOn')}{' '}
												<b>{subscriptionData?.trial_end}</b>
											</div>
										)}
									{subscriptionData?.is_trial_mode && (
										<button className="bg-[#240b3b] text-white px-4 py-2 rounded">
											{subscriptionsText('payNow')}
										</button>
									)}
								</div>
							)}

							{/* Cancel Subscription Section */}
							{!(
								subscriptionData?.plan_name === 'Discover' ||
								subscriptionData?.cancel_at != null
							) && (
								<div className="flex justify-start items-center py-2 w-full">
									<button className="text-[#767676] bg-[#f4f4f4] px-4 py-2 font-bold rounded">
										{subscriptionsText('cancelSubscription')}
									</button>
								</div>
							)}
						</div>
						<div
							className="flex flex-row justify-between px-024 py-016 w-full h-[52px] cursor-pointer"
							// onClick={() => onAction()}
						>
							<label className="text-black1 font-medium text-base">
								{subscriptionsText('applicableTaxes')}
							</label>
						</div>
					</div>
				</div>

				<div className="flex flex-col">
					<div className="flex flex-row items-center p-0 mb-4">
						<label className="font-medium text-lg text-black1">
							{subscriptionsText('manageBillingFeatures')}
						</label>
					</div>
					<div className="flex flex-col items-start p-0 w-full h-full bg-white rounded-12">
						<div className="w-full shadow-lg_2 bg-white rounded-12 px-024 pt-024 pb-016">
							{/* Header Section */}
							{/* Row 1 */}
							<div className="flex flex-row justify-between items-center pb-4">
								<div>
									<h2 className="font-semibold text-lg">
										{subscriptionData?.plan_name}{' '}
										{subscriptionsText('features')}
									</h2>
									<p className="text-sm text-gray-700 mt-1">
										{subscriptionData?.plan_name === 'Discover' && freeSubtitle}
										{subscriptionData?.plan_name === 'Launch' && launchSubtitle}
										{subscriptionData?.plan_name === 'Elevate' &&
											elevateSubtitle}
										{subscriptionData?.plan_name === 'Optimize' &&
											optimizeSubtitle}
										{subscriptionData?.plan_name === 'Enterprise' &&
											subscriptionsText('enterpriseSubtitle')}
									</p>
								</div>
								<button
									className="text-[#240b3b] hover:bg-[#f4f4f4] px-4 py-2 rounded"
									onClick={() => setFeaturesModal(true)}
									style={{
										boxShadow:
											'rgba(47, 47, 47, 0.04) 0px 0px 1px 0px, rgba(47, 47, 47, 0.12) 0px 1px 4px 0px',
										fontWeight: 'bold',
									}}
								>
									{subscriptionsText('featuresButton')}
								</button>
							</div>

							{/* Row 2 */}
							<div className="flex flex-row justify-between items-center py-4">
								<div>
									<h2 className="font-semibold text-lg">
										{subscriptionsText('invoices')}
									</h2>
									<p className="text-sm text-gray-700 mt-1">
										{subscriptionsText('invoicesSubtitle')}
									</p>
								</div>
								<button
									className="text-[#240b3b] hover:bg-[#f4f4f4] px-4 py-2 rounded"
									style={{
										boxShadow:
											'rgba(47, 47, 47, 0.04) 0px 0px 1px 0px, rgba(47, 47, 47, 0.12) 0px 1px 4px 0px',
										fontWeight: 'bold',
									}}
								>
									{subscriptionsText('viewInvoicesButton')}
								</button>
							</div>

							{/* Row 3 */}
							<div className="flex flex-row justify-between items-center py-4">
								<div>
									<h2 className="font-semibold text-lg">
										{subscriptionsText('paymentInformation')}
									</h2>
									<p className="text-sm text-gray-700 mt-1">
										{subscriptionsText('paymentInfoSubtitle')}
									</p>
								</div>
								<button
									className="text-[#240b3b] hover:bg-[#f4f4f4] px-4 py-2 rounded"
									style={{
										boxShadow:
											'rgba(47, 47, 47, 0.04) 0px 0px 1px 0px, rgba(47, 47, 47, 0.12) 0px 1px 4px 0px',
										fontWeight: 'bold',
									}}
								>
									{subscriptionsText('updatePaymentButton')}
								</button>
							</div>
						</div>
						<div
							className="flex flex-row justify-between px-024 py-016 w-full h-[52px] cursor-pointer"
							onClick={() => setFeaturesModal(true)}
						>
							<label className="text-black1 font-medium text-base">
								{subscriptionsText('viewFeaturesInDetail')}
							</label>
							<img src={Svg_chevron_right} alt="" />
						</div>
					</div>
				</div>
			</div>
			<Card className="mt-6" hidden={short_code === "BYB2929SCDE"}>
				<p
					style={{ padding: '20px 15px 20px 15px' }}
					className="text-black1 font-medium text-[20px]"
				>
					{subscriptionsText('manage_subscription')}
				</p>
				<CardContent style={{ background: '#f6f6f8' }}>
					<div className="md:block mt-9">
						<div className="align-col-middle">
							<div className=" w-full">
								<div className=" px-4  max-w-screen-main mx-auto flex flex-col justify-start ">
									<div>
										<div
											className="flex flex-col md:flex-row align-middle mb-4 md:mb-8"
											style={{ justifyContent: 'right' }}
										>
											<div className="flex h-full align-middle mt-4 md:mt-0">
												<div className="border-[1px] border-[#E3E3E3] flex flex-row rounded-[15px] align-middle ">
													<div
														onClick={() => onBillingMethodChange('monthly')}
														// onClick={() => setBillingMethod("monthly")}
														className={`cursor-pointer text-[13px] leading-6 md:text-14 font-bold px-4 md:px-7 py-3 ${
															billingMethod === 'monthly'
																? 'text-white bg-[#240b3b] rounded-[15px]'
																: ''
														}`}
													>
														{subscriptionsText('monthly')}
													</div>
													<div
														onClick={() => onBillingMethodChange('yearly')}
														// onClick={() => setBillingMethod("yearly")}
														className={`cursor-pointer text-[13px] leading-6 md:text-14 font-bold px-4 md:px-7 py-3 ${
															billingMethod === 'yearly'
																? 'text-white bg-[#240b3b] rounded-[15px]'
																: ''
														} `}
													>
														{subscriptionsText('yearlySave')}
													</div>
												</div>
											</div>
										</div>

										<div
											className={
												'grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-3 xl:gap-3 mb-20'
											}
										>
											<div
												className={`rounded-[13px] border-[#E0E0E0] relative border-[1px] overflow-hidden`}
											>
												<div className="text-14 font-bold leading-6 text-white py-[6px] align-middle w-full rounded-t-[16px] bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]">
													{subscriptionsText('welcomeAboard')}
												</div>
												<div className="bg-white h-full rounded-2xl w-full flex flex-col">
													<div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
														<div className="h-full flex flex-col items-start">
															<div
																className={`flex w-full justify-left items-left leading-[8px] mb-2`}
															>
																<p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[0px]">
																	{subscriptionsText('planNameDiscover')}
																</p>
															</div>
															<p
																style={{ fontSize: '40px' }}
																className="text-[#333333] font-bold leading-[55px] align-middle flex mb-[16px]"
															>
																${freePrice}
																<span className="text-[15px] content-center ml-1">
																	{subscriptionsText(
																		billingMethod === 'monthly'
																			? 'month'
																			: 'year'
																	)}
																</span>
															</p>
															<p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
																{subscriptionsText('freeSubtitle')}
															</p>
														</div>
														<div className="flex">
															<button
																disabled={
																	subscriptionData?.plan_name === 'Discover'
																}
																className={`xl:text-14 w-full text-white py-[11px] rounded-[40px] transition ease-in-out hover:scale-105`}
																style={
																	subscriptionData?.plan_name === 'Discover'
																		? {
																				background: '#80808014',
																				pointerEvents: 'none',
																				color: '#240b3b',
																				border: '1PX SOLID #240b3b',
																		  }
																		: {
																				color: '#240b3b',
																				border: '1PX SOLID #240b3b',
																		  }
																}
															>
																{subscriptionData?.plan_name === 'Discover'
																	? subscriptionsText('currentPlan')
																	: subscriptionsText('downgradeToDiscover')}
															</button>
														</div>
													</div>
													<hr className="border-[#E0E0E0] border-[1px] mx-6" />
													<div className="mt-6 px-6 w-full">
														<p className="text-16 mb-6 font-bold">
															{subscriptionsText('discoverIncludes')}
														</p>
														{isFB(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'streamlinedReservationsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'streamlinedReservations'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'menuManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('menuManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicInventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicInventoryManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicAnalyticsAndReportingTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicAnalyticsAndReporting'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicGuestManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('basicGuestManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicMarketingStrategyTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicMarketingStrategy'
																		)}
																	</p>
																</div>
															</>
														)}

														{isEntertainmentVenue(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'bookingsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('bookingsManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'itemsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('itemsManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'inventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('inventoryManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'analyticsAndReportingTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('analyticsAndReporting')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'customerManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('customerManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'marketingToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('marketingTools')}
																	</p>
																</div>
															</>
														)}

														{isAccommodation(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'bookingsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('bookingsManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'unitsOrRoomsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'unitsOrRoomsManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'inventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('inventoryManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'itemsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('itemsManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'guestManagementTooltip75'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('guestManagement75')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'marketingToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('marketingTools')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'analyticsAndReportingTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'analyticsAndReporting75'
																		)}
																	</p>
																</div>
															</>
														)}

														{isRetailVenue(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'orderManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('orderManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'productsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('productsManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'inventoryManagementTooltip40'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('inventoryManagement40')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'dashboardAndRevenueAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-6"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'dashboardAndRevenueAnalytics'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'marketingToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('marketingTools')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicStoreManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('basicStoreManagement')}
																	</p>
																</div>
															</>
														)}
														<hr
															className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
															style={{ borderColor: '#827c5d' }}
														/>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('customization')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{subscriptionsText(
																	'notAvailableInDiscoverPlan'
																)}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('support')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText('accessToOnlineHelpCenter')}
															</p>
														</div>
													</div>
												</div>
											</div>

											<div
												className={`rounded-[13px] ${
													suggestedPlan === 'Launch'
														? 'border-[#333333] border-[2px] relative box-border'
														: 'border-[#E0E0E0] border-[1px] overflow-hidden'
												}`}
											>
												<div
													className={`text-14 font-bold leading-6 text-white py-[6px] align-middle w-full rounded-t-[16px] ${
														suggestedPlan === 'Launch'
															? 'absolute top-[-16px] bg-[#240b3b] border-[#240b3b]'
															: 'bg-[#808080] border-[#808080]'
													}`}
												>
													{subscriptionsText('greatValue')}
												</div>
												<div className="bg-white h-full rounded-2xl w-full flex flex-col">
													<div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
														<div className="h-full flex flex-col items-start">
															<div
																className={`flex w-full justify-left ${
																	suggestedPlan === 'Launch'
																		? 'items-center leading-[22px]'
																		: 'items-left leading-[8px]'
																} mb-2`}
															>
																<p
																	className={`text-20 text-[#333333] font-extrabold ${
																		suggestedPlan === 'Launch'
																			? ''
																			: 'leading-[22px] mb-[0px]'
																	}`}
																>
																	{subscriptionsText('planNameLaunch')}
																</p>
																{suggestedPlan === 'Launch' && (
																	<div
																		className={
																			'flex justify-center items-center'
																		}
																		style={{ marginLeft: '10px' }}
																	>
																		<div
																			className={`
                                    w-0 h-0 border-t-[8px] border-t-transparent
                                    border-r-[8px] border-r-[#240b3b]
                                    border-b-[8px] border-b-transparent`}
																		/>
																		<div
																			className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
																		>
																			{subscriptionsText('suggestedForYou')}
																		</div>
																	</div>
																)}
															</div>
															<p
																className="text-[#333333] font-bold leading-[55px] align-middle flex mb-[16px]"
																style={{ fontSize: '40px' }}
															>
																${launchPrice}
																<span className="text-[15px] content-center ml-1">
																	{subscriptionsText(
																		billingMethod === 'monthly'
																			? 'month'
																			: 'year'
																	)}
																</span>
															</p>
															<p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
																{subscriptionsText('launchSubtitle')}
															</p>
														</div>
														<div className="flex">
															<button
																disabled={
																	(subscriptionData?.plan_name === 'Launch' &&
																		subscriptionData?.plan_cycle === 'Yearly' &&
																		billingMethod === 'yearly') ||
																	(subscriptionData?.plan_name === 'Launch' &&
																		subscriptionData?.plan_cycle ===
																			'Monthly' &&
																		billingMethod === 'monthly') ||
																	startingTrial1
																}
																onClick={() => onStartTrial('Launch')}
																className={`xl:text-14 w-full text-white py-[11px] rounded-[40px] transition ease-in-out hover:scale-105`}
																style={
																	(subscriptionData?.plan_name === 'Launch' &&
																		subscriptionData?.plan_cycle === 'Yearly' &&
																		billingMethod === 'yearly') ||
																	(subscriptionData?.plan_name === 'Launch' &&
																		subscriptionData?.plan_cycle ===
																			'Monthly' &&
																		billingMethod === 'monthly')
																		? {
																				background: '#80808014',
																				pointerEvents: 'none',
																				color: '#240b3b',
																				border: '1PX SOLID #240b3b',
																		  }
																		: {
																				color: '#240b3b',
																				border: '1PX SOLID #240b3b',
																		  }
																}
															>
																{startingTrial1 ? (
																	<CircularProgress
																		sx={{
																			'& .MuiCircularProgress-svg': {
																				color: 'rgb(46, 39, 60)',
																			},
																		}}
																		size={16}
																	/>
																) : subscriptionData?.plan_name ===
																  'Discover' ? (
																	subscriptionsText('startFreeTrial')
																) : subscriptionData?.plan_name === 'Launch' ? (
																	subscriptionData?.plan_cycle === 'Monthly' &&
																	billingMethod === 'yearly' ? (
																		subscriptionsText('switchToYearly')
																	) : subscriptionData?.plan_cycle ===
																			'Yearly' &&
																	  billingMethod === 'monthly' ? (
																		subscriptionsText('switchToMonthly')
																	) : subscriptionData?.plan_cycle ===
																	  'Yearly' ? (
																		subscriptionsText('currentPlan')
																	) : (
																		subscriptionsText('currentPlan')
																	)
																) : (
																	subscriptionsText('downgradeToLaunch')
																)}
															</button>
														</div>
														{subscriptionData?.plan_name === 'Discover' && (
															<Tooltip
																arrow
																placement="bottom"
																title={subscriptionsText('freeTrialTooltip')}
															>
																<div
																	style={{
																		color: 'rgb(130 124 93)',
																		textAlign: 'center',
																		marginTop: '10px',
																		fontWeight: 'bold',
																		cursor: 'pointer',
																	}}
																>
																	{subscriptionsText('freeTrial14Days')}
																</div>
															</Tooltip>
														)}
													</div>
													<hr className="border-[#E0E0E0] border-[1px] mx-6" />
													<div className="mt-6 px-6 w-full">
														<p className="text-16 mb-6 font-bold">
															{subscriptionsText('allFromDiscoverPlus')}
														</p>

														{isFB(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'unlimitedStreamlinedReservationsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'unlimitedStreamlinedReservations'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'fullInventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'fullInventoryManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'staffManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('staffManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'enhancedMarketingStrategyToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'enhancedMarketingStrategyTools'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'loyaltyAndRetentionProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'loyaltyAndRetentionProgram'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'paymentLinksTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('paymentLinks')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicGuestSurveysAndRatingsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicGuestSurveysAndRatings'
																		)}
																	</p>
																</div>
															</>
														)}

														{isEntertainmentVenue(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'unlimitedBookingsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'unlimitedBookingsManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'fullInventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'fullInventoryManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'staffManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('staffManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'enhancedMarketingToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-2"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'enhancedMarketingTools'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicLoyaltyAndRetentionProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicLoyaltyAndRetentionProgram'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicPaymentLinksTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('basicPaymentLinks')}
																	</p>
																</div>
															</>
														)}

														{isAccommodation(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'unlimitedBookingsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'unlimitedBookingsManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'unlimitedUnitsOrRoomsManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'unlimitedUnitsOrRoomsManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'fullInventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'fullInventoryManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'staffManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('staffManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'enhancedMarketingToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-2"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'enhancedMarketingTools'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicLoyaltyAndRetentionProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicLoyaltyAndRetentionProgram'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicPaymentLinksTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('basicPaymentLinks')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicGuestSurveysAndRatingsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicGuestSurveysAndRatings'
																		)}
																	</p>
																</div>
															</>
														)}

														{isRetailVenue(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'unlimitedOrderManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'unlimitedOrderManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'fullInventoryManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'fullInventoryManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'staffManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('staffManagement')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'enhancedMarketingToolsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-2"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'enhancedMarketingTools'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="top"
																		title={subscriptionsText(
																			'basicLoyaltyAndRetentionProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicLoyaltyAndRetentionProgram'
																		)}
																	</p>
																</div>
															</>
														)}

														<hr
															className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
															style={{ borderColor: '#827c5d' }}
														/>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('customization')}
														</p>

														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'customizeLookAndFeelTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-1"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText('customizeLookAndFeel')}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('communicationTemplates')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'communicationTemplatesTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText(
																	'communicationTemplatesText'
																)}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('support')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText('emailSupportTooltip')}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText('emailSupport')}
															</p>
														</div>
													</div>
												</div>
											</div>

											<div
												className={`rounded-[13px] ${
													suggestedPlan === 'Elevate'
														? 'border-[#333333] border-[2px] relative box-border'
														: 'border-[#E0E0E0] border-[1px] overflow-hidden'
												}`}
											>
												<div
													className={`text-14 font-bold leading-6 text-white py-[6px] align-middle w-full rounded-t-[16px] ${
														suggestedPlan === 'Elevate'
															? 'absolute top-[-16px] bg-[#240b3b] border-[#240b3b]'
															: 'bg-[#808080] border-[#808080]'
													}`}
												>
													{subscriptionsText('justRight')}
												</div>
												<div className="bg-white h-full w-full rounded-[13px] flex flex-col">
													<div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start">
														<div className="h-full flex flex-col items-start">
															<div
																className={`flex w-full justify-left ${
																	suggestedPlan === 'Elevate'
																		? 'items-center leading-[22px]'
																		: 'items-left leading-[8px]'
																} mb-2`}
															>
																<p
																	className={`text-20 text-[#333333] font-extrabold ${
																		suggestedPlan === 'Elevate'
																			? ''
																			: 'leading-[22px] mb-[0px]'
																	}`}
																>
																	{subscriptionsText('planNameElevate')}
																</p>
																{suggestedPlan === 'Elevate' && (
																	<div
																		className="flex justify-center items-center"
																		style={{ marginLeft: '10px' }}
																	>
																		<div
																			className={`
                                    w-0 h-0 border-t-[8px] border-t-transparent
                                    border-r-[8px] border-r-[#240b3b]
                                    border-b-[8px] border-b-transparent`}
																		/>
																		<div
																			className={`px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]`}
																		>
																			{subscriptionsText('suggestedForYou')}
																		</div>
																	</div>
																)}
															</div>
															<p
																className="text-[#333333] font-bold leading-[55px] align-middle flex mb-[16px]"
																style={{
																	fontSize: '40px',
																	marginBottom: `${
																		suggestedPlan === 'Elevate'
																			? '20px'
																			: '15px'
																	}`,
																}}
															>
																${elevatePrice}
																<span className="text-[15px] content-center ml-1">
																	{subscriptionsText(
																		billingMethod === 'monthly'
																			? 'month'
																			: 'year'
																	)}
																</span>
															</p>
															<p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
																{subscriptionsText('elevateSubtitle')}
															</p>
														</div>
														<div className="flex">
															<button
																disabled={
																	(subscriptionData?.plan_name === 'Elevate' &&
																		subscriptionData?.plan_cycle === 'Yearly' &&
																		billingMethod === 'yearly') ||
																	(subscriptionData?.plan_name === 'Elevate' &&
																		subscriptionData?.plan_cycle ===
																			'Monthly' &&
																		billingMethod === 'monthly') ||
																	startingTrial2
																}
																onClick={() => onStartTrial('Elevate')}
																className={`xl:text-14 w-full text-white py-[11px] rounded-[40px] transition ease-in-out hover:scale-105`}
																style={
																	(subscriptionData?.plan_name === 'Elevate' &&
																		subscriptionData?.plan_cycle === 'Yearly' &&
																		billingMethod === 'yearly') ||
																	(subscriptionData?.plan_name === 'Elevate' &&
																		subscriptionData?.plan_cycle ===
																			'Monthly' &&
																		billingMethod === 'monthly')
																		? {
																				background: '#80808014',
																				pointerEvents: 'none',
																				color: '#240b3b',
																				border: '1px solid #240b3b',
																		  }
																		: {
																				color: '#240b3b',
																				border: '1px solid #240b3b',
																		  }
																}
															>
																{startingTrial2 ? (
																	<CircularProgress
																		sx={{
																			'& .MuiCircularProgress-svg': {
																				color: 'rgb(46, 39, 60)',
																			},
																		}}
																		size={16}
																	/>
																) : subscriptionData?.plan_name ===
																  'Discover' ? (
																	subscriptionsText('startFreeTrial')
																) : subscriptionData?.plan_name ===
																  'Elevate' ? (
																	subscriptionData?.plan_cycle === 'Monthly' &&
																	billingMethod === 'yearly' ? (
																		subscriptionsText('switchToYearly')
																	) : subscriptionData?.plan_cycle ===
																			'Yearly' &&
																	  billingMethod === 'monthly' ? (
																		subscriptionsText('switchToMonthly')
																	) : subscriptionData?.plan_cycle ===
																	  'Yearly' ? (
																		subscriptionsText('currentPlan')
																	) : (
																		subscriptionsText('currentPlan')
																	)
																) : subscriptionData?.plan_name === 'Launch' ? (
																	subscriptionsText('upgrade')
																) : subscriptionData?.plan_name ===
																		'Optimize' ||
																  subscriptionData?.plan_name ===
																		'Enterprise' ? (
																	subscriptionsText('downgradeToElevate')
																) : (
																	subscriptionsText('startFreeTrial')
																)}
															</button>
														</div>
														{subscriptionData?.plan_name === 'Discover' && (
															<Tooltip
																arrow
																placement="bottom"
																title={subscriptionsText('freeTrialTooltip')}
															>
																<div
																	style={{
																		color: 'rgb(130 124 93)',
																		textAlign: 'center',
																		marginTop: '10px',
																		fontWeight: 'bold',
																		cursor: 'pointer',
																	}}
																>
																	{subscriptionsText('freeTrial14Days')}
																</div>
															</Tooltip>
														)}
													</div>
													<hr className="border-[#E0E0E0] border-[1px] mx-6" />
													<div className="mt-6 px-6 w-[100%]">
														<p className="text-16 mb-6 font-bold">
															{subscriptionsText('allFromLaunchPlus')}
														</p>
														{isFB(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'marketingAutomationTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('marketingAutomation')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'affiliatePartnershipsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('affiliatePartnerships')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('advancedAnalytics')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'deliveryOrdersManagementTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'deliveryOrdersManagement'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedGuestBehaviorAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'advancedGuestBehaviorAnalytics'
																		)}
																	</p>
																</div>
															</>
														)}
														{isEntertainmentVenue(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'marketingAutomationTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('marketingAutomation')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'basicAffiliatePartnershipsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicAffiliatePartnerships'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('advancedAnalytics')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'entertainmentMembershipProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'entertainmentMembershipProgram'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedCustomerSurveysAndRatingsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'advancedCustomerSurveysAndRatings'
																		)}
																	</p>
																</div>
															</>
														)}
														{isAccommodation(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'marketingAutomationIncludingGuestJourneySupportTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'marketingAutomationIncludingGuestJourneySupport'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'basicAffiliatePartnershipsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicAffiliatePartnerships'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('advancedAnalytics')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'iCalIntegrationTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('iCalIntegration')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedGuestBehaviorAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'advancedGuestBehaviorAnalytics'
																		)}
																	</p>
																</div>
															</>
														)}
														{isRetailVenue(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'marketingAutomationTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('marketingAutomation')}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'basicAffiliatePartnershipsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'basicAffiliatePartnerships'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'advancedDashboardAndRevenueAnalyticsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'advancedDashboardAndRevenueAnalytics'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'retailCustomerLoyaltyProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'retailCustomerLoyaltyProgram'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'customerSurveysAndRatingsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'customerSurveysAndRatings'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'consistentInventoryTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('consistentInventory')}
																	</p>
																</div>
															</>
														)}
														<hr
															className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
															style={{ borderColor: '#827c5d' }}
														/>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('customization')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'enhancePlatformWithAdditionalFeaturesTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{isFB(venueType) &&
																	subscriptionsText('featureAdditionsFB')}
																{isAccommodation(venueType) &&
																	subscriptionsText(
																		'featureAdditionsAccommodation'
																	)}
																{isEntertainmentVenue(venueType) &&
																	subscriptionsText(
																		'featureAdditionsEntertainment'
																	)}
																{isRetailVenue(venueType) &&
																	subscriptionsText('featureAdditionsRetail')}
															</p>
														</div>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'customizePlatformLookAndFeelTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{isFB(venueType) &&
																	subscriptionsText(
																		'advancedUICustomizationFB'
																	)}
																{isAccommodation(venueType) &&
																	subscriptionsText(
																		'advancedUICustomizationAccommodation'
																	)}
																{isEntertainmentVenue(venueType) &&
																	subscriptionsText(
																		'advancedUICustomizationEntertainment'
																	)}
																{isRetailVenue(venueType) &&
																	subscriptionsText(
																		'advancedUICustomizationRetail'
																	)}
															</p>
														</div>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{subscriptionsText('elevateDiscount')}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('support')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'getAssistanceViaEmailTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText('elevateSupport')}
															</p>
														</div>
													</div>
												</div>
											</div>

											<div
												className={`rounded-[13px] ${
													suggestedPlan === 'Optimize'
														? 'border-[#333333] border-[2px] relative box-border'
														: 'border-[#E0E0E0] border-[1px] overflow-hidden'
												}`}
											>
												<div
													className={`text-14 font-bold leading-6 text-white py-[6px] align-middle  w-full rounded-t-[16px] ${
														suggestedPlan === 'Optimize'
															? 'absolute top-[-16px] bg-[#240b3b] border-[#240b3b]'
															: 'bg-[#808080] border-[#808080]'
													}`}
												>
													{subscriptionsText('worthIt')}
												</div>
												<div className="bg-white h-full w-full rounded-[13px] flex flex-col">
													<div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
														<div className="h-full flex flex-col items-start">
															<div
																className={`flex w-full justify-left ${
																	suggestedPlan === 'Optimize'
																		? 'items-center leading-[22px]'
																		: 'items-left leading-[8px]'
																} mb-2`}
															>
																<p
																	className={`text-20 text-[#333333] font-extrabold ${
																		suggestedPlan === 'Optimize'
																			? ''
																			: 'leading-[22px] mb-[0px]'
																	}`}
																>
																	{subscriptionsText('optimize')}
																</p>
																{suggestedPlan === 'Optimize' && (
																	<div
																		className="flex justify-center items-center"
																		style={{ marginLeft: '10px' }}
																	>
																		<div className="w-0 h-0 border-t-[8px] border-t-transparent border-r-[8px] border-r-[#240b3b] border-b-[8px] border-b-transparent" />
																		<div className="px-3 py-1 rounded-[8px] bg-[#240b3b] text-white text-[12px]">
																			{subscriptionsText('suggestedForYou')}
																		</div>
																	</div>
																)}
															</div>
															<p
																className="text-[#333333] font-bold leading-[55px] align-middle flex mb-[16px]"
																style={{
																	fontSize: '40px',
																	marginTop: `${
																		suggestedPlan === 'Optimize' ? '5px' : '0px'
																	}`,
																}}
															>
																${optimizePrice}
																<span className="text-[15px] content-center ml-1">
																	{billingMethod === 'monthly'
																		? subscriptionsText('month')
																		: subscriptionsText('year')}
																</span>
															</p>
															<p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
																{subscriptionsText('optimizeSubtitle')}
															</p>
														</div>
														<div className="flex">
															<button
																disabled={
																	(subscriptionData?.plan_name === 'Optimize' &&
																		subscriptionData?.plan_cycle === 'Yearly' &&
																		billingMethod === 'yearly') ||
																	(subscriptionData?.plan_name === 'Optimize' &&
																		subscriptionData?.plan_cycle ===
																			'Monthly' &&
																		billingMethod === 'monthly') ||
																	startingTrial3
																}
																onClick={() => onStartTrial('Optimize')}
																className={`xl:text-14 w-full text-white py-[11px] rounded-[40px] transition ease-in-out hover:scale-105`}
																style={
																	(subscriptionData?.plan_name === 'Optimize' &&
																		subscriptionData?.plan_cycle === 'Yearly' &&
																		billingMethod === 'yearly') ||
																	(subscriptionData?.plan_name === 'Optimize' &&
																		subscriptionData?.plan_cycle ===
																			'Monthly' &&
																		billingMethod === 'monthly')
																		? {
																				background: '#80808014',
																				pointerEvents: 'none',
																				color: '#240b3b',
																				border: '1PX SOLID #240b3b',
																		  }
																		: {
																				color: '#240b3b',
																				border: '1PX SOLID #240b3b',
																		  }
																}
															>
																{startingTrial3 ? (
																	<CircularProgress
																		sx={{
																			'& .MuiCircularProgress-svg': {
																				color: 'rgb(46, 39, 60)',
																			},
																		}}
																		size={16}
																	/>
																) : subscriptionData?.plan_name ===
																  'Discover' ? (
																	subscriptionsText('startYourFreeTrial')
																) : subscriptionData?.plan_name ===
																  'Optimize' ? (
																	subscriptionData?.plan_cycle === 'Monthly' &&
																	billingMethod === 'yearly' ? (
																		subscriptionsText('switchToYearly')
																	) : subscriptionData?.plan_cycle ===
																			'Yearly' &&
																	  billingMethod === 'monthly' ? (
																		subscriptionsText('switchToMonthly')
																	) : (
																		subscriptionsText('currentPlan')
																	)
																) : subscriptionData?.plan_name === 'Launch' ||
																  subscriptionData?.plan_name === 'Elevate' ? (
																	subscriptionsText('upgrade')
																) : subscriptionData?.plan_name ===
																  'Enterprise' ? (
																	subscriptionsText('downgradeToOptimize')
																) : (
																	subscriptionsText('startYourFreeTrial')
																)}
															</button>
														</div>
														{subscriptionData?.plan_name === 'Discover' && (
															<Tooltip
																arrow
																placement="bottom"
																title={subscriptionsText(
																	'extended30DayTrialTooltip'
																)}
															>
																<div
																	style={{
																		color: 'rgb(130 124 93)',
																		textAlign: 'center',
																		marginTop: '10px',
																		fontWeight: 'bold',
																		cursor: 'pointer',
																	}}
																>
																	{subscriptionsText('30DaysFreeTrial')}
																</div>
															</Tooltip>
														)}
													</div>
													<hr className="border-[#E0E0E0] border-[1px] mx-6" />
													<div className="mt-6 px-6 w-full">
														<p className="text-16 mb-6 font-bold">
															{subscriptionsText('allFromElevatePlus')}
														</p>
														{isFB(venueType) && (
															<>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'premiumTablesWithPricingAndFloorplanVisibilityTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-5"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'premiumTablesWithPricingAndFloorplanVisibility'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'diningGuestLoyaltyProgramTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-3"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'diningGuestLoyaltyProgram'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'customizableBrandProfileTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText(
																			'customizableBrandProfile'
																		)}
																	</p>
																</div>
																<div className="flex flex-row-reverse justify-between gap-2">
																	<Tooltip
																		arrow
																		placement="bottom"
																		title={subscriptionsText(
																			'inPersonPaymentsTooltip'
																		)}
																	>
																		<img
																			src={InformationIcon}
																			className="cursor-pointer w-5 h-5 mt-1"
																		/>
																	</Tooltip>
																	<p
																		className="text-16 mb-6"
																		style={{ textWrap: 'balance' }}
																	>
																		{subscriptionsText('inPersonPayments')}
																	</p>
																</div>
															</>
														)}
														{/* Additional venue type conditionals with tooltips and texts based on JSON file keys */}
														{/* Update the following sections for other venue types such as isEntertainmentVenue, isAccommodation, and isRetailVenue */}
														<hr
															className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
															style={{ borderColor: '#827c5d' }}
														/>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('customization')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'enhanceYourPlatformWithAdditionalFeaturesTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{isFB(venueType) &&
																	subscriptionsText(
																		'majorFeatureIntegrationFB'
																	)}
																{isAccommodation(venueType) &&
																	subscriptionsText(
																		'majorFeatureIntegrationAccommodation'
																	)}
																{isEntertainmentVenue(venueType) &&
																	subscriptionsText(
																		'majorFeatureIntegrationEntertainment'
																	)}
																{isRetailVenue(venueType) &&
																	subscriptionsText(
																		'majorFeatureIntegrationRetail'
																	)}
															</p>
														</div>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{subscriptionsText(
																	'discountOnCustomizationForOptimizeSubscribers'
																)}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('support')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'directPhoneSupportTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText(
																	'phoneAndEmailSupportWith12HourResponseTime'
																)}
															</p>
														</div>
													</div>
												</div>
											</div>

											<div
												className={`rounded-[13px] border-[#E0E0E0] border-[1px] overflow-hidden`}
											>
												<div className="text-14 font-bold leading-6 text-white py-[6px] align-middle w-full rounded-t-[16px] bg-[#808080] border-[#808080] border-[1px] box-content translate-x-[-1px]">
													{subscriptionsText('builtForYou')}
												</div>
												<div className="bg-white h-full rounded-2xl w-full flex flex-col">
													<div className="bg-white pt-10 pb-6 px-6 flex flex-col justify-start border-[#E0E0E0]">
														<div className="h-full flex flex-col items-start">
															<p className="text-20 text-[#333333] font-extrabold leading-[22px] mb-[6px]">
																{subscriptionsText('enterprise')}
															</p>
															<img
																src={TBDIcon}
																className="h-[55px] w-[55px]"
																style={{
																	marginTop: '8px',
																	marginBottom: '8px',
																}}
															/>
															<p className="text-[#676879] text-14 font-medium leading-[22px] min-h-[88px] mb-[18px]">
																{subscriptionsText('enterpriseSubtitleCustom')}
															</p>
														</div>
														<div className="flex">
															<Tooltip arrow placement="top" title={''}>
																<button
																	disabled={
																		(subscriptionData?.plan_name ===
																			'Enterprise' &&
																			subscriptionData?.plan_cycle ===
																				'Yearly' &&
																			billingMethod === 'yearly') ||
																		(subscriptionData?.plan_name ===
																			'Enterprise' &&
																			subscriptionData?.plan_cycle ===
																				'Monthly' &&
																			billingMethod === 'monthly')
																	}
																	className={`xl:text-14 w-full text-white py-[11px] rounded-[40px] transition ease-in-out hover:scale-105`}
																	onClick={() => setContactSalesModal(true)}
																	style={
																		(subscriptionData?.plan_name ===
																			'Enterprise' &&
																			subscriptionData?.plan_cycle ===
																				'Yearly' &&
																			billingMethod === 'yearly') ||
																		(subscriptionData?.plan_name ===
																			'Enterprise' &&
																			subscriptionData?.plan_cycle ===
																				'Monthly' &&
																			billingMethod === 'monthly')
																			? {
																					background: '#80808014',
																					pointerEvents: 'none',
																					color: '#240b3b',
																					border: '1px solid #240b3b',
																			  }
																			: {
																					color: '#240b3b',
																					border: '1px solid #240b3b',
																			  }
																	}
																>
																	{subscriptionData?.plan_name === 'Discover'
																		? subscriptionsText('contactUs')
																		: subscriptionData?.plan_name ===
																		  'Enterprise'
																		? subscriptionData?.plan_cycle ===
																				'Monthly' && billingMethod === 'yearly'
																			? subscriptionsText('switchToYearly')
																			: subscriptionData?.plan_cycle ===
																					'Yearly' &&
																			  billingMethod === 'monthly'
																			? subscriptionsText('switchToMonthly')
																			: subscriptionData?.plan_cycle ===
																			  'Yearly'
																			? subscriptionsText('currentPlan')
																			: subscriptionsText('currentPlan')
																		: subscriptionData?.plan_name ===
																				'Launch' ||
																		  subscriptionData?.plan_name ===
																				'Elevate' ||
																		  subscriptionData?.plan_name === 'Optimize'
																		? subscriptionsText('contactUs')
																		: subscriptionData?.plan_cycle ===
																				'Monthly' && billingMethod === 'yearly'
																		? subscriptionsText('switchToYearly')
																		: subscriptionData?.plan_cycle ===
																				'Yearly' && billingMethod === 'monthly'
																		? subscriptionsText('switchToMonthly')
																		: subscriptionData?.plan_cycle === 'Yearly'
																		? subscriptionsText('currentPlan')
																		: subscriptionsText('currentPlan')}
																</button>
															</Tooltip>
														</div>
														{subscriptionData?.plan_name === 'Discover' && (
															<Tooltip
																arrow
																placement="bottom"
																title={subscriptionsText('extended30DayTrialTooltip')}
															>
																<div
																	style={{
																		color: 'rgb(130 124 93)',
																		textAlign: 'center',
																		marginTop: '10px',
																		fontWeight: 'bold',
																		cursor: 'pointer',
																	}}
																>
																	{subscriptionsText('30DaysFreeTrial')}
																</div>
															</Tooltip>
														)}
													</div>
													<hr className="border-[#E0E0E0] border-[1px] mx-6" />
													<div className="mt-6 px-6 w-full">
														<p className="text-16 mb-6 font-bold">
															{subscriptionsText('allFromOptimizePlus')}
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip
																arrow
																placement="top"
																title={subscriptionsText(
																	'dedicatedAccountManagerTooltip'
																)}
															>
																<img
																	src={InformationIcon}
																	className="cursor-pointer w-5 h-5 mt-4"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{subscriptionsText('dedicatedAccountManager')}
															</p>
														</div>
														<div className="flex flex-row-reverse justify-between gap-2">
															<img
																src={InformationIcon}
																className="w-5 h-5 mt-8"
															/>
															<p className="text-16 mb-6">
																{subscriptionsText(
																	'customIntegrationsAndFeatureDevelopment'
																)}
															</p>
														</div>
														<div className="flex flex-row-reverse justify-between gap-2">
															<img
																src={InformationIcon}
																className="w-5 h-5 mt-3"
															/>
															<p className="text-16 mb-6">
																{subscriptionsText('premiumSupportAndTraining')}
															</p>
														</div>
														<hr
															className="border-[#E0E0E0] border-[2px] mb-4 mx-0"
															style={{ borderColor: '#827c5d' }}
														/>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('price')}:
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-5"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{isAccommodation(venueType)
																	? subscriptionsText('customPricingAccommodation')
																	: isRetailVenue(venueType)
																	? subscriptionsText('customPricingRetail')
																	: isEntertainmentVenue(venueType)
																	? subscriptionsText('customPricingEntertainment')
																	: isFB(venueType)
																	? subscriptionsText('customPricingFB')
																	: subscriptionsText('customPricingDefault')}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('customization')}:
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-7"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{subscriptionsText(
																	'customFeatureDevelopmentBasedOnRequirements'
																)}
															</p>
														</div>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-5"
																/>
															</Tooltip>
															<p className="text-16 mb-6">
																{subscriptionsText(
																	'discountOnCustomizationForEnterpriseSubscribers'
																)}
															</p>
														</div>
														<p className="text-16 mb-2 font-bold">
															{subscriptionsText('support')}:
														</p>
														<div className="flex flex-row-reverse justify-between gap-2">
															<Tooltip arrow placement="top" title={''}>
																<img
																	src={InformationIcon}
																	className="w-5 h-5 mt-3"
																/>
															</Tooltip>
															<p
																className="text-16 mb-6"
																style={{ textWrap: 'balance' }}
															>
																{subscriptionsText('24/7PriorityPhoneAndEmailSupport')}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			<SubscriptionContactSales
				showModal={contactSalesModal}
				onConfirm={() => {
					setContactSalesModal(false)
					// refreshData();
				}}
				onClose={() => setContactSalesModal(false)}
				onRefresh={null}
			/>

			<FeaturesModal
				showModal={featuresModal}
				planName={subscriptionData?.plan_name}
				features={subscriptionData?.plan_features}
				onConfirm={() => {
					setFeaturesModal(false)
					// refreshData();
				}}
				onClose={() => setFeaturesModal(false)}
				onRefresh={null}
			/>
		</div>
	)
}

export default Subscriptions
