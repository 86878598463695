import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import { Tooltip } from '@mui/material'
import React from 'react'
import { isCursorPointer } from '../../../helpers/is-cursor-pointer'
import AllCarts from './all-carts'

export default function NewOrderSidebar({
	title,
	cart,
	deleteFromCart,
	totalCost,
	handleClick,
	handleOrder,
	isCustomerDisabled,
	isOrderDisabled,
}) {
	return (
		<div className="p-6 h-[100vh] bg-[#240b3b] relative min-w-[409px]">
			<div className="flex md:hidden cursor-pointer pb-4 border-b  border-[#393C49] items-center gap-2 ">
				<span className="text-white">
					<ChevronLeftOutlinedIcon />
				</span>
				<span
					className={`text-[18px] md:text-[20px] w-full text-center md:text-start text-white font-semibold `}
				>
					{title}
				</span>
			</div>
			<p className="hidden md:block text-white text-20 font-semibold leading-[140%]">
				{title}
			</p>

			<div className="flex border-b-[1px] border-[#393C49] gap-2 py-3">
				<button className="bg-white rounded-[5px] py-[7px] px-[12px] text-[#240b3b] text-14 font-semibold leading-[140%] w-fit">
					Dine in
				</button>
				<button className="text-white border-white border-[1px]  rounded-[5px] py-[7px] px-[12px] bg-[#240b3b] text-14 w-fit font-semibold leading-[140%]">
					To Go
				</button>
			</div>

			<div>
				<div className={`border-[#393C49]  py-3 overflow-auto h-[75vh]`}>
					<AllCarts
						cart={cart}
						deleteFromCart={deleteFromCart}
						totalCost={totalCost}
					/>
				</div>
			</div>

			<div className="w-full absolute bottom-6 px-6 right-0">
				<div className="flex flex-col gap-1">
					<Tooltip
						title={
							isCustomerDisabled
								? 'Before adding a customer, please make sure to include at least one item in the cart.'
								: ''
						}
					>
						<button
							onClick={handleClick}
							disabled={isCustomerDisabled}
							className={`${'bg-white text-[#1F1D2B]'} w-full flex-1  rounded-[8px] p-[14px]  text-center     text-14 font-semibold leading-[140%] ${isCursorPointer(
								isCustomerDisabled
							)}`}
						>
							Add Customer
						</button>
					</Tooltip>
					<Tooltip
						title={
							isCustomerDisabled || isOrderDisabled
								? 'Please ensure you add both an order to the cart and a customer before completing the order'
								: ''
						}
					>
						<button
							onClick={handleOrder}
							disabled={isCustomerDisabled || isOrderDisabled}
							className={`${'bg-white text-[#1F1D2B]'} w-full flex-1  rounded-[8px] p-[14px]  text-center     text-14 font-semibold leading-[140%] ${isCursorPointer(
								isCustomerDisabled || isOrderDisabled
							)} `}
						>
							Complete Order
						</button>
					</Tooltip>
				</div>
			</div>
		</div>
	)
}
