import React, { useState } from 'react'
import { devicesViewText } from '.'
import SelectFilter from '../../../components/dropdowns/select-filter'
import { plusIcon, searchIcon } from '../../guests/guests/icons'
import '../../guests/guests/index.css'
import { brandData, setupItems } from './data'

const DeviceFilters = ({
	setSearch,
	search,
	setOpen,
	selectedFilter,
	setSelectedFilter,
	selectedSetup,
	setSelectedSetup,
	setSelectedDevice,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [dropdownSetupOpen, setDropdownSetupOpen] = useState(false)

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const toggleSetupDropdown = () => {
		setDropdownSetupOpen(!dropdownSetupOpen)
	}

	const onSelectFilter = (tier) => {
		setSelectedFilter(tier)
		setDropdownOpen(false)
	}

	const onSelectSetup = (tier) => {
		setSelectedSetup(tier)
		setDropdownSetupOpen(false)
	}

	return (
		<div className="filter-container grid grid-cols-3 items-center gap-4 !px-0 !m-0">
			<div className="grid grid-cols-5 gap-2 w-full">
				<div
					style={{ height: '40px' }}
					className="col-span-3 input-search relative w-full"
				>
					<span className="search-icon">{searchIcon}</span>
					<input
						type="search"
						placeholder={devicesViewText('placeholder')}
						className="w-full text-[15px]"
						onChange={(e) => setSearch(e.target.value)}
						value={search}
					/>
				</div>
			</div>
			<div className="w-full flex gap-2 justify-end">
				<div className="flex items-center gap-2">

					<button onClick={() => {
								setOpen(true)
								setSelectedDevice(undefined)
					}} className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5'>
						{plusIcon} <span>{devicesViewText('add_device')}</span>
					</button>

				</div>

				<SelectFilter
					onChange={onSelectFilter}
					selectedItem={selectedFilter?.label}
					toggleDropdown={toggleDropdown}
					dropdownOpen={dropdownOpen}
					className="!w-fit"
					items={[
						{
							label: 'Filter by Brand',
							value: 'filter_by_brand',
						},
						...brandData,
					]}
					showArrowDown={false}
				/>

				<SelectFilter
					onChange={onSelectSetup}
					selectedItem={selectedSetup?.label}
					toggleDropdown={toggleSetupDropdown}
					dropdownOpen={dropdownSetupOpen}
					className="!w-fit"
					items={setupItems}
					showArrowDown={false}
				/>
			</div>
		</div>
	)
}

export default DeviceFilters
