export const ticketsData = [
	{
		id: 1,
		unique_code: 'Code 1',
		no_of_umbrella: 10,
		reservation_no: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 2,
		unique_code: 'Code 2',
		no_of_umbrella: 15,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 3,
		unique_code: 'Code 3',
		no_of_umbrella: 20,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 4,
		unique_code: 'Code 4',
		no_of_umbrella: 18,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 5,
		unique_code: 'Code 5',
		no_of_umbrella: 30,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 6,
		unique_code: 'Code 6',
		no_of_umbrella: 40,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 7,
		unique_code: 'Code 7',
		no_of_umbrella: 15,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
	{
		id: 8,
		unique_code: 'Code 8',
		no_of_umbrella: 45,
		reservation_n0: 'Reservation No 1',
		umbrella_no: 'Umbrella 1',
		area_no: '1',
		created_at: '2024/03/22',
		status: 'Status',
	},
]

export const areas = [
	{ value: 'area_1', label: 'Area 1' },
	{ value: 'area_2', label: 'Area 2' },
	{ value: 'area_3', label: 'Area 3' },
	{ value: 'area_4', label: 'Area 4' },
	{ value: 'area_5', label: 'Area 5' },
	{ value: 'area_6', label: 'Area 6' },
	{ value: 'area_7', label: 'Area 7' },
]

export const price_rate_type = [
	{ value: 'entire', label: 'Entire' },
	{ value: 'per_seat', label: 'Per seat' },
]

export const currencies = [
	{ value: 'ALL', label: 'ALL' },
	{ value: 'EUR', label: 'EUR' },
	{ value: 'USD', label: 'USD' },
]

export const tryAtHomeIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="#000000"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<path d="M5 12l5 5L19 7" />
	</svg>
)
