import apiFactory from "../utils/apiFactory";
import {
  API_EMPLOYEE,
  API_PAYROLL,
  API_SCHEDULE_CONFLICT,
  API_SCHEDULE,
  API_ROLES,
  API_GENERATE_REPORTS,
  API_SCHEDULE_REQUESTS,
  API_APPROVE_TIME_OFF,
  API_STAFF
} from "../constants/API";

export default class StaffService {
  static Create = async (rawData) => {
    let api = {
      content_type: "application/json",
      url: API_EMPLOYEE,
      method: "POST",
    };
    return apiFactory(api, rawData);
  };
  static ReadAll = async () => {
    let api = {
      content_type: "application/json",
      url: API_EMPLOYEE,
      method: "GET",
    };
    return apiFactory(api);
  };

  static ReadAllStaff = async () => {
    let api = {
      content_type: "application/json",
      url: API_STAFF,
      method: "GET",
    };
    return apiFactory(api);
  };
  static ReadOne = async (index) => {
    let api = {
      content_type: "application/json",
      url: `${API_EMPLOYEE}/${index}`,
      method: "GET",
    };
    return apiFactory(api);
  };
  static Update = async (rawData) => {
    let api = {
      content_type: "application/json",
      url: `${API_EMPLOYEE}/${rawData.id}`,
      method: "PUT",
    };
    return apiFactory(api, rawData);
  };
  static Delete = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_EMPLOYEE}/${id}`,
      method: "DELETE",
    };
    return apiFactory(api);
  };
  
  static Confirm = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_EMPLOYEE}/${id}/confirm`,
      method: "PATCH",
    };
    return apiFactory(api, {confirmed : 2});
  };

  
  static PayrollCalculate = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_PAYROLL,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  
  static ScheduleConflicts = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_SCHEDULE_CONFLICT,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static Schedule = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_SCHEDULE,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  
  static GetRoles = async () => {
    let api = {
      content_type: "application/json",
      url: API_ROLES,
      method: "GET",
    };
    return apiFactory(api);
  };

  static GenerateReports = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_GENERATE_REPORTS,
      method: "POST",
    };
    return apiFactory(api, payload);
  }
  
  static ScheduleRequests = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_SCHEDULE_REQUESTS,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  
  static ApproveTOSchedule = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_APPROVE_TIME_OFF,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static CancelTOSchedule = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_APPROVE_TIME_OFF,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  
  static GetScheduleRequests = async () => {
    let api = {
      content_type: "application/json",
      url: API_SCHEDULE_REQUESTS,
      method: "GET",
    };
    return apiFactory(api);
  };
}
