import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import CustomDateTimePicker from '../../components/custom/CustomDateTimePicker'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericPagination from '../../components/generic-table/generic-pagination'
import GenericTable from '../../components/generic-table/generic-table'
import TableEmptyData from '../../components/table/TableEmptyData'
import TableService from '../../services/tableService'

export const resAvailableText = (text) => t(`reservations.Available.${text}`)

export function AvailableReservation() {
	const [data, setData] = useState([])
	const [date, setDate] = useState(new Date())
	const [loaded, setLoaded] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [date, page, rowsPerPage])

	const refreshData = () => {
		let start_time = moment(date).format('YYYY-MM-DD 11:00:00')
		let end_time = moment(date).format('YYYY-MM-DD 23:00:00')

		TableService.GetAvailables({ start_time, end_time })
			.then((res) => {
				setLoaded(true)
				setData(res.data)
			})
			.catch(() => {
				setLoaded(true)
			})
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const columns = [
		{ field: 'id', label: resAvailableText('nr') },
		{ field: 'name', label: resAvailableText('name') },
		{ field: 'size', label: resAvailableText('size') },
		{ field: 'seats', label: resAvailableText('nrOfSeats') },
		{ field: 'location', label: resAvailableText('location') },
		{ field: 'shape', label: resAvailableText('shape') },
	]

	const tableData = data
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		.map((row, index) => ({
			...row,
			id: index + 1,
		}))

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6 avail-list">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{resAvailableText('availableByDate')}
									</span>
								</div>
								<div className="flex flex-row items-center">
									<CustomDateTimePicker
										onlyDate={true}
										classes="small"
										value={date}
										onChange={setDate}
									/>
								</div>
							</div>
							{data && data.length ? (
								<>
									<div className="flex flex-col py-6 overflow-auto">
										<GenericTable
											data={tableData}
											columns={columns}
											className="mt-4"
										/>
									</div>
									<GenericPagination
										count={data.length}
										page={page}
										rowsPerPage={rowsPerPage}
										onPageChange={handlePageChange}
										onRowsPerPageChange={handleRowsPerPageChange}
										setPage={setPage}
										className="!p-2"
									/>
								</>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData content={resAvailableText('noData')} />
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
