import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom'
import CheckIcon from '../../../assets/svgs/checkmark.svg'
// import "./index.css";

const FoodSuccess = () => {
   	let params = useParams()
		let { state, currency } = useLocation()

		const [isMobile, setIsMobile] = useState(false)
		const { short_code } = useSelector((state) => state.app)

		useEffect(() => {
			if (window.innerWidth < 768) setIsMobile(true)
			else setIsMobile(false)
		}, [window.innerWidth])
		return (
			<div className={'relative h-full w-full '}>
				{isMobile ? (
					<div className="justify-center bg-[#240b3b] items-center flex flex-row px-6">
						<a
							href={`/${short_code}/admin/pos/food/cart`}
							// onClick={() => setIsOpen(false)}
							className="text-white text-[30px] inline p-2"
						>
							{`<`}
						</a>
						<p
							className={` text-18 text-white text-center inline w-full font-semibold leading-[140%]`}
						>
							Order success{' '}
						</p>
					</div>
				) : (
					<div className="h-[72px] w-full flex px-[38px] py-6 justify-between bg-white">
						<a
							href={`/${short_code}/admin/pos/food/cart`}
							className="text-[#252C32] text-18 font-bold leading-[24px]"
						>
							New Order
						</a>
						{/*<a className="text-[#252C32] text-18 font-bold leading-[24px]">*/}
						{/*   Add Customer*/}
						{/*</a>*/}
					</div>
				)}

				<div
					className={`bg-[#F9F7F7] w-full h-full flex flex-col ${
						isMobile ? 'justify-start' : 'justify-center'
					} `}
				>
					<h1
						className={` ${
							isMobile ? 'text-[22px] py-20' : 'text-[46px] mb-20'
						} text-[#240b3b]   leading-[125%]  text-center `}
					>
						<span className="font-bold">
							{/*Fix currency here*/}
							{state?.currency}{' '}
							{state?.confirmResult?.paymentIntent?.amount
								? state?.confirmResult?.paymentIntent?.amount / 100
								: 0}
						</span>{' '}
						<span className="">paid by Card</span>
					</h1>
					<div className="w-full align-middle">
						<div
							className={`rounded-[21px] bg-white  align-middle flex-col w-fit ${
								isMobile ? 'py-[45px] px-[60px]' : 'py-[78px] px-[120px]'
							} `}
						>
							<p
								className={`${
									isMobile ? 'text-24' : 'text-[36px]'
								} text-[#047857] font-medium leading-[40px] mb-6`}
							>
								Success
							</p>
							<img
								src={CheckIcon}
								className={`${isMobile ? 'w-[75px] : h-[75px]' : ''} `}
							/>
						</div>
					</div>
				</div>
			</div>
		)
};

export default FoodSuccess;
