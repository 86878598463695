import React, { useEffect, useState } from "react";
import ArrowDown from '../assets/svgs/Line.svg'
import BarIcon from "../assets/svgs/bar-code.svg";
import ListIcon from "../assets/svgs/list.svg";
import SearchIcon from '../assets/svgs/search1.svg'

const NavbarPOS = () => {
  const [isMobile, setIsMobile] = useState(false);

  const [category, setCategory] = useState("All Menu");
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const menu = [
    "All menu",
    "Cold dishes",
    "Soup",
    "Grill",
    "Appetizer",
    "Dessert",
  ];

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
    else setIsMobile(false);
  }, [window.innerWidth]);

  return (
    // NAVBAR
    <div className="flex flex-col">
      <div
        className={`flex relative  z-50 ${
          isMobile ? "bg-white" : "bg-[#F9F7F7] "
          }   border-[#E4E4E4] w-full justify-between items-center px-[24px] text-[#19191C]`}
      >
        <div
          className={`flex ${
            isMobile
              ? "justify-between flex-1 flex-row items-center "
            : "justify-start flex-col my-12"
          }`}
        >
          <div
            className={`text-[#240b3b]  font-bold ${
              isMobile ? "text-16" : "text-26"
            }`}
          >
            POS#1
          </div>
          <div className="text-[#240b3b] font-normal font-14 leading-[140%] ">
            Thursday Nov 16, 2023
          </div>
        </div>
        {!isMobile && (
          <div className="align-middle">
            <input
              placeholder="Search products"
              className="border-[1px] text-[#240b3b] bg-white border-[#E4E4E4] min-w-[170px] rounded-[8px] placeholder:text-[#240b3b] bg-transparent text-14 px-6 py-[10px] active:border-[#E4E4E4]"
            />
          </div>
        )}
      </div>
      {isMobile && (
        <div className="flex flex-1 flex-row h-[56px] border-[1px] border-[#D1D1D1]">
          <div className="flex flex-1 text-16 h-[56px] items-center px-6 relative ">
            <div
              onClick={() => setIsOpenMenu(!isOpenMenu)}
              className="px-2 flex items-center relative flex-1"
            >
              {category}
              <img src={ArrowDown} className="absolute right-1" />
            </div>
            {isOpenMenu && (
              <div
                onClick={() => setIsOpenMenu(false)}
                className="absolute flex flex-col left-6 top-[56px] z-50 bg-white border-white border-[1px]"
              >
                {menu.map((item, i) => (
                  <div
                    className="p-2"
                    key={i}
                    onClick={() => {
                      setCategory(item);
                      setIsOpenMenu(false);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="w-[56px] h-[56px] align-middle border-[#D1D1D1] border-l-[1px]">
            <img src={SearchIcon} className="w-[24px] h-[24px]" />
          </div>
          <div className="w-[56px] h-[56px] align-middle border-[#D1D1D1] border-l-[1px]">
            <img src={BarIcon} className="w-[24px] h-[24px]" />
          </div>
          <div className="w-[56px] h-[56px] align-middle border-[#D1D1D1] border-l-[1px]">
            <img src={ListIcon} className="w-[24px] h-[24px]" />
          </div>
        </div>
      )}
    </div>
  );
};
export default NavbarPOS;
