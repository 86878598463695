import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	ListItemText,
	MenuItem,
	Modal,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import InfoIcon from '../../../../assets/svgs/info_black.svg'
import LoadingScreen from '../../../../components/custom/LoadingScreen'
import { boxStyle } from '../../../../constants/types'
import { isEmpty } from '../../../../utils/common'
// import { Map } from "@googlemaps/react-wrapper";
// import GoogleMapReact from "google-map-react";
// import LocationPin from "google-map-react";
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import AssistantButton from '../../../../components/assistantButton'
import MapContainer from '../../../../components/googleMapSection'
import accommodationService from '../../../../services/accommodationService'
import { vbAssistantAskQuestion } from '../../../../store/actions/chatbot'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import { KEYS } from '../../../../utils/storage'

const unitstatusList = [
	{ title: 'Listed', value: 'Listed' },
	{ title: 'Snoozed', value: 'Snoozed' },
	{ title: 'Unlisted', value: 'Unlisted' },
	{ title: 'Deactivated', value: 'Deactivated' },
]

const currencyList = [
	{ title: 'Lek', value: 'ALL' },
	{ title: 'Dollar', value: 'USD' },
	{ title: 'Euro', value: 'EUR' },
]

export const nameAndLocationFormText = (text) =>
	t(`accommodation.rental_unit.show.nameAndLocationForm.${text}`)

const NameAndLocationForm = ({ data, rentalUnitId, languages }) => {
	const navigate = useNavigate()

	const [updatingBreakfeastDetails, setUpdatinBreakfeastDetails] =
		useState(false)
	const [updatingParkingDetails, setUpdatingParkingDetails] = useState(false)
	const [updatingLanguages, setUpdatingLanguages] = useState(false)
	const [selectedlanguages, setSelectedLanguages] = useState([])
	const [updatingHostProfile, setUpdatingHostProfile] = useState(false)
	const [personName, setPersonName] = useState(false)
	const { short_code } = useSelector((state) => state.app)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const validatorName = yup.object().shape({
		about: yup
			.string()
			.trim()
			.required('About accommodation is required')
			.min(6, 'Min not met'),
		name: yup.string().required('Accommodation Name is required'),
	})

	const validatorLocation = yup.object().shape({
		//address_line1: yup.string(),
		// country: yup.string().required("Country is required"),
		address: yup.string().required('Address is required'),
	})

	const parkingDetailsFormikProps = useFormik({
		initialValues: {
			availability: null,
			reservation: null,
			location: null,
			park_type: null,
		},
		validationSchema: null,
		onSubmit: (values) => onSubmitParkingDetails(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const breakfeastFormikProps = useFormik({
		initialValues: {
			offers_breakfast: 0,
		},
		validationSchema: null,
		onSubmit: (values) => onSubmitAllowBreakfast(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const onHandleLanguageChecked = (value, language) => {
		// bind for checked
		let tmp = Object.assign([], selectedlanguages)
		if (value == true) {
			tmp.push(language)
			setSelectedLanguages(tmp)
		} else {
			var index = tmp.indexOf(language)
			if (index !== -1) {
				tmp.splice(index, 1)
				setSelectedLanguages(tmp)
			}
		}
	}

	const addressInputRef = useRef(null)

	useEffect(() => {
		let autocomplete

		if (!window.google || !window.google.maps || !window.google.maps.places) {
			console.error('Google Maps API not loaded yet.')
			return
		}

		autocomplete = new window.google.maps.places.Autocomplete(
			addressInputRef.current
		)
		autocomplete.addListener('place_changed', () => {
			const selectedPlace = autocomplete.getPlace()
			if (selectedPlace && selectedPlace.geometry) {
				const { location } = selectedPlace.geometry
				formikPropsLocation.setFieldValue('latitude', location.lat())
				formikPropsLocation.setFieldValue('longitude', location.lng())

				// Also set the address in Formik's state. This ensures that the input value is set.
				formikPropsLocation.setFieldValue(
					'address',
					selectedPlace.formatted_address
				)
			} else {
				console.log('No geometry data found for selected place.')
			}
		})

		return () => {
			if (autocomplete) {
				window.google.maps.event.clearInstanceListeners(autocomplete)
			}
		}
	}, [])

	useEffect(() => {
		if (data?.accommodation_host_profile) {
			accommodationHostProfileFormikProps.setFieldValue(
				'host_name',
				data?.accommodation_host_profile?.host_name
			)
			accommodationHostProfileFormikProps.setFieldValue(
				'about_host',
				data?.accommodation_host_profile?.about_host
			)
			accommodationHostProfileFormikProps.setFieldValue(
				'accommodation_profile',
				true
			)
		}

		formikPropsName.setFieldValue('name', data?.name)
		formikPropsName.setFieldValue('about', data?.about)
		formikPropsName.setFieldValue('about_space', data?.about_space)
		formikPropsName.setFieldValue(
			'about_guest_access',
			data?.about_guest_access
		)
		formikPropsName.setFieldValue('currency', data?.currency)
		formikPropsName.setFieldValue('unit_status', data?.unit_status)
		formikPropsName.setFieldValue('guest_interaction', data?.guest_interaction)
		formikPropsLocation.setFieldValue('address', data?.address?.address)
		// formikPropsLocation.setFieldValue("country", data?.address?.country);
		formikPropsLocation.setFieldValue('latitude', data?.address?.latitude)
		formikPropsLocation.setFieldValue('longitude', data?.address?.longitude)

		if (data?.languages) {
			setSelectedLanguages(data?.languages.map((item) => item?.id) ?? [])
		}

		if (data?.parking_details) {
			parkingDetailsFormikProps.setFieldValue(
				'availability',
				data?.parking_details?.availability
			)
			parkingDetailsFormikProps.setFieldValue(
				'reservation',
				data?.parking_details?.reservation
			)
			parkingDetailsFormikProps.setFieldValue(
				'location',
				data?.parking_details?.location
			)
			parkingDetailsFormikProps.setFieldValue(
				'park_type',
				data?.parking_details?.type
			)
		}

		if (data?.breakfast_details) {
			breakfeastFormikProps.setFieldValue(
				'offers_breakfast',
				data?.breakfast_details?.offers_breakfast
			)
		}
	}, [data])

	const [loading, setLoading] = useState(false)
	const [updatingName, setUpdatingName] = useState(false)
	const [updatingLocation, setUpdatingLocation] = useState(false)
	const formikPropsName = useFormik({
		initialValues: {
			name: '',
			about: '',
			about_space: '',
			about_guest_access: '',
			currency: '',
			unit_status: '',
			guest_interaction: '',
		},
		validationSchema: validatorName,
		onSubmit: (values) => onSubmitName(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const accommodationHostProfileFormikProps = useFormik({
		initialValues: {
			host_name: null,
			about_host: null,
			accommodation_profile: false,
		},
		validationSchema: yup.object().shape({
			about_host: yup
				.string()
				.trim()
				.required('About host is required')
				.max(250, 'Max exceeded')
				.min(6, 'Min not met'),
			host_name: yup.string().trim().required('Host name is required'),
		}),
		onSubmit: (values) => onSubmitAccommodationHostProfile(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const formikPropsLocation = useFormik({
		initialValues: {
			// country: "",
			address: '',
			latitude: 0,
			longitude: 0,
		},
		validationSchema: validatorLocation,
		onSubmit: (values) => onSubmitLocation(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const onSubmitAccommodationHostProfile = (values) => {
		setUpdatingHostProfile(true)
		const details = {
			type: 'accommodation_host_profile',
			host_name: values.host_name,
			about_host: values.about_host,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'update',
				})
				setUpdatingHostProfile(false)
				NotificationManager.success(
					'Accommodation Host Profile has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=0`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'update',
				})
				setUpdatingHostProfile(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitAccomodationSetup')
			})
	}

	const onSubmitName = (values) => {
		const payload = {
			...values,
		}
		setUpdatingName(true)
		const details = {
			type: 'name',
			about: payload?.about,
			name: payload?.name,
			about_space: payload?.about_space ?? null,
			about_guest_access: payload?.about_guest_access ?? null,
			unit_status: payload?.unit_status,
			guest_interaction: payload?.guest_interaction,
			currency: payload?.currency ?? null,
		}

		accommodationService
			.UpdateRentalUnitNameLocation(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'update',
				})
				setUpdatingName(false)
				NotificationManager.success(
					'Accommodation has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=0`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'update',
				})
				setUpdatingName(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateRentalUnitNameLocation')
			})
	}

	const onSubmitLocation = (values) => {
		window.scrollTo(0, 0)

		const payload = {
			...values,
		}

		setUpdatingLocation(true)
		const details = {
			type: 'location',
			address: payload?.address,
			// country: payload?.country,
			latitude: payload?.latitude ?? null,
			longitude: payload?.longitude ?? null,
		}

		accommodationService
			.UpdateRentalUnitNameLocation(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units location', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'update',
				})
				setUpdatingLocation(false)
				NotificationManager.success(
					'Accommodation has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=0`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units location', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'update',
				})
				setUpdatingLocation(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('UpdateRentalUnitNameLocation')
			})
	}

	const onSubmitParkingDetails = (values) => {
		setUpdatingParkingDetails(true)
		const details = {
			type: 'parking_details',
			availability: values.availability,
			reservation: values.reservation,
			location: values.location,
			park_type: values.park_type,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'accomodation setup',
				})
				setUpdatingParkingDetails(false)
				NotificationManager.success(
					'Parking Details has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=0`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'accomodation setup',
				})
				setUpdatingParkingDetails(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitAccomodationSetup')
			})
	}
	const onSubmitAllowBreakfast = (values) => {
		setUpdatinBreakfeastDetails(true)
		const details = {
			type: 'breakfast_details',
			offers_breakfast: values.offers_breakfast === 1,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'accomodation setup',
				})
				setUpdatinBreakfeastDetails(false)
				NotificationManager.success(
					'Allow Breakfeast has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=0`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'accomodation setup',
				})
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'accomodation setup',
				})
				setUpdatinBreakfeastDetails(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitAccomodationSetup')
			})
	}

	const onSubmitLanguages = () => {
		setUpdatingLanguages(true)
		const details = {
			type: 'languages',
			languages: selectedlanguages,
		}
		accommodationService
			.UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'accomodation setup',
				})
				setUpdatingLanguages(false)
				NotificationManager.success(
					'Languages has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=0`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'accomodation setup',
				})
				setUpdatingLanguages(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitAccomodationSetup')
			})
		// onSubmitForm(payload);
	}

	const handleLanguageChange = (event) => {
		const {
			target: { value },
		} = event
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		)
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'updated_guest_access_content'
		if (aiField === 'About') {
			askFor = 'updated_rental_unit_about_content'
		}
		if (aiField === 'About Space') {
			askFor = 'updated_rental_unit_space_content'
		}
		if (aiField === 'Guest Interaction') {
			askFor = 'updated_guest_interaction_content'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		switch (aiField) {
			case 'About':
				formikPropsName.setFieldValue('about', generated)
				break
			case 'About Space':
				formikPropsName.setFieldValue('about_space', generated)
				break
			case 'About Guest Access':
				formikPropsName.setFieldValue('about_guest_access', generated)
				break
			case 'Guest Interaction':
				formikPropsName.setFieldValue('guest_interaction', generated)
				break
			default:
				break
		}

		setAiQuery('')
		setGenerated('')
	}

	if (loading) {
		return <LoadingScreen />
	}

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-6 pt-2 gap-10">
					<div className="relative started-form">
						<div>
							<p className="text-base text-gray5 my-4">
								<span className="text-black1 text-20 font-bold">
									{nameAndLocationFormText('name_prompt')}
								</span>
							</p>
						</div>

						<div className="flex flex-row gap-12">
							<div className="flex flex-col w-[60%] p-[22px] rounded-[10px] border-[#E0E0E0] border-[1px]">
								<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
									{nameAndLocationFormText('accommodation_name')}
								</p>
								<div className="flex flex-col">
									<TextField
										required
										size="medium"
										placeholder={nameAndLocationFormText(
											'enter_accommodation_name'
										)}
										value={formikPropsName.values.name}
										error={formikPropsName.errors.name}
										onChange={(e) => {
											formikPropsName.setFieldValue('name', e.target.value)
										}}
									/>
									{!isEmpty(formikPropsName.errors.name) && (
										<div className="text-red-600 text-12 mt-2">
											{formikPropsName.errors.name}
										</div>
									)}
								</div>
								<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-2">
									{nameAndLocationFormText('about_accommodation')}
								</p>
								<div className="flex flex-col">
									<TextField
										required
										size="medium"
										multiline
										rows={5}
										placeholder={nameAndLocationFormText('about_accommodation')}
										value={formikPropsName.values.about}
										error={formikPropsName.errors.about}
										onChange={(e) => {
											formikPropsName.setFieldValue('about', e.target.value)
										}}
									/>
									<div className="flex flex-row justify-end mt-2">
										<AssistantButton
											onAction={() => handleOpenAIModal('About')}
										/>
									</div>
									{!isEmpty(formikPropsName.errors.about) && (
										<div className="text-red-600 text-12 mt-2">
											{formikPropsName.errors.about}
										</div>
									)}
								</div>

								<p className="text-black1 text-18 font-semibold leading-[35px] mt-4 py-2">
									{nameAndLocationFormText('about_space')}
								</p>
								<div className="flex flex-col">
									<TextField
										required
										size="medium"
										multiline
										rows={5}
										placeholder={nameAndLocationFormText('about_space')}
										value={formikPropsName.values.about_space}
										error={formikPropsName.errors.about_space}
										onChange={(e) => {
											formikPropsName.setFieldValue(
												'about_space',
												e.target.value
											)
										}}
									/>
									<div className="flex flex-row justify-end mt-2">
										<AssistantButton
											onAction={() => handleOpenAIModal('About Space')}
										/>
									</div>
									{!isEmpty(formikPropsName.errors.about_space) && (
										<div className="text-red-600 text-12 mt-2">
											{formikPropsName.errors.about_space}
										</div>
									)}
								</div>

								<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-2">
									{nameAndLocationFormText('guest_access')}
								</p>
								<div className="flex flex-col">
									<TextField
										required
										size="medium"
										multiline
										rows={5}
										placeholder={nameAndLocationFormText('guest_access')}
										value={formikPropsName.values.about_guest_access}
										error={formikPropsName.errors.about_guest_access}
										onChange={(e) => {
											formikPropsName.setFieldValue(
												'about_guest_access',
												e.target.value
											)
										}}
									/>
									<div className="flex flex-row justify-end mt-2">
										<AssistantButton
											onAction={() => handleOpenAIModal('About Guest Access')}
										/>
									</div>
									{!isEmpty(formikPropsName.errors.about_guest_access) && (
										<div className="text-red-600 text-12 mt-2">
											{formikPropsName.errors.about_guest_access}
										</div>
									)}
								</div>
								<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-2">
									{nameAndLocationFormText('interaction_with_guests')}
								</p>
								<div className="flex flex-col">
									<TextField
										required
										size="medium"
										multiline
										rows={5}
										placeholder={nameAndLocationFormText(
											'interaction_with_guests'
										)}
										value={formikPropsName.values.guest_interaction}
										error={formikPropsName.errors.guest_interaction}
										onChange={(e) => {
											formikPropsName.setFieldValue(
												'guest_interaction',
												e.target.value
											)
										}}
									/>
									<div className="flex flex-row justify-end mt-2">
										<AssistantButton
											onAction={() => handleOpenAIModal('Guest Interaction')}
										/>
									</div>
									{!isEmpty(formikPropsName.errors.guest_interaction) && (
										<div className="text-red-600 text-12 mt-2">
											{formikPropsName.errors.guest_interaction}
										</div>
									)}
								</div>

								{venueType === 'vacation_rental' && (
									<>
										<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-2">
											{nameAndLocationFormText('currency')}
										</p>
										<div className="flex flex-col">
											<TextField
												id="outlined-select"
												size="small"
												error={formikPropsName.errors.currency}
												select
												value={formikPropsName.values.currency}
												onChange={(event) => {
													formikPropsName.setFieldValue(
														'currency',
														event.target.value
													)
												}}
											>
												{currencyList.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.title}
													</MenuItem>
												))}
											</TextField>
											{!isEmpty(formikPropsName.errors.currency) && (
												<div className="text-red-600 text-12 mt-2">
													{formikPropsName.errors.currency}
												</div>
											)}
										</div>
									</>
								)}

								<p className="text-black1 text-18 font-semibold mt-4 leading-[35px] py-2">
									{nameAndLocationFormText('unit_status')}
								</p>

								<div className="flex flex-col">
									<TextField
										id="outlined-select"
										size="small"
										error={formikPropsName.errors.unit_status}
										select
										value={formikPropsName.values.unit_status}
										onChange={(event) => {
											formikPropsName.setFieldValue(
												'unit_status',
												event.target.value
											)
										}}
									>
										{unitstatusList.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.title}
											</MenuItem>
										))}
									</TextField>
									{!isEmpty(formikPropsName.errors.unit_status) && (
										<div className="text-red-600 text-12 mt-2">
											{formikPropsName.errors.unit_status}
										</div>
									)}
								</div>

								<div className="flex flex-col justify-end">
									<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
										<button
											type="submit"
											className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
											onClick={() => {
												formikPropsName.handleSubmit()
											}}
										>
											{updatingName ? <CircularProgress size={16} /> : 'Update'}
										</button>
									</div>
								</div>
							</div>
							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('choosing_right_name')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('choosing_right_name_description')}
									</p>
								</div>
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('guest_interaction')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('guest_interaction_description')}
									</p>
								</div>
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('space_guest_access')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('space_guest_access_description')}
									</p>
								</div>
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('unit_status')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('unit_status_description')}
									</p>
								</div>
							</div>
						</div>

						<hr className="mt-4 mb-8" />
						<div>
							<p className="text-base text-gray5 my-4">
								<span className="text-black1 text-20 font-bold">
									{nameAndLocationFormText(
										'where_is_your_accommodation_located'
									)}
								</span>
							</p>
						</div>

						<div className="flex flex-row gap-12">
							<div
								className="w-[60%] p-[22px] rounded-[10px] border-[#E0E0E0] border-[1px]"
								style={{ padding: '22px', paddingTop: '10px' }}
							>
								<p className="text-black1 text-18 font-semibold leading-[35px] mt-0 py-2">
									{nameAndLocationFormText('accommodation_address')}
								</p>
								<div className="flex flex-col">
									<input
										className={`w-full h-[50px] border outline-none py-0 px-4`}
										placeholder={nameAndLocationFormText(
											'enter_address_placeholder'
										)}
										ref={addressInputRef}
										type={'text'}
										style={{ borderRadius: '4px' }}
										value={formikPropsLocation.values.address}
										onChange={(e) => {
											formikPropsLocation.setFieldValue(
												'address',
												e.target.value
											)
										}}
									/>
								</div>
								{data?.address?.latitude && (
									<>
										<p className="text-black1 text-18 font-semibold leading-[35px] py-2 mt-4">
											{nameAndLocationFormText('confirm_location')}
										</p>
										<div className="">
											<MapContainer
												latitude={data?.address?.latitude}
												longitude={data?.address?.longitude}
												address={formikPropsLocation.values.address}
											/>
										</div>
										<p className="text-[#707070] text-14 font-normal my-2">
											This is the location we’ll show to the guests on your VB
											Profile.
										</p>
									</>
								)}
								<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
									<button
										type="submit"
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={() => formikPropsLocation.handleSubmit()}
									>
										{updatingLocation ? (
											<CircularProgress size={16} />
										) : (
											nameAndLocationFormText('update_button')
										)}
									</button>
								</div>
							</div>
							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>
									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('accurate_info_heading')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('accurate_info_text')}
									</p>
								</div>
							</div>
						</div>
						<hr className="mb-8 mt-4" />
						<div>
							<p className={'text-base text-gray5 my-4'}>
								<span className="text-black1 text-20 font-bold">
									Set host profile
								</span>
							</p>
						</div>
						<div className="flex flex-row gap-12">
							<div className="w-[60%]">
								<div className="flex flex-col  p-[22px] rounded-t-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										Choose host profile
									</p>
									<div className="flex  mt-0 flex-row   items-center">
										<Checkbox
											checked={
												accommodationHostProfileFormikProps.values
													.accommodation_profile
											}
											disabled={true}
											style={{ width: 'fit-content' }}
											onChange={(e) =>
												accommodationHostProfileFormikProps.setFieldValue(
													'accommodation_profile',
													e.target.checked
												)
											}
											className="mt-2"
										/>
										<span className="text-gray5 text-16 font-medium">
											The host
										</span>
									</div>
									<p className="text-[#707070] text-14 font-normal ">
										Help your listing stand out by telling potential guests a
										bit more about yourself. This information will be shown on
										your accommodation page.
									</p>
								</div>
								<div className="flex flex-col  p-[22px] rounded-b-[10px] border-[#E0E0E0] border-[1px]">
									<div>
										<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
											Host name
										</p>
										<div className="flex flex-col">
											<TextField
												required
												size="medium"
												placeholder="Enter host name"
												value={
													accommodationHostProfileFormikProps.values.host_name
												}
												error={
													accommodationHostProfileFormikProps.errors.host_name
												}
												onChange={(e) => {
													accommodationHostProfileFormikProps.setFieldValue(
														'host_name',
														e.target.value
													)
												}}
											/>
											{!isEmpty(
												accommodationHostProfileFormikProps.errors.host_name
											) && (
												<div className="text-red-600 text-12 mt-2">
													{accommodationHostProfileFormikProps.errors.host_name}
												</div>
											)}
										</div>
									</div>

									<p className="text-black1 text-18 font-semibold leading-[35px] mt-4 py-2">
										About host
									</p>
									<div className="flex flex-col">
										<TextField
											required
											size="medium"
											multiline
											rows={3}
											placeholder="Write something about your host"
											value={
												accommodationHostProfileFormikProps.values.about_host
											}
											error={
												accommodationHostProfileFormikProps.errors.about_host
											}
											onChange={(e) => {
												accommodationHostProfileFormikProps.setFieldValue(
													'about_host',
													e.target.value
												)
											}}
										/>
										{!isEmpty(
											accommodationHostProfileFormikProps.errors.about_host
										) && (
											<div className="text-red-600 text-12 mt-2">
												{accommodationHostProfileFormikProps.errors.about_host}
											</div>
										)}
									</div>
									<div className="flex flex-col justify-end">
										<div className="flex flex-row justify-between mt-4 h-[48px] gap-8">
											<button
												type="submit"
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												onClick={() => {
													accommodationHostProfileFormikProps.handleSubmit()
												}}
											>
												{updatingHostProfile ? (
													<CircularProgress size={16} />
												) : (
													'Update'
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										Introduce the host
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										Sharing a bit about who host is can bridge the gap with
										potential guests. A personal touch can make your listing
										more appealing and foster trust. Remember, guests like to
										know a bit about where they're staying at!
									</p>
								</div>
							</div>
						</div>

						<hr className="mb-8 mt-4" />
						<div>
							<p className={'text-base text-gray5 my-4'}>
								<span className="text-black1 text-20 font-bold">
									{nameAndLocationFormText('heading')}
								</span>
							</p>
						</div>
						<div className="flex flex-row gap-12">
							<div className="flex flex-col w-[60%] gap-8">
								<div className="flex flex-col p-[22px] rounded-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										{nameAndLocationFormText('serveBreakfast')}
									</p>
									<div className="flex flex-col">
										<RadioGroup
											column
											value={breakfeastFormikProps.values.offers_breakfast}
											onChange={(e) => {
												breakfeastFormikProps.setFieldValue(
													'offers_breakfast',
													e.target.value
												)
											}}
										>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label={nameAndLocationFormText('yes')}
											/>
											<FormControlLabel
												value={0}
												control={<Radio />}
												label={nameAndLocationFormText('no')}
											/>
										</RadioGroup>
									</div>
								</div>
								<div className="flex flex-row justify-between mt-0 mb-4 h-[48px] gap-8">
									<button
										type="submit"
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={() => {
											breakfeastFormikProps.handleSubmit()
										}}
									>
										{updatingBreakfeastDetails ? (
											<CircularProgress size={16} />
										) : (
											nameAndLocationFormText('updateButton')
										)}
									</button>
								</div>
							</div>
							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('detailHeading')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('detailText')}
									</p>
								</div>
							</div>
						</div>

						<hr className="mb-8 mt-4" />
						<div>
							<p className={'text-base text-gray5 my-4'}>
								<span className="text-black1 text-20 font-bold">
									{nameAndLocationFormText('heading')}
								</span>
							</p>
						</div>
						<div className="flex flex-row gap-12">
							<div className="flex flex-col w-[60%] ">
								<div className="flex flex-col p-[22px] rounded-t-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										{nameAndLocationFormText('serveParking')}
									</p>
									<div className="flex flex-col">
										<RadioGroup
											column
											value={parkingDetailsFormikProps.values.availability}
											onChange={(e) => {
												parkingDetailsFormikProps.setFieldValue(
													'availability',
													e.target.value
												)
											}}
										>
											<FormControlLabel
												value={'free'}
												control={<Radio />}
												label={nameAndLocationFormText('yesFree')}
											/>
											<FormControlLabel
												value={'paid'}
												control={<Radio />}
												label={nameAndLocationFormText('yesPaid')}
											/>
											<FormControlLabel
												value={'no'}
												control={<Radio />}
												label={nameAndLocationFormText('no')}
											/>
										</RadioGroup>
									</div>
								</div>
								<div className="flex flex-col p-[22px] rounded-b-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										{nameAndLocationFormText('reservationNeeded')}
									</p>
									<div className="flex flex-col">
										<RadioGroup
											column
											value={parkingDetailsFormikProps.values.reservation}
											onChange={(e) => {
												parkingDetailsFormikProps.setFieldValue(
													'reservation',
													e.target.value
												)
											}}
										>
											<FormControlLabel
												value={'needed'}
												control={<Radio />}
												label={nameAndLocationFormText('needed')}
											/>
											<FormControlLabel
												value={'not_needed'}
												control={<Radio />}
												label={nameAndLocationFormText('notNeeded')}
											/>
										</RadioGroup>
									</div>

									<p className="text-black1 text-18 font-semibold leading-[35px] mt-4">
										{nameAndLocationFormText('parkingLocation')}
									</p>
									<div className="flex flex-col">
										<RadioGroup
											column
											value={parkingDetailsFormikProps.values.location}
											onChange={(e) => {
												parkingDetailsFormikProps.setFieldValue(
													'location',
													e.target.value
												)
											}}
										>
											<FormControlLabel
												value={'on_site'}
												control={<Radio />}
												label={nameAndLocationFormText('onSite')}
											/>
											<FormControlLabel
												value={'off_site'}
												control={<Radio />}
												label={nameAndLocationFormText('offSite')}
											/>
										</RadioGroup>
									</div>

									<p className="text-black1 text-18 font-semibold leading-[35px] mt-4">
										{nameAndLocationFormText('parkingType')}
									</p>
									<div className="flex flex-col">
										<RadioGroup
											column
											value={parkingDetailsFormikProps.values.park_type}
											onChange={(e) => {
												parkingDetailsFormikProps.setFieldValue(
													'park_type',
													e.target.value
												)
											}}
										>
											<FormControlLabel
												value={'private'}
												control={<Radio />}
												label={nameAndLocationFormText('private')}
											/>
											<FormControlLabel
												value={'public'}
												control={<Radio />}
												label={nameAndLocationFormText('public')}
											/>
										</RadioGroup>
									</div>
								</div>
								<div className="flex flex-row justify-between mt-5 mb-6 h-[48px] gap-8">
									<button
										type="submit"
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={() => {
											parkingDetailsFormikProps.handleSubmit()
										}}
									>
										{updatingParkingDetails ? (
											<CircularProgress size={16} />
										) : (
											nameAndLocationFormText('updateButton')
										)}
									</button>
								</div>
							</div>
							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('infoHeading1')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('infoText')}
									</p>
								</div>
							</div>
						</div>

						<hr className="mb-8 mt-4" />
						<div>
							<p className={'text-base text-gray5 my-4'}>
								<span className="text-black1 text-20 font-bold">
									{nameAndLocationFormText('heading1')}
								</span>
							</p>
						</div>
						<div className="flex flex-row gap-12">
							<div className="flex flex-col w-[60%]">
								<div className="flex flex-col p-[22px] rounded-t-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										{nameAndLocationFormText('selectLanguage')}
									</p>
									<FormGroup>
										{languages.slice(0, 3).map((language) => (
											<FormControlLabel
												key={language.name}
												control={
													<Checkbox
														onChange={(e) =>
															onHandleLanguageChecked(
																e.target.checked,
																language.id
															)
														}
														checked={selectedlanguages.includes(language.id)}
														value={selectedlanguages.includes(language.id)}
													/>
												}
												label={language.name}
											/>
										))}
									</FormGroup>
								</div>
								<div className="flex flex-col p-[22px] rounded-b-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
										{nameAndLocationFormText('addLanguages')}
									</p>
									<FormControl className="flex width-full">
										<Select
											multiple
											value={selectedlanguages}
											renderValue={(selected) =>
												languages
													.slice(3, languages.length)
													.filter((language) =>
														selectedlanguages.includes(language.id)
													)
													.map((item) => item.name)
													.join(', ')
											}
											className="language-selection flex-1 flex"
										>
											{languages.slice(3, languages.length).map((language) => (
												<MenuItem key={language.name} value={language.id}>
													<Checkbox
														onChange={(e) =>
															onHandleLanguageChecked(
																e.target.checked,
																language.id
															)
														}
														checked={selectedlanguages.includes(language.id)}
														value={selectedlanguages.includes(language.id)}
													/>
													<ListItemText primary={language.name} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>

								<div className="flex flex-row justify-between mt-6 mb-4 h-[48px] gap-8">
									<button
										type="submit"
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={() => {
											onSubmitLanguages()
										}}
									>
										{updatingLanguages ? (
											<CircularProgress size={16} />
										) : (
											nameAndLocationFormText('updateButton')
										)}
									</button>
								</div>
							</div>

							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{nameAndLocationFormText('infoHeading1')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{nameAndLocationFormText('infoText1')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{nameAndLocationFormText('header')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									// placeholder={nameAndLocationFormText('queryPlaceholder')}  // Uncomment if you have a placeholder
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{nameAndLocationFormText('assistInfo')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-transparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												nameAndLocationFormText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{nameAndLocationFormText('addTo')} {aiField}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</Card>
	)
}

export default NameAndLocationForm
