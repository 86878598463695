import MuiClose from '@mui/icons-material/Close'
import MuiDone from '@mui/icons-material/Done'
import MuiDoneAll from '@mui/icons-material/DoneAll'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DatePicker from 'react-datepicker'
import { NotificationManager } from 'react-notifications'
import Svg_calendar_32 from '../../assets/svgs/calendar-32.svg'
import Svg_chevron_down_orig from '../../assets/svgs/chevron-down-orig.svg'
import Svg_person from '../../assets/svgs/person.svg'
import ReservationService from '../../services/reservationService'
import AreYouSureModal from '../common/areYouSureModal'

const getTranslation = (text) =>
	t(`components.reservation.ReservationPanel.${text}`)

export default function ReservationPanel(props) {
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

	const [weekDays, setWeekDays] = useState([])
	const [reservations, setReservations] = useState([])
	const [currentDate, setCurrentDate] = useState(new Date(moment()))
	const [calendarOpen, setCalendarOpen] = useState(false)

	const [isAreYouSureConfirmModalOpen, setAreYouSureConfirmModalOpen] =
		useState(false)
	const [isAreYouSureCancelModalOpen, setAreYouSureCancelModalOpen] =
		useState(false)
	const [selectedReservation, setReservationSelected] = useState({})

	const handleCalendarClick = (e) => {
		let newDate = new Date(e)
		if (!isNaN(newDate)) {
			prepareDisplay(newDate)
		}
		setCalendarOpen(!calendarOpen)
	}

	const handleWeekDayClick = (e, day) => {
		prepareDisplay(day)
	}

	const prepareDisplay = (newDate) => {
		// Get sun to sat (week_start, wee_end)
		var curr = new Date(newDate)
		var first = curr.getDate() - curr.getDay()
		let newWeekdays = []
		for (let i = 0; i < 7; i++) {
			newWeekdays.push(new Date(new Date(curr).setDate(first++)))
		}

		// filter props.reservationsData to choosen date
		let reservationFiltered = props.reservationsData.filter((reservation) =>
			isSameDate(newDate, reservation.start_time)
		)

		setCurrentDate(newDate)
		setWeekDays(newWeekdays)
		setReservations(reservationFiltered)
	}

	const isSameDate = (p1, p2) => {
		let d1 = new Date(p1)
		let d2 = new Date(p2)
		let date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())
		let date2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate())
		return moment(date1).isSame(date2)
	}

	const onConfirmReservation = (e, item) => {
		setReservationSelected(item)
		setAreYouSureConfirmModalOpen(true)
	}

	const onDeleteReservation = (e, item) => {
		setReservationSelected(item)
		setAreYouSureCancelModalOpen(true)
	}

	useEffect(() => {
		prepareDisplay(currentDate)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		prepareDisplay(currentDate)
		// eslint-disable-next-line
	}, [props.reservationsData])

	return (
		<>
			<div className="absolute w-[503px] h-full bg-white right-0 z-20 flex flex-col">
				<div className="flex flex-col p-024 shadow-lg_3">
					<div className="flex flex-row justify-between">
						<label className="text-14 text-gray3">
							{getTranslation('selectDate')}
						</label>
						<div className="relative flex flex-row">
							<label
								className="text-14 text-gray3"
								onClick={handleCalendarClick}
							>
								{moment(currentDate).format('MMMM yyyy')}
							</label>
							{calendarOpen && (
								<div className="absolute right-0 top-[20px]">
									<DatePicker
										selected={currentDate}
										onChange={handleCalendarClick}
										inline
									/>
								</div>
							)}
							<img
								className="text-14 pl-[8px]"
								src={Svg_chevron_down_orig}
								alt=""
							/>
						</div>
					</div>
					<div
						className="flex flex-row mt-4 rounded-[16px] w-full h-[64px]"
						style={{ border: '1px solid #E4E4E4' }}
					>
						<div className="flex w-[56px] h-[64px] justify-center">
							<img className="p-[12px]" src={Svg_calendar_32} alt="" />
						</div>
						{weekDays.map((item, index) => {
							return (
								<div
									key={index}
									className={`flex flex-col w-[58px] h-[64px] justify-center ${
										isSameDate(item, currentDate) ? 'bg-cyan2' : ''
									}`}
									onClick={(e) => handleWeekDayClick(e, item)}
									style={{ borderLeft: '1px solid #E4E4E4' }}
								>
									<label
										className={`text-center text-12 font-medium  ${
											isSameDate(item, currentDate)
												? 'text-cyan1'
												: 'text-gray3'
										}`}
									>
										{days[index]}
									</label>
									<label
										className={`text-center text-16 font-semibold ${
											isSameDate(item, currentDate) ? 'text-cyan1' : ''
										}`}
									>
										{item.getDate()}
									</label>
								</div>
							)
						})}
					</div>
				</div>
				<div className="flex flex-col px-[24px] mt-12">
					<div
						className="flex flex-row justify-center text-gray3 text-12 h-[25px]"
						style={{ borderBottom: '1px solid #E4E4E4' }}
					>
						<label style={{ paddingLeft: '24px', width: '260px' }}>
							{getTranslation('customerName')}
						</label>
						<label style={{ width: '150px' }}>{getTranslation('time')}</label>
						<label style={{ width: '100px' }}>{getTranslation('table')}</label>
						<label style={{ width: '100px' }}>{getTranslation('order')}</label>
						<label style={{ width: '100px' }}>{getTranslation('status')}</label>
					</div>
					{reservations.map((item, index) => {
						return (
							<div
								key={index}
								className="flex flex-row justify-center text-14 h-[52px] items-center"
								style={{ borderTop: '1px solid #E4E4E4' }}
							>
								<label style={{ paddingLeft: '24px', width: '260px' }}>
									{item.notes}
								</label>
								<label style={{ width: '150px' }}>
									{moment(item.start_time).format('HH:mm:ss')}
								</label>
								<label style={{ width: '100px' }}>T-{item.table_id}</label>
								<div className="flex flex-row" style={{ width: '100px' }}>
									<img className="pr-1" src={Svg_person} alt="" />
									{item.guest_count}
								</div>
								<div className="flex flex-row" style={{ width: '100px' }}>
									<div>
										{item.confirmed ? (
											<MuiDoneAll fontSize="small" className="cursor-pointer" />
										) : (
											<MuiDone
												fontSize="small"
												className="cursor-pointer"
												onClick={(e) => onConfirmReservation(e, item)}
											/>
										)}
									</div>
									<MuiClose
										fontSize="small"
										className="ml-2 cursor-pointer"
										onClick={(e) => onDeleteReservation(e, item)}
									/>
								</div>
							</div>
						)
					})}
				</div>
				<div className="absolute bottom-[24px] h-[48px] right-[24px] left-[24px]">
					<button
						className="flex justify-center items-center text-white bg-cyan1 h-full w-full rounded-[58px] font-semibold text-16"
						onClick={props.handleOpen}
					>
						{getTranslation('addNewReservation')}
					</button>
				</div>
			</div>
			<div
				className="absolute w-full h-full opacity-20 bg-black z-10"
				onClick={() => props.showReservation(false)}
			></div>

			{isAreYouSureConfirmModalOpen && (
				<AreYouSureModal
					title={getTranslation('confirmReservation')}
					content={getTranslation('confirmReservationContent')}
					firstButton={getTranslation('noCancel')}
					secondButton={getTranslation('yesIam')}
					showModal={isAreYouSureConfirmModalOpen}
					onClose={() => setAreYouSureConfirmModalOpen(false)}
					onSubmit={() =>
						ReservationService.Patch(selectedReservation.id, 'confirm', {
							table_id: selectedReservation.table_id,
							confirmed: 1,
						}).then(() => {
							NotificationManager.success(
								getTranslation('successfullyConfirmed')
							)
							props.getReservationData()
						})
					}
				/>
			)}

			{isAreYouSureCancelModalOpen && (
				<AreYouSureModal
					title={getTranslation('cancelReservation')}
					content={getTranslation('cancelReservationContent')}
					firstButton={getTranslation('noCancel')}
					secondButton={getTranslation('yesIam')}
					showModal={isAreYouSureCancelModalOpen}
					onClose={() => setAreYouSureCancelModalOpen(false)}
					onSubmit={() =>
						ReservationService.Cancel(selectedReservation.id).then(() => {
							NotificationManager.success(
								getTranslation('successfullyCancelled')
							)
							props.getReservationData()
						})
					}
				/>
			)}
		</>
	)
}
