import { months } from "../account/store/store-bulk-import";

export function getMonthsArray() {
   const startYear = 2022;
   const endDate = new Date();
   const result = [];

   for (let year = startYear; year <= endDate.getFullYear(); year++) {
      for (let month of months) {
         const monthNumber = parseInt(month.value, 10);

         if (year === endDate.getFullYear() && monthNumber > (endDate.getMonth() + 1)) {
            break;
         }

         const formatted = `${month.label} ${year}`;
         result.push({
            label: formatted,
            value: formatted
         });
      }
   }

   return result;
}

export const dailyOverview = [
   {
      no: "#123431",
      store: "Metropol",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      store: "Metropol",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      store: "Metropol",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      store: "Metropol",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      store: "Metropol",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      store: "Metropol",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
]
export const dailtyOverviewColumns = [{
   label: 'No.',
   field: 'no',
   align: 'left'
},
{
   label: 'Store',
   field: 'store',
   align: 'left',
}, {
   label: 'Period',
   field: 'period',
   align: 'left',
}, {
   label: 'Generated at',
   field: 'generated_at',
   align: 'left',
}, {
   label: 'Staf',
   field: 'staf',
   align: 'left',
}, {
   label: 'Actions',
   field: 'actions',
   align: 'left',
},];

export const monthlyOverview = [
   {
      no: "#123431",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
   {
      no: "#123431",
      generated_at: "05/09/2024 4:00 AM",
      period: "Week 1, Sept 2024",
      staf: "Griseld Gerveni"
   },
];
export const monthlyOverviewColumns = [{
   label: 'No.',
   field: 'no',
   align: 'left'
},
{
   label: 'Period',
   field: 'period',
   align: 'left',
}, {
   label: 'Generated at',
   field: 'generated_at',
   align: 'left',
}, {
   label: 'Staf',
   field: 'staf',
   align: 'left',
}, {
   label: 'Actions',
   field: 'actions',
   align: 'left',
},];

export const inventoryTurnover = [
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },
   {
      no: "#123431",
      store_name: "Metropol",
      average_inventory: "5,000",
      cost: "25,000",
      turnover: "5.0",
      days: "73",
   },

];
export const inventoryTurnoverColumns = [{
   label: 'No.',
   field: 'no',
   align: 'left'
},
{
   label: 'Store Name',
   field: 'store_name',
   align: 'left'
}, {
   label: 'Average Inventory',
   field: 'average_inventory',
   align: 'center'
}, {
   label: 'Costs of Goods Sold',
   field: 'cost',
   align: 'center'
}, {
   label: 'Inventory Turnover',
   field: 'turnover',
   align: 'center'
}, {
   label: 'Days to Sell Inventory',
   field: 'days',
   align: 'center',
   cellComponent: ({ data }) => {
      return <span>{data}</span>
   }
},];

export const dailyOverviewModalCol = [{
   label: 'Day',
   field: 'day',
   align: 'left'
},
{
   label: 'Year 2023',
   field: 'initial_year',
   align: 'left',
   cellComponent: ({ data }) => {
      return <span className="flex items-center justify-around w-full"><span>{data}</span><span>0</span></span>
   }
}, {
   label: 'Year 2024',
   field: 'current_year',
   align: 'center',
   cellComponent: ({ data }) => {
      return <span className="flex items-center justify-around w-full"><span>{data}</span><span>0</span></span>
   }
}, {
   label: 'Year 2024',
   field: 'percentage',
   align: 'center',
   cellComponent: ({ data }) => {
      console.log(data, "data");
      console.log(data <= 0, "data <= 0");
      return data <= 0 ? <span style={{ color: "#ED1C24" }}>{data}</span> : <span style={{ color: "#41E32C" }}>+{data}</span>;
   }

}];

export const dailyOverviewModalData = [
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: 56
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: 65
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: 44
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: -21
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: 89
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: -23
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: 12
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: -65
   },
   {
      day: 1,
      initial_year: 2023,
      current_year: 2024,
      percentage: 16
   },

   {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   }, {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   }, {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   }, {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   }, {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   }, {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   }, {
      day: 2,
      initial_year: 2023,
      current_year: 2024,
      percentage: -78
   },];

export const monthlyOverviewModalCol = [{
   label: 'Day',
   field: 'day',
   align: 'left'
}, {
   label: 'Daily Sales',
   field: 'daily_sales',
   align: 'left'
}, {
   label: '# of tickets',
   field: 'day',
   align: 'left'
}, {
   label: 'QTY',
   field: 'qty',
   align: 'left'
}, {
   label: 'PPT',
   field: 'ppt',
   align: 'left'
}, {
   label: 'VPT',
   field: 'vpt',
   align: 'left'
}, {
   label: 'PPP',
   field: 'ppp',
   align: 'left',
   cellComponent: ({ data }) => {
      return <span>{data}</span>
   }
},];

export const monthlyOverviewModalData = [
   {
      day: 1,
      daily_sales: 1500,
      qty: 300,
      ppt: 5,
      vpt: 10,
      ppp: 50
   },
   {
      day: 2,
      daily_sales: 2000,
      qty: 250,
      ppt: 8,
      vpt: 12,
      ppp: 55
   },
   {
      day: 3,
      daily_sales: 1800,
      qty: 280,
      ppt: 6.5,
      vpt: 11,
      ppp: 52
   },
   {
      day: 4,
      daily_sales: 1600,
      qty: 320,
      ppt: 5.5,
      vpt: 10.5,
      ppp: 48
   },
   {
      day: 5,
      daily_sales: 1900,
      qty: 310,
      ppt: 6,
      vpt: 11.2,
      ppp: 53
   },
   {
      day: 6,
      daily_sales: 1700,
      qty: 275,
      ppt: 6.2,
      vpt: 10.8,
      ppp: 50
   },
   {
      day: 7,
      daily_sales: 2100,
      qty: 340,
      ppt: 6.1,
      vpt: 11.3,
      ppp: 57
   },
   {
      day: 8,
      daily_sales: 2200,
      qty: 360,
      ppt: 6.5,
      vpt: 11.8,
      ppp: 58
   },
   {
      day: 9,
      daily_sales: 2400,
      qty: 390,
      ppt: 6.2,
      vpt: 12.0,
      ppp: 59
   },
   {
      day: 10,
      daily_sales: 2300,
      qty: 350,
      ppt: 6.8,
      vpt: 11.9,
      ppp: 60
   },
   {
      day: 11,
      daily_sales: 2000,
      qty: 300,
      ppt: 6,
      vpt: 10.5,
      ppp: 55
   },
   {
      day: 12,
      daily_sales: 2150,
      qty: 315,
      ppt: 6.2,
      vpt: 11.2,
      ppp: 56
   }

];


