import { Card, CardContent } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Svg_bookmark from '../../../assets/svgs/bookmark.svg'
import Svg_man from '../../../assets/svgs/man.svg'
import Svg_share from '../../../assets/svgs/share.svg'
import AddScheduleModal from '../../../components/employee/addScheduleModal'
import CalculatePayrollModal from '../../../components/employee/calculatePayrollModal'
import ScheduleConflictModal from '../../../components/employee/scheduleConflictModal'
import StaffService from '../../../services/staffService'
import { Payrolls } from './PayrollsBox'
import { ScheduleRequests } from './ScheduleRequestsBox'

export const employeesText = (text) => t(`employees.profile.view.${text}`)

const StaffProfileScreen = () => {
	const { id: employee_id } = useParams()
	const [isCalculatePayrollOpen, setCalculatePayrollOpen] = useState(false)
	const [isScheduleConflictOpen, setScheduleConflictOpen] = useState(false)
	const [isAddScheduleOpen, setAddScheduleOpen] = useState(false)
	const [schedules, setSchedules] = useState([])
	const [data, setData] = useState({})
	const [employees, setEmployees] = useState([])

	useEffect(() => {
		// call api, Employee, setData
		StaffService.ReadOne(employee_id).then((res) => {
			setData(res.data)
		})
		StaffService.ScheduleRequests({ employee_id }).then((res) => {
			setSchedules(res.schedule_time_off)
		})
		StaffService.ReadAll().then((res) => {
			setEmployees(res.data)
		})
		// eslint-disable-next-line
	}, [employee_id])

	return (
		<div
			className="overflow-auto"
			style={{ height: 'calc(100vh - 76px - 68px)', margin: 0, padding: 0 }}
		>
			<div className="grid sm:grid-cols-5 grid-cols-1 bg-[#F9F7F7] p-6 gap-6">
				<Card className="">
					<CardContent className="!p-0">
						<div className="flex flex-row items-center pl-4 pr-3 pt-5">
							<span className="text-black1 text-l font-medium">
								{employeesText('employeeHeader')}
							</span>
						</div>
						<div className="flex justify-center items-center">
							<img
								className="h-24 w-24 mt-4 rounded-full self-center]"
								src={Svg_man}
								alt={employeesText('altImage')}
							/>
						</div>
						<div className="text-lg text-black1 font-medium self-center text-center mt-4 mb-0">
							{data.name}
						</div>
						<div className="text-base text-gray5 self-center text-center">
							{data?.role?.name === 'Custom'
								? data?.custom_role
								: data?.role?.name}
						</div>
						<div className="space-x-4 m-4 self-center hidden">
							<img
								className="h-16 w-16"
								src={Svg_bookmark}
								alt={employeesText('altBookmark')}
							/>
							<img
								className="h-16 w-16"
								src={Svg_share}
								alt={employeesText('altShare')}
							/>
						</div>
						<div className="flex flex-col w-full gap-4 mt-10">
							<span
								className="bg-black1 text-sm text-white w-full rounded-lg text-center font-medium py-2.5"
								onClick={() => setCalculatePayrollOpen(true)}
							>
								{employeesText('calculatePayroll')}
							</span>
							<div className="flex flex-row gap-4 w-full">
								<span
									className="w-1/2 text-sm bg-white text-black1 border rounded-lg text-center font-medium py-2.5"
									onClick={() => setScheduleConflictOpen(true)}
								>
									{employeesText('timeOffScheduleConflicts')}
								</span>
								<span
									className="w-1/2 text-sm bg-white text-black1 border rounded-lg text-center font-medium py-2.5"
									onClick={() => setAddScheduleOpen(true)}
								>
									{employeesText('addTimeOffSchedule')}
								</span>
							</div>
						</div>
						<div className="flex flex-col border rounded-lg mt-4">
							<div className="flex flex-row justify-between items-center border-b py-2 px-4">
								<p className="text-20 text-black1 font-medium">
									{employeesText('generalInfo')}
								</p>
							</div>
							<TableBody className="w-full">
								<TableRow className="!flex w-100">
									<TableCell
										align="left"
										className="!text-gray-500 !text-left text-base !pt-[22px] !w-20 border-r"
									>
										{employeesText('name')}
									</TableCell>
									<TableCell
										align="left"
										className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]"
									>
										{data.name}
									</TableCell>
								</TableRow>
								<TableRow className="!flex w-100">
									<TableCell
										align="left"
										className="!text-gray-500 !text-left text-base !pt-[22px] !w-20 border-r"
									>
										{employeesText('email')}
									</TableCell>
									<TableCell
										align="left"
										className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]"
									>
										{data.email}
									</TableCell>
								</TableRow>
								<TableRow className="!flex w-100">
									<TableCell
										align="left"
										className="!text-gray-500 !text-left text-base !pt-[22px] !w-20 border-r"
									>
										{employeesText('salary')}
									</TableCell>
									<TableCell
										align="left"
										className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]"
									>
										<ul style={{ marginTop: '-8px' }}>
											<li>
												<b>{employeesText('frequency')}:</b>
												{data.salary_frequency === 'custom'
													? ` ${employeesText('every')} ${
															data.frequency_number
													  } ${data.frequency_unit}${
															data.frequency_number > 1 ? '' : ''
													  }`
													: data.salary_frequency}
											</li>
											<li>
												<b>{employeesText('salary')}:</b> $ {data.salary}
											</li>
										</ul>
									</TableCell>
								</TableRow>
								<TableRow className="!flex w-100">
									<TableCell
										align="left"
										className="!text-gray-500 !text-left text-base !pt-[22px] !w-20 border-r"
									>
										{employeesText('venue')}
									</TableCell>
									<TableCell
										align="left"
										className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]"
									>
										{data?.venue?.name ?? employeesText('venueName')}
									</TableCell>
								</TableRow>
							</TableBody>
						</div>
					</CardContent>
				</Card>
				<div className="col-span-3 flex flex-col gap-5">
					<ScheduleRequests data={schedules} employees={employees} />
					<Payrolls data={data?.payrolls ?? []} employees={employees} />
				</div>
			</div>
			<CalculatePayrollModal
				showModal={isCalculatePayrollOpen}
				onClose={() => setCalculatePayrollOpen(false)}
				employeeId={employee_id}
			/>
			<ScheduleConflictModal
				showModal={isScheduleConflictOpen}
				onClose={() => setScheduleConflictOpen(false)}
				employeeId={employee_id}
			/>
			<AddScheduleModal
				showModal={isAddScheduleOpen}
				onClose={() => setAddScheduleOpen(false)}
				employeeId={employee_id}
			/>
		</div>
	)
}

export default StaffProfileScreen
