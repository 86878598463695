import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import LoadingScreen from "../../components/custom/LoadingScreen";
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../helpers/venueHelpers'
import VenueBrandProfileService from "../../services/venueBrandProfileService";
import { KEYS } from "../../utils/storage";
import ButtonStyleConfig from './ButtonStyleConfig'

export const customBrandText = (text) => t(`customBrand.view.${text}`)


export default function CustomBrand() {
    const cookies = new Cookies();
    const venueType = cookies.get(KEYS.VENUE_TYPE)
		const [loaded, setLoaded] = useState(false)
		const [elements, setElements] = useState([])

		useEffect(() => {
			refreshData()
		}, [])

		const refreshData = () => {
			VenueBrandProfileService.get()
				.then((res) => {
					setLoaded(true)

					// for food and beverage pick specific elements
					if (isFB(venueType)) {
						// only those with element_name = food
						// 1. FindATimeButton
						// 2. AllButtons
						// 3. BookNowButton
						// 4. Footer
						// 5. Tags
						// 6. SubscribeBtn
						// 7. TimeSuggested
						// 8. ContactFormLeftBlock
						// 9. ContactFormBtn
						// 10. ContactFormTopLabel

						setElements(
							res.filter((e) => {
								return [
									'FindATimeButton',
									'AllButtons',
									'BookNowButton',
									'Footer',
									'Tags',
									'SubscribeBtn',
									'TimeSuggested',
									'ContactFormLeftBlock',
									'ContactFormBtn',
									'ContactFormTopLabel',
								].includes(e.element_name)
							})
						)
					}

					// for retail pick specific elements
					if (isRetailVenue(venueType)) {
						// only those with element_name =
						// 1. AllButtons
						// 2. CartPlusButton
						// 3. Footer
						// 4. Tags
						// 5. OutOfStock
						// 6. SubscribeBtn
						// 7. YourCart
						// 8. AddToCart
						// 9. ContactFormLeftBlock
						// 10. ContactFormTopLabel
						// 11. ContactFormBtn

						setElements(
							res.filter((e) => {
								return [
									'AllButtons',
									'CartPlusButton',
									'Footer',
									'Tags',
									'OutOfStock',
									'SubscribeBtn',
									'YourCart',
									'AddToCart',
									'ContactFormLeftBlock',
									'ContactFormTopLabel',
									'ContactFormBtn',
								].includes(e.element_name)
							})
						)
					}

					// for accommodation pick specific elements
					if (isAccommodation(venueType)) {
						// only those with element_name =
						// 1. AllButtons
						// 2. Footer
						// 3. ContactFormLeftBlock
						// 4. ContactFormTopLabel
						// 5. ContactFormBtn
						setElements(
							res.filter((e) => {
								return [
									'AllButtons',
									'Footer',
									'ContactFormLeftBlock',
									'ContactFormTopLabel',
									'ContactFormBtn',
								].includes(e.element_name)
							})
						)
					}

					// for entertainment_venues pick specific elements
					if (isEntertainmentVenue(venueType)) {
						// only those with element_name =
						// 1. AllButtons
						// 2. Tags
						// 3. Footer
						// 3. ContactFormLeftBlock
						// 4. ContactFormTopLabel
						// 5. ContactFormBtn
						// 6. SubscribeBtn
						setElements(
							res.filter((e) => {
								return [
									'AllButtons',
									'Tags',
									'Footer',
									'ContactFormLeftBlock',
									'ContactFormTopLabel',
									'ContactFormBtn',
									'SubscribeBtn',
								].includes(e.element_name)
							})
						)
					}
				})
				.catch((err) => {
					setLoaded(true)
				})
		}

		return (
			<>
				{loaded ? (
					<>
						<div className="p-6 mb-4">
							<Card className="mt-6">
								<CardContent className="!p-0">
									<div className="w-full rounded-4xl p-6 gap-10">
										<div className="grid md:grid-cols-2 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
											<div className="flex flex-col">
												<p className="text-base text-gray5 mb-2">
													<span className="text-black1 text-xl font-medium">
														{customBrandText('brand_appeal_title')}
													</span>
												</p>
												<span className="text-gray5 text-16 font-medium">
													{customBrandText('brand_appeal_description')}
												</span>
											</div>

											<div className="flex flex-col">
												<p className="text-base text-gray5 mb-2">
													<span className="text-black1 text-xl font-medium">
														{customBrandText('buttons_text_title')}
													</span>
												</p>
												<span className="text-gray5 text-16 font-medium">
													{customBrandText('buttons_text_description')}
												</span>
											</div>
										</div>
									</div>
								</CardContent>
							</Card>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
								{elements.map((element) => (
									<ButtonStyleConfig key={element.id} btnData={element} />
								))}
							</div>
						</div>
					</>
				) : (
					<LoadingScreen />
				)}
			</>
		)
}
