import {
	CircularProgress,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import 'react-time-picker/dist/TimePicker.css'
import saleIcon from '../../assets/svgs/sale_white.svg'
import { boxStyle } from '../../constants/types'
import FeatureFeedbackService from '../../services/FeatureFeedbackService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { RInputDeclare } from '../custom/RInputDeclare'
import { feedbackText } from './index'

export default function FeedbackModal({ showModal, onConfirm, onClose, type }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const [checkValid, setCheckValid] = useState(false)
	const [isUseful, setIsUseful] = useState(null)
	const [isUnclear, setIsUnclear] = useState(null)
	const [isAdditional, setIsAdditional] = useState(null)

	const [usefulDescription, setUsefulDescription] = useState('')
	const [unclearDescription, setUnclearDescription] = useState('')
	const [additionalDescription, setAdditionalDescription] = useState('')

	useEffect(() => {
		if (showModal) {
			setCheckValid(false)
			setIsUseful(null)
			setIsUnclear(null)
			setIsAdditional(null)
			setUsefulDescription('')
			setUnclearDescription('')
			setAdditionalDescription('')
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (isUseful === null) {
			setCheckValid(true)
			return
		}
		if (isUnclear === null) {
			setCheckValid(true)
			return
		}
		if (isAdditional === null) {
			setCheckValid(true)
			return
		}
		let payload = {
			feature_name: type,
			question_1: `${feedbackText('is_page_useful')} ${type} ${feedbackText(
				'does'
			)}?`,
			question_2: `${feedbackText('is_anything_unclear')} ${type}?`,
			question_3: `${feedbackText('additional_feedback')} ${type}?`,
			question_1_answer: parseInt(isUseful),
			question_2_answer: parseInt(isUnclear),
			question_3_answer: parseInt(isAdditional),
			additional_info_question_1: usefulDescription ?? '',
			additional_info_question_2: unclearDescription ?? '',
			additional_info_question_3: additionalDescription ?? '',
		}
		setLoading(true)
		FeatureFeedbackService.submitFeedBack(payload)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent(feedbackText('feature_feedback_service'), {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'submit feedback',
				})
				setLoading(false)
				NotificationManager.success(
					data?.message ?? feedbackText('feature_feedback_saved')
				)
				onClose()
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent(feedbackText('feature_feedback_service'), {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'submit feedback',
				})
				setLoading(false)
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || t('common.error_generic')
						)
					} else {
						NotificationManager.error(t('common.error_generic'))
					}
				}
			})
	}
	const onCloseModal = () => {
		onClose(null)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={saleIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{type} {feedbackText('feature_feedback')}
						</label>
					</div>
					<div className="flex flex-col p-024">
						<RInputDeclare
							value={`${feedbackText('is_page_useful')} ${type} does?`}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<RadioGroup
							row
							value={isUseful}
							className="gap-6"
							onChange={(e) => {
								setIsUseful(e.target.value)
							}}
						>
							<FormControlLabel value={1} control={<Radio />} label="Yes" />
							<FormControlLabel value={0} control={<Radio />} label="No" />
						</RadioGroup>
						<TextField
							required
							size="small"
							maxRows={5}
							minRows={3}
							multiline
							value={usefulDescription}
							placeholder={feedbackText('tell_us_why_optional')}
							style={{ background: '#fff' }}
							sx={{
								'& .MuiOutlinedInput-notchedOutline': {
									border: '1px solid #d9d9d9!important',
								},
							}}
							onChange={(e) => {
								setUsefulDescription(e.target.value)
							}}
						/>
						<RInputDeclare
							value={`${feedbackText('is_anything_unclear')} ${type}?`}
							className="!mt-4 !mb-2 !text-black1 font-medium text-base"
						/>
						<RadioGroup
							row
							value={isUnclear}
							className="gap-6"
							onChange={(e) => {
								setIsUnclear(e.target.value)
							}}
						>
							<FormControlLabel value={1} control={<Radio />} label="Yes" />
							<FormControlLabel value={0} control={<Radio />} label="No" />
						</RadioGroup>
						<TextField
							required
							size="small"
							maxRows={5}
							minRows={3}
							multiline
							value={unclearDescription}
							placeholder={feedbackText('tell_us_what_optional')}
							style={{ background: '#fff' }}
							sx={{
								'& .MuiOutlinedInput-notchedOutline': {
									border: '1px solid #d9d9d9!important',
								},
							}}
							onChange={(e) => {
								setUnclearDescription(e.target.value)
							}}
						/>
						<RInputDeclare
							value={`${feedbackText('additional_feedback')} ${type}?`}
							className="!mt-4 !mb-2 !text-black1 font-medium text-base"
						/>
						<RadioGroup
							row
							value={isAdditional}
							className="gap-6"
							onChange={(e) => {
								setIsAdditional(e.target.value)
							}}
						>
							<FormControlLabel value={1} control={<Radio />} label="Yes" />
							<FormControlLabel value={0} control={<Radio />} label="No" />
						</RadioGroup>
						<TextField
							required
							size="small"
							maxRows={5}
							minRows={3}
							multiline
							value={additionalDescription}
							placeholder={feedbackText('tell_us_what_optional')}
							style={{ background: '#fff' }}
							sx={{
								'& .MuiOutlinedInput-notchedOutline': {
									border: '1px solid #d9d9d9!important',
								},
							}}
							onChange={(e) => {
								setAdditionalDescription(e.target.value)
							}}
						/>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCloseModal}
						>
							{t('Cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? <CircularProgress size={16} /> : t('submit')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
