import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GenericPagination from '../../../../components/generic-table/generic-pagination'
import GenericTable from '../../../../components/generic-table/generic-table'
import TableEmptyData from '../../../../components/table/TableEmptyData'
import { columns, tableTurnoverData } from './data'

export default function TableTurnoverReport() {
	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		loadData()
	}, [])

	const loadData = () => {
		// Use dummy data instead of API call
		setData(tableTurnoverData)
	}

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="flex justify-between items-center pl-6 pr-3 py-4">
					<div className="flex items-center">
						<span className="text-xl font-medium">Table Turnover Report</span>
						<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
							{data.length} tables
						</span>
					</div>
				</div>
				{data.length > 0 ? (
					<>
						<GenericTable
							data={data.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage
							)}
							columns={columns}
							className="!pt-0"
						/>
						<GenericPagination
							count={data.length}
							page={page}
							rowsPerPage={rowsPerPage}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							setPage={setPage}
						/>
					</>
				) : (
					<div className="border border-x-0 border-b-0 mb-10">
						<TableEmptyData content="report" />
					</div>
				)}
			</CardContent>
		</Card>
	)
}
