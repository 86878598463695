import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { t } from 'i18next'
import creditIcon from '../../assets/svgs/credit-card.svg'
import retailService from '../../services/retailService'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import GenericModal from '../generic-modal'

const getTranslation = (text) => t(`components.store.emailModal.${text}`)

export default function EmailModal({ showModal, data, onClose }) {
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState({})

	useEffect(() => {
		if (data !== undefined && data?.recipient) {
			setState({ ...data, new_order_email_recipient: data.recipient })
		}
	}, [data])

	const onSubmit = () => {
		if (!state.new_order_email_recipient) {
			return
		}
		let payload = {
			new_order_email_recipient: state.new_order_email_recipient,
		}

		retailService
			.saveStoreSettings(payload)
			.then(({ data }) => {
				setLoading(false)
				onClose(true)
				NotificationManager.success(
					data?.message ?? 'New recipient email saved successfully'
				)
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
			})
	}

	return (
		<GenericModal
			{...{ onClose, onSubmit, loading }}
			saveText={getTranslation('update')}
			children={
				<CustomFormInput
					title={getTranslation('recipient')}
					required={true}
					value={state?.new_order_email_recipient}
					placeholder={getTranslation('enterRecipientPlaceholder')}
					onChange={(e) =>
						setState({
							...state,
							new_order_email_recipient: e.target.value,
						})
					}
				/>
			}
			icon={creditIcon}
			title={getTranslation('setRecipient')}
			open={showModal}
		/>
	)
}
