import {
	Card,
	CardContent,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from '@mui/material'
import { t } from 'i18next'
import React, { useRef } from 'react'
import editIcon from '../../../assets/svgs/edit.svg'
import plusIcon from '../../../assets/svgs/plus.svg'
import searchIcon from '../../../assets/svgs/search.svg'
import trashIcon from '../../../assets/svgs/trash.svg'
import { createUUID } from '../../../utils/common'

export const vendorcardText = (text) =>
	t(`hygieneStandard.vendors.vendorcard.${text}`)

const VendorCard = ({
	vendorsData = [],
	vendorPage,
	vendorRowsPerPage,
	handleVendorChangePage,
	handleVendorChangeRowsPerPage,
	setShowVendorModal,
	setIsSureModalOpenV,
	columns = [],
	vendorsDescription,
	vendorsDescription2,
	isVendor = true,
}) => {
	const _curVendor = useRef(null)

	const CellColumns = columns.map((item) => (
		<TableCell
			className="!text-gray5 !text-left !font-medium !text-base"
			align="left"
		>
			{item}
		</TableCell>
	))

	return (
		<Card className="mt-6">
			<CardContent className="!p-0">
				<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
					<div className="flex flex-row items-center">
						<Tooltip
							title={vendorcardText('hygieneHealthVendorsDetails')}
							arrow
						>
							<span className="text-black1 text-xl font-medium">
								{vendorcardText('vendors')}
							</span>
							<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
								{vendorsData.length} {vendorcardText('vendorsLower')}
							</span>
						</Tooltip>
					</div>
					<div
						className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
						onClick={() => {
							_curVendor.current = null
							setShowVendorModal(true)
						}}
					>
						<img src={plusIcon} alt="search" className="mr-2" />
						<span>{vendorcardText('addNewVendor')}</span>
					</div>
				</div>

				{vendorsData.length ? (
					<>
						<div className="flex flex-col overflow-auto  max-w-[160vh] max-h-[70vh]">
							<TableContainer component={Paper} className="mt-4">
								<Table
									sx={{ minWidth: 650 }}
									aria-label={vendorcardText('simpleTable')}
								>
									<TableHead className="bg-gray7">
										<TableRow key={createUUID()}>{CellColumns}</TableRow>
									</TableHead>
									<TableBody>
										{vendorsData
											.slice(
												vendorPage * vendorRowsPerPage,
												vendorPage * vendorRowsPerPage + vendorRowsPerPage
											)
											.map((row, index) => (
												<TableRow key={index} style={{ cursor: 'pointer' }}>
													<TableCell
														align="left"
														className="!py-3.5 !text-gray5 !text-left !text-base"
													>
														{row.name}
													</TableCell>
													<TableCell
														align="left"
														className="!py-3.5 !text-gray5 !text-left !text-base"
													>
														{vendorcardText(row.type)}
													</TableCell>
													<TableCell
														align="left"
														className="!py-3.5 !text-gray5 !text-left !text-base"
													>
														{row.contact_name ||
														row.contact_email ||
														row.contact_phone ? (
															<>
																{row.contact_name}
																{(row.contact_email || row.contact_phone) &&
																	' ('}
																{row.contact_email && <>{row.contact_email}</>}
																{row.contact_email && row.contact_phone && ', '}
																{row.contact_phone && <>{row.contact_phone}</>}
																{(row.contact_email || row.contact_phone) &&
																	')'}
															</>
														) : (
															'-'
														)}
													</TableCell>
													<TableCell
														align="left"
														className="!py-3.5 !text-gray5 !text-left !text-base"
													>
														{row.address}
													</TableCell>

													{isVendor && (
														<>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.hygiene_rating}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																<span
																	className={`${
																		row.compliance_certified === 1
																			? 'bg-green-500'
																			: 'bg-red-500'
																	} px-2 py-0.5 w-max text-white text-sm font-bold rounded`}
																>
																	{vendorcardText(
																		row.compliance_certified ? 'yes' : 'no'
																	)}
																</span>
															</TableCell>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.certification_details}
															</TableCell>
														</>
													)}
													<TableCell
														align="left"
														className="!py-3.5 !text-gray5 !text-left !text-base"
													>
														{row.notes}
													</TableCell>
													<TableCell
														align="left"
														className="!flex flex-row !text-gray-500 !py-3.5 !text-left !text-base !justify-left w-[100px]"
													>
														<IconButton
															color="secondary"
															onClick={() => {
																_curVendor.current = row
																setIsSureModalOpenV(true)
															}}
														>
															<img src={trashIcon} alt="trash" />
														</IconButton>
														<IconButton
															color="secondary"
															onClick={(e) => {
																e.stopPropagation()
																_curVendor.current = row
																setShowVendorModal(true)
															}}
														>
															<img src={editIcon} alt="edit" />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={vendorsData.length}
							rowsPerPage={vendorRowsPerPage}
							page={vendorPage}
							className="!p-2"
							onPageChange={handleVendorChangePage}
							onRowsPerPageChange={handleVendorChangeRowsPerPage}
						/>
					</>
				) : (
					<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
						<div className="bg-gray7 !p-2 rounded-full">
							<div className="bg-black1 !p-2 rounded-full">
								<img src={searchIcon} alt="search" />
							</div>
						</div>
						<span className="text-black1 text-lg">
							{vendorcardText('noVendorsAddedYet')}
						</span>
						<span className="text-gray5 text-base mt-1">
							{vendorcardText('vendorsDescription')} <br />
							{vendorcardText('vendorsDescription2')}
						</span>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer mt-4"
							onClick={() => {
								_curVendor.current = null
								setShowVendorModal(true)
							}}
						>
							<img src={plusIcon} alt="plus" className="mr-2" />
							<span>{vendorcardText('addNewVendor')}</span>
						</div>
					</div>
				)}
			</CardContent>
		</Card>
	)
}

export default VendorCard
