import { MenuItem } from '@mui/material'
import React from 'react'

export default function SelectItems(data) {
	return data.map((customer) => (
		<MenuItem key={customer.value} value={customer.value}>
			{customer.label}
		</MenuItem>
	))
}
