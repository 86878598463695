import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { boxStyle } from '../../constants/types'
import accommodationService from '../../services/accommodationService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { RInputDeclare } from '../custom/RInputDeclare'

export const prepaymentModalText = (text) =>
	t(`components.accommodation.prepaymentModal.${text}`)

export default function PrepaymentModal({
	showModal,
	data,
	rentalUnitId,
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [prepaymentAmount, setPrepaymentAmount] = useState('10.00')
	const navigate = useNavigate()

	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		if (showModal) {
			if (data?.prepayment_amount != null) {
				setPrepaymentAmount(data?.prepayment_amount)
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (!prepaymentAmount) {
			return
		}

		const details = {
			type: 'prepayment_amount',
			prepayment_amount: prepaymentAmount,
		}
		setLoading(true)
		accommodationService
			.UpdateReantalUnitPoliciesAndRules(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('UpdateReantalUnitPoliciesAndRules', {
					action_category: 'PoliciesAndRules',
					action_outcome: 'success',
					interaction_element: 'PoliciesAndRules',
				})
				setLoading(false)
				NotificationManager.success(
					'Prepayment Amount has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=4`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('UpdateReantalUnitPoliciesAndRules', {
					action_category: 'PoliciesAndRules',
					action_outcome: 'fail',
					interaction_element: 'PoliciesAndRules',
				})
				setLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on updating the Prepayment Amount')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-col justify-between px-2 m-4 relative">
						<p className="text-black1 text-20 font-semibold leading-[25px] mb-2 py-2">
							{prepaymentModalText('question')}
						</p>

						<p className="text-[#027A48] text-14 font-normal mb-2">
							{prepaymentModalText('recommended')}
						</p>

						<div className="flex gap-3 w-fit rounded-full border-[#DDD] border-[1px] bg-white">
							{['10.00', '15.00', '25.00', '30.00', '40.00', '50.00'].map(
								(percentage) => (
									<button
										key={percentage}
										className={`rounded-full py-[10px] px-[29px] ${
											prepaymentAmount === percentage
												? 'border-[1px] border-black1 bg-[#fcf5f3]'
												: ''
										}`}
										onClick={() => setPrepaymentAmount(percentage)}
									>
										{percentage}%
									</button>
								)
							)}
						</div>

						<RInputDeclare
							value={prepaymentModalText('custom')}
							className="!mt-4 !mb-2 !text-black1 text-base"
						/>
						<TextField
							required
							label=""
							size="small"
							placeholder={prepaymentModalText('placeholder')}
							value={prepaymentAmount}
							onChange={(e) => setPrepaymentAmount(e.target.value)}
						/>

						<div className="flex mt-4 gap-3">
							<button
								className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center py-[10px] text-cyan1"
								onClick={onClose}
							>
								{prepaymentModalText('cancel')}
							</button>
							<button
								className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center py-[10px] text-white"
								onClick={onSubmit}
							>
								{loading ? (
									<CircularProgress size={16} />
								) : (
									prepaymentModalText('save')
								)}
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
