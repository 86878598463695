import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableRowClasses,
} from '@mui/material'
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { t } from 'i18next'
import React, { useEffect, useState } from "react";
import creditIcon from "../../../../assets/svgs/credit-card.svg";
import { boxStyle } from "../../../../constants/types";
import './index.css'

const StyledTableRow = styled(TableRow)(({ theme, showModal }) => ({
	[`&.${tableRowClasses.root}`]: {
		//   height: "200px"
	},
}))

export const availabiltyCalendarText = (text) =>
	t(`accommodation.rental_unit.show.availabilityCalendar.${text}`)

const AvailabilityCalendar = (props) => {
	const {
		monthValue = new Date(),
		events = [],
		onChange = () => {},
		data,
	} = props;
	const [weeks, setWeeks] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const bookings = data || [];

	const handleClose = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		const month = monthValue.getMonth()
		const year = monthValue.getFullYear()
		const daysInMonth = new Date(year, month + 1, 0).getDate()
		const firstDayOfWeek = new Date(year, month, 1).getDay()

		// Generate an array of days in the month
		const days = Array.from({ length: daysInMonth }, (_, index) => index + 1)

		// Generate an array of empty cells for leading days in the week
		const leadingEmptyCells = Array.from(
			{ length: firstDayOfWeek },
			(_, index) => null
		)

		// Group the days into weeks
		const weeks = [...leadingEmptyCells, ...days].reduce((acc, day, index) => {
			if (index % 7 === 0) {
				return [...acc, [day]]
			} else {
				acc[acc.length - 1].push(day)
				return acc
			}
		}, [])
		setWeeks(weeks)
	}, [monthValue])

	// Adjust this function to handle the bookings data
	const getDateStr = (day) => {
		return `${monthValue.getFullYear()}-${String(
			monthValue.getMonth() + 1
		).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
	};

	const isWithinRange = (day) => {
		const dateStr = getDateStr(day);
		return bookings.some((b) => {
			const start = b.start_date || b.check_in_date;
			const end = b.end_date || b.check_out_date;
			return dateStr >= start && dateStr < end;
		});
	};

	const getBookingsForDay = (day) => {
		const dateStr = getDateStr(day);
		return bookings.filter((b) => {
			const start = b.start_date || b.check_in_date;
			const end = b.end_date || b.check_out_date;
			return dateStr >= start && dateStr < end;
		});
	};

	const handleCellClick = (day) => {
		const bookingsForDay = getBookingsForDay(day)
		if (bookingsForDay.length > 0) {
			setSelectedBooking(bookingsForDay[0]) // Taking the first booking
			setIsModalOpen(true)
		}
	}

	const getDayRangeType = (day) => {
		const dateStr = getDateStr(day);
		for (let b of bookings) {
			const start = b.start_date || b.check_in_date;
			const end = b.end_date || b.check_out_date;
			if (dateStr === start) return 'start';
			if (dateStr > start && dateStr < end) return 'middle';
			if (dateStr === end) return 'end';
		}
		return null;
	};

	const getBookingTitleForDay = (day) => {
		const bookingsForDay = getBookingsForDay(day);
		if (bookingsForDay.length > 0) {
			return bookingsForDay[0].title || 'Booking';
		}
		return null;
	};


	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table className="month-calendar-table">
					<TableHead className="">
						<TableRow>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								Sunday
								{/*{availabiltyCalendarText('sunday')}*/}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								Monday
								{/*{availabiltyCalendarText('monday')}*/}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								Tuesday
								{/*{availabiltyCalendarText('tuesday')}*/}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								Wednesday
								{/*{availabiltyCalendarText('wednesday')}*/}
								{/*{availabiltyCalendarText('wednesday')}*/}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								Thursday
								{/*{availabiltyCalendarText('thursday')}*/}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								{/*{availabiltyCalendarText('friday')}*/}
								Friday
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
								align="left"
							>
								Satuday
								{/*{availabiltyCalendarText('saturday')}*/}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{weeks.map((week, index) => (
							<StyledTableRow key={index}>
								{week.map((day, index) => {
									const rangeType = getDayRangeType(day)
									const isInRange = isWithinRange(day)
									const bookingTitleForDay = getBookingTitleForDay(day)
									return (
										<TableCell
											className={`!text-gray5 !text-left !font-medium !text-base month-calendar-cell cursor-pointer`}
											align="left"
											key={index}
											onClick={() => handleCellClick(day)}
										>
											<div>{day}</div>
											{isInRange && (
												<div className={`range-bar ${rangeType}-range`}>
													{bookingTitleForDay}
												</div>
											)}
										</TableCell>
									)
								})}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{isModalOpen && (
				<Modal
					open={isModalOpen}
					onClose={() => {
						handleClose()
						setSelectedBooking(null)
					}}
					className="category-modal "
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={boxStyle} className="outline-none h-auto min-h-[8rem]">
						<div className="flex flex-row items-center justify-between gap-3 px-024 pb-016 pt-024">
							<div className="flex flex-row items-center gap-3">
								<div className="p-2 rounded-full bg-gray7">
									<div className="p-2 rounded-full bg-black1">
										<img src={creditIcon} alt="icon" />
									</div>
								</div>
								<label className="font-semibold text-20 text-black1">
									{availabiltyCalendarText('bookingDetail')}
								</label>
							</div>
							<CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
						</div>

						{selectedBooking ? (
							<div className="px-12 pb-12">
								<Table sx={{ width: '100%', padding: '20px' }} size="medium">
									<TableBody>
										{selectedBooking.title && (
											<TableRow>
												<TableCell>
													<strong>{availabiltyCalendarText('title')}</strong>
												</TableCell>
												<TableCell>{selectedBooking.title}</TableCell>
											</TableRow>
										)}
										{selectedBooking.description && (
											<TableRow>
												<TableCell>
													<strong>
														{availabiltyCalendarText('description')}
													</strong>
												</TableCell>
												<TableCell>{selectedBooking.description}</TableCell>
											</TableRow>
										)}
										{selectedBooking.event_description && (
											<TableRow>
												<TableCell>
													<strong>
														{availabiltyCalendarText('eventDescription')}
													</strong>
												</TableCell>
												<TableCell
													sx={{
														maxWidth: '340px !important',
														wordWrap: 'break-word',
													}}
												>
													{selectedBooking.event_description || 'N/A'}
												</TableCell>
											</TableRow>
										)}
										{selectedBooking.type && (
											<TableRow>
												<TableCell>
													<strong>{availabiltyCalendarText('type')}</strong>
												</TableCell>
												<TableCell>
													{capitalizeFirstLetter(selectedBooking.type)}
												</TableCell>
											</TableRow>
										)}
										{selectedBooking.summary && (
											<TableRow>
												<TableCell>
													<strong>{availabiltyCalendarText('summary')}</strong>
												</TableCell>
												<TableCell>{selectedBooking.summary}</TableCell>
											</TableRow>
										)}
										{selectedBooking.start_date && (
											<TableRow>
												<TableCell>
													<strong>
														{availabiltyCalendarText('startDate')}
													</strong>
												</TableCell>
												<TableCell>{selectedBooking.start_date}</TableCell>
											</TableRow>
										)}
										{selectedBooking.end_date && (
											<TableRow>
												<TableCell>
													<strong>{availabiltyCalendarText('endDate')}</strong>
												</TableCell>
												<TableCell>{selectedBooking.end_date}</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</div>
						) : (
							availabiltyCalendarText('noEventDetails')
						)}
					</Box>
				</Modal>
			)}
		</>
	)
}

export default AvailabilityCalendar;
