import {
	Box,
	Checkbox,
	CircularProgress,
	Modal,
	TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import CustomSelect from '../../../components/custom/CustomSelect'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'

import { NotificationManager } from 'react-notifications'
import v2TableWhite from '../../../assets/svgs/v2-table-white.svg'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import HygieneStandardService from '../../../services/hygieneStandardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

const vendorTypes = [
	'Cleaning Services',
	'Sanitization',
	'Personal Hygiene Products',
	'Waste Management',
	'Other',
]

export const createVendorModalText = (text) =>
	t(`hygieneStandard.vendors.createVendorModal.${text}`)

export default function CreateVendorModal({
	showModal,
	data,
	onClose,
	isVendor = true,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const vendorService = isVendor
		? HygieneStandardService.CreateHygieneVendor
		: HygieneStandardService.CreateHygieneVendor

	const [state, setState] = useState({
		available: 1,
	})

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
			}
		}

		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const handleChangeType = (value, index) => {
		setState((v) => ({ ...v, type: value }))
	}

	const onSubmit = () => {
		const { name, type } = state

		if (!name) {
			NotificationManager.warning('Vendor Name is required!')
			return
		}

		if (!type) {
			NotificationManager.warning('Vendor type is required!')
			return
		}

		if (state.id) {
			setLoading(true)
			HygieneStandardService.UpdateHygieneVendor({
				...state,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'hygiene vendor',
					})
					NotificationManager.success(
						'Vendor has been updated successfully',
						'Success',
						3000
					)
					onClose(true)
					setLoading(false)
				})
				.catch((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'hygiene vendor',
					})
					NotificationManager.error('Failed', '')
					setLoading(false)
				})
		} else {
			setLoading(true)
			vendorService({
				...state,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'hygiene vendor',
					})
					NotificationManager.success(
						'Vendor has been created successfully',
						'Success',
						3000
					)
					onClose(true)
					setLoading(false)
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'hygiene vendor',
					})
					if (typeof err?.error === 'string') {
						NotificationManager.error(err?.error)
					} else {
						const errorKeys = Object.keys(err?.error || {})
						if (errorKeys.length > 0) {
							const firstErrorKey = errorKeys[0]
							const firstErrorMessage = err?.error[firstErrorKey][0]
							NotificationManager.error(
								firstErrorMessage || 'Something went wrong!'
							)
						} else {
							NotificationManager.error('Something went wrong!')
						}
					}
					setLoading(false)
					throw new Error('Problem on CreateHygieneVendor')
				})
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-2 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 text-white p-2 rounded-full">
								<div className="bg-black1 rounded-full">
									<img src={v2TableWhite} width={20} alt="icon" />
								</div>
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{createVendorModalText('editOrNewVendor')}
							</label>
						</div>
					</div>
					<div className="flex flex-col pb-0 p-024 mb-4">
						<RInputDeclare
							value={createVendorModalText('vendorName')}
							className={`!mt-0 !mb-2 !text-black1 font-medium`}
						/>
						<TextField
							required
							size="small"
							value={state?.name}
							placeholder={createVendorModalText('enterVendorName')}
							onChange={(e) => setState({ ...state, name: e.target.value })}
						/>

						<RInputDeclare
							value={createVendorModalText('type')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<CustomSelect
							values={vendorTypes}
							value={state?.type}
							placeholder={createVendorModalText('chooseType')}
							height="!h-10"
							className="!h-10 border !border-gray6"
							handleChange={handleChangeType}
						/>

						<RInputDeclare
							value={createVendorModalText('contactName')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							required
							size="small"
							value={state?.contact_name}
							placeholder={createVendorModalText('contactNamePlaceholder')}
							className="!text-slate-700"
							onChange={(e) =>
								setState({ ...state, contact_name: e.target.value })
							}
						/>

						<RInputDeclare
							value={createVendorModalText('contactPhone')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							required
							size="small"
							value={state?.contact_phone}
							placeholder={createVendorModalText('contactPhonePlaceholder')}
							className="!text-slate-700"
							onChange={(e) =>
								setState({ ...state, contact_phone: e.target.value })
							}
						/>

						<RInputDeclare
							value={createVendorModalText('contactEmail')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							required
							size="small"
							value={state?.contact_email}
							placeholder={createVendorModalText('contactEmailPlaceholder')}
							className="!text-slate-700"
							onChange={(e) =>
								setState({ ...state, contact_email: e.target.value })
							}
						/>

						<RInputDeclare
							value={createVendorModalText('address')}
							className={` !mb-2 !mt-5 !text-black1 font-medium `}
						/>
						<TextField
							required
							size="small"
							value={state?.address}
							placeholder={createVendorModalText('vendorAddressPlaceholder')}
							onChange={(e) => setState({ ...state, address: e.target.value })}
						/>

						{isVendor && (
							<>
								<RInputDeclare
									value={createVendorModalText('hygieneRating')}
									className={` !mb-2 !mt-5 !text-black1 font-medium `}
								/>
								<TextField
									required
									size="small"
									value={state?.hygiene_rating}
									placeholder={createVendorModalText(
										'hygieneRatingPlaceholder'
									)}
									onChange={(e) =>
										setState({ ...state, hygiene_rating: e.target.value })
									}
								/>
								<div className="flex flex-row">
									<RInputDeclare
										value={createVendorModalText('complianceCertified')}
										className={` !mb-2 !mt-5 !text-black1 font-medium `}
									/>
									<Checkbox
										checked={state?.compliance_certified}
										onChange={(e) =>
											setState({
												...state,
												compliance_certified: e.target.checked,
											})
										}
										className="mt-2"
										style={{ marginTop: '8px' }}
									/>
								</div>

								<RInputDeclare
									value={createVendorModalText('certificationDetails')}
									className={` !mb-2 !mt-5 !text-black1 font-medium `}
								/>
								<TextField
									required
									size="small"
									value={state?.certification_details}
									placeholder={createVendorModalText(
										'certificationDetailsPlaceholder'
									)}
									onChange={(e) =>
										setState({
											...state,
											certification_details: e.target.value,
										})
									}
								/>
							</>
						)}
						<RInputDeclare
							value={createVendorModalText('notes')}
							className={` !mb-2 !mt-5 !text-black1 font-medium `}
						/>
						<TextField
							required
							size="small"
							multiline={true}
							minRows={2}
							value={state?.notes}
							placeholder={createVendorModalText('notesPlaceholder')}
							onChange={(e) => setState({ ...state, notes: e.target.value })}
						/>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{createVendorModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								`${createVendorModalText('updateOrCreate')}`
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
