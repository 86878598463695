import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TshirtIcon from '../../../assets/svgs/Bg.svg'
import CartIcon from '../../../assets/svgs/cart.svg'
import DiscountIcon from '../../../assets/svgs/discount1.svg'
import GiftCardIcon from '../../../assets/svgs/giftcard.svg'
import PlusIcon from '../../../assets/svgs/plus1.svg'
import RewardIcon from '../../../assets/svgs/reward.svg'
import SaveIcon from '../../../assets/svgs/save.svg'
import TrashIcon from '../../../assets/svgs/trash-outline.svg'
import UserIcon from '../../../assets/svgs/users.svg'
import {
	deletFromRetailCart,
	getRetailCart,
	updateRetailCart,
} from '../../../utils/storage'

import './index.css'

const RetailCheckoutMobile = (props) => {
	const { list } = props

	const { short_code } = useSelector((state) => state.app)

	const [updateList, setUpdateList] = useState(false)

	let navigate = useNavigate()

	const [flag, setFlag] = useState(false)

	const [cart, setCart] = useState([])

	// Iterate through the array and calculate the total cost

	let totalCost = 0

	cart?.forEach((item) => {
		totalCost += item.quantity * item.price
	})

	useEffect(() => {
		setCart(getRetailCart())

		setUpdateList(false)

		// eslint-disable-next-line
	}, [updateList])


	const deleteFromCart = (id) => {
		deletFromRetailCart(id)

		setUpdateList(true)
	}

	const redirectToPay = () => {
		navigate(`/${short_code}/admin/pos/retail/pay`)
	}

	const currencySymbols = {
		ALL: 'Lek',
		USD: '$',
		EUR: '€',
		// Add other currencies and their symbols here
	}

	const getCurrencySymbol = (currencyCode) => {
		return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
	}

	const [isOpen, setIsOpen] = useState(false)
	return isOpen ? (
		<div className="p-6 bg-[#240b3b] w-full z-[100] absolute top-0 bottom-0">
			<div className="flex flex-row justify-center items-center">
				<p
					onClick={() => setIsOpen(false)}
					className="text-white text-[30px] inline p-2"
				>
					{`<`}
				</p>
				<p className="text-white text-20 text-center inline w-full font-semibold leading-[140%]">
					New Order #34562
				</p>
			</div>
			{/*<div className="flex gap-2 py-4  border-[#393C49] border-t-[1px]">*/}
			{/*	<button className="bg-white rounded-[5px] items-center justify-between flex flex-1 py-[7px] px-[12px] text-[#240b3b] text-14 font-semibold leading-[140%] ">*/}
			{/*		<div className="align-middle">*/}
			{/*			<img src={UserIcon} className="inline mr-2" />*/}
			{/*			<span className="">Add a customer</span>*/}
			{/*		</div>*/}
			{/*		<span>→</span>*/}
			{/*	</button>*/}
			{/*</div>*/}
			<div>
				<div className="border-[#393C49]  py-2 ">
					<div className="flex flex-col justify-between">
						{cart?.map((item) => {
							return (
								<div className="border-y-[1px] border-[#393C49] py-2">
									<div className="flex justify-between flex-row items-center">
										<div className="flex justify-start py-2  ">
											<div className="w-[71px] h-[71px] text-16 bg-[#F7F8F9] font-semibold align-middle rounded-[4px] mr-2">
												<img src={TshirtIcon} />
											</div>
											<div className="flex flex-col justify-start h-full">
												<p className="text-white text-16 font-medium">
													{item?.length}
												</p>
												<p className="text-[#ABBBC2] text-14 font-medium">
													{item?.quantity} X {getCurrencySymbol(item?.currency)}
													{item?.price}
												</p>
											</div>
										</div>
										<p className="text-16 font-medium leading-[140%] text-white ">
											{getCurrencySymbol(item?.currency)}
											{item?.quantity * item?.price}
										</p>
									</div>
									<div className="flex justify-between items-center gap-4 py-2">
										<input
											placeholder="Add note"
											className="flex flex-1 text-white border-[1px] bg-transparent border-[#E4E4E4] rounded-[8px] p-[14px]"
										/>
										<button
											className=" bg-transparent border-[1px] border-[#E4E4E4] rounded-[8px] p-[14px]"
											onClick={() => {
												deleteFromCart(item?.id)
											}}
										>
											<img src={TrashIcon} />
										</button>
									</div>
								</div>
							)
						})}
						<div className="grid grid-cols-3 gap-[15px] border-y-[1px] border-[#D1D1D1] py-4">
							<div className="bg-white border-white border-[1px] rounded-[8px]">
								<div className="bg-[#240b3b] h-10 rounded-[8px] align-middle">
									<img src={DiscountIcon} className="  w-[15px] h-[15px] " />
								</div>
								<div className="h-[20px] align-middle">
									<p className="text-[#1F1D2B] text-[11px]  font-semibold leading-[130%] ">
										Discount
									</p>
								</div>
							</div>
							{/*<div className="bg-white border-white border-[1px] rounded-[8px]">*/}
							{/*	<div className="bg-[#240b3b] h-10 rounded-[8px] align-middle">*/}
							{/*		<img src={RewardIcon} className="  w-[15px] h-[15px] " />*/}
							{/*	</div>*/}
							{/*	<div className="h-[20px] align-middle">*/}
							{/*		<p className="text-[#1F1D2B] text-[11px]  font-semibold leading-[130%] ">*/}
							{/*			Rewards{' '}*/}
							{/*		</p>*/}
							{/*	</div>*/}
							{/*</div>*/}
							{/*<div className="bg-white border-white border-[1px] rounded-[8px]">*/}
							{/*	<div className="bg-[#240b3b] h-10 rounded-[8px] align-middle">*/}
							{/*		<img src={GiftCardIcon} className="  w-[15px] h-[15px] " />*/}
							{/*	</div>*/}
							{/*	<div className="h-[20px] align-middle">*/}
							{/*		<p className="text-[#1F1D2B] text-[11px]  font-semibold leading-[130%] ">*/}
							{/*			Gift Cards{' '}*/}
							{/*		</p>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</div>
						<div className="py-2">
							<div className="flex justify-between py-2">
								<p className="text-[#E5EAFF] text-14 font-normal ">Discount</p>
								<p className="text-white text-14 font-medium leading-[130%]">
									{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}0
								</p>
							</div>
							<div className="flex justify-between py-2">
								<p className="text-[#E5EAFF] text-14 font-normal ">Sub total</p>
								<p className="text-white text-14 font-medium leading-[130%]">
									{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
									{totalCost}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full absolute bottom-6 px-6 right-0 flex gap-3">
				{/*<button className=" bg-white rounded-[8px] p-[14px]  text-center    text-[#1F1D2B] text-14 font-semibold leading-[140%] w-fit">*/}
				{/*	<img src={SaveIcon} />*/}
				{/*</button>*/}
				<button
					className=" bg-[#027A48] rounded-[8px] p-[14px]  text-center    text-white text-18 font-semibold leading-[140%] flex-1 "
					onClick={() => {
						redirectToPay()
					}}
				>
					Charge {getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
					{totalCost}
				</button>
			</div>
		</div>
	) : (
		<div
			className={
				'relative sub-container view-sales overflow-auto px-4 h-full w-full bg-[#F9F7F7]'
			}
		>
			<div className="flex flex-wrap gap-3 py-2">
				{list?.map((item) => {
					return (
						<div
							className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[344px] h-[118px] flex"
							onClick={() => {
								updateRetailCart(item)
								setUpdateList(true)
							}}
						>
							<img className=" rounded-[8px] object-cover  " src={TshirtIcon} />
							<div className=" flex justify-between bg-white items-center rounded-r-[8px] ">
								<div className="flex-1 flex justify-between flex-col p-3 rounded-r-[8px] h-full">
									<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
										{item?.title}
									</p>
									<p className="text-[#8E8E8E] text-14 font-semibold leading-[130%] ">
										x in stock
										{/*/ 2 variants*/}
									</p>
									<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
										{getCurrencySymbol(
											cart?.length ? cart[0]?.currency : 'USD'
										)}
										{item?.price}
									</p>
								</div>
								<img src={PlusIcon} className="w-10 h-10 mr-3" />
							</div>
						</div>
					)
				})}
			</div>

			<div className="h-15 w-full absolute bottom-[10px] right-0 px-6">
				<div
					className="flex justify-between p-5 rounded-[5px]   bg-[#240b3b]"
					onClick={() => setIsOpen(true)}
				>
					<div className="flex">
						<img src={CartIcon} className="mr-2" />
						<p className="text-white text-16 font-medium ">
							{' '}
							{cart?.length} Items
						</p>
					</div>
					<p className="text-white text-16 font-medium ">Total: {getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}{totalCost}</p>
				</div>
			</div>
		</div>
	)
}

export default RetailCheckoutMobile
