import { Card, CardContent } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate } from 'react-router-dom'
import InventoryModal from '../../components/inventory/InventoryModal'
import InventoryService from '../../services/inventoryService'
import MenuService from '../../services/menuService'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import plusIcon from '../../assets/svgs/plus.svg'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericPagination from '../../components/generic-table/generic-pagination'
import GenericTable from '../../components/generic-table/generic-table'
import useDebounce from '../../components/Hooks/useDebounce'
import TableEmptyData from '../../components/table/TableEmptyData'
import { isRetailVenue } from '../../helpers/venueHelpers'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { KEYS } from '../../utils/storage'
import { columns } from './data'
import RenderRetailInventoryTable from './retail/inventory-table'

export const inventoryText = (text) => t(`menu_management.inventory.${text}`)

export default function Inventory() {
	const navigate = useNavigate()
	const cookies = new Cookies()
	const _curInventory = useRef(null)
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [categories, setCategories] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [callEnd, setCallEnd] = useState({ data: false, category: false })
	const { short_code } = useSelector((state) => state.app)
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const [search, setSearch] = useState('')
	const debouncedValue = useDebounce(search, 900)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		loadData()
		loadCategories()
	}, [debouncedValue])

	useEffect(() => {
		if (callEnd.data && callEnd.category) {
			setLoaded(true)
		}
	}, [callEnd])

	const loadData = () => {
		InventoryService.getInventories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'inventory',
				})
				setCallEnd((v) => ({ ...v, data: true }))
				setData(res?.data || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'inventory',
				})
				setCallEnd((v) => ({ ...v, data: true }))
			})
	}

	const loadCategories = () => {
		MenuService.getCategories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				setCallEnd((v) => ({ ...v, category: true }))
				setCategories(res?.categories || [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'categories',
				})
				setCallEnd((v) => ({ ...v, category: true }))
			})
	}

	const onView = (row) =>
		navigate(`/${short_code}/admin/menu/inventory/${row.actions.id}`)

	const mapData = data.map((row, index) => ({
		no: index + 1,
		label: row.label,
		category: row.category,
		number_of_products: row.products?.length ?? 0,
		actions: row,
	}))

	if (isRetailVenue(venueType)) {
		return (
			<RenderRetailInventoryTable />
		)
	}
	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{inventoryText('inventory')}
									</span>
									<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
										{data.length} {inventoryText('inventory')}
									</span>
								</div>
								<div
									className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
									onClick={() => {
										_curInventory.current = null
										setShowModal(true)
									}}
								>
									<img src={plusIcon} alt="search" className="mr-2" />
									<span>{inventoryText('add_inventory')}</span>
								</div>
							</div>
							{data.length > 0 ? (
								<div className="flex flex-col overflow-auto max-h-[70vh]">
									<GenericTable
										data={mapData}
										columns={columns(inventoryText)}
										onView={onView}
									/>
									<GenericPagination
										count={data.length}
										page={page}
										rowsPerPage={rowsPerPage}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										setPage={setPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={inventoryText('inventory')}
										btnLabel={inventoryText('add_inventory')}
										onAction={() => setShowModal(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
			{showModal && (
				<InventoryModal
					categories={categories}
					showModal={showModal}
					onClose={(refresh) => {
						if (refresh === true) {
							loadData()
						}
						setShowModal(false)
					}}
					curInventory={_curInventory.current}
				/>
			)}
		</div>
	)
}
