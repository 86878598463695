import React from 'react';

const CustomToggleButton = ({
    label = '',
    classes = '',
    active = false,
    onAction = () => {}
}) => {
    const handleAction = () => {
        onAction();
    };

    return (
        <div className='my-2'>
            <button
                className={`min-h-[40px] rounded-lg grow h-full flex justify-center items-center font-medium
                    ${active ? 'bg-white text-cyan1 border border-cyan1 ' : `bg-white text-neutral border border-gray6`} ${classes}`}
                onClick={handleAction}
            >
                {label}
            </button>
        </div>
    )
};

export default CustomToggleButton;
