import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'
import Svg_close from '../../../assets/svgs/close.svg'
import { boxStyle } from '../../../constants/types'
import StaffService from '../../../services/staffService'
import { RInputDeclare } from '../../custom/RInputDeclare'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import ReportService from '../../../services/reportService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

const newStaffText = (text) =>
	t(`components.report.staff.newStaffExpense.${text}`)

export default function NewStaffExpense({
	showModal,
	onConfirm,
	onClose,
	data,
	refresh,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)

	const [employee, setEmployee] = useState(0)
	const [type, setType] = useState('')
	const [amount, setAmount] = useState(0)
	const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))

	const [employeeOptions, setEmployeeOptions] = useState([])
	const [typeOptions, setTypeOptions] = useState([])

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setEmployee(0)
			setType('')
			setAmount(0)
			setDate(dayjs().format('YYYY-MM-DD'))
			setEmployeeOptions([])

			StaffService.ReadAll().then((res) => {
				setEmployeeOptions(
					res.data.map((item) => {
						return {
							value: item.id,
							label: item.name,
						}
					})
				)
			})

			setTypeOptions(
				['mileage', 'travel', 'other'].map((item) => {
					return { label: item, value: item }
				})
			)
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (employee && type && amount && date) {
			ReportService.NewExpense({
				employee_id: employee,
				amount: amount,
				type: type,
				date: date,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('report service', {
						action_category: 'create',
						action_outcome: 'success',
						interaction_element: 'expense',
					})
					NotificationManager.success('Success')
					onConfirm()
				})
				.catch((e) => {
					identifyVenue(short_code)
					trackEvent('report service', {
						action_category: 'create',
						action_outcome: 'success',
						interaction_element: 'expense',
					})
					NotificationManager.error('Failed')
				})
			onClose()
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div
						className="flex flex-row justify-between px-024 pb-016 pt-024"
						style={{ borderBottom: '1px solid #E4E4E4' }}
					>
						<div>
							<label className="text-20 font-semibold text-gray-500">
								{newStaffText('newStaffExpenseReport')}
							</label>
							<label className="text-24 font-semibold ml-2"></label>
						</div>
						<div onClick={onClose}>
							<img src={Svg_close} alt="" />
						</div>
					</div>
					<div className="flex flex-col p-024 mb-4">
						<RInputDeclare value={newStaffText('employee')} />
						<TextField
							id="outlined-select"
							error={checkValid && !employee}
							select
							value={employee}
							onChange={(event) => {
								setEmployee(event.target.value)
							}}
						>
							{employeeOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare value={newStaffText('type')} />
						<TextField
							id="outlined-select"
							error={checkValid && !type}
							select
							value={type}
							onChange={(event) => {
								setType(event.target.value)
							}}
						>
							{typeOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare value={newStaffText('amount')} />
						<TextField
							required
							error={checkValid && !amount}
							id="filled-required"
							label=""
							type="number"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
						/>

						<RInputDeclare value={newStaffText('date')} />

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label=""
								value={dayjs(date)}
								onChange={(v) => setDate(v.format('YYYY-MM-DD'))}
							/>
						</LocalizationProvider>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-4">
						<button
							className="rounded-[58px] bg-white grow h-full flex justify-center items-center text-cyan1"
							style={{ border: '1px solid #23cbd8' }}
							onClick={onClose}
						>
							{newStaffText('cancel')}
						</button>
						<button
							className="rounded-[58px] bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{newStaffText('confirm')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
