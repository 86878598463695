import {
	AccountBalanceWallet,
	ContentCopyOutlined,
	Email,
	Sms,
	WhatsApp,
} from '@mui/icons-material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/checked_white.svg'
import { smallBoxStyle } from '../../constants/types'

export const generatedLinkModalText = (text) =>
	t(`orderandpay.paymentLinks.generatedLinkModal.${text}`)

export default function GeneratedLinkModal({ showModal, onClose, data }) {
	const [visible, setVisible] = useState(false)
	const { short_code } = useSelector((state) => state.app)

	// main, email, sms, whatsApp, all, also remove static case for main link
	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const onCopyText = ({ value, label }) => {
		if (value) {
			navigator.clipboard.writeText(value)
			NotificationManager.success(
				`${label} is copied successfully and can be shared everywhere`
			)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={smallBoxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{generatedLinkModalText('title')}
						</label>
					</div>
					<div className="flex flex-col p-024">
						<div className="px-4 rounded-12 bg-gray7 w-full">
							<div className="flex flex-row justify-between justify-center items-center w-full p-2">
								<div className="flex flex-row justify-start items-center">
									<div className="!text-black1 font-medium text-base">
										{data?.data}
									</div>
								</div>
								<div
									onClick={() =>
										onCopyText({ value: data?.data, label: 'Link' })
									}
									className="cursor-pointer"
								>
									<ContentCopyOutlined />
								</div>
							</div>
						</div>
						<div className="mt-6 px-4 py-2 rounded-lg border !border-gray6 rounded-6 w-full">
							<div className="flex flex-row justify-between justify-center items-center w-full mt-4 p-2">
								<div className="flex flex-row justify-start items-center">
									<Email />
									<div className="ml-4 !text-black1 font-medium text-base">
										{generatedLinkModalText('sendByEmail')}
									</div>
								</div>
								<div
									onClick={() =>
										onCopyText({ value: data?.data, label: 'Email' })
									}
									className="cursor-pointer"
								>
									<ContentCopyOutlined />
								</div>
							</div>
							<div className="flex flex-row justify-between justify-center items-center w-full mt-4 p-2">
								<div className="flex flex-row justify-start items-center">
									<Sms />
									<div className="ml-4 !text-black1 font-medium text-base">
										{generatedLinkModalText('sendBySms')}
									</div>
								</div>
								<div
									onClick={() =>
										onCopyText({ value: data?.data, label: 'Link' })
									}
									className="cursor-pointer"
								>
									<ContentCopyOutlined />
								</div>
							</div>
							<div className="flex flex-row justify-between justify-center items-center w-full mt-4 p-2">
								<div className="flex flex-row justify-start items-center">
									<WhatsApp />
									<div className="ml-4 !text-black1 font-medium text-base">
										{generatedLinkModalText('sendByWhatsApp')}
									</div>
								</div>
								<div
									onClick={() =>
										onCopyText({ value: data?.data, label: 'Link' })
									}
									className="cursor-pointer"
								>
									<ContentCopyOutlined />
								</div>
							</div>
							<div className="flex flex-row justify-between justify-center items-center w-full mt-4 mb-4 p-2">
								<div className="flex flex-row justify-start items-center">
									<AccountBalanceWallet />
									<div className="ml-4 !text-black1 font-medium text-base">
										{generatedLinkModalText('seeAllPaymentLinks')}
									</div>
								</div>
								<a
									href={`/${short_code}/admin/order-and-pay/payment-links`}
									className="cursor-pointer"
								>
									<ContentCopyOutlined />
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mr-4 mb-4 mt-2 ml-4 px-2">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{generatedLinkModalText('closeButton')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
