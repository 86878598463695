import React, { useEffect, useState } from 'react'

import { t } from 'i18next'
import locationIcon from '../../../assets/svgs/location.svg'
import moneyIcon from '../../../assets/svgs/money.svg'
import moneyWhiteIcon from '../../../assets/svgs/money_white.svg'
import ApplyPromoModal from '../../../components/reservation/applyPromoModal'
import ProvidePaymentModal from '../../../components/reservation/providePaymentModal'

export const paymentPromoText = (text) =>
	t(`reservations.reservationdetail.PaymentPromo.${text}`)

export default function PaymentPromo({
	data,
	reservationId,
	guestId,
	onRefresh,
}) {
	const [applyPromoModal, setApplyPromoModal] = useState(false)
	const [providePaymentModal, setProvidePaymentModal] = useState(false)
	const [disableApplyPromo, setDisableApplyPromo] = useState(false)

	useEffect(() => {
		if (!data?.order?.subtotal || data?.order?.promotion_id) {
			setDisableApplyPromo(true)
		}
	}, [data])
	return (
		<div className="flex flex-col mt-4 w-full">
			<div className="flex flex-row justify-between items-center mb-4">
				<p className="text-black1 text-lg font-medium">
					{paymentPromoText('paymentPromo')}
				</p>
				<div className="flex flex-row items-center gap-5">
					<div
						className={`${
							!data?.order?.subtotal || data?.order?.promotion_id
								? 'bg-gray-500'
								: 'bg-black1'
						} flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer`}
						onClick={() => {
							if (!(!data?.order?.subtotal || data?.order?.promotion_id)) {
								setApplyPromoModal(true)
							}
						}}
					>
						<img src={locationIcon} alt="search" className="mr-2" />
						<span>{paymentPromoText('applyPromo')}</span>
					</div>
					<div
						className={`${
							data?.order && data?.order?.payment_method_id
								? 'bg-gray-500'
								: 'bg-black1'
						} flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer`}
						onClick={() => {
							if (!(data?.order && data?.order?.payment_method_id)) {
								setProvidePaymentModal(true)
							}
						}}
					>
						<img src={moneyWhiteIcon} alt="search" className="mr-2" />
						<span>{paymentPromoText('providePayment')}</span>
					</div>
				</div>
			</div>
			<div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
				<div className="p-4 bg-[#F9F7F7] rounded-lg">
					<img src={moneyIcon} alt="desk" />
				</div>
				<div className="flex-1 flex flex-row gap-16">
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{paymentPromoText('reservationTotal')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.order?.total_amount
									? '$' + data?.order?.total_amount
									: '-'}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{paymentPromoText('promotion')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.order?.promotion?.title ?? '-'}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{paymentPromoText('paymentStatus')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data?.order?.payment_method?.name ?? '-'}
							</span>
						</div>
					</div>
				</div>
			</div>
			<ProvidePaymentModal
				showModal={providePaymentModal}
				reservationId={reservationId}
				onClose={() => setProvidePaymentModal(false)}
				onConfirm={() => {
					setProvidePaymentModal(false)
					onRefresh()
				}}
			/>
			<ApplyPromoModal
				showModal={applyPromoModal}
				reservationId={reservationId}
				guestId={guestId}
				onClose={() => setApplyPromoModal(false)}
				onConfirm={() => {
					setApplyPromoModal(false)
					onRefresh()
				}}
			/>
		</div>
	)
}
