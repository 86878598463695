import { CloseOutlined, InfoOutlined, SmsOutlined } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'

import { t } from 'i18next'
import { rightBoxStyle } from '../../constants/types'
import { Help } from './help'
import Messages from './messages'

export const helpText = (text) => t(`components.helpfulModal.view.${text}`)

export default function HelpfulModal({ showModal, onConfirm, onClose }) {
	const [visible, setVisible] = useState(false)
	const [tab, setTab] = useState('assist')

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal swipe-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ backgroundColor: 'transparent' }}
			>
				<Box
					sx={rightBoxStyle}
					className="outline-none overflow-auto flex flex-col"
				>
					<div className="relative h-20 bg-black1">
						<div
							onClick={onClose}
							className="absolute right-4 top-7 cursor-pointer"
						>
							<CloseOutlined className="text-white" />
						</div>
					</div>
					<div
						className="grid grid-cols-2 justify-between px-024 pb-0 pt-024 relative"
						style={{ borderBottom: '1px solid #E4E4E4' }}
					>
						<div
							className={`flex flex-row justify-center cursor-pointer items-center pb-4 gap-2 border-b-2 ${
								tab === 'assist' ? 'border-black1' : 'border-transparent'
							}`}
							onClick={() => setTab('assist')}
						>
							<SmsOutlined className="!text-xl text-gray5 cursor-pointer" />
							<label className="text-18 font-semibold text-black1 cursor-pointer">
								{helpText('vbAssistant')}
							</label>
						</div>
						<div
							className={`flex flex-row justify-center cursor-pointer items-center pb-4 gap-2 border-b-2 ${
								tab === 'help' ? 'border-black1' : 'border-transparent'
							}`}
							onClick={() => setTab('help')}
						>
							<InfoOutlined className="!text-xl text-gray5 cursor-pointer" />
							<label className="text-18 font-semibold text-black1 cursor-pointer">
								{helpText('help')}
							</label>
						</div>
					</div>
					<div className="w-full flex-1">
						{tab === 'assist' && <Messages />}
						{tab === 'help' && <Help />}
					</div>
				</Box>
			</Modal>
		</div>
	)
}
