import apiFactory from "../utils/apiFactory";
import { API_ACCOUNTS, API_ACCOUNTS_EMAIL } from "../constants/API";


export const update = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...{content_type: 'multipart/form-data', url: 'accounts'}, url: (API_ACCOUNTS.url + '/update-restaurant'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const resendEmailVerify = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/resend-verify-email'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const verifyEmail = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/verify-email'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const getRegisterConfig = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/register-config'), method: 'get' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const checkQrCode = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/check-qrcode'), method: 'get' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const getPaymentConfig = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/payment-config'), method: 'get' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const getPaymentMethods = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/payment-methods'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const addCard = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/add-card'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const payWithCard = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/pay-with-card'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const changeSubscription = (payload) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + '/change-subscribe'), method: 'post' }, payload)
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const getPricingPlan = (plan_id) => {
    return new Promise(async (resolve, reject) => {
        apiFactory({ ...API_ACCOUNTS, url: (API_ACCOUNTS.url + `/pricing-plans`), query: `id=${plan_id}`,  method: 'get' })
            .then(async (data) => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const storeAccountsEmailPreferences = async (payload) => {
	let api = {
		content_type: "application/json",
		url: `${API_ACCOUNTS_EMAIL}`,
		method: "PUT",
	};
	return apiFactory(api, payload);
};