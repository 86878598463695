import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import MenuService from '../../services/menuService'
import CustomSelect from '../custom/CustomSelect'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select'
import GenericModal from '../generic-modal'

const menuText = (text) => t(`components.menus.MenuModal.${text}`)

export default function MenuModal({
	showModal,
	categories = [],
	products = [],
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [category, setCategory] = useState(null)
	const [selectedProducts, setSelectedProducts] = useState([])
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (category?.id == null) {
			return NotificationManager.warning('Please select a category!')
		}
		if (selectedProducts.length === 0) {
			return NotificationManager.warning('Please select products!')
		}

		let payload = {
			category_id: category?.id,
			product_ids: selectedProducts.map((p) => p.id),
		}
		setLoading(true)
		MenuService.storeMenu(payload)
			.then(({ data }) => {
				setLoading(false)
				onClose(true)
				identifyVenue(short_code)
				trackEvent('Create Menu', {
					action_category: 'creation',
					action_outcome: 'success',
					interaction_element: 'menu',
				})
				NotificationManager.success(
					data?.message ?? 'Menu is saved successfully'
				)
			})
			.catch((err) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Create Menu', {
					action_category: 'creation',
					action_outcome: 'failed',
					interaction_element: 'menu',
				})

				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
			})
	}

	const children = (
		<div className="flex flex-col gap-1">
			<CustomFormSelect
				title={menuText('category')}
				value={category?.title}
				options={categories.map((item) => ({
					label: item.title,
					value: item.id,
				}))}
				setOptionValue={setCategory}
				placeholder={menuText('selectCategory')}
			/>

			<RInputDeclare
				value={menuText('products')}
				className="!mt-6 !mb-1 !text-black1 font-medium"
			/>
			<CustomSelect
				height={'!h-10'}
				className={'!h-10 border !border-gray6'}
				values={products}
				value={selectedProducts}
				isMultiple={true}
				placeholder={menuText('selectProducts')}
				renderRow={(item) => <p>{item.title}</p>}
				renderValue={(item) => <span>{item?.title}</span>}
				handleChange={(v) => {
					let tmp = selectedProducts.slice(0)
					let index = tmp.findIndex((t) => t.id === v.id)
					if (index === -1) {
						tmp.push(v)
					}
					setSelectedProducts(tmp)
				}}
				onRemoveItem={(v) => {
					let tmp = selectedProducts.slice(0)
					let index = tmp.findIndex((t) => t.id === v.id)
					if (index !== -1) {
						tmp.splice(index, 1)
					}
					setSelectedProducts(tmp)
				}}
			/>
		</div>
	)

	return (
		<GenericModal
			{...{ onClose, onSubmit, children }}
			open={visible}
			saveText={menuText('confirm')}
			title={menuText('newMenu')}
		/>
	)
}
