import React, { useEffect, useState } from "react";

import CheckoutIcon from "../assets/svgs/checkout.svg";
import TransactionsIcon from "../assets/svgs/transactions.svg";
import NotificationIcon from "../assets/svgs/notifications.svg";
import MoreIcon from "../assets/svgs/more.svg";

const FooterPOS = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) setIsMobile(true);
    else setIsMobile(false);
  }, [window.innerWidth]);

  return (
    <div
      className={` flex flex-1 flex-row justify-between items-center   ${
        isMobile
          ? "bg-white border-[1px] border-[#979797] rounded-t-[24px] p-6 pt-9"
          : "bg-[#E6E6E6] p-4"
      }`}
    >
      <div className="px-8 align-middle">
        <img src={CheckoutIcon} className="inline mr-2" />
        {!isMobile && (
          <span className="text-[#240b3b] font-semibold text-14 leading-6 ">
            Checkout
          </span>
        )}
      </div>
      {/*<div className="px-8 align-middle">*/}
      {/*  <img src={TransactionsIcon} className="inline mr-2" />*/}
      {/*  {!isMobile && (*/}
      {/*    <span className="text-[#240b3b] font-semibold text-14 leading-6 ">*/}
      {/*      Transactions*/}
      {/*    </span>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*{!isMobile && (*/}
      {/*  <div className="px-8 align-middle">*/}
      {/*    <img src={NotificationIcon} className="inline mr-2" />*/}
      {/*    <span className="text-[#240b3b] font-semibold text-14 leading-6 ">*/}
      {/*      Notifications*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}

      {/*<div className="px-8 align-middle">*/}
      {/*  <img src={MoreIcon} className="inline mr-2" />*/}
      {/*  {!isMobile && (*/}
      {/*    <span className="text-[#240b3b] font-semibold text-14 leading-6 ">*/}
      {/*      More*/}
      {/*    </span>*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
};

export default FooterPOS;
