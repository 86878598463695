import React from "react";


const Footer = () => {
  return (
		<div className="flex flex-row justify-end items-center bg-white p-4">
			{/* <img src={Svg_appicon} className="w-[136px] h-[36px]" alt="" /> */}
			<span className="text-cyan1 text-12 font-normal">
				Copyright &copy; 2024 VenueBoost | All Rights Reserved
			</span>
		</div>
	)
};

export default Footer;
