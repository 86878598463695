export const ingredients = [
	{
		id: 1,
		name: 'Ingredient 1',
		description: 'Main Description',
		price: 10,
		unit: 'kg',
		price_per_unit: '$15',
	},
	{
		id: 2,
		name: 'Ingredient 2',
		description: 'Main Description',
		price: 5,
		unit: 'liters',
	},
]

export const unitOptions = [
	{
		label: 'Kg',
		value: 'kg',
	},
	{
		label: 'Liters',
		value: 'liters',
	},
	{
		label: 'Pieces',
		value: 'pieces',
	},
]


export const ingredientOptions = ingredients.map((item) => ({
	label: item.name,
	value: item.id,
}))