import React from 'react'
import { reservationsText } from '.'
import GenericTable from '../../components/generic-table/generic-table'
import { columns, reservationData } from './data'

const ReservationTable = ({
	reservations = [],
	handleReservationDetail,
	setReservation,
	setShowConfirmModal,
	setShowCancelModal,
	page = 0,
	rowsPerPage = 10,
}) => {
	const onConfirmReservation = (item) => {
		setReservation(item)
		setShowConfirmModal(true)
	}

	const onCancelReservation = (item) => {
		setReservation(item)
		setShowCancelModal(true)
	}

	return (
		<GenericTable
			columns={columns(reservationsText)}
			data={reservationData(reservations, page, rowsPerPage)}
			onDelete={onCancelReservation}
			onView={handleReservationDetail}
			onConfirm={onConfirmReservation}
		/>
	)
}

export default ReservationTable
