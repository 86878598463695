import React from 'react'
import ItemsIcon from '../assets/svgs/items.svg'
const SidebarMenu = () => {
	return (
		<div className="p-6 bg-white relative  w-[234px]">
			<p className="text-[#64748B] text-[16px] font-semibold pb-3"> Menu</p>
			<div className="flex flex-col gap-y-4">
				<button className="p-3 gap-2 flex flex-1 items-center bg-[#E7EAEE] rounded-[4px]">
					<img className="w-6 h-6" src={ItemsIcon} alt="" />
					<p className="text-[#191D23] text-16 whitespace-nowrap text-ellipsis overflow-hidden  font-semibold">
						Items
					</p>
				</button>
			</div>
		</div>
	)
}

export default SidebarMenu
