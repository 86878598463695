import {
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
} from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import saleIcon from '../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../components/common/IconButton'
import { boxStyle } from '../../constants/types'
import IngredientService from '../../services/ingredient-service'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { unitOptions } from './data'

export const ingredientModalText = (text) =>
	t(`ingredient.ingredientModal.${text}`)

export default function AddEditIngredient({
	showModal,
	setShowModal,
	onClose,
	recordDetails,
	setRecordDetails,
	getAllIngredients = () => {},
}) {
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState(recordDetails?.name || '')
	const [description, setDescription] = useState(
		recordDetails?.description || ''
	)
	const [unit, setUnit] = useState(recordDetails?.unit || '')
	const [pricePerUnit, setPricePerUnit] = useState(
		recordDetails?.price_per_unit || ''
	)
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		if (recordDetails) {
			setName(recordDetails.name)
			setDescription(recordDetails.description)
			setUnit(recordDetails.unit)
			setPricePerUnit(recordDetails.price_per_unit || '')
		}
	}, [recordDetails])

	const createIngredient = () => {
		setLoading(true)
		IngredientService.createIngredient({
			name,
			description,
			unit,
			price_per_unit: pricePerUnit,
		})
			.then(() => {
				identifyVenue(short_code)
				trackEvent('Ingredient', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'ingredient',
				})
				NotificationManager.success(
					'Ingredient has been created successfully',
					'Success',
					3000
				)
				setLoading(false)
				setShowModal(false)
				setRecordDetails(undefined)
				getAllIngredients()
			})
			.catch((error) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Ingredient', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'ingredient',
				})
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
			})
	}

	const updateIngredient = (id) => {
		setLoading(true)
		IngredientService.updateIngredient({
			name,
			description,
			unit,
			price_per_unit: pricePerUnit,
			id,
		})
			.then(() => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Ingredient', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'ingredient',
				})
				NotificationManager.success(
					'Ingredient has been updated successfully',
					'Success',
					3000
				)
				setShowModal(false)
				setRecordDetails(undefined)
				getAllIngredients()
			})
			.catch((error) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Ingredient', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'ingredient',
				})
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
			})
	}

	const onClick = () => {
		if (recordDetails) {
			updateIngredient(recordDetails.id)
		} else {
			createIngredient()
		}
	}

	return (
		<Modal
			open={showModal}
			onClose={onClose}
			className="category-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none">
				<CustomIconButton
					icon={saleIcon}
					title={
						recordDetails
							? ingredientModalText('edit_ingredient')
							: ingredientModalText('new_ingredient')
					}
				/>
				<div className="grid grid-cols-2 gap-5 pt-0 p-024 mb-4">
					<TextField
						required
						size="small"
						value={name}
						placeholder={ingredientModalText('enter_name')}
						onChange={(e) => setName(e.target.value)}
						className="mb-4"
					/>

					<TextField
						required
						size="small"
						value={description}
						placeholder={ingredientModalText('enter_description')}
						onChange={(e) => setDescription(e.target.value)}
						className="mb-4"
					/>

					<FormControl fullWidth size="small">
						<InputLabel>{ingredientModalText('select_unit')}</InputLabel>
						<Select
							value={unit}
							onChange={(e) => setUnit(e.target.value)}
							label={ingredientModalText('select_unit')}
						>
							{unitOptions.map((item) => (
								<MenuItem key={item.value} value={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<TextField
						size="small"
						value={pricePerUnit}
						placeholder={ingredientModalText('enter_price_per_unit')}
						onChange={(e) => setPricePerUnit(e.target.value)}
					/>
				</div>
				<div className="flex flex-row justify-between space-x-4 h-[48px] px-024 mb-4">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{ingredientModalText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onClick}
					>
						{loading ? (
							<CircularProgress size={16} />
						) : (
							ingredientModalText('save')
						)}
					</button>
				</div>
			</Box>
		</Modal>
	)
}
