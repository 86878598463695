import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import "react-time-picker/dist/TimePicker.css";
import { boxStyle } from "../../constants/types";
import StaffService from "../../services/staffService";
import CustomIconButton from '../common/IconButton';
import CustomDateTimePicker from '../custom/CustomDateTimePicker';

import { t } from 'i18next'
import customerIcon from "../../assets/svgs/customer_white.svg";

export const staffReportModalText = (text) =>
	t(`components.employee.StaffReportModal.${text}`)

export default function StaffReportModal({ showModal, onClose }) {
	const [visible, setVisible] = useState(false)
	const [start, setStart] = useState(new Date())
	const [end, setEnd] = useState(new Date())

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		let payload = {
			start_date: moment(start).format('YYYY-MM-DD'),
			end_date: moment(end).format('YYYY-MM-DD'),
		}

		StaffService.GenerateReports(payload)
			.then((res) => {
				NotificationManager.success('Success', '')
				onClose(false)
			})
			.catch(() => {
				NotificationManager.error('Failed', '')
				throw new Error('Problem on generating staff report')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<CustomIconButton
						icon={customerIcon}
						title={staffReportModalText('generate_staff_report')}
					/>
					<div className="flex flex-col px-6 mb-4">
						<div className="mt-2">
							<label className="!mt-6 !mb-2 text-base !text-black1 font-medium">
								{staffReportModalText('start_date')}
							</label>
							<CustomDateTimePicker
								onlyDate={true}
								value={start}
								onChange={setStart}
							/>
						</div>
						<div className="mt-6">
							<label className="!mt-6 !mb-2 text-base !text-black1 font-medium">
								{staffReportModalText('end_date')}
							</label>
							<CustomDateTimePicker
								onlyDate={true}
								value={end}
								onChange={setEnd}
							/>
						</div>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{staffReportModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{staffReportModalText('confirm')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
