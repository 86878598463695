import React from 'react'

import moneyIcon from '../../../assets/svgs/money.svg'
import { ordersText } from '../../orders/retail/orderDetails/OrderSummaryBlock'



export default function OrderSummary({ data }) {
	return (
		<div className="flex flex-col mt-4 w-[35%]">
			<p className="mb-4 text-black1 text-lg font-medium leading-10">
				{ordersText('order_summary')}
			</p>
			<div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
				<div className="p-4 bg-[#F9F7F7] rounded-lg">
					<img src={moneyIcon} alt="desk" />
				</div>
				<div className="flex-1 flex flex-col gap-5 flex-1">
					<div className="flex flex-row justify-between items-center">
						<span className="text-gray5 text-base font-normal">
							{ordersText('subtotal')}
						</span>
						<span className="text-black1 text-base">
							${data?.order?.subtotal ?? 0}
						</span>
					</div>
					<div className="flex flex-row justify-between items-center">
						<span className="text-gray5 text-base font-normal">
							{ordersText('discount')}
						</span>
						<span className="text-black1 text-base">
							-${data?.order?.discount_total ?? 0}
						</span>
					</div>
					<div className="flex flex-row justify-between items-center">
						<span className="text-black1 text-base font-bold">
							{ordersText('total')}
						</span>
						<div className="flex flex-row gap-2">
							{data?.promotion ? (
								<span className="text-gray5 text-base">
									<del>$20.00</del>
								</span>
							) : null}
							<span className="text-black1 text-base font-bold">
								${data?.order?.total_amount ?? 0}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
