import React, { useState } from 'react'

import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import promotionIcon from '../../../assets/svgs/promotionlist.svg'
import reservationIcon from '../../../assets/svgs/reservationlist.svg'
import waitIcon from '../../../assets/svgs/waitlist.svg'
import CustomSwitch from '../../../components/custom/CustomSwitch'
import GuestService from '../../../services/guestService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export const guestCommunicationPreferencesText = (text) =>
	t(`guests.guestDetails.CommunicationPreferences.${text}`)

export const CommunicationPreferences = ({ data, onRefresh }) => {
	const { short_code } = useSelector((state) => state.app)

	const [smsSwitch, setSMSSwitch] = useState(false)
	const [emailSwitch, setEmailSwitch] = useState(false)
	const [phoneSwitch, setPhoneSwitch] = useState(false)

	useEffect(() => {
		setSMSSwitch(data?.accept_sms == 1 ? true : false)
		setEmailSwitch(data?.accept_email == 1 ? true : false)
		setPhoneSwitch(data?.accept_phone == 1 ? true : false)
	}, [])

	const handleSMSSwitch = (value) => {
		setSMSSwitch(value)
		handleSaveEmailSettings(value, emailSwitch, phoneSwitch)
	}

	const handleEmailSwitch = (value) => {
		setEmailSwitch(value)
		handleSaveEmailSettings(smsSwitch, value, phoneSwitch)
	}

	const handlePhoneSwitch = (value) => {
		setPhoneSwitch(value)
		handleSaveEmailSettings(smsSwitch, emailSwitch, value)
	}

	const handleSaveEmailSettings = (sms, email, phone) => {
		const payload = {
			id: data?.id,
			accept_sms: sms === true ? 1 : 0,
			accept_email: email === true ? 1 : 0,
			accept_promotion_email: phone === true ? 1 : 0,
		}
		GuestService.storeAccountsEmailPreferences(payload)
			.then(() => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'customers',
				})
				onRefresh()
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'customers',
				})
			})
	}
	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-l font-medium">
							{guestCommunicationPreferencesText('communicationsPreferences')}
						</span>
					</div>
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<img src={waitIcon} alt="list" />
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{guestCommunicationPreferencesText('sms')}
									</span>
								</div>
								<span className="text-gray5 text-sm">
									{guestCommunicationPreferencesText('smsNotificationDescription')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							{smsSwitch ? (
								<span className="text-sm font-medium text-[#027A48]">
									{guestCommunicationPreferencesText('on')}
								</span>
							) : (
								<span className="text-sm font-medium text-gray5">
									{guestCommunicationPreferencesText('off')}
								</span>
							)}
							<CustomSwitch active={smsSwitch} onClick={handleSMSSwitch} />
						</div>
					</div>
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<img src={reservationIcon} alt="list" />
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{guestCommunicationPreferencesText('email')}
									</span>
								</div>
								<span className="text-gray5 text-sm">
									{guestCommunicationPreferencesText('emailNotificationDescription')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							{emailSwitch ? (
								<span className="text-sm font-medium text-[#027A48]">
									{guestCommunicationPreferencesText('on')}
								</span>
							) : (
								<span className="text-sm font-medium text-gray5">
									{guestCommunicationPreferencesText('off')}
								</span>
							)}
							<CustomSwitch active={emailSwitch} onClick={handleEmailSwitch} />
						</div>
					</div>
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<img src={promotionIcon} alt="list" />
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{guestCommunicationPreferencesText('phoneCall')}
									</span>
								</div>
								<span className="text-gray5 text-sm">
									{guestCommunicationPreferencesText('phoneCallNotificationDescription')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							{phoneSwitch ? (
								<span className="text-sm font-medium text-[#027A48]">
									{guestCommunicationPreferencesText('on')}
								</span>
							) : (
								<span className="text-sm font-medium text-gray5">
									{guestCommunicationPreferencesText('off')}
								</span>
							)}
							<CustomSwitch active={phoneSwitch} onClick={handlePhoneSwitch} />
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}
