import { CREATE_PAYMENT_INTENT, TERMINAL_CONNECTION } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const createConnection = async () => {
	let api = {
		content_type: 'application/json',
		url: `${TERMINAL_CONNECTION}`,
		method: 'POST',
	}
	return apiFactory(api)
}

const createPaymentIntent = async (body) => {
	let api = {
		content_type: 'application/json',
		url: `${CREATE_PAYMENT_INTENT}`,
		method: 'POST',
	}
	return apiFactory(api, body)
}
export default {
	createConnection,
	createPaymentIntent,
}
