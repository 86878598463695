import React, { useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Cookies from 'universal-cookie';
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker';
import FoodDeliveryAnalyticDashboard from '../../../components/dashboard/food-delivery/FoodDeliveryAnalyticDashboard';
import RetailAnalyticDashboard from '../../../components/dashboard/retail/analytics/RetailAnalyticDashboard';
import { isRetailVenue } from '../../../helpers/venueHelpers';
import { KEYS } from '../../../utils/storage';
import AveragePartySize from './average-party-size';
import AverageTableOccupancy from './average-table-occupancy';
import GuestAttendanceRate from './guest-attendance';
import NoShowCancellationRates from './no-show-cancellations';
import PeakHoursVisualization from './peak-hours-vizualizations';
import ReservationTrendsOverTime from './reservation-trends-over-time';
import RevPASH from './revenue-per-available-seat-hour';
import TableUtilizationRate from './table-utilization-rate';

const DashboardAnalytics = () => {
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)

	const [start, setStart] = useState()

	if (isRetailVenue(venueType)) {
		return <RetailAnalyticDashboard />
	}

	if (venueType === 'restaurant' && !cookieBasicInfo.allow_reservation_from) {
		return <FoodDeliveryAnalyticDashboard />
	}

	return (
		<div className="flex flex-col h-full overflow-y-auto p-6">
			<div className='absolute bg-[#F9F7F7] z-[9999] top-2 right-6'>
				<CustomDateTimePicker
					onlyDate={true}
					value={start}
					onChange={setStart}
				/>
			</div>

			<div className='mt-12 grid md:grid-cols-2 grid-cols-1 flex-col gap-3 md:gap-6'>
				<ReservationTrendsOverTime />
				<TableUtilizationRate />
				<AveragePartySize />
				<PeakHoursVisualization />
				<RevPASH />
				<NoShowCancellationRates />
				<AverageTableOccupancy />
				<GuestAttendanceRate />
			</div>
		</div>
	)
}

export default DashboardAnalytics
