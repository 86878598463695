import apiFactory from "../utils/apiFactory";
import { API_WEB_PROFILE } from "../constants/API";

export default class WebProfileService {
    static UpdateWebProfile = async (payload) => {
		let api = {
			content_type: "application/json",
			url: `${API_WEB_PROFILE + '/update'}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};

    static UpdateGymWebProfile = async (payload) => {
		let api = {
			content_type: "multipart/form-data",
			url: `${API_WEB_PROFILE + '/gym'}`,
			method: "POST",
		};
		return apiFactory(api, payload);
	};

    static UpdateRestaurantWebProfile = async (payload) => {
		let api = {
			content_type: "multipart/form-data",
			url: `${API_WEB_PROFILE + '/restaurant'}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};
    static UpdateGolfWebProfile = async (payload) => {
		let api = {
			content_type: "multipart/form-data",
			url: `${API_WEB_PROFILE + '/golf'}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};

    static UpdateEventsHalltWebProfile = async (payload) => {
		let api = {
			content_type: "multipart/form-data",
			url: `${API_WEB_PROFILE + '/events-hall'}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};
}

// export const API_UPSTATE = 'web-profile/upstate';
// export const API_GYM = 'web-profile/hotels/gym';
// export const API_RESTAURANTS = 'web-profile/hotels/restaurant';
// export const API_EVENT_HALL = 'web-profile/hotels/events-hall';