import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useRef, useState } from 'react'
import { isEmpty } from '../../../utils/common'
import './index.css'

const CustomSelect = ({
	values,
	value,
	up = false,
	disabled = false,
	isMultiple = false,
	height = 'h-10',
	className,
	placeholder,
	style,
	renderRow,
	renderValue,
	handleChange,
	onRemoveItem,
}) => {
	const [isOpen, setOpen] = useState(false)
	const ref = useRef(null)

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [])

	const itemList = () => {
		if (values == null) return null
		const list = values.map((item, index) => {
			return (
				<div
					onClick={() => {
						handleChange(item, index)
					}}
					className="text-dropdown-item flex_between"
					key={index}
				>
					{renderRow != null ? renderRow(item, index) : <p>{item}</p>}
				</div>
			)
		})

		return (
			<div
				className={`text-dropdown-items ${
					up && '!absolute !w-full !bottom-10'
				}`}
			>
				{' '}
				{list}{' '}
			</div>
		)
	}

	return (
		<div
			ref={ref}
			className={`text-dropdown ${isOpen ? 'active' : ''} ${
				isMultiple ? 'multi' : 'single'
			} ${className} ${isOpen && '!z-50'} ${disabled && 'opacity-50'}`}
			onClick={() => {
				if (!disabled) setOpen(!isOpen)
			}}
			style={style}
		>
			<div className={`text-drop-btn ${height}`}>
				{((isMultiple !== true && isEmpty(value)) ||
					(isMultiple === true && (value == null || value.length == 0))) &&
				!isEmpty(placeholder) ? (
					<span className={'placeholder'}>{value ? value : placeholder}</span>
				) : isMultiple !== true ? (
					renderValue != null ? (
						renderValue(value)
					) : (
						<span>{value}</span>
					)
				) : (
					<div
						className="align-row-start w100 flex_wrap"
						style={{ marginTop: '5px' }}
					>
						{value.map((v, index) => (
							<div key={index} className="align-middle tag">
								{renderValue != null ? renderValue(v) : <span>{v}</span>}
								<span className="close-btn" onClick={() => onRemoveItem(v)}>
									<CloseIcon fontSize="small" />
								</span>
							</div>
						))}
					</div>
				)}
			</div>
			{itemList()}
		</div>
	)
}

export default CustomSelect
