import React, { useEffect, useState } from 'react'

import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import CustomFormInput from '../../../components/custom/CustomInput/custom-form-input'
import GenericModal from '../../../components/generic-modal'
import { boxStyle } from '../../../constants/types'
import GuestService from '../../../services/guestService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export const addEditGuestText = (text) => t(`guests.guests.${text}`)

export default function AddEditGuestModal({
	selectedGuest,
	onClose,
	open,
	getGuests,
}) {
	const { short_code } = useSelector((state) => state.app)

	const initialValue = {
		name: '',
		email: '',
		phone: '',
	}

	const [guest, setGuest] = useState(initialValue)

	const { email, name, phone } = guest

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (selectedGuest?.id != null) {
			setGuest(selectedGuest)
		}
	}, [open, selectedGuest])

	const onSubmit = () => {
		if (!name || !email || !phone) {
			return
		}
		setLoading(true)

		const payload = {
			name,
			email,
			phone,
		}

		GuestService.createGuest(payload)
			.then(() => {
				setLoading(false)
				getGuests()
				setGuest(initialValue)
				identifyVenue(short_code)
				trackEvent('create guest', {
					action_category: 'post',
					action_outcome: 'success',
					interaction_element: 'guests',
				})
				NotificationManager.success(
					'Guest has been added successfully',
					'Success',
					3000
				)
				onClose()
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(
					err?.errors?.email?.[0] ??
						'An error occurred while adding the guest.',
					'Error',
					3000
				)

				identifyVenue(short_code)
				trackEvent('create guest', {
					action_category: 'post',
					action_outcome: 'error',
					interaction_element: 'guests',
				})
			})
	}

	const children = (
		<>
			<CustomFormInput
				value={name}
				onChange={(e) => setGuest({ ...guest, name: e.target.value })}
				placeholder={addEditGuestText('name')}
				required={true}
			/>
			<CustomFormInput
				value={email}
				onChange={(e) => setGuest({ ...guest, email: e.target.value })}
				placeholder={addEditGuestText('email')}
				required={true}
			/>

			<CustomFormInput
				value={phone}
				onChange={(e) => setGuest({ ...guest, phone: e.target.value })}
				placeholder={addEditGuestText('phone')}
				required={true}
			/>
		</>
	)

	return (
		<GenericModal
			{...{ children, onClose, onSubmit, open, loading }}
			title={
				selectedGuest?.id != null
					? addEditGuestText('edit_guest')
					: addEditGuestText('add_guest')
			}
			sx={{ ...boxStyle, width: 600 }}
		/>
	)
}
