import React, { Component } from "react";
import FamilyTree from "@balkangraph/familytree.js";

export default class Chart extends Component {
	constructor(props) {
		super(props);
		this.divRef = React.createRef();
	}

	shouldComponentUpdate() {
		return false;
	}

	componentDidMount() {
		let myTemplate = Object.assign({}, FamilyTree.templates.hugo, { enableSearch: false });
		myTemplate.size = [330, 220];
		myTemplate.node =
			'<rect x="0" y="0" height="{h}" width="{w}" stroke-width="1" fill="#fff"  stroke="#240b3b" rx="12" ry="12"></rect>';
		myTemplate.defs = "";
		myTemplate.field_name = '<text data-width="210" data-text-overflow="ellipsis" style="font-size: 16px; font-weight: 500;" fill="#240b3b" x="115" y="130" text-anchor="left">{val}</text>';
		myTemplate.field_owner = '<text data-width="210" data-text-overflow="ellipsis" style="font-size: 16px; font-weight: 500;" fill="#240b3b" x="110" y="175" text-anchor="left">{val}</text>';
		myTemplate.field_role = '<text data-width="210" data-text-overflow="ellipsis" style="font-size: 16px; font-weight: 500;" fill="#240b3b" x="170" y="175" text-anchor="left">{val}</text>';
		// myTemplate.field_salary = '<text data-width="210" data-text-overflow="ellipsis"  style="font-size: 22px;" fill="#88ffff" x="120" y="95" text-anchor="left">{val}</text>';
		myTemplate.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="3px" fill="none" d="{rounded}" />';
		// myTemplate.link = '<path stroke="#686868" stroke-width="3px" fill="none" data-l-id="[{id}][{child-id}]" d="M{xa},{ya} C{xb},{yb} {xc},{yc} {xd},{yd}" />';
		myTemplate.img_0 =
			'<circle cx="168" cy="63" r="33" fill="#D9D9D9"></circle>'
			// '<clipPath id="ulaImg">' +
			// '<circle cx="168" cy="63" r="33" fill="#D9D9D9"></circle>' +
			// "</clipPath>" +
			// '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="" x="135" y="30" width="66" height="66">' +
			// "</image>";

		FamilyTree.templates.myTemplate = myTemplate;
		this.family = new FamilyTree(this.divRef.current, {
			nodes: this.props.nodes,
			nodeBinding: {
				field_name: "name",
				field_role: "role",
				field_owner: "owner",
				field_salary: "salary",
				img_0: "img",
			},
			template: "myTemplate",
		});
	}

	render() {
		return <div id="tree" ref={this.divRef}></div>;
	}
}
