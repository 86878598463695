import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { campaignsText } from '.'
import Svg_close from '../../../assets/svgs/close_sm.svg'
import discountIcon from '../../../assets/svgs/discount.svg'
import typeIcon from '../../../assets/svgs/type.svg'
import { boxStyle } from '../../../constants/types'

export default function CampaignViewModal({ showModal, data, onClose }) {
	const [visible, setVisible] = useState(false)
	const [state, setState] = useState({
		available: 1,
	})

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				// getPromotionDetails(data?.id)
				setState(data)
			}
		}
		setVisible(showModal)
	}, [showModal])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none p-8">
					<div
						className={`flex flex-col border-b border-0 border-gray-400 mb-5 pb-7`}
					>
						<div className="flex flex-row justify-between">
							<div>
								<label className="text-xl font-medium text-black1 mr-5">
									{campaignsText('campaign_details')}
								</label>
							</div>
							<div
								className="bg-gray2 p-2 rounded-full cursor-pointer"
								onClick={onClose}
							>
								<img src={Svg_close} alt={campaignsText('close')} />
							</div>
						</div>
						<div className="grid grid-cols-2">
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={typeIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('title')}
									</span>
									<span className="text-gray-500 text-sm">{data?.title}</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('promotion')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.promotion ? `ID#${data.promotion.id}` : '-'}
									</span>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-2">
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('description')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.description}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('link')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.link ?? '-'}
									</span>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-2">
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('type')}
									</span>
									<span className="text-gray-500 text-sm">{data?.type}</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('target')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.target ?? 'null'}
									</span>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-2">
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('scheduled')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.scheduled_date ?? '-'}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{campaignsText('sent')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.sent === 0
											? campaignsText('no')
											: campaignsText('yes')}
									</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
