import React, { useEffect, useState } from 'react'
import DiscountIcon from '../../../assets/svgs/discount1.svg'
import { updateCart } from '../../../utils/storage'

import { isEmpty } from '../../../utils/common'
import './index.css'

const FoodCheckout = (props) => {
	const { list, setUpdateCart } = props

	const currencySymbols = {
		ALL: 'Lek',
		USD: '$',
		EUR: '€',
		// Add other currencies and their symbols here
	}

	const getCurrencySymbol = (currencyCode) => {
		return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
	}

	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		if (window.innerWidth < 768) setIsMobile(true)
		else setIsMobile(false)
	}, [window.innerWidth])
	return (
		<div
			className={
				'relative sub-container view-sales overflow-auto px-4 h-full w-full bg-[#F9F7F7]'
			}
		>
			<div className="border-b-[1px] border-[#240b3b]  flex pb-2 w-full">
				<button className="border-[1px] bg-white font-semibold border-[#393C49] bg-transparent text-[#1F1D2B] px-6 py-[14px] rounded-[8px]">
					ALL MENU
				</button>
				<button className=" font-semibold border-[#240b3b] bg-transparent text-[#240b3b] px-6 py-[14px] rounded-[8px]">
					COLD DISHES
				</button>

				<button className=" font-semibold border-[#240b3b] bg-transparent text-[#240b3b] px-6 py-[14px] rounded-[8px]">
					SOUP{' '}
				</button>
				<button className=" font-semibold border-[#240b3b] bg-transparent text-[#240b3b] px-6 py-[14px] rounded-[8px]">
					GRILL
				</button>
				<button className=" font-semibold border-[#240b3b] bg-transparent text-[#240b3b] px-6 py-[14px] rounded-[8px]">
					APPETIZER{' '}
				</button>
				<button className=" font-semibold border-[#240b3b] bg-transparent text-[#240b3b] px-6 py-[14px] rounded-[8px]">
					DESSERT
				</button>
			</div>
			<div className="flex flex-wrap gap-8 py-4 px-2">
				{list?.map((item, index) => {
					return (
						<div
							className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[192px]"
							onClick={() => {
								updateCart(item)
								setUpdateCart(true)
							}}
						>
							{isEmpty(item.image_path) ? (
								''
							) : (
								<img
									className=" rounded-[8px] w-full object-cover h-[100px] "
									src={item.image_path}
								/>
							)}

							<div className="h-[80px] bg-white items-center flex justify-between flex-col p-4 rounded-b-[8px]">
								<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
									{item?.title}
								</p>
								<p className="text-[#1F1D2B] text-14 font-bold leading-[130%] ">
									{getCurrencySymbol(item?.currency)} {item?.price}
								</p>
							</div>
						</div>
					)
				})}
			</div>
			<div className="absolute  bottom-[24px] flex flex-wrap gap-8">
				<div className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[192px]">
					<div className=" rounded-[8px] w-full object-cover h-[80px] bg-[#240b3b] align-middle">
						<img src={DiscountIcon} />
					</div>
					<div className="h-[40px] bg-white items-center flex justify-between flex-col p-4 rounded-b-[8px]">
						<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
							Discount
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FoodCheckout
