import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiftedChat } from '../messages/react-web-gifted-chat';
// import { Spin } from 'antd';
import { sendMessage } from '../../store/actions/chatbot';
import EmojiPicker from '../messages/EmojiPicker';
import { renderComposer, renderInputToolbar, renderSend } from '../messages/InputToolbar';
import { renderBubble, renderMessage, renderSystemMessage } from '../messages/MessageContainer';
import './index.css';

const Messages = (props) => {
	const dispatch = useDispatch()
	const { messages, loading } = useSelector((state) => state.chatbot)
	const [showEmoji, setShowEMoji] = useState(false)
	const [text, setText] = useState('')
	const [localMessages, setLocalMessages] = useState(messages)
	const systemMsg = {
		id: 1,
		text: '',
		createdAt: new Date(),
		created_at: new Date().getTime(),
		system: true,
	}

	React.useEffect(() => {}, [])

	const onSend = async (newMessages = []) => {
		for (let i = 0; i < newMessages.length; i++) {
			dispatch(
				sendMessage({
					...newMessages[i],
					createdAt: new Date(),
					created_at: new Date().getTime(),
				})
			)
		}
	}

	const onSelectEmoji = (emoji) => {
		setShowEMoji(false)
		setText((text) => text.concat(emoji))
	}

	const onPressEmoji = () => {
		setTimeout(() => {
			setShowEMoji(true)
		}, 100)
	}

	const onLongPressMessage = () => {}

	const onPressMsg = async (currentMessage) => {
		if (
			currentMessage &&
			currentMessage.text &&
			currentMessage.user?.id !== 'guest'
		) {
		}
	}

	const onSelectOption = (option) => {
		dispatch(
			sendMessage({
				id: new Date().getTime(),
				createdAt: new Date(),
				created_at: new Date().getTime(),
				text: option,
				user: { id: 'guest' },
			})
		)
	}

	const isCloseToTop = (event) => {
		return event.target.scrollTop === 0
	}

	const isCloseToBottom = (event) => {
		const node = event.target
		return node.scrollHeight - node.scrollTop > node.clientHeight - 20
	}
	const filterMessages = (messages) => {
		// Text to match for removal
		const removeTexts = [
			'Below are some curated articles',
			'Here are some ways to get started',
		]

		// Filter out matched objects
		return messages?.filter((msg) => {
			return !removeTexts.some((text) => msg.text?.includes(text))
		})
	}

	useEffect(() => {
		let localMessageData = localStorage.getItem('messagesArray')
		if (localMessageData) {
			setLocalMessages(JSON.parse(localMessageData))
		}
	}, [])

	useEffect(() => {
		if (messages?.length > 3 && localMessages) {
			const filteredMessage = filterMessages(messages)
			localStorage.setItem('messagesArray', JSON.stringify(filteredMessage))
			setLocalMessages(filteredMessage)
		}
	}, [messages.length])

	return (
		<div className={'messages-screen relative'}>
			<GiftedChat
				messages={localMessages.length === 0 ? [systemMsg] : localMessages}
				text={text}
				onInputTextChanged={(_text) => {
					setText(_text)
				}}
				onSend={onSend}
				user={{
					id: 'guest',
				}}
				minInputToolbarHeight={100}
				alwaysShowSend={true}
				showUserAvatar={true}
				renderUsernameOnMessage={true}
				textInputAutoFocus={false}
				renderLoading={() => (
					<div
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							paddingVertical: 12,
						}}
					>
						{/* <Spin /> */}
					</div>
				)}
				listViewProps={{
					scrollviewHeight: 370,
					ListFooterComponent: () => {
						return (
							<div className={'align-col-middle'} style={{ minHeight: '12px' }}>
								<div
									style={{
										height: 30,
										minHeight: '30px',
										backgroundColor: '#fff',
									}}
								/>
							</div>
						)
					},
					ListHeaderComponent: () => {
						return (
							<div
								style={{
									height: 30,
									minHeight: '30px',
									backgroundColor: '#fff',
								}}
							/>
						)
					},
					onScroll: (event) => {
						// eslint-disable-next-line no-empty
						if (isCloseToTop(event)) {
						}
						if (isCloseToBottom(event)) {
						}
					},
				}}
				renderInputToolbar={renderInputToolbar}
				renderSend={(props) => renderSend(props)}
				renderComposer={(props) => renderComposer(props, onPressEmoji)}
				renderMessage={renderMessage}
				renderBubble={(props) =>
					renderBubble(
						props,
						onLongPressMessage,
						onPressMsg,
						onSelectOption,
						loading
					)
				}
				// renderAvatar={null}
				alignTop={true}
				bottomOffset={0}
				renderSystemMessage={renderSystemMessage}
				loadEarlier={true}
				renderLoadEarlier={() => (
					<div
						className={'align-col-middle'}
						style={{
							minHeight: props.is_order_support === true ? '30px' : '90px',
						}}
					>
						<div
							style={{
								height: props.is_order_support === true ? 30 : 90,
								minHeight: props.is_order_support === true ? '30px' : '90px',
								backgroundColor: '#fff',
							}}
						/>
					</div>
				)}
				parsePatterns={(linkStyle) => [
					{
						pattern: /#(\w+)/,
						style: linkStyle,
						onPress: () => {},
					},
					{
						pattern: /kod: \w+/g,
						style: linkStyle,
					},
					{
						pattern: /kod \w+/g,
						style: linkStyle,
					},
				]}
			/>
			{showEmoji && <EmojiPicker onSelect={onSelectEmoji} />}
		</div>
	)
}

export default Messages;
