import { Card, CardContent, TablePagination, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import shopifyIcon from '../../assets/svgs/shopify.svg'
import uploadIcon from '../../assets/svgs/upload.svg'
import woocommerceIcon from '../../assets/svgs/woocommerce.svg'
import useDebounce from '../../components/Hooks/useDebounce'
import AreYouSureModal from '../../components/common/areYouSureModal'
import CustomMUISelect from '../../components/custom/CustomSelect/custom-select'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericTable from '../../components/generic-table/generic-table'
import InventoryAlertModal from '../../components/inventory/InventoryAlertModal'
import TableEmptyData from '../../components/table/TableEmptyData'
import { isRetailVenue } from '../../helpers/venueHelpers'
import MenuService from '../../services/menuService'
import SalesService from '../../services/salesService'
import { isEmpty } from '../../utils/common'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { KEYS } from '../../utils/storage'
import ShopifyModal from '../menu/products/shopifyModal'
import WooCommerceModal from '../menu/products/woocommerceModal'
import StoreBulkImportModal, {
	months,
	periods,
	years,
} from './store/store-bulk-import'

export default function SettingsStore() {
	const { short_code } = useSelector((state) => state.app)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const [showImportModal, setShowImportModal] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false)
	const [selectedProduct, setProductSelected] = useState({})
	const [search, setSearch] = useState('')
	const debouncedValue = useDebounce(search, 900)

	const [visibleShopify, setVisibleShopify] = useState(false)
	const [visibleWoocommerce, setVisibleWoocommerce] = useState(false)
	const [showAlertModal, setShowAlertModal] = useState(false)
	const [period, setPeriod] = useState()
	const [year, setyear] = useState()
	const [month, setMonth] = useState()
	const [sales, setSales] = useState([])

	const getAllSales = (debouncedValue, period) => {
		let query = []
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}
		if (!isEmpty(period)) {
			query.push(`period=${period}`)
		}
		if (!isEmpty(year)) {
			query.push(`year=${year}`)
		}
		if (!isEmpty(month)) {
			query.push(`start_month=${month}`)
		}
		SalesService.getSalesImported(query.join('&'))
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Sales', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'sales',
				})
				setSales(res?.sales ?? [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('Sales', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'sales',
				})
			})
	}

	useEffect(() => {
		getAllSales(debouncedValue, period)
	}, [debouncedValue, period, year, month])

	const isAllowedFromBackend = false

	const columns = [
		{ label: 'Title', field: 'product_title', align: 'left' },
		{ label: 'Article No', field: 'article_no', align: 'left' },
		{ label: 'Add. Code', field: 'additional_code', align: 'left' },
		{ label: 'Quantity', field: 'quantity_sold', align: 'right' },
		{
			label: 'Start Date',
			field: 'start_date',
			align: 'right',
		},
		{
			label: 'End Date',
			field: 'end_date',
			align: 'right'
		},
		{
			label: 'Imported At',
			field: 'created_at',
			align: 'right',
			cellComponent: (data) => {
				return (
					<div>
						{data?.data
							? moment(data?.data).format('DD-MM-YYYY HH:mm')
							: ''}
					</div>
				)
			},
		},
	]

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [debouncedValue])

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const refreshData = () => {
		let query = []
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}

		setLoaded(true)
	}

	const onDelete = (id) => {
		setProductSelected(id)
		setAreYouSureDeleteModalOpen(true)
	}

	const onEdit = useCallback((id) => {
		identifyVenue(short_code)
		// Track update Store event
		trackEvent('Update Store', {
			action_category: 'click',
			action_outcome: 'redirect',
			interaction_element: 'button',
		})
		// navigate(`/${short_code}/admin/menu/items/edit/${id}`)
		// eslint-disable-next-line
	}, [])

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">Sales Imported</span>
									<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
										{sales.length} Imports
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										gap: '16px',
										alignItems: 'flex-end',
									}}
								>
									<div className="flex flex-row items-center gap-4">
										<Tooltip title={'Import sales at once from your file!'}>
											<div
												className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer}`}
												onClick={() => setShowImportModal(true)}
											>
												<img
													src={uploadIcon}
													alt="plus"
													className="mr-2 cursor-pointer"
												/>
												<span className="cursor-pointer">Bulk import</span>
											</div>
										</Tooltip>
									</div>

									{isRetailVenue(venueType) && isAllowedFromBackend && (
										<div className="flex flex-row items-center gap-4 mr-4">
											<img
												src={shopifyIcon}
												alt="shopify"
												className="cursor-pointer w-10"
												onClick={() => setVisibleShopify(true)}
											/>
											<img
												src={woocommerceIcon}
												alt="woocommerce"
												className="cursor-pointer w-10"
												onClick={() => setVisibleWoocommerce(true)}
											/>
										</div>
									)}
									{/*<div className="flex flex-row border rounded-lg px-3">*/}
									{/*	<input*/}
									{/*		className=" w-[220px] max-w-[80vw] h-[40px] px-2 outline-none"*/}
									{/*		placeholder="Search store"*/}
									{/*		value={search}*/}
									{/*		onChange={(e) => setSearch(e.target.value)}*/}
									{/*	/>*/}
									{/*</div>*/}
									<CustomMUISelect
										data={years}
										onChange={(e) => setyear(e.target.value)}
										placeholder="Select year"
										value={year}
									/>
									<CustomMUISelect
										data={months}
										onChange={(e) => setMonth(e.target.value)}
										placeholder="Select month"
										value={month}
									/>
									<CustomMUISelect
										data={periods}
										onChange={(e) => setPeriod(e.target.value)}
										placeholder="Select period"
										value={period}
									/>
								</div>
							</div>

							{sales?.length > 0 ? (
								<div className="flex flex-col overflow-auto max-h-[70vh]">
									<GenericTable
										columns={columns}
										data={sales}
										onDelete={onDelete}
										onEdit={onEdit}
									/>

									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={[].length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content="imported sales data"
										btnLabel="Import sales data"
										onAction={() => setShowImportModal(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}

			{showImportModal && (
				<StoreBulkImportModal
					showImportModal={showImportModal}
					setShowImportModal={setShowImportModal}
					onClose={() => setShowImportModal(false)}
				/>
			)}

			{isAreYouSureDeleteModalOpen && (
				<AreYouSureModal
					title="Delete product"
					content="Are you sure you want to delete this product?"
					firstButton={'No, go back'}
					secondButton={'Yes, I am'}
					showModal={isAreYouSureDeleteModalOpen}
					onClose={() => setAreYouSureDeleteModalOpen(false)}
					onSubmit={() =>
						MenuService.delProduct(selectedProduct)
							.then(({ data }) => {
								identifyVenue(short_code)
								trackEvent('menu service', {
									action_category: 'delete',
									action_outcome: 'success',
									interaction_element: 'products',
								})
								refreshData()
								NotificationManager.success(
									data?.message ?? 'Product is deleted successfully'
								)
							})
							.catch((err) => {
								identifyVenue(short_code)
								trackEvent('menu service', {
									action_category: 'delete',
									action_outcome: 'fail',
									interaction_element: 'products',
								})
								if (typeof err?.error === 'string') {
									NotificationManager.error(err?.error)
								} else {
									const errorKeys = Object.keys(err?.error || {})
									if (errorKeys.length > 0) {
										const firstErrorKey = errorKeys[0]
										const firstErrorMessage = err?.error[firstErrorKey][0]
										NotificationManager.error(
											firstErrorMessage || 'Something went wrong!'
										)
									} else {
										NotificationManager.error('Something went wrong!')
									}
								}
							})
					}
				/>
			)}

			{visibleShopify && (
				<ShopifyModal
					showModal={visibleShopify}
					onClose={() => setVisibleShopify(false)}
				/>
			)}

			{visibleWoocommerce && (
				<WooCommerceModal
					showModal={visibleWoocommerce}
					onClose={() => setVisibleWoocommerce(false)}
				/>
			)}

			{showAlertModal && (
				<InventoryAlertModal
					title="Inventory Alert"
					showModal={showAlertModal}
					onClose={(refresh) => {
						if (refresh === true) {
							refreshData()
						}
						setShowAlertModal(false)
					}}
				/>
			)}
		</div>
	)
}
