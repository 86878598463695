import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'
import vb_svg from '../../../assets/svgs/v2-vb-table-icon.svg'
import uber_svg from '../../../assets/svgs/ubsereats_white.svg'

import { useEffect, useState } from 'react'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import TableEmptyData from '../../../components/table/TableEmptyData'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import orderService from '../../../services/orderService'
import moment from 'moment'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
// import './index.css';

const PickupOrders = () => {
	const { short_code } = useSelector((state) => state.app)
	const navigate = useNavigate()

	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [loaded, setLoaded] = useState(false)
	const [date, setDate] = useState(new Date())

	useEffect(() => {
		refreshData(date)
	}, [])

	const refreshData = (currentDate) => {
		let payload = {
			date: moment(currentDate).format('YYYY-MM-DD 23:59:59'),
		}
		orderService
			.GetAllPickupOrders(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('order service', {
					action_category: 'put',
					action_outcome: 'success',
					interaction_element: 'orders',
				})
				setLoaded(true)
				setData(res?.orders || [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('order service', {
					action_category: 'put',
					action_outcome: 'fail',
					interaction_element: 'orders',
				})
				setLoaded(true)
			})
	}
	const onChangeDate = (currentDate) => {
		setDate(currentDate)
		setLoaded(false)
		refreshData(currentDate)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onAction = (id) => {
		navigate(`/${short_code}/admin/orders/order/${id}`)
	}

	return (
		<>
			{loaded ? (
				<div
					className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
					style={{ height: 'calc(100vh - 76px - 68px)' }}
				>
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center p-4 pl-7 pr-7">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										Pickup Orders
									</span>
								</div>
								<div className="flex flex-row items-center gap-4">
									<CustomDateTimePicker
										onlyDate={true}
										classes="small"
										value={date}
										onChange={onChangeDate}
									/>
								</div>
							</div>
							{data.length ? (
								<div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
									<div
										className="flex flex-col  overflow-auto"
										style={{
											boxShadow:
												'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
										}}
									>
										<Card>
											<CardContent className="!p-0">
												<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
													<div className="flex flex-row items-center">
														<span className="text-black1 text-l font-medium mr-3">
															Pickup orders for your venue
														</span>
														<span className="text-base font-medium text-black1 px-2 bg-gray2 rounded-full">
															{data.length} orders
														</span>
													</div>
												</div>
												<TableContainer component={Paper} className="mt-4">
													<Table aria-label="simple table">
														<TableHead className="bg-gray7">
															<TableRow>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="left"
																>
																	ID#
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="left"
																>
																	Customer
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	Total
																</TableCell>
																{/*<TableCell className="!text-gray5 !text-left !font-medium !text-base" align="right">*/}
																{/*    Subotal*/}
																{/*</TableCell>*/}
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	Discount
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base whitespace-nowrap"
																	align="right"
																>
																	Source
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base whitespace-nowrap"
																	align="right"
																>
																	Date
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	Payment
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	Status
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{data.map((item, index) => (
																<TableRow
																	style={{ cursor: 'pointer' }}
																	key={index}
																	onClick={() => onAction(item.order_id)}
																>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.order_id}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.customer}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.total}
																	</TableCell>
																	{/*<TableCell align="left" className="py-3 !text-gray5 !text-left !text-base">*/}
																	{/*    {item.subtotal}*/}
																	{/*</TableCell>*/}
																	<TableCell
																		align="left"
																		className="py-3 !text-[#027A48] !text-left !text-base"
																	>
																		{item.discount ? (
																			<div className="flex justify-center items-center text-[14px] font-normal text-[#027A48]">
																				{item.discount}
																			</div>
																		) : (
																			''
																		)}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base whitespace-nowrap"
																	>
																		{item.source == 'vb' ? (
																			<div className="flex flex-row justify-start align-center cursor-pointer">
																				<img
																					src={vb_svg}
																					alt="icon"
																					className="w-[20px] h-[25px] mr-2"
																				/>
																				<p>{item.source_name}</p>
																			</div>
																		) : item.source == 'uber' ? (
																			<div className="flex flex-row justify-start align-center cursor-pointer">
																				<img
																					src={uber_svg}
																					alt="icon"
																					className="w-[20px] h-[25px] mr-2"
																				/>
																				<p>{item.source_name}</p>
																			</div>
																		) : (
																			''
																		)}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.date}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.payment_methodh}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="py-3 !text-gray5 !text-left !text-base"
																	>
																		{item.status == 'completed' ? (
																			<div className="font-semibold text-[16px] text-cyan1 bg-[#ECFDF3] border align-center rounded-[10px] cursor-pointer px-[16px]">
																				<div className="flex justify-center items-center text-[14px] font-normal text-[#19191C]">
																					<span className="mr-[8px] rounded-[6px] w-[2px] h-[2px] bg-[#12B76A] outline outline-[#12B76A]"></span>
																					<div className="flex justify-center items-center text-[14px] font-normal text-[#027A48]">
																						Completed
																					</div>
																				</div>
																			</div>
																		) : (
																			''
																		)}
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
													<TablePagination
														rowsPerPageOptions={[10, 25, 100]}
														component="div"
														count={data.length}
														rowsPerPage={rowsPerPage}
														page={page}
														className="!p-2"
														onPageChange={handleChangePage}
														onRowsPerPageChange={handleChangeRowsPerPage}
													/>
												</TableContainer>
											</CardContent>
										</Card>
									</div>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData content=" pickup orders" />
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</>
	)
}

export default PickupOrders
