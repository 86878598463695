export const currencySymbols = {
	ALL: 'Lek',
	USD: '$',
	EUR: '€',
	// Add other currencies and their symbols here
}

export const getCurrencySymbol = (currencyCode) => {
	return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
}

export const methodLabels = [
	{
		label: 'Cash',
		value: 'cash',
	},
	{
		label: 'Credit card',
		value: 'card',
	},
	{
		label: 'Other method',
		value: 'other',
	},
]

export const currencyLabels = [
	{
		label: 'ALL',
		value: 'ALL',
	},
	{
		label: 'USD',
		value: 'usd',
	},
	{
		label: 'EUR',
		value: 'eur',
	},
]
