import { Card, CardContent } from '@mui/material'
import { useEffect, useState } from 'react'

import { t } from 'i18next'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import LoadingScreen from '../../../../components/custom/LoadingScreen'
import SelectFilter from '../../../../components/dropdowns/select-filter'
import GenericPagination from '../../../../components/generic-table/generic-pagination'
import GenericTable from '../../../../components/generic-table/generic-table'
import TableEmptyData from '../../../../components/table/TableEmptyData'
import { getCurrencySymbolHelper } from '../../../../helpers/venueHelpers'
import accommodationService from '../../../../services/accommodationService'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import BookingModal from '../modal'

export const bookingListText = (text) => t(`accommodation.booking.list.${text}`)

const BookingsList = () => {
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [data, setData] = useState([])
	const [filter, setFilter] = useState('All')
	const filterOptions = ['All', 'Pending', 'Upcoming', 'Completed', 'Canceled']
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen)
	}

	const { short_code } = useSelector((state) => state.app)

	const handleFilterChange = (event) => {
		setFilter(event)
		setPage(0)
	}

	const handleOpen = (row) => {
		setSelectedRow(row)
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
		setSelectedRow(null)
	}

	const handleDataFetch = (short_code) => {
		let payload = {
			short_code,
		}
		setLoading(true)
		accommodationService
			.GetBooking(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('GetBooking', {
					action_category: 'booking',
					action_outcome: 'success',
					interaction_element: 'booking',
				})
				setLoading(false)
				setData(res?.bookings?.data)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('GetBooking', {
					action_category: 'booking',
					action_outcome: 'fail',
					interaction_element: 'booking',
				})
				setLoading(false)
				throw new Error('Problem retrieving the bookings')
			})
	}

	useEffect(() => {
		handleDataFetch(short_code)
	}, [short_code])

	const statusTransformer = {
		Pending: { bgColor: '#f8dda7', textColor: '#94660c', readable: 'Pending' },
		Upcoming: {
			bgColor: '#BCE8F1',
			textColor: '#31708F',
			readable: 'Upcoming',
		},
		Canceled: { bgColor: '#e5e5e5', textColor: '#777', readable: 'Canceled' },
		Completed: { bgColor: '#3C763D', textColor: '#fff', readable: 'Completed' },
	}



	const StatusCell = ({ data }) => (
		<div
			style={{
				backgroundColor: `${statusTransformer[data]?.bgColor}`,
			}}
			className={`font-semibold text-[16px] text-cyan1 border align-center rounded-[10px] cursor-pointer px-[16px] w-[120px]`}
		>
			<span
				className={`flex justify-center items-center text-[14px] font-bold p-2`}
				style={{
					color: statusTransformer[data]?.textColor,
				}}
			>
				{statusTransformer[data]?.readable}
			</span>
		</div>
	)

	const PaymentCell = ({ data }) => (
		<div>
			<p className={'text-cyan1'}>
				{bookingListText('prepayment')}:{' '}
				<b>
					{getCurrencySymbolHelper(data?.rental_unit?.currency) ?? '$'}{' '}
					{data.prepayment_amount}
				</b>
			</p>
			<p className={'text-cyan1'}>
				{bookingListText('remaining')}:{' '}
				<b>
					{getCurrencySymbolHelper(data?.rental_unit?.currency) ?? '$'}{' '}
					{data.total_amount}
				</b>
			</p>
			<p className={'text-cyan1'}>
				{bookingListText('total')}:{' '}
				<b>
					{getCurrencySymbolHelper(data?.rental_unit?.currency) ?? '$'}{' '}
					{data.total_amount - data.prepayment_amount}
				</b>
			</p>
		</div>
	)

	const columns = [
		{
			field: 'created_at',
			label: bookingListText('created_at'),
			align: 'right',
		},
		{ field: 'check_in', label: bookingListText('check_in'), align: 'right' },
		{
			field: 'check_out',
			label: bookingListText('check_out'),
			align: 'right',
		},
		{
			field: 'nr_guests',
			label: bookingListText('nr_guests'),
			align: 'right',
		},
		{
			field: 'status',
			label: bookingListText('status'),
			align: 'right',
			cellComponent: StatusCell,
		},
		{
			field: 'paid_with',
			label: bookingListText('paid_with'),
			align: 'right',
		},
		{
			field: 'payment',
			label: bookingListText('payment'),
			align: 'right',
			cellComponent: PaymentCell,
		},
		{
			field: 'actions',
			label: bookingListText('actions'),
			align: 'right',
		},
	]

	const transformedData = data?.map((row) => ({
		...row,
		created_at: moment(row.created_at).format('MMMM DD, YYYY'),
		check_in: row.check_in_date,
		check_out: row.check_out_date,
		nr_guests: row.guest_nr,
		status: row.status,
		paid_with: row.paid_with,
		payment: row,
		actions: row,
	}))

	const onRowsPerPageChange = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	if (loading) {
		return <LoadingScreen />
	}


	const onDecline = (rowId) => {
		handleStatusChange({ id: rowId, status: "Cancelled" });
	}

	const onConfirm = (rowId) => {
		handleStatusChange({ id: rowId, status: "Confirmed" });
	}

	const handleStatusChange = (payload) => {
		accommodationService
			.ChangeBookingStatus(payload)
			.then((res) => {
				NotificationManager.success(
					`Booking has been ${payload.status} successfully`,
					'Success',
					3000
				)
				handleDataFetch(short_code);
			})
			.catch((err) => {
				console.error(err);
				NotificationManager.error('Failed changing Booking status')
			});
	}

	// TODO: add a way to confirm booking
	return (
		<div className="flex flex-col h-full">
			<div className="flex flex-col h-full p-6">
				<Card>
					<CardContent className="!p-0">
						<div className="flex justify-between items-center px-6 mt-2">
							<div className="flex items-center">
								<span className="text-black1 text-xl pt-2 font-medium">
									{bookingListText('all_bookings')}
								</span>
							</div>

							<SelectFilter
								onChange={handleFilterChange}
								selectedItem={filter}
								toggleDropdown={toggleDropdown}
								dropdownOpen={dropdownOpen}
								items={filterOptions}
								className="!w-48"
							/>
						</div>

						{data.length > 0 ? (
							<>
								<GenericTable
									data={transformedData}
									columns={columns}
									onDetails={handleOpen}
									onConfirm={onConfirm}
									onDelete={onDecline}
								/>

								<GenericPagination
									onPageChange={(_, newPage) => setPage(newPage)}
									onRowsPerPageChange={onRowsPerPageChange}
									page={page}
									rowsPerPage={rowsPerPage}
									setPage={setPage}
									count={data.length}
								/>
							</>
						) : (
							<TableEmptyData content={bookingListText('booking')} />
						)}
					</CardContent>
				</Card>

				<BookingModal {...{ handleClose, open, selectedRow }} />
			</div>
		</div>
	)
}

export default BookingsList
