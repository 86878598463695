import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Img from '../../../assets/images/orderitem.png'
import CartIcon from '../../../assets/svgs/cart.svg'
import PlusIcon from '../../../assets/svgs/plus1.svg'
import TrashIcon from '../../../assets/svgs/trash-outline.svg'
import { deletFromCart, getFoodCart, updateCart } from '../../../utils/storage'

import './index.css'
import {isEmpty} from "../../../utils/common";

const FoodCheckoutMobile = (props) => {
	const { list } = props

	const { short_code } = useSelector((state) => state.app)

	const [updateList, setUpdateList] = useState(false)

	let navigate = useNavigate()

	const [flag, setFlag] = useState(false)

	const [cart, setCart] = useState([])

	// Iterate through the array and calculate the total cost

	let totalCost = 0

	cart?.forEach((item) => {
		totalCost += item.quantity * item.price
	})

	useEffect(() => {
		setCart(getFoodCart())

		setUpdateList(false)

		// eslint-disable-next-line
	}, [updateList])

	const deleteFromCart = (id) => {
		deletFromCart(id)

		setUpdateList(true)
	}

	const redirectToPay = () => {
		navigate(`/${short_code}/admin/pos/food/pay`)
	}

	const currencySymbols = {
		ALL: 'Lek',
		USD: '$',
		EUR: '€',
		// Add other currencies and their symbols here
	}

	const getCurrencySymbol = (currencyCode) => {
		return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
	}

	const [isOpen, setIsOpen] = useState(false)

	return isOpen ? (
		<div className="p-6 bg-[#240b3b] w-full z-[100] absolute top-0 bottom-0">
			<div className="flex flex-row justify-center items-center">
				<p
					onClick={() => setIsOpen(false)}
					className="text-white text-[30px] inline p-2"
				>
					{`<`}
				</p>
				<p className="text-white text-20 text-center inline w-full font-semibold leading-[140%]">
					New Order #34562
				</p>
			</div>
			<div className="flex gap-2 py-3">
				<button className="bg-white rounded-[5px] py-[7px] px-[12px] text-[#240b3b] text-14 font-semibold leading-[140%] w-fit">
					Dine in
				</button>
				<button className="text-white border-white border-[1px]  rounded-[5px] py-[7px] px-[12px] bg-[#240b3b] text-14 w-fit font-semibold leading-[140%]">
					To Go
				</button>
				{/*<button className="text-white border-white border-[1px]  rounded-[5px] py-[7px] px-[12px] bg-[#240b3b] text-14 w-fit font-semibold leading-[140%]">*/}
				{/*	Delivery*/}
				{/*</button>*/}
			</div>

			<div>
				<div className="border-[#D1D1D1]  py-2 ">
					<div className="flex flex-col justify-between">
						{cart?.map((item) => {
							return (
								<div className="border-y-[1px] border-[#D1D1D1] py-2">
									<div className="flex justify-between flex-row items-center">
										<div className="flex justify-start py-2  items-center">
											<div className="w-10 h-10 text-16 bg-[#F7F8F9] font-semibold align-middle rounded-[4px] mr-2">
												{item?.quantity}X
											</div>
											<div className="flex flex-col justify-between">
												<p className="text-white text-16 font-medium">
													{item?.title}
												</p>
												<p className="text-[#ABBBC2] text-14 font-medium">
													{item?.quantity} X {getCurrencySymbol(item?.currency)}
													{item?.price}
												</p>
											</div>
										</div>
										<p className="text-16 font-medium leading-[140%] text-white ">
											{getCurrencySymbol(item?.currency)}
											{item?.quantity * item?.price}
										</p>
									</div>
									<div className="flex justify-between items-center gap-4 py-2">
										<input
											placeholder="Add note"
											className="flex flex-1 text-white border-[1px] bg-transparent border-[#E4E4E4] rounded-[8px] p-[14px]"
										/>
										<button
											className=" bg-transparent border-[1px] border-[#E4E4E4] rounded-[8px] p-[14px]"
											onClick={() => {
												deleteFromCart(item?.id)
											}}
										>
											<img src={TrashIcon} />
										</button>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="w-full absolute bottom-6 px-6 right-0">
					<div className="py-2 border-t-[1px] border-[#D1D1D1]">
						<div className="flex justify-between py-4">
							<p className="text-[#E5EAFF] text-14 font-normal ">Discount</p>
							<p className="text-white text-14 font-medium leading-[130%]">
								$0
							</p>
						</div>
						<div className="flex justify-between py-2">
							<p className="text-[#E5EAFF] text-14 font-normal ">Sub total</p>
							<p className="text-white text-14 font-medium leading-[130%]">
								{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
								{totalCost}
							</p>
						</div>
					</div>
					<button
						className={`bg-white text-[#1F1D2B] w-full flex-1  rounded-[8px] p-[14px]  text-center     text-14 font-semibold leading-[140%] `}
						onClick={() => {
							redirectToPay()
						}}
					>
						Charge {getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
						{totalCost}
					</button>
				</div>
			</div>
		</div>
	) : (
		<div
			className={
				'relative sub-container view-sales overflow-auto px-4 h-full w-full bg-[#F9F7F7]'
			}
		>
			<div className="flex flex-wrap gap-8 py-2">
				{list?.map((item) => {
					return (
						<div
							className="rounded-[8px] border-[1px] border-[#E4E4E4] w-[152px]"
							onClick={() => {
								updateCart(item)
								setUpdateList(true)
							}}
						>

							{isEmpty(item.image_path) ?
								''
								:
								<img
									className=" rounded-[8px] w-full object-cover h-[77px] "
									src={item.image_path}
								/>
							}
							<div className=" relative h-[80px] bg-white items-start flex justify-between flex-col p-4 rounded-b-[8px]">
								<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
									{item?.title}
								</p>
								<p className="text-[#1F1D2B] text-14 font-semibold leading-[130%] ">
									{getCurrencySymbol(item?.currency)} {item?.price}
								</p>
								<img src={PlusIcon} className="absolute bottom-2 right-2" />
							</div>
						</div>
					)
				})}
			</div>

			<div className="h-15 w-full absolute bottom-[10px] right-0 px-6">
				<div
					className="flex justify-between p-5 rounded-[5px]   bg-[#240b3b]"
					onClick={() => setIsOpen(true)}
				>
					<div className="flex">
						<img src={CartIcon} className="mr-2" />
						<p className="text-white text-16 font-medium ">
							{' '}
							{cart?.length} Items
						</p>
					</div>
					<p className="text-white text-16 font-medium ">
						Total: {getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
						{totalCost}
					</p>
				</div>
			</div>
		</div>
	)
}

export default FoodCheckoutMobile
