import React from 'react';

import { FormControlLabel, Switch, styled } from '@mui/material';
import './index.css';

const ActionSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#027A48',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
        opacity: 1,
        border: '1px solid #027A48',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#027A48'
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#667085',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 1 : 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    backgroundColor: '#667085'
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'transparent' : 'transparent',
    opacity: 1,
    border: '1px solid #667085',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CustomSwitch = (props) => {
  const { active = false } = props;
  const handleChange = (e) => {
    props.onClick(e.target.checked);
   
};
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <FormControlLabel
        control={<ActionSwitch sx={{ m: 1 }} checked={active}  onClick={props.onClick ? handleChange : null} />}
        label=""
       
        
      />
    </div>
  )
}

export default CustomSwitch;
