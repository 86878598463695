import React from "react";

const KDSOrdersReady = () => {
  return (
    // <div className={"sub-container view-sales overflow-auto py-8 px-4 h-full"}>
    // </div>
    <div className="w-full h-[100vh] bg-white grid grid-cols-2 gap-9">
      <div className="w-full h-full bg-[#F9F7F7]">
        <div className="bg-[#D1F3FA] align-middle py-2">
          <p className="text-[34px] text-[#044E78] font-semibold tracking-widest">
            PREPARING
          </p>
        </div>
        <div className="p-5 grid grid-cols-2 gap-5 ">
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#044E78] text-[#044E78] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1F3FA]">
                120
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#044E78] text-[#044E78] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1F3FA]">
                121
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#044E78] text-[#044E78] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1F3FA]">
                122
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#044E78] text-[#044E78] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1F3FA]">
                123
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#044E78] text-[#044E78] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1F3FA]">
                124
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#044E78] text-[#044E78] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1F3FA]">
                125
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full  bg-[#F9F7F7]">
        <div className="bg-[#D1FADF] align-middle py-2">
          <p className="text-[34px] text-[#047857] font-semibold tracking-widest">
            READY
          </p>
        </div>
        <div className="p-5 grid grid-cols-2 gap-5">
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#047857] text-[#047857] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1FADF]">
                114
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#047857] text-[#047857] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1FADF]">
                115
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#047857] text-[#047857] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1FADF]">
                116
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#047857] text-[#047857] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1FADF]">
                117
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden border-[#E4E4E4] border-[1px]">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#047857] text-[#047857] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1FADF]">
                118
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
          <div className="rounded-[10px] overflow-hidden  border-[#E4E4E4] border-[1px] ">
            <div className="bg-[#240b3b] text-white text-[20px] font-semibold leading-[150%]  align-middle py-2">
              Sandy Rivera
            </div>
            <div className="bg-white py-2 align-middle flex flex-col">
              <div className="border-[#047857] text-[#047857] tracking-widest border-[1px] rounded-[13px] font-extrabold text-[22px] py-1 px-8 bg-[#D1FADF]">
                119
              </div>
              <div className="text-[#240b3b] text-20 font-semibold text-center mt-1">
                Order #320300
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KDSOrdersReady;
