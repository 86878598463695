import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import GuestService from '../../services/guestService'
import WaitlistService from '../../services/waitlistService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import CustomIconButton from '../common/IconButton'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import calendarIcon from '../../assets/svgs/calendar_white.svg'

const getTranslation = (text) =>
	t(`components.waitlist.guestHistoryModal.${text}`)

export default function GuestHistoryModal({ showModal, onClose }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)

	const [guest, setGuest] = useState(0)
	const [guestOptions, setGuestOptions] = useState([])
	const [data, setData] = useState({})

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setGuest(0)

			GuestService.GetGuestsWithoutTable().then((res) => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'guest options',
				})
				// GuestService.GetGuestsWithoutTable().then(res => {
				setGuestOptions(
					res.data.map((item) => {
						return {
							value: item.id,
							label: item.name,
						}
					})
				)
			})
		}
		setVisible(showModal)
	}, [showModal])

	const handleGetHistory = () => {
		if (guest) {
			WaitlistService.GetGuestHistory(guest)
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('guest service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'guest history',
					})
					NotificationManager.success('Success')
					setData(res)
				})
				.catch((e) => {
					identifyVenue(short_code)
					trackEvent('guest service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'guest history',
					})
					NotificationManager.error('Failed')
				})
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 600 }} className="outline-none">
					<CustomIconButton
						icon={calendarIcon}
						title={getTranslation('guestHistoryTitle')}
					/>
					<div className="flex flex-col px-6 mb-4">
						<div className="flex flex-row gap-2 mb-2">
							<RInputDeclare
								value={getTranslation('guestLabel')}
								className="text-base !text-black1 font-medium !mb-0"
							/>
							<RInputDeclare
								value={getTranslation('requiredLabel')}
								className="text-base !mb-0"
							/>
						</div>
						<TextField
							id="outlined-select"
							error={checkValid && !guest}
							select
							size="small"
							value={guest}
							onChange={(event) => {
								setGuest(event.target.value)
							}}
						>
							{guestOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<span
							className="py-2 w-full rounded-lg bg-black1 text-white text-lg text-center font-bold mt-4"
							onClick={handleGetHistory}
						>
							{getTranslation('getGuestHistory')}
						</span>

						<div className="flex flex-col my-4">
							<div className="flex flex-row w-full border-b py-5 px-4">
								<span className="text-gray5 w-1/2 text-base">
									{getTranslation('waitlistCount')}
								</span>
								<span className="text-gray5 w-1/2 text-base">100</span>
							</div>
							<div className="flex flex-row w-full border-b py-5 px-4">
								<span className="text-gray5 w-1/2 text-base">
									{getTranslation('seatedCount')}
								</span>
								<span className="text-gray5 w-1/2 text-base">100</span>
							</div>
							<div className="flex flex-row w-full border-b py-5 px-4">
								<span className="text-gray5 w-1/2 text-base">
									{getTranslation('totalWaitTime')}
								</span>
								<span className="text-gray5 w-1/2 text-base">100</span>
							</div>
							<div className="flex flex-row w-full border-b py-5 px-4">
								<span className="text-gray5 w-1/2 text-base">
									{getTranslation('averageWaitTime')}
								</span>
								<span className="text-gray5 w-1/2 text-base">100</span>
							</div>
						</div>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-0 m-6">
						<button
							className="border rounded-lg bg-white text-black1 text-lg font-bold grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('close')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
