import { Card, CardContent } from '@mui/material'
import React from 'react'
import { averagePartyBlock } from '../../../sales/data'
// import { revenueIcon } from '../../../sales/data'

const AveragePartySize = () => {
	return (
		<Card className="md:col-span-2">
			<CardContent className="flex flex-col items-start h-full !p-0">
				<div className="flex justify-between items-center pl-6 pr-3 py-4">
					<div className="flex items-center">
						<span className="text-xl font-medium">Average Party Size</span>
					</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 w-full">
					{averagePartyBlock().map((item, index) => (
						<div className="revenue-block" key={index}>
							<div className="flex items-center gap-2">
								<span>{item.icon}</span>
								<span className="title">{item.title}</span>
							</div>
							<span className="value">{item.value}</span>
						</div>
					))}
				</div>
			</CardContent>
		</Card>
	)
}

export default AveragePartySize
