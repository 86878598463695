import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import {
	Card,
	CardContent,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from '@mui/material'
import { useState } from 'react'
import Chart from 'react-apexcharts'

import { t } from 'i18next'
import { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import DashboardService from '../../../services/dashboardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export const foodDeliveryText = (text) =>
	t(`components.dashboard.foodDelivery.${text}`)

const listAnalyticType = ['sales', 'coupons', 'discounts']
const FoodDeliveryAnalyticDashboard = () => {
	const { short_code } = useSelector((state) => state.app)

	const [analyticsData, setAnalyticsData] = useState({
		series: [{ name: '', data: [] }],
		xaxis: { categories: [] },
	})
	const [type, setType] = useState('sales')
	const [revenueData, setRevenueData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const analyticsOptions = {
		stroke: {
			show: true,
			colors: ['#543c7f'],
		},
		chart: {
			id: 'apexchart-01',
		},
		xaxis: {
			categories: [],
		},
		yaxis: {
			min: 0,
			labels: {
				formatter: (val) =>
					`${analyticsData?.currency ?? ''} ${val.toFixed(0)}`,
			},
		},
		fill: {
			colors: ['#240b3b'],
		},
		dataLabels: {
			style: {
				colors: ['#00000000'],
			},
		},
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		getAnalyticsTypeData(type)
		getRevenueData()
	}, [])

	const getAnalyticsTypeData = (analyticType) => {
		let payload = {
			type: analyticType,
		}

		DashboardService.GetRevenue(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'revenue',
				})
				setAnalyticsData(res)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'revenue',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const getRevenueData = () => {
		let payload = {
			type: 'detailed',
		}
		DashboardService.GetRevenue(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'revenue',
				})
				setRevenueData(res)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'revenue',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}
	return (
		<div
			className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 py-5">
						<span className="text-black1 text-xl font-medium">
							{foodDeliveryText('revenue')}
						</span>
					</div>
					<div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-col grow bg-white rounded-12 px-0">
									<div className="flex flex-row justify-between items-baseline w-full border-b pl-6 shadow-sm py-6">
										<TextField
											id="outlined-select"
											size="small"
											select
											className="w-min-[150px]"
											value={type}
											onChange={(event) => {
												setType(event.target.value)
												getAnalyticsTypeData(event.target.value)
											}}
										>
											{listAnalyticType.map((option) => (
												<MenuItem key={option} value={option}>
													{option}
												</MenuItem>
											))}
										</TextField>
									</div>
									<div className="px-6">
										<Chart
											className="mt-4"
											options={{
												...analyticsOptions,
												xaxis: { categories: analyticsData?.xaxis?.categories },
											}}
											series={[
												{
													name: analyticsData?.series?.[0]?.name,
													data: analyticsData?.series?.[0]?.data,
												},
											]}
											type="line"
											width={'100%'}
											height={322}
										/>
									</div>
								</div>
							</CardContent>
						</Card>

						<Card className="mt-4">
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{foodDeliveryText('revenue')}
										</span>
									</div>
								</div>
								<div className="flex flex-col overflow-auto">
									<TableContainer component={Paper} className="mt-4">
										<Table aria-label="simple table">
											<TableHead className="bg-gray7">
												<TableRow>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{foodDeliveryText('date')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{foodDeliveryText('orders')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{foodDeliveryText('sales')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{foodDeliveryText('coupons_used')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{foodDeliveryText('discounts_used')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{revenueData.map((item, index) => (
													<TableRow key={index}>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.date}
														</TableCell>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.order_count}
														</TableCell>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.currency} {item.sales}
														</TableCell>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.coupons_used}
														</TableCell>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.discounts_used}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											rowsPerPage={rowsPerPage}
											page={page}
											className="!p-2"
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</TableContainer>
								</div>
							</CardContent>
						</Card>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default FoodDeliveryAnalyticDashboard
