import { RemoveRedEyeOutlined } from '@mui/icons-material'
import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/month_calendar_white.svg'
import { smallBoxStyle } from '../../constants/types'

export const reservetionsEventsModalText = (text) =>
	t(`hotel.eventHall.reservetionsEventsModal.${text}`)

export default function ReservetionsEventsModal({ showModal, onClose, data }) {
	const [visible, setVisible] = useState(false)
	const { short_code } = useSelector((state) => state.app)
	const navigate = useNavigate()
	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const goToReservations = () => {
		navigate(`/${short_code}/admin/reservation/reservations`)
	}
	const goToReservationDetails = (reservation_id) => {
		navigate(`/${short_code}/admin/reservation/reservations/${reservation_id}`)
	}
	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={smallBoxStyle} className="outline-none">
					<div
						className="flex flex-row items-center px-024 pt-024 pb-0 gap-3"
						style={{ marginBottom: '-20px' }}
					>
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{reservetionsEventsModalText('reservations')}
						</label>
					</div>
					<div className="flex flex-col p-024">
						<Card className="mt-6">
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center pl-4 pr-3 py-2">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-l font-medium mr-3">
											{reservetionsEventsModalText('reservations')}
										</span>
										<span className="text-base font-medium text-black1 px-2 bg-gray2 rounded-full">
											{data.length}{' '}
											{reservetionsEventsModalText('reservationsCount')}
										</span>
									</div>
								</div>
								<TableContainer component={Paper} className="mt-4">
									<Table aria-label="simple table">
										<TableHead className="bg-gray7">
											<TableRow>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="center"
												>
													{reservetionsEventsModalText('partySize')}
												</TableCell>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="center"
												>
													{reservetionsEventsModalText('date')}
												</TableCell>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="center"
												>
													{reservetionsEventsModalText('actions')}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((item, index) => (
												<TableRow
													key={index}
													onClick={() =>
														goToReservationDetails(item.reservation_id)
													}
												>
													<TableCell
														align="center"
														className="py-3 !text-gray5 !text-left !text-base"
													>
														{item.party_size}
													</TableCell>
													<TableCell
														align="center"
														className="py-3 !text-gray5 !text-left !text-base"
													>
														{item.date}
													</TableCell>
													<TableCell
														align="center"
														className="py-3 !text-gray5 !text-left !text-base"
													>
														<RemoveRedEyeOutlined />
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{reservetionsEventsModalText('cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => goToReservations()}
						>
							{reservetionsEventsModalText('allReservations')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
