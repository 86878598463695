import React from 'react'
import { RInputDeclare } from '../RInputDeclare'
import CustomMUIInput from './custom-mui-input'

const CustomFormInput = ({
	text = true,
	value,
	placeholder = '',
	className = '!h-12',
	fullWidth = true,
	onChange,
	required = false,
	title,
	disabled = false,
	error = false,
	type = 'text',
	name,
	mt = 'mt-1'
}) => {
	return (
		<div className={`flex flex-col gap-1 ${mt}`}>
			{text && (
				<RInputDeclare
					value={title || placeholder}
					className="!m-0 !p-0 !text-black1 font-medium text-base"
				/>
			)}
			<CustomMUIInput
				{...{
					onChange,
					value,
					fullWidth,
					placeholder,
					className,
					disabled,
					required,
					error,
					type, name
				}}
			/>
		</div>
	)
}

export default CustomFormInput
