import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import cardIcon from '../../assets/svgs/v2-dollar-payment-link.svg'
import EmailChangeCodeModal from '../../components/account/EmailChangeCodeModal'
import CustomSelect from '../../components/custom/CustomSelect'
import CustomSwitch from '../../components/custom/CustomSwitch'
import LoadingScreen from '../../components/custom/LoadingScreen'
import { RInputDeclare } from '../../components/custom/RInputDeclare'
import StripeOnboardingModal from '../../components/stripeOnboardingModal'
import { isEntertainmentVenue, isRetailVenue } from '../../helpers/venueHelpers'
import LoginService from '../../services/loginService'
import VenueService from '../../services/venuService'
import { getUserProfile } from '../../store/actions/auth'
import { KEYS } from '../../utils/storage'

export const venueSpaceText = (text) => t(`account.venueSpace.${text}`)

const adminLanguages = [{ title: 'English', value: 'English' }]

const validatorEmail = yup.object().shape({
	email: yup
		.string()
		.trim()
		.required('Email is required')
		.max(72, 'Max exceeded')
		.min(5, 'Min not met')
		.matches(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			'Invalid email'
		),
})

const validatorPass = yup.object().shape({
	cur_password: yup
		.string()
		.trim()
		.required('Please enter the current password.'),
	password: yup
		.string()
		.trim()
		.required('New Password is required')
		.max(24, 'Max exceeded')
		.min(8, 'Min not met'),
	confirm_pass: yup
		.string()
		.required('Please confirm your new Password')
		.oneOf([yup.ref('password'), null], 'Password is not matched'),
})

const LoginInfo = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const signatureRef = useRef(null)
	const { user } = useSelector((state) => state.auth)

	const [emailVerifyLoading, setEmailVerifyLoading] = useState(false)
	const [hasEmailChanges, setHasEmailChanges] = useState(false)
	const [verifyCodeModal, showVerifyCodeModal] = useState(false)
	const [stripeOnboardingModal, showStripeOnboardingModal] = useState(false)

	const [loading, setLoading] = useState(false)
	const [loadingScreen, setLoadingScreen] = useState(false)
	const [hasPassChanges, setHasPassChanges] = useState(false)
	const [isESignatureEnabled, setESignatureEnabled] = useState(true)
	const [singatureInput, setSingatureInput] = useState('')
	const [data, setData] = useState([
		// { id: '112', name: 'resaurant_bill_noma.pdf', type: 'pdf', date: '20/10/2023 22:20 PM', from: 'Vb Sales', signed: 1, document_uploaded: 1 },
		// { id: '113', name: 'cashpay_bill.doc', type: 'doc', date: '20/10/2023 22:20 PM', from: 'Vb Sales', signed: 1, document_uploaded: 0 },
		// { id: '113', name: 'nussu_bill.pdf', type: 'pdf', date: '20/10/2023 22:20 PM', from: 'Vb Sales', signed: 1, document_uploaded: 1 }
	])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [loaded, setLoaded] = useState(true)
	const [venueConfiguration, setVenueConfiguration] = useState('')
	const [venueLanguages, setVenueLanguages] = useState([])
	const [canProcessTransactions, setCanProcessTransactions] = useState(false)
	const [moreInfoRequired, setMoreInfoRequired] = useState(false)
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const clearSignature = () => {
		signatureRef.current.clear()
	}

	const formikEmailProps = useFormik({
		initialValues: { email: '' },
		validationSchema: validatorEmail,
		onSubmit: (values) => onChangeEmailRequest(values),
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false,
	})

	const formikPassProps = useFormik({
		initialValues: { cur_password: '', password: '', confirm_pass: '' },
		validationSchema: validatorPass,
		onSubmit: (values) => onChangePassRequest(values),
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false,
	})

	useEffect(() => {
		if (user) {
			formikEmailProps.setFieldValue('email', user?.email)
		}
	}, [user])

	useEffect(() => {
		getConfiguration()
	}, [])

	const reloadWithoutQueryParams = () => {
		navigate(window.location.pathname)
	}

	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search)

		if (query.get('success')) {
			NotificationManager.success(
				'Onboarding completed! Please proceed with enabling Online Payments at any time.',
				'Success',
				3000
			)
			getConfiguration()
			setTimeout(() => {
				reloadWithoutQueryParams()
			}, 3000)
		} else if (query.get('canceled')) {
			NotificationManager.error(
				'Onboarding paused, more info might be required!. Please contact contact@venueboost.io for any assistance.',
				'Info',
				3000
			)
			setTimeout(() => {
				reloadWithoutQueryParams()
			}, 3000)
		}
	}, [])

	const getConfiguration = () => {
		setLoadingScreen(true)
		VenueService.CheckVenueConfiguration()
			.then((res) => {
				setLoadingScreen(false)
				setVenueConfiguration(res?.properties)
				setVenueLanguages(res?.languages)
				setCanProcessTransactions(
					res?.properties?.can_process_transactions === 1 ? true : false
				)
				setMoreInfoRequired(
					res?.properties?.more_info_required === 1 ? true : false
				)
			})

			.catch((err) => {
				setLoading(false)
				throw new Error('Problem on getConfiguration')
			})
	}

	const handleCanProcessTransactions = (value) => {
		if (!venueConfiguration?.onboarding_completed) {
			showStripeOnboardingModal(true)
		} else {
			setCanProcessTransactions(value)
			const details = {
				can_process_transactions: value,
			}

			VenueService.saveVenueConfiguration(details)
				.then((data) => {
					if (value) {
						NotificationManager.success(
							'Online Payment enabled successfully',
							'Success',
							3000
						)
					}

					if (!value) {
						NotificationManager.success(
							'Online Payment disabled successfully',
							'Success',
							3000
						)
					}
				})
				.catch((err) => {
					setCanProcessTransactions(!value)
					if (typeof err?.error === 'string') {
						NotificationManager.error(err?.error)
					} else {
						const errorKeys = Object.keys(err?.error || {})
						if (errorKeys.length > 0) {
							const firstErrorKey = errorKeys[0]
							const firstErrorMessage = err?.error[firstErrorKey][0]
							NotificationManager.error(
								firstErrorMessage || 'Something went wrong!'
							)
						} else {
							NotificationManager.error('Something went wrong!')
						}
					}
					throw new Error('Problem on saveVenueConfiguration')
				})
		}
	}

	const handleLanguageEmailChange = (value) => {
		const details = {
			email_language: value.value,
		}

		VenueService.saveVenueConfiguration(details)
			.then((data) => {
				NotificationManager.success(
					'Email language changed successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(0)
				}, 1000)
			})
			.catch((err) => {
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
				throw new Error('Problem on saveVenueConfiguration')
			})
	}

	const onChangeEmailRequest = ({ email }) => {
		const payload = {
			email: email,
		}
		setEmailVerifyLoading(true)
		LoginService.requestChangeEmail(payload)
			.then((data) => {
				setEmailVerifyLoading(false)
				showVerifyCodeModal(true)
			})
			.catch((error) => {
				setEmailVerifyLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on requestChangeEmail')
			})
	}

	const onChangePassRequest = (values) => {
		const payload = {
			...values,
		}

		setLoading(true)
		LoginService.reqestChangePass(payload)
			.then((data) => {
				setLoading(false)
				dispatch(getUserProfile())
				formikPassProps.setFieldValue('cur_password', '')
				formikPassProps.setFieldValue('password', '')
				formikPassProps.setFieldValue('confirm_pass', '')
				NotificationManager.success(
					'Password has been updated successfully',
					'Success',
					3000
				)
			})
			.catch((error) => {
				setLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on updating the password')
			})
	}

	if (loadingScreen) {
		return <LoadingScreen />
	}

	return (
		<div
			className={'h-full overflow-y-auto p-6'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card className="mt-6">
				<CardContent className="!p-0">
					<div className="w-full rounded-4xl p-6 gap-10">
						<div className="grid md:grid-cols-2 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
							<div className="flex flex-col">
								<p className={'text-base text-gray5 mb-2'}>
									<span className="text-black1 text-xl font-medium">
										{venueSpaceText('languagePreferences')}
									</span>
								</p>
								<span className="text-gray5 text-16 font-medium">
									{venueSpaceText('vbAdminLanguage')}
								</span>
								<div className="grid md:grid-cols-2 grid-cols-1 flex-col pt-4 bg-white rounded-3xl gap-3 md:gap-6">
									<div className="flex flex-col">
										<RInputDeclare
											value={venueSpaceText('vbAdminLanguage')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={adminLanguages}
											value={'English'}
											disabled={true}
											height={'!h-10'}
											className={'!h-10 border !border-gray6'}
											placeholder={venueSpaceText('chooseVBAdminLanguage')}
											renderRow={(item) => <p>{item.title}</p>}
											handleChange={(v) => {}}
											onRemoveItem={(v) => {}}
										/>
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={venueSpaceText('emailLanguage')}
											className="!mt-0 !mb-2  !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={venueLanguages}
											value={venueConfiguration.email_language}
											style={{ zIndex: 1000 }}
											height={'!h-10'}
											className={'!h-10 border !border-gray6'}
											placeholder={venueSpaceText('defineEmailLanguage')}
											renderRow={(item) => <p>{item.title}</p>}
											handleChange={handleLanguageEmailChange}
										/>
									</div>
								</div>
								<span className="text-gray5 mt-4 text-16 font-medium">
									{venueSpaceText('emailLanguageSettingDescription')}
								</span>
							</div>
							<div className="flex flex-col">
								<p className={'text-base text-gray5 mb-2'}>
									<span className="text-black1 text-xl font-medium">
										{venueSpaceText('onlinePayments')}
									</span>
								</p>
								<span className="text-gray5 text-16 font-medium">
									{`${venueSpaceText('activateCardPayments')} ${
										isRetailVenue(venueType) || isEntertainmentVenue(venueType)
											? 'customers'
											: 'guests'
									}`}
								</span>
								<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
									<div className="flex flex-row gap-4">
										<div className="bg-gray2 p-3 rounded-lg">
											<img src={cardIcon} alt="referral" className="w-6 h-6" />
										</div>
										<div className="flex flex-col">
											<div>
												<span className="text-xl font-medium text-black1 mr-3">
													{venueSpaceText('enableOnlinePayments')}
												</span>
											</div>
											<span className="text-gray5 text-sm">
												{venueSpaceText('activateCardPayments')}
											</span>
										</div>
									</div>
									<div className="flex flex-row items-center gap-3">
										{canProcessTransactions ? (
											<span className="text-sm font-medium text-[#027A48]">
												On
											</span>
										) : (
											<span className="text-sm font-medium text-gray5">
												Off
											</span>
										)}
										<CustomSwitch
											active={canProcessTransactions}
											onClick={handleCanProcessTransactions}
										/>
									</div>
								</div>
								{!venueConfiguration?.onboarding_completed && (
									<span className="text-gray5 text-16 font-medium">
										{venueSpaceText('noteOnboarding')}
									</span>
								)}

								{venueConfiguration?.onboarding_completed &&
									moreInfoRequired && (
										<span className="text-gray5 text-16 font-medium text-red1">
											{venueSpaceText('attentionStripeOnboardingIncomplete')}
										</span>
									)}

								{venueConfiguration?.onboarding_completed &&
									!moreInfoRequired && (
										<span className="text-gray5 text-16 font-medium text-green5">
											{venueSpaceText('stripeOnboardingComplete')}
										</span>
									)}
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			<Card className="mt-6">
				<CardContent className="!p-0">
					<div className="w-full rounded-4xl p-6 gap-10">
						<div className="flex flex-col justify-center pr-10 mb-4">
							<div className="text-xl font-medium text-black1">
								{venueSpaceText('loginSecurity')}
							</div>
						</div>
						<div className="relative started-form">
							<div className="grid md:grid-cols-2 grid-cols-1 flex-col mt-8 bg-white rounded-3xl gap-3 md:gap-6">
								<div className="flex flex-col justify-start items-start w-full gap-3 md:gap-3">
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={venueSpaceText('loginEmail')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikEmailProps.values.email}
											error={formikEmailProps.errors.email}
											onChange={(e) => {
												formikEmailProps.setFieldValue('email', e.target.value)
												setHasEmailChanges(true)
											}}
										/>
									</div>

									<Button
										sx={{
											'&.Mui-disabled': {
												color: '#fff',
											},
										}}
										disabled={!hasEmailChanges || emailVerifyLoading}
										variant={'contained'}
										className=" !mt-5 w-full h-[48px] !bg-black1 text-white font-medium"
										onClick={formikEmailProps.handleSubmit}
									>
										{emailVerifyLoading ? (
											<CircularProgress size={18} />
										) : (
											venueSpaceText('changeEmail')
										)}
									</Button>
								</div>
								<div className="flex flex-col justify-center items-start w-full gap-3 md:gap-3">
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={venueSpaceText('changePassword')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											size="small"
											type="password"
											value={formikPassProps.values.cur_password}
											error={formikPassProps.errors.cur_password}
											onChange={(e) => {
												formikPassProps.setFieldValue(
													'cur_password',
													e.target.value
												)
												setHasPassChanges(true)
											}}
										/>
										<TextField
											size="small"
											className="!mt-4"
											placeholder={venueSpaceText('newPassword')}
											type="password"
											value={formikPassProps.values.password}
											error={formikPassProps.errors.password}
											onChange={(e) => {
												formikPassProps.setFieldValue(
													'password',
													e.target.value
												)
												setHasPassChanges(true)
											}}
										/>
										<TextField
											size="small"
											className="!mt-4"
											placeholder={venueSpaceText('confirmPassword')}
											type="password"
											value={formikPassProps.values.confirm_pass}
											error={formikPassProps.errors.confirm_pass}
											onChange={(e) => {
												formikPassProps.setFieldValue(
													'confirm_pass',
													e.target.value
												)
												setHasPassChanges(true)
											}}
										/>
									</div>
									<Button
										sx={{
											'&.Mui-disabled': {
												color: '#fff',
											},
										}}
										disabled={!hasPassChanges || loading}
										variant={'contained'}
										className=" !mt-5 w-full h-[48px] !bg-black1 text-white font-medium"
										onClick={formikPassProps.handleSubmit}
									>
										{loading ? (
											<CircularProgress size={18} />
										) : (
											venueSpaceText('resetPassword')
										)}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>

			{verifyCodeModal && (
				<EmailChangeCodeModal
					showModal={verifyCodeModal}
					onClose={(flag) => {
						showVerifyCodeModal(false)
						if (flag == true) {
							setHasEmailChanges(false)
						}
					}}
				/>
			)}

			{stripeOnboardingModal && (
				<StripeOnboardingModal
					showModal={stripeOnboardingModal}
					onClose={() => {
						showStripeOnboardingModal(false)
					}}
				/>
			)}
		</div>
	)
}

export default LoginInfo
