import React, { useEffect, useState } from "react";
import SelectFilter from "../dropdowns/select-filter";

export function BrandFilter({ brands, onBrandChange = () => { } }) {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ value: 'all', label: 'All Brands' })
    const [dropdownOpen, setDropdownOpen] = useState(false)

    useEffect(() => {
        setData([{ value: 'all', label: 'All Brands' }, ...((brands ?? []).map(b => ({ value: b.id, label: b.title })))])
    }, [brands])

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleFilterChange = (selectedItem) => {
        setFilter(selectedItem)
        onBrandChange(selectedItem)
        setDropdownOpen(false)
    }

    return (
        <SelectFilter
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            items={data}
            selectedItem={filter?.label}
            onChange={handleFilterChange}
        />
    )
}