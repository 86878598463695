import React from "react";
import GeneralToolTip from "./general-tooltip";

const LineChartTooltip = (props) => {
  const hoveredItem = props?.payload[0];

  if (hoveredItem) {
    const colorValue = hoveredItem.color ?? hoveredItem.stroke ?? "";
    const toolTipPayloadValue = `${
      hoveredItem?.payload?.field ?? hoveredItem?.payload?.name ?? ""
    }: ${hoveredItem?.payload?.value}`;

    return (
      <GeneralToolTip
        color={colorValue}
        field={toolTipPayloadValue}
        bgColor={hoveredItem.stroke}
      />
    );
  }
  return null;
};

export default LineChartTooltip;
