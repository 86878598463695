import WestTwoToneIcon from "@mui/icons-material/WestTwoTone";
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FiMoreVertical } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MoreIcon from '../../assets/svgs/v2-orderpay-standard.svg';
import LoadingScreen from '../../components/custom/LoadingScreen';
import ReservationModal from '../../components/reservation/reservationModal';
import ReservationPanel from '../../components/reservation/reservationPanel';
import TableEmptyData from '../../components/table/TableEmptyData';
import { RenderTable } from '../../components/table/renderTables';
import TableShapeModal from '../../components/table/tableShapeModal';
import ReservationService from '../../services/reservationService';
import TableService from '../../services/tableService';
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil';
import './index.css';

export const tablesText = (text) => t(`tables.view.${text}`)

const TablesScreen = () => {
	let navigate = useNavigate()
	let location = useLocation()

	const [orderList, setOrderList] = useState([])
	const [tableDetails, setTableDetails] = useState([])
	const [filteredTableDetails, setFilteredTableDetails] = useState(tableDetails)

	const [reservation, showReservation] = useState(false)
	const [startDate, setStartDate] = useState(new Date())
	const [isReserveModelOpen, setReserveModelOpen] = useState(false)
	const [isTablesShapesModalOpen, setIsTablesShapesModalOpen] = useState(false)

	const [isAvailable, showAvailable] = useState(false)
	const [isReserved, showReserved] = useState(false)
	const [isNotAvailable, showNotAvailable] = useState(false)

	const [reservationsData, setReservationsData] = useState([])
	const [loaded, setLoaded] = useState(true)
	const { short_code } = useSelector((state) => state.app)
	const [floorFilter, setFloorFilter] = useState()
	const [uniqueLocations, setUniqueLocations] = useState([])
	const [showMoreFilterModal, setShowMoreFilterModal] = useState(false)
	const [showTime, setShowTime] = useState(false)
	const [showAmount, setShowAmount] = useState(false)
	const [showReservations, setShowReservations] = useState(false)

	useEffect(() => {
		getReservationData()
		getTableData()
	}, [])

	useEffect(() => {
		const temp = tableDetails.filter(
			(item, index) => item.location_id === floorFilter
		)
		setFilteredTableDetails(temp)
	}, [floorFilter])

	const getTableData = () => {
		setLoaded(false)
		TableService.GetAll()
			.then((res) => {
				setTableDetails(res.tables)
				setUniqueLocations(res.unique_locations)
				if (res.unique_locations.length > 0) {
					setFloorFilter(res.unique_locations[0].id)
				}
				setLoaded(true)
				identifyVenue(short_code)
				trackEvent('Tables Management', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch((e) => {
				setLoaded(true)
				identifyVenue(short_code)
				trackEvent('Tables Management', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'screen',
				})
			})
	}

	const handleChange = (e) => {
		setReserveModelOpen(!isReserveModelOpen)
		setStartDate(e)
	}

	const handleOpen = () => {
		setReserveModelOpen(true)
	}

	const handleTableListView = () => {
		navigate(`${location.pathname}/listview`)
	}

	const getReservationData = async () => {
		ReservationService.ReadAll().then((res) => {
			setReservationsData(JSON.parse(JSON.stringify(res.data)))
		})
	}

	const handleAvailable = () => {
		showAvailable((isAvailable) => !isAvailable)
	}

	const handleReserved = () => {
		showReserved((isReserved) => !isReserved)
	}

	const handleNotAvailable = () => {
		showNotAvailable((isNotAvailable) => !isNotAvailable)
	}

	const onTableClick = (selected, name) => {
		if (selected === true)
			setOrderList(orderList.filter((item) => item !== name))
		else if (selected === false)
			if (!(name in orderList)) setOrderList((oldArray) => [...oldArray, name])
	}

	const onTabFilterChange = (tab) => {
		setFloorFilter(tab)
	}
	return (
		<div className="flex flex-col h-full">
			<div className="w-full flex flex-row h-[34px] px-[24px] py-[8px] bg-white border-b border-[#E4E4E4] !hidden">
				<p className="text-cyan1 font-medium text-[12px]">
					{tablesText('dashboard')}&nbsp;/&nbsp;{tablesText('Food')}
					&nbsp;/&nbsp;
				</p>
				<p className="text-[#828487] font-medium text-[12px]">
					{tablesText('select_table')}
				</p>
			</div>
			<div className="w-full flex flex-row min-h-[68px] bg-white border-b border-[#E4E4E4]">
				<div className="flex justify-center items-center">
					<WestTwoToneIcon
						fontSize="small"
						className="cursor-pointer !hidden"
					/>

					<div className="w-fit h-[68px]">
						<div className="flex flex-col justify-center ml-[16px] !p-3 !px-6 w-[140px] h-[68px] !bg-white rounded-24 !text-18px">
							<p className="text-[#19191C] font-medium text-[21px]">
								{tablesText('Tables')}
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-1 justify-center flex-row min-w-[870px] items-center ml-auto mr-[16px]">
					<button
						className={
							isAvailable
								? 'font-semibold text-[16px] text-cyan1 border-[#8cf58f] border align-center rounded-[10px] cursor-pointer px-[16px] h-[36px] mr-[12px]'
								: 'font-normal text-[16px] text-[#19191C] bg-[#fff] border-[#E4E4E4] border align-center rounded-[10px] cursor-pointer px-[16px] h-[36px] mr-[12px]'
						}
						onClick={handleAvailable}
					>
						<div className="flex justify-center items-center pl-[10px] text-[14px] font-normal text-[#19191C]">
							<span className="mr-[8px] rounded-[6px] w-[12px] h-[12px] bg-[#D9F6DA] outline outline-4 outline-[#D9F6DA]" />
							{tablesText('Available')}
						</div>
					</button>
					<button
						className={
							isReserved
								? 'font-semibold text-[16px] text-cyan1 border-[#fcc39d] border align-center rounded-[10px] cursor-pointer px-[16px] h-[36px] mr-[12px]'
								: 'font-normal text-[16px] text-[#19191C] bg-[#fff] border-[#E4E4E4] border align-center rounded-[10px] cursor-pointer px-[16px] h-[36px] mr-[12px]'
						}
						onClick={handleReserved}
					>
						<div className="flex justify-center items-center pl-[10px] text-[14px] font-normal text-[#19191C]">
							<span className="mr-[8px] rounded-[6px] w-[12px] h-[12px] bg-[#FEE8D9] outline outline-4 outline-[#FEE8D9]" />
							{tablesText('Reserved')}
						</div>
					</button>
					<button
						className={
							isNotAvailable
								? 'font-semibold text-[16px] text-cyan1 border-[#d3ccff] border align-center rounded-[10px] cursor-pointer px-[16px] h-[36px] mr-[12px]'
								: 'font-normal text-[16px] text-[#19191C] bg-[#fff] border-[#E4E4E4] border align-center rounded-[10px] cursor-pointer px-[16px] h-[36px] mr-[12px]'
						}
						onClick={handleNotAvailable}
					>
						<div className="flex justify-center items-center pl-[10px] text-[14px] font-normal text-[#19191C]">
							<span className="mr-[8px] rounded-[6px] w-[12px] h-[12px] bg-[#EDEAFF] outline outline-4 outline-[#EDEAFF]" />
							{tablesText('not_available')}
						</div>
					</button>

					<div className="flex flex-end ml-auto mr-[16px] align-middle gap-4">
						<div className="relative flex align-middle gap-2">
							<Tooltip
								title={
									'Click to get more details about the shapes of tables that you can create'
								}
								arrow
							>
								<img
									alt=""
									onClick={() => setIsTablesShapesModalOpen(true)}
									src={MoreIcon}
									className="w-[24px] h-[24px] cursor-pointer"
								/>
							</Tooltip>

							<p
								className="text-black1 font-bold flex align-middle gap-2 text-[20px] cursor-pointer"
								onMouseEnter={() => setShowMoreFilterModal(true)}
								onMouseLeave={() => setShowMoreFilterModal(false)}
							>
								{tablesText('Properties')}
								<span className="rounded-full w-[20px] h-[20px] text-[16px] font-medium  text-white align-middle bg-slate-500">
									{' '}
									{tablesText('3')}
								</span>
							</p>
							<div className="flex w-[36px] h-[36px] bg-white rounded-[10px] border-[1px] p-2 border-[#E4E4E4] ml-4 align-middle right-4 ">
								<FiMoreVertical />
							</div>
							{showMoreFilterModal && (
								<div className="absolute z-20 right-0 top-[36px] flex flex-col p-3 bg-white border-[#e4e4e4] border-[1px] rounded-[10px] ">
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													onClick={() => setShowTime(!showTime)}
													checked={showTime}
												/>
											}
											label="Dining time"
										/>
										<FormControlLabel
											control={
												<Checkbox
													onClick={() => setShowAmount(!showAmount)}
													checked={showAmount}
												/>
											}
											label="Amount"
										/>
										<FormControlLabel
											control={
												<Checkbox
													onClick={() => setShowReservations(!showReservations)}
													checked={showReservations}
												/>
											}
											label="Reservations"
										/>
									</FormGroup>
								</div>
							)}
						</div>
						{/*TODO: add a tooltip which opens a modal explaining the table shapes*/}
						<div
							className="bg-black1 flex flex-row px-4 py-1 text-white rounded-lg cursor-pointer"
							onClick={handleTableListView}
						>
							<span>{tablesText('list_view')}</span>
						</div>
					</div>
				</div>
			</div>
			<div
				className={`relative blur-20 flex bg-[#F8F9FD] ${
					reservation ? '' : 'overflow-auto'
				}`}
				style={{ height: 'calc(100vh - 178px)', margin: 0, padding: 0 }}
			>
				<div className="button-wrapper">
					<div className="center-locations">
						<div
							className="fixed bottom-[80px] shadow-xl  z-10 flex bg-white rounded-[10px] border-[1px] p-1 border-[#E4E4E4] gap-2 ml-4 align-middle"
							draggable="true"
						>
							<div className="buttons-wrapper">
								{uniqueLocations.map((location, index) => (
									<button
										variant="contained"
										key={index}
										className={`${
											floorFilter === location.id
												? '!px-2 w-[120px] h-[32px] !rounded-[8px] !shadow-none border-none !bg-black1'
												: '!px-2 w-[120px] h-[32px] !rounded-[8px] !shadow-none border-none !bg-transparent'
										}`}
										onClick={() => onTabFilterChange(location.id)}
									>
										<p
											className={`${
												floorFilter === location.id
													? 'font-normal text-[14px] text-white'
													: 'font-normal text-[14px] text-[#19191C]'
											}`}
										>
											{location.name}
										</p>
									</button>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="flex-1">
					{loaded ? (
						<>
							{filteredTableDetails.length == 0 && (
								<div className="flex justify-center items-center pt-8">
									<TableEmptyData
										content="table"
										btnLabel="New Table"
										tableList={true}
										onAction={() => handleTableListView()}
									/>
								</div>
							)}
							<div className="flex flex-wrap p-[24px] gap-[20px] mb-4">
								{filteredTableDetails.length > 0 &&
									filteredTableDetails.map((item, i) => {
										return (
											<RenderTable
												isSelected={orderList.includes(item.name)}
												num={item.seats}
												content={item.name}
												statusDescription={item.status}
												shape={
													item.shape === 'cocktail' ||
													item.shape === 'kid' ||
													item.shape === 'outdoor'
														? 'round'
														: item.shape === 'booths'
														? 'picnic'
														: item.shape
												}
												onClick={(sel, cont) => onTableClick(sel, cont)}
												isHChair={false}
												vertical={false}
												key={i}
												location_id={item.location_id}
												diningTime={item.diningTime}
												amount={item.amount}
												reservations={item.reservations}
												// showTime={showTime}
												// showAmount={showAmount}
												// showReservations={showReservations}
											/>
										)
									})}
							</div>
						</>
					) : (
						<LoadingScreen />
					)}

					<div className="h-[80px]"></div>
				</div>

				{
					/* Right Reservation Sidebar */
					reservation && (
						<ReservationPanel
							handleChange={handleChange}
							handleOpen={handleOpen}
							showReservation={showReservation}
							startDate={startDate}
							reservationsData={reservationsData}
							getReservationData={getReservationData}
							isReserveModelOpen={isReserveModelOpen}
						/>
					)
				}
			</div>
			<ReservationModal
				isModelOpen={isReserveModelOpen}
				setModelOpen={setReserveModelOpen}
				getReservationData={getReservationData}
			/>
			{isTablesShapesModalOpen && (
				<TableShapeModal
					showModal={isTablesShapesModalOpen}
					onClose={() => setIsTablesShapesModalOpen(false)}
				/>
			)}
		</div>
	)
}

export default TablesScreen;
