import { t } from 'i18next'
import AccommodationVacationRentalStandardIcon from '../../../../assets/svgs/accommodation-vacation-standard.svg'
import AccommodationVacationRentalWhiteIcon from '../../../../assets/svgs/accommodation-vacation-white.svg'
import v2AccountSubscriptionStandard from '../../../../assets/svgs/v2-account-subscription-standard.svg'
import v2AccountSubscriptionWhite from '../../../../assets/svgs/v2-account-subscription-white.svg'
import v2AccountVenueSpaceStandard from '../../../../assets/svgs/v2-account-venue-space-standard.svg'
import v2AccountVenueSpaceWhite from '../../../../assets/svgs/v2-account-venue-space-white.svg'
import v2AdvancedAccountStandard from '../../../../assets/svgs/v2-advanced-account-standard.svg'
import v2AdvancedAccountWhite from '../../../../assets/svgs/v2-advanced-account-white.svg'
import v2AnalyticsStandard from '../../../../assets/svgs/v2-analytics-standard.svg'
import v2AnalyticsWhite from '../../../../assets/svgs/v2-analytics-white.svg'
import v2CampaignStandard from '../../../../assets/svgs/v2-campaign-standard.svg'
import v2CampaignWhite from '../../../../assets/svgs/v2-campaign-white.svg'
import v2CustomerInsightStandard from '../../../../assets/svgs/v2-customer_insights-standard.svg'
import v2CustomerInsightWhite from '../../../../assets/svgs/v2-customer_insights-white.svg'
import v2DashboardStandard from '../../../../assets/svgs/v2-dashboard-standard.svg'
import v2DashboardWhite from '../../../../assets/svgs/v2-dashboard-white.svg'
import v2DiscountStandard from '../../../../assets/svgs/v2-discount-standard.svg'
import v2DiscountWhite from '../../../../assets/svgs/v2-discount-white.svg'
import v2EventHallStandard from '../../../../assets/svgs/v2-event-hall-standard.svg'
import v2EventHallWhite from '../../../../assets/svgs/v2-event-hall-white.svg'
import v2GuestStandard from '../../../../assets/svgs/v2-guest-standard.svg'
import v2GuestWhite from '../../../../assets/svgs/v2-guest-white.svg'
import v2GuestsEnrolledStandard from '../../../../assets/svgs/v2-guests-enrolled-standard.svg'
import v2GuestsEnrolledWhite from '../../../../assets/svgs/v2-guests-enrolled-white.svg'
import v2GymStandard from '../../../../assets/svgs/v2-gym-standard.svg'
import v2GymWhite from '../../../../assets/svgs/v2-gym-white.svg'
import v2IntegrationStandard from '../../../../assets/svgs/v2-integration-standard.svg'
import v2IntegrationWhite from '../../../../assets/svgs/v2-integration-white.svg'
import v2LoyaltyStandard from '../../../../assets/svgs/v2-loyalty-standard..svg'
import v2LoyaltyWhite from '../../../../assets/svgs/v2-loyalty-white.svg'
import v2LoyaltyProgramStandard from '../../../../assets/svgs/v2-loyaltyprogram-standart.svg'
import v2LoyaltyProgramWhite from '../../../../assets/svgs/v2-loyaltyprogram-white.svg'
import v2MarketingStandard from '../../../../assets/svgs/v2-marketing-standard.svg'
import v2MarketingWhite from '../../../../assets/svgs/v2-marketing-white.svg'
import v2MenuCategoryStandard from '../../../../assets/svgs/v2-menu-category-standard.svg'
import v2MenuCategoryWhite from '../../../../assets/svgs/v2-menu-category-white.svg'
import v2MenuInventoryStandard from '../../../../assets/svgs/v2-menu-inventory-standard.svg'
import v2MenuInventoryWhite from '../../../../assets/svgs/v2-menu-inventory-white.svg'
import v2MenuProductStandard from '../../../../assets/svgs/v2-menu-product-standard.svg'
import v2MenuProductWhite from '../../../../assets/svgs/v2-menu-product-white.svg'
import v2MenuStandard from '../../../../assets/svgs/v2-menu-standard.svg'
import v2MenuWhite from '../../../../assets/svgs/v2-menu-white.svg'
import v2OrderAndPayStandard from '../../../../assets/svgs/v2-orderpay-standard.svg'
import v2OrderAndPayWhite from '../../../../assets/svgs/v2-orderpay-white.svg'
import v2ReferralStandard from '../../../../assets/svgs/v2-referal-standard.svg'
import v2ReferralWhite from '../../../../assets/svgs/v2-referal-white.svg'
import v2ReservationStandard from '../../../../assets/svgs/v2-reservation-standard.svg'
import v2ReservationWhite from '../../../../assets/svgs/v2-reservation-white.svg'
import v2RestaurantStandard from '../../../../assets/svgs/v2-restaurant-standard.svg'
import v2RestaurantWhite from '../../../../assets/svgs/v2-restaurant-white.svg'
import v2SettingStandard from '../../../../assets/svgs/v2-setting-standard.svg'
import v2SettingWhite from '../../../../assets/svgs/v2-setting-white.svg'
import v2StaffStandard from '../../../../assets/svgs/v2-staff-standard.svg'
import v2StaffWhite from '../../../../assets/svgs/v2-staff-white.svg'
import v2TableStandard from '../../../../assets/svgs/v2-table-standard.svg'
import v2TableWhite from '../../../../assets/svgs/v2-table-white.svg'
import v2TOStandard from '../../../../assets/svgs/v2-timeoff-standard.svg'
import v2TOWhite from '../../../../assets/svgs/v2-timeoff-white.svg'

 const getTranslation = (text) =>
	t(`components.sidebarItems.accomodation.FamilyResortSidebar.${text}`)

export const FamilyResortSidebar = [
	{
		img: v2DashboardStandard,
		imgActive: v2DashboardWhite,
		key: 1,
		name: 'dashboard',
		text: getTranslation('dashboard'),
		default: true,
		menus: [
			{
				img: v2DashboardStandard,
				imgActive: v2DashboardWhite,
				name: 'venue-dashboard',
				text: getTranslation('dashboard'),
				key: 1,
			},
			{
				img: v2AnalyticsStandard,
				imgActive: v2AnalyticsWhite,
				name: 'analytics',
				text: getTranslation('analyticsAndReporting'),
				key: 2,
			},
			{
				img: v2CustomerInsightStandard,
				imgActive: v2CustomerInsightWhite,
				name: 'insights',
				text: getTranslation('customerInsights'),
				key: 3,
			},
		],
	},
	{
		img: v2MenuStandard,
		imgActive: v2MenuWhite,
		key: 2,
		name: 'menu',
		text: getTranslation('menuManagement'),
		menus: [
			{
				img: v2MenuProductStandard,
				imgActive: v2MenuProductWhite,
				name: 'items',
				text: getTranslation('products'),
				key: 2,
			},
			{
				img: v2MenuCategoryStandard,
				imgActive: v2MenuCategoryWhite,
				name: 'categories',
				text: getTranslation('categories'),
				key: 3,
			},
			{
				img: v2MenuInventoryStandard,
				imgActive: v2MenuInventoryWhite,
				name: 'inventory',
				text: getTranslation('inventory'),
				key: 4,
			},
		],
	},

	{
		img: v2ReservationStandard,
		imgActive: v2ReservationWhite,
		key: 3,
		name: 'booking',
		text: getTranslation('bookings'),
		menus: [
			{
				img: v2AdvancedAccountStandard,
				imgActive: v2AdvancedAccountWhite,
				name: 'booking',
				text: getTranslation('bookings'),
				key: 1,
			},
		],
	},
	{
		img: AccommodationVacationRentalStandardIcon,
		imgActive: AccommodationVacationRentalWhiteIcon,
		key: 4,
		name: 'rental',
		text: getTranslation('rental'),
		menus: [
			{
				img: AccommodationVacationRentalStandardIcon,
				imgActive: AccommodationVacationRentalWhiteIcon,
				name: 'units',
				text: getTranslation('units'),
				key: 1,
			},
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'housekeeping',
				text: getTranslation('housekeeping'),
				key: 2,
			},
		],
	},
	{
		img: v2GuestStandard,
		imgActive: v2GuestWhite,
		key: 5,
		name: 'guests',
		text: getTranslation('guestsManagement'),
		menus: [
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'guests',
				text: getTranslation('guests'),
				key: 1,
			},
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'communication-preferences',
				text: getTranslation('communicationPreferences'),
				key: 2,
			},
			{
				img: v2OrderAndPayStandard,
				imgActive: v2OrderAndPayWhite,
				name: 'surveys-and-ratings',
				text: getTranslation('surveysAndRatings'),
				key: 3,
			},
		],
	},
	{
		img: v2StaffStandard,
		imgActive: v2StaffWhite,
		key: 6,
		name: 'staff',
		text: getTranslation('staffManagement'),
		menus: [
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'employees',
				text: getTranslation('employees'),
				key: 1,
			},
			{
				img: v2TOStandard,
				imgActive: v2TOWhite,
				name: 'time-off-requests',
				text: getTranslation('timeOffRequests'),
				key: 2,
			},
			{
				img: v2IntegrationStandard,
				imgActive: v2IntegrationWhite,
				name: 'staff-integrations',
				text: getTranslation('integrations'),
				key: 3,
			},
		],
	},
	{
		img: v2EventHallStandard,
		imgActive: v2EventHallWhite,
		key: 7,
		name: 'events-hall',
		text: getTranslation('eventsHall'),
		menus: [
			{
				img: v2EventHallStandard,
				imgActive: v2EventHallWhite,
				name: 'halls-list',
				text: getTranslation('manage'),
				key: 1,
			},
			{
				img: v2MarketingStandard,
				imgActive: v2MarketingWhite,
				name: 'halls-availability',
				text: getTranslation('availability'),
				key: 2,
			},
		],
	},
	{
		img: v2GymStandard,
		imgActive: v2GymWhite,
		key: 8,
		name: 'hotel-gym',
		text: getTranslation('gym'),
		menus: [
			{
				img: v2GymStandard,
				imgActive: v2GymWhite,
				name: 'gyms-list',
				text: getTranslation('manage'),
				key: 1,
			},
			{
				img: v2MarketingStandard,
				imgActive: v2MarketingWhite,
				name: 'gyms-availability',
				text: getTranslation('availability'),
				key: 2,
			},
		],
	},
	{
		//
		img: v2RestaurantStandard,
		imgActive: v2RestaurantWhite,
		key: 9,
		name: 'hotel-restaurant',
		text: getTranslation('restaurant'),
		menus: [
			{
				img: v2RestaurantStandard,
				imgActive: v2RestaurantWhite,
				name: 'rest-manage',
				text: getTranslation('manage'),
				key: 1,
			},
			{
				img: v2MarketingStandard,
				imgActive: v2MarketingWhite,
				name: 'rest-availability',
				text: getTranslation('availability'),
				key: 2,
			},
		],
	},
	{
		img: v2MarketingStandard,
		imgActive: v2MarketingWhite,
		key: 10,
		name: 'marketing',
		text: getTranslation('marketing'),
		menus: [
			{
				img: v2DiscountStandard,
				imgActive: v2DiscountWhite,
				name: 'promotions',
				text: getTranslation('promotions'),
				key: 1,
			},
			{
				img: v2ReferralStandard,
				imgActive: v2ReferralWhite,
				name: 'referrals',
				text: getTranslation('referrals'),
				key: 3,
			},
			{
				img: v2CampaignStandard,
				imgActive: v2CampaignWhite,
				name: 'campaigns',
				text: getTranslation('campaigns'),
				key: 4,
			},
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'enduser-card',
				text: getTranslation('cards'),
				key: 5,
			},
			{
				img: v2OrderAndPayStandard,
				imgActive: v2OrderAndPayWhite,
				name: 'wallet',
				text: getTranslation('wallet'),
				key: 6,
			},
		],
	},

	{
		img: v2SettingStandard,
		imgActive: v2SettingWhite,
		key: 11,
		name: 'settings',
		text: getTranslation('settings'),
		menus: [
			{
				img: v2AccountSubscriptionStandard,
				imgActive: v2AccountSubscriptionWhite,
				name: 'subscription',
				text: getTranslation('subscription'),
				key: 1,
			},
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'account',
				text: getTranslation('account'),
				key: 2,
			},
			{
				img: v2AccountVenueSpaceStandard,
				imgActive: v2AccountVenueSpaceWhite,
				name: 'space',
				text: getTranslation('configurations'),
				key: 3,
			},
			{
				img: v2AdvancedAccountStandard,
				imgActive: v2AdvancedAccountWhite,
				name: 'web',
				text: getTranslation('customizableBrandProfile'),
				key: 4,
			},
			{
				img: v2TableStandard,
				imgActive: v2TableWhite,
				name: 'hygiene-standard',
				text: getTranslation('hygieneStandards'),
				key: 5,
			},
		],
	},
	{
		img: v2LoyaltyStandard,
		imgActive: v2LoyaltyWhite,
		key: 12,
		name: 'loyalty',
		text: getTranslation('loyalty'),
		menus: [
			{
				img: v2LoyaltyProgramStandard,
				imgActive: v2LoyaltyProgramWhite,
				name: 'programs',
				text: getTranslation('programs'),
				key: 1,
			},
			{
				img: v2GuestsEnrolledStandard,
				imgActive: v2GuestsEnrolledWhite,
				name: 'guests-enrolled',
				text: getTranslation('guestsEnrolled'),
				key: 2,
			},
		],
	},
]
