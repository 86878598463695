import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Cookies from 'universal-cookie'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import ProgramsEmptyData from '../../../components/loyalty/ProgramsEmptyData'
import AddProgram from '../../../components/loyalty/addProgram'
import LoyaltyService from '../../../services/loyaltyService'
import { KEYS } from '../../../utils/storage'

export default function ProgramsScreen() {
	const [data, setData] = useState(null)
	const [loaded, setLoaded] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const cookies = new Cookies()
	const programId = cookies.get(KEYS.LOYALTY_PROGRAM_ID)
	useEffect(() => {
		// setData
		if (programId) {
			refreshData()
		}
	}, [])

	const refreshData = () => {
		setLoaded(false)
		LoyaltyService.getProgramDetails(programId)
			.then((res) => {
				setLoaded(true)
				setData(res.data)
				setShowModal(true)
			})
			.catch((err) => {
				setLoaded(true)
			})
	}
	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							{data || showModal ? (
								<AddProgram
									showModal={showModal}
									data={data}
									onClose={() => {}}
								/>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<ProgramsEmptyData
										content="Programs"
										onAction={() => {
											setShowModal(true)
										}}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
