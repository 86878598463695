import {
  Card,
  CardContent,
  CircularProgress,
  ImageList,
  ImageListItem,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../../assets/svgs/info_black.svg";
import { ImgDragUpload } from "../../../../components/common/ImgDragUpload";
import LoadingScreen from '../../../../components/custom/LoadingScreen';
import accommodationService from '../../../../services/accommodationService';
import VenueService from "../../../../services/venuService";
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil';

export const photosFormText = (text) =>
	t(`accommodation.rental_unit.show.photosForm.${text}`)

const PhotosForm = ({ data, rentalUnitId, facilities }) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] =
      useState([
          null, null, null, null, null, null,
          null, null, null, null, null, null,
          null, null, null, null, null, null,
          null, null, null, null, null, null,
          null, null, null, null, null, null,
      ]);

  const navigate = useNavigate();
  const { short_code } = useSelector((state) => state.app);
  const [updatingVrLink, setUpdatingVrLink] = useState(false);
  const [vrLink, setVrLink] = useState(
      data?.vr_link
          ? data?.vr_link
          : null
  );
  useEffect(() => {
    if (data?.gallery) {
      const tempImages = [...images];
      data?.gallery.forEach((item, index) => {
        tempImages[index] = item;
      });
      setImages(tempImages);
    }
  }, [data]);


  const onUploadImage = (image, index) => {
		let formData
		formData = new FormData()
		formData.append('type', 'gallery')
		if (image?.type === 'file' && image?.url != null) {
			formData.append('photo', image.file)
		}

		setLoading(true)
		accommodationService
			.UploadReantalUnitPhoto(formData, rentalUnitId)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'photo',
				})
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=3`)
					navigate(0)
				}, 1000)
				NotificationManager.success('Image has been uploaded successfully')
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('update Rental Units', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'photo',
				})
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				throw new Error('Problem on UploadReantalUnitPhoto')
			})
	}

	const onCancelImage = (index) => {
		setLoading(true)
		VenueService.deleteFacilitiesPhoto(images[index].photo_id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('delete facilities', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'photo',
				})
				setLoading(false)
				if (images[index]) {
					const tempImages = [...images]
					tempImages[index] = null
					setImages(tempImages)
				}
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('delete facilities', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'photo',
				})
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Unable do delete Photo')
			})
	}

  const onSubmitUpdateVRLink = () => {
    setUpdatingVrLink(true);
    const details = {
      type: "accommodation_vr_link",
      vr_link: vrLink,
    };
    accommodationService
        .UpdateReantalUnitAccomodationSetup(details, rentalUnitId)
        .then((data) => {
          
          identifyVenue(short_code)
					trackEvent('update Rental Units', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'Accomodation',
					})
          setUpdatingVrLink(false);
          NotificationManager.success(
              "Accommodation Virtual Tour Link has been updated successfully",
              "Success",
              3000
          );
          setTimeout(() => {
            navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=3`);
            navigate(0);
          }, 1000);
        })
        .catch((error) => {
          identifyVenue(short_code)
					trackEvent('update Rental Units', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'Accomodation',
					})
          setUpdatingVrLink(false);
          NotificationManager.error(
              error.message || "Something went wrong!",
              "Error",
              3000
          );
          throw new Error("Problem on UpdateReantalUnitAccomodationSetup");
        });
  };


  if (loading) {
    return <LoadingScreen />;
  }

  return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-6 pt-2 gap-10">
					<div className="relative started-form">
						<div>
							<p className={'text-base text-gray5 my-4'}>
								<span className="text-black1 text-20 font-bold">
									{photosFormText('whatDoesAccommodationLookLike')}
								</span>
							</p>
						</div>
						<div className="flex flex-row gap-12">
							<div className="w-[60%]">
								<div className="p-[22px] rounded-t-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										{photosFormText('uploadPhotos')}
									</p>
									<p className="text-[#707070] text-16 font-normal">
										{photosFormText('uploadTip')}
									</p>
								</div>
								<div className="p-[22px] rounded-b-[10px] border-[#E0E0E0] border-[1px]">
									<p className="text-black1 text-18 font-semibold leading-[35px]">
										{photosFormText('photos')}
									</p>
									<p className="text-[#707070] text-16 font-normal mb-6">
										{photosFormText('arrangePhotos')}
									</p>

									<ImageList
										variant="quilted"
										style={{ maxHeight: '550px', paddingRight: '10px' }}
										cols={3}
										gap={23}
									>
										{images.map((item, index) => (
											<ImageListItem key={`img_uploader_${index}`} className="">
												<ImgDragUpload
													backgroundColor="#fff"
													image={item ? { url: item.photo_path } : null}
													onCancel={() => onCancelImage(index)}
													onChange={(img) => onUploadImage(img, index)}
													label={photosFormText('dragDropLabel')}
													classes="max-h-[180px] p-2 mb-2 mt-2"
												/>
											</ImageListItem>
										))}
									</ImageList>
								</div>

								<hr className="mb-8 mt-4" />
								<div>
									<p className={'text-base text-gray5 my-4'}>
										<span className="text-black1 text-20 font-bold">
											{photosFormText('virtualTourLink')}
										</span>
									</p>
								</div>
								<div className="flex flex-row gap-12">
									<div className="flex flex-col w-[100%] gap-8">
										<div className="flex flex-col p-[22px] rounded-[10px] border-[#E0E0E0] border-[1px]">
											<p className="text-black1 text-18 font-semibold leading-[35px]">
												{photosFormText('hasVirtualTourLink')}
											</p>
											<div className="flex flex-col">
												<TextField
													required
													size="small"
													placeholder={photosFormText('enterVRLink')}
													value={vrLink}
													onChange={(e) => setVrLink(e.target.value)}
												/>
											</div>
										</div>
										<div className="flex flex-row justify-between mt-0 mb-4 h-[48px] gap-8">
											<button
												type="submit"
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												onClick={onSubmitUpdateVRLink}
											>
												{updatingVrLink ? (
													<CircularProgress size={16} />
												) : (
													photosFormText('update')
												)}
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} alt="icon" />
									</div>

									<h4 className="text-black1 text-18 font-bold leading-7">
										{photosFormText('showcaseSpace')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{photosFormText('showcaseSpaceDescription')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
};

export default PhotosForm;
