import { loadStripeTerminal } from '@stripe/terminal-js'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCurrencySymbolHelper } from '../../../helpers/venueHelpers'
import terminalService from '../../../services/terminalService'
import { emptyRetailCart, getRetailCart } from '../../../utils/storage'
// import "./index.css";

const RetailPay = () => {
	let navigate = useNavigate()

	const [isMobile, setIsMobile] = useState(false)
	const { short_code } = useSelector((state) => state.app)

	const [cart, setCart] = useState([])

	const [terminal, setTerminal] = useState({})

	let [simulator, setSimulator] = useState(true)

	let totalCost = 0

	cart.forEach((item) => {
		totalCost += item.quantity * item.price
	})

	useEffect(() => {
		setCart(getRetailCart())
	}, [])

	useEffect(() => {
		if (window.innerWidth < 768) setIsMobile(true)
		else setIsMobile(false)
	}, [window.innerWidth])

	useEffect(() => {
		initializeBackendClientAndTerminal()
	}, [])

	const startConnection = async () => {
		let connectionTokenResult = await terminalService.createConnection()
		return connectionTokenResult.data.secret
	}

	function unexpectedDisconnect() {
		// You might want to display UI to notify the user and start re-discovering readers
	}

	const initializeBackendClientAndTerminal = async () => {
		const StripeTerminal = await loadStripeTerminal()

		let create = await StripeTerminal.create({
			onFetchConnectionToken: await startConnection,
			onUnexpectedReaderDisconnect: await unexpectedDisconnect,
		})

		setTerminal((old) => {
			return create
		})
		if (simulator) connectToSimulator(create)
	}

	const connectToSimulator = async (terminal) => {
		const simulatedResult = await terminal.discoverReaders({
			simulated: true,
		})
		let done = await connectToReader(
			terminal,
			simulatedResult.discoveredReaders[0]
		)
	}

	const connectToReader = async (terminal, selectedReader) => {
		// 2b. Connect to a discovered reader.
		const connectResult = await terminal.connectReader(selectedReader)
		if (connectResult.error) {
		} else {
			alert('Device Connected')

			return connectResult
		}
	}

	const collectCardPayment = async () => {
		if (terminal) {
			let createIntentResponse = await terminalService.createPaymentIntent({
				amount: totalCost * 100,
				description: 'Payment from POS Retail',
				payment_method_types: ['card_present'],
			})

			const paymentMethodPromise = await terminal.collectPaymentMethod(
				createIntentResponse?.data?.client_secret
			)

			if (paymentMethodPromise.error) {
			} else {
				const confirmResult = await terminal.processPayment(
					paymentMethodPromise.paymentIntent
				)

				if (confirmResult?.paymentIntent?.status === 'succeeded') {
					let state = {
						confirmResult,
						currency: getCurrencySymbolHelper(
							cart?.length ? cart[0]?.currency : 'USD'
						),
					}

					emptyRetailCart()

					navigate(`/${short_code}/admin/pos/retail/success`, {
						state,
					})
				}
			}
		} else {
			alert('Device not connected')
		}
	}

	return (
		<div
			className={`relative overflow-auto h-full w-full bg-[#F9F7F7] flex 
      flex-col md:flex-row`}
		>
			{isMobile && (
				<div
					className="flex flex-col justify-center items-center md:hidden align-middle w-full text-[48px] text-[#240b3b] font-bold py-12"
					onClick={() => collectCardPayment()}
				>
					<span className="font-normal">↑</span>
					<span>Tap</span>
				</div>
			)}
			<div
				className={`w-full px-6 md:w-[60vw] md:h-full md:px-[47px] bg-[#240b3b]  py-6 `}
			>
				{/*<div className="flex md:inline-flex md:px-3 cursor-pointer py-2 items-center gap-2 md:border md:border-white rounded-md">*/}
				{/*   <span*/}
				{/*      // onClick={() => setIsOpen(false)}*/}
				{/*      className="text-white"*/}
				{/*   >*/}
				{/*      <KeyboardBackspaceOutlinedIcon />*/}
				{/*   </span>*/}
				{/*   <span*/}
				{/*      className={`text-[18px] md:text-[20px] w-full text-center md:text-start text-white font-semibold leading-[140%]`}*/}
				{/*   >*/}
				{/*      All payment methods*/}
				{/*   </span>*/}
				{/*</div>*/}

				<h1
					className={`${
						isMobile ? 'text-[40px]' : 'text-[48px]'
					} text-white  font-bold leading-[130%] py-8`}
				>
					Pay{' '}
					{getCurrencySymbolHelper(cart?.length ? cart[0]?.currency : 'USD')}{' '}
					{totalCost}
				</h1>
				<div>
					{cart?.map((item, index) => {
						return (
							<div
								key={index}
								className="flex justify-between py-2 flex-row items-center border-y-[1px] border-slate-700"
							>
								<div className="flex justify-start py-2  items-center">
									<div className="w-10 h-10 text-16 bg-[#F7F8F9] font-semibold align-middle rounded-[4px] mr-2">
										{item?.quantity}X
									</div>
									<div className="flex flex-col justify-between">
										<p className="text-white text-16 font-medium">
											{item?.title}
										</p>
										<p className="text-[#ABBBC2] text-14 font-medium">
											{item?.quantity} X{' '}
											{getCurrencySymbolHelper(item?.currency)} {item?.price}
										</p>
									</div>
								</div>
								<p className="text-16 font-medium leading-[140%] text-white ">
									{getCurrencySymbolHelper(item?.currency)}
									{item?.quantity * item?.price}
								</p>
							</div>
						)
					})}

					<div className="py-4 border-slate-700 border-b-[1px]">
						<div className="flex justify-between py-2">
							<p className="text-[#E5EAFF] text-14 font-normal ">Discount</p>
							<p className="text-white text-14 font-medium leading-[130%]">
								{getCurrencySymbolHelper(
									cart?.length ? cart[0]?.currency : 'USD'
								)}
								0
							</p>
						</div>
						<div className="flex justify-between py-2">
							<p className="text-[#E5EAFF] text-14 font-normal ">Sub total</p>
							<p className="text-white text-14 font-medium leading-[130%]">
								{getCurrencySymbolHelper(
									cart?.length ? cart[0]?.currency : 'USD'
								)}
								{totalCost}
							</p>
						</div>
					</div>
					<div
						className={`${
							isMobile ? 'py-4' : ' pt-4 pb-12'
						} border-slate-700 border-b-[1px]`}
					>
						<div className="flex justify-between py-2">
							<p className="text-[#E5EAFF] text-14 font-normal ">Total</p>
							<p className="text-white text-14 font-medium leading-[130%]">
								{getCurrencySymbolHelper(
									cart?.length ? cart[0]?.currency : 'USD'
								)}
								{totalCost}
							</p>
						</div>
					</div>
				</div>
				<div
					className={`${
						isMobile ? 'mt-4' : 'absolute'
					} text-center  text-white text-14 border-[1px] bottom-6 px-[12px] py-[7px]  rounded-[5px] w-fit`}
				>
					♺ &nbsp; English
				</div>
			</div>
			<div className={`w-full h-full  md:w-[40vw]  bg-[#EFEFEF]`}>
				<div
					className="!hidden md:!flex align-middle w-full h-[50vh] text-[48px] text-[#240b3b] font-bold leading-[288%]"
					onClick={() => collectCardPayment()}
				>
					Tap →
				</div>
				<a
					className={`
             text-[36px] h-full md:h-[50vh] md:text-[48px]"
          } w-full bg-[#F9F7F7]  align-middle  text-[#240b3b] font-bold leading-[288%]`}
					// href={`/${short_code}/admin/pos/retail/success`}
				>
					Insert →
				</a>
			</div>
		</div>
	)
}

export default RetailPay
