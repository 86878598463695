import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import MenuService from '../../../services/menuService'
import retailService from '../../../services/retailService'
import { isEmpty } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { productText } from '../../../views/menu/products'
import LoadingScreen from '../../custom/LoadingScreen'
import ProductAttribues from './productAttributes'
import ProductGeneralInfo from './productGeneralInfo'
import ProductInventory from './productInventory'
import ProductVariations from './productVariations'

export default function AddEditProductRetail({ isComposed = false }) {
	const navigate = useNavigate()
	const { id: product_id } = useParams()
	// const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState(null)
	const [categories, setCategories] = useState([])
	const [subCategories, setSubCategories] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [attributes, setAttributess] = useState([])
	const [variations, setVariations] = useState([])
	const [dropdownVariations, setDropdownVariations] = useState([])
	const [index, setIndex] = useState(0)
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		loadData()
		loadInitalValues()

		identifyVenue(short_code)

		if (product_id == null) {
			trackEvent('Create Product', {
				action_category: 'navigation',
				action_outcome: 'arrived',
				interaction_element: 'screen',
			})
		} else {
			trackEvent('Update Product', {
				action_category: 'navigation',
				action_outcome: 'arrived',
				interaction_element: 'screen',
			})
		}
	}, [product_id])

	const loadData = () => {
		if (product_id == null) {
			return
		}
		MenuService.getProductDetails(product_id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				setState(res?.product || {})
				setAttributess(res?.product?.attributes || [])
				setVariations(res?.product?.variations || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'products',
				})
			})

		MenuService.getVariationsAtProduct(product_id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'variations',
				})
				setDropdownVariations(res?.data || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'variations',
				})
			})
	}

	const loadInitalValues = () => {
		MenuService.getCategories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				const parentCategories = (res?.categories || []).filter(
					(category) => !category?.parent?.id
				)
				const subFinalCategories = (res?.categories || []).filter(
					(category) => category.parent && category.parent.id !== null
				)

				setCategories(parentCategories)
				setSubCategories(subFinalCategories) // Assuming you have another state variable for subcategories
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
			})
		retailService
			.getSuppliers()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('retail service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'suppliers',
				})
				setSuppliers(res?.suppliers || [])
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('retail service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'suppliers',
				})
			})
	}
	const onSubmitGeneral = (data) => {
		let payload = {}
		if (!product_id) {
			identifyVenue(short_code)
			trackEvent('Create Product', {
				action_category: 'click',
				action_outcome: 'submit',
				interaction_element: 'button',
			})

			payload = {
				...data,
				order_method: 'Delivery-Pickup-Reserve',
				available: data?.available == 1 ? 1 : 0,
				price: isEmpty(data.price) ? 0 : data.price,
				category_id: data.category?.id,
				parent_category_id: data.parent?.id,
				is_for_retail: 1,
			}
		} else {
			identifyVenue(short_code)
			trackEvent('Update Product', {
				action_category: 'click',
				action_outcome: 'submit',
				interaction_element: 'button',
			})

			payload = {
				...data,
				id: product_id,
				order_method: 'Delivery-Pickup-Reserve',
				available: data?.available == 1 ? 1 : 0,
				price: isEmpty(data.price) ? 0 : data.price,
				category_id: data.category?.id,
				parent_category_id: data.parent?.id,
				is_for_retail: 1,
			}
		}
		let formData
		formData = new FormData()
		if (data?.image?.type === 'file' && data?.image?.url != null) {
			formData.append('image', data?.image.file)
		}
		// Append other payload data to the formData
		for (const key in payload) {
			formData.append(key, payload[key])
		}

		setLoading(true)
		MenuService.storeProduct(formData)
			.then((res) => {
				if (product_id == null) {
					identifyVenue(short_code)
					trackEvent('Create Product', {
						action_category: 'creation',
						action_outcome: 'success',
						interaction_element: 'product',
					})
					navigate(-1)
				} else {
					NotificationManager.success(
						res?.message ?? 'Product has been updated successfully'
					)

					identifyVenue(short_code)
					trackEvent('Update Product', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'product',
					})

					setLoading(false)
					loadData()
					navigate(0)
				}
			})
			.catch((err) => {
				setLoading(false)
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
			})
	}

	const onSubmitInventory = (data) => {
		if (!data?.stock_quantity) {
			NotificationManager.warning(productText('stock_quantity_required'))
			return
		}
		let payload = {}
		payload = {
			product_id: product_id,
			manage_stock: data?.manage_stock,
			sold_individually: data?.sold_individually,
			low_stock_threshold: data?.low_stock_threshold,
			stock_quantity: data?.stock_quantity,
			sku: data?.sku,
			article_no: data?.article_no,
			supplier_id: data?.supplier_id,
			allow_backorders: data?.allow_backorders,
		}
		setLoading(true)
		MenuService.storeProductInventory(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				if (product_id == null) {
					NotificationManager.success(
						res?.message ?? productText('inventory_updated_successfully')
					)
					navigate(-1)
				} else {
					NotificationManager.success(
						res?.message ?? 'Inventory has been updated successfully'
					)
					setLoading(false)
					// loadData();
					navigate(0)
				}
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				setLoading(false)
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || t('common.error_generic')
						)
					} else {
						NotificationManager.error(t('common.error_generic'))
					}
				}
			})
	}

	const onTabClicked = (event, index) => {
		setIndex(index)
	}

	if (loading) {
		return <LoadingScreen />
	}
	return (
		<div className="flex flex-col h-full p-6">
			<Card className="!overflow-auto">
				<CardContent className="!p-0">
					<div className="justify-start items-center py-4 px-5">
						<p className=" text-xl font-medium mb-0">
							{product_id != null ? 'Manage' : 'Create'}{' '}
							{productText('Product')}
						</p>
						<p className="text-black1 text-16 font-medium mt-2">
							{productText('configure_product_stock_variants')}
						</p>
					</div>
					<div className="flex flex-row items-center"></div>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={index}
							onChange={onTabClicked}
							className="pt-0 pr-4 pl-4 pb-2"
							textColor="inherit"
							// indicatorColor="secondary"
							TabIndicatorProps={{
								style: {
									backgroundColor: '#666A73',
								},
							}}
						>
							<Tab label={productText('General')} />
							{product_id ? <Tab label={productText('Inventory')} /> : null}
							{product_id ? <Tab label={productText('Attributes')} /> : null}
							{product_id ? <Tab label={productText('Variations')} /> : null}
						</Tabs>
						<Panel value={index} index={0}>
							<ProductGeneralInfo
								currency={state?.currency}
								data={state}
								product_id={product_id}
								categories={categories}
								subCategories={subCategories}
								onSubmitForm={onSubmitGeneral}
								onRefresh={() => loadData()}
								isComposed={isComposed}
							/>
						</Panel>
						{product_id ? (
							<Panel value={index} index={1}>
								<ProductInventory
									data={state?.inventory_retail}
									supp
									product_id={product_id}
									suppliers={suppliers}
									onSubmitForm={onSubmitInventory}
								/>
							</Panel>
						) : null}
						{product_id ? (
							<Panel value={index} index={2}>
								<ProductAttribues
									data={attributes}
									product_id={product_id}
									onRefresh={() => loadData()}
								/>
							</Panel>
						) : null}
						{product_id ? (
							<Panel value={index} index={3}>
								<ProductVariations
									currency={state?.currency}
									data={variations}
									product_id={product_id}
									attributes={dropdownVariations}
									onRefresh={() => loadData()}
								/>
							</Panel>
						) : null}
					</Box>
				</CardContent>
			</Card>
		</div>
	)
}

const Panel = (props) => (
	<div
		hidden={props.value !== props.index}
		className="row-span-2 bg-white rounded-12 m-4 border border-gray-200"
	>
		<div>{props.children}</div>
	</div>
)
