import "react-datepicker/dist/react-datepicker.css";
import { createUUID } from "../../../utils/common";
import Paper from "@mui/material/Paper";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import order_history from '../../../assets/svgs/order_history.svg'
import { useState } from "react";

export function OrderHistoryBlock({ data }) {
    const [ dummyData, setDummyData] = useState([
        {status: 'Pending', date: '20/10/2023 22:20 PM'},
        {status: 'Prepared', date: '20/10/2023 22:20 PM'},
        {status: 'Completed', date: '20/10/2023 22:20 PM'}
    ])
    return (
        <div className="flex flex-col mt-4 w-[35%]">
            <p className=' mb-2 text-black1 text-lg font-medium'>Order History</p>
            <div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
                <div className="p-4 bg-[#F9F7F7] rounded-lg">
                    <img src={order_history} alt="desk" />
                </div>
                <div className="flex-1 flex flex-col gap-10">
                    <div className="flex flex-col justify-center align-center gap-1 order-history-container">
                        {data.map((item, index) => {
                            return (
                        <div className="flex flex-col pb-4">
                            <div className={`${(index == 0 || (index == data.length - 1)) ? 'order-history-item' : ''}`}></div>
                                <span className={`text-sm ${item.status == 'Pending' ? 'text-[#BC5238]' : item.status == 'Completed' ? 'text-[#027A48]' : 'text-black1 font-bold'}`}>{item.status}</span>
                                <span className="text-gray5 text-base font-bold">{item.date}</span>
                        </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}