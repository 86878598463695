import { Card, CardContent, TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import NewStaffPerformance from '../../../components/report/staff/newStaffPerformance'
import TableEmptyData from '../../../components/table/TableEmptyData'
import ReportService from '../../../services/reportService'
import StaffService from '../../../services/staffService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export default function SalesReport() {
	const [data, setData] = useState([])
	const [addModal, setAddModal] = useState(false)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [employee, setEmployee] = useState(0)
	const [employeeOptions, setEmployeeOptions] = useState([])
	const [loaded, setLoaded] = useState(false)
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		StaffService.ReadAll().then((res) => {
			setEmployeeOptions(
				res.data.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				})
			)
		})

		refreshData()
	}, [])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const refreshData = () => {
		ReportService.GetSales()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('report service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'sales',
				})
				setLoaded(true)

				let titles = res.title
				let quantities = res.total_quantity
				let sales = res.total_sales
				let raw = []

				for (let i = 0; i < titles.length; i++) {
					raw.push({
						name: titles[i],
						total_quantity: quantities[i],
						total_sales: sales[i],
					})
				}
				setData(raw)
			})
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('report service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'sales',
				})
				setLoaded(true)
			})
	}

	const confirmAdd = (location) => {}

	return (
		<div>
			<div className="flex flex-col h-full">
				{loaded ? (
					<div className="flex flex-col p-6  overflow-auto">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											Sales Report
										</span>
									</div>
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setAddModal(true)}
									>
										<span>Generate Sales Report</span>
									</div>
								</div>
								{data.length ? (
									<div className="flex flex-col overflow-auto">
										<TableContainer component={Paper} className="mt-4">
											<Table sx={{ minWidth: 650 }} aria-label="simple table">
												<TableHead>
													<TableRow className="bg-gray7">
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															Name
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															Total quantity
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															Total sales
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{data.map((row, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base"
															>
																{row.name}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base"
															>
																{row.total_quantity}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3 !text-gray5 !text-left !text-base "
															>
																{row.total_sales}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={data.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</div>
								) : (
									<div className="border border-x-0 border-b-0 mt-5 mb-10">
										<TableEmptyData
											content="report"
											btnLabel="Generate Sales Report"
											btnIcon={false}
											onAction={() => setAddModal(true)}
										/>
									</div>
								)}
							</CardContent>
						</Card>
					</div>
				) : (
					<LoadingScreen />
				)}
				<NewStaffPerformance
					showModal={addModal}
					onClose={() => setAddModal(false)}
					onConfirm={confirmAdd}
				/>
			</div>
		</div>
	)
}
