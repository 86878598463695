import React from 'react'

const BestSellerProducts = ({data = []}) => {
	return (
		<div className='bg-white p-4 w-full flex flex-col'>
			<div className="w-full">
				<span className=" font-bold text-18 text-black1 leading-6 ">Bestseller Products</span>
				<div className="text-12">
					Table below show all top selling products
				</div>
			</div>
			<div className='w-full mt-5'>
				{
					data.map(p =>
						<div className='mt-3 w-full flex justify-center items-center'>
							<img alt='' src={p.image_path} className={'w-10 h-10 mr-4'} />
							<div className='flex-1'>
								<h4 className=' text-16 text-black1 font-semibold'>{p.title}</h4>
								<p className='text-12 text-black1 font-medium'>{p.brand_title}</p>
							</div>
							<div className='text-16 text-black1 font-semibold pr-2'>
								{p.order_count} here
							</div>
						</div>
					)
				}
			</div>
		</div>
	)
}

export default BestSellerProducts
