import { Card, CardContent, TablePagination } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import plusIcon from '../../assets/svgs/plus.svg'
import AreaModal from '../../components/beachbar/area-modal'
import AreYouSureModal from '../../components/common/areYouSureModal'
import CustomDateTimePicker from '../../components/custom/CustomDateTimePicker'
import CustomMUISelect from '../../components/custom/CustomSelect/custom-select'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericTable from '../../components/generic-table/generic-table'
import TableEmptyData from '../../components/table/TableEmptyData'
import { orderTransformer } from '../delivery/orders'
import { areas } from './dummyData'

export const umbrellasText = (text) => t(`beachBar.umbrellas.${text}`)

const BeachbarArea = ({ isArea }) => {
	const navigate = useNavigate()
	const [showAddArea, setShowAddArea] = useState(false)
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [loaded, setLoaded] = useState(true)
	const { short_code } = useSelector((state) => state.app)
	const [selectedUmbrella, setUmbrellaSelected] = useState()
	const [recordDetails, setRecordDetails] = useState({})
	const { id: beachArea_id } = useParams()

	const [areaDetails, setAreaDetails] = useState({
		search: '',
		start: null,
		end: null,
		status: '',
		area: '',
	})

	const status = [
		{ value: 'new', label: 'New' },
		{ value: 'processing', label: 'Processing' },
		{ value: 'complete', label: 'Complete' },
	]

	const umbrellas = [
		{
			id: 1,
			title: 'Umbrella 1',
			description: 'Main Description',
			price: 500,
			area: 'Area 1',
			price_rate_type: 'Entire',
			status: 'new',
			img_url:
				'https://venueboost-production-venue-gallery-photos.s3.amazonaws.com/venue_gallery_photos/restaurant/product/uproot-restaurant-upr01scd/aIFvjhsSb1ZPrJNlAlFo.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYN36CX6XW6Z2N7B2%2F20240324%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240324T222645Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Signature=0fb3d1a719b3017c8aa88db8d1f251b7ba0097d1c300a58392d50b445cb8f27c',
		},
		{
			id: 2,
			title: 'Umbrella 2',
			description: 'Main Description',
			price: 350,
			area: 'Area 2',
			price_rate_type: 'Per seat',
			status: 'new',
			img_url:
				'https://venueboost-production-venue-gallery-photos.s3.amazonaws.com/venue_gallery_photos/restaurant/product/uproot-restaurant-upr01scd/aIFvjhsSb1ZPrJNlAlFo.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYN36CX6XW6Z2N7B2%2F20240324%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240324T222645Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Signature=0fb3d1a719b3017c8aa88db8d1f251b7ba0097d1c300a58392d50b445cb8f27c',
		},
		{
			id: 3,
			title: 'Umbrella 3',
			description: 'Main Description',
			price: 350,
			area: 'Area 3',
			price_rate_type: 'Entire',
			status: 'processing',
			img_url:
				'https://venueboost-production-venue-gallery-photos.s3.amazonaws.com/venue_gallery_photos/restaurant/product/uproot-restaurant-upr01scd/aIFvjhsSb1ZPrJNlAlFo.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYN36CX6XW6Z2N7B2%2F20240324%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240324T222645Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Signature=0fb3d1a719b3017c8aa88db8d1f251b7ba0097d1c300a58392d50b445cb8f27c',
		},
	]

	const columns = [
		{
			label: umbrellasText('image'),
			field: 'img_url',
			align: 'center',
			cellComponent: (row) => {
				return (
					<img
						src={row.data}
						style={{ borderRadius: '5px' }}
						className=" w-9 h-9"
						alt={umbrellasText('preview')}
					/>
				)
			},
		},
		{ label: umbrellasText('no'), field: 'id', align: 'left' },
		{ label: umbrellasText('title'), field: 'title', align: 'left' },
		{ label: umbrellasText('area'), field: 'area', align: 'left' },
		{
			label: umbrellasText('rate_type'),
			field: 'price_rate_type',
			align: 'left',
		},
		{
			label: umbrellasText('description'),
			field: 'description',
			align: 'right',
		},
		{
			label: umbrellasText('status'),
			field: 'status',
			align: 'right',
			cellComponent: (item) => {
				return (
					<div
						style={{
							backgroundColor: `${orderTransformer[item.data]?.bgColor}`,
						}}
						className={`font-semibold text-[16px] text-cyan1 border align-center rounded-[10px] cursor-pointer px-[16px] w-[120px]`}
					>
						<span
							className={`flex justify-center items-center text-[14px] font-bold p-2`}
							style={{
								color: orderTransformer[item.data]?.textColor,
							}}
						>
							{orderTransformer[item.data]?.readable}
						</span>
					</div>
				)
			},
		},
		{ label: umbrellasText('price'), field: 'price', align: 'right' },
		{ label: umbrellasText('actions'), field: 'actions', align: 'right' },
	]

	const handleChangePage = (event, newPage) => setPage(newPage)

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onEdit = (row) => {
		navigate(`/${short_code}/admin/beachbar/umbrellas/edit/${row.id}`)
		setRecordDetails(row)
	}

	const onMoreDetails = (row) => {
		console.log(row, 'row')
	}

	const onCreate = () => {
		navigate(`/${short_code}/admin/beachbar/umbrellas/create`)
	}

	const onDelete = (id) => {
		setUmbrellaSelected(id)
		setAreYouSureDeleteModalOpen(true)
	}

	return loaded ? (
		<div className="flex flex-col h-full p-6">
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
						<div className="flex flex-row items-center">
							<span className="text-black1 text-xl font-medium">
								{umbrellasText('umbrellas')}
							</span>
							<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
								{umbrellas.length} {umbrellasText('umbrellas')}
							</span>
						</div>
						<div
							style={{ display: 'flex', gap: '16px', alignItems: 'flex-end' }}
						>
							{!isArea && (
								<CustomMUISelect
									data={areas}
									onChange={(e) =>
										setAreaDetails((prev) => ({
											...prev,
											area: e.target.value,
										}))
									}
									placeholder={umbrellasText('select_area')}
									value={areaDetails.area}
								/>
							)}
							<div className="flex flex-row border rounded-lg px-3">
								<input
									className=" w-[220px] max-w-[80vw] h-[40px] px-2 outline-none"
									placeholder={umbrellasText('search_area')}
									value={areaDetails.search}
									onChange={(e) =>
										setAreaDetails((prev) => ({
											...prev,
											search: e.target.value,
										}))
									}
								/>
							</div>
							<CustomDateTimePicker
								onlyDate={true}
								value={areaDetails.start}
								onChange={(e) =>
									setAreaDetails((prev) => ({
										...prev,
										start: e,
									}))
								}
								placeholder={umbrellasText('start_date')}
							/>
							<CustomMUISelect
								data={status}
								onChange={(e) =>
									setAreaDetails((prev) => ({
										...prev,
										status: e.target.value,
									}))
								}
								placeholder={umbrellasText('select_status')}
								value={areaDetails.status}
							/>
							<div
								className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
								onClick={onCreate}
							>
								<img src={plusIcon} alt="search" className="mr-2" />
								<span>{umbrellasText('add_umbrella')}</span>{' '}
								{/* Replace "Add Umbrella" */}
							</div>
						</div>
					</div>
					<AreYouSureModal
						title={umbrellasText('delete_umbrella')}
						content={umbrellasText('delete_umbrella_confirmation')}
						firstButton={umbrellasText('no_go_back')}
						secondButton={umbrellasText('yes_i_am')}
						showModal={isAreYouSureDeleteModalOpen}
						onClose={() => setAreYouSureDeleteModalOpen(false)}
						onSubmit={() => onDelete(selectedUmbrella)}
					/>

					{umbrellas && umbrellas.length > 0 ? (
						<div className="flex flex-col overflow-auto max-h-[70vh]">
							<GenericTable
								columns={columns}
								data={umbrellas}
								onDelete={onDelete}
								onEdit={onEdit}
								onView={onMoreDetails}
							/>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={umbrellas.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</div>
					) : (
						<div className="border border-x-0 border-b-0 mt-5 mb-10">
							<TableEmptyData
								content="umbrellas"
								btnLabel="Add Umbrella"
								onAction={onCreate}
							/>
						</div>
					)}
					{showAddArea && (
						<AreaModal
							recordDetails={recordDetails}
							setRecordDetails={setRecordDetails}
							umbrellas={umbrellas}
							showModal={showAddArea}
							setShowModal={setShowAddArea}
							onClose={() => {
								setShowAddArea(false)
								setRecordDetails(undefined)
							}}
						/>
					)}
				</CardContent>
			</Card>
		</div>
	) : (
		<LoadingScreen />
	)
}

export default BeachbarArea
