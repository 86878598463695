import { Box, Modal } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import saleIcon from '../../../assets/svgs/sale_white.svg'
import CustomFormInput from '../../../components/custom/CustomInput/custom-form-input'
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select'
import ConfirmationFooter from '../../../components/footer/ConfirmationFooter'
import { boxStyle } from '../../../constants/types'
import { isCursorPointer } from '../../../helpers/is-cursor-pointer'
import orderService from '../../../services/orderService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { initialCustomerDetails } from './create-order'

export const deliveryCustomersModalText = (text) =>
	t(`customers.delivery.modal.${text}`)

export default function CustomerModal({
	visible,
	onClose,
	customerDetails,
	setCustomerDetails,
	selectedCustomer,
	customersData,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [open, setOpen] = useState(false)

	const handleCreateCustomer = (payload) => {
		if (selectedCustomer) {
			setCustomerDetails((prev) => ({
				...prev,
				customer: {
					...prev.customer,
					id: selectedCustomer.id,
				},
			}))
			NotificationManager.success(deliveryCustomersModalText('customers_added'))
		} else {
			orderService
				.createCustomer(payload)
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('order service', {
						action_category: 'create',
						action_outcome: 'success',
						interaction_element: 'customers',
					})
					setCustomerDetails((prev) => ({
						...prev,
						customer: {
							...prev.customer,
							id: res.customer_id,
						},
					}))
					NotificationManager.success(
						deliveryCustomersModalText('customers_added')
					)
				})
				.catch(() => {
					NotificationManager.error(t('common.error_generic'))
					identifyVenue(short_code)
					trackEvent('order service', {
						action_category: 'create',
						action_outcome: 'fail',
						interaction_element: 'customers',
					})
				})
		}
		onClose()
	}

	const handleSelectChange = (event) => {
		const { value } = event.target
		setCustomerDetails((prev) =>
			!value
				? initialCustomerDetails
				: {
						...prev,
						customer: {
							...prev.customer,
							id: value,
						},
				  }
		)
	}

	const isDisabled =
		customerDetails.customer.id && customerDetails.customer.id > -1

	const customerOptions = useMemo(() => {
		return customersData.map((item) => ({
			label: item.name,
			value: item.id,
		}))
	}, [customersData])

	const clearOption = useMemo(() => {
		if (open)
			return [
				{
					label: 'Clear selection',
					value: undefined,
				},
			]
		return []
	}, [open])

	useEffect(() => {
		if (selectedCustomer) {
			const { address } = selectedCustomer
			setCustomerDetails({
				customer: {
					first_name: selectedCustomer?.name,
					last_name: selectedCustomer?.last_name,
					phone: selectedCustomer?.phone,
					email: selectedCustomer?.email,
					id: selectedCustomer?.id,
				},
				shipping_address: {
					line1: address?.line1,
					line2: address?.line2,
					city: address?.city,
					state: address?.state,
					postal_code: address?.postal_code,
				},
			})
		}
	}, [selectedCustomer])

	return (
		<Modal
			open={visible}
			onClose={onClose}
			className="schedule-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none">
				<div className="flex flex-row items-center px-024 pb-4 pt-024 gap-3">
					<div className="p-2 bg-gray7 rounded-full">
						<div className="bg-black1 p-2 rounded-full">
							<img src={saleIcon} alt="icon" />
						</div>
					</div>
					<div>
						<label className="text-20 font-semibold text-black1">
							{deliveryCustomersModalText('newCustomer')}
						</label>
					</div>
				</div>
				<div className="flex flex-col px-024 mb-4 gap-3">
					<CustomFormSelect
						title={deliveryCustomersModalText('customerDetails')}
						placeholder={deliveryCustomersModalText('customerDetails')}
						onChange={handleSelectChange}
						value={customerDetails.customer.id}
						onClose={() => setOpen(false)}
						onOpen={() => setOpen(true)}
						options={[...clearOption, ...customerOptions]}
					/>

					<div className="grid grid-cols-2 gap-2">
						<CustomFormInput
							disabled={isDisabled}
							value={customerDetails.customer.first_name}
							onChange={(e) =>
								setCustomerDetails((prev) => ({
									...prev,
									customer: {
										...prev.customer,
										first_name: e.target.value,
									},
								}))
							}
							title={deliveryCustomersModalText('firstName')}
							placeholder={deliveryCustomersModalText('firstName')}
						/>

						<CustomFormInput
							required
							title={deliveryCustomersModalText('lastName')}
							placeholder={deliveryCustomersModalText('lastName')}
							size="medium"
							type="text"
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={customerDetails.customer.last_name}
							onChange={(e) =>
								setCustomerDetails((prev) => ({
									...prev,
									customer: {
										...prev.customer,
										last_name: e.target.value,
									},
								}))
							}
						/>
						<CustomFormInput
							required
							title={deliveryCustomersModalText('phoneNumber')}
							placeholder={deliveryCustomersModalText('phoneNumber')}
							size="medium"
							type="number"
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={selectedCustomer?.phone ?? customerDetails.customer.phone}
							onChange={(e) =>
								setCustomerDetails((prev) => ({
									...prev,
									customer: {
										...prev.customer,
										phone: e.target.value,
									},
								}))
							}
						/>
						<CustomFormInput
							required={true}
							title={deliveryCustomersModalText('email')}
							placeholder={deliveryCustomersModalText('email')}
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={selectedCustomer?.email ?? customerDetails.customer.email}
							onChange={(e) =>
								setCustomerDetails((prev) => ({
									...prev,
									customer: {
										...prev.customer,
										email: e.target.value,
									},
								}))
							}
						/>
					</div>

					<div className="grid grid-cols-2 gap-2">
						<CustomFormInput
							required={true}
							title={deliveryCustomersModalText('addressLine1')}
							placeholder={deliveryCustomersModalText('addressLine1')}
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={
								selectedCustomer?.shipping_address?.line1 ??
								customerDetails.shipping_address?.line1
							}
							onChange={(e) =>
								setCustomerDetails((prevData) => ({
									...prevData,
									shipping_address: {
										...prevData.shipping_address,
										line1: e.target.value,
									},
								}))
							}
						/>
						<CustomFormInput
							title={deliveryCustomersModalText('addressLine2')}
							placeholder={deliveryCustomersModalText('addressLine2')}
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={
								selectedCustomer?.shipping_address?.line2 ??
								customerDetails.shipping_address?.line2
							}
							onChange={(e) =>
								setCustomerDetails((prevData) => ({
									...prevData,
									shipping_address: {
										...prevData.shipping_address,
										line2: e.target.value,
									},
								}))
							}
						/>
						<CustomFormInput
							title={deliveryCustomersModalText('state')}
							placeholder={deliveryCustomersModalText('state')}
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={
								selectedCustomer?.shipping_address?.state ??
								customerDetails.shipping_address?.state
							}
							onChange={(e) =>
								setCustomerDetails((prevData) => ({
									...prevData,
									shipping_address: {
										...prevData.shipping_address,
										state: e.target.value,
									},
								}))
							}
						/>
						<CustomFormInput
							title={deliveryCustomersModalText('city')}
							placeholder={deliveryCustomersModalText('city')}
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={
								selectedCustomer?.shipping_address?.city ??
								customerDetails.shipping_address?.city
							}
							onChange={(e) =>
								setCustomerDetails((prevData) => ({
									...prevData,
									shipping_address: {
										...prevData.shipping_address,
										city: e.target.value,
									},
								}))
							}
						/>
						<CustomFormInput
							title={deliveryCustomersModalText('postalCode')}
							placeholder={deliveryCustomersModalText('postalCode')}
							disabled={isDisabled}
							className={`${isCursorPointer(isDisabled)} !h-12`}
							value={
								selectedCustomer?.shipping_address?.postal_code ??
								customerDetails.shipping_address?.postal_code
							}
							onChange={(e) =>
								setCustomerDetails((prevData) => ({
									...prevData,
									shipping_address: {
										...prevData.shipping_address,
										postal_code: e.target.value,
									},
								}))
							}
						/>
					</div>
				</div>

				<ConfirmationFooter
					onClose={onClose}
					onSubmit={() => handleCreateCustomer(customerDetails)}
					title={deliveryCustomersModalText('continue')}
					isDisabled={customerDetails.customer.first_name.length === 0}
				/>
			</Box>
		</Modal>
	)
}
