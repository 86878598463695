import { isEmpty } from '../../../utils/common'


export const currencySymbols = {
	ALL: 'Lek',
	USD: '$',
	EUR: '€',
	// Add other currencies and their symbols here
}

const getCurrencySymbol = (currencyCode) => {
	return currencySymbols[currencyCode] || ''
}

export const columns = (productText) => [
	{
		label: productText('image'),
		field: 'product',
		align: 'left',
		cellComponent: ({ data }) => (
			<div>
				{isEmpty(data?.image_path) ? (
					<div className="w-9 h-9 bg-gray6" style={{ borderRadius: '5px' }} />
				) : (
					<img
						src={data?.image_path}
						style={{ borderRadius: '5px' }}
						className="w-9 h-9"
						alt="preview"
					/>
				)}
			</div>
		),
	},
	{ label: productText('title'), field: 'product_title', align: 'left' },
	{
		label: productText('brand_name'), field: 'brand', align: 'left',
		cellComponent: ({ data, row }) => data?.title,
	},
	{
		label: productText('type'), field: 'product', align: 'left',
		cellComponent: ({ data, row }) => data?.product_type,
	},
	{ field: 'sku', label: 'SKU', align: 'left' },
	{
		label: productText('price'),
		field: 'product',
		align: 'left',
		cellComponent: ({ data, row }) => (
			<div>
				{data?.price} Lek
			</div>
		),
	},
	{ label: productText('stock'), field: 'stock_quantity', align: 'left' },
	{ label: productText('last_sync'), field: 'synced_at', align: 'left' },
	{ label: productText('actions'), field: 'actions', align: 'left' },
]
