import { Box, Modal } from '@mui/material'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import calendarIcon from '../../../assets/svgs/calendar_gray.svg'
import Svg_close from '../../../assets/svgs/close_sm.svg'
import discountIcon from '../../../assets/svgs/discount.svg'
import reservationIcon from '../../../assets/svgs/reservation.svg'
import typeIcon from '../../../assets/svgs/type.svg'
import valueIcon from '../../../assets/svgs/value.svg'
import { boxStyle } from '../../../constants/types'

export const promotionsViewModalText = (text) =>
	t(`marketing.promotions.PromotionViewModal.${text}`)

export default function PromotionViewModal({ showModal, data, onClose }) {
	const [visible, setVisible] = useState(false)
	const [state, setState] = useState({
		available: 1,
	})

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				// getPromotionDetails(data?.id)
				setState(data)
			}
		}
		setVisible(showModal)
	}, [showModal])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none p-8">
					<div
						className={`flex flex-col ${
							data?.discounts.length > 0 &&
							'border border-b border-0 border-gray-400 mb-5 pb-7'
						}`}
					>
						<div className="flex flex-row justify-between">
							<div>
								<label className="text-xl font-medium text-black1 mr-5">
									{data?.title} {promotionsViewModalText('details')}
								</label>
								<label className="text-base text-black1 bg-gray2 ml-2 px-3 py-1 rounded-full">
									{data?.discounts.length > 0
										? `${promotionsViewModalText('linked_with_discount')} no.${
												data?.discounts[0]?.id
										  }`
										: promotionsViewModalText('not_linked')}
								</label>
							</div>
							<div
								className="bg-gray2 p-2 rounded-full cursor-pointer"
								onClick={onClose}
							>
								<img src={Svg_close} alt="" />
							</div>
						</div>
						<span className="text-gray5 text-base">
							{data?.discounts.length > 0
								? promotionsViewModalText('linked')
								: promotionsViewModalText('not_linked')}
						</span>
						<div className="grid grid-cols-2">
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={typeIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{promotionsViewModalText('title')}
									</span>
									<span className="text-gray-500 text-sm">{data?.title}</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={discountIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{promotionsViewModalText('discount')}
									</span>
									<span className="text-gray-500 text-sm">
										ID# {data?.discounts[0]?.id ?? '-'}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={calendarIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{promotionsViewModalText('start_time')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.start_time
											? moment(data.start_time).format(
													promotionsViewModalText('date_format')
											  )
											: ''}
									</span>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={calendarIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{promotionsViewModalText('end_time')}
									</span>
									<span className="text-gray-500 text-sm">
										{data?.end_time
											? moment(data.end_time).format(
													promotionsViewModalText('date_format')
											  )
											: ''}
									</span>
								</div>
							</div>
						</div>
						<div className="flex flex-row items-center gap-4 mt-5">
							<div className="p-2 bg-gray2 rounded-lg">
								<img src={discountIcon} alt="icon" />
							</div>
							<div className="flex flex-col">
								<span className="text-gray-700 font-medium text-base">
									{promotionsViewModalText('description')}
								</span>
								<span className="text-gray-500 text-sm">
									{data?.description ?? '-'}
								</span>
							</div>
						</div>
					</div>
					{data?.discounts.map((discount, index) => (
						<div className="flex flex-col mt-5" key={index}>
							<div className="flex flex-row justify-between">
								<div>
									<label className="text-xl font-medium text-black1 mr-5">
										{promotionsViewModalText('discount_no')} {discount.id}
									</label>
								</div>
							</div>
							<div className="grid grid-cols-2">
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={typeIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{promotionsViewModalText('type')}
										</span>
										<span className="text-gray-500 text-sm">
											{discount.type === 'fixed'
												? promotionsViewModalText('fixed')
												: promotionsViewModalText('percentage')}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={valueIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{promotionsViewModalText('value')}
										</span>
										<span className="text-gray-500 text-sm">
											{discount.type === 'fixed' ? '$' : '%'} {discount.value}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={calendarIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{promotionsViewModalText('start_time')}
										</span>
										<span className="text-gray-500 text-sm">
											{discount?.start_time
												? moment(discount.start_time).format(
														promotionsViewModalText('date_format')
												  )
												: ''}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-4 mt-5">
									<div className="p-2 bg-gray2 rounded-lg">
										<img src={calendarIcon} alt="icon" />
									</div>
									<div className="flex flex-col">
										<span className="text-gray-700 font-medium text-base">
											{promotionsViewModalText('end_time')}
										</span>
										<span className="text-gray-500 text-sm">
											{discount?.end_time
												? moment(discount.end_time).format(
														promotionsViewModalText('date_format')
												  )
												: ''}
										</span>
									</div>
								</div>
							</div>
							<div className="flex flex-row items-center gap-4 mt-5">
								<div className="p-2 bg-gray2 rounded-lg">
									<img src={reservationIcon} alt="icon" />
								</div>
								<div className="flex flex-col">
									<span className="text-gray-700 font-medium text-base">
										{promotionsViewModalText('reservation_count')}
									</span>
									<span className="text-gray-500 text-sm">
										{discount.reservation_count}
									</span>
								</div>
							</div>
						</div>
					))}
				</Box>
			</Modal>
		</div>
	)
}
