import React from "react";

import { faBars as menuIcon } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CompletedIcon from "../assets/svgs/completed.svg";
import PendingIcon from "../assets/svgs/pending.svg";
import ProcessingIcon from "../assets/svgs/processing.svg";

import { useLocation } from "react-router-dom";
const NavbarKDS = () => {
  const location = useLocation();
  const setPagesNav = location.pathname.includes('/kds/orders-ready');
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
  return (
    // NAVBAR  
    <div className="flex">  
      {!setPagesNav && <div className="flex relative h-[76px] z-50 bg-white border-b-2 border-[#E4E4E4] w-full justify-between py-4 px-[1%] text-[#19191C]">

        <div className="left inline-flex items-center">
          <div className="flex justify-center items-center cursor-pointer logo mx-6">
            <FontAwesomeIcon icon={menuIcon} className="fa-lg" />
          </div>
        </div>
        <div className="left inline-flex items-start gap-4">
          <button className="rounded-[6px] px-4 py-2 bg-[#240b3b] ">
            <span className="text-white text-14 font-normal leading-6">
              All
            </span>
          </button>
          <button className="rounded-[6px] px-4 py-2 bg-white align-middle">
            <img src={PendingIcon} className="inline mr-2" />
            <span className="text-[#252C32] text-14 font-normal leading-6 inline">
              Pending
            </span>
          </button>
          <button className="rounded-[6px] px-4 py-2 bg-white align-middle">
            <img src={CompletedIcon} className="inline mr-2" />
            <span className="text-[#252C32] text-14 font-normal leading-6 inline">
              Completed
            </span>
          </button>
          <button className="rounded-[6px] px-4 py-2 bg-white align-middle">
            <img src={ProcessingIcon} className="inline mr-2" />
            <span className="text-[#252C32] text-14 font-normal leading-6 inline">
              Processing
            </span>
          </button>
        </div>
        <div>
          <input
            placeholder="Search"
            className="border-[1px] border-[#E4E4E4] min-w-[472px] rounded-[16px] bg-transparent text-14 px-6 py-[10px] active:border-[#E4E4E4]"
          />
        </div>
        <div className="right flex items-center gap-4">
          <span className="text-[#252C32] text-14 font-bold leading-6 ">
            {new Date().toLocaleDateString('en-US', options)}
          </span>
          <span className="text-[#252C32] text-14 font-bold leading-6 ">
            {new Date().toLocaleTimeString('en-US', optionsTime)}
          </span>
        </div>
      </div>}
      {setPagesNav && <div className="flex relative h-[76px] z-50 bg-white border-b-2 border-[#E4E4E4] w-full justify-between py-4 px-[1%] text-[#19191C]">
        <span className="text-[#252C32] text-14 font-bold leading-6 ">
          {new Date().toLocaleTimeString('en-US', optionsTime)}
        </span>
        <div className="right flex items-center gap-4">
          <span className="text-[#252C32] text-14 font-bold leading-6 ">
            {new Date().toLocaleDateString('en-US', options)}
          </span>
        </div>
      </div>}
    </div>
  );
};
export default NavbarKDS;
