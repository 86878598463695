import React from 'react'
import DiscountIcon from '../../../assets/svgs/discount1.svg'
import TrashIcon from '../../../assets/svgs/trash-outline.svg'
import { getCurrencySymbol } from './currency'

export default function AllCarts({ cart, deleteFromCart, totalCost }) {
	return (
		<div className="flex flex-col justify-between">
			{cart?.map((item) => {
				return (
					<div className="border-b-[1px] border-[#393C49] py-3">
						<div className="flex justify-between flex-row items-center">
							<div className="flex justify-start py-2  items-center">
								<div className="w-10 h-10 text-16 bg-[#F7F8F9] font-semibold align-middle rounded-[4px] mr-2">
									{item?.quantity}X
								</div>
								<div className="flex flex-col justify-between">
									<p className="text-white text-16 font-medium">
										{item?.title}
									</p>
									<p className="text-[#ABBBC2] text-14 font-medium">
										{item?.quantity} X {getCurrencySymbol(item?.currency)}
										{item?.price}
									</p>
								</div>
							</div>
							<p className="text-16 font-medium leading-[140%] text-white ">
								{getCurrencySymbol(item?.currency)}
								{item?.quantity * item?.price}
							</p>
						</div>
						<div className="flex justify-between items-center gap-4 py-2">
							<input
								placeholder="Add note"
								className="flex flex-1 text-white border-[1px] bg-transparent border-[#E4E4E4] rounded-[8px] p-[14px]"
							/>
							<button
								className=" bg-transparent border-[1px] border-[#E4E4E4] rounded-[8px] p-[14px]"
								onClick={() => deleteFromCart(item?.id)}
							>
								<img src={TrashIcon} alt="TrashIcon" />
							</button>
						</div>
					</div>
				)
			})}

			<div className="flex md:hidden flex-wrap justify-between py-3 border-b-[1px] border-[#393C49] ">
				<div className=" pe-2  w-1/3">
					<div className="rounded-[8px] border-[1px] border-[#E4E4E4] w-full">
						<div className=" rounded-[8px] w-full object-cover h-[60px] bg-[#240b3b] align-middle">
							<img src={DiscountIcon} alt="DiscountIcon" />
						</div>
						<div className="h-[40px] bg-white items-center flex justify-between flex-col p-3 rounded-b-[8px]">
							<p className="text-[#1F1D2B] text-14 font-semibold">Discount</p>
						</div>
					</div>
				</div>
			</div>
			<div className="py-3">
				<div className="flex justify-between py-2">
					<p className="text-[#E5EAFF] text-14 font-normal ">Discount</p>
					<p className="text-white text-14 font-medium leading-[130%]">
						{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}0
					</p>
				</div>
				<div className="flex justify-between py-2">
					<p className="text-[#E5EAFF] text-14 font-normal ">Sub total</p>
					<p className="text-white text-14 font-medium leading-[130%]">
						{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}
						{totalCost}
					</p>
				</div>
			</div>
		</div>
	)
}
