export const SET_APP_TOKEN = 'SET_APP_TOKEN';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_APP_PRICING_PLAN = 'SET_APP_PRICING_PLAN';
export const SET_VENUE_TYPE = 'SET_VENUE_TYPE';
export const SET_VENUE_NAME = 'SET_VENUE_NAME'
export const SET_RESTAURANT_LIST = 'SET_RESTAURANT_LIST';

export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const SET_CHAT_LAST_MSG = 'SET_CHAT_LAST_MSG';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING';

export const SET_IS_TRIAL_MODE = 'SET_IS_TRIAL_MODE';
export const SET_SHOW_UPGRADE_FROM_TRIAL = 'SET_SHOW_UPGRADE_FROM_TRIAL';
export const SET_PLAN_FEATURES = 'SET_PLAN_FEATURES';
export const SET_SUBSCRIPTION_PLAN = 'SET_SUBSCRIPTION_PLAN';
