import apiFactory from "../utils/apiFactory";
import { API_TEMPLATE } from "../constants/API";

export default class TemplateService {
    static GetAllMessageTemplates = async () => {
        let api = {
            content_type: "application/json",
            url: API_TEMPLATE,
            method: "GET",
        };
        return apiFactory(api);
    };

    static GetAllAutomaticReplies = async () => {
        let api = {
            content_type: "application/json",
            url: `${API_TEMPLATE}/automatic-replies`,
            method: "GET",
        };
        return apiFactory(api);
    };

    static CreateMessageTemplate = async (rawData) => {
        let api = {
            content_type: "application/json",
            url: API_TEMPLATE,
            method: "POST",
        };
        return apiFactory(api, rawData);
    };

    static CreateAutomaticReply = async (rawData) => {
        let api = {
            content_type: "application/json",
            url: `${API_TEMPLATE}/automatic-replies`,
            method: "POST",
        };
        return apiFactory(api, rawData);
    };

    static UpdateMessageTemplate = async (payload) => {
        let api = {
            content_type: "application/json",
            url: `${API_TEMPLATE}`,
            method: "PUT",
        };
        return apiFactory(api, payload);
    };

    static UpdateAutomaticReply = async (rawData) => {
        let api = {
            content_type: "application/json",
            url: `${API_TEMPLATE}/automatic-replies`,
            method: "PUT",
        };
        return apiFactory(api, rawData);
    };

    // is this for Automatic Reply
    static DeleteAutomaticReply = async (id) => {
        let api = {
            content_type: "application/json",
            url: `${API_TEMPLATE}/${id}`,
            method: "DELETE",
        };
        return apiFactory(api);
    };
}