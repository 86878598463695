import { AddOutlined } from '@mui/icons-material'
import TrashIcon from '@mui/icons-material/DeleteOutline'
import { Box, IconButton, Modal, TextField } from '@mui/material'
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import saleIcon from '../../../assets/svgs/sale_white.svg'
import SelectItems from '../../../components/SelectItems'
import ConfirmationFooter from '../../../components/footer/ConfirmationFooter'
import { boxStyle } from '../../../constants/types'
import orderService from '../../../services/orderService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { currencyLabels, methodLabels } from './currency'

export default function PaymentMethod({
	visible,
	onClose,
	totalPrice,
	customerDetails,
	selectedItems,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [methodOptions, setMethodOptions] = useState([
		{ payment_type: '', amount: 0, currency: 'usd' },
	])

	const addNumberOrIncrement = (num) => {
		if (methodOptions.includes(num)) {
			setMethodOptions((prev) => [...prev, num + 1])
		} else {
			setMethodOptions((prev) => [...prev, num])
		}
	}

	const handleChange = (index, field, value) => {
		setMethodOptions((prevOptions) => {
			const newOptions = [...prevOptions]
			newOptions[index] = { ...newOptions[index], [field]: value }
			return newOptions
		})
	}

	const handleRemove = (index) => {
		setMethodOptions((prevOptions) => prevOptions.filter((_, i) => i !== index))
	}

	const handleFinalizeOrder = () => {
		const orderProducts = selectedItems.map((item) => {
			return {
				product_id: item.id,
				product_instructions: item.title,
				product_quantity: item.quantity,
				product_total_price: item.price,
				product_discount_price: item?.discount ?? 0,
			}
		})

		const body = {
			subtotal: totalPrice,
			order_products: orderProducts,
			total_amount: totalPrice,
			customer_id: customerDetails.customer.id,
			is_for_self: true,
			other_person_name: null,
			split_payments: methodOptions,
			total_payment: totalPrice,
		}

		orderService
			.finalizeOrder(body)
			.then(() => {
				NotificationManager.success('Order was created successfully')
				identifyVenue(short_code)
				trackEvent('order service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'finalize order',
				})
			})
			.catch(() => {
				NotificationManager.error('Something went wrong!')
				identifyVenue(short_code)
				trackEvent('order service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'finalize order',
				})
			})

		onClose()
	}

	const calculateTotalAmount = methodOptions.reduce(
		(total, item) => total + (item.amount || 0),
		0
	)

	const areAllPaymentTypesValid = () => {
		return (
			methodOptions.every(
				(item) =>
					typeof item.payment_type !== 'number' &&
					typeof item.payment_type === 'string' &&
					item.payment_type.trim() !== '' &&
					item.payment_type
			) && calculateTotalAmount === totalPrice
		)
	}

	return (
		<Modal
			open={visible}
			onClose={onClose}
			className="schedule-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none">
				<div className="flex flex-row items-center px-024 pb-4 pt-024 gap-4">
					<div className="p-2 bg-gray7 rounded-full">
						<div className="bg-black1 p-2 rounded-full">
							<img src={saleIcon} alt="icon" />
						</div>
					</div>
					<div>
						<label className="text-20 font-semibold text-black1">
							Payment Method
						</label>
					</div>
				</div>

				{methodOptions.map((item, index) => (
					<div className="flex flex-col px-4 mb-4 gap-4" key={index}>
						<div className="flex items-center gap-2">
							<TextField
								required
								select
								labelId="payment-select-label"
								id="payment-select"
								label="Payment Method"
								className="w-1/2 h-full"
								value={item.payment_type}
								onChange={(e) =>
									handleChange(index, 'payment_type', e.target.value)
								}
							>
								{SelectItems(methodLabels)}
							</TextField>
							<TextField
								required
								id="filled-required"
								label="Amount"
								size="medium"
								type="number"
								value={item.amount || ''}
								onChange={(e) =>
									handleChange(
										index,
										'amount',
										parseFloat(e.target.value) || null
									)
								}
							/>
							<TextField
								required
								select
								id="currency-select"
								label="Currency"
								className="w-36 h-full"
								value={item.currency}
								onChange={(e) =>
									handleChange(index, 'currency', e.target.value)
								}
							>
								{SelectItems(currencyLabels)}
							</TextField>
							<IconButton onClick={() => handleRemove(index)}>
								<TrashIcon />
							</IconButton>
						</div>
					</div>
				))}

				<div className="flex justify-center items-center">
					<button
						className="flex justify-center items-center text-white bg-cyan1 h-full w-1/2 font-semibold text-16 p-3"
						onClick={() => addNumberOrIncrement(methodOptions.length + 1)}
					>
						<IconButton>
							<AddOutlined
								style={{
									color: 'white',
									width: '20px',
									height: '20px',
								}}
							/>
						</IconButton>
						<span>Add new Method</span>
					</button>
				</div>

				<div className="flex justify-end py-2 px-5">
					Total: <strong>${totalPrice}</strong>
				</div>

				<ConfirmationFooter
					onClose={onClose}
					onSubmit={handleFinalizeOrder}
					title="Continue"
					isDisabled={!areAllPaymentTypesValid()}
				/>
			</Box>
		</Modal>
	)
}
