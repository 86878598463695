import { FeedbackOutlined, InfoOutlined, QrCode } from '@mui/icons-material'
import { t } from 'i18next'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { rentalUnitListText } from '../../views/accommodation/rentalUnit/list'
import { storeSettingsText } from '../../views/account/storeSettings'
import { deliveryCustomersText } from '../../views/customers'
import { guestsText } from '../../views/guests/communicationPreferences'
import { guestsViewText } from '../../views/guests/guests'
import { menuText } from '../../views/menu/menus'
import { reservationsText } from '../../views/reservations'
import QrCodeModal from '../QrCodeModal'
import FeedbackModal from './FeedbackModal'
import QuickInfoModal from './QuickInfoModal'

export const feedbackText = (text) => t(`menu_management.feedback.${text}`)

export default function FeedbackButtons({ type, extra = false }) {
	const [quickInfoVisible, setQuickInfoVisible] = useState(false)
	const [feedbackVisible, setFeedbackVisible] = useState(false)
	const [qrCodeVisible, setQrCodeVisible] = useState(false)
	const { short_code } = useSelector((state) => state.app)
	const handleOpenInfo = () => {
		setQuickInfoVisible(!quickInfoVisible)
		identifyVenue(short_code)
		if (type === menuText('menu-management')) {
			trackEvent('Quick Info - Menu Management', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === reservationsText('reservations')) {
			trackEvent('Quick Info - Reservations', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === 'Orders Management') {
			trackEvent('Quick Info - Orders Management', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === storeSettingsText('store_settings')) {
			trackEvent('Quick Info - Store Settings', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === rentalUnitListText('rentalUnits')) {
			trackEvent('Quick Info - Rental Units', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === deliveryCustomersText('customers')) {
			trackEvent('Quick Info - Customers', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === guestsViewText('guestsManagement')) {
			trackEvent('Quick Info - Guests Management', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		} else if (type === guestsText('communicationPreferencesTitle')) {
			trackEvent('Quick Info - Communication Preferences', {
				action_category: 'open_modal',
				action_outcome: 'success',
				interaction_element: 'button',
			})
		}
	}

	return (
		<div className="flex flex-row gap-0 items-center">
			{extra && (
				<div
					className="bg-white flex flex-col justify-center align-center px-4 py-2 text-black1 text-sm rounded-lg cursor-pointer"
					onClick={() => setQrCodeVisible(!qrCodeVisible)}
				>
					<div className="flex flex-row justify-center align-center">
						<QrCode />
					</div>
					<span>{feedbackText('QR_CODE')}</span>
				</div>
			)}

			<div
				className="bg-white flex flex-col justify-center align-center px-4 py-2 text-black1 text-sm rounded-lg cursor-pointer"
				onClick={() => handleOpenInfo()}
			>
				<div className="flex flex-row justify-center align-center">
					<InfoOutlined />
				</div>
				<span>{feedbackText('HELPFUL_INFO')}</span>
			</div>
			<div
				className="bg-white flex flex-col justify-center align-center px-4 py-2 text-black1 text-sm rounded-lg cursor-pointer"
				onClick={() => {
					setFeedbackVisible(!feedbackVisible)
				}}
			>
				<div className="flex flex-row justify-center align-center">
					<FeedbackOutlined />
				</div>
				<span>{feedbackText('feedback')}</span>
			</div>
			<FeedbackModal
				showModal={feedbackVisible}
				onClose={() => setFeedbackVisible(false)}
				type={type}
			/>
			<QrCodeModal
				showModal={qrCodeVisible}
				onClose={() => setQrCodeVisible(false)}
			/>
			<QuickInfoModal
				showModal={quickInfoVisible}
				onClose={() => setQuickInfoVisible(false)}
				type={type}
			/>
		</div>
	)
}
