import React, { useRef } from 'react'
import searchIcon from '../../assets/svgs/search_gray.svg'

export default function GenericSearch({ search, setSearch, placeholder }) {
	const inputRef = useRef(null)
	return (
		<div className="flex flex-row justify-center items-center border rounded-lg px-3">
			<img src={searchIcon} alt="search" />
			<input
				className=" w-[250px] max-w-[80vw] h-[40px] px-2 outline-none"
				placeholder={placeholder}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				ref={inputRef}
			/>
		</div>
	)
}
