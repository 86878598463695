import i18n from 'i18next';
import moment from 'moment'
import { initReactI18next } from 'react-i18next';
import translationAL from '../assets/locales/al.json'
import translationDE from '../assets/locales/de.json'
import translationEN from '../assets/locales/en.json';
import translationES from '../assets/locales/es.json';
import translationFR from '../assets/locales/fr.json';
import translationGR from '../assets/locales/gr.json'
import translationIT from '../assets/locales/it.json'
import { KEYS, setStorageKey } from '../utils/storage';

export const appMoment = moment;

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    fr: {
        translation: translationFR
    },
    de: {
      translation: translationDE
    },
    it: {
      translation: translationIT
    },
    gr: {
        translation: translationGR
    },
    al: {
      translation: translationAL
    },
};

export const getLanguage = () => {
  return i18n.language;
};

export const setLanguage = async (lang) => {
  await i18n.changeLanguage(lang);
  setStorageKey(KEYS.LANGUAGE, lang);
  appMoment.locale(lang);
};

i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
  });

export default i18n;