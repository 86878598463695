import v2DashboardStandard from "../../../../assets/svgs/v2-dashboard-standard.svg";
import v2DashboardWhite from "../../../../assets/svgs/v2-dashboard-white.svg";
import v2AnalyticsStandard from "../../../../assets/svgs/v2-analytics-standard.svg";
import v2AnalyticsWhite from "../../../../assets/svgs/v2-analytics-white.svg";
import v2CustomerInsightStandard from "../../../../assets/svgs/v2-customer_insights-standard.svg";
import v2CustomerInsightWhite from "../../../../assets/svgs/v2-customer_insights-white.svg";
import v2MenuStandard from "../../../../assets/svgs/v2-menu-standard.svg";
import v2MenuWhite from "../../../../assets/svgs/v2-menu-white.svg";
import v2MenuProductStandard from "../../../../assets/svgs/v2-menu-product-standard.svg";
import v2MenuProductWhite from "../../../../assets/svgs/v2-menu-product-white.svg";
import v2MenuCategoryStandard from "../../../../assets/svgs/v2-menu-category-standard.svg";
import v2MenuCategoryWhite from "../../../../assets/svgs/v2-menu-category-white.svg";
import v2MenuInventoryStandard from "../../../../assets/svgs/v2-menu-inventory-standard.svg";
import v2MenuInventoryWhite from "../../../../assets/svgs/v2-menu-inventory-white.svg";
import v2ReservationStandard from "../../../../assets/svgs/v2-reservation-standard.svg";
import v2ReservationWhite from "../../../../assets/svgs/v2-reservation-white.svg";
import v2WaitlistStandard from "../../../../assets/svgs/v2-waitlist-standard.svg";
import v2WaitlistWhite from "../../../../assets/svgs/v2-waitlist-white.svg";
import v2DiningStandard from "../../../../assets/svgs/v2-dining-standard.svg";
import v2DiningWhite from "../../../../assets/svgs/v2-dining-white.svg";
import v2SeatingArrangementsStandard from "../../../../assets/svgs/v2-seating-arrangements-standard.svg";
import v2SeatingArrangementsWhite from "../../../../assets/svgs/v2-seating-arrangements-white.svg";
import v2MarketingStandard from "../../../../assets/svgs/v2-marketing-standard.svg";
import v2MarketingWhite from "../../../../assets/svgs/v2-marketing-white.svg";
import v2TableStandard from "../../../../assets/svgs/v2-table-standard.svg";
import v2TableWhite from "../../../../assets/svgs/v2-table-white.svg";
import v2TableAvailableStandard from "../../../../assets/svgs/v2-table-available-standard.svg";
import v2TableAvailableWhite from "../../../../assets/svgs/v2-table-available-white.svg";
import v2GuestStandard from "../../../../assets/svgs/v2-guest-standard.svg";
import v2GuestWhite from "../../../../assets/svgs/v2-guest-white.svg";
import v2StaffStandard from "../../../../assets/svgs/v2-staff-standard.svg";
import v2StaffWhite from "../../../../assets/svgs/v2-staff-white.svg";
import v2TOStandard from "../../../../assets/svgs/v2-timeoff-standard.svg";
import v2TOWhite from "../../../../assets/svgs/v2-timeoff-white.svg";
import v2EventHallStandard from "../../../../assets/svgs/v2-event-hall-standard.svg";
import v2EventHallWhite from "../../../../assets/svgs/v2-event-hall-white.svg";
import v2GymStandard from "../../../../assets/svgs/v2-gym-standard.svg";
import v2GymWhite from "../../../../assets/svgs/v2-gym-white.svg";
import v2RestaurantStandard from "../../../../assets/svgs/v2-restaurant-standard.svg";
import v2RestaurantWhite from "../../../../assets/svgs/v2-restaurant-white.svg";
import v2DiscountStandard from "../../../../assets/svgs/v2-discount-standard.svg";
import v2DiscountWhite from "../../../../assets/svgs/v2-discount-white.svg";
import v2EmailStandard from "../../../../assets/svgs/v2-email-standard.svg";
import v2EmailWhite from "../../../../assets/svgs/v2-email-white.svg";
import v2ReferralStandard from "../../../../assets/svgs/v2-referal-standard.svg";
import v2ReferralWhite from "../../../../assets/svgs/v2-referal-white.svg";
import v2ReportStandard from "../../../../assets/svgs/v2-report-standard.svg";
import v2ReportWhite from "../../../../assets/svgs/v2-report-white.svg";
import v2DeliveryPickupStandard from "../../../../assets/svgs/v2-deliverypickup-standard.svg";
import v2DeliveryPickupWhite from "../../../../assets/svgs/v2-deliverypickup-white.svg";
import v2PayrollStandard from "../../../../assets/svgs/v2-payroll-standard.svg";
import v2PayrollWhite from "../../../../assets/svgs/v2-payroll-white.svg";
import v2AdvancedAccountStandard from "../../../../assets/svgs/v2-advanced-account-standard.svg";
import v2AdvancedAccountWhite from "../../../../assets/svgs/v2-advanced-account-white.svg";
import v2IntegrationStandard from "../../../../assets/svgs/v2-integration-standard.svg";
import v2IntegrationWhite from "../../../../assets/svgs/v2-integration-white.svg";
import v2DPIStandard from "../../../../assets/svgs/v2-dp-integrate-standard.svg";
import v2DPIWhite from "../../../../assets/svgs/v2-dp-integrate-white.svg";
import v2POSInventoryStandard from "../../../../assets/svgs/v2-pos-inventory-standard.svg";
import v2POSInventoryWhite from "../../../../assets/svgs/v2-pos-inventory-white.svg";
import v2SettingStandard from "../../../../assets/svgs/v2-setting-standard.svg";
import v2SettingWhite from "../../../../assets/svgs/v2-setting-white.svg";
import v2AccountSubscriptionStandard from "../../../../assets/svgs/v2-account-subscription-standard.svg";
import v2AccountSubscriptionWhite from "../../../../assets/svgs/v2-account-subscription-white.svg";
import v2AccountVenueSpaceStandard from "../../../../assets/svgs/v2-account-venue-space-standard.svg";
import v2AccountVenueSpaceWhite from "../../../../assets/svgs/v2-account-venue-space-white.svg";
import v2LoyaltyStandard from "../../../../assets/svgs/v2-loyalty-standard..svg";
import v2LoyaltyWhite from "../../../../assets/svgs/v2-loyalty-white.svg";
import v2LoyaltyProgramStandard from "../../../../assets/svgs/v2-loyaltyprogram-standart.svg";
import v2LoyaltyProgramWhite from "../../../../assets/svgs/v2-loyaltyprogram-white.svg";
import v2GuestsEnrolledStandard from "../../../../assets/svgs/v2-guests-enrolled-standard.svg";
import v2GuestsEnrolledWhite from "../../../../assets/svgs/v2-guests-enrolled-white.svg";
import v2CampaignStandard from "../../../../assets/svgs/v2-campaign-standard.svg";
import v2CampaignWhite from "../../../../assets/svgs/v2-campaign-white.svg";
import v2OrderAndPayStandard from "../../../../assets/svgs/v2-orderpay-standard.svg";
import v2OrderAndPayWhite from "../../../../assets/svgs/v2-orderpay-white.svg";


export const DentalClinicSidebar = [
    {
        img: v2DashboardStandard,
        imgActive: v2DashboardWhite,
        key: 1,
        name: "dashboard",
        text: "Dashboard",
        default: true,
        menus: [
            {
                img: v2DashboardStandard,
                imgActive: v2DashboardWhite,
                name: "venue-dashboard",
                text: "Dashboard",
                key: 1,
            },
            {
                img: v2AnalyticsStandard,
                imgActive: v2AnalyticsWhite,
                name: "analytics",
                text: "Analytics",
                key: 2,
            },
            {
                img: v2CustomerInsightStandard,
                imgActive: v2CustomerInsightWhite,
                name: "insights",
                text: "Customer Insights",
                key: 3,
            },
        ],
    },
    {
        img: v2MenuStandard,
        imgActive: v2MenuWhite,
        key: 2,
        name: "menu",
        text: "Menu Management",
        menus: [
            { img: v2MenuStandard, imgActive: v2MenuWhite , name: "menu", text: "Menu", key: 1 },
            { img: v2MenuProductStandard, imgActive: v2MenuProductWhite, name: "items", text: "Products", key: 2 },
            { img: v2MenuCategoryStandard, imgActive: v2MenuCategoryWhite ,name: "categories", text: "Categories", key: 3 },
            { img: v2MenuInventoryStandard, imgActive: v2MenuInventoryWhite , name: "inventory", text: "Inventory", key: 4 },
        ],
    },
    {
        img: v2ReservationStandard,
        imgActive: v2ReservationWhite,
        key: 3,
        name: "reservation",
        text: "Reservations",
        menus: [
            { img: v2ReservationStandard, imgActive: v2ReservationWhite, name: "reservations", text: "Reservations", key: 1 },
            { img: v2WaitlistStandard, imgActive: v2WaitlistWhite, name: "waitlist", text: "Waitlist Management", key: 2 },
            { img: v2DiningStandard, imgActive: v2DiningWhite, name: "dining-space-locations", text: "Dining Space Locations", key: 3 },
            { img: v2SeatingArrangementsStandard,imgActive: v2SeatingArrangementsWhite, name: "seating-arrangement", text: "Seating Arrangement", key: 4 },
            { img: v2MarketingStandard, imgActive: v2MarketingWhite, name: "reservation-calendar", text: "Reservation Calendar", key: 5 }
        ],
    },
    {
        img: v2TableStandard,
        imgActive: v2TableWhite,
        key: 4,
        name: "table",
        text: "Tables",
        menus: [
            { img: v2TableStandard, imgActive: v2TableWhite, name: "tables", text: "Tables Management", key: 1 },
            { img: v2TableAvailableStandard, imgActive: v2TableAvailableWhite, name: "available", text: "Available By Date", key: 2 },
        ],
    },
    {
        img: v2GuestStandard,
        imgActive: v2GuestWhite,
        key: 5,
        name: "guests",
        text: "Guests Management",
        menus: [{ img: v2GuestStandard, imgActive: v2GuestWhite, name: "guests", text: "Guests", key: 1 }],
    },
    {
        img: v2StaffStandard,
        imgActive: v2StaffWhite,
        key: 6,
        name: "staff",
        text: "Staff Management",
        menus: [
            {
                img: v2StaffStandard,
                imgActive: v2StaffWhite,
                name: "employees",
                text: "Employees",
                key: 1,
            },
            { img: v2TOStandard, imgActive: v2TOWhite, name: "time-off-requests", text: "Time-off requests", key: 2 },
        ],
    },
    {
        img: v2EventHallStandard,
        imgActive: v2EventHallWhite,
        key: 7,
        name: "events-hall",
        text: "Events Hall",
        menus: [
            { img: v2EventHallStandard, imgActive: v2EventHallWhite,  name: "halls-list", text: "Manage", key: 1 },
            { img: v2MarketingStandard, imgActive: v2MarketingWhite, name: "halls-availability", text: "Availability", key: 2 }
        ],
    },
    {
        img: v2GymStandard,
        imgActive: v2GymWhite,
        key: 8,
        name: "hotel-gym",
        text: "Gym",
        menus: [
            {  img: v2GymStandard, imgActive: v2GymWhite, name: "gyms-list", text: "Manage", key: 1 },
            {  img: v2MarketingStandard, imgActive: v2MarketingWhite, name: "gyms-availability", text: "Availability", key: 2 }
        ],
    },
    {
        //
        img: v2RestaurantStandard,
        imgActive: v2RestaurantWhite,
        key: 9,
        name: "hotel-restaurant",
        text: "Restaurant",
        menus: [
            {  img: v2RestaurantStandard, imgActive: v2RestaurantWhite, name: "rest-manage", text: "Manage", key: 1 },
            {  img: v2MarketingStandard, imgActive: v2MarketingWhite, name: "rest-availability", text: "Availability", key: 2 }
        ],
    },
    {
        img: v2MarketingStandard,
        imgActive: v2MarketingWhite,
        key: 10,
        name: "marketing",
        text: "Marketing",
        menus: [
            { img: v2DiscountStandard, imgActive: v2DiscountWhite, name: "promotions", text: "Promotions", key: 1 },
            { img: v2EmailStandard, imgActive: v2EmailWhite, name: "emails", text: "Emails", key: 2 },
            { img: v2ReferralStandard, imgActive: v2ReferralWhite, name: "referrals", text: "Referrals", key: 3 },
            { img: v2CampaignStandard, imgActive: v2CampaignWhite, name: "campaigns", text: "Campaigns", key: 4 },
            { img: v2StaffStandard, imgActive: v2StaffWhite, name: "enduser-card", text: "Cards", key: 5 },
            { img: v2OrderAndPayStandard, imgActive: v2OrderAndPayWhite, name: "wallet", text: "Wallet", key: 6 },
        ],
    },
    {
        img: v2ReportStandard,
        imgActive: v2ReportWhite,
        key: 11,
        name: "reporting",
        text: "Reporting",
        menus: [
            { img: v2DeliveryPickupStandard,imgActive: v2DeliveryPickupWhite, name: "orders", text: "Orders", key: 1 },
            { img: v2StaffStandard, imgActive:v2StaffWhite, name: "staff-reporting", text: "Staff", key: 2 },
            { img: v2PayrollStandard, imgActive:v2PayrollWhite, name: "financial", text: "Financial", key: 3 },

            { img: v2AnalyticsStandard, imgActive:v2AnalyticsWhite,  name: "sales-reporting", text: "Sales", key: 4 },
            { img: v2WaitlistStandard, imgActive: v2WaitlistWhite, name: "waitlist-reporting", text: "Waitlist", key: 5 },
            { img: v2TableAvailableStandard, imgActive: v2TableAvailableWhite, name: "tables-reporting", text: "Tables", key: 6 },
            { img: v2AdvancedAccountStandard, imgActive: v2AdvancedAccountWhite, name: "advanced", text: "Advanced", key: 7 },
        ],
    },
    {
        img: v2IntegrationStandard,
        imgActive: v2IntegrationWhite,
        key: 12,
        name: "integrations",
        text: "Integrations",
        menus: [
            { img: v2DPIStandard, imgActive: v2DPIWhite, name: "delivery-and-pickup", text: "Delivery & Pickup", key: 1 },
            { img: v2POSInventoryStandard, imgActive: v2POSInventoryWhite, name: "inventory", text: "POS Inventory", key: 2 }

        ],
    },
    {
        img: v2SettingStandard,
        imgActive: v2SettingWhite,
        key: 13,
        name: "settings",
        text: "Settings",
        menus: [
            { img: v2AccountSubscriptionStandard, imgActive: v2AccountSubscriptionWhite, name: "subscription", text: "Subscription", key: 1 },
            { img: v2GuestStandard, imgActive: v2GuestWhite, name: "account", text: "Account", key: 2 },
            { img: v2AccountVenueSpaceStandard, imgActive: v2AccountVenueSpaceWhite, name: "space", text: "Venue Space", key: 3 },
            { img: v2AdvancedAccountStandard, imgActive: v2AdvancedAccountWhite, name: "web", text: "VB Web Profile", key: 4 },
            { img: v2TableStandard, imgActive: v2TableWhite, name: "hygiene-standard", text: "Hygiene Standards", key: 5 },
        ],
    },
    {
        img: v2LoyaltyStandard,
        imgActive: v2LoyaltyWhite,
        key: 14,
        name: "loyalty",
        text: "Loyalty",
        menus: [
            { img: v2LoyaltyProgramStandard, imgActive: v2LoyaltyProgramWhite, name: "programs", text: "Programs", key: 1 },
            { img: v2GuestsEnrolledStandard,  imgActive: v2GuestsEnrolledWhite, name: "guests-enrolled", text: "Guests Enrolled", key: 2 }
        ],
    },
]

