import React from 'react';
import { Message } from '../react-web-gifted-chat';
import SystemMessage from '../react-web-gifted-chat/SystemMessage';
import MessageBubble from './MessageBubble';

export const renderMessage = (props) => (
  <Message
    {...props}
    containerStyle={{
      left: { backgroundColor: '#fff', display: 'flex' },
      right: { backgroundColor: '#fff', display: 'flex' }
    }}
    textStyle={{ fontSize: 13, textAlign: 'center' }}
  />
);

export const renderBubble = (props, onLongPress, onPressMsg, onSelectOption, loading) => {
  return (
    <MessageBubble
      {...props}
      isGroup={false}
      // renderTime={() => <Text>Time</Text>}
      // renderTicks={() => <Text>Ticks</Text>}
      containerStyle={{
        left: {  marginTop: 8 },
        right: { paddingRight: 12, marginTop: 8 }
      }}
      bottomContainerStyle={{
        left: { display: 'none' },
        right: { display: 'none' }
      }}
      tickStyle={{}}
      // usernameStyle={{ color: 'tomato', fontWeight: '100' }}
      containerToNextStyle={{
        left: {},
        right: {}
      }}
      containerToPreviousStyle={{
        left: {},
        right: {}
      }}
      onPressMsg={onPressMsg} 
      onLongPress={onLongPress}
      onSelectOption={onSelectOption}
      loading={loading}
    />
  );
};

export const renderSystemMessage = (props) => (
  <SystemMessage
    {...props}
    containerStyle={{ backgroundColor: 'transparent', paddingHorizontal: 20 }}
    wrapperStyle={{
      backgroundColor: '#efefef',
      padding: props.currentMessage && props.currentMessage.text ? 14 : 0,
      borderRadius: 8
    }}
    textStyle={{ color: 'black', fontSize: 16, }}
  />
);
