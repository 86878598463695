import { Box, CircularProgress } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import * as yup from 'yup'
import saleIcon from '../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../components/common/IconButton'
import CreateInventoryForm from '../../components/inventory/create-inventory-form'
import { boxStyle } from '../../constants/types'
import PhysicalStoreService from '../../services/physicalStoreService'


export const storeModalText = (text) =>
	t(`physical_store.new.${text}`)
export const accountText = (text) => t(`account.view.${text}`)
export default function NewPhysicalStoreModal({
	showModal,
	data,
	countries,
	onClose,
}) {
	const [visible, setVisible] = useState(showModal)
	const [loading, setLoading] = useState(false);
	const [countryName, setCountryName] = useState(null)
	const [stateName, setStateName] = useState('')
	const [cityName, setCityName] = useState('')
	
	const validator = yup.object().shape({
		name: yup
			.string()
			.trim()
			.required('Name is required')
			.max(72, 'Max exceeded')
			.min(2, 'Min not met'), 
		address_line1: yup
			.string()
			.trim()
			.required('Address Line 1 is required')
			.max(250, 'Max exceeded')
			.min(6, 'Min not met'),
		country: yup.number().integer().required('Country is required'),
		state: yup.string().required('State is required'),
		city: yup.string().required('City is required'),
		postcode: yup.string().required('Postal code is required'),
	})

	const formikProps = useFormik({
		initialValues: {
			name: '',
			country: null,
			state: null,
			city: null,
			postcode: '',
			address_line1: '',
			address_line2: '',
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})
	
	useEffect(() => {
		setVisible(showModal)

		if (data) {
			formikProps.setFieldValue('name', data?.name);
			formikProps.setFieldValue('country', data?.country_id);
			formikProps.setFieldValue('state', data?.state_id);
			formikProps.setFieldValue('city', data?.city_id);
			formikProps.setFieldValue('postcode', data?.postcode);
			formikProps.setFieldValue('address_line1', data?.address_line1);
			formikProps.setFieldValue('address_line2', data?.address_line2);

			setCountryName(data.country)
			setStateName(data.state)
			setCityName(data.city)
		}

		// eslint-disable-next-line
	}, [showModal, data])

	const onSubmit = (values) => {
		let storeData = {
			id: data?.id,
			name: values.name,  
			country_id: values.country,
			state_id: values.state,
			city_id: values.city,
			postcode: values.postcode,
			address_line1: values.address_line1,
			address_line2: values.address_line2,
		}
	  
		let promise = null
		if (data?.id == null) {
			promise = PhysicalStoreService.Create(storeData)
		} else {
			promise = PhysicalStoreService.Update(storeData)
		}

		setLoading(true);
		promise
			.then((res) => {
				setLoading(false);
				if (data?.id == null) {
					NotificationManager.success(
						'Physical Store created successfully',
						'Success',
						3000
					)
				} else {
					NotificationManager.success(
						'Physical Store updated successfully',
						'Success',
						3000
					)
				}

				onClose(true)
			})
			.catch((err) => {
				setLoading(false);
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
				throw new Error('Problem on creating an store')
			})
	}
 
	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<CustomIconButton
						title={
							data?.id
								? storeModalText('edit')
								: storeModalText('title')
						}
						icon={saleIcon}
					/>
					<CreateInventoryForm {...{ countryName, formikProps, setCityName, setCountryName, setStateName, stateName, countries }} />

					<div className="flex justify-between space-x-4 h-[48px] m-6 !mt-2">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{storeModalText('cancel')}
						</button>
						<button
							type="submit"
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => formikProps.handleSubmit()}
						>
							{loading ? <CircularProgress size={16} /> : storeModalText('save')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
