import { API_DEVICES } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class DeviceService {
   static GetAll = async (query) => {
      let api = {
         content_type: 'application/json',
         url: API_DEVICES,
         method: 'GET',
         query,
      }
      return apiFactory(api)
   }

   static createDevice = async (payload) => {
      let api = {
         content_type: 'application/json',
         url: API_DEVICES,
         method: 'POST',
      }
      return apiFactory(api, payload)
   }

   static updateDevice = async (payload, id) => {
      let api = {
         content_type: 'application/json',
         url: `${API_DEVICES}/${id}`,
         method: 'PUT',
      }
      return apiFactory(api, payload)
   }

   static deleteDevice = async (id) => {
      let api = {
         content_type: 'application/json',
         url: `${API_DEVICES}/${id}`,
         method: 'DELETE',
      }
      return apiFactory(api,)
   }


}
