import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Modal,
	TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { productText } from '.'
import saleIcon from '../../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../../components/common/IconButton'
import { boxStyle } from '../../../constants/types'
import MenuService from '../../../services/menuService'
import { brandModalText } from '../brands/brand-modal'

const TryAtHomeModal = ({ open, onClose, product, refreshData }) => {
	const [take_home, setTakeHome] = useState(false)
	const [take_home_days, setTakeHomeDays] = useState()
	const [loading, setLoading] = useState(false)

	const handleCheckboxChange = (event) => {
		setTakeHome(event.target.checked)
	}

	const handleDaysChange = (event) => {
		setTakeHomeDays(Number(event.target.value ?? 0))
	}

	useEffect(() => {
		if (product.take_home) {
			setTakeHome(product.take_home.take_home === 1)
			setTakeHomeDays(product.take_home.take_home_days)
		} else {
			setTakeHome(false)
			setTakeHomeDays()
		}
	}, [product])

	const handleSubmit = () => {
		setLoading(true)

		const payload = {
			take_home,
			take_home_days,
			id: product.take_home?.id ?? undefined,
			product_id: product.id,
		}

		MenuService.createTryAtHome(payload)
			.then(() => {
				NotificationManager.success('Product has been updated successfully')
				onClose()
				setLoading(false)
				refreshData()
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
			})
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="category-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none px-024 pb-024">
				<CustomIconButton
					px={false}
					icon={saleIcon}
					title={`Try ${product?.title ?? ''} at home`}
				/>

				<div className="flex flex-col pt-0 mb-4 items-center">
					<FormControlLabel
						className="w-full"
						control={
							<Checkbox
								checked={take_home}
								onChange={handleCheckboxChange}
								name="tryAtHome"
							/>
						}
						label="Can try at home"
					/>

					<TextField
						id="days"
						type="number"
						value={take_home_days}
						onChange={handleDaysChange}
						disabled={!take_home}
						margin="normal"
						fullWidth
						size="small"
						placeholder={productText('number_of_days')}
					/>
				</div>
				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{brandModalText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={handleSubmit}
					>
						{loading ? <CircularProgress size={16} /> : brandModalText('save')}
					</button>
				</div>
			</Box>
		</Modal>
	)
}

export default TryAtHomeModal
