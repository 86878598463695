import { Box, Card, CardContent, Tab, Tabs } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import InfoCard from '../../../components/info-card'
import GuestService from '../../../services/guestService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { ActivityTable } from './ActivityTable'
import { CommunicationPreferences } from './CommunicationPrefernces'
import { LoyaltyTable } from './LoyaltyTable'
import { PromotionsTable } from './PromotionsTable'
import TableReservations from './TableReservations'
import { TableBookings } from './bookings-tables'
import { infoFields } from './data'
import GuestBasicInfo from './guest-basic-info'
import './index.css'

export const guestsDetailsText = (text) => t(`guests.guestDetails.view.${text}`)

const GuestDetailScreen = () => {
	const { short_code } = useSelector((state) => state.app)

	const { id: guest_id } = useParams()
	const [data, setData] = useState({})
	const [activities, setActivities] = useState([])
	const [loyalties, setLoyalties] = useState([])
	const [promotions, setPromotions] = useState([])
	const [reservations, setReservations] = useState([])
	const [bookings, setBookings] = useState([])
	const [index, setIndex] = useState(0)
	const [loaded, setLoaded] = useState(false)

	const { state } = useLocation()

	const isAccommodation = useMemo(() => {
		if (state) {
			return state.isAccommodation
		}
		return false
	}, [state])

	useEffect(() => {
		getGuestDetails()
	}, [guest_id])

	const getGuestDetails = () => {
		GuestService.GetOne(guest_id)
			.then((res) => {
				setLoaded(true)
				setData(res.guest.data)
				setActivities(res.guest?.activities ?? [])
				setLoyalties(res.guest?.loyalties ?? [])
				setPromotions(res.guest?.promotions ?? [])
				setReservations(res.guest?.reservations ?? [])
				setBookings(res.guest?.bookings ?? [])
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'guests',
				})
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'guests',
				})
				setLoaded(true)
				NotificationManager.error(err?.message ?? 'Failed to get guest details')
			})
	}

	const onTabClicked = (event, index) => {
		setIndex(index)
	}

	if (!loaded) {
		return <LoadingScreen />
	}

	return (
		<div
			className="overflow-auto"
			style={{ height: 'calc(100vh - 76px - 68px)', margin: 0, padding: 0 }}
		>
			<div className="grid sm:grid-cols-7 grid-cols-1 bg-[#F9F7F7] p-6 gap-6">
				<GuestBasicInfo data={data} />

				<div className="col-span-5 flex flex-col gap-5">
					<Card>
						<CardContent>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									value={index}
									onChange={onTabClicked}
									className="pt-0 pr-4 pl-4 pb-2"
									textColor="inherit"
									// indicatorColor="secondary"
									TabIndicatorProps={{
										style: {
											backgroundColor: '#666A73',
										},
									}}
								>
									<Tab
										sx={{ textTransform: 'capitalize' }}
										label={'General Info'}
									/>
									<Tab
										sx={{ textTransform: 'capitalize' }}
										label={
											isAccommodation
												? guestsDetailsText('bookings')
												: guestsDetailsText('reservations')
										}
									/>
									{!isAccommodation && (
										<>
											<Tab
												sx={{ textTransform: 'capitalize' }}
												label={guestsDetailsText('loyaltyPrograms')}
											/>
											<Tab
												sx={{ textTransform: 'capitalize' }}
												label={guestsDetailsText('activities')}
											/>
											<Tab
												sx={{ textTransform: 'capitalize' }}
												label={guestsDetailsText('promotions')}
											/>
											<Tab
												sx={{ textTransform: 'capitalize' }}
												label={guestsDetailsText('communicationPreferences')}
											/>
										</>
									)}
								</Tabs>
								<Panel value={index} index={0}>
									<InfoCard title="User Info" infoFields={infoFields(data)} />
								</Panel>
								<Panel value={index} index={1}>
									{isAccommodation ? (
										<TableBookings data={bookings} />
									) : (
										<TableReservations data={reservations} />
									)}
								</Panel>
								{!isAccommodation && (
									<>
										<Panel value={index} index={2}>
											<LoyaltyTable data={loyalties} />
										</Panel>
										<Panel value={index} index={3}>
											<ActivityTable data={activities} />
										</Panel>
										<Panel value={index} index={4}>
											<PromotionsTable data={promotions} />
										</Panel>
										<Panel value={index} index={5}>
											<CommunicationPreferences
												data={data}
												onRefresh={() => {
													setLoaded(false)
													getGuestDetails()
												}}
											/>
										</Panel>
									</>
								)}
							</Box>
						</CardContent>
					</Card>
				</div>
			</div>
		</div>
	)
}

const Panel = (props) => (
	<div
		hidden={props.value !== props.index}
		className="row-span-2 bg-white rounded-12 m-4 border border-gray-200"
	>
		<div>{props.children}</div>
	</div>
)

export default GuestDetailScreen
