import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import plusIcon from '../../assets/svgs/plus.svg'
import AreYouSureModal from '../../components/common/areYouSureModal'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericPagination from '../../components/generic-table/generic-pagination'
import GenericTable from '../../components/generic-table/generic-table'
import TableEmptyData from '../../components/table/TableEmptyData'
import brandService from '../../services/brands-service'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import BrandModal from '../menu/brands/brand-modal'

export const brandText = (text) => t(`brand.${text}`)

export default function Brands() {
	const [showAddBrand, setShowAddBrand] = useState(false)
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [loaded, setLoaded] = useState(true)
	const { short_code } = useSelector((state) => state.app)
	const [selectedBrand, setBrandSelected] = useState()
	const [recordDetails, setRecordDetails] = useState()
	const [brands, setBrands] = useState([])

	const navigate = useNavigate()

	const columns = [
		{
			label: 'Logo',
			field: 'logo_path',
			align: 'left',
			cellComponent: (data) => (
				<img src={data?.data} alt="Logo" style={{ width: '50px' }} />
			),
		},
		{ label: 'Title', field: 'title', align: 'left' },
		{ label: 'Description', field: 'description', align: 'left' },

		{ label: 'Actions', field: 'actions', align: 'right' },
	]

	const goToBrands = () => navigate(`/${short_code}/admin/menu/brands`)

	const getAllBrands = () => {
		brandService
			.getBrands()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Brand', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'brand',
				})
				setLoaded(true)
				setBrands(res?.brands ?? [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('Brand', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'brand',
				})
				setLoaded(true)
			})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onEdit = (row) => {
		setShowAddBrand(true)
		setRecordDetails(row)
	}

	const onDelete = (id) => {
		setBrandSelected(id)
		setAreYouSureDeleteModalOpen(true)
	}

	useEffect(() => {
		getAllBrands()
	}, [])

	const handleDeleteBrand = () => {
		brandService
			.deleteBrands(selectedBrand)
			.then(() => {
				identifyVenue(short_code)
				trackEvent('Brand', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'brand',
				})
				NotificationManager.success(
					'Brand has been deleted successfully',
					'Success',
					3000
				)
				setAreYouSureDeleteModalOpen(false)
				setRecordDetails(undefined)
				getAllBrands()
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Brand', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'brand',
				})
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
			})
	}

	return (
		<div>
			{showAddBrand && (
				<BrandModal
					open={showAddBrand}
					onClose={() => {
						setShowAddBrand(false)
						setRecordDetails(null)
					}}
					brandDetails={recordDetails}
					fetchBrands={getAllBrands}
					setBrandDetails={setRecordDetails}
					brands={brands}
				/>
			)}

			{isAreYouSureDeleteModalOpen && (
				<AreYouSureModal
					title={brandText('delete_brand')}
					content={brandText('delete_brand_confirmation')}
					firstButton={brandText('no_go_back')}
					secondButton={brandText('yes_i_am')}
					showModal={isAreYouSureDeleteModalOpen}
					onClose={() => setAreYouSureDeleteModalOpen(false)}
					onSubmit={handleDeleteBrand}
				/>
			)}

			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{brandText('Brands')}
									</span>
									<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
										{brands.length} {brandText('brands')}
									</span>
								</div>
								<div className="flex items-center gap-1">
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={goToBrands}
									>
										<span>Brands</span>
									</div>
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setShowAddBrand(true)}
									>
										<img src={plusIcon} alt="add" className="mr-2" />
										<span>{brandText('add_brand')}</span>
									</div>
								</div>
							</div>
							{brands.length && brands.length > 0 ? (
								<div className="flex flex-col overflow-auto max-h-[70vh]">
									<GenericTable
										columns={columns}
										data={brands}
										onDelete={onDelete}
										onEdit={onEdit}
									/>
									<GenericPagination
										count={brands.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										setPage={setPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={'brands'}
										btnLabel={'Add brand'}
										onAction={() => setShowAddBrand(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
