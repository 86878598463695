import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import RetailService from '../../../../services/retailService'
import CustomBarChart from '../../../Charts/custom-barchart'
import BestSellerProducts from './BestSellerProducts'
import Calendar from './calendar'
import { barChartList, dataBlocks, ordersCityBarChartList } from './data'
import OrdersTable from './orders'
import OrdersChart from './OrdersChart'

const RetailDashboard = () => {
	const [dashboardData, setDashboardData] = useState()
	const [userWelcome, setUserWelcome] = useState('Admin')
	const { auth } = useSelector((state) => state)
 
	useEffect(() => {
		if (auth && auth.user) {
			setUserWelcome(auth.user.name)
		}
		loadDashboardData();
	}, [auth])

	const loadDashboardData = () => {
		RetailService.GetDashboard()
			.then((res) => {
				setDashboardData(res);
				console.log('dashboard data', res)
			})
			.catch((error) => {
				NotificationManager.error(
					error?.message || 'Something went wrong!',
					'Error',
					3000
				)
			})
	}

	return (
		<div className="p-6 flex flex-col gap-5">
			<div className="name">
				<h1>Welcome, {userWelcome}! 👋</h1>
				<span>Here is your dashboard, where you can track everything</span>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
				{dataBlocks(dashboardData?.block_data).map((item, index) => (
					<div className="revenue-block" key={index}>
						<div className="flex items-center gap-2">
							<span>{item.icon}</span>
							<span className="title">{item.title}</span>
						</div>
						<div className="w-full flex items-center justify-between gap-2">
							<span className="value">{item.value}</span>
							<span className=" text-18 text-green1 font-semibold">{item.growth > 0 ? '+' : ''}{item.growth}%</span>
						</div>
					</div>
				))}
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
				<div className="statistics col-span-3 w-full">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">Revenue Statistics</span>
						<div className="flex items-center gap-2 weekly">
							<span>Monthly</span>
						</div>
					</div>
					<div className="flex items-center gap-2">
						<span className="value">{dashboardData?.block_data?.sales_amount ?? 0} Lek</span>
						<span className="this-week">this month</span>
					</div>
					<div className="w-full">
						<CustomBarChart
							data={dashboardData?.revenue_statistics || []}
							dataKey="week"
							barChartList={barChartList}
							showLegend={false}
							barSize={50}
							yaxis={true}
							cartesian={true}
							paddingBar={5}
							horizontalCartesian={true}
							showLabelCenter={false}
							radius={[1, 1, 0, 0]}
						/>
					</div>
				</div>

				<div className="statistics w-full col-span-2">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">Orders by Date</span>
						<div className="flex items-center gap-2 weekly">
							<span>Monthly</span>
						</div>
					</div>
					<div className="w-full">
						<Calendar bookingsData={dashboardData?.orders_by_date
							|| []} />
					</div>
				</div>
			</div>

			<div className="w-full col-span-2 bg-white flex flex-col py-6 pl-6 rounded-12">
				<div className="w-full">
					<span className=" font-bold text-18 text-black1 leading-6 ">Orders Report by Cities</span>
					<div className="text-12">
						Graphical orders view by cities
					</div>
				</div>
				<div className="w-full mt-7 !px-0">
					<CustomBarChart
						data={dashboardData?.orders_city || []}
						dataKey="city"
						barChartList={ordersCityBarChartList}
						showLegend={false}
						barSize={80}
						yaxis={true}
						cartesian={true}
						paddingBar={5}
						horizontalCartesian={true}
						showLabelCenter={false}
						radius={[10, 10, 0, 0]}
					/>
				</div>
			</div>
			<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5">
				<BestSellerProducts data={dashboardData?.bestseller_products || []} />
				<OrdersChart data={dashboardData?.orders_by_date || []} />
			</div>
			<div className="w-full col-span-2 bg-white flex flex-col py-6 pl-6 rounded-12">
				<span className="bookings">Orders</span>
				<OrdersTable data={dashboardData?.orders || []} />
			</div>
		</div>
	)
}

export default RetailDashboard
