import React from 'react';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';

const CustomRadarChart = ({ height = 400, data, domain = [0, 150] }) => {
   return (
      <ResponsiveContainer width="100%" height={height}>
         <RadarChart outerRadius={150} width={600} height={600} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis angle={90} domain={domain} />
            {data.map((entry) => (
               <Radar
                  key={entry.dataKey}
                  name={entry.name}
                  dataKey={entry.dataKey}
                  stroke={entry.stroke}
                  fill={entry.fill}
                  fillOpacity={0.4}
               />
            ))}
            <Legend />
         </RadarChart>
      </ResponsiveContainer>
   );
}

export default CustomRadarChart;
