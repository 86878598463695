import { API_GUESTS, API_GUESTS_WITHOUT_TABLE } from '../constants/API'
import apiFactory from '../utils/apiFactory'

export default class GuestService {
	static GetAll = async (query) => {
		let api = {
			content_type: 'application/json',
			url: API_GUESTS,
			method: 'GET',
			query,
		}
		return apiFactory(api)
	}

	static GetOne = async (id) => {
		let api = {
			content_type: 'application/json',
			url: `${API_GUESTS}/${id}`,
			method: 'GET',
		}
		return apiFactory(api)
	}

	static storeAccountsEmailPreferences = async (payload) => {
		let api = {
			content_type: 'application/json',
			url: `${API_GUESTS}/update-email-preferences`,
			method: 'PUT',
		}
		return apiFactory(api, payload)
	}

	static createGuest = async (payload) => {
		let api = {
			content_type: 'application/json',
			url: API_GUESTS,
			method: 'POST',
		}
		return apiFactory(api, payload)
	}

	static GetGuestsWithoutTable = async () => {
		let api = {
			content_type: 'application/json',
			url: API_GUESTS_WITHOUT_TABLE,
			method: 'GET',
		}
		return apiFactory(api)
	}
}
