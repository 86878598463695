import {
	Badge,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableRowClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import './index.css'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	[`&.${tableRowClasses.root}`]: {
		//   height: "200px"
	},
}))
const CustomMonthCalendar = (props) => {
	const { monthValue = new Date(), events = [], onChange = () => {} } = props

	const [weeks, setWeeks] = useState([])
	useEffect(() => {
		const month = monthValue.getMonth()
		const year = monthValue.getFullYear()
		const daysInMonth = new Date(year, month + 1, 0).getDate()
		const firstDayOfWeek = new Date(year, month, 1).getDay()

		// Generate an array of days in the month
		const days = Array.from({ length: daysInMonth }, (_, index) => index + 1)

		// Generate an array of empty cells for leading days in the week
		const leadingEmptyCells = Array.from(
			{ length: firstDayOfWeek },
			(_, index) => null
		)

		// Group the days into weeks
		const weeks = [...leadingEmptyCells, ...days].reduce((acc, day, index) => {
			if (index % 7 === 0) {
				return [...acc, [day]]
			} else {
				acc[acc.length - 1].push(day)
				return acc
			}
		}, [])
		setWeeks(weeks)
	}, [monthValue])

	// Get the events count for a specific day
	const getEventsCount = (day) => {
		const eventsForDay = events.filter((event) => event.day === day)
		return eventsForDay.length
	}

	return (
		<TableContainer component={Paper}>
			<Table className="month-calendar-table">
				<TableHead className="">
					<TableRow>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Sun
						</TableCell>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Mon
						</TableCell>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Tue
						</TableCell>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Wed
						</TableCell>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Thu
						</TableCell>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Fri
						</TableCell>
						<TableCell
							className="!text-gray5 !text-left !font-medium !text-base month-calendar-header"
							align="left"
						>
							Sat
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{weeks.map((week, index) => (
						<StyledTableRow key={index}>
							{week.map((day, index) => (
								<TableCell
									className="!text-gray5 !text-left !font-medium !text-base month-calendar-cell cursor-pointer"
									align="left"
									key={index}
									onClick={() => onChange(day)}
								>
									<div className="flex-flex-col justify-between py-4">
										<p>{day}</p>
										{getEventsCount(day) > 0 && (
											<Badge
												sx={{
													'& .MuiBadge-badge': {
														color: '#ffffff',
														backgroundColor: '#353535',
														fontSize: 12,
														height: 24,
														minWidth: 24,
														borderRadius: '99px',
													},
												}}
												className="mt-4 ml-2 rounded-[48px]"
												badgeContent={getEventsCount(day)}
											/>
										)}
									</div>
								</TableCell>
							))}
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default CustomMonthCalendar
