import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import { boxStyle } from "../../constants/types";
import { RInputDeclare } from "../custom/RInputDeclare";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import icon from "../../assets/svgs/credit-card.svg";
import { NotificationManager } from "react-notifications";
import CustomDateTimePicker from "../custom/CustomDateTimePicker";
import VenueService from "../../services/venuService";
import Svg_close from "../../assets/svgs/close.svg";
import {getRegisterConfig} from "../../services/restaurantService";
import CustomSelect from "../custom/CustomSelect";
import {isEmpty} from "../../utils/common";

const alCityOptions = [
    { name: 'Tirane', value: 'Tirane' },
]

const cityOptions = [
    { name: 'Manhattan', value: 'Manhattan' },
    { name: 'New York City', value: 'New York City' },
    { name: 'Brooklyn', value: 'Brooklyn' },
    { name: 'Queens', value: 'Queens' },
    { name: 'The Bronx', value: 'The Bronx' },
    { name: 'Staten Island', value: 'Staten Island' },
    { name: 'Albany', value: 'Albany' },
    { name: 'Buffalo', value: 'Buffalo' },
    { name: 'Rochester', value: 'Rochester' },
    { name: 'Syracuse', value: 'Syracuse' },
    { name: 'Yonkers', value: 'Yonkers' },
    { name: 'White Plains', value: 'White Plains' },
    { name: 'Albion', value: 'Albion' },
    { name: 'Alden', value: 'Alden' },
    { name: 'Amherst', value: 'Amherst' },
    { name: 'Arcade', value: 'Arcade' },
    { name: 'Attica', value: 'Attica' },
    { name: 'Avon', value: 'Avon' },
    { name: 'Batavia', value: 'Batavia' },
    { name: 'Blasdell', value: 'Blasdell' },
    // Add the remaining cities here
];

const industryList = [
    { value: "Food", title: "Food & Beverage" },
    { value: "Sport & Entertainment", title: "Entertainment Venues" },
    { value: "Accommodation", title: "Accommodation" },
    { value: "Retail", title: "Retail Management" },
    // { value: "Healthcare", title: "Healthcare" },
];

const categoryList = {
    Food: [
        { value: "restaurant", title: "Restaurant" },
        { value: "bistro", title: "Bistro" },
        { value: "cafe", title: "Cafe" },
        { value: "bar", title: "Bar" },
        { value: "pub_club", title: "Pub & Club" },
        { value: "food_truck", title: "Food Truck" },
        { value: "fine_dining", title: "Fine Dining" },
        { value: "bakery", title: "Bakery" },
        { value: "catering_service", title: "Catering Service" },
        { value: "fast_food", title: "Fast Food" },
        { value: "ice_cream_parlor", title: "Ice Cream Parlor" },
        { value: "food_stall", title: "Food Stall" },
        { value: "buffet", title: "Buffet" },
        { value: "pop_up_dining", title: "Pop-Up Dining" },
        { value: "juice_bar", title: "Juice Bar" },
        { value: "food_hall", title: "Food Hall" },
        { value: "supper_club", title: "Supper Club" },
        { value: "pizzeria", title: "Pizzeria" },
        { value: "coffee_shop", title: "Coffee Shop" },
        { value: "delis", title: "Delis" },
        { value: "food_court", title: "Food Court" },
    ],
    "Sport & Entertainment": [
        { value: "golf_venue", title: "Golf Course" },
        { value: "bowling", title: " Bowling Alley" },
        { value: "gym", title: "Gym & Fitness Center" },
        { value: "arcade_and_game_center", title: "Arcade & Game Center" },
        { value: "sports_arena", title: "Sports Arena" },
        { value: "concert_hall_and_theater", title: "Concert Hall & Theater" },
        { value: "amusement_and_theme_park", title: "Amusement & Theme Park" },
        { value: "ski_resort", title: "Ski Resort" },
        { value: "museum", title: "Museum" },
        { value: "cinema", title: "Cinema" },
    ],
    Accommodation: [
        { value: "hotel", title: "Hotel" },
        { value: "cozy_retreat", title: "Cozy Retreat" },
        { value: "hostel", title: "Hostel" },
        { value: "hotel_chain", title: "Hotel Chain" },
        { value: "vacation_rental", title: "Vacation Rental" },
        { value: "luxury_resort", title: " Luxury Resort" },
        { value: "family_resort", title: "Family Resorts" },
        { value: "service_apartment", title: "Service Apartment" },
        { value: "bed_and_breakfast", title: "Bed and Breakfast" },
        { value: "motel", title: "Motel" },
        { value: "capsule_hotel", title: "Capsule Hotel" },
        { value: "youth_hostel", title: "Youth Hostel" },
        { value: "campground", title: "Campground" },
        { value: "rv_park", title: "RV Park" },
    ],
    Retail: [
        { value: "sport_essentials", title: "Sporting Goods Store" },
        { value: "home_decor", title: "Home Décor Store" },
        { value: "fashion_threads", title: "Fashion Boutique" },
        { value: "retail_chain", title: "Retail Chain" },
        { value: "specialty_store", title: "Specialty Store" },
        { value: "online_shop", title: "Online Shop" },
        { value: "grocery_store", title: "Grocery Store" },
        { value: "electronics_store", title: "Electronics Store" },
        { value: "pharmacy", title: "Pharmacy" },
        { value: "auto_motorcycle_parts", title: "Auto Parts Store" },
        { value: "liquor_store", title: "Liquor Store" },
        { value: "pet_store", title: "Pet Store" },
    ],
    // Healthcare: [
    //     { value: "hospital", title: "Hospital" },
    //     { value: "dental_clinic", title: "Dental Clinic" },
    // ],
};

export default function AddVenueModal({ showModal, onConfirm, onClose, data, refresh }) {
    const [visible, setVisible] = useState(false);
    const [checkValid, setCheckValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const [restaurantName, setRestaurantName] = useState("");
    const [restaurantEmail, setRestaurantEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [country, setCountry] = useState(null);
    const [countryName, setCountryName] = useState(null);
    const [state, setState] = useState("");
    const [stateName, setStateName] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [venueType, setVenueType] = useState("");
    const [venueIndustry, setVenueIndustry] = useState("");
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);

    // restaurant_name: '',
    // restaurant_email: '',
    // phone: '',
    // address_line1: '',
    // address_line2: '',
    // state: '',
    // city: '',
    // postcode: '',
    // venue_type: '',
    // venue_industry: '',
    // contact_id: contactId,
    // logo_image: 'logo',



    useEffect(() => {
        if (showModal) {
            loadRegisterConfig();
            // initialize
            setVenueIndustry("");
            setRestaurantName("");
            setRestaurantEmail("");
            setPhone("");
            setAddressLine1("");
            setAddressLine2("");
            setState("");
            setCity("");
            setPostcode("");
            setVenueType("");

            setCheckValid(false);
        }
        setVisible(showModal);
    }, [showModal]);

    const loadRegisterConfig = () => {
        getRegisterConfig()
            .then((data) => {
                setStates(data?.states || []);
                setCountries(data?.countries || []);
                setCities(data?.cities || []);
            })
            .catch((error) => {
                throw new Error("Problem on getRegisterConfig");
            });

    }

    const onSubmit = () => {
        if (restaurantEmail && phone && addressLine1 && state && city && postcode && venueType && venueIndustry) {
            let payload = {
                restaurant_name: restaurantName,
                restaurant_email: restaurantEmail,
                phone: phone,
                address_line1: addressLine1,
                address_line2: addressLine2,
                state: state,
                country: country,
                city: city,
                postcode: postcode,
                venue_type: venueType,
                venue_industry: venueIndustry,
            }
            setLoading(true);
            VenueService.Create(payload)
                .then(({ data }) => {
                    setLoading(false);
                    onConfirm({ data: data?.link });
                    onClose();
                    NotificationManager.success(data?.message ?? "Venue is saved successfully");
                })
                .catch(err => {
                    setLoading(false);
                    if (typeof err?.error === 'string') {
                        NotificationManager.error(err?.error);
                    } else {
                        const errorKeys = Object.keys(err?.error || {});
                        if (errorKeys.length > 0) {
                            const firstErrorKey = errorKeys[0];
                            const firstErrorMessage = err?.error[firstErrorKey][0];
                            NotificationManager.error(firstErrorMessage || "Something went wrong!");
                        } else {
                            NotificationManager.error("Something went wrong!");
                        }
                    }

                })
        } else {
            setCheckValid(true);
        }
    };
    const onCloseModal = () => {
        onClose(null);
    };
    return (
        <div>
            <Modal
                open={visible}
                onClose={onClose}
                className="schedule-modal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={boxStyle} className="outline-none">
                    <div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
            			</div>
						<label className="text-20 font-semibold text-black1"> New Venue</label>
					</div>
                    <div className="flex flex-col p-024">
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Venue Name" className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    required
                                    type="text"
                                    size="small"
                                    error={checkValid && !restaurantName}
                                    id="filled-required"
                                    value={restaurantName}
                                    onChange={e => setRestaurantName(e.target.value)}
                                    inputProps={{
                                    }}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Venue Email" className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    required
                                    type="text"
                                    size="small"
                                    error={checkValid && !restaurantEmail}
                                    id="filled-required"
                                    value={restaurantEmail}
                                    onChange={e => setRestaurantEmail(e.target.value)}
                                    inputProps={{
                                    }}
                                />
                            </div>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Phone" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    required
                                    type="text"
                                    size="small"
                                    error={checkValid && !phone}
                                    id="filled-required"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                    inputProps={{
                                    }}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Address Line 1" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    required
                                    type="text"
                                    size="small"
                                    error={checkValid && !addressLine1}
                                    id="filled-required"
                                    value={addressLine1}
                                    onChange={e => setAddressLine1(e.target.value)}
                                    inputProps={{
                                    }}
                                />
                            </div>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Address Line 2" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    required
                                    type="text"
                                    size="small"
                                    error={checkValid && !addressLine2}
                                    id="filled-required"
                                    value={addressLine2}
                                    onChange={e => setAddressLine2(e.target.value)}
                                    inputProps={{
                                    }}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <RInputDeclare value={"Country"} className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <CustomSelect
                                    values={countries} // Assuming 'countries' is an array of country objects
                                    value={countryName}
                                    renderRow={(item) => <span>{item?.name}</span>}
                                    className={'!z-[5] !bg-white !border-gray6 !h-10'}
                                    placeholder={'Choose Country'}
                                    handleChange={(event) => {
                                        setCountry(event.id);
                                        setCountryName(event.name)
                                        // Reset state and city when country changes
                                        setState(null);
                                        setStateName(null);
                                        setCity(null);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                                <RInputDeclare value={"State"} className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <CustomSelect
                                    values={country ? countries.find(c => c.id === country)?.states : []}
                                    value={stateName}
                                    renderRow={(item) => <span>{item?.name}</span>}
                                    className={'!z-[5] !bg-white !border-gray6 !h-10'}
                                    placeholder={'Choose State'}
                                    handleChange={(event) => {
                                        setState(event.id);
                                        setStateName(event.name);
                                        // Reset city when state changes
                                        setCity(null);
                                    }}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <RInputDeclare value="City" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    id="outlined-select"
                                    size="small"
                                    error={checkValid && !city}
                                    select
                                    value={city}
                                    onChange={event => {
                                        setCity(event.target.value);
                                    }}
                                >
                                    {state && countries.find(c => c.id === country)?.states.find(s => s.id === state)?.cities.map(city => (
                                        <MenuItem key={city.id} value={city.id}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Postal code" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    required
                                    type="text"
                                    size="small"
                                    error={checkValid && !postcode}
                                    id="filled-required"
                                    value={postcode}
                                    onChange={e => setPostcode(e.target.value)}
                                    inputProps={{
                                    }}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <RInputDeclare value="Venue Industry" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <TextField
                                    id="outlined-select"
                                    size="small"
                                    error={checkValid && !venueIndustry}
                                    select
                                    value={venueIndustry}
                                    onChange={event => {
                                        setVenueIndustry(event.target.value);
                                    }}
                                >
                                    {industryList.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                            <RInputDeclare value="Venue Type" className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                            <TextField
                                id="outlined-select"
                                size="small"
                                error={checkValid && !venueType}
                                select
                                value={venueType}
                                onChange={event => {
                                    setVenueType(event.target.value);
                                }}
                            >
                                {venueIndustry &&
                                    categoryList[venueIndustry].map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
                        <button
                            className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                            onClick={onCloseModal}>
                            Cancel
                        </button>
                        <button className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white" onClick={onSubmit}>
                            {loading ? <CircularProgress size={16} /> : 'Submit'}
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
