import { Card, CardContent } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { t } from 'i18next'
import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import plusIcon from '../../assets/svgs/plus.svg'
import AreaModal from '../../components/beachbar/area-modal'
import ConfigurationModal from '../../components/beachbar/config-modal'
import AreYouSureModal from '../../components/common/areYouSureModal'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericTable from '../../components/generic-table/generic-table'
import TableEmptyData from '../../components/table/TableEmptyData'
import beachbarService from '../../services/beachbar-service'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'

export const beachBarAreaText = (text) => t(`beachBar.beachbar_area.${text}`)

export default function Umbrellas() {
	const navigate = useNavigate()
	const [showAddArea, setShowAddArea] = useState(false)
	const [showConfiguration, setShowConfiguration] = useState(false)
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [loaded, setLoaded] = useState(true)
	const { short_code } = useSelector((state) => state.app)
	const [selectedUmbrella, setUmbrellaSelected] = useState()
	const [recordDetails, setRecordDetails] = useState()
	const [areas, setAreas] = useState([])

	const columns = [
		{ label: 'No', field: 'id', align: 'left' },
		{ label: 'Area Code', field: 'unique_code', align: 'left' },
		{
			label: 'Nr of Umbrella',
			field: 'no_of_umbrella',
			align: 'right',
			cellComponent: (data) => <div>{data?.data ?? '0'}</div>,
		},
		{ label: 'Actions', field: 'actions', align: 'right' },
	]

	const getAllAreas = () => {
		beachbarService
			.getBeachbarAreas()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'beachbar',
				})
				setLoaded(true)
				setAreas(res?.data ?? [])
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'beachbar',
				})
				setLoaded(true)
			})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onEdit = (row) => {
		setShowAddArea(true)
		setRecordDetails(row)
	}
	const onDelete = (id) => {
		setUmbrellaSelected(id)
		setAreYouSureDeleteModalOpen(true)
	}

	// useEffect(() => {
	// 	getAllAreas()
	// }, [])

	const onSubmit = () => {
		beachbarService
			.deleteBeachbarArea(selectedUmbrella)
			.then(() => {
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'beachbar',
				})
				NotificationManager.success(
					'Area has been deleted successfully',
					'Success',
					3000
				)
				setAreYouSureDeleteModalOpen(false)
				setRecordDetails(undefined)
				getAllAreas()
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Beach bar', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'beachbar',
				})
				NotificationManager.error(
					error.message || t('common.error_generic'),
					'Error',
					3000
				)
			})
	}

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{beachBarAreaText('beach_areas')}
									</span>
									<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
										{areas.length} {beachBarAreaText('areas')}
									</span>
								</div>
								<div className="flex items-center gap-1">
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setShowAddArea(true)}
									>
										<img src={plusIcon} alt="search" className="mr-2" />
										<span>{beachBarAreaText('add_area')}</span>
									</div>
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => {
											if (short_code && short_code.length > 0) {
												navigate(`/${short_code}/admin/beachbar/umbrellas`)
											}
										}}
									>
										<span>{beachBarAreaText('all_umbrellas')}</span>
									</div>
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setShowConfiguration(true)}
									>
										<img src={plusIcon} alt="search" className="mr-2" />
										<span>
											{beachBarAreaText('add_configuration')}{' '}
											{/* Add Configuration */}
										</span>
									</div>
								</div>
							</div>
							{areas.length && areas.length > 0 ? (
								<div className="flex flex-col overflow-auto max-h-[70vh]">
									<GenericTable
										columns={columns}
										data={areas}
										onDelete={onDelete}
										onEdit={onEdit}
									/>

									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={areas.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={beachBarAreaText('umbrella_areas')}
										btnLabel={beachBarAreaText('add_area')}
										onAction={() => setShowAddArea(true)}
									/>
								</div>
							)}
						</CardContent>
						<AreYouSureModal
							title={beachBarAreaText('delete_umbrella')}
							content={beachBarAreaText('delete_umbrella_confirmation')}
							firstButton={beachBarAreaText('no_go_back')}
							secondButton={beachBarAreaText('yes_i_am')}
							showModal={isAreYouSureDeleteModalOpen}
							onClose={() => setAreYouSureDeleteModalOpen(false)}
							onSubmit={onSubmit}
						/>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
			{showAddArea && (
				<AreaModal
					recordDetails={recordDetails}
					setRecordDetails={setRecordDetails}
					showModal={showAddArea}
					setShowModal={setShowAddArea}
					onClose={() => {
						setShowAddArea(false)
						setRecordDetails(undefined)
					}}
					getAllAreas={getAllAreas}
				/>
			)}

			{showConfiguration && (
				<ConfigurationModal
					recordDetails={recordDetails}
					setRecordDetails={setRecordDetails}
					showModal={showConfiguration}
					setShowModal={setShowConfiguration}
					onClose={() => {
						setShowConfiguration(false)
						setRecordDetails(undefined)
					}}
				/>
			)}
		</div>
	)
}
