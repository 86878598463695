import { TextField } from '@mui/material'
import React from 'react'
import AssistantButton from '../../../components/assistantButton'
import CustomFormInput from '../../../components/custom/CustomInput/custom-form-input'
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { promotionsModalText } from './PromotionModal'

const PromotionForm = ({
	data,
	venueType,
	state,
	setState,
	discounts = [],
	handleChangeType,
	handleChangeDiscount,
	coupons = [],
	handleChangeCoupon,
	handleOpenAIModal,
}) => {
	const typeOptions = [
		{
			label: 'Discount',
			value: 'discount',
		},
		{
			label: 'Coupon',
			value: 'coupon',
		},
	]

	return (
		<div className="flex flex-col gap-5 mb-4 overflow-auto max-h-[60vh]">
			{!data?.id && (
				<>
					{(venueType === 'restaurant' ||
						venueType === 'sport_essentials' ||
						venueType === 'home_decor' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads') && (
						<CustomFormSelect
							onChange={handleChangeType}
							value={state?.type}
							placeholder={promotionsModalText('choose_type')}
							options={typeOptions}
						/>
					)}
					{!(
						venueType === 'sport_essentials' ||
						venueType === 'home_decor' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads'
					) &&
						venueType !== 'restaurant' && (
							<CustomFormSelect
								onChange={handleChangeType}
								value={state?.type}
								placeholder={promotionsModalText('choose_type')}
								options={[
									{
										label: 'discount',
										value: 'discount',
									},
								]}
							/>
						)}

					<CustomFormSelect
						onChange={handleChangeDiscount}
						value={state?.discount_id ?? ''}
						placeholder={promotionsModalText('choose_discount')}
						options={discounts}
					/>

					{(venueType === 'restaurant' ||
						venueType === 'sport_essentials' ||
						venueType === 'pharmacy' ||
						venueType === 'home_decor' ||
						venueType === 'fashion_threads') && (
						<>
							<CustomFormSelect
								onChange={handleChangeCoupon}
								value={state?.coupon_id ?? ''}
								placeholder={promotionsModalText('choose_coupon')}
								options={coupons}
							/>
						</>
					)}
				</>
			)}

			<CustomFormInput
				value={state?.title}
				onChange={(e) => setState({ ...state, title: e.target.value })}
				placeholder={promotionsModalText('title')}
				required={true}
			/>
			<div className="flex flex-row justify-end mt-2">
				<AssistantButton onAction={() => handleOpenAIModal('Title')} />
			</div>

			<RInputDeclare
				value={promotionsModalText('description')}
				className="!mt-2 !mb-2 !text-black1 font-medium"
			/>
			<TextField
				required
				size="small"
				multiline
				minRows={3}
				value={state?.description}
				placeholder={promotionsModalText('enter_description')}
				className="!text-slate-700"
				onChange={(e) => setState({ ...state, description: e.target.value })}
			/>
			<div className="flex flex-row justify-end mt-2">
				<AssistantButton onAction={() => handleOpenAIModal('Description')} />
			</div>

			{data?.id && (
				<>
					<RInputDeclare
						value={promotionsModalText('type')}
						className="!mt-6 !mb-2 !text-black1 font-medium"
					/>
					{(venueType === 'restaurant' ||
						venueType === 'sport_essentials' ||
						venueType === 'pharmacy' ||
						venueType === 'home_decor' ||
						venueType === 'fashion_threads') && (
						<CustomFormSelect
							onChange={handleChangeType}
							value={state?.type}
							placeholder={promotionsModalText('choose_type')}
							options={typeOptions}
						/>
					)}
					{!(
						venueType === 'sport_essentials' ||
						venueType === 'home_decor' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads'
					) &&
						venueType !== 'restaurant' && (
							<CustomFormSelect
								onChange={handleChangeType}
								value={state?.type}
								placeholder={promotionsModalText('choose_type')}
								options={typeOptions.filter(
									(item) => item.value === 'discount'
								)}
							/>
						)}
					<RInputDeclare
						value={promotionsModalText('discount')}
						className="!mt-6 !mb-2 !text-black1 font-medium"
					/>

					<CustomFormSelect
						onChange={handleChangeDiscount}
						value={
							state?.discount_id === 'ID-D#undefined' ? '' : state?.discount_id
						}
						placeholder={promotionsModalText('choose_discount')}
						options={discounts}
					/>

					{(venueType === 'restaurant' ||
						venueType === 'sport_essentials' ||
						venueType === 'home_decor' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads') && (
						<>
							<CustomFormSelect
								onChange={handleChangeCoupon}
								value={
									state?.coupon_id === 'ID-C#undefined' ? '' : state?.coupon_id
								}
								placeholder={promotionsModalText('coupon')}
								options={coupons}
							/>
						</>
					)}
				</>
			)}
		</div>
	)
}

export default PromotionForm
