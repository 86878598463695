import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate } from 'react-router-dom'

import useDebounce from '../../../components/Hooks/useDebounce'
import CustomToggleButton from '../../../components/custom/CustomToggleButton'
import MenuModal from '../../../components/menus/MenuModal'
import MenuProduct from '../../../components/menus/Product'
import MenuService from '../../../services/menuService'
import { isEmpty } from '../../../utils/common'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import plusIcon from '../../../assets/svgs/plus.svg'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import FeedbackButtons from '../../../components/feedback'
import GenericSearch from '../../../components/search/Search'
import TableEmptyData from '../../../components/table/TableEmptyData'
import { isFB } from '../../../helpers/venueHelpers'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import './index.css'

const currencySymbols = {
	ALL: 'Lek',
	USD: '$',
	EUR: '€',
	// Add other currencies and their symbols here
}

const getCurrencySymbol = (currencyCode) => {
	return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
}

export const menuText = (text) => t(`menu_management.menu.${text}`)

export default function Menus() {
	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [allProducts, setAllProducts] = useState([])
	const [categories, setCategories] = useState([])
	const [category_id, setCategoryId] = useState(null)
	const [search, setSearch] = useState('')
	const [loaded, setLoaded] = useState(false)
	const [callEnd, setCallEnd] = useState({ product: false, category: false })

	const cookies = new Cookies()
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)
	const currency = cookieBasicInfo.currency ?? 'USD'
	const debouncedValue = useDebounce(search, 900)
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		loadData()
	}, [])

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [category_id, debouncedValue])

	useEffect(() => {
		if (callEnd.product && callEnd.category) {
			setLoaded(true)
			identifyVenue(short_code)
			trackEvent(menuText('menu-management'), {
				action_category: 'load',
				action_outcome: 'success',
				interaction_element: 'screen',
			})
		}
	}, [callEnd])

	const loadData = () => {
		MenuService.getProducts()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent(t('menu-service'), {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: menuText('products'),
				})
				setAllProducts(res?.products || [])
				setCallEnd((v) => ({ ...v, product: true }))
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent(t('menu-service'), {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: menuText('products'),
				})
				setCallEnd((v) => ({ ...v, product: true }))
			})

		MenuService.getCategories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent(t('menu-service'), {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				setCategories(res?.categories || [])
				setCallEnd((v) => ({ ...v, category: true }))
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent(t('menu-service'), {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'categories',
				})
				setCallEnd((v) => ({ ...v, category: true }))
			})
	}

	const refreshData = () => {
		let query = []
		if (category_id) {
			query.push(`category=${category_id}`)
		}
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}

		MenuService.getProducts(query.join('&'))
			.then((res) => {
				setData(res?.products || [])
				identifyVenue(short_code)
				trackEvent(t('menu-service'), {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: menuText('products'),
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent(t('menu-service'), {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: menuText('products'),
				})
			})
	}

	return (
		<div>
			{loaded ? (
				<>
					<div className="flex flex-col h-full p-6">
						<Card>
							<CardContent className="!p-0">
								<div className="flex justify-between items-center pl-4 pt-2">
									<div className="flex items-center">
										<span className="text-black1 text-xl font-medium">
											{menuText('Menu')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{data.length} {menuText('products')}
										</span>
									</div>
									{isFB(venueType) && (
										<FeedbackButtons
											type={menuText('menu-management')}
											extra={true}
										/>
									)}
									{!isFB(venueType) && (
										<FeedbackButtons type={menuText('menu-management')} />
									)}
								</div>
								<div className="flex justify-between items-center py-2 px-3">
									<GenericSearch
										search={search}
										setSearch={setSearch}
										placeholder="Search Menu"
									/>
									<div className="flex items-center">
										<div className="gap-1 scrollable-categories">
											<CustomToggleButton
												label="All"
												classes="px-4 mx-2"
												active={category_id ? false : true}
												onAction={() => setCategoryId('')}
											/>
											{categories.map((item) => (
												<CustomToggleButton
													key={item.id}
													label={item.title}
													classes="px-4 mx-2"
													active={category_id === item.id ? true : false}
													onAction={() => setCategoryId(item.id)}
												/>
											))}
										</div>
										<div
											className="flex py-2.5 px-4 mx-2 justify-center items-center text-white bg-black1 rounded-lg cursor-pointer"
											onClick={() => setShowModal(true)}
										>
											<img src={plusIcon} alt="plus" className="mr-2" />
											<span className="font-semibold text-sm">New Menu</span>
										</div>
									</div>
								</div>
								{data.length > 0 ? (
									<div className="flex flex-col px-1 pb-10 overflow-auto max-h-[70vh]">
										<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 w-full">
											{data.map((item) => (
												<MenuProduct
													key={item.id}
													data={item}
													onClick={() =>
														navigate(
															`/${short_code}/admin/menu/items/edit/${item.id}`
														)
													}
													currency={getCurrencySymbol(currency)}
												/>
											))}
										</div>
									</div>
								) : (
									<div className="mb-10">
										<TableEmptyData
											content="menus"
											btnLabel="New Menu"
											onAction={() => setShowModal(true)}
										/>
									</div>
								)}
							</CardContent>
						</Card>
						{showModal && (
							<MenuModal
								showModal={showModal}
								categories={categories}
								products={allProducts}
								onClose={(flag) => {
									if (flag === true) {
										refreshData()
									}
									setShowModal(false)
								}}
							/>
						)}
					</div>
				</>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
