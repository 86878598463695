import { SET_APP_PRICING_PLAN, SET_VENUE_TYPE, SET_RESTAURANT_LIST, SET_LANGUAGE } from './types';
import { getPricingPlan } from '../../services/restaurantService';
import { KEYS, getStorageKey, setLanguage } from '../../utils/storage';

export const loadPricingPlanDetails = (plan_id) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        getPricingPlan(plan_id)
            .then(async (data) => {
                dispatch({ type: SET_APP_PRICING_PLAN, payload: data.pricing_plans });
                resolve(data.pricing_plans);
            })
            .catch(error => {
                reject(error);
            })
    });
};

export const setVenueType = (venue_type, short_code) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch({ type: SET_VENUE_TYPE, payload: {venue_type, short_code}})
        resolve()
    })
}

export const setRestaurantList = (list) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch({ type: SET_RESTAURANT_LIST, payload: list})
        resolve()
    })
}

export const initLanguage = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let lang = getStorageKey(KEYS.LANGUAGE);
        await setLanguage(lang ?? 'en');
        dispatch({
            type: SET_LANGUAGE, payload: lang ?? 'en'
        });
        resolve();
    });
};

export const changeLanguage = (lang) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        await setLanguage(lang ?? 'en');
        dispatch({
            type: SET_LANGUAGE, payload: lang ?? 'en'
        });
        resolve();
    });
};
