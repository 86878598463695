import v2AccountSubscriptionStandard from '../../../../assets/svgs/v2-account-subscription-standard.svg'
import v2AccountSubscriptionWhite from '../../../../assets/svgs/v2-account-subscription-white.svg'
import v2AccountVenueSpaceStandard from '../../../../assets/svgs/v2-account-venue-space-standard.svg'
import v2AccountVenueSpaceWhite from '../../../../assets/svgs/v2-account-venue-space-white.svg'
import v2AdvancedAccountStandard from '../../../../assets/svgs/v2-advanced-account-standard.svg'
import v2AdvancedAccountWhite from '../../../../assets/svgs/v2-advanced-account-white.svg'
import v2AnalyticsStandard from '../../../../assets/svgs/v2-analytics-standard.svg'
import v2AnalyticsWhite from '../../../../assets/svgs/v2-analytics-white.svg'
import v2CampaignStandard from '../../../../assets/svgs/v2-campaign-standard.svg'
import v2CampaignWhite from '../../../../assets/svgs/v2-campaign-white.svg'
import v2DashboardStandard from '../../../../assets/svgs/v2-dashboard-standard.svg'
import v2DashboardWhite from '../../../../assets/svgs/v2-dashboard-white.svg'
import v2DeliveryStandard from '../../../../assets/svgs/v2-delivery-standard..svg'
import v2DeliveryWhite from '../../../../assets/svgs/v2-delivery-white.svg'
import v2DeliveryPickupStandard from '../../../../assets/svgs/v2-deliverypickup-standard.svg'
import v2DeliveryPickupWhite from '../../../../assets/svgs/v2-deliverypickup-white.svg'
import v2DiscountStandard from '../../../../assets/svgs/v2-discount-standard.svg'
import v2DiscountWhite from '../../../../assets/svgs/v2-discount-white.svg'
import v2GuestStandard from '../../../../assets/svgs/v2-guest-standard.svg'
import v2GuestWhite from '../../../../assets/svgs/v2-guest-white.svg'
import v2GuestsEnrolledStandard from '../../../../assets/svgs/v2-guests-enrolled-standard.svg'
import v2GuestsEnrolledWhite from '../../../../assets/svgs/v2-guests-enrolled-white.svg'
import v2IntegrationStandard from '../../../../assets/svgs/v2-integration-standard.svg'
import v2IntegrationWhite from '../../../../assets/svgs/v2-integration-white.svg'
import v2LoyaltyStandard from '../../../../assets/svgs/v2-loyalty-standard..svg'
import v2LoyaltyWhite from '../../../../assets/svgs/v2-loyalty-white.svg'
import v2LoyaltyProgramStandard from '../../../../assets/svgs/v2-loyaltyprogram-standart.svg'
import v2LoyaltyProgramWhite from '../../../../assets/svgs/v2-loyaltyprogram-white.svg'
import v2MarketingStandard from '../../../../assets/svgs/v2-marketing-standard.svg'
import v2MarketingWhite from '../../../../assets/svgs/v2-marketing-white.svg'
import v2MenuCategoryStandard from '../../../../assets/svgs/v2-menu-category-standard.svg'
import v2MenuCategoryWhite from '../../../../assets/svgs/v2-menu-category-white.svg'
import v2MenuInventoryStandard from '../../../../assets/svgs/v2-menu-inventory-standard.svg'
import v2MenuInventoryWhite from '../../../../assets/svgs/v2-menu-inventory-white.svg'
import v2MenuProductStandard from '../../../../assets/svgs/v2-menu-product-standard.svg'
import v2MenuProductWhite from '../../../../assets/svgs/v2-menu-product-white.svg'
import v2MenuStandard from '../../../../assets/svgs/v2-menu-standard.svg'
import v2MenuWhite from '../../../../assets/svgs/v2-menu-white.svg'
import v2OrderAndPayStandard from '../../../../assets/svgs/v2-orderpay-standard.svg'
import v2OrderAndPayWhite from '../../../../assets/svgs/v2-orderpay-white.svg'
import v2ReferralStandard from '../../../../assets/svgs/v2-referal-standard.svg'
import v2ReferralWhite from '../../../../assets/svgs/v2-referal-white.svg'
import v2SettingStandard from '../../../../assets/svgs/v2-setting-standard.svg'
import v2SettingWhite from '../../../../assets/svgs/v2-setting-white.svg'
import v2StaffStandard from '../../../../assets/svgs/v2-staff-standard.svg'
import v2StaffWhite from '../../../../assets/svgs/v2-staff-white.svg'
import v2TableStandard from '../../../../assets/svgs/v2-table-standard.svg'
import v2TableWhite from '../../../../assets/svgs/v2-table-white.svg'
import v2TOStandard from '../../../../assets/svgs/v2-timeoff-standard.svg'
import v2TOWhite from '../../../../assets/svgs/v2-timeoff-white.svg'
import { getRetailTranslation } from '../auto_motorcycle_parts'

export const PetStoreSidebar = [
	{
		img: v2DashboardStandard,
		imgActive: v2DashboardWhite,
		key: 1,
		name: 'dashboard',
		text: getRetailTranslation('dashboard'),
		default: true,
		menus: [
			{
				img: v2DashboardStandard,
				imgActive: v2DashboardWhite,
				name: 'venue-dashboard',
				text: getRetailTranslation('venue-dashboard'),
				key: 1,
			},
			{
				img: v2AnalyticsStandard,
				imgActive: v2AnalyticsWhite,
				name: 'analytics',
				text: getRetailTranslation('revenue'),
				key: 2,
			},
		],
	},
	{
		img: v2MenuStandard,
		imgActive: v2MenuWhite,
		key: 2,
		name: 'menu',
		text: getRetailTranslation('menu-management'),
		menus: [
			{
				img: v2MenuProductStandard,
				imgActive: v2MenuProductWhite,
				name: 'items',
				text: getRetailTranslation('products'),
				key: 2,
			},
			{
				img: v2MenuCategoryStandard,
				imgActive: v2MenuCategoryWhite,
				name: 'categories',
				text: getRetailTranslation('categories'),
				key: 3,
			},
			{
				img: v2MenuInventoryStandard,
				imgActive: v2MenuInventoryWhite,
				name: 'inventory',
				text: getRetailTranslation('inventory'),
				key: 4,
			},
			{
				img: v2MenuProductStandard,
				imgActive: v2MenuProductWhite,
				name: 'consistent-inventory',
				text: getRetailTranslation('consistent-inventory'),
				key: 5,
			},
			{
				img: v2MenuProductStandard,
				imgActive: v2MenuProductWhite,
				name: 'collections',
				text: 'Collections',
				key: 6,
			},
		],
	},

	{
		img: v2StaffStandard,
		imgActive: v2StaffWhite,
		key: 3,
		name: 'staff',
		text: getRetailTranslation('staff-management'),
		menus: [
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'employees',
				text: getRetailTranslation('employees'),
				key: 1,
			},
			{
				img: v2TOStandard,
				imgActive: v2TOWhite,
				name: 'time-off-requests',
				text: getRetailTranslation('time-off-requests'),
				key: 2,
			},
			{
				img: v2IntegrationStandard,
				imgActive: v2IntegrationWhite,
				name: 'staff-integrations',
				text: getRetailTranslation('integrations'),
				key: 3,
			},
		],
	},
	{
		img: v2MarketingStandard,
		imgActive: v2MarketingWhite,
		key: 4,
		name: 'marketing',
		text: getRetailTranslation('marketing'),
		menus: [
			{
				img: v2DiscountStandard,
				imgActive: v2DiscountWhite,
				name: 'promotions',
				text: getRetailTranslation('promotions'),
				key: 1,
			},
			{
				img: v2ReferralStandard,
				imgActive: v2ReferralWhite,
				name: 'referrals',
				text: getRetailTranslation('referrals'),
				key: 2,
			},
			{
				img: v2CampaignStandard,
				imgActive: v2CampaignWhite,
				name: 'campaigns',
				text: getRetailTranslation('campaigns'),
				key: 3,
			},
			{
				img: v2StaffStandard,
				imgActive: v2StaffWhite,
				name: 'enduser-card',
				text: getRetailTranslation('cards'),
				key: 4,
			},
			{
				img: v2OrderAndPayStandard,
				imgActive: v2OrderAndPayWhite,
				name: 'wallet',
				text: getRetailTranslation('wallet'),
				key: 5,
			},
		],
	},
	{
		img: v2MenuStandard,
		imgActive: v2MenuWhite,
		key: 12,
		name: 'articles',
		text: 'Articles',
		menus: [
			{
				img: v2MenuProductStandard,
				imgActive: v2MenuProductWhite,
				name: 'list',
				text: 'List',
				key: 1,
			},
			{
				img: v2MenuCategoryStandard,
				imgActive: v2MenuCategoryWhite,
				name: 'categories',
				text: 'Categories',
				key: 2,
			},

		],
	},
	{
		img: v2SettingStandard,
		imgActive: v2SettingWhite,
		key: 5,
		name: 'settings',
		text: getRetailTranslation('settings'),
		menus: [
			{
				img: v2AccountSubscriptionStandard,
				imgActive: v2AccountSubscriptionWhite,
				name: 'subscription',
				text: getRetailTranslation('subscription'),
				key: 1,
			},
			{
				img: v2GuestStandard,
				imgActive: v2GuestWhite,
				name: 'account',
				text: getRetailTranslation('account'),
				key: 2,
			},
			{
				img: v2AccountVenueSpaceStandard,
				imgActive: v2AccountVenueSpaceWhite,
				name: 'space',
				text: getRetailTranslation('configurations'),
				key: 3,
			},
			{
				img: v2AdvancedAccountStandard,
				imgActive: v2AdvancedAccountWhite,
				name: 'store-settings',
				text: getRetailTranslation('store-settings'),
				key: 4,
			},
			{
				img: v2AccountVenueSpaceStandard,
				imgActive: v2AccountVenueSpaceWhite,
				name: 'store-integrations',
				text: getRetailTranslation('integrations'),
				key: 5,
			},
			{
				img: v2TableStandard,
				imgActive: v2TableWhite,
				name: 'hygiene-standard',
				text: getRetailTranslation('hygiene-standards'),
				key: 6,
			},
		],
	},
	{
		img: v2LoyaltyStandard,
		imgActive: v2LoyaltyWhite,
		key: 6,
		name: 'loyalty',
		text: getRetailTranslation('loyalty'),
		menus: [
			{
				img: v2LoyaltyProgramStandard,
				imgActive: v2LoyaltyProgramWhite,
				name: 'programs',
				text: getRetailTranslation('programs'),
				key: 1,
			},
			{
				img: v2GuestsEnrolledStandard,
				imgActive: v2GuestsEnrolledWhite,
				name: 'guests-enrolled',
				text: getRetailTranslation('enrolled-customers'),
				key: 2,
			},
		],
	},
	{
		img: v2DeliveryStandard,
		imgActive: v2DeliveryWhite,
		key: 7,
		name: 'delivery',
		text: getRetailTranslation('orders'),
		menus: [
			{
				img: v2DeliveryPickupStandard,
				imgActive: v2DeliveryPickupWhite,
				name: 'delivery-orders',
				text: getRetailTranslation('orders'),
				key: 1,
			},
			{
				img: v2GuestsEnrolledStandard,
				imgActive: v2GuestsEnrolledWhite,
				name: 'customers',
				text: getRetailTranslation('customers'),
				key: 2,
			},
			{
				img: v2OrderAndPayStandard,
				imgActive: v2OrderAndPayWhite,
				name: 'surveys-and-ratings',
				text: getRetailTranslation('surveys-and-ratings'),
				key: 3,
			},
		],
	},
]
