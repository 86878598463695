import {
	Card,
	CardContent,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import LoadingScreen from '../../components/custom/LoadingScreen'

import editIcon from '../../assets/svgs/edit.svg'
import plusIcon from '../../assets/svgs/plus.svg'
import referralIcon from '../../assets/svgs/referral.svg'
import searchIcon from '../../assets/svgs/search.svg'
import trashIcon from '../../assets/svgs/trash.svg'

import { t } from 'i18next'

export const surveyAndRatingsText = (text) => t(`surveyAndRatings.view.${text}`)

export default function SurveyAndRatings() {
	const _curCard = useRef(null)
	const [surveys, setSurveys] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const [loaded, setLoaded] = useState(false)
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		setLoaded(true)
	}, [])

	return (
		<>
			{loaded ? (
				<div className="p-6 mb-4">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row items-center justify-between p-7">
								<div className="flex flex-row items-center">
									<div className="bg-gray2 p-3 rounded-lg mr-6">
										<img
											src={referralIcon}
											alt={surveyAndRatingsText('referral')}
											className="w-6 h-6"
										/>
									</div>
									<div className="flex flex-col justify-center items-start">
										<p className="text-base text-black1 font-medium">
											{surveyAndRatingsText('surveys')}
										</p>
										<div className="flex flex-row justify-center items-center mt-1">
											<div className="text-xl text-black1 font-medium mr-5">
												{surveys.length} / ∞
											</div>
										</div>
									</div>
									<div className="w-[80px]" />
									<div className="flex flex-col justify-center items-start">
										<p className="text-base text-black1 font-medium">
											{surveyAndRatingsText('ratings')}
										</p>
										<div className="mt-3 text-xl text-black1 font-medium">
											0 / ∞
										</div>
									</div>
								</div>
								<div className="flex flex-row justify-start items-center gap-4">
									<div
										className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => {
											_curCard.current = null
											// setShowModal(true)
										}}
									>
										<img src={plusIcon} alt="plus" className="mr-2" />
										<span>{surveyAndRatingsText('create_survey')}</span>
									</div>
								</div>
							</div>
						</CardContent>
						<CardContent className="!p-0">
							{surveys.length ? (
								<>
									<div className="flex flex-col overflow-auto max-h-[70vh]">
										<TableContainer component={Paper} className="">
											<Table sx={{ minWidth: 650 }} aria-label="simple table">
												<TableHead className="bg-gray7">
													<TableRow>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															{surveyAndRatingsText('survey_title')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															{surveyAndRatingsText('created')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															{surveyAndRatingsText('status')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															{surveyAndRatingsText('responses')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															{surveyAndRatingsText('completes_at')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															{surveyAndRatingsText('rate')}
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base w-[100px]"
															align="right"
														>
															{surveyAndRatingsText('actions')}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{surveys
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((row, index) => (
															<TableRow key={index}>
																<TableCell
																	align="left"
																	className="!py-3.5 !text-gray5 !text-left !text-base !font-bold cursor-pointer"
																>
																	-
																</TableCell>
																<TableCell
																	align="left"
																	className="!py-3.5 !text-gray5 !text-left !text-base"
																>
																	-
																</TableCell>
																<TableCell
																	align="left"
																	className={`py-3.5 text-base cursor-pointer`}
																>
																	-
																</TableCell>
																<TableCell
																	align="left"
																	className={`!py-3.5 !text-gray5 !text-left !text-base cursor-pointer`}
																>
																	-
																</TableCell>
																<TableCell
																	align="left"
																	className="!py-3.5 !text-gray5 !text-left !text-base"
																>
																	-
																</TableCell>
																<TableCell
																	align="left"
																	className="!py-3.5 !text-gray5 !text-left !text-base"
																>
																	-
																</TableCell>
																<TableCell
																	align="left"
																	className="!flex flex-row !text-gray-500 !py-3.5 !text-gray5 !text-left !text-base !justify-left w-[100px]"
																>
																	<IconButton color="secondary">
																		<img src={trashIcon} alt="trash" />
																	</IconButton>
																	<IconButton
																		color="secondary"
																		onClick={(e) => {
																			e.stopPropagation()
																		}}
																	>
																		<img src={editIcon} alt="edit" />
																	</IconButton>
																</TableCell>
															</TableRow>
														))}
												</TableBody>
											</Table>
										</TableContainer>
									</div>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={surveys.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</>
							) : (
								<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
									<div className="bg-gray7 !p-2 rounded-full">
										<div className="bg-black1 !p-2 rounded-full">
											<img src={searchIcon} alt="search" />
										</div>
									</div>
									<span className="text-black1 text-lg">
										{surveyAndRatingsText('no_surveys_found')}
									</span>
									<span className="text-gray5 text-base mt-1">
										{surveyAndRatingsText('no_surveys_found_description')}
									</span>
									<div
										className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer mt-4"
										onClick={() => {
											_curCard.current = null
											// setShowModal(true)
										}}
									>
										<img src={plusIcon} alt="plus" className="mr-2" />
										<span>{surveyAndRatingsText('create_own_survey')}</span>
									</div>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</>
	)
}
