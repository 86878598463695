export const API_CHAT = {
  content_type: 'application/json',
  url: 'ai/post-chat',
  has_token: false
}

export const API_VB_ASSISTANT_ASK = {
  content_type: 'application/json',
  url: 'ai/vb-assistant-ask',
  has_token: false
}
