import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Card, CardContent, Divider } from '@mui/material'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import ConfirmReactivateVenueModal from '../../../components/venues/confirmReactivateVenueModal'
import PauseVenueModal from '../../../components/venues/pauseVenueModal'
import { isAccommodation } from '../../../helpers/venueHelpers'
import AccommodationService from '../../../services/accommodationService'
import VenueService from '../../../services/venuService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
export function VenueDetailsScreen() {
	const { short_code } = useSelector((state) => state.app)

	const { id: venue_id } = useParams()
	const { code: unit_code } = useParams()
	const [data, setData] = useState({})
	const [showReactivateModal, setShowReactivateModal] = useState(false)
	const [showPausedModal, setShowPausedModal] = useState(false)

	const navigate = useNavigate()

	const [loaded, setLoaded] = useState(false)

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		getVenueDetails()
	}, [])

	const getVenueDetails = () => {
		if (isAccommodation(venueType)) {
			AccommodationService.GetRentalUnits()
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('Reantal Unit', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'rental',
					})
					setLoaded(true)
					const details = res.data.find((item) => item.unit_code === unit_code)
					setData(details ? details[0] : null)
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('Reantal Unit', {
						action_category: 'get',
						action_outcome: 'fail',
						interaction_element: 'rental',
					})
					setLoaded(true)
					NotificationManager.error(
						err?.message ?? 'Failed to get rental unit details'
					)
					throw new Error('Failed to get rental unit details')
				})
		} else {
			VenueService.GetAllVenues()
				.then((res) => {
					setLoaded(true)
					const details = res.find((item) => item.app_key === venue_id)
					if (details) {
						setData(details)
					}
				})
				.catch((err) => {
					setLoaded(true)
					NotificationManager.error(
						err?.message ?? 'Failed to get venue details'
					)
				})
		}
	}

	const onReactivateVenue = () => {
		setShowReactivateModal(true)
	}

	const onGoToSubscriptions = (data) => {
		navigate(`/${data?.short_code}/admin/settings/subscription`)
	}

	const onReactivate = () => {
		setShowReactivateModal(false)
		VenueService.ReactivateVenue(data?.short_code)
			.then(() => {
				NotificationManager.success('Successfully reactivated')
				getVenueDetails()
				setShowPausedModal(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	const onPauseVenue = () => {
		setShowPausedModal(true)
	}

	const onPause = (payload) => {
		setShowPausedModal(false)
		VenueService.PauseVenue(payload, data?.short_code)
			.then(() => {
				NotificationManager.success('Successfully paused')
				getVenueDetails()
				setShowReactivateModal(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	if (!loaded) {
		return <LoadingScreen />
	}
	return (
		<div className="flex flex-col h-full px-4 py-3 w-[60%]">
			<div className="flex flex-row items-center mb-4">
				<span className="text-black1 text-xl font-medium">
					Venue Basic Details
				</span>
			</div>
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2">
						<div className="flex flex-row items-center">
							<span className="text-black1 text-xl font-medium"></span>
						</div>
					</div>
					<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg ">
						<div className="flex flex-col gap-4">
							<div className="text-xl font-bold text-black1">{data?.name}</div>
							<div className="flex flex-col">
								<div className="flex flex-col justify-center align-center">
									{isAccommodation(venueType) && (
										<span className="!text-gray5 !text-left !text-base">
											{data?.url}
										</span>
									)}
									{!isAccommodation(venueType) && (
										<span className="!text-gray5 !text-left !text-base">
											{data.short_code} / {data.app_key}
										</span>
									)}
									{!isAccommodation(venueType) && (
										<span className="!text-gray5 !text-left !text-base">
											{data.address != '-'
												? data.address.address_line1 +
												  ', ' +
												  data.address.postcode +
												  ', ' +
												  data.address.city +
												  ', ' +
												  data.address.state
												: 'Address: N/A'}
										</span>
									)}
									{isAccommodation(venueType) && (
										<span className="!text-gray5 !text-left !text-base">
											{data?.address ?? '-'} , {data?.country ?? '-'}
										</span>
									)}
								</div>
							</div>
						</div>
						{/* <div className='flex flex-row items-center gap-3'>
                    {parseInt(data.paused) === 0 &&
                        <span className={`text-black1 text-base px-3 py-1 rounded-full`}>
                            Pause
                        </span>
                    }
                    {parseInt(data.paused) === 1 &&
                        <span className={`text-black1 text-base px-3 py-1 rounded-full`}>
                            Reactivate
                        </span>
                    }
                </div> */}
					</div>
					<Divider />
					<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg ">
						<div className="flex flex-col gap-4">
							<div className="text-xl font-bold text-black1">Venue status</div>
							<div className="flex flex-col">
								<div>
									{/* <span className='text-xl font-medium text-black1 mr-3'>Venue ID: </span>
                            <span className='text-base font-medium text-black1 px-2 bg-gray2 rounded-full'>{data.app_key}</span> */}
								</div>
								<span className="text-gray5 text-sm">
									<span
										className={`text-base py-1 rounded-full ${
											data?.paused === 0 ? 'text-green2 ' : 'text-red2'
										}`}
									>
										{data?.paused === 0 ? 'Active' : 'Paused'}
									</span>
								</span>
								{data?.venuePauseHistories?.[0] ? (
									<span className={`text-base py-1 rounded-full text-gray5`}>
										Paused from{' '}
										{moment(data?.venuePauseHistories[0]?.start_time).format(
											'DD MMM YYYY, HH:mm'
										)}{' '}
										to{' '}
										{moment(data?.venuePauseHistories[0]?.end_time).format(
											'DD MMM YYYY, HH:mm'
										)}
									</span>
								) : null}
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							{parseInt(data?.paused) === 0 &&
								!data?.venuePauseHistories?.[0] && (
									<span
										className={`text-cyan1 text-base py-1 rounded-full cursor-pointer`}
										onClick={() => onPauseVenue()}
									>
										Pause
									</span>
								)}
							{(parseInt(data?.paused) === 1 ||
								data?.venuePauseHistories?.[0]) && (
								<span
									className={`text-cyan1 text-base py-1 rounded-full cursor-pointer`}
									onClick={() => onReactivateVenue()}
								>
									Reactivate
								</span>
							)}
						</div>
					</div>
					<Divider />

					{isAccommodation(venueType) && (
						<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg ">
							<div className="flex flex-col gap-4">
								<div className="text-xl font-bold text-black1">Venue plan</div>
								<div className="flex flex-col">
									<div className="flex flex-col justify-center align-center">
										<span
											className={`!text-left !text-base ${
												data?.plan?.active_plan === 1
													? 'text-green2 '
													: 'text-red2'
											}`}
										>
											{data?.plan?.active_plan == 1 ? 'Active' : 'Inactive'}
										</span>
										<span className="!text-gray5 !text-left !text-base">
											{data?.plan?.name} / {data?.plan?.plan_type}
										</span>
									</div>
								</div>
							</div>
							<div className="flex flex-row items-center gap-3">
								<span
									style={{ cursor: 'pointer' }}
									onClick={() => onGoToSubscriptions(data)}
									className={`text-cyan1 text-base py-1 rounded-full cursor-pointe`}
								>
									Review Plan
								</span>
							</div>
						</div>
					)}

					<Divider />
				</CardContent>
			</Card>
			<PauseVenueModal
				showModal={showPausedModal}
				onClose={() => setShowPausedModal(false)}
				onConfirm={(data) => {
					onPause(data)
				}}
			/>
			<ConfirmReactivateVenueModal
				showModal={showReactivateModal}
				onClose={() => setShowReactivateModal(false)}
				onConfirm={() => {
					onReactivate()
				}}
			/>
		</div>
	)
}
