export const cancellationNoShowData = [
	{ date: '2024-07-20', cancellations: 5, noShows: 3 },
	{ date: '2024-07-21', cancellations: 3, noShows: 2 },
	{ date: '2024-07-22', cancellations: 4, noShows: 4 },
]

export const cancellationNoShowColumns = [
	{ field: 'date', label: 'Date', align: 'center' },
	{ field: 'cancellations', label: 'Cancellations', align: 'center' },
	{ field: 'noShows', label: 'No-shows', align: 'center' },
]
