import { Box, Checkbox, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { boxStyle } from '../../../constants/types'

import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import v2TableWhite from '../../../assets/svgs/v2-table-white.svg'
import HygieneStandardService from '../../../services/hygieneStandardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export const checkViewModalText = (text) =>
	t(`hygieneStandard.checks.checkViewModal.${text}`)

export default function CheckViewModal({ showModal, data, onClose }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const [state, setState] = useState({
		available: 1,
	})

	const [checklistItems, setChecklistItems] = useState([''])
	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)

				// Check and set state for checklist items
				if (data?.checklist_items) {
					const checklistItemObjects = data.checklist_items.map((item) => ({
						item: item.item,
						is_completed: item.is_completed,
						id: item.id,
					}))
					setChecklistItems(checklistItemObjects)
				}
			}
		}
		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const handleCheckboxChange = async (index, event) => {
		const newChecklistItems = [...checklistItems]
		const item = newChecklistItems[index]

		item.is_completed = event.target.checked
		setChecklistItems(newChecklistItems)

		const payload = {
			is_completed: item.is_completed,
			hygiene_check_id: data?.id,
		}
		// Call API to update the backend
		try {
			setLoading(true)
			await HygieneStandardService.UpdateChecklistItemStatus(payload, item.id)
				.then(() => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'checklist',
					})
				})
				.catch(() => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'checklist',
					})
				})
			setLoading(false)
			NotificationManager.success(
				'Success updating checklist item',
				'Success',
				3000
			)
		} catch (error) {
			setLoading(false)
			// Handle error (e.g., show a notification)
			NotificationManager.error('Error updating checklist item')
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-2 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 text-white p-2 rounded-full">
								<div className="bg-black1 rounded-full">
									<img src={v2TableWhite} width={20} alt="icon" />
								</div>
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{checkViewModalText('hygieneChecklistItems')}
							</label>
						</div>
					</div>
					<div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
						{checkViewModalText('efficientlyTrackProgress')}
					</div>
					<div className="flex flex-col pb-0 p-024 mb-4">
						{checklistItems.map((checklistItem, index) => (
							<div key={index} className="flex items-center">
								<Checkbox
									checked={checklistItem.is_completed}
									onChange={(e) => handleCheckboxChange(index, e)}
									className="mr-2"
								/>
								{checklistItem.item}
							</div>
						))}
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{checkViewModalText('close')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
