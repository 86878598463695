import {
	Box,
	CircularProgress,
	InputAdornment,
	Modal,
	TextField,
	TextareaAutosize,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import LoyaltyService from '../../services/loyaltyService'
import { RInputDeclare } from '../custom/RInputDeclare'

import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import icon from '../../assets/svgs/coupon-outline.svg'
import { boxStyle } from '../../constants/types'
import { isAccommodation } from '../../helpers/venueHelpers'
import { getUserProfile } from '../../store/actions/auth'
import { vbAssistantAskQuestion } from '../../store/actions/chatbot'
import { KEYS } from '../../utils/storage'
import { productText } from '../../views/menu/products'
import AssistantButton from '../assistantButton'

export const AddProgramText = (text) =>
	t(`loyalty.programs.add_program.${text}`)

export default function AddProgram({ showModal, data, onClose }) {
	const [visible, setVisible] = useState(false)
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [rewardValue, setRewardValue] = useState(5)
	const [programId, setprogramId] = useState(0)
	const [loading, setLoading] = useState(false)
	const [checkValid, setCheckValid] = useState(false)

	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const dispatch = useDispatch()
	useEffect(() => {
		if (data && showModal) {
			setTitle(data.title)
			setDescription(data.description)
			setprogramId(data.id)
			setRewardValue(data.reward_value)
			setCheckValid(false)
		} else {
			setTitle('')
			setDescription('')
			setprogramId(0)
			setRewardValue(5)
			setCheckValid(false)
		}
		setVisible(showModal)
	}, [showModal])

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const handleRewardChange = (event) => {
		const inputValue = event.target.value
		if (inputValue === '' || (inputValue >= 0 && inputValue <= 100)) {
			setRewardValue(inputValue)
		}
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'loyalty_program_description'

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		setDescription(generated)
		setAiQuery('')
		setGenerated('')
	}

	const onSubmit = () => {
		if (!title) {
			NotificationManager.warning(AddProgramText('titleRequired'))
			return
		}

		if (!description) {
			NotificationManager.warning(AddProgramText('descriptionRequired'))
			return
		}

		if (!rewardValue) {
			NotificationManager.warning(AddProgramText('rewardValueRequired'))
			return
		}

		const payload = {
			title,
			description,
			reward_value: rewardValue,
			id: programId,
		}

		setLoading(true)

		const handleResponse = (response) => {
			setLoading(false)
			onClose(true)
			NotificationManager.success(
				AddProgramText('programSavedSuccess', { data: response?.data })
			)
		}

		const handleError = (err) => {
			setLoading(false)
			NotificationManager.error(err?.message ?? AddProgramText('errorMessage'))
			throw new Error(AddProgramText('programSaveError'))
		}

		if (programId != 0) {
			LoyaltyService.updateProgram(payload)
				.then(handleResponse)
				.catch(handleError)
		} else {
			LoyaltyService.storeProgram(payload)
				.then((response) => {
					handleResponse(response)
					dispatch(getUserProfile())
				})
				.catch(handleError)
		}
	}

	return (
		<div className="p-0 ml-2 mr-4 mt-6 mb-6">
			<div className="flex flex-row justify-start items-center pr-0 pt-0 bg-white m-4 rounded-12">
				<div className="p-2 bg-gray7 rounded-full">
					<div className="bg-gray8 p-2 rounded-full">
						<img src={icon} alt="icon" />
					</div>
				</div>

				<div className="flex flex-col items-start px-024 pb-016 pt-024 gap-0 ">
					<label className="text-20 font-semibold text-black1">
						{AddProgramText('loyaltyProgram')}
					</label>
					{!(
						venueType === 'sport_essentials' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads' ||
						venueType === 'home_decor'
					) && (
						<span className="text-gray5 text-base mt-0">
							{AddProgramText('boostGuestLoyalty')}
						</span>
					)}
					{(venueType === 'sport_essentials' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads' ||
						venueType === 'home_decor') && (
						<span className="text-gray5 text-base mt-0">
							{AddProgramText('boostCustomerLoyalty')}
						</span>
					)}
				</div>
			</div>
			<div className="max-w-[840px] bg-white border ml-4 mt-2 rounded-12">
				<div className="flex flex-col px-024 mb-4">
					{!(
						venueType === 'sport_essentials' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads' ||
						venueType === 'home_decor'
					) && (
						<>
							{!isAccommodation(venueType) && (
								<RInputDeclare
									value={AddProgramText('loyaltyProgramDescriptionGuest')}
									className="!mt-6 !mb-0 !text-black1 font-bold text-base"
								/>
							)}

							{isAccommodation(venueType) && (
								<RInputDeclare
									value={AddProgramText(
										'loyaltyProgramDescriptionAccommodation'
									)}
									className="!mt-6 !mb-0 !text-black1 font-bold text-base"
								/>
							)}
							<RInputDeclare
								value={AddProgramText('howItWorks')}
								className="!mt-6 !mb-2 !text-black1 font-bold text-base"
							/>
							{!isAccommodation(venueType) && (
								<ul>
									<li>{AddProgramText('earnPercentageReservation')}</li>
									<li>{AddProgramText('exampleReservation')}</li>
								</ul>
							)}
							{isAccommodation(venueType) && (
								<ul>
									<li>{AddProgramText('earnPercentageBooking')}</li>
									<li>{AddProgramText('exampleBooking')}</li>
								</ul>
							)}
						</>
					)}
					{(venueType === 'sport_essentials' ||
						venueType === 'pharmacy' ||
						venueType === 'fashion_threads' ||
						venueType === 'home_decor') && (
						<>
							<RInputDeclare
								value={AddProgramText('loyaltyProgramDescriptionCustomer')}
								className="!mt-6 !mb-0 !text-black1 font-bold text-base"
							/>
							<RInputDeclare
								value={AddProgramText('howItWorks')}
								className="!mt-6 !mb-2 !text-black1 font-bold text-base"
							/>
							<ul>
								<li>{AddProgramText('earnPercentageOrder')}</li>
								<li>{AddProgramText('exampleOrder')}</li>
							</ul>
						</>
					)}

					<RInputDeclare
						value={AddProgramText('title')}
						className="!mt-6 !mb-2 !text-black1 font-medium text-base"
					/>
					<TextField
						required
						size="small"
						error={checkValid && !title}
						id="filled-required"
						label={AddProgramText('required')}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder={AddProgramText('titlePlaceholder')}
					/>

					<RInputDeclare
						value={AddProgramText('reward')}
						className="!mt-6 !mb-2 !text-black1 font-medium text-base"
					/>
					<TextField
						required
						type="number"
						size="small"
						error={checkValid && !rewardValue}
						id="filled-required"
						label={AddProgramText('required')}
						value={rewardValue}
						onChange={handleRewardChange}
						placeholder={AddProgramText('rewardPlaceholder')}
						InputProps={{
							inputProps: {
								min: 5,
								max: 100,
							},
							startAdornment: (
								<InputAdornment position="start">
									<p className="text-[14px] !text-black1">%</p>
								</InputAdornment>
							),
						}}
					/>

					{!data?.reward_value && (
						<RInputDeclare
							value={AddProgramText('rewardPercentageHint')}
							className="!mt-2 !mb-2 !text-gray1 text-14 font-medium text-base"
						/>
					)}
					<RInputDeclare
						value={AddProgramText('description')}
						className="!mb-2 !mt-6 !text-black1 font-medium text-base"
					/>
					<TextareaAutosize
						required
						error={checkValid && !description}
						size="small"
						className="border border-black1"
						minRows={10}
						label={AddProgramText('required')}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						placeholder={AddProgramText('descriptionPlaceholder')}
						style={{ padding: '10px' }}
					/>
					<div className="flex flex-row justify-end mt-2">
						<AssistantButton
							onAction={() => handleOpenAIModal('Description')}
						/>
					</div>
				</div>

				<div className="flex flex-row justify-between space-x-4 h-[48px] mt-12 m-6">
					<button
						className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onSubmit}
					>
						{loading ? (
							<CircularProgress size={16} />
						) : (
							AddProgramText('confirm')
						)}
					</button>
				</div>
			</div>

			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{AddProgramText('aiAssistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{AddProgramText('aiAssistantHint')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-tranparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												AddProgramText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{productText('add_to')}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
