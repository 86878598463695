import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	ImageList,
	ImageListItem,
	Modal,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import creditIcon from '../../assets/svgs/credit-card.svg'
import RoomIcon1 from '../../assets/svgs/mdi_bed-single.svg'
import { boxStyle } from '../../constants/types'
import {
	default as AccommodationService,
	default as accommodationService,
} from '../../services/accommodationService'
import VenueService from '../../services/venuService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { ImgDragUpload } from '../common/ImgDragUpload'
import CustomSelect from '../custom/CustomSelect'
import CustomSwitch from '../custom/CustomSwitch'

export const addEditRoomText = (text) =>
	t(`components.accommodation.addEditRoomModal.${text}`)

const roomTypes = [
	{ value: 'living_room', title: 'Living room' },
	{ value: 'bedroom', title: 'Bedroom' },
	{ value: 'balcony', title: 'Balcony' },
	{ value: 'child_room', title: 'Child room' },
	{ value: 'other_spaces', title: 'Other Spaces' },
	{ value: 'bathroom', title: 'Bathroom' },
	{ value: 'full_bathroom', title: 'Full Bathroom' },
	{ value: 'kitchen', title: 'Kitchen' },
	{ value: 'full_kitchen', title: 'Full Kitchen' },
	{ value: 'gym', title: 'Gym' },
	{ value: 'exterior', title: 'Exterior' },
	{ value: 'patio', title: 'Patio' },
	{ value: 'utility_room', title: 'Utility room' },
]

const shared_spaces = [
	{ id: 1, name: 'Me' },
	{ id: 2, name: 'My family' },
	{ id: 3, name: 'Other guests' },
	{ id: 4, name: 'Flatmates/housemates' },
]

// other spaces and exterior should have only gallery

const RoomItem = (props) => {
	const [num, setNum] = useState(props.initialQuantity)

	const onHandleNumChange = (value) => {
		if (props.onChangeBedNumber) {
			props.onChangeBedNumber(value)
		}
		setNum(value)
	}

	return (
		<div className="flex justify-around py-[8px] px-[14px] rounded-[5px] border-[1px] border-[#CBD5E1] text-16 font-bold leading-[35px]">
			<div className="flex flex-1 flex-row items-center justify-start">
				<img src={props.icon} className="mr-2" />
				<p className="text-black1 font-bold">{props.title}</p>
				<span className="text-[#707070] font-normal">
					{props.content ? ' - ' + props.content : ''}
				</span>
			</div>
			<div className="flex flex-col">
				<div className="flex flex-row justify-start gap-0 rounded-[5px] overflow-hidden">
					<div
						className="flex flex-row justify-center align-center rounded-l-[5px] px-5 py-2 border-[#E0E0E0] border-[1px] cursor-pointer"
						onClick={() => num > 0 && onHandleNumChange(num - 1)}
					>
						<div className="text-base font-bold">-</div>
					</div>
					<div className="flex flex-row justify-center align-center   px-5 py-2 border-[#E0E0E0] border-[1px] cursor-pointer">
						<div className="text-base font-bold text-black1">{num}</div>
					</div>
					<div
						className="flex flex-row justify-center align-center rounded-r-[5px] px-5 py-2 border-[#E0E0E0] border-[1px] cursor-pointer"
						onClick={() => onHandleNumChange(num + 1)}
					>
						<div className="text-base font-bold">+</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default function AddEditRoomModal({
	showModal,
	beds,
	rentalUnitId,
	selectedRoom,
	onClose,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const [selectedRoomType, setSelectedRoomType] = useState('')
	const [selecteBeds, setSelecteBeds] = useState([])
	const [sharedSpacesSwitch, setSharedSpacesSwitch] = useState(false)
	const [hasPrivateSwitch, setHasPrivateSwitch] = useState(false)
	const [selectedSharedSpaces, setSelectedSharedSpaces] = useState([])
	const [bedQuantities, setBedQuantities] = useState({})

	const [images, setImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	])

	const navigate = useNavigate()

	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		setVisible(showModal)

		if (selectedRoom?.id != null) {
			setSelectedRoomType(selectedRoom.type)
			setHasPrivateSwitch(selectedRoom.has_private_bathroom == 1 ? true : false)
			setSharedSpacesSwitch(selectedRoom.shared_space == 1 ? true : false)
			setSelectedSharedSpaces(selectedRoom.shared_space_with || [])

			// Populate bed quantities from the response
			const bedQuantitiesFromResponse = {}
			selectedRoom?.beds?.forEach((bed) => {
				bedQuantitiesFromResponse[bed.id] = bed.pivot.quantity
			})
			setBedQuantities(bedQuantitiesFromResponse)
		}
	}, [showModal])

	useEffect(() => {
		if (selectedRoom?.gallery) {
			const tempImages = [...images]
			selectedRoom?.gallery.forEach((item, index) => {
				tempImages[index] = item
			})
			setImages(tempImages)
		}
	}, [selectedRoom])

	const onSubmit = () => {
		if (!selectedRoomType) {
			return
		}
		setLoading(true)
		const details = {
			id: selectedRoom?.id ? selectedRoom.id : null,
			type: selectedRoomType,
			has_private_bathroom: hasPrivateSwitch,
			shared_space: sharedSpacesSwitch,
			shared_space_with: selectedSharedSpaces,
			beds: selecteBeds,
		}

		AccommodationService.AddReantalUnitAccomodationRoom(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('AddReantalUnitAccomodationRoom', {
					action_category: 'AccomodationRoom',
					action_outcome: 'success',
					interaction_element: 'AccomodationRoom',
				})
				setLoading(false)
				if (selectedRoom?.id) {
					NotificationManager.success(
						'Room has been updated successfully',
						'Success',
						3000
					)
				} else {
					NotificationManager.success(
						'Room has been added successfully',
						'Success',
						3000
					)
				}

				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=1`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('AddReantalUnitAccomodationRoom', {
					action_category: 'AccomodationRoom',
					action_outcome: 'fail',
					interaction_element: 'AccomodationRoom',
				})
				setLoading(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error(
					'An error occurred while adding a room for accommodation module'
				)
			})
	}

	const showSleepingArrangements = () => {
		return (
			selectedRoomType === 'living_room' ||
			selectedRoomType === 'bedroom' ||
			selectedRoomType === 'child_room'
		)
	}

	const showSharedSpace = () => {
		return (
			selectedRoomType === 'bedroom' ||
			selectedRoomType === 'bathroom' ||
			selectedRoomType === 'full_bathroom' ||
			selectedRoomType === 'kitchen' ||
			selectedRoomType === 'full_kitchen' ||
			selectedRoomType === 'living_room' ||
			selectedRoomType === 'patio' ||
			selectedRoomType === 'utility_room' ||
			selectedRoomType === 'gym'
		)
	}

	const handleShareSpacesSwitch = (value) => {
		setSharedSpacesSwitch(value)
	}

	const handlePrivateBathSwitch = (value) => {
		setHasPrivateSwitch(value)
	}

	const handleSharedSpaceChange = (name, checked) => {
		if (checked) {
			setSelectedSharedSpaces((prevSpaces) => [...prevSpaces, name])
		} else {
			setSelectedSharedSpaces((prevSpaces) =>
				prevSpaces.filter((space) => space !== name)
			)
		}
	}

	const onHadleRoomItemChanged = (roomId, quantity) => {
		let tmp = Object.assign([], selecteBeds)
		var index = tmp.findIndex((item) => item.id == roomId)
		if (index !== -1) {
			tmp.splice(index, 1)
			tmp.push({ id: roomId, quantity: quantity })
			setSelecteBeds(tmp)
		} else {
			tmp.push({ id: roomId, quantity: quantity })
			setSelecteBeds(tmp)
		}
	}

	const onUploadImage = (image, index) => {
		let formData
		formData = new FormData()
		formData.append('type', 'gallery')
		if (image?.type === 'file' && image?.url != null) {
			formData.append('photo', image.file)
		}
		accommodationService
			.UploadRentalUnitRoomPhoto(formData, rentalUnitId, selectedRoom.id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('UploadRentalUnitRoomPhoto', {
					action_category: 'upload',
					action_outcome: 'success',
					interaction_element: 'upload',
				})
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=1`)
					navigate(0)
				}, 1000)
				NotificationManager.success('Image has been uploaded successfully')
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				throw new Error(
					'An error occurred while uploading a photo for a room for accommodation module'
				)
			})
	}

	const onCancelImage = (index) => {
		setLoading(true)
		VenueService.deleteFacilitiesPhoto(images[index].photo_id)
			.then((res) => {
				setLoading(false)
				if (images[index]) {
					const tempImages = [...images]
					tempImages[index] = null
					setImages(tempImages)
				}
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Unable do delete Photo')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-0 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={creditIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{selectedRoom?.id != null
								? `${addEditRoomText('editRoom')} ${selectedRoom.name}`
								: addEditRoomText('addRoom')}
						</label>
					</div>
					<div className="overflow-auto max-h-[70vh] flex flex-col justify-between px-2 m-4 gap-3 relative">
						<p className="text-black1 mt-4 text-18 font-semibold leading-[15px]">
							{addEditRoomText('roomType')}
						</p>
						<CustomSelect
							values={roomTypes}
							value={
								roomTypes.find((item) => item.value === selectedRoomType)?.title
							}
							className={'!bg-white !border-gray6 !h-10'}
							placeholder={addEditRoomText('selectRoomType')}
							renderRow={(item) => <p>{item.title}</p>}
							handleChange={(v) => setSelectedRoomType(v.value)}
						/>

						{showSharedSpace() && (
							<>
								<div className="flex flex-row justify-between items-center bg-white rounded-lg my-3">
									<div className="flex flex-row gap-4">
										<div className="flex flex-col">
											<div>
												<span className="text-18 font-medium text-black1 mr-3">
													{addEditRoomText('sharedSpaces')}
												</span>
											</div>
											<span className="text-gray5 text-sm">
												{addEditRoomText('sharedSpacesDescription')}
											</span>
										</div>
									</div>
									<div className="flex flex-row items-center gap-3">
										{sharedSpacesSwitch ? (
											<span className="text-sm font-medium text-[#027A48]">
												{addEditRoomText('on')}
											</span>
										) : (
											<span className="text-sm font-medium text-gray5">
												{addEditRoomText('off')}
											</span>
										)}
										<CustomSwitch
											active={sharedSpacesSwitch}
											onClick={handleShareSpacesSwitch}
										/>
									</div>
								</div>

								<p className="text-18 font-medium text-black1 mr-3 mt-4 leading-[5px]">
									{addEditRoomText('onlyIfHasSharedSpaces')}
								</p>
								<span className="text-gray5 text-sm">
									{addEditRoomText('sharedSpacesWhoElse')}
								</span>
								<FormGroup>
									{shared_spaces.map((item) => (
										<FormControlLabel
											key={item.name}
											control={
												<Checkbox
													onChange={(e) =>
														handleSharedSpaceChange(item.name, e.target.checked)
													}
													checked={selectedSharedSpaces.includes(item.name)}
												/>
											}
											label={item.name}
										/>
									))}
								</FormGroup>
							</>
						)}

						{selectedRoomType === 'bedroom' && (
							<div className="flex flex-row justify-between items-center bg-white rounded-lg my-3">
								<div className="flex flex-row gap-4">
									<div className="flex flex-col">
										<div>
											<span className="text-18 font-medium text-black1 mr-3">
												{addEditRoomText('hasPrivateEnSuiteBathroom')}
											</span>
										</div>
										<span className="text-gray5 text-sm">
											{addEditRoomText('privateBathroomDescription')}
										</span>
									</div>
								</div>
								<div className="flex flex-row items-center gap-3">
									{hasPrivateSwitch ? (
										<span className="text-sm font-medium text-[#027A48]">
											{addEditRoomText('on')}
										</span>
									) : (
										<span className="text-sm font-medium text-gray5">
											{addEditRoomText('off')}
										</span>
									)}
									<CustomSwitch
										active={hasPrivateSwitch}
										onClick={handlePrivateBathSwitch}
									/>
								</div>
							</div>
						)}

						{showSleepingArrangements() && (
							<>
								<p className="text-black1 text-18 font-semibold mt-4 leading-[15px]">
									{addEditRoomText('sleepingArrangements')}
								</p>
								<div className="flex flex-col gap-5">
									{beds.map((item, index) => (
										<RoomItem
											title={item.name}
											id={item.id}
											initialQuantity={bedQuantities[item.id] || 0}
											onChangeBedNumber={(quantity) =>
												onHadleRoomItemChanged(item.id, quantity)
											}
											content={
												item.size_from
													? `${item.size_from} - ${item.size_to} cm wide`
													: null
											}
											icon={RoomIcon1}
										/>
									))}
								</div>
							</>
						)}

						{selectedRoom?.id != null && (
							<>
								<p className="text-black1 mt-4 text-18 font-semibold leading-[15px]">
									{addEditRoomText('photos')}
								</p>
								<ImageList
									variant="quilted"
									style={{
										overflowY: 'scroll',
										minHeight: '550px',
										maxHeight: '550px',
										paddingRight: '10px',
										marginBottom: '10px',
									}}
									cols={3}
									gap={23}
								>
									{images.map((item, index) => (
										<ImageListItem key={'img_uploader' + index} className="">
											<ImgDragUpload
												backgroundColor={'#fff'}
												image={item ? { url: item?.photo_path } : null}
												onCancel={() => onCancelImage(index)}
												onChange={(img) => onUploadImage(img, index)}
												label={addEditRoomText('uploadImageLabel')}
												classes="max-h-[180px] p-2 mb-2 mt-2"
											/>
										</ImageListItem>
									))}
								</ImageList>
							</>
						)}
						<div className="flex gap-3">
							<button
								className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center py-[10px] text-cyan1"
								onClick={onClose}
							>
								{addEditRoomText('cancel')}
							</button>
							<button
								className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center py-[10px] text-white"
								onClick={onSubmit}
							>
								{loading ? (
									<CircularProgress size={16} />
								) : (
									addEditRoomText('save')
								)}
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
