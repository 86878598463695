import React from 'react';
import { Composer, InputToolbar, Send } from '../react-web-gifted-chat';
import { AiOutlineSend, } from 'react-icons/ai'
import { BsEmojiLaughing } from 'react-icons/bs'
// import { Button } from 'antd'
import { isEmpty } from '../../../utils/common';


export const renderInputToolbar = (props) => (
  <InputToolbar
    {...props}
    containerStyle={{
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#999',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 8,
      paddingBottom: 8,
    }}
    primaryStyle={{ alignItems: 'center', display: 'flex' }}
  />
);

const CustomComposer = ({
  props,
  onPressEmoji,
}) => {
  return (
    <div
      className={'flex flex-row w-full'} >
      <Composer
        {...props}
        placeholder={'Type Something'}
        textInputStyle={{
          flex: 1,
          textAlign: 'left',
          color: 'black',
          fontSize: 16,
          lineHeight: '20px',
          backgroundColor: 'white',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 12,
          marginLeft: 0,
        }}
      />
      <div className={'flex flex-row justify-center items-center gap-2'}>
        {/* <Button shape="circle" icon={<BsEmojiLaughing color='#999' size={20} />} style={{ borderWidth: 0, marginBottom: 0 }} onClick={onPressEmoji} /> */}
        <BsEmojiLaughing color='#999' size={20}  onClick={onPressEmoji} />
        <Send
          {...props}
          containerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 8,
            height: 20,
          }}>
          <AiOutlineSend color={isEmpty(props.text) ? '#999' : '#240b3b'} size={20} />
        </Send>
      </div>
    </div>
  );
};

export const renderComposer = (
  props,
  onPressEmoji,
  onPressLocation,
  onImageUpload,
  onImageCapture,
  onRecord
) => (
  <CustomComposer
    props={props}
    onPressEmoji={onPressEmoji}
    onPressLocation={onPressLocation}
    onImageUpload={onImageUpload}
    onImageCapture={onImageCapture}
    onRecord={onRecord}
  />
);

export const renderSend = () => null;
