import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import { columnsRenderTable } from './columns'

const RetailTable = ({ orderTransformer, data = [] }) => {
	const { short_code } = useSelector((state) => state.app)
	const navigate = useNavigate()

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onActionRetail = (id) => {
		navigate(`/${short_code}/admin/retail/order/${id}`)
	}
	const displayedData = useMemo(() => {
		const start = page * rowsPerPage
		const end = start + rowsPerPage
		return data.slice(start, end)
	}, [data, page, rowsPerPage])

	return (
		<div className="mt-4">
			<GenericTable
				data={displayedData}
				columns={columnsRenderTable(orderTransformer, false)}
				onView={(item) => onActionRetail(item.id)}
				className="!pt-0"
			/>
			<GenericPagination
				count={data.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				setPage={setPage}
				menuItems={[10, 25, 50]}
				px="!pr-[23px]"
			/>
		</div>
	)
}

export default RetailTable
