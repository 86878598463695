import { t } from 'i18next'
import moment from 'moment'
import React from 'react'
import detailIcon from '../../../assets/svgs/detail.svg'

export const reservationInfoText = (text) =>
	t(`reservations.reservationdetail.ReservationInfo.${text}`)

export default function ReservationInfo({ data }) {
	return (
		<div className="flex flex-col">
			<p className="mb-2 text-black1 text-lg font-medium">
				{reservationInfoText('detailsInfo')}
			</p>
			<div className="flex flex-row bg-white rounded-lg p-8 items-center gap-8">
				<div className="p-4 bg-[#F9F7F7] rounded-lg">
					<img src={detailIcon} alt="detail" />
				</div>
				<div className="flex-1 flex flex-row gap-16">
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('startTime')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{moment(data.start_time).format('MMMM Do YYYY, h:mm:ss a')}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('endTime')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{moment(data.end_time).format('MMMM Do YYYY, h:mm:ss a')}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('insertionType')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data.insertion_type ?? ''}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('source')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data.source ?? ''}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('seating')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data.seating_arrangement ?? '-'}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('nrGuests')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data.guest_count}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('notes')}
							</span>
							<span className="text-gray5 text-base font-bold">
								{data.notes ?? '-'}
							</span>
						</div>
						<div className="flex flex-col gap-1">
							<span className="text-gray5 text-sm font-normal">
								{reservationInfoText('confirm')}
							</span>
							{data.confirmed === 0 ? (
								<span className="text-yellow1 text-base font-bold">
									{reservationInfoText('pending')}
								</span>
							) : data.confirmed === 1 ? (
								<span className="text-green2 text-base font-bold">
									{reservationInfoText('confirmed')}
								</span>
							) : data.confirmed === 2 ? (
								<span className="text-red2 text-base font-bold">
									{reservationInfoText('cancelled')}
								</span>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
