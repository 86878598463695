import Hikvision from '../../../assets/images/vision_track/frame1.png'
import Unv from '../../../assets/images/vision_track/frame2.png'
import './index.css'

const getColor = (value) => {
	const { data = '' } = value

	if (data.toLowerCase() === 'configured') {
		return {
			color: '#088B3A',
			background: '#E7F7ED',
		}
	} else if (data.toLowerCase() === 'validating') {
		return {
			color: '#F4BC5E',
			background: '#FDF6DD',
		}
	} else if (data.toLowerCase() === 'not configured') {
		return {
			color: '#6C757D',
			background: '#F2F2F2',
		}
	} else {
		return {
			color: '#EB5842',
			background: '#FBE8D8',
		}
	}
}

const getImage = (value = '') => {
	if (value.toLowerCase() === 'hikvision') {
		return Hikvision
	} else if (value.toLowerCase() === 'unv') {
		return Unv
	} else {
		return 'other'
	}
}

const getSetupStatus = (value) => {
	const { data = '' } = value

	return (
		<div
			className="setup-status"
			style={{
				...getColor(value),
				minWidth: 'fit-content',
			}}
		>
			<span className="setup-text">{data}</span>
		</div>
	)
}

export const deviceColumns = [
	{
		label: 'Device-id',
		field: 'device_id',
		align: 'center',
	},
	{ label: 'Device Nickname', field: 'device_nickname', align: 'left' },
	{ label: 'Device Type', field: 'type', align: 'left' },
	{ label: 'Location', field: 'location', align: 'left' },
	{
		label: 'Brand',
		field: 'brand',
		align: 'left',
		cellComponent: ({ data }) =>
			data === 'Other' ? (
				<div>{data}</div>
			) : (
				<img alt={data} src={getImage(data)} />
			),
	},
	{
		label: 'Setup Status',
		field: 'setup_status',
		align: 'left',
		cellComponent: (value) => {
			return getSetupStatus(value)
		},
	},
	{ label: 'Actions', field: 'actions', align: 'right' },
]

export const brandData = [
	{
		label: 'HIKVISION',
		value: 'Hikvision',
	},
	{
		label: 'UNV',
		value: 'UNV',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]

export const typeData = [
	{
		label: 'Camera',
		value: 'camera',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]

export const deviceStatusData = [
	{
		label: 'Configured',
		value: 'configured',
	},
	{
		label: 'Non-configured',
		value: 'non-configured',
	},
	{
		label: 'Disconnected',
		value: 'disconnected',
	},
	{
		label: 'Validating',
		value: 'validating',
	},
]

export const setupItems = [
	{ label: 'Setup status', value: 'setup_status' },
	{ label: 'Configured', value: 'configured' },
	{ label: 'Validating', value: 'validating' },
	{ label: 'Disconnected', value: 'disconnected' },
]
