import apiFactory from "../utils/apiFactory";
import {
	API_LOCATIONS,
	API_SEATING_ARRANGEMENTS,
	API_SHAPE,
	API_TABLE,
	API_TABLE_AVAILABILITY,
	API_TABLE_AVAILABLE,
	API_CREATE_TABLE,
	API_CREATE_SEATING_ARRANGEMENT,
	API_TABLE_DETAILS,
	API_TABLE_SPLIT,
	API_TABLE_MOVE,
	API_TABLE_MERGE,
	API_TABLE_ASSIGN_GUESTS,
} from "../constants/API";

export default class TableService {
	static GetAll = async () => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE}`,
			method: "GET",
		};
		return apiFactory(api);
	};
	static GetOne = async (index) => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE_DETAILS}/${index}`,
			method: "GET",
		};
		return apiFactory(api);
	};
	static GetTableAvailableTimeSlot = async (date, tableId) => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE_AVAILABILITY}`,
			method: "GET",
		};
		return apiFactory(api, {}, {
			date: date,
			table_id: tableId,
			});
	};
	static GetSeatingArrangements = async () => {
		let api = {
			content_type: "application/json",
			url: `${API_SEATING_ARRANGEMENTS}`,
			method: "GET",
		};
		return apiFactory(api);
	};
	static UpdateSeatingArrangements = async (payload) => {
		let api = {
			content_type: "application/json",
			url: `${API_SEATING_ARRANGEMENTS}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};
	static DeleteSeatingArrangement = async (id) => {
		let api = {
			content_type: "application/json",
			url: `${API_SEATING_ARRANGEMENTS}`,
			method: "DELETE",
		};
		return apiFactory(api, { id });
	};
	static GetLocations = async () => {
		let api = {
			content_type: "application/json",
			url: `${API_LOCATIONS}`,
			method: "GET",
		};
		return apiFactory(api);
	};
	static AddLocation = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_LOCATIONS}`,
			method: "POST",
		};
		return apiFactory(api, payload);
	};
	static GetShapes = async () => {
		let api = {
			content_type: "application/json",
			url: `${API_SHAPE}`,
			method: "GET",
		};
		return apiFactory(api);
	};
	static GetAvailables = async param => {
		let api = {
			content_type: "application/json",
			url: API_TABLE_AVAILABLE,
			method: "GET",
		};
		return apiFactory(api, {}, param);
	};
	static AddTable = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_CREATE_TABLE}`,
			method: "POST",
		};
		return apiFactory(api, payload);
	};
	static MergeTable = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE_MERGE}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};
	static SplitTable = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE_SPLIT}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};
	static MoveTable = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE_MOVE}`,
			method: "PUT",
		};
		return apiFactory(api, payload);
	};
	static CreateSeatingArrangement = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_CREATE_SEATING_ARRANGEMENT}`,
			method: "POST",
		};
		return apiFactory(api, payload);
	};
	static AssignGuests = async payload => {
		let api = {
			content_type: "application/json",
			url: `${API_TABLE_ASSIGN_GUESTS}`,
			method: "POST",
		};
		return apiFactory(api, payload);
	};
}
