import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import React from "react";

import { Card, CardContent } from "@mui/material";
// import './index.css';


const IntegrationInventory = () => {
    return (
        <>
            <div className="overflow-auto" style={{ height: "calc(100vh - 76px - 68px)", margin: 0, padding: 0 }}>
                <div className="h-full right-0 p-6 z-20 flex flex-col">
                    <Card>
                        <CardContent className="!p-0">
                            <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5 pb-5">
                                <div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										Inventory Integration
									</span>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </div>

            </div>
        </>
    );
};

export default IntegrationInventory;
