import React, { useState } from 'react'
import GenericPagination from '../../../generic-table/generic-pagination'
import GenericTable from '../../../generic-table/generic-table'
import { OrdersColumns } from './data'

const OrdersTable = ({data = []}) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	return (
		<>
			<GenericTable
				columns={OrdersColumns}
				data={data}
			/>
			<GenericPagination
				rowsPerPage={rowsPerPage}
				page={page}
				setPage={setPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				count={data.length}
				onPageChange={handleChangePage}
			/>
		</>

	)
}

export default OrdersTable
