import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'
import saleIcon from '../assets/svgs/sale_white.svg'
import { boxStyle } from '../constants/types'
import { checkQrCode } from '../services/restaurantService'
import { feedbackText } from './feedback'

export default function QrCodeModal({ showModal, onConfirm, onClose, type }) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const [qrCode, setQrCodeValid] = useState(null)

	useEffect(() => {
		if (showModal) {
			loadQrCode()
		}
		setVisible(showModal)
	}, [showModal])

	const loadQrCode = () => {
		checkQrCode()
			.then((data) => {
				setQrCodeValid(data?.data ?? null)
			})
			.catch((error) => {
				throw new Error('Problem on getRegisterConfig')
			})
	}

	const onCloseModal = () => {
		onClose(null)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={saleIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{feedbackText('qr_code_menu')}
						</label>
					</div>
					<div className="flex flex-row p-024">
						<div className="flex-1 flex justify-center items-center">
							<img src={qrCode} />
						</div>
						<div className="flex-1">
							<p>{feedbackText('display_qr_info')}</p>
						</div>
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCloseModal}
						>
							{t('Cancel')}
						</button>
						<button className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white">
							{loading ? (
								<CircularProgress size={16} />
							) : (
								<a href={qrCode} download="qr_code.png">
									{t('Download')}
								</a>
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
