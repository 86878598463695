import {
	Box,
	CircularProgress,
	InputAdornment,
	MenuItem,
	Modal,
	TextField,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { boxStyle } from '../../constants/types'
import CustomIconButton from '../common/IconButton'

import { t } from 'i18next'
import calendarIcon from '../../assets/svgs/calendar_white.svg'
import Svg_payment from '../../assets/svgs/cash.svg'
import moneyIcon from '../../assets/svgs/money.svg'
import usersIcon from '../../assets/svgs/users.svg'
import ReservationService from '../../services/reservationService'
import CustomSelect from '../custom/CustomSelect'
import CustomSwitch from '../custom/CustomSwitch'
import { RInputDeclare } from '../custom/RInputDeclare'

const assignOrderConfirmModalText = (text) =>
	t(`components.reservation.AssignOrderConfirmModal.${text}`)

const payWithOptions = [
	{
		label: 'Cash',
		value: 'Cash',
	},
	{
		label: 'Card',
		value: 'Card',
	},
]
export default function AssignOrderConfirmModal({
	showModal,
	data,
	guestData,
	reservation_id,
	onClose,
	onSubmit,
}) {
	const [loading, setLoading] = useState(false)
	const [visible, setVisible] = useState(false)
	const [total, setTotal] = useState({
		subTotal: 0,
		billAmount: 0,
		subCharge: 0,
		orderDiscount: 0,
		tax: 0,
	})
	const [payedWith, setPayedWith] = useState('Cash')
	const [guestEnrolled, setGuestEnrolled] = useState(false)
	const [promoData, setPromoData] = useState([])
	const [selectedPromo, setSelectedPromo] = useState(null)

	useEffect(() => {
		setVisible(showModal)
		if (showModal && guestData?.id) {
			ReservationService.GetGuestValidPromotion(guestData?.id)
				.then((res) => {
					let promo = res.promotions?.filter(
						(item) => item.discounts.length > 0
					)
					setPromoData(promo)
					if (data.promotion_id) {
						let tmpPromo = promo.find((item) => item.id == data.promotion_id)
						if (tmpPromo) {
							setSelectedPromo(tmpPromo.title)
						}
					}
				})
				.catch((err) => {
					NotificationManager.error(err?.message ?? 'Something went wrong!')
				})
		}
	}, [showModal])

	useEffect(() => {
		if (data && data.products.length > 0) {
			let subTotal = 0
			let billAmount = 0
			let orderDiscount = 0

			data.products.forEach((item) => {
				subTotal += item.quantity * item.price
			})

			let tmpPromo = promoData.find((item) => item.id == selectedPromo)

			if (tmpPromo) {
				let discount = tmpPromo?.discounts[0]
				if (discount.type == 'fixed') {
					orderDiscount = parseFloat(discount.value)
				} else {
					orderDiscount = (subTotal * parseFloat(discount.value)) / 100
				}
			}
			billAmount =
				subTotal +
				total.subCharge +
				total.orderDiscount +
				total.tax -
				orderDiscount

			setTotal((value) => ({ ...value, subTotal, billAmount, orderDiscount }))
		}
	}, [data, selectedPromo])

	const handelGuestEnrolledSwitch = (value) => {
		setGuestEnrolled(value)
	}

	const handleSubmit = () => {
		onSubmit({
			payment_method: payedWith,
			enroll_guest: guestEnrolled,
			total: total.billAmount,
			subtotal: total.subTotal,
			discount_value: total.orderDiscount,
			promotion_id: selectedPromo,
		})
		// setLoading(true);
		// OrderService.storeOrder({
		// 	...data,
		// 	order_products: data.products.map(p => ({
		// 		product_id: p.id,
		// 		product_quantity: p.quantity,
		// 		product_total_price: p.price * p.quantity,
		// 	})),
		// })
		// 	.then(({ data }) => {
		// 		setLoading(false);
		// 		onClose(true);
		// 		NotificationManager.success(data?.message ?? "Order has been saved successfully");
		// 	})
		// 	.catch(err => {
		// 		setLoading(false);
		// 		NotificationManager.error(err?.message ?? "Something went wrong!");
		// 	})
	}

	const handleCancel = () => {
		onClose()
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<CustomIconButton
						icon={calendarIcon}
						title={assignOrderConfirmModalText('orderConfirmation')}
					/>
					<div className="px-6 pt-2 h-[60vh] overflow-auto">
						<div className="flex flex-col">
							<p className="mb-4 text-black1 text-lg font-medium">
								{assignOrderConfirmModalText('guestDetails')}
							</p>
							<div className="flex flex-row bg-white border rounded-lg p-6 items-center gap-5">
								<div className="p-4 bg-[#F9F7F7] rounded-lg">
									<img src={usersIcon} alt="desk" />
								</div>
								<div className="flex-1 flex flex-row gap-10">
									<div className="flex flex-col gap-5">
										<div className="flex flex-col gap-1">
											<span className="text-gray5 text-sm font-normal">
												{assignOrderConfirmModalText('name')}
											</span>
											<span className="text-gray5 text-base font-bold">
												{guestData?.name ?? assignOrderConfirmModalText('noGuestName')}
											</span>
										</div>
										<div className="flex flex-col gap-1">
											<span className="text-gray5 text-sm font-normal">
												{assignOrderConfirmModalText('phone')}
											</span>
											<span className="text-gray5 text-base font-bold">
												{guestData?.phone ?? assignOrderConfirmModalText('noGuestPhone')}
											</span>
										</div>
									</div>
									<div className="flex flex-col gap-5">
										<div className="flex flex-col gap-1">
											<span className="text-gray5 text-sm font-normal">
												{assignOrderConfirmModalText('email')}
											</span>
											<span className="text-gray5 text-base font-bold">
												{guestData?.email ?? assignOrderConfirmModalText('noGuestEmail')}
											</span>
										</div>
										<div className="flex flex-col gap-1">
											<span className="text-gray5 text-sm font-normal">
												{assignOrderConfirmModalText('notes')}
											</span>
											<span className="text-gray5 text-base font-bold">
												{assignOrderConfirmModalText('noNotes')}
											</span>
										</div>
									</div>
									<div className="flex flex-col gap-5">
										<div className="flex flex-col gap-1">
											<span className="text-gray5 text-sm font-normal">
												{assignOrderConfirmModalText('vipStatus')}
											</span>
											<span className="text-gray5 text-base font-bold">
												{assignOrderConfirmModalText('regular')}
											</span>
										</div>
										<div className="flex flex-col gap-1">
											<span className="text-gray5 text-sm font-normal">
												{assignOrderConfirmModalText('address')}
											</span>
											<span className="text-gray5 text-base font-bold">
												{guestData?.address ?? assignOrderConfirmModalText('noAddress')}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-row justify-between items-center border rounded-lg px-4 py-2 mt-4">
							<span className="text-black1 text-xl font-medium">
								{assignOrderConfirmModalText('loyaltyEnrolled')}
							</span>
							<div className="flex flex-row items-center gap-2">
								{guestEnrolled ? (
									<span className="text-sm font-medium text-[#027A48]">
										{assignOrderConfirmModalText('on')}
									</span>
								) : (
									<span className="text-sm font-medium text-gray5">
										{assignOrderConfirmModalText('off')}
									</span>
								)}
								<CustomSwitch
									active={guestEnrolled}
									onClick={handelGuestEnrolledSwitch}
								/>
							</div>
						</div>
						<div>
							<p className="my-4 text-black1 text-lg font-medium">
								{assignOrderConfirmModalText('orderSummary')}
							</p>
							<div className="border rounded-lg">
								<TableContainer component={Paper}>
									<Table size="small" aria-label="a dense table">
										<TableHead className="bg-gray7">
											<TableRow>
												<TableCell className="!text-gray5 !text-left !font-medium !text-base">
													{assignOrderConfirmModalText('itemMenu')}
												</TableCell>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="right"
												>
													{assignOrderConfirmModalText('qty')}
												</TableCell>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="right"
												>
													{assignOrderConfirmModalText('price')}
												</TableCell>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="right"
												>
													{assignOrderConfirmModalText('tax')}
												</TableCell>
												<TableCell
													className="!text-gray5 !text-left !font-medium !text-base"
													align="right"
												>
													{assignOrderConfirmModalText('subtotal')}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.products.length > 0 &&
												data.products.map((product, index) => (
													<TableRow key={index}>
														<TableCell
															component="th"
															scope="row"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															{product.title}
														</TableCell>
														<TableCell
															align="right"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															{product.quantity}
														</TableCell>
														<TableCell
															align="right"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															${product.price}
														</TableCell>
														<TableCell
															align="right"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															$0
														</TableCell>
														<TableCell
															align="right"
															className="!py-3.5 !text-gray5 !text-left !text-base"
														>
															${product.quantity * product.price}
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</div>
						<div className="flex flex-col mb-6">
							<RInputDeclare
								value={assignOrderConfirmModalText('promotionsAvailable')}
								className="text-16 !text-black1 font-medium"
							/>
							<CustomSelect
								values={promoData}
								value={
									selectedPromo
										? promoData.find((item) => item.id == selectedPromo)?.title
										: ''
								}
								placeholder={assignOrderConfirmModalText('checkAvailablePromotions')}
								renderRow={(item) => <p>{item.title}</p>}
								handleChange={(value) => setSelectedPromo(value?.id)}
							/>
						</div>
						<div className="flex flex-col">
							<p className="mb-4 text-black1 text-lg font-medium leading-10">
								{assignOrderConfirmModalText('paymentInformation')}
							</p>
							<div className="flex flex-row bg-white border rounded-lg p-4 items-center gap-8">
								<div className="p-4 bg-[#F9F7F7] rounded-lg">
									<img src={moneyIcon} alt="desk" />
								</div>
								<div className="flex-1 flex flex-col gap-5 flex-1">
									<div className="flex flex-row justify-between items-center">
										<span className="text-gray5 text-base font-normal">
											{assignOrderConfirmModalText('subtotal')}
										</span>
										<span className="text-black1 text-base">
											${total.subTotal}
										</span>
									</div>
									{data?.discount_value && (
										<div className="flex flex-row justify-between items-center">
											<span className="text-gray5 text-base font-normal">
												{assignOrderConfirmModalText('promotion')}
											</span>
											<span className="text-black1 text-base">
												-${total.orderDiscount}
											</span>
										</div>
									)}
									<div className="flex flex-row justify-between items-center">
										<span className="text-black1 text-base font-bold">
											{assignOrderConfirmModalText('total')}
										</span>
										<div className="flex flex-row gap-2">
											{total?.orderDiscount && (
												<span className="text-gray5 text-base">
													<del>${total.orderDiscount}</del>
												</span>
											)}
											<span className="text-black1 text-base font-bold">
												${total.billAmount}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className="flex flex-row justify-between items-center space-x-4 mt-6 py-5"
							style={{ borderTop: '1px solid #E4E4E4' }}
						>
							<div className="flex flex-col">
								<div className="flex flex-row gap-12 items-center p-3">
									<TextField
										id="outlined-select"
										size="medium"
										variant="outlined"
										select
										className="flex flex-row items-center gap-3 border ml-2"
										value={payedWith}
										onChange={(event) => setPayedWith(event.target.value)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<div className="p-2 bg-gray6 rounded-lg w-10 h-10 mr-4">
														<img src={Svg_payment} alt="payment" />
													</div>
												</InputAdornment>
											),
											title: assignOrderConfirmModalText('paymentMethod'),
											style: { minWidth: '200px' },
										}}
									>
										{payWithOptions.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
												className="font-normal text-gray3 text-xs"
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>
							<div className="flex flex-row !h-11">
								<button
									className="border rounded-lg bg-white grow h-full flex justify-center items-center px-14 text-cyan1 mr-4"
									onClick={handleCancel}
								>
									{assignOrderConfirmModalText('cancel')}
								</button>
								<button
									className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center px-14 text-white"
									onClick={handleSubmit}
								>
									{loading ? (
										<CircularProgress size={16} />
									) : (
										assignOrderConfirmModalText('confirm')
									)}
								</button>
							</div>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
