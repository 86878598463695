export const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 731,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
};

export const smallBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 531,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "16px",
};

export const rightBoxStyle = {
  position: "absolute",
  width: 531,
  height: '100%',
  right: '0',
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const ROLES = [
  {
    value: '0',
    label: "WORKER",
  },
  {
    value: '1',
    label: "OFFICER",
  },
  {
    value: '2',
    label: "MASTER",
  },
];

export const OWNER = [
  {
    value: '0',
    label: "OWNER1",
  },
  {
    value: '1',
    label: "OWNER2",
  },
  {
    value: '2',
    label: "OWNER3",
  },
  {
    value: '3',
    label: "OWNER4",
  },
];

export const MANAGER = [
  {
    value: '0',
    label: "MANAGER1",
  },
  {
    value: '1',
    label: "MANAGER2",
  },
  {
    value: '2',
    label: "MANAGER3",
  },
  {
    value: '3',
    label: "MANAGER4",
  },
];

export const SALARY_FREQUENCYS = [
  {
    value: "hourly",
    label: "Hourly",
  },
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "bi-weekly",
    label: "Bi-weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "annual",
    label: "Annual",
  },
  {
    value: "custom",
    label: "Custom",
  },
];

export const tableColors = [
	{ bgColor: "#D9F6DA", textColor: "#8cf58f" }, // available
	{ bgColor: "#FEE8D9", textColor: "#fcc39d" }, // Reserved
	{ bgColor: "#EDEAFF", textColor: "#d3ccff" }, // not available
];