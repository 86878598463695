import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'

export const activityDetailsText = (text) =>
	t(`guests.guestDetails.ActivityDetails.${text}`)

export const ActivityTable = ({ data }) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const columns = [
		{
			field: 'name',
			label: activityDetailsText('activityName'),
			align: 'left',
		},
		{
			field: 'date',
			label: activityDetailsText('date'),
			align: 'left',
		},
	]

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (value) => {
		setRowsPerPage(value)
	}

	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-l pl-6 font-medium">
							{activityDetailsText('allGuestActivities')}
						</span>
					</div>
					<GenericTable
						data={data.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)}
						columns={columns}
					/>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
							{activityDetailsText('noActivities')}
						</div>
					)}
					<GenericPagination
						count={data.length}
						page={page}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						setPage={setPage}
					/>
				</CardContent>
			</Card>
		</div>
	)
}
