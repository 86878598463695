import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { faCalendarAlt as calendarIcon } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarMonth } from "@mui/icons-material";
import {
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";

import { t } from 'i18next'
import CustomMonthCalendar from "../../../components/custom/CustomMonthCalendar/CustomMonthCalendar";
import LoadingScreen from "../../../components/custom/LoadingScreen";
import MarketingService from "../../../services/marketingService";
import AvailabilityModal from "./availabilityModal";
import PromotionsEventsModal from "./promotionsEventsModal";

export const availabiltyText = (text) =>
	t(`marketing.promotions.availability.${text}`)

// import './index.css';

const PromotionsAvailability = () => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	const monthOptions = []
	for (let i = 0; i < months.length; i++) {
		monthOptions.push({
			value: i + 1,
			label: months[i] + ' ' + new Date().getFullYear(),
		})
	}
	const [month, setMonth] = useState(1)
	const [modalEvents, setModalEvents] = useState([])
	const [events, setEvents] = useState([])
	const [calendarDate, setCalendarDate] = useState(new Date())

	const [loading, setLoading] = useState(true)
	const [visibleManage, setVisibleManage] = useState(false)

	useEffect(() => {
		var month = new Date().getMonth()
		setMonth(month + 1)
		getCaledarDetails(month + 1)
	}, [])

	const getCaledarDetails = (month) => {
		const payload = {
			year: new Date().getFullYear(),
			month: month,
		}
		MarketingService.GetCalendarPromotions(payload)
			.then((res) => {
				setLoading(false)
				setEvents(res)
			})
			.catch((err) => {
				setLoading(false)
			})
	}

	const onDaySelect = (day) => {
		const displayEvents = events.filter((item) => item.day === day)
		if (displayEvents.length > 0) {
			setModalEvents(displayEvents)
		}
	}

	const setNewMonth = (newMonth) => {
		setMonth(newMonth)
		let cd = calendarDate
		cd.setMonth(newMonth - 1)
		setCalendarDate(cd)
		setLoading(true)
		getCaledarDetails(newMonth)
	}

	if (loading) {
		return <LoadingScreen />
	}
	return (
		<>
			<div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
				<div className="h-full right-0 p-6 z-20 flex flex-col">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5 pb-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-s font-medium">
										{availabiltyText('welcome')}
									</span>
								</div>
								<div className="flex flex-row items-center gap-4">
									<TextField
										id="outlined-select"
										select
										className="w-[150px]"
										value={month}
										onChange={(event) => setNewMonth(event.target.value)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<CalendarMonth />
												</InputAdornment>
											),
										}}
									>
										{monthOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>
							<div
								className="bg-black1 flex flex-row items-center px-4 py-2 mx-4 mb-4 text-white rounded-lg w-max cursor-pointer gap-2"
								onClick={() => setVisibleManage(true)}
							>
								<FontAwesomeIcon icon={calendarIcon} />
								<span>{availabiltyText('manage_visibility')}</span>
							</div>
							<Card className="pb-4 pr-4 pl-4 pt-0 h-full">
								<CardContent className="!p-0">
									<CustomMonthCalendar
										events={events}
										monthValue={calendarDate}
										onChange={onDaySelect}
									/>
								</CardContent>
							</Card>
						</CardContent>
					</Card>
				</div>
			</div>

			<PromotionsEventsModal
				showModal={modalEvents.length > 0}
				data={modalEvents}
				onClose={() => setModalEvents([])}
				onRefresh={null}
			/>
			<AvailabilityModal
				showModal={visibleManage}
				onClose={() => setVisibleManage(false)}
			/>
		</>
	)
}
  

export default PromotionsAvailability;
