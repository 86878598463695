import apiFactory from "../utils/apiFactory";
import { API_FEATURE_FEEDBACK } from "../constants/API";

export default class FeatureFeedbackService {
    static submitFeedBack = async rawData => {
        let api = {
            content_type: "application/json",
            url: API_FEATURE_FEEDBACK,
            method: "POST",
        };
        return apiFactory(api, rawData);
    };
}
