import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import CustomDateTimePicker from '../../components/custom/CustomDateTimePicker'
import FoodDeliveryDashboard from '../../components/dashboard/food-delivery/FoodDeliveryDashboard'
import RetailDashboard from '../../components/dashboard/retail/general/RetailDashboard'
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue
} from '../../helpers/venueHelpers'
import DashboardService from '../../services/dashboardService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { KEYS } from '../../utils/storage'
import AccommodationDashboard from './accommodation-dashboard'
import './index.css'
import RestaurantDashboard from './restaurant-dashboard'

const SalesScreen = () => {
	const { t } = useTranslation()
	const [data, setData] = useState([])
	const [turnaroundData, setTurnaroundData] = useState([])
	const [analysis, setAnalysis] = useState([])
	const [series2, setSeries2] = useState([])

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const [occupancyDate, setOccupancyDate] = useState(new Date())
	const [turnaroundTimeDate, setTurnaroundTimeDate] = useState(new Date())
	const [analysisDate, setAnalysisDate] = useState(new Date())
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)
	const { short_code } = useSelector((state) => state.app)

	const { show_upgrade_from_trial } = useSelector((state) => state.app)

	const options = {
		stroke: {
			show: false,
		},
		chart: {
			id: 'apexchart-01',
		},
		xaxis: {
			categories: [
				'Mon, 20',
				'Tue, 27',
				'Wed, 04',
				'Fri, 12',
				'Sun, 19',
				'Mon, 26',
			],
		},
		fill: {
			colors: ['#240b3b'],
		},
		dataLabels: {
			style: {
				colors: ['#00000000'],
			},
		},
	}

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		if (
			!(
				venueType === 'sport_essentials' ||
				venueType === 'pharmacy' ||
				venueType === 'fashion_threads' ||
				venueType === 'home_decor'
			)
		) {
			GetAverageTableOccupancy(occupancyDate)
			GetReservationWeekly()
			GetTableTurnaroundTime(turnaroundTimeDate)
			GetReservationSourceAnalysis(analysisDate)
		}
	}, [])

	const GetAverageTableOccupancy = (currentDate) => {
		let payload = {
			date: moment(currentDate).format('YYYY-MM-DD 23:59:59'),
		}
		DashboardService.GetAverageTableOccupancy(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'table occupancy',
				})
				setData(res.data)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'table occupancy',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const GetReservationWeekly = () => {
		DashboardService.GetReservationWeekly()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'reservation weekly',
				})
				setSeries2(res.data)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'reservation weekly',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const GetTableTurnaroundTime = (currentDate) => {
		let payload = {
			date: moment(currentDate).format('YYYY-MM-DD 23:59:59'),
		}
		DashboardService.GetTableTurnaroundTime(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'table turnaround',
				})
				setTurnaroundData(res.data)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'table turnaround',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const GetReservationSourceAnalysis = (currentDate) => {
		let payload = {
			date: moment(currentDate).format('YYYY-MM-DD 23:59:59'),
		}
		DashboardService.GetReservationSourceAnalysis(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'reservation analysis',
				})
				setAnalysis(res.data)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('dashboard service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'reservation analysis',
				})
				NotificationManager.error(error?.message ?? 'Something went wrong!')
			})
	}

	const onChangeOccupancyDateChanged = (currentDate) => {
		setOccupancyDate(currentDate)
		GetAverageTableOccupancy(currentDate)
	}

	const onTurnaroundTimeDateChanged = (currentDate) => {
		setTurnaroundTimeDate(currentDate)
		GetTableTurnaroundTime(currentDate)
	}

	const onAnalysisDateChanged = (currentDate) => {
		setAnalysisDate(currentDate)
		GetReservationSourceAnalysis(currentDate)
	}


	if (isRetailVenue(venueType)) {
		return (
			<RetailDashboard showUpgradedFromTrialProp={show_upgrade_from_trial} />
		)
	}
	if (isFB(venueType) && !cookieBasicInfo.allow_reservation_from) {
		return (
			<FoodDeliveryDashboard
				showUpgradedFromTrialProp={show_upgrade_from_trial}
			/>
		)
	}
	if (isFB(venueType) && cookieBasicInfo.allow_reservation_from === 1) {
		return <RestaurantDashboard />
	}
	if (isEntertainmentVenue(venueType)) {
		// TODO: Add entertainment dashboard
		return false
	}

	if (isAccommodation(venueType)) {
		return <AccommodationDashboard />
	}

	return (
		<div
			className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 py-5">
						<span className="text-black1 text-xl font-medium">
							{t('DASHBOARD')}
						</span>
					</div>
					<div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
						<div className="grid sm:grid-cols-3 grid-cols-1 gap-5">
							<Card className="col-span-2">
								<CardContent className="!p-0">
									<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
										<div className="flex flex-row items-center">
											<span className="text-black1 text-xl font-medium">
												{isAccommodation(venueType)
													? 'Average Accommodation Occupancy'
													: 'Average Table Occupancy'}
											</span>
										</div>
										<CustomDateTimePicker
											onlyDate={true}
											classes="small"
											value={occupancyDate}
											onClick={onChangeOccupancyDateChanged}
										/>
									</div>
									<div className="flex flex-col overflow-auto">
										<TableContainer component={Paper} className="mt-4">
											<Table aria-label="simple table">
												<TableHead className="bg-gray7">
													<TableRow>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															Date
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															Occupancy Rate (%)
														</TableCell>
														{isAccommodation(venueType) && (
															<TableCell
																className="!text-gray5 !text-left !font-medium !text-base"
																align="right"
															>
																Nr of bookings
															</TableCell>
														)}
													</TableRow>
												</TableHead>
												<TableBody>
													{data.map((item, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.date}
															</TableCell>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.rate}
															</TableCell>

															{isAccommodation(venueType) && (
																<TableCell
																	align="left"
																	className="py-3 !text-gray5 !text-left !text-base"
																>
																	0
																</TableCell>
															)}
														</TableRow>
													))}
												</TableBody>
											</Table>
											<TablePagination
												rowsPerPageOptions={[10, 25, 100]}
												component="div"
												count={data.length}
												rowsPerPage={rowsPerPage}
												page={page}
												className="!p-2"
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</TableContainer>
									</div>
								</CardContent>
							</Card>
							<Card>
								<CardContent className="!p-0">
									<div className="flex flex-col grow bg-white rounded-12 px-0">
										<div className="flex flex-row justify-between items-baseline w-full border-b pl-6 shadow-sm py-6">
											<label className="font-medium text-xl">
												{isAccommodation(venueType)
													? 'Bookings weekly'
													: 'Reservations weekly'}
											</label>
										</div>
										<div className="px-6">
											<Chart
												className="mt-4"
												options={options}
												series={series2}
												type="bar"
												width={'100%'}
												height={322}
											/>
										</div>
									</div>
								</CardContent>
							</Card>
						</div>
						{!isAccommodation(venueType) && (
							<Card className="mt-5">
								<CardContent className="!p-0">
									<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
										<div className="flex flex-row items-center">
											<span className="text-black1 text-xl font-medium">
												Table Turnaround Time
											</span>
										</div>
										<CustomDateTimePicker
											onlyDate={true}
											classes="small"
											value={turnaroundTimeDate}
											onClick={onTurnaroundTimeDateChanged}
										/>
									</div>
									<div className="flex flex-col overflow-auto">
										<TableContainer component={Paper} className="mt-4">
											<Table aria-label="simple table">
												<TableHead className="bg-gray7">
													<TableRow>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base w-14 whitespace-nowrap"
															align="left"
														>
															Reservation ID
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="left"
														>
															Start time
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															End time
														</TableCell>
														<TableCell
															className="!text-gray5 !text-left !font-medium !text-base"
															align="right"
														>
															Turnaround Time (minutes)
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{turnaroundData.map((item, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.id}
															</TableCell>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.start_time}
															</TableCell>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.end_time}
															</TableCell>
															<TableCell
																align="left"
																className="py-3 !text-gray5 !text-left !text-base"
															>
																{item.time}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
											<TablePagination
												rowsPerPageOptions={[10, 25, 100]}
												component="div"
												count={data.length}
												rowsPerPage={rowsPerPage}
												page={page}
												className="!p-2"
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</TableContainer>
									</div>
								</CardContent>
							</Card>
						)}
						<Card className="mt-5">
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{isAccommodation(venueType)
												? 'Accommodations Booking Analysis'
												: 'Reservation Source Analysis'}
										</span>
									</div>
									<CustomDateTimePicker
										onlyDate={true}
										classes="small"
										value={analysisDate}
										onClick={onAnalysisDateChanged}
									/>
								</div>
								<div className="flex flex-col overflow-auto">
									<TableContainer component={Paper} className="mt-4">
										<Table aria-label="simple table">
											<TableHead className="bg-gray7">
												<TableRow>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base w-14"
														align="left"
													>
														Nr
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base w-1/2"
														align="left"
													>
														{isAccommodation(venueType)
															? 'Accommodation'
															: 'Source'}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{isAccommodation(venueType)
															? 'Number of Bookings'
															: 'Number of Reservations'}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{analysis.map((item, index) => (
													<TableRow key={index}>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.id}
														</TableCell>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.source}
														</TableCell>
														<TableCell
															align="left"
															className="py-3 !text-gray5 !text-left !text-base"
														>
															{item.count}
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={data.length}
											rowsPerPage={rowsPerPage}
											page={page}
											className="!p-2"
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</TableContainer>
								</div>
							</CardContent>
						</Card>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default SalesScreen
