import "react-datepicker/dist/react-datepicker.css";
import { createUUID, isEmpty } from "../../utils/common";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VenueService from "../../services/venuService";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  TablePagination,
} from "@mui/material";
import TableModal from "../../components/table/tableModal";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import v2Dashboard from '../../assets/svgs/v2-dashboard-standard.svg';
import v2Details from '../../assets/svgs/v2-table-standard.svg';
import plusIcon from "../../assets/svgs/plus.svg";
import TableEmptyData from "../../components/table/TableEmptyData";
import LoadingScreen from "../../components/custom/LoadingScreen";
import AddVenueModal from "../../components/venues/addVenueModal";
import { DashboardOutlined, AssignmentOutlined} from "@mui/icons-material"
import { useSelector } from "react-redux";

export function VenueListScreen() {
  const navigate = useNavigate();
	const { short_code } = useSelector(state => state.app);

  const [newModal, setNewModal] = useState(false);
  const [data, setData] = useState([]);
  const [venue, setVenue] = useState({})
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loaded, setLoaded] = useState(false);


  // const toggleSelection = id => {
  // 	if (selection.find(o => o === id) === undefined) {
  // 		setSelection(selection.concat([id]));
  // 	} else {
  // 		setSelection(selection.filter(o => o !== id));
  // 	}
  // };

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
	setLoaded(false);
    VenueService.GetAllVenues()
      .then((res) => {
        setLoaded(true);
        setData(res);
      })
      .catch((e) => {
        setLoaded(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


const onGoProfile = data => {
  navigate(`/${short_code}/admin/venues/venues/${data?.app_key}`);
};

const onGoDashboard = data => {
  navigate(`/${data?.short_code}/admin/dashboard/venue-dashboard`);
};

  return (
    <div>
      {loaded ? (
        <div className="flex flex-col h-full p-6">
          <Card className="">
            <CardContent className="!p-0">
              <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
                <div className="flex flex-row items-center">
                  <span className="text-black1 text-xl font-medium">
                    Venue List
                  </span>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <div
                    className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
                    onClick={() => setNewModal(true)}
                  >
                    <img src={plusIcon} alt="search" className="mr-2" />
                    <span>New Venue</span>
                  </div>
                </div>
              </div>
              {data.length ? (
                <div className="flex flex-col  overflow-auto">
                  <TableContainer component={Paper} className="mt-4">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead className="bg-gray7">
                        <TableRow key={createUUID()}>
                          <TableCell
                            className="!text-gray5 !text-left !font-medium !text-base"
                            align="center"
                          >
                            Venue
                          </TableCell>
                          <TableCell
                            className="!text-gray5 !text-left !font-medium !text-base"
                            align="center"
                          >
                            Shortcode / Appkey
                          </TableCell>
                          <TableCell
                            className="!text-gray5 !text-left !font-medium !text-base"
                            align="center"
                          >
                            Plan
                          </TableCell>
                          <TableCell
                            className="!text-gray5 !text-left !font-medium !text-base whitespace-nowrap"
                            align="center"
                          >
                            Status
                          </TableCell>
                          <TableCell
                            className="!text-gray5 !text-left !font-medium !text-base whitespace-nowrap"
                            align="center"
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((row, index) => (
                          <TableRow key={index} onClick={() => {}}>
                            <TableCell
                              align="center"
                              className="!py-3.5 !text-gray5 !text-left !text-base"
                             
                            >
                              <div className="flex flex-row justify-start align-center">
                                {isEmpty(row.logo) ? (
                                  <div
                                    className="w-[60px] h-[60px] bg-gray6"
                                    style={{ borderRadius: "5px" }}
                                  />
                                ) : (
                                  <img
                                    src={row.logo}
                                    style={{ borderRadius: "5px" }}
                                    className=" w-[60px] h-[60px]"
                                    alt="preview"
                                  />
                                )}
                                <div className="flex flex-col justify-center align-center ml-4">
                                  <span className="ml-2 !text-black1 !text-left !text-base">
                                    {row.name}
                                  </span>
                                  <span className="ml-2 !text-gray5 !text-left !text-base">
                                    {row.address != "-"
                                      ? row.address.address_line1 + ', ' + row.address.postcode + ', ' + row.address.city + ', ' + row.address.state
                                      : "Address: N/A"}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="!py-3.5 !text-gray5 !text-left !text-base"
                            >
                              {row.short_code} / {row.app_key}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="!py-3.5 !text-gray5 !text-left !text-base "
                            >
                              <div className="flex flex-col justify-center align-center">
                                <span className="ml-2 !text-gray5 !text-left !text-base">
                                  {row?.plan?.active_plan == 1
                                    ? "Active"
                                    : "Inactive"}
                                </span>
                                <span className="ml-2 !text-gray5 !text-left !text-base">
                                  {row?.plan?.name} / {row?.plan?.plan_type}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              className="!py-3.5 !text-gray5 !text-left !text-base"
                            >
                              <span
                                className={`text-base px-3 py-1 rounded-full ${
                                  row.paused === 0
                                    ? "bg-[#ECFDF3] text-green2 "
                                    : "text-red2 bg-red3"
                                }`}
                              >
                                ●&nbsp;&nbsp;
                                {row.paused === 0 ? "Active" : "Paused"}
                              </span>
                            </TableCell>
                            <TableCell
                              align="right"
                              className="!py-3.5 !text-gray5 !text-left !text-base"
                            >
                              <div className="flex flex-row">
                                  <div className="mr-2 cursor-pointer" onClick={() => onGoProfile(row)}>
                                    <AssignmentOutlined />
                                  </div>
                                  <div className="cursor-pointer" onClick={() =>onGoDashboard(row)}>
                                    <DashboardOutlined />
                                  </div>

																	
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  {!data.length && (
                    <div className="mt-[60px] align-middle text-gray-500">
                      {" "}
                      NO DATA{" "}
                    </div>
                  )}
                </div>
              ) : (
                <div className="border border-x-0 border-b-0 mt-5 mb-10">
                  <TableEmptyData
                    content="venues"
                    btnLabel="New Venue"
                    onAction={() => setNewModal(true)}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      ) : (
        <LoadingScreen />
      )}
      <AddVenueModal
        showModal={newModal}
        onConfirm={() => {
          setLoaded(false);
          refreshData();
        }}
        onClose={() => setNewModal(false)}
        onRefresh={null}
      />


    </div>
  );
}
