import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
} from "@mui/material";

import { boxStyle } from "../../constants/types";
import PlanUpgraded from '../../assets/svgs/plan_upgraded.svg'

import {useDispatch, useSelector} from "react-redux";
import {SET_SHOW_UPGRADE_FROM_TRIAL} from "../../store/actions/types";
import {KEYS, setStorageKey} from "../../utils/storage";

export default function UpgradedFromTrial({ showModal, data, rentalUnitId, onClose }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const { subscription_plan } = useSelector((state) => state.app);

  const onCloseModal = () => {
    dispatch({
      type: SET_SHOW_UPGRADE_FROM_TRIAL,
      payload: false
    });
    setStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL, false);
    onClose(true);
  };

  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);


  return (
    <div>
      <Modal
        open={visible}
        onClose={onClose}
        className="class-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...boxStyle, width: 450 }} className="outline-none">
          <div className="flex flex-col justify-between px-2 m-4 relative">
            <div className="flex justify-center">
              <img
                  src={PlanUpgraded}
                  alt="Confirmation Icon"
                  className="w-[85px] h-[85px] py-4"
              />
            </div>
            <p className="text-black1 text-center font-semibold leading-[25px] mb-2 " style={{
              fontSize: "30px",
              lineHeight: "35px",
            }}>
              Thank you for using VenueBoost
              {" "}
            </p>

            <div className="text-center text-17 flex flex-row items-center px-024 pb-010 gap-3">
              Your account has been updated to: {subscription_plan.name} plan {subscription_plan.recurring}
            </div>


            <div className="flex mt-8 gap-3">
              <button
                className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center py-[10px] text-cyan1"
                onClick={onCloseModal}
              >
                Close
              </button>
              <button
                  className="border border-gray6 rounded-lg bg-[#240b3b]  font-bold grow h-full flex justify-center items-center py-[10px] text-white"
                  onClick={onCloseModal}
              >
                Start exploring
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
