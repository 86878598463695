import React from 'react';
import './index.css';

const NotfoundScreen = () => {
  return (
		<div className={'align-col-middle view-tables'} style={{ height: '55vh' }}>
			Not found route 404
		</div>
	)
};

export default NotfoundScreen;
