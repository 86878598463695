import { AccessTimeOutlined, SearchOutlined } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../helpers/venueHelpers'
import { KEYS } from '../../utils/storage'

export const commonAnswerText = (text) =>
	t(`components.helpfulModal.help.${text}`)

const helpListFB = [
	{
		title: commonAnswerText('venueBoostIncreaseRevenue'),
		content: commonAnswerText('venueBoostDynamicPricing'),
		creator: 'VenueBoost',
		updatedAt: 'December 2, 2023',
	},
	{
		title: commonAnswerText('venueBoostSimplifyInventory'),
		content: commonAnswerText('venueBoostInventoryInsights'),
		creator: 'VenueBoost',
		updatedAt: 'December 2, 2023',
	},
	{
		title: commonAnswerText('venueBoostLoyaltyFeatures'),
		content: commonAnswerText('venueBoostRewardGuests'),
		creator: 'VenueBoost',
		updatedAt: 'December 2, 2023',
	},
	{
		title: commonAnswerText('venueBoostStreamlineMarketing'),
		content: commonAnswerText('venueBoostMarketingAutomation'),
		creator: 'VenueBoost',
		updatedAt: 'December 2, 2023',
	},
	{
		title: commonAnswerText('guestLoyaltyProgram'),
		content: commonAnswerText('venueBoostDiningGuestLoyaltyProgram'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostLoyaltyManagementGoal'),
		content: commonAnswerText('venueBoostLoyaltyManagement'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostKeyFeatureInventoryManagement'),
		content: commonAnswerText('venueBoostInventoryManagement'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostBusinessInsights'),
		content: commonAnswerText('venueBoostBusinessInsightsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostMarketingOffer'),
		content: commonAnswerText('venueBoostMarketingSolutions'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostStaffManagementBenefits'),
		content: commonAnswerText('venueBoostStaffManagementBenefitsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
]

const helpListAccommodation = [
	{
		title: commonAnswerText('venueBoostGuestManagement'),
		content: commonAnswerText('venueBoostGuestManagementFeatures'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostMarketingAutomation2'),
		content: commonAnswerText('venueBoostMarketingAutomationContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostiCalIntegration'),
		content: commonAnswerText('venueBoostiCalIntegrationContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostInventoryManagementAccommodation'),
		content: commonAnswerText(
			'venueBoostInventoryManagementAccommodationContent'
		),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostStaffCoordination'),
		content: commonAnswerText('venueBoostStaffCoordinationContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostPromotionsCampaigns'),
		content: commonAnswerText('venueBoostPromotionsCampaignsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostLoyalty'),
		content: commonAnswerText('venueBoostLoyaltyContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostTimeOffRequests'),
		content: commonAnswerText('venueBoostTimeOffRequestsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostGuestSurveysRatingsBenefits'),
		content: commonAnswerText('venueBoostGuestSurveysRatingsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
]

const helpListRetail = [
	{
		title: commonAnswerText('venueBoostRetailLoyaltyProgram'),
		content: commonAnswerText('venueBoostRetailLoyaltyProgramContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostOrderManagement'),
		content: commonAnswerText('venueBoostOrderManagementContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostRetailInventoryManagement'),
		content: commonAnswerText('venueBoostRetailInventoryManagementContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostInventoryInsightsBenefits'),
		content: commonAnswerText('venueBoostInventoryInsightsBenefitsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostStaffManagementRetailObjective'),
		content: commonAnswerText(
			'venueBoostStaffManagementRetailObjectiveContent'
		),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
]

const helpListEntertainmentVenues = [
	{
		title: commonAnswerText('venueBoostMarketingAutomationGoal'),
		content: commonAnswerText('venueBoostMarketingAutomationContent2'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostBookingsManagementGoal'),
		content: commonAnswerText('venueBoostBookingsManagementContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostInventoryManagementAbout'),
		content: commonAnswerText('venueBoostInventoryManagementAboutContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostMarketingAutomationBenefits'),
		content: commonAnswerText('venueBoostMarketingAutomationBenefitsContent'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
	{
		title: commonAnswerText('venueBoostBookingsManagement'),
		content: commonAnswerText('venueBoostBookingsManagementContent2'),
		creator: 'VenueBoost',
		updatedAt: 'December 1, 2023',
	},
]

export const Help = (props) => {
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const [filtered, setFiltered] = useState([])
	useEffect(() => {
		if (isAccommodation(venueType)) {
			setFiltered(helpListAccommodation)
		} else if (isFB(venueType)) {
			setFiltered(helpListFB)
		} else if (isRetailVenue(venueType)) {
			setFiltered(helpListRetail)
		} else if (isEntertainmentVenue(venueType)) {
			setFiltered(helpListEntertainmentVenues)
		}
	}, [venueType])

	const handleSearch = (search_str) => {
		if (search_str) {
			let ourOriginalList = []

			if (isAccommodation(venueType)) {
				ourOriginalList = helpListAccommodation
			} else if (isFB(venueType)) {
				ourOriginalList = helpListFB
			} else if (isRetailVenue(venueType)) {
				ourOriginalList = helpListRetail
			} else if (isEntertainmentVenue(venueType)) {
				ourOriginalList = helpListEntertainmentVenues
			}

			setFiltered(
				ourOriginalList.filter(
					(item) =>
						item.title.toLowerCase().includes(search_str.toLowerCase()) ||
						item.content.toLowerCase().includes(search_str.toLowerCase())
				)
			)
		} else {
			if (isAccommodation(venueType)) {
				setFiltered(helpListAccommodation)
			} else if (isFB(venueType)) {
				setFiltered(helpListFB)
			} else if (isRetailVenue(venueType)) {
				setFiltered(helpListRetail)
			} else if (isEntertainmentVenue(venueType)) {
				setFiltered(helpListEntertainmentVenues)
			}
		}
	}

	const handleContactUs = () => {
		window.location = 'https://venueboost.io/contact-us'
	}

	return (
		<div className="flex flex-col relative h-full">
			<div className="flex flex-col px-4 py-5 gap-4">
				<div>
					<TextField
						required
						size="small"
						placeholder={commonAnswerText('searchPlaceholder')}
						className="!text-slate-700 w-full"
						onChange={(e) => handleSearch(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchOutlined />
								</InputAdornment>
							),
						}}
					/>
				</div>
				<span className="font-bold">{commonAnswerText('commonAnswer')}</span>
				<div
					className="scrollable-content"
					style={{
						overflowY: 'auto',
						maxHeight: 'calc(100vh - 200px)',
						marginBottom: '70px',
					}}
				>
					{filtered.length > 0 &&
						filtered.map((item, index) => (
							<div
								key={index}
								className="flex flex-col"
								style={{ marginTop: '15px' }}
							>
								<span className="text-green5 cursor-pointer">{item.title}</span>
								<div className="flex flex-row items-center gap-4">
									<span className="font-bold">
										{commonAnswerText('by')} {item.creator}
									</span>
									<div className="flex flex-row items-center gap-2">
										<AccessTimeOutlined />
										<span>
											{commonAnswerText('updatedAt')} {item.updatedAt}
										</span>
									</div>
								</div>
								<span>{item.content}</span>
							</div>
						))}
				</div>
			</div>
			<div className="flex justify-center py-6 w-full border-t-2 border-gray-300 bg-gray-100 absolute bottom-0">
				<span
					className="font-bold border-2 border-black1 rounded px-4 py-1 cursor-pointer"
					onClick={() => handleContactUs()}
				>
					{commonAnswerText('contactUs')}
				</span>
			</div>
		</div>
	)
}
