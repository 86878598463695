import { TextField } from '@mui/material'
import React from 'react'
import { RInputDeclare } from '../../../../components/custom/RInputDeclare'

const InputField = ({
	label,
	value,
	onChange,
	placeholder,
	type = 'text',
	multiline = false,
	rows = 1,
}) => {
	return (
		<div className="flex flex-col w-full">
			<RInputDeclare
				value={label}
				className="!mt-0 !mb-2 !text-black1 text-base"
			/>
			<TextField
				required
				label=""
				size="small"
				type={type}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				multiline={multiline}
				minRows={rows}
			/>
		</div>
	)
}

export default InputField
