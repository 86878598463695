import { Card, CardContent, Grid } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import themeCardImage from '../../../assets/images/theme.png'
import addIcon from '../../../assets/svgs/add-icon.svg'
import eyeIcon from '../../../assets/svgs/eye-icon.svg'
import DynamicBreadcrumbs from '../../../components/shared/dynamicBreadCrumbs'

const getTranslation = (text) =>
	t(`account.venueWebProfile.themeSelector.${text}`)

// Dynamic breadcrumbs items you can use href for link
const breadcrumbItems = [
	{
		label: 'Settings',
		// href: '/admin/settings',
	},
	{ label: 'Theme Selector' },
]

function ThemeSelector() {
	return (
		<div className="h-full px-6 py-2 overflow-y-auto">
			{/* Component for breadcrumbs */}
			<DynamicBreadcrumbs breadcrumbItems={breadcrumbItems} />
			<div className="w-full mt-6">
				<Card>
					<CardContent className="!p-0">
						<div className="flex flex-col items-start pl-4 pr-3 pt-5">
							<span className="text-black1 text-xl font-medium">
								{getTranslation('themeSelectorTitle')}
							</span>
							<span className="text-gray5 text-l font-medium">
								{getTranslation('themeSelectorDescription')}
							</span>
						</div>
						<Grid container spacing={2} className="mr-4 ml-4 my-3 p-4">
							<Grid item xs={4}>
								<div
									className="flex flex-col justify-between items-center shadow-card bg-white rounded-lg h-full min-h-[245px] w-full relative"
									style={{ border: '1px solid #BCBCBC' }}
								>
									<div className="h-[200px] w-full object-cover ">
										<img
											src={themeCardImage}
											className="w-full h-full object-cover rounded-t-lg"
											alt="icon"
										/>
									</div>
									{/* This is for preview theme button */}
									<div
										className="text-[#131118] absolute font-medium text-12 bg-white px-3 py-2 rounded-full right-1 top-1 cursor-pointer flex items-center justify-center"
										style={{ border: '1px solid #BCBCBC' }}
									>
										<img src={eyeIcon} alt="icon" className="mr-1" />
										{getTranslation('previewButtonText')}
									</div>
									<div className="flex justify-between w-full items-center px-2 py-3">
										<div className="text-[#240B3B] text-14 font-semibold">
											{getTranslation('classicText')}
										</div>
										<div className="text-[#240B3B] text-12 font-medium">
											{getTranslation('activatedText')}
										</div>
									</div>
								</div>
							</Grid>
							<Grid item xs={4}>
								<div
									className="flex flex-col justify-between items-center shadow-card bg-white rounded-lg h-full min-h-[245px] w-full"
									style={{ border: '1px solid #BCBCBC' }}
								>
									<div className="h-[200px] flex justify-center items-center w-full object-cover rounded-t-lg bg-[#D9D9D9] cursor-pointer">
										<img src={addIcon} alt="icon" />
									</div>
									<div className="flex justify-between w-full items-center px-2 py-3">
										<div className="text-[#949494] text-14 font-semibold">
											{getTranslation('classicText')}
										</div>
										<div className="text-[#949494] text-12 font-medium">
											{getTranslation('activatedText')}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default ThemeSelector
