import React, { useEffect, useState } from 'react'
import NavbarPOS from './NavbarPOS'
// import Sidebar from "./Sidebar";
import { useSelector } from 'react-redux'
import useDebounce from '../components/Hooks/useDebounce'
import MenuService from '../services/menuService'
import { isEmpty } from '../utils/common'
import { identifyVenue, trackEvent } from '../utils/mixpanelUtil'
import RetailCheckout from '../views/POS/RetailCheckout'
import FooterPOS from './FooterPOS'
import './index.css'
import SidebarMenu from './SidebarMenu'
import SidebarPOS from './SidebarPOSRetail'

export default function LayoutPOSRetail() {
	const { short_code } = useSelector((state) => state.app)

	const [updateCart, setUpdateCart] = useState(false)

	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [search, setSearch] = useState('')
	const debouncedValue = useDebounce(search, 900)

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [debouncedValue])

	const refreshData = () => {
		let query = []
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}

		MenuService.getProducts(query.join('&'), short_code)
			.then((res) => {
				setLoaded(true)
				setData(res?.products || [])
				identifyVenue(short_code)
				trackEvent('Products List', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch((err) => {
				setLoaded(true)
				identifyVenue(short_code)
				trackEvent('Products List', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'product',
				});
				throw new Error("Problem retrieving Products List for POS Food non-mobile");
			})
	}

	return (
		<div className="flex flex-row w-full h-full">
			<div className="flex flex-col">
				<div className="flex h-full">
					<div className="flex h-full border-b-2 border-r border-b-[#240b3b]">
						<SidebarMenu />
					</div>
					<div className="flex flex-col w-full justify-between flex-1 h-full">
						<div>
							<NavbarPOS />
						</div>
						<div className="flex flex-1 h-full">
							<div className="product-list-container">
								<RetailCheckout list={data} setUpdateCart={setUpdateCart} />
							</div>
						</div>
					</div>
				</div>
				<div className="">
					<FooterPOS />
				</div>
			</div>
			<div className="flex  h-full">
				<SidebarPOS updateCart={updateCart} setUpdateCart={setUpdateCart} />
			</div>
		</div>
	)
}
