import React, { useState } from 'react'
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { XAxisLineChart, compactNumberFormatter } from './custom-x-axis-chart'
import { getDomainValue } from './get-domain'
import { labelListStyle } from './styles'
import BarChartTooltip from './tooltips/custom-bar-tooltip'
import { useXAxisTopSettings } from './use-x-axis'

const CustomBarChart = ({
	data = [],
	dataKey = '',
	barChartList = [],
	showLegend = true,
	barSize = 80,
	yaxis = true,
	cartesian = true,
	paddingBar = 5,
	horizontalCartesian = true,
	showLabelCenter = true,
	strokeDasharray = "",
	radius = [0, 0, 0, 0],
}) => {
	const [hiddenBars, setHiddenBars] = useState({})
	const [tooltipData, setTooltipData] = useState()

	const xAxisSettings = useXAxisTopSettings(dataKey)

	const handleMouseEnter = (data) => {
		setTooltipData({
			...data,
			payload: [data.payload],
		})
	}

	const handleMouseLeave = () => setTooltipData(undefined)

	const handleLegendClick = (dataKey) => {
		setHiddenBars((prevState) => ({
			...prevState,
			[dataKey]: !prevState[dataKey],
		}))
	}

	return (
		<ResponsiveContainer width="100%" height={300} className='!p-0 my-0 mr-2'>
			<BarChart data={data} className='p-0 w-full'>
				<XAxis {...xAxisSettings} interval={0} tick={XAxisLineChart} />
				<Tooltip
					content={
						<BarChartTooltip
							name={tooltipData?.name}
							tooltipPayload={tooltipData?.tooltipPayload}
						/>
					}
					cursor={false}
				/>
				{showLegend && (
					<Legend className="cursor-pointer" onClick={handleLegendClick} />
				)}
				{yaxis && (
					<YAxis
						type="number"
						domain={[0, getDomainValue(data)]}
						allowDecimals={false}
						axisLine={false}
						tickLine={false}
						tickFormatter={compactNumberFormatter}
						interval={0}
						tick={true}
						padding={{
							bottom: 0,
							top: 1
						}}
					/>
				)}
				{cartesian && (
					<CartesianGrid vertical={false} horizontal={horizontalCartesian} strokeDasharray={strokeDasharray} />
				)}

				{barChartList.map((bar) => (
					<Bar
						key={bar.dataKey}
						name={bar.name}
						dataKey={bar.dataKey}
						barSize={barSize}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						isAnimationActive={false}
						fill={bar.color}
						hide={hiddenBars[bar.dataKey]}
						radius={radius}
					>
						{data.map((_, entryIndex) => (
							<Cell
								key={`cell-${entryIndex}`}
								fill={bar.color}
								style={{ outline: 'none', padding: paddingBar }}
							/>
						))}

						{showLabelCenter && (
							<LabelList
								dataKey={bar.dataKey}
								position="center"
								style={{
									...labelListStyle('white'),
									fontWeight: 'normal',
									fontSize: 10,
								}}
								content={(props) => <CustomMiddleLabelList {...props} />}
							/>
						)}
					</Bar>
				))}
			</BarChart>
		</ResponsiveContainer>
	)
}

export default CustomBarChart

export const CustomMiddleLabelList = ({ x, y, width, height, value }) => {
	const xAxisTextPosition = x + width / 2

	const textSize = Math.ceil(height > 12 ? 12 : height)
	const yAxisTextPosition = y + height / 2 + textSize / 2.5

	return height > 8 ? (
		<g>
			<circle cx={xAxisTextPosition} cy={yAxisTextPosition} width={100} />
			<text
				x={xAxisTextPosition}
				y={yAxisTextPosition}
				fill="#ffffff"
				fontSize={`${textSize}px`}
				textAnchor="middle"
				dominantBaseline="top"
				className="zIndex"
			>
				{value}
			</text>
		</g>
	) : null
}
