import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import reservationIcon from '../../../assets/svgs/reservationlist.svg'
import waitIcon from '../../../assets/svgs/waitlist.svg'
import CustomSwitch from '../../../components/custom/CustomSwitch'
import retailService from '../../../services/retailService'

export const storePaymentsText = (text) =>
	t(`account.storeSettings.storePayments.${text}`)

const StorePayments = ({ data, onRefresh }) => {
	const [cash, setCash] = useState('')
	const [card, setCard] = useState('')

	useEffect(() => {
		setCash(data?.enable_cash_payment_method === 1 ? '1' : '2')
		setCard(data?.enable_card_payment_method === 1 ? '1' : '2')
	}, [])

	// TODO: check if this can be improved
	const handleCash = (value) => {
		setCash(value ? '1' : '2')
		handleSaveEmailSettings()
	}

	const handleCard = (value) => {
		setCard(value ? '1' : '2')
		handleSaveEmailSettings()
	}

	const handleSaveEmailSettings = (sms, email, phone) => {
		let payload = {
			enable_cash_payment_method: cash === '1' ? 1 : 0,
			enable_card_payment_method: card === '1' ? 1 : 0,
		}

		retailService
			.saveStoreSettings(payload)
			.then(({ data }) => {
				NotificationManager.success(
					data?.message ?? 'Payment settings saved successfully'
				)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? 'Something went wrong!')
			})
	}
	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-l font-medium">
							{storePaymentsText('configured_payment_methods_description')}
						</span>
					</div>
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<img src={waitIcon} alt="list" />
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{storePaymentsText('cash_title')}
									</span>
								</div>
								<span className="text-gray5 text-sm">
									{storePaymentsText('cash_description')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							{cash === '1' ? (
								<span className="text-sm font-medium text-[#027A48]">
									{storePaymentsText('status_on')}
								</span>
							) : (
								<span className="text-sm font-medium text-gray5">
									{storePaymentsText('status_off')}
								</span>
							)}
							<CustomSwitch active={cash === '1'} onClick={handleCash} />
						</div>
					</div>
					<div className="flex flex-row mr-4 ml-4 justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
						<div className="flex flex-row gap-4">
							<div>
								<img src={reservationIcon} alt="list" />
							</div>
							<div className="flex flex-col">
								<div>
									<span className="text-xl font-medium text-black1 mr-3">
										{storePaymentsText('card_title')}
									</span>
								</div>
								<span className="text-gray5 text-sm">
									{storePaymentsText('card_description')}
								</span>
							</div>
						</div>
						<div className="flex flex-row items-center gap-3">
							{card === '1' ? (
								<span className="text-sm font-medium text-[#027A48]">
									{storePaymentsText('status_on')}
								</span>
							) : (
								<span className="text-sm font-medium text-gray5">
									{storePaymentsText('status_off')}
								</span>
							)}
							<CustomSwitch active={card === '1'} onClick={handleCard} />
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default StorePayments
