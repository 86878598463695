import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Modal from '@mui/material/Modal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import Svg_chevron_down_orig from '../../assets/svgs/chevron-down-orig.svg'
import { boxStyle } from '../../constants/types'
import ReservationService from '../../services/reservationService'
import TableService from '../../services/tableService'
import { createUUID, validatePhoneNumber } from '../../utils/common'

import MuiClose from '@mui/icons-material/Close'
import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { RInputDeclare } from '../custom/RInputDeclare'

import GuestService from '../../services/guestService'
import { validateEmailAddress } from '../../utils/common'
import CustomIconButton from '../common/IconButton'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import calendarIcon from '../../assets/svgs/calendar_white.svg'
import leftIcon from '../../assets/svgs/left.svg'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { KEYS } from '../../utils/storage'

const getTranslation = (text) =>
	t(`components.reservation.ReservationModal.${text}`)

export default function ReservationModal(props) {
	const emailReg = /\S+@\S+\.\S+/

	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	const [weekDays, setWeekDays] = useState([])

	const partySizeOptions = Array(10)
		.fill(0)
		.map((item, index) => {
			return { value: index + 1, label: (index + 1).toString() }
		})

	const cookies = new Cookies()
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)

	const [availableStartTime, setAvailableStartTime] = useState([])
	const [availableTimes, setAvailableTimes] = useState([])
	const [availableEndTime, setAvailableEndTime] = useState([])

	const [tableNumber, setTableNumber] = useState(0)
	const [partySize, setPartySize] = useState(1)
	const [startTime, setStartTime] = useState(1)
	const [endTime, setEndTime] = useState(1)
	const [hasGuest, setHasGuest] = useState(false)
	const [hasAdditionalGuests, setHasAdditionalGuest] = useState(false)
	const { short_code } = useSelector((state) => state.app)

	// Main Guest Detail
	const [mainName, setMainName] = useState('')
	const [mainEmail, setMainEmail] = useState('')
	const [mainPhone, setMainPhone] = useState('')

	// Guest Detail
	const [guests, setGuests] = useState([])
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [source, setSource] = useState('')
	const [notes, setNotes] = useState('')

	const [step, setStep] = useState(0)
	const [currentDate, setCurrentDate] = useState(new Date(moment()))
	const [calendarOpen, setCalendarOpen] = useState(false)
	const [tableOptions, setTableOptions] = useState([])

	const [checkReservation, setCheckReservation] = useState(false)
	const [checkGuest, setCheckGuest] = useState(false)
	const [checkGuestAdd, setCheckGuestAdd] = useState(false)

	const [guestData, setGuestData] = useState([])
	const [guestOptions, setGuestOptions] = useState([])
	const [selectedGuest, setSelectedGuest] = useState(null)

	const [manualInput, setManualInput] = useState(false)
	useEffect(() => {
		if (props.isModelOpen) {
			initializeForms()
		}
		// eslint-disable-next-line
	}, [props.isModelOpen])

	const initializeForms = () => {
		setCheckReservation(false)
		setCheckGuest(false)

		setTableNumber(0)
		setStartTime(1)
		setEndTime(1)
		setPartySize(1)
		setHasGuest(false)
		setHasAdditionalGuest(false)

		setManualInput(false)
		setMainName('')
		setMainEmail('')
		setMainPhone('')

		setName('')
		setEmail('')
		setPhone('')
		setGuests([])
		setSelectedGuest(null)

		setStep(0)

		var curr = new Date()
		var first = curr.getDate() - curr.getDay()
		let newWeekdays = []
		for (let i = 0; i <= 7; i++) {
			newWeekdays.push(new Date(new Date(curr).setDate(first++)))
		}
		setWeekDays(newWeekdays)
		setCurrentDate(curr)

		// set available time range
		getTableTimeSlots(currentDate, tableNumber)

		// set available time range
		let availableTimeRange = []
		let startTime = moment(cookieBasicInfo.reservation_start_time, 'HH:mm')
		let endTime = moment(cookieBasicInfo.reservation_end_time, 'HH:mm')

		while (startTime.isSameOrBefore(endTime)) {
			availableTimeRange.push({
				value: startTime.format('HH:mm'),
				label: startTime.format('HH:mm'),
			})
			startTime.add(30, 'minutes')
		}

		setAvailableStartTime(availableTimeRange)
		setAvailableEndTime(availableTimeRange)
		setAvailableTimes(availableTimeRange)

		// get guest data
		resetGuestOptions()

		// get table data
		TableService.GetAll()
			.then((res) => {
				setTableOptions(
					res?.tables.map((item) => {
						return { value: item.id, label: item.name }
					})
				)
			})
			.catch(() => {
				throw new Error('Problem reading the tables on reservation modal')
			})
	}

	const resetGuestOptions = () => {
		GuestService.GetAll().then((res) => {
			let newGuestOptions = res.data
				.filter((o) => o.is_main)
				.map((item) => {
					return { value: item.id, label: item.name }
				})
			setGuestData(res.data)
			setGuestOptions(newGuestOptions)
		})
	}

	const isSameDate = (p1, p2) => {
		let d1 = new Date(p1)
		let d2 = new Date(p2)
		let date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())
		let date2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate())
		return moment(date1).isSame(date2)
	}

	const handleTableNumber = (e) => {
		setTableNumber(e.target.value)
		getTableTimeSlots(currentDate, e.target.value)
	}

	const handleWeekDayClick = (e, day) => {
		setCurrentDate(day)
		getTableTimeSlots(day, tableNumber)
	}

	const handleCalendarClick = (e) => {
		let newDate = new Date(e)
		if (!isNaN(newDate)) {
			var curr = new Date(newDate)
			var first = curr.getDate() - curr.getDay()
			let newWeekdays = []
			for (let i = 0; i < 7; i++) {
				newWeekdays.push(new Date(new Date(curr).setDate(first++)))
			}
			setWeekDays(newWeekdays)
			setCurrentDate(newDate)
			getTableTimeSlots(newDate, tableNumber)
		}
		setCalendarOpen(!calendarOpen)
	}

	const handleStartTimeSelect = (item) => {
		let mstart = moment(
			'2000-1-1 ' + availableStartTime.find((o) => o.value === item).label
		)
		let timeEnd = availableTimes.filter((o) =>
			moment('2000-1-1 ' + o.label).isAfter(mstart.add(30, 'mins'))
		)

		setAvailableEndTime(timeEnd)
		setStartTime(item)
	}

	const createNewReservation = async (rawData) => {
		ReservationService.Create(rawData)
			.then((res) => {
				NotificationManager.success('New reservation created successfully', '')
				props.getReservationData()
				identifyVenue(short_code)
				trackEvent('Create Reservation', {
					action_category: 'creation',
					action_outcome: 'success',
					interaction_element: 'reservation',
				})
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
				identifyVenue(short_code)
				trackEvent('Create Reservation', {
					action_category: 'creation',
					action_outcome: 'failed',
					interaction_element: 'reservation',
				})
			})
		resetGuestOptions()
	}
	const handleContinue = async () => {
		if (step === 0 && (!partySize || !startTime || !endTime)) {
			setCheckReservation(true)
			return
		}

		// finally reserve all data
		let rawData = {}
		rawData.start_time = moment(currentDate).format(
			'YYYY-MM-DD ' +
				availableStartTime.find((o) => o.value === startTime).label
		)
		rawData.end_time = moment(currentDate).format(
			'YYYY-MM-DD ' + availableEndTime.find((o) => o.value === endTime).label
		)
		rawData.guest_count = guests.length + (hasGuest ? 1 : 0)
		rawData.confirmed = 0
		rawData.provideGuestInfo = hasGuest
		rawData.hasAdditionalGuests = false
		if (source) {
			rawData.source = source
		}
		if (notes) {
			rawData.notes = notes
		}

		if (tableNumber) {
			rawData.table_id = tableNumber
		}

		if (step === 0 && hasGuest) {
			if (partySize && currentDate && startTime !== endTime) {
				// if valid input
				setStep(1)
			} else {
				setCheckReservation(true)
			}
		} else if (step === 0) {
			// has no guest
			if (partySize && currentDate && startTime !== endTime) {
				// if valid
				props.setModelOpen(false)
				createNewReservation(rawData)
			} else {
				setCheckReservation(true)
			}
		} else {
			if (
				(manualInput &&
					mainName.length &&
					mainEmail.length &&
					mainPhone.length &&
					emailReg.test(mainEmail)) ||
				(!manualInput && selectedGuest)
			) {
				rawData.hasAdditionalGuests = hasAdditionalGuests

				let guestsData = []
				if (selectedGuest || manualInput) {
					let mainGuestData = manualInput
						? {
								name: mainName,
								email: mainEmail,
								phone: mainPhone,
								is_main: true,
						  }
						: { id: selectedGuest }
					guestsData.push(mainGuestData)
				}
				for (let i = 0; i < guests.length; i++) {
					let item = {
						name: guests[i].name,
						email: guests[i].email,
						phone: guests[i].phone,
					}
					guestsData.push(item)
				}
				rawData.guests = guestsData

				if (hasAdditionalGuests && guestsData.length < 2) {
					NotificationManager.error(
						'You have checked: Has additional guests? option! You need to confirm the additional guest or uncheck the option',
						'Warning'
					)
					return
				}

				// if valid
				await createNewReservation(rawData)
				props.setModelOpen(false)
			} else {
				NotificationManager.error('Please confirm main guest data', 'Warning')
				return
			}
		}
	}
	const handleClose = () => props.setModelOpen(false)
	const handleDeleteGuestDetail = (e, item) => {
		let result = guests.filter((guest) => guest.id !== item.id)
		setGuests(result)
	}
	const handleNewGuestDetail = () => {
		if (name.length) {
			let guestArray = JSON.parse(JSON.stringify(guests))
			guestArray.push({
				id: createUUID(),
				name: name,
				email: email,
				phone: phone,
			})
			setName('')
			setEmail('')
			setPhone('')
			setGuests(guestArray)
			setCheckGuestAdd(false)
		} else {
			setCheckGuestAdd(true)
		}
	}
	const handleMainGuestSelect = (index) => {
		let mguest = guestData.find((o) => o.id === index)
		if (mguest) {
			setMainName(mguest.name)
			setMainEmail(mguest.email)
			setMainPhone(mguest.phone)
		}
		setSelectedGuest(index)
	}

	const getTableTimeSlots = (date, tableId) => {
		const formattedDate = moment(date).format('YYYY-MM-DD')
		if (date && tableId !== undefined && tableId !== 0) {
			TableService.GetTableAvailableTimeSlot(formattedDate, tableId)
				.then((res) => {
					const startTimeOptions = []
					const endTimeOptions = []

					const timeSlots = res.available_timeslots.map((slot) => {
						return {
							value: moment(slot.start_time).format('HH:mm'),
							label: moment(slot.start_time).format('HH:mm'),
						}
					})

					timeSlots.forEach((slot, index) => {
						startTimeOptions.push(slot)

						if (index < timeSlots.length - 1) {
							const currentEndTime = moment(slot.value, 'HH:mm')
							const nextStartTime = moment(timeSlots[index + 1].value, 'HH:mm')

							if (nextStartTime.diff(currentEndTime, 'minutes') >= 30) {
								endTimeOptions.push(timeSlots[index + 1])
							}
						}
					})

					setAvailableTimes(timeSlots)
					setAvailableStartTime(startTimeOptions)
					setAvailableEndTime(endTimeOptions)
				})
				.catch(() => {
					throw new Error('Problem on getting getTableTimeSlots')
				})
		}
	}

	return (
		<div>
			<Modal
				open={props.isModelOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					{step === 0 ? (
						<CustomIconButton
							icon={calendarIcon}
							title={getTranslation('newReservation')}
						/>
					) : (
						<CustomIconButton
							icon={leftIcon}
							title={getTranslation('guestDetails')}
						/>
					)}
					{step === 0 && (
						<div className="flex flex-col px-024 overflow-auto max-h-[70vh]">
							<div className="grid grid-cols-2 gap-4">
								<div className="col-start-1 flex flex-col">
									<div className="flex flex-row gap-2">
										<RInputDeclare
											value={getTranslation('selectPartySize')}
											className="text-base !text-black1 font-medium !mb-2"
										/>
										<RInputDeclare
											value={getTranslation('required')}
											className="text-base !mb-2"
										/>
									</div>
									<TextField
										id="outlined-select"
										size="small"
										error={checkReservation && !partySize}
										select
										value={partySize}
										onChange={(event) => setPartySize(event.target.value)}
									>
										{partySizeOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
								<div className="col-start-2 flex flex-col">
									<div className="flex flex-row gap-2">
										<RInputDeclare
											value={getTranslation('selectTableNumber')}
											className="text-base !text-black1 font-medium !mb-2"
										/>
										<RInputDeclare
											value={getTranslation('optional')}
											className="text-base !mb-2"
										/>
									</div>
									<TextField
										id="outlined-select"
										size="small"
										select
										value={tableNumber}
										onChange={handleTableNumber}
									>
										{tableOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>
							<div className="relative flex flex-row justify-between mt-5">
								<label className="text-base text-black1">
									{getTranslation('selectDate')}
								</label>
								<div className="flex flex-row">
									<label
										className="text-14 text-gray3 whitespace-nowrap"
										onClick={handleCalendarClick}
									>
										{moment(currentDate).format('MMMM yyyy')}
									</label>
									{calendarOpen && (
										<div className="absolute right-0 top-[20px] z-100 z-50">
											<DatePicker
												selected={currentDate}
												onChange={handleCalendarClick}
												inline
											/>
										</div>
									)}
									<img
										onClick={handleCalendarClick}
										className="text-14 pl-[8px]"
										src={Svg_chevron_down_orig}
										alt=""
									/>
								</div>
							</div>
							<div
								className="flex flex-row mt-2 rounded-[16px] w-full h-[64px]"
								style={{ border: '1px solid #E4E4E4' }}
							>
								{weekDays.map((item, index) => {
									return (
										<div
											key={index}
											className={`flex flex-col w-[88px] h-[64px] justify-center ${
												isSameDate(item, currentDate) ? 'bg-black1' : ''
											}`}
											onClick={(e) => handleWeekDayClick(e, item)}
											style={
												index > 0 && index < 7
													? { borderLeft: '1px solid #E4E4E4' }
													: index > 6
													? {
															borderLeft: '1px solid #E4E4E4',
															borderRadius: '0 8px 8px 0',
													  }
													: { borderRadius: '8px 0 0 8px' }
											}
										>
											<label
												className={`text-center text-12 font-medium  ${
													isSameDate(item, currentDate)
														? 'text-white'
														: 'text-gray3'
												}`}
											>
												{days[index > 6 ? index - 7 : index]}
											</label>
											<label
												className={`text-center text-16 font-semibold ${
													isSameDate(item, currentDate) ? 'text-white' : ''
												}`}
											>
												{item.getDate()}
											</label>
										</div>
									)
								})}
							</div>
							<div className="grid grid-cols-2 mt-4 gap-4">
								<div className="col-start-1 flex flex-col">
									<div className="flex flex-row gap-2">
										<RInputDeclare
											value={getTranslation('startTime')}
											className="text-base !text-black1 font-medium !mb-2"
										/>
										<RInputDeclare
											value={getTranslation('required')}
											className="text-base !mb-2"
										/>
									</div>
									<TextField
										id="outlined-select"
										size="small"
										error={checkReservation && !startTime}
										select
										value={startTime}
										onChange={(event) =>
											handleStartTimeSelect(event.target.value)
										}
									>
										{availableStartTime.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
								<div className="col-start-2 flex flex-col">
									<div className="flex flex-row gap-2">
										<RInputDeclare
											value={getTranslation('endTime')}
											className="text-base !text-black1 font-medium !mb-2"
										/>
										<RInputDeclare
											value={getTranslation('required')}
											className="text-base !mb-2"
										/>
									</div>
									<TextField
										id="outlined-select"
										size="small"
										error={
											checkReservation && (!endTime || startTime === endTime)
										}
										select
										value={endTime}
										onChange={(event) => setEndTime(event.target.value)}
									>
										{availableEndTime.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</div>
							</div>
							<div className="grid grid-cols-2 mt-4 gap-4">
								<div className="col-start-1 flex flex-col">
									<RInputDeclare
										value={getTranslation('source')}
										className="text-base !text-black1 font-medium !mb-2"
									/>
									<TextField
										id="outlined-select"
										size="small"
										select
										value={source}
										onChange={(event) => setSource(event.target.value)}
									>
										<MenuItem key={'call'} value={'call'}>
											{getTranslation('call')}
										</MenuItem>
										<MenuItem key={'google'} value={'google'}>
											{getTranslation('google')}
										</MenuItem>
										<MenuItem key={'website'} value={'website'}>
											{getTranslation('website')}
										</MenuItem>
										<MenuItem key={'facebook'} value={'facebook'}>
											{getTranslation('facebook')}
										</MenuItem>
										<MenuItem key={'instagram'} value={'instagram'}>
											{getTranslation('instagram')}
										</MenuItem>
										<MenuItem key={'other'} value={'other'}>
											{getTranslation('other')}
										</MenuItem>
									</TextField>
								</div>
								<div className="col-start-2 flex flex-col">
									<RInputDeclare
										value={getTranslation('specialRequests')}
										className="text-base !text-black1 font-medium !mb-2"
									/>
									<TextField
										id="filled-required"
										label=""
										size="small"
										value={notes}
										onChange={(e) => setNotes(e.target.value)}
									/>
								</div>
							</div>
							<div className="grid grid-cols-2">
								<div className="grid-start-1 col-span-1">
									<div
										className="mt-2 flex items-center"
										onClick={() => {
											setHasGuest(!hasGuest)
										}}
									>
										<Checkbox
											checked={hasGuest}
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: 28,
													marginLeft: '-10px',
												},
											}}
											readOnly
										/>
										<label className="text-base text-black1">
											{getTranslation('haveGuestDetail')}
										</label>
									</div>
								</div>
							</div>
						</div>
					)}
					{step >= 1 /* Guest Details */ && (
						<div className="flex flex-col px-024 overflow-auto max-h-[70vh]">
							{/* Main Guest Detail */}

							<div className="grid grid-cols-2">
								<div className="grid-start-1 col-span-1">
									<div
										className="mt-2 flex items-center"
										onClick={() => {
											setManualInput(!manualInput)
										}}
									>
										<Checkbox
											checked={manualInput}
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: 28,
													marginLeft: '-10px',
												},
											}}
											readOnly
										/>
										<label className="text-lg text-black1">
											{getTranslation('manualInput')}
										</label>
									</div>
								</div>
							</div>

							<div className={`${manualInput ? '!hidden ' : 'flex flex-col'}`}>
								<div className="flex flex-row gap-2">
									<RInputDeclare
										value={getTranslation('selectMainGuest')}
										className="text-base !text-black1 font-medium !mb-2"
									/>
									<RInputDeclare
										value={getTranslation('required')}
										className="text-base !mb-2"
									/>
								</div>
								<TextField
									id="outlined-select"
									size="small"
									select
									value={selectedGuest}
									onChange={(event) =>
										handleMainGuestSelect(event.target.value)
									}
								>
									{guestOptions.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</div>
							<div className={`${!manualInput ? '!hidden ' : 'flex flex-col'}`}>
								<div className="flex flex-row gap-2">
									<RInputDeclare
										value={getTranslation('mainGuest')}
										className="text-base !text-black1 font-medium !mb-2"
									/>
									<RInputDeclare
										value={getTranslation('required')}
										className="text-base !mb-2"
									/>
								</div>
								<TextField
									required
									size="small"
									error={checkGuest && !mainName}
									id="filled-required"
									value={mainName}
									onChange={(e) => setMainName(e.target.value)}
									placeholder={getTranslation('enterName')}
								/>

								<div className="col-start-1 col-span-2 flex flex-col mt-2">
									<div className="flex flex-row gap-2">
										<RInputDeclare
											value={getTranslation('email')}
											className="text-base !text-black1 font-medium !mb-2"
										/>
										<RInputDeclare
											value={getTranslation('required')}
											className="text-base !mb-2"
										/>
									</div>
									<TextField
										required
										size="small"
										error={checkGuest && !validateEmailAddress(mainEmail)}
										id="filled-required"
										value={mainEmail}
										onChange={(e) => setMainEmail(e.target.value)}
										placeholder={getTranslation('enterEmail')}
									/>
								</div>
								<div className="col-start-3 col-span-2 flex flex-col mt-2">
									<div className="flex flex-row gap-2">
										<RInputDeclare
											value={getTranslation('phone')}
											className="text-base !text-black1 font-medium !mb-2"
										/>
										<RInputDeclare
											value={getTranslation('required')}
											className="text-base !mb-2"
										/>
									</div>
									<TextField
										required
										size="small"
										error={checkGuest && !validatePhoneNumber(mainPhone)}
										id="filled-required"
										type="number"
										value={mainPhone}
										onChange={(e) => setMainPhone(e.target.value)}
										placeholder={getTranslation('enterPhone')}
									/>
								</div>
							</div>

							<div className="grid-start-1 col-span-1">
								<div
									className="mt-4 flex items-center"
									onClick={() => {
										setHasAdditionalGuest(!hasAdditionalGuests)
									}}
								>
									<Checkbox
										checked={hasAdditionalGuests}
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 28,
												marginLeft: '-10px',
											},
										}}
										readOnly
									/>
									<label className="text-lg text-black1">
										{getTranslation('hasAdditionalGuests')}
									</label>
								</div>
							</div>

							{/* Additional Guest Detail */}
							{guests.length > 0 &&
								hasAdditionalGuests &&
								guests.map((item) => {
									return (
										<div
											className="flex flex-col p-024 rounded-xl border-gray-400 mb-4"
											key={item.id}
										>
											<div className="grid grid-cols-5 gap-4">
												<div className="col-start-1 col-span-4 flex flex-col">
													<RInputDeclare
														value={getTranslation('guestName')}
														className="text-base !text-black1 font-medium !mb-2"
													/>
													<TextField
														size="small"
														disabled
														id="filled-required"
														value={item.name}
													/>
												</div>
												<div className="col-span-1 flex justify-center items-end text-white">
													<Button
														variant="contained"
														className="!p-2 !w-[60px] !h-[60px] !rounded-full !bg-[#240b3b]"
														onClick={(e) => handleDeleteGuestDetail(e, item)}
													>
														<MuiClose
															fontSize="small"
															className="cursor-pointer "
														/>
													</Button>
												</div>
											</div>

											<div className="grid grid-cols-4 gap-4 ">
												<div className="col-start-1 col-span-2 flex flex-col">
													<RInputDeclare
														value={getTranslation('email')}
														className="text-base !text-black1 font-medium !mb-2"
													/>
													<TextField
														size="small"
														disabled
														id="filled-required"
														value={item.email}
													/>
												</div>
												<div className="col-start-3 col-span-2 flex flex-col">
													<RInputDeclare
														value={getTranslation('phone')}
														className="text-base !text-black1 font-medium !mb-2"
													/>
													<TextField
														size="small"
														disabled
														id="filled-required"
														value={item.phone}
													/>
												</div>
											</div>
										</div>
									)
								})}

							{hasAdditionalGuests && (
								<div className="flex flex-col p-024 rounded-xl border">
									<div className="flex flex-col col-span-5 gap-4">
										<div className="col-span-3 flex flex-col">
											<div className="flex flex-row gap-2">
												<RInputDeclare
													value={getTranslation('guestName')}
													className="text-base !text-black1 font-medium !mb-2"
												/>
												<RInputDeclare
													value={getTranslation('required')}
													className="text-base !mb-2"
												/>
											</div>
											<TextField
												required
												error={checkGuestAdd && !name}
												id="filled-required"
												size="small"
												value={name}
												onChange={(e) => setName(e.target.value)}
												placeholder={getTranslation('enterName')}
											/>
										</div>
									</div>

									<div className="col-start-1 col-span-2 flex flex-col mt-2">
										<div className="flex flex-row gap-2">
											<RInputDeclare
												value={getTranslation('email')}
												className="text-base !text-black1 font-medium !mb-2"
											/>
										</div>
										<TextField
											error={checkGuestAdd && !validateEmailAddress(email)}
											id="filled-required"
											size="small"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder={getTranslation('enterEmail')}
										/>
									</div>
									<div className="col-start-3 col-span-2 flex flex-col mt-2">
										<div className="flex flex-row gap-2">
											<RInputDeclare
												value={getTranslation('phone')}
												className="text-base !text-black1 font-medium !mb-2"
											/>
										</div>
										<TextField
											error={checkGuestAdd && !validatePhoneNumber(phone)}
											id="filled-required"
											size="small"
											type="number"
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
											placeholder={getTranslation('enterPhone')}
										/>
									</div>

									<div className="col-span-1 mt-6 flex justify-center items-center text-white">
										<span
											className="py-2 px-12 border rounded-lg text-black1 font-medium cursor-pointer"
											onClick={handleNewGuestDetail}
										>
											{getTranslation('addAdditionalGuestToReservation')}
										</span>
									</div>
								</div>
							)}
						</div>
					)}
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-4 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={handleClose}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={handleContinue}
						>
							{step >= 1
								? getTranslation('create')
								: getTranslation('continue')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
