import {
	Card,
	CardContent,
	CircularProgress,
	FormControlLabel,
	ImageList,
	ImageListItem,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import menuService from '../../../services/menuService'
import VenueService from '../../../services/venuService'
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import ComposedProducts from '../../../views/menu/products/composed-products'
import AssistantButton from '../../assistantButton'
import { ImgDragUpload } from '../../common/ImgDragUpload'
import { RInputDeclare } from '../../custom/RInputDeclare'
import VbAiAssistant from '../modal/vb-ai-assistant'

const getTranslation = (text) =>
	t(`components.retail.productDetails.productGeneralInfo.${text}`)

const ProductGeneralInfo = ({
	currency,
	data,
	product_id,
	categories,
	subCategories,
	onSubmitForm,
	onRefresh,
	isComposed = false,
}) => {
	const { short_code } = useSelector((state) => state.app)

	console.log(isComposed, 'isComposed')

	const [state, setState] = useState(null)
	const [loading, setLoading] = useState(false)
	const [image, setImage] = useState(null)
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [selectedParentCategory, setSelectedParentCategory] = useState(null)
	const [images, setImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	])
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		if (data) {
			setState(data)
			setSelectedCategory(data?.category?.id)
			setSelectedParentCategory(data?.parent?.id)
			if (data?.image_path) {
				setImage({
					type: 'url',
					url: isEmpty(data?.image_path) ? null : data?.image_path,
				})
			}

			if (data?.gallery) {
				if (data?.gallery.length > 0) {
					const tempImages = [...images]
					data?.gallery.forEach((item, index) => {
						tempImages[index] = item
					})
					setImages(tempImages)
				}
			}
		}
	}, data)

	const onUploadImage = (image, index) => {
		let formData
		formData = new FormData()
		formData.append('type', 'product')
		formData.append('product_id', product_id)
		if (image?.type === 'file' && image?.url != null) {
			formData.append('photo', image.file)
		}
		setLoading(true)
		menuService
			.uploadPhoto(formData)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'upload photo',
				})
				setLoading(false)
				onRefresh()
				NotificationManager.success('Image has been uploaded successfully')
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'upload photo',
				})
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
			})
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'short_product_description'
		if (aiField === 'Long description') {
			askFor = 'long_product_description'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		if (aiField === 'Short description') {
			setState({ ...state, short_description: generated })
		}

		if (aiField === 'Long description') {
			setState({ ...state, description: generated })
		}
		setAiQuery('')
		setGenerated('')
	}

	const onCancelImage = (index) => {
		setLoading(true)
		VenueService.deleteFacilitiesPhoto(images[index].photo_id)
			.then((res) => {
				setLoading(false)
				if (images[index]) {
					const tempImages = [...images]
					tempImages[index] = null
					setImages(tempImages)
				}
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Unable do delete Photo')
			})
	}

	const onSubmit = () => {
		onSubmitForm({ ...state, image: image })
	}

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-2 gap-10">
					<div className="items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-xl font-medium">
							{getTranslation('general')}
						</span>
						<p className="text-black1 text-16 font-medium mt-1">
							{getTranslation('basicProductInfo')}
						</p>
					</div>
					<div className="relative started-form">
						<div className=" flex flex-col py-4 px-5 overflow-auto">
							<div className="grid md:grid-cols-11 grid-cols-1 gap-5 mb-8 w-full">
								<div className="md:col-span-6 flex flex-col">
									<div className="grid grid-cols-1 md:grid-cols-5 gap-3 w-full">
										<div className="col-span-3 flex flex-col w-full">
											<RInputDeclare
												value={getTranslation('title')}
												className="!mt-0 !mb-2 !text-black1 text-base"
											/>
											<TextField
												required
												label=""
												size="small"
												value={state?.title}
												onChange={(e) =>
													setState({ ...state, title: e.target.value })
												}
												placeholder={getTranslation('enterTitle')}
											/>
										</div>
										<div className="col-span-2 flex flex-col w-full">
											<RInputDeclare
												value={getTranslation('price', currency)}
												className="!mt-0 !mb-2 !text-black1 text-base"
											/>
											<TextField
												required
												label=""
												size="small"
												type="number"
												value={state?.price}
												onChange={(e) =>
													setState({ ...state, price: e.target.value })
												}
												placeholder={getTranslation('enterPrice')}
											/>
										</div>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('articleNo')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<TextField
											required
											label=""
											size="small"
											value={state?.article_no}
											onChange={(e) =>
												setState({ ...state, article_no: e.target.value })
											}
											placeholder={getTranslation('enterArticleNo')}
										/>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('selectCategory')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<TextField
											id="outlined-select"
											size="small"
											select
											value={state?.parent?.id ?? selectedParentCategory}
											onChange={(e) =>
												setState({
													...state,
													parent: { id: e.target.value },
													category: { id: null },
												})
											}
										>
											{categories.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.title}
												</MenuItem>
											))}
										</TextField>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('selectSubCategory')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<TextField
											id="outlined-select"
											size="small"
											select
											value={state?.category?.id ?? selectedCategory}
											onChange={(e) =>
												setState({ ...state, category: { id: e.target.value } })
											}
										>
											{subCategories
												.filter(
													(option) =>
														option.parent.id ===
														(state?.parent?.id ?? selectedParentCategory)
												)
												.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{option.title}
													</MenuItem>
												))}
										</TextField>
									</div>
								</div>
								<div className="md:col-span-5 justify-start items-start pt-8 h-full">
									<ImgDragUpload image={image} onChange={setImage} />
								</div>
							</div>
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={getTranslation('longDescription')}
									className="!mt-0 !mb-2 !text-black1 text-base"
								/>
								<TextField
									required
									label=""
									size="small"
									placeholder={getTranslation('enterLongDescription')}
									value={state?.description}
									multiline
									minRows={5}
									onChange={(e) =>
										setState({ ...state, description: e.target.value })
									}
								/>
								<div className="flex flex-row justify-end mt-2">
									<AssistantButton
										onAction={() => handleOpenAIModal('Long description')}
									/>
								</div>
							</div>
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={getTranslation('shortDescription')}
									className="text-base !text-black1 mt-4 !mb-2"
								/>
								<TextField
									required
									label=""
									size="small"
									placeholder={getTranslation('enterShortDescription')}
									value={state?.short_description}
									multiline
									minRows={3}
									onChange={(e) =>
										setState({ ...state, short_description: e.target.value })
									}
								/>
								<div className="flex flex-row justify-end mt-2">
									<AssistantButton
										onAction={() => handleOpenAIModal('Short description')}
									/>
								</div>
							</div>
							<div className={'gap-2  w-full'}>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={getTranslation('published')}
										className="text-base !text-black1"
									/>
									<RadioGroup
										row
										className="gap-4"
										defaultValue={1}
										style={{ marginTop: -8 }}
										value={state?.available ?? 0}
										onChange={(e) => {
											setState({ ...state, available: e.target.value })
										}}
									>
										<FormControlLabel
											value={1}
											control={<Radio />}
											label={getTranslation('yes')}
										/>
										<FormControlLabel
											value={0}
											control={<Radio />}
											label={getTranslation('no')}
										/>
									</RadioGroup>
								</div>
							</div>
							{product_id ? (
								<div>
									<p className="text-base text-gray5 mt-8 mb-2">
										{getTranslation('productImages')}
									</p>
									<div className="flex-col mt-0 rounded-3xl gap-3 md:gap-6">
										<div className="flex flex-col h-full mt-0 py-4">
											<ImageList variant="quilted" cols={6}>
												{images.map((item, index) => (
													<ImageListItem
														key={'img_uploader' + index}
														className="pl-2 pb-2"
													>
														<ImgDragUpload
															backgroundColor={'#fff'}
															image={item ? { url: item?.photo_path } : null}
															onCancel={() => onCancelImage(index)}
															onChange={(img) => onUploadImage(img, index)}
															label={getTranslation('uploadImagePlaceholder')}
															classes="max-h-[180px] p-2 mb-2 mt-2"
														/>
													</ImageListItem>
												))}
											</ImageList>
										</div>
									</div>
								</div>
							) : null}

							{isComposed === true ? <ComposedProducts /> : null}
							<div className="flex flex-row justify-end w-full mt-8 h-11 gap-4">
								<button
									className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
									onClick={() => navigate(-1)}
								>
									{getTranslation('cancel')}
								</button>
								<button
									className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
									onClick={onSubmit}
								>
									{loading ? (
										<CircularProgress size={16} />
									) : (
										`${
											product_id != null
												? getTranslation('update')
												: getTranslation('create')
										}`
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</CardContent>

			<VbAiAssistant
				{...{
					aiField,
					aiQuery,
					aiVisible,
					generated,
					getTranslation,
					handleGenerate,
					handleSendReport,
					setAiQuery,
					setAiVisible,
					setGenerated,
					generating,
				}}
			/>
		</Card>
	)
}

export default ProductGeneralInfo
