import {
	Box,
	CircularProgress,
	IconButton,
	Modal,
	TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import CustomSelect from '../../../components/custom/CustomSelect'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'

import DeleteIcon from '@mui/icons-material/Delete'
import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import v2TableWhite from '../../../assets/svgs/v2-table-white.svg'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'
import {
	isAccommodation,
	isEntertainmentVenue,
	isFB,
	isRetailVenue,
} from '../../../helpers/venueHelpers'
import HygieneStandardService from '../../../services/hygieneStandardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'

const retailTypes = [
	'Checkout Area Sanitation',
	'Products Storage Check',
	'Staff Training',
	'Cleaning Check',
]

const accommodationType = [
	'Room Hygiene Check',
	'Restroom Check',
	'Seating Areas Check',
	'Dining Areas Check',
	'Cleaning Check',
	'Staff Training',
]

const fbType = [
	'Temperature Check',
	'Products Check',
	'Seating Areas Check',
	'Dining Areas Check',
	'Kitchen Check',
	'General Cleaning Check',
	'Staff Training',
]

const entertainmentType = [
	'Public Area Check',
	'Equipment Check',
	'Staff Training',
	'General Cleaning Check',
]

export const checkCreateModalText = (text) =>
	t(`hygieneStandard.checks.checkCreateModal.${text}`)

export default function CheckCreateModal({ showModal, data, onClose }) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [types, setTypes] = useState([])

	const [state, setState] = useState({
		available: 1,
	})

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		if (isAccommodation(venueType)) {
			setTypes(accommodationType)
		} else if (isFB(venueType)) {
			setTypes(fbType)
		} else if (isRetailVenue(venueType)) {
			setTypes(retailTypes)
		} else if (isEntertainmentVenue(venueType)) {
			setTypes(entertainmentType)
		}
	}, [venueType])

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)

				// Check and set state for checklist items
				if (data?.checklist_items) {
					const checklistItemValues = data.checklist_items.map(
						(item) => item.item
					)
					setChecklistItems(checklistItemValues)
				}
			}
		}
		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const onChangeCheckDate = (currentDate) => {
		setState({ ...state, check_date: currentDate })
	}

	const [checklistItems, setChecklistItems] = useState([''])

	const handleChecklistChange = (index, event) => {
		const newChecklistItems = checklistItems.map((item, i) => {
			if (i === index) {
				return event.target.value
			}
			return item
		})
		setChecklistItems(newChecklistItems)
	}

	const handleRemoveChecklistItem = (index) => {
		const newChecklistItems = checklistItems.filter((_, i) => i !== index)
		setChecklistItems(newChecklistItems)
	}

	const handleAddChecklistItem = () => {
		setChecklistItems([...checklistItems, ''])
	}

	const onSubmit = () => {
		const { item, type, check_date } = state

		if (!item) {
			NotificationManager.warning('Hygiene Check naming is required!')
			return
		}

		if (!type) {
			NotificationManager.warning('Hygiene Check type is required!')
			return
		}

		if (!check_date) {
			NotificationManager.warning('Hygiene Check Date is required!')
			return
		}

		// Validation for checklist items
		if (
			checklistItems.length === 0 ||
			checklistItems.some((ci) => ci.trim() === '')
		) {
			NotificationManager.warning(
				'At least one checklist item is required and all must be filled!'
			)
			return
		}

		// Format checklist items for backend
		const formattedChecklistItems = checklistItems.map((ci) => ({ item: ci }))

		if (state.id) {
			setLoading(true)
			HygieneStandardService.UpdateHygieneCheck({
				...state,
				checklist_items: formattedChecklistItems,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'hygiene standart',
					})
					NotificationManager.success(
						'Hygiene Check has been updated successfully',
						'Success',
						3000
					)
					onClose(true)
					setLoading(false)
				})
				.catch((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'hygiene standart',
					})
					NotificationManager.error('Failed', '')
					setLoading(false)
				})
		} else {
			setLoading(true)
			HygieneStandardService.CreateHygieneCheck({
				...state,
				checklist_items: formattedChecklistItems,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'hygiene standart',
					})
					NotificationManager.success(
						'Hygiene Check has been created successfully',
						'Success',
						3000
					)
					onClose(true)
					setLoading(false)
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'hygiene standart',
					})
					if (typeof err?.error === 'string') {
						NotificationManager.error(err?.error)
					} else {
						const errorKeys = Object.keys(err?.error || {})
						if (errorKeys.length > 0) {
							const firstErrorKey = errorKeys[0]
							const firstErrorMessage = err?.error[firstErrorKey][0]
							NotificationManager.error(
								firstErrorMessage || 'Something went wrong!'
							)
						} else {
							NotificationManager.error('Something went wrong!')
						}
					}
					setLoading(false)
					throw new Error('Problem on CreateHygieneCheck')
				})
		}
	}

	const handleChangeType = (value, index) => {
		setState((v) => ({ ...v, type: value }))
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-2 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 text-white p-2 rounded-full">
								<div className="bg-black1 rounded-full">
									<img src={v2TableWhite} width={20} alt="icon" />
								</div>
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{data?.id != null
									? checkCreateModalText('editHygieneCheck')
									: checkCreateModalText('newHygieneCheck')}
							</label>
						</div>
					</div>
					<div className="flex flex-col pb-0 p-024 mb-4">
						<RInputDeclare
							value={checkCreateModalText('nameWithAsterisk')}
							className={`!mt-0 !mb-2 !text-black1 font-medium`}
						/>
						<TextField
							required
							size="small"
							value={state?.item}
							placeholder={checkCreateModalText('enterName')}
							onChange={(e) => setState({ ...state, item: e.target.value })}
						/>

						<RInputDeclare
							value={checkCreateModalText('assignedTo')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							required
							size="small"
							value={state?.assigned_to}
							placeholder={checkCreateModalText('enterAssigneeEmail')}
							className="!text-slate-700"
							onChange={(e) =>
								setState({ ...state, assigned_to: e.target.value })
							}
						/>

						<RInputDeclare
							value={checkCreateModalText('reminder')}
							className={`!mb-2 !mt-5 !text-black1 font-medium`}
						/>
						<TextField
							required
							size="small"
							value={state?.remind_hours_before}
							placeholder={checkCreateModalText('reminderHoursBefore')}
							onChange={(e) =>
								setState({ ...state, remind_hours_before: e.target.value })
							}
						/>

						<RInputDeclare
							value={checkCreateModalText('typeWithAsterisk')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<CustomSelect
							values={types}
							value={state?.type}
							placeholder={checkCreateModalText('chooseType')}
							height="!h-10"
							className="!h-10 border !border-gray6"
							handleChange={handleChangeType}
						/>

						<RInputDeclare
							value={checkCreateModalText('checkDate')}
							className="!mt-4 !mb-0 !text-black1 font-medium"
						/>
						<CustomDateTimePicker
							onlyDate={false}
							value={state.check_date}
							onChange={onChangeCheckDate}
						/>

						<label className="!mt-5 text-14 !mb-2 !text-black1 font-medium">
							{checkCreateModalText('checklistItems')}
						</label>
						{checklistItems.map((item, index) => (
							<div key={index} className="flex items-center mb-2">
								<TextField
									size="small"
									value={item}
									placeholder={`${checkCreateModalText('checklistItem')} #${
										index + 1
									}`}
									onChange={(e) => handleChecklistChange(index, e)}
									className="flex-grow"
								/>
								<IconButton
									onClick={() => handleRemoveChecklistItem(index)}
									size="small"
									className="ml-2"
								>
									<DeleteIcon />
								</IconButton>
							</div>
						))}
						<button
							className="rounded-md font-bold bg-cyan1 text-white mt-2 p-2"
							onClick={handleAddChecklistItem}
						>
							+ {checkCreateModalText('addNewChecklistItem')}
						</button>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{checkCreateModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								`${checkCreateModalText(state.id ? 'update' : 'create')}`
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
