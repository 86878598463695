import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// import { NotificationManager } from "react-notifications";

import AssignOrderModal from '../../../components/reservation/AssignOrderModal'
import ReservationService from '../../../services/reservationService'
import TableService from '../../../services/tableService'
import { GuestDetailBlock } from './GuestDetailBlock'
import ReservationInfo from './ReservationInfo'
import TableInfo from './TableInfo'

import AssignOrderConfirmModal from '../../../components/reservation/AssignOrderConfirmModal'

import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import checkIcon from '../../../assets/svgs/check_green.svg'
import closeIcon from '../../../assets/svgs/close_red.svg'
import plusIcon from '../../../assets/svgs/plus.svg'
import ReservationCancelModal from '../ReservationCancelModal'
import ReservationConfirmModal from '../ReservationConfirmModal'
import OrderItems from './OrderItems'
import OrderSummary from './OrderSummary'
import PaymentPromo from './PaymentPromo'

export const reservationdetailText = (text) =>
	t(`reservations.reservationdetail.view.${text}`)

const ReservationDetailScreen = () => {
	const { id: reservationId } = useParams()
	const [reservationData, setReservationData] = useState({})
	const [tableData, setTableData] = useState({})
	const [showOrderModal, setShowOrderModal] = useState(false)
	const [showOrderConfirmModal, setShowOrderConfirmModal] = useState(false)
	const [showCancelModal, setShowCancelModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [order, setOrder] = useState({})
	const [mainGuest, setMainGuest] = useState(null)
	// const [confirm, setConfirm] = useState(false)

	useEffect(() => {
		getReservationData()
	}, [])

	const getReservationData = () => {
		ReservationService.ReadOne(reservationId).then((res) => {
			setReservationData(res.data)
			let table_id = res.data.table_id
			if (res.data.guests) {
				const mainGuest = res.data.guests.find((item) => item.is_main === 1)
				if (mainGuest) {
					setMainGuest(mainGuest)
				}
			}
			// setConfirm(res.data.confirmed == 1 || res.data.confirmed == 2)
			TableService.GetAll()
				.then((res) => {
					let rawTableData = res.find((o) => o.id === table_id)
					if (rawTableData) {
						setTableData(rawTableData)
					}
				})
				.catch((e) => {})
		})
	}

	const handleOrderSubmit = (data) => {
		setShowOrderModal(false)
		setOrder(data)
		setShowOrderConfirmModal(true)
	}

	const onOrderSubmit = (data) => {
		let payload = {
			...order,
			...data,
		}
		setShowOrderConfirmModal(true)
		ReservationService.AssignOrder(reservationId, payload)
			.then((res) => {
				NotificationManager.success(
					res?.message ?? 'Reservation submited successfully'
				)
				getReservationData()
			})
			.catch((e) => {
				NotificationManager.error(e?.error ?? 'Failed', '')
			})
	}

	const onCancel = () => {
		ReservationService.Cancel(reservationData.id)
			.then(() => {
				NotificationManager.success('Successfully cancelled')
				getReservationData()
				setShowCancelModal(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	const onConfirm = () => {
		ReservationService.Confirm(reservationData.id)
			.then(() => {
				NotificationManager.success('Successfully confirmed')
				getReservationData()
				setShowConfirmModal(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	return (
		<div className="flex flex-col h-full p-6">
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 py-5">
						<div className="flex flex-row items-center">
							<span className="text-black1 text-xl font-medium">
								{reservationdetailText('reservationDetails')}
							</span>
						</div>
						<div className="flex flex-row gap-4 items-center">
							{reservationData.confirmed == 0 ? (
								<>
									<div
										className={`flex flex-row gap-2 w-28 border font-medium flex flex-row px-4 py-2 text-black1 text-sm rounded-lg cursor-pointer text-red2 bg-red3 border-black1`}
										onClick={() => setShowCancelModal(true)}
									>
										<img src={closeIcon} alt="icon" />
										<span>{reservationdetailText('cancel')}</span>
									</div>
									<div
										className={`flex flex-row gap-2 w-28 border font-medium flex flex-row px-4 py-2 text-black1 text-sm rounded-lg cursor-pointer text-green2 bg-green3 border-green4`}
										onClick={() => setShowConfirmModal(true)}
									>
										<img src={checkIcon} alt="icon" />
										<span>{reservationdetailText('confirm')}</span>
									</div>
								</>
							) : (
								''
							)}
							<div
								className={`${
									reservationData?.order &&
									reservationData?.order?.order_products?.length > 0
										? 'bg-gray-500'
										: 'bg-black1'
								} flex flex-row px-6 py-2 text-white text-sm whitespace-nowrap rounded-lg cursor-pointer`}
								onClick={() => {
									if (
										!(
											reservationData?.order &&
											reservationData?.order?.order_products?.length > 0
										)
									) {
										setShowOrderModal(true)
									}
								}}
							>
								<img src={plusIcon} alt="plus" className="mr-2" />
								<span>{reservationdetailText('assignOrder')}</span>
							</div>
						</div>
					</div>
					<div className="flex flex-col px-2 py-4 bg-[#F9F7F7] overflow-auto">
						<div
							className="flex flex-col py-4 px-5"
							style={{
								height: 'calc(100vh - 76px - 68px - 78px - 64px - 32px)',
							}}
						>
							<ReservationInfo data={reservationData} />
							<div className="flex flex-row gap-8 mt-4 w-full">
								<TableInfo data={reservationData?.table} />
								<GuestDetailBlock data={reservationData?.guests || []} />
							</div>
							{reservationData.confirmed != 2 && (
								<div className="flex flex-row gap-8 mt-4 w-full">
									<div className="flex flex-col w-[65%] gap-4">
										<PaymentPromo
											data={reservationData}
											reservationId={reservationData?.id}
											guestId={mainGuest?.id}
											onRefresh={() => getReservationData()}
										/>
										<OrderItems
											data={reservationData?.order?.order_products ?? []}
										/>
									</div>
									<OrderSummary data={reservationData} />
								</div>
							)}
							<div style={{ minHeight: 40 }} />
						</div>
					</div>
				</CardContent>
			</Card>
			{showOrderModal && (
				<AssignOrderModal
					reservation_id={reservationId}
					data={reservationData}
					showModal={showOrderModal}
					onClose={(refresh) => {
						setShowOrderModal(false)
					}}
					onSubmit={handleOrderSubmit}
				/>
			)}
			{showOrderConfirmModal && (
				<AssignOrderConfirmModal
					reservation_id={reservationId}
					data={order}
					guestData={mainGuest}
					showModal={showOrderConfirmModal}
					onClose={(refresh) => {
						setShowOrderConfirmModal(false)
					}}
					onSubmit={(data) => {
						onOrderSubmit(data)
						setShowOrderConfirmModal(false)
					}}
				/>
			)}
			<ReservationCancelModal
				showModal={showCancelModal}
				onClose={() => setShowCancelModal(false)}
				onSubmit={() => onCancel()}
			/>
			<ReservationConfirmModal
				showModal={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				onSubmit={() => onConfirm()}
			/>
		</div>
	)
}
export default ReservationDetailScreen
