import React, { useEffect, useState } from "react";
import {
    Modal,
    TextField,
    Box,
    CircularProgress
} from "@mui/material";

import { boxStyle } from "../constants/types";

import cardIcon from '../assets/svgs/v2-dollar-payment-link-white.svg';

import {useSelector} from "react-redux";
import {NotificationManager} from "react-notifications";
import VenueService from "../services/venuService";

export default function StripeOnboardingModal({ showModal, data, onClose }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { short_code } = useSelector((state) => state.app);

    useEffect(() => {
        if (showModal) {
            if (data?.id != null) {
                // do nothing for now
            }
        }
        setVisible(showModal);
    }, [showModal]);

    const onSubmit = (plan_id) => {
        window.scrollTo(0, 0)
        setLoading(true);
        VenueService.createConnect({})
            .then((data) => {
                document.location.href = data?.url;
            })
            .catch((err) => {
                setLoading(false)
                if (typeof err?.error === 'string') {
                    NotificationManager.error(err?.error);
                } else {
                    const errorKeys = Object.keys(err?.error || {});
                    if (errorKeys.length > 0) {
                        const firstErrorKey = errorKeys[0];
                        const firstErrorMessage = err?.error[firstErrorKey][0];
                        NotificationManager.error(firstErrorMessage || "Something went wrong!");
                    } else {
                        NotificationManager.error("Something went wrong!");
                    }
                }
                throw new Error("Problem on saveVenueConfiguration");
            });


        // subscriptionService
        //     .changeSubscription({
        //         plan_id: plan_id,
        //         mode: billingMethod,
        //     })
        //     .then((data) => {
        //         setLoading(false)
        //         document.location.href = data?.url
        //     })
        //     .catch((error) => {
        //         setLoading(false)
        //         const message = error.message || 'Something went wrong!'
        //         NotificationManager.error(message, 'Info', 3000)
        //     })
    }

    return (
        <div>
            <Modal
                open={visible}
                onClose={onClose}
                className="class-modal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={boxStyle} className="outline-none">
                    <div className="flex flex-row items-center px-024 pb-010 pt-024 gap-3">
                        <div className="p-2 bg-gray7 rounded-full">
                            <div className="bg-black1 p-2 rounded-full">
                                <img src={cardIcon} alt="icon" />
                            </div>
                        </div>
                        <label className="text-20 font-semibold text-black1">
                            Stripe Onboarding
                        </label>

                    </div>
                    <div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
                        To enable online payments, you need to complete your onboarding with Stripe. Please provide the following information about your business:
                    </div>
                    <div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3" style={{paddingLeft: "40px"}}>
                        <ul style={{listStyle: "decimal"}}>
                            <li>Address</li>
                            <li>Business name</li>
                            <li>Business Tax ID</li>
                            <li>Owner's information</li>
                        </ul>
                    </div>
                    <div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
                        <button
                            className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                            onClick={onSubmit}
                        >
                            {loading ? (
                                <CircularProgress size={16} />
                            ) : "Complete Onboarding"}
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
