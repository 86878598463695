import { CircularProgress, Divider, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import { RInputDeclare } from '../custom/RInputDeclare'

import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import Cookies from 'universal-cookie'
import { isAccommodation } from '../../helpers/venueHelpers'
import TemplateService from '../../services/templateService'
import { vbAssistantAskQuestion } from '../../store/actions/chatbot'
import { KEYS } from '../../utils/storage'
import AssistantButton from '../assistantButton'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import CustomSelect from '../custom/CustomSelect'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select'
import GenericModal from '../generic-modal'

export const messageTemplateModalText = (text) =>
	t(`components.guests.AddMessageTemplateModal.${text}`)

const tagList = [
	{ id: 'guest_name', title: 'Full Name' },
	{ id: 'venue_name', title: 'Venue Name' },
]

const tagBookingList = [
	{ id: 'guest_name', title: 'Full Name' },
	{ id: 'accommodation_name', title: 'Accommodation Name' },
]

const typeList = ['SMS', 'Email']

export default function AddMessageTemplateModal({
	showModal,
	onConfirm,
	onClose,
	data,
}) {
	const [visible, setVisible] = useState(false)
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [tags, setTags] = useState([])
	const [type, setType] = useState('')
	const [checkValid, setCheckValid] = useState(false)
	const [content, setContent] = useState('')
	const [loading, setLoading] = useState(false)
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	const cookies = new Cookies()

	const venueType = cookies.get(KEYS.VENUE_TYPE)

	useEffect(() => {
		if (showModal) {
			if (data != null) {
				setName(data?.name)
				setDescription(data?.description)
				setType(data.type)
				checkForTags(description)
				handleContetChange(data?.description)
				setCheckValid(false)
			} else {
				setName('')
				setDescription('')
				setType('')
				setTags([])
				setContent('')
				setCheckValid(false)
			}
		}
		setVisible(showModal)
	}, [showModal])

	const handleContetChange = (description) => {
		let cnt = description
		cnt = cnt.replace('[guest_name]', 'John Doe')
		if (isAccommodation(venueType)) {
			cnt = cnt.replace('[accommodation_name]', 'Accommodation Name')
		} else {
			cnt = cnt.replace('[venue_name]', 'Venue Name')
		}
		setContent(cnt)
	}

	const checkForTags = (description) => {
		let tmp = []
		if (description.includes('[guest_name]')) {
			tmp.push(tagList[0])
		}
		if (isAccommodation(venueType)) {
			if (description.includes('[accommodation_name]')) {
				tmp.push(tagList[1])
			}
		} else {
			if (description.includes('[venue_name]')) {
				tmp.push(tagList[1])
			}
		}
		setTags(tmp)
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'email_sms_template'

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		setDescription(generated)
		setAiQuery('')
		setGenerated('')
	}

	const onSubmit = () => {
		if (description && type) {
			// onConfirm({ description, type, tags });
			let payload = {
				id: data?.id,
				name: name,
				type: type,
				description: description,
			}
			if (data?.id) {
				setLoading(true)
				TemplateService.UpdateMessageTemplate(payload)
					.then(({ data }) => {
						setLoading(false)
						NotificationManager.success(
							data?.message ?? 'Feature feeadback is saved successfully'
						)
						onConfirm()
					})
					.catch((err) => {
						setLoading(false)
						if (typeof err?.error === 'string') {
							NotificationManager.error(err?.error)
						} else {
							const errorKeys = Object.keys(err?.error || {})
							if (errorKeys.length > 0) {
								const firstErrorKey = errorKeys[0]
								const firstErrorMessage = err?.error[firstErrorKey][0]
								NotificationManager.error(
									firstErrorMessage || 'Something went wrong!'
								)
							} else {
								NotificationManager.error('Something went wrong!')
							}
						}
					})
			} else {
				setLoading(true)
				TemplateService.CreateMessageTemplate(payload)
					.then(({ data }) => {
						setLoading(false)
						NotificationManager.success(
							data?.message ?? 'Template is saved successfully'
						)
						onConfirm()
					})
					.catch((err) => {
						setLoading(false)
						if (typeof err?.error === 'string') {
							NotificationManager.error(err?.error)
						} else {
							const errorKeys = Object.keys(err?.error || {})
							if (errorKeys.length > 0) {
								const firstErrorKey = errorKeys[0]
								const firstErrorMessage = err?.error[firstErrorKey][0]
								NotificationManager.error(
									firstErrorMessage || 'Something went wrong!'
								)
							} else {
								NotificationManager.error('Something went wrong!')
							}
						}
					})
			}
		} else {
			setCheckValid(true)
		}
	}

	const children = (
		<div className="flex flex-col overflow-auto max-h-[60vh] mb-4">
			<CustomFormInput
				value={name}
				onChange={(e) => {
					setName(e.target.value)
				}}
				placeholder={messageTemplateModalText('name')}
				title={messageTemplateModalText('template_name')}
			/>

			<RInputDeclare
				value={messageTemplateModalText('content')}
				className="!mb-2 !mt-6 !text-black1 font-medium text-base"
			/>
			<TextField
				required
				error={checkValid && !description}
				size="small"
				multiline
				minRows={3}
				label=""
				value={description}
				onChange={(e) => {
					setDescription(e.target.value)
					handleContetChange(e.target.value)
				}}
				placeholder={messageTemplateModalText('define_template_content')}
			/>
			<div className="flex flex-row justify-end mt-2">
				<AssistantButton onAction={() => handleOpenAIModal('Description')} />
			</div>

			<CustomFormSelect
				setOptionValue={setType}
				value={type}
				placeholder={messageTemplateModalText('select_type')}
				options={typeList.map((item) => ({
					label: item,
					value: item,
				}))}
				title={messageTemplateModalText('type')}
			/>

			<RInputDeclare
				value={messageTemplateModalText('tags')}
				className="!mb-2 !mt-6 !text-black1 font-medium text-base"
			/>
			{
				isAccommodation(venueType) && (
					<CustomSelect
						height={'!h-10'}
						className={'!h-10 border !border-gray6'}
						values={tagBookingList}
						value={tags}
						isMultiple={true}
						placeholder={messageTemplateModalText('helpful_tags')}
						renderRow={(item) => <p>{item.title}</p>}
						renderValue={(item) => <span>{item?.title}</span>}
						handleChange={(v) => {
							let tmp = tags.slice(0)
							let index = tmp.findIndex((t) => t.id == v.id)
							if (index == -1) {
								tmp.push(v)
								let desc = description
								desc += '[' + v.id + ']'
								setDescription(desc)
								handleContetChange(desc)
							}
							setTags(tmp)
						}}
						onRemoveItem={(v) => {
							let tmp = tags.slice(0)
							let index = tmp.findIndex((t) => t.id == v.id)
							if (index != -1) {
								tmp.splice(index, 1)
								const updatedString = description.replace('[' + v.id + ']', '')
								setDescription(updatedString)
								handleContetChange(updatedString)
							}
							setTags(tmp)
						}}
					/>
				)
			}
			{
				!isAccommodation(venueType) && (
					<CustomSelect
						height={'!h-10'}
						className={'!h-10 border !border-gray6'}
						values={tagList}
						value={tags}
						isMultiple={true}
						placeholder={messageTemplateModalText('helpful_tags')}
						renderRow={(item) => <p>{item.title}</p>}
						renderValue={(item) => <span>{item?.title}</span>}
						handleChange={(v) => {
							let tmp = tags.slice(0)
							let index = tmp.findIndex((t) => t.id == v.id)
							if (index == -1) {
								tmp.push(v)
								let desc = description
								desc += '[' + v.id + ']'
								setDescription(desc)
								handleContetChange(desc)
							}
							setTags(tmp)
						}}
						onRemoveItem={(v) => {
							let tmp = tags.slice(0)
							let index = tmp.findIndex((t) => t.id == v.id)
							if (index != -1) {
								tmp.splice(index, 1)
								const updatedString = description.replace('[' + v.id + ']', '')
								setDescription(updatedString)
								handleContetChange(updatedString)
							}
							setTags(tmp)
						}}
					/>
				)
			}
			<Divider />
			<RInputDeclare
				value={messageTemplateModalText('message_preview')}
				className="!mb-2 !mt-6 !text-black1 font-medium text-base"
			/>
			<div className="flex flex-col bg-[#d1d1d1] rounded-12 started-form mt-2 px-6 py-6">
				<p className="text-black1 text-lg">{content}</p>
			</div>
		</div>
	)

	return (
		<div>
			<GenericModal
				{...{ onClose, onSubmit, loading, children }}
				title={
					data?.id
						? messageTemplateModalText('edit_template')
						: messageTemplateModalText('create_template')
				}
				open={visible}
				saveText={
					data?.id
						? messageTemplateModalText('update')
						: messageTemplateModalText('create')
				}
			/>

			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{messageTemplateModalText('vb_ai_assistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{messageTemplateModalText('ai_details')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-transparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												messageTemplateModalText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{messageTemplateModalText('add_to')} {aiField}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
