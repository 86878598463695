export const HeatmapOverlay = ({ data, width, height }) => {
   const colorScale = (value) => {
      const startColor = { r: 120, g: 55, b: 179 };
      const endColor = { r: 237, g: 217, b: 255 };

      const clampedValue = Math.max(0, Math.min(1, value));
      const r = Math.round(startColor.r + clampedValue * (endColor.r - startColor.r));
      const g = Math.round(startColor.g + clampedValue * (endColor.g - startColor.g));
      const b = Math.round(startColor.b + clampedValue * (endColor.b - startColor.b));
      return `rgb(${r}, ${g}, ${b})`;
   };

   // Calculate the number of rows and columns based on the data
   const numRows = data.length;
   const numCols = data[0].length;

   // Dynamically calculate the cell width and height to fill the entire container
   const cellWidth = width / numCols;
   const cellHeight = height / numRows;

   const gridCells = [];
   for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
         const value = data[row][col];
         gridCells.push(
            <rect
               key={`${col}-${row}`}
               x={col * cellWidth}
               y={row * cellHeight}
               width={cellWidth}
               height={cellHeight}
               fill={colorScale(value)}
               opacity={0.5}
            />
         );
      }
   }

   return (
      <div style={{ position: 'relative', width, height }}>

         <svg
            style={{
               position: 'absolute',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
            }}
            viewBox={`0 0 ${width} ${height}`}
         >
            {gridCells}
         </svg>
      </div>
   );
};

const CustomHeatmap = ({ data, width = 600, height = 400 }) => {
   console.log(data, "heatmap data");

   return data && <HeatmapOverlay data={data} width={width} height={height} />;
};

export default CustomHeatmap;
