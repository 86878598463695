import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { t } from 'i18next'
import React from 'react'

export const ordersText = (text) => t(`orderandpay.orders.${text}`)

export default function OrderSummaryBlock({ currency, data }) {
	return (
		<div className="flex flex-col mt-5 w-[35%]">
			<p className="mb-2 text-black1 text-lg font-medium">
				{ordersText('order_summary')}
			</p>
			<TableContainer component={Paper} className="mt-2">
				<Table aria-label="simple table">
					<TableHead className="bg-gray7 border-b">
						<TableRow>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="left"
							>
								{ordersText('total')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="left"
							>
								{ordersText('subtotal')}
							</TableCell>
							<TableCell
								className="!text-gray5 !text-left !font-medium !text-base"
								align="right"
							>
								{ordersText('discount')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell
								align="left"
								className="!py-3.5 !text-gray5 !text-left !text-base"
							>
								{currency}
								{data?.total_amount ?? 0}
							</TableCell>
							<TableCell
								align="left"
								className="!py-3.5 !text-gray5 !text-left !text-base "
							>
								{currency}
								{data?.subtotal ?? 0}
							</TableCell>
							<TableCell
								align="left"
								className="!py-3.5 !text-gray5 !text-left !text-base"
							>
								- {currency}
								{data?.discount_value ? data?.discount_value : 0}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
