import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import React, { useEffect } from "react";

import { Card, CardContent, TextField, MenuItem, Checkbox, ImageList, ImageListItem } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomSelect from "../../../components/custom/CustomSelect";
import { getDateTimeFromTimeSpan, isEmpty } from "../../../utils/common";
import { RInputDeclare } from "../../../components/custom/RInputDeclare";
import { useState } from "react";
import HotelEmptyData from "../../../components/hotel/hotelEmptyData";
import { CheckBox } from "@mui/icons-material";
import CustomTimePicker from "../../../components/custom/CustomTimePicker/CustomTimePicker";
import { ImgDragUpload } from "../../../components/common/ImgDragUpload";
import WebProfileService from "../../../services/webProfileService";
import { NotificationManager } from "react-notifications";
import VenueService from "../../../services/venuService";
import LoadingScreen from "../../../components/custom/LoadingScreen";
import moment from "moment";

const validator = yup.object().shape({
    name: yup.string().trim()
        .required('Name is required')
        .max(72, 'Max exceeded')
        .min(2, 'Min not met'),
    description: yup.string().trim()
        .required('Description is required')
        .max(72, 'Max exceeded')
        .min(5, 'Min not met'),
});

const GymsList = () => {
    const api_type = 'hotel_gym';
    const formikProps = useFormik({
        initialValues: { name: '', description: '' },
        validationSchema: validator,
        onSubmit: (values) => onSubmit(values),
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false
    });

    const [configured, setConfigured] = useState(false);
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
    ]);
    // checkbox
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wendsday, setWendsday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);

    // start times
    const [mondayStartTime, setmondayStartTime] = useState(new Date());
    const [tuesdayStartTime, settuesdayStartTime] = useState(new Date());
    const [wendsdayStartTime, setwendsdayStartTime] = useState(new Date());
    const [thursdayStartTime, setthursdayStartTime] = useState(new Date());
    const [fridayStartTime, setfridayStartTime] = useState(new Date());
    const [saturdayStartTime, setsaturdayStartTime] = useState(new Date());
    const [sundayStartTime, setsundayStartTime] = useState(new Date());

    // end time
    const [mondayEndTime, setmondayEndTime] = useState(new Date());
    const [tuesdayEndTime, settuesdayEndTime] = useState(new Date());
    const [wendsdayEndTime, setwendsdayEndTime] = useState(new Date());
    const [thursdayEndTime, setthursdayEndTime] = useState(new Date());
    const [fridayEndTime, setfridayEndTime] = useState(new Date());
    const [saturdayEndTime, setsaturdayEndTime] = useState(new Date());
    const [sundayEndTime, setsundayEndTime] = useState(new Date());

    useEffect(() => {
        getDetails()
    }, []);

    const getDetails = () => {
        VenueService.CheckManageVenue(api_type)
            .then(res => {
                setLoading(false);
                setConfigured(true);
                if (res.hasManaged) {
                    formikProps.setValues(res.information.information);
                    res.information.availability.forEach(item => {
                        if (item.day_of_week == "Monday") {
                            setMonday(true);
                            setmondayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            setmondayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        } else if (item.day_of_week == "Tuesday") {
                            setTuesday(true);
                            settuesdayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            settuesdayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        } else if (item.day_of_week == "Wednesday") {
                            setWendsday(true);
                            setwendsdayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            setwendsdayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        } else if (item.day_of_week == "Thursday") {
                            setThursday(true);
                            setthursdayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            setthursdayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        } else if (item.day_of_week == "Friday") {
                            setFriday(true);
                            setfridayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            setfridayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        } else if (item.day_of_week == "Saturday") {
                            setSaturday(true);
                            setsaturdayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            setsaturdayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        } else if (item.day_of_week == "Sunday") {
                            setSunday(true);
                            setsundayStartTime(getDateTimeFromTimeSpan(item.open_time));
                            setsundayEndTime(getDateTimeFromTimeSpan(item.close_time))
                        }
                    });
                    if (res.information.gallery.length > 0) {
                        const tempImages = [...images];
                        res.information.gallery.forEach((item, index) => {
                            tempImages[index] = item;
                        });
                        setImages(tempImages);
                    }
                }
            })
            .catch(err => {
                setLoading(false);
            })
    }

    const onSubmit = (values) => {
        window.scrollTo(0, 0);

        const payload = {
            ...values,
            type: api_type,
        }

        setLoading(true);
        VenueService.UpdateVenueInformation(payload)
            .then(res => {
                NotificationManager.success("Gym information has been saved successfully");
                setLoading(false);
            })
            .catch(err => {
                NotificationManager.error(err?.message ?? "Something went wrong!");
                setLoading(false);
            })
    }

    const onSubmitAvailibilty = () => {
        const payload = {
            type: api_type,
            availability: [],
        }
        if (monday) payload.availability.push({ day_of_week: 'Monday', open_time: moment(mondayStartTime).format('HH:mm'), close_time: moment(mondayEndTime).format('HH:mm'), });
        if (tuesday) payload.availability.push({ day_of_week: 'Tuesday', open_time: moment(tuesdayStartTime).format('HH:mm'), close_time: moment(tuesdayEndTime).format('HH:mm'), });
        if (wendsday) payload.availability.push({ day_of_week: 'Wednesday', open_time: moment(wendsdayStartTime).format('HH:mm'), close_time: moment(wendsdayEndTime).format('HH:mm'), });
        if (thursday) payload.availability.push({ day_of_week: 'Thursday', open_time: moment(thursdayStartTime).format('HH:mm'), close_time: moment(thursdayEndTime).format('HH:mm'), });
        if (friday) payload.availability.push({ day_of_week: 'Friday', open_time: moment(fridayStartTime).format('HH:mm'), close_time: moment(fridayEndTime).format('HH:mm'), });
        if (saturday) payload.availability.push({ day_of_week: 'Saturday', open_time: moment(saturdayStartTime).format('HH:mm'), close_time: moment(saturdayEndTime).format('HH:mm'), });
        if (sunday) payload.availability.push({ day_of_week: 'Sunday', open_time: moment(sundayStartTime).format('HH:mm'), close_time: moment(sundayEndTime).format('HH:mm'), });


        setLoading(true);
        VenueService.UpdateVenueAvailability(payload)
            .then(res => {
                NotificationManager.success("Gym Availability has been saved successfully");
                setLoading(false);
            })
            .catch(err => {
                NotificationManager.error(err?.message ?? "Something went wrong!");
                setLoading(false);
            })
    }

    const onUploadImage = (image, index) => {
        let formData;
        formData = new FormData();
        formData.append("type", "gallery");
        formData.append("facility_type", api_type)
        if (image?.type === 'file' && image?.url != null) {
            formData.append('photo', image.file);
        }
        setLoading(true);
        VenueService.uploadFacilitiesPhoto(formData)
            .then(res => {
                // hope response has the new image, or at least id
                getDetails();
                NotificationManager.success("Image has been uploaded successfully");
            })
            .catch(err => {
                setLoading(false);
                NotificationManager.error(err?.message ?? "Something went wrong!");
            })
    }

    const onCancelImage = (index) => {
        setLoading(true);
        VenueService.deleteFacilitiesPhoto(images[index].photo_id)
            .then(res => {
                setLoading(false);
                if (images[index]) {
                    const tempImages = [...images];
                    tempImages[index] = null;
                    setImages(tempImages);
                }
            })
            .catch(err => {
                setLoading(false);
                NotificationManager.error(err?.message ?? "Unable do delete Photo")
            })
    }
    if (loading) {
        return <LoadingScreen />
    }
    return (
        <div className={'sub-container view-sales overflow-auto py-8 px-4 h-full'} style={{ height: 'calc(100vh - 76px - 68px)' }}>
            <Card>
                <CardContent className="!p-0">
                    <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5 pb-5">
                        <div className="flex flex-row items-center">
                            <span className="text-black1 text-l font-medium">
                                Manage your hotel gym details for better management
                            </span>
                        </div>
                    </div>

                </CardContent>
            </Card>
            {configured ?
                <>
                    <Card className="mt-6">
                        <p style={{ padding: "20px 15px 20px 15px" }} className="text-black1 font-medium text-[20px]">Gym Information</p>
                        <CardContent style={{ background: '#f6f6f8' }}>

                            <div className='flex flex-col'>
                                <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                                    <div className='flex flex-col'>
                                        <RInputDeclare value={"Name"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                        <TextField
                                            required
                                            size='small'
                                            value={formikProps.values.name}
                                            error={formikProps.errors.name}
                                            placeholder="Name"
                                            style={{ background: '#fff' }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: '1px solid #d9d9d9!important'
                                                }
                                            }}
                                            onChange={(e) => { formikProps.setFieldValue('name', e.target.value) }}
                                        />
                                        {
                                            !isEmpty(formikProps.errors.name) &&
                                            <div className="text-red-600 text-12 mt-2">{formikProps.errors.name}</div>
                                        }
                                    </div>
                                    <div className='flex flex-col'>
                                        <RInputDeclare value={"Description"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                        <TextField
                                            required
                                            size='small'
                                            maxRows={5}
                                            minRows={5}
                                            multiline
                                            value={formikProps.values.description}
                                            error={formikProps.errors.description}
                                            placeholder="Description"
                                            style={{ background: '#fff' }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: '1px solid #d9d9d9!important'
                                                }
                                            }}
                                            onChange={(e) => { formikProps.setFieldValue('description', e.target.value) }}
                                        />
                                        {
                                            !isEmpty(formikProps.errors.description) &&
                                            <div className="text-red-600 text-12 mt-2">{formikProps.errors.description}</div>
                                        }
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
                                    <button
                                        className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                                    >
                                        Clear
                                    </button>
                                    <button
                                        className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                                        onClick={formikProps.handleSubmit}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                    <Card className="mt-6">
                        <p style={{ padding: "20px 15px 20px 15px" }} className="text-black1 font-medium text-[20px]">Gym Availability</p>
                        <CardContent style={{ background: '#f6f6f8' }}>
                            <div className='flex flex-col'>
                                <div className='grid md:grid-cols-2 grid-cols-1 gap-8 py-2'>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Mon"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={monday} onChange={e => setMonday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={mondayStartTime}
                                                onChange={setmondayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={mondayEndTime}
                                                onChange={setmondayEndTime}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Fri"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={friday} onChange={e => setFriday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={fridayStartTime}
                                                onChange={setfridayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={fridayEndTime}
                                                onChange={setfridayEndTime}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='grid md:grid-cols-2 grid-cols-1 gap-8 py-2'>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Tue"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={tuesday} onChange={e => setTuesday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={tuesdayStartTime}
                                                onChange={settuesdayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={tuesdayEndTime}
                                                onChange={settuesdayEndTime}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Sat"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={saturday} onChange={e => setSaturday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={saturdayStartTime}
                                                onChange={setsaturdayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={saturdayEndTime}
                                                onChange={setsaturdayEndTime}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='grid md:grid-cols-2 grid-cols-1 gap-8 py-2'>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Weds"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={wendsday} onChange={e => setWendsday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={wendsdayStartTime}
                                                onChange={setwendsdayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={wendsdayEndTime}
                                                onChange={setwendsdayEndTime}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Sun"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={sunday} onChange={e => setSunday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={sundayStartTime}
                                                onChange={setsundayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={sundayEndTime}
                                                onChange={setsundayEndTime}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='grid md:grid-cols-2 grid-cols-1 gap-8 py-2'>
                                    <div className='flex flex-row justify-start center-align p-2 gap-8'>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Thur"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <Checkbox checked={thursday} onChange={e => setThursday(e.target.checked)} className="mt-2" />

                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"Open Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={thursdayStartTime}
                                                onChange={setthursdayStartTime}
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <RInputDeclare value={"End Time"} className="!mt-0 !mb-2 !text-black1 font-medium text-base" />
                                            <CustomTimePicker
                                                classes='bg-white'
                                                value={thursdayEndTime}
                                                onChange={setthursdayEndTime}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start center-align p-2'>
                                        <div className="flex flex-row justify-center align-center h-[48px] mt-4">
                                            <button
                                                className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                                                onClick={onSubmitAvailibilty}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="mt-6">
                        <p style={{ padding: "20px 15px 20px 15px" }} className="text-black1 font-medium text-[20px]">Gym Images</p>
                        <CardContent style={{ background: '#f6f6f8' }}>
                            <div className='relative started-form'>
                                <div className="flex flex-col h-full mt-0 py-4">
                                    <div className='grid md:grid-cols-1 grid-cols-1 gap-8'>
                                        <ImageList variant="quilted" cols={6}>
                                            {
                                                images.map((item, index) => (
                                                    <ImageListItem key={'img_uploader' + index} className="pl-2 pb-2 pt-1">
                                                        <ImgDragUpload
                                                            backgroundColor={'#fff'}
                                                            image={item ? { url: item?.photo_path } : null}
                                                            onCancel={() => onCancelImage(index)}
                                                            onChange={(img) => onUploadImage(img, index)}
                                                            label={'Click to upload or drag and drop, PNG, JPG or JPEG (max. 12MB)'}
                                                            classes="max-h-[190px] p-2 mb-2 mt-2"
                                                        />
                                                    </ImageListItem>
                                                ))}
                                        </ImageList>
                                    </div>

                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </>
                :
                <div className="border border-b-0 mb-10">
                    <HotelEmptyData content={'gym'} onAction={() => setConfigured(true)} />
                </div>
            }
        </div>
    );
};

export default GymsList;
