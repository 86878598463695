import apiFactory from "../utils/apiFactory";
import { API_REFERRALS  } from "../constants/API";

export default class ReferralsService {
  static getReferrals = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REFERRALS}`,
      method: "GET",
    };
    return apiFactory(api);
  }
}
