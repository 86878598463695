import "react-clock/dist/Clock.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import { boxStyle } from "../../constants/types";
import { RInputDeclare } from "../custom/RInputDeclare";
import { CircularProgress, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import icon from "../../assets/svgs/credit-card.svg";
import saleIcon from '../../assets/svgs/sale_white.svg'
import { NotificationManager } from "react-notifications";
import VenueService from "../../services/venuService";
import CustomSelect from "../custom/CustomSelect";
import CustomDateTimePicker from "../custom/CustomDateTimePicker";

const reasonList = [
    'Going on vacation',
    'Not yet ready to go live',
    'Booking season has ended',
    'Calendar fully booked',
    'Natural disaster in area',
    'Venue Undergoing maintenance',
    'Not yet ready to take bookings/accept reservations'
]

const howLongList = [
    { value: 1, title: '1 week' },
    { value: 2, title: '2 weeks' },
    { value: 3, title: '3 weeks' },
    { value: 4, title: '4 weeks' },
    { value: 5, title: '5 weeks' },
    { value: 6, title: '6 weeks' },
    { value: -1, title: 'select date' },
]

export default function PauseVenueModal({ showModal, onConfirm, onClose, type }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [checkValid, setCheckValid] = useState(false);
    const [reason, setReason] = useState("");
    const [howLong, setHowLong] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());



    useEffect(() => {
        if (showModal) {
            // initialize
            setCheckValid(false);
            setReason("");
            setHowLong(null);
            setStartDate(new Date());
            setEndDate(new Date());
        }
        setVisible(showModal);
    }, [showModal]);

    const onSubmit = () => {
        if (reason && howLong) {
            let startTime = new Date();
            let sentBackendStartTime = null
            let endTime = new Date();
            if (howLong == 'select date') {
                sentBackendStartTime = startDate;
                endTime = endDate;
            } else {
                const weeks = howLongList.find(item => item.title == howLong).value;
                endTime.setDate(startTime.getDate() + (7 * weeks));

                sentBackendStartTime = null;

            }
            let payload = {
                reason: reason,
                end_time: moment(endTime).format("YYYY-MM-DD 23:59:59"),
                start_time: sentBackendStartTime ? moment(sentBackendStartTime).format("YYYY-MM-DD 23:59:59") : null,

            }
            onConfirm(payload);
        } else {
            setCheckValid(true)
        }


    };
    const onCloseModal = () => {
        onClose(null);
    };


    return (
        <div>
            <Modal
                open={visible}
                onClose={onClose}
                className="schedule-modal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={boxStyle} className="outline-none">
                    <div className="flex flex-row items-center px-024 pb-02 pt-024 gap-3">
                        <div className="p-2 bg-gray7 rounded-full">
                            <div className="bg-black1 p-2 rounded-full">
                                <img src={saleIcon} alt="icon" />
                            </div>
                        </div>
                        <label className="text-20 font-semibold text-black1">Pause venue</label>
                    </div>
                    <div className="flex flex-col p-024">
                        <RInputDeclare value={`Why do you want to pause your venue?`} className="!mt-0 !mb-1 !text-black1 font-bold text-base" />
                        <RInputDeclare value={`Select reason`} className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                        <CustomSelect
                            values={reasonList}
                            value={reason}
                            height={'!h-10'}
                            className={'!h-10 border !border-gray6'}
                            renderRow={(item) => (
                                <p>{item}</p>
                            )}
                            handleChange={(v) => {
                                setReason(v);
                            }}
                        />
                        <RInputDeclare value={`How long do you want to pause your venue?`} className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                        <CustomSelect
                            values={howLongList}
                            value={howLong}
                            height={'!h-10'}
                            className={'!h-10 border !border-gray6'}
                            renderRow={(item) => (
								<p>{item.title}</p>
							)}
                            handleChange={(v) => {
                                setHowLong(v.title);
                            }}
                        />
                        {howLong == 'select date' ? <div className='grid md:grid-cols-2 grid-cols-1 gap-8'>
                            <div className='flex flex-col'>
                                <RInputDeclare value={`Select start date`} className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <CustomDateTimePicker
                                    onlyDate={true}
                                    value={startDate}
                                    onChange={(value) => setStartDate(value)}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <RInputDeclare value={`Select end date`} className="!mt-4 !mb-2 !text-black1 font-medium text-base" />
                                <CustomDateTimePicker
                                    onlyDate={true}
                                    value={endDate}
                                    onChange={(value) => setEndDate(value)}
                                />
                            </div>
                        </div>
                            : null}
                    </div>
                    <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
                        <button
                            className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                            onClick={onCloseModal}>
                            Cancel
                        </button>
                        <button className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white" onClick={onSubmit}>
                            {loading ? <CircularProgress size={16} /> : 'Submit'}
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
