import { CloseOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useRef } from 'react'
import uploadIcon from '../../assets/svgs/upload.svg'

export const ImgDragUpload = ({
	image,
	classes = '',
	img_classes = '',
	onChange,
	label,
	backgroundColor = '',
	onCancel,
	hideCancel = false,
}) => {
	const image_file_input = useRef(null)

	const _handleImageChange = (e) => {
		e.preventDefault()
		try {
			let reader = new FileReader()
			let file = e.target.files[0]

			reader.onloadend = () => {
				onChange({
					type: 'file',
					url: reader.result,
					file: file,
				})
			}

			reader.readAsDataURL(file)
		} catch (err) {
			console.log('handle image error', err)
		}
	}

	const _handleImageCancel = (e) => {
		e.stopPropagation()
		if (onCancel) {
			onCancel(null)
		}
	}

	return (
		<div
			className={`flex flex-col cursor-pointer justify-center items-center relative border border-gray-300 rounded-lg h-full ${classes}`}
			onClick={() => image_file_input.current?.click()}
			style={{ backgroundColor: backgroundColor }}
		>
			{image ? (
				<div className="w-full h-full">
					<img
						className={` h-full object-fit rounded-lg ${img_classes}`}
						style={{ objectFit: 'contain' }}
						src={image.url}
						alt="preview"
					/>
				</div>
			) : (
				<div
					className="flex flex-col items-center mt-2 cursor-pointer text-14 text-blue-500"
					onClick={() => {
						// image_file_input.current?.click();
					}}
				>
					<div className="p-2 bg-gray7 rounded-full w-max">
						<div className="bg-black1 p-2 rounded-full">
							<img src={uploadIcon} alt="icon" />
						</div>
					</div>
						<span className={'text-black1 text-base text-center px-2 pb-2'}>
						{label ??
							'Click to upload or drag and drop, PNG, JPG or JPEG (max. 12MB)'}
					</span>
				</div>
			)}
			{image != null && hideCancel == false && (
				<IconButton
					style={{
						position: 'absolute',
						width: 28,
						height: 28,
						right: -10,
						top: -8,
						backgroundColor: '#aaa',
					}}
					onClick={_handleImageCancel}
				>
					<CloseOutlined style={{ color: '#fff' }} />
				</IconButton>
			)}
			<input
				hidden={true}
				ref={image_file_input}
				type="file"
				accept="image/*"
				onChange={_handleImageChange}
			/>
		</div>
	)
}
