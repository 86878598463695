import { CircularProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/dollar_white.svg'
import { smallBoxStyle } from '../../constants/types'
import ReservationService from '../../services/reservationService'
import { RInputDeclare } from '../custom/RInputDeclare'

const providePaymentModalText = (text) =>
	t(`components.reservation.ProvidePaymentModal.${text}`)

const payWithOptions = [
	{
		label: 'Cash',
		value: 'Cash',
	},
	{
		label: 'Card',
		value: 'Card',
	},
]

export default function ProvidePaymentModal({
	showModal,
	onConfirm,
	onClose,
	data,
	reservationId,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [subtotal, setSubtotal] = useState('')
	const [payedWith, setPayedWith] = useState('')
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setPayedWith('')
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (subtotal && payedWith) {
			setLoading(true)
			let payload = {
				subtotal: subtotal,
				payment_method: payedWith,
			}
			ReservationService.ProvidePaymentMethod(reservationId, payload)
				.then(({ data }) => {
					setLoading(false)
					onConfirm()
					NotificationManager.success(
						data?.message ?? 'Provide payment method saved successfully'
					)
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(err?.error ?? 'Something went wrong!')
				})
		} else {
			setCheckValid(true)
		}
	}
	const onCloseModal = () => {
		onClose(false)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={smallBoxStyle} className="outline-none">
					<div
						className="flex flex-row items-center px-024 pt-024 pb-0 gap-3"
						style={{ marginBottom: '-20px' }}
					>
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{providePaymentModalText('providePaymentDetails')}
						</label>
					</div>
					<div className="flex flex-col p-024">
						<div className="flex flex-row gap-2">
							<RInputDeclare
								value={providePaymentModalText('subtotal')}
								className="text-base !text-black1 font-medium !mt-4 !mb-2"
							/>
							<RInputDeclare value="*required" className="text-base !mb-2" />
						</div>
						<TextField
							required
							type="number"
							size="small"
							error={checkValid && !subtotal}
							id="filled-required"
							value={subtotal}
							onChange={(e) => setSubtotal(e.target.value)}
							inputProps={{
								inputProps: { min: 0 },
							}}
						/>

						<RInputDeclare
							value={providePaymentModalText('paidWith')}
							className="!mt-4 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							size="small"
							error={checkValid && !payedWith}
							select
							value={payedWith}
							onChange={(event) => setPayedWith(event.target.value)}
						>
							{payWithOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onCloseModal}
						>
							{providePaymentModalText('cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								providePaymentModalText('save')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
