import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import "./index.css";

import { useFormik } from "formik";
import { t } from 'i18next';
import { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AmexIcon from "../../../../assets/svgs/Amex.svg";
import JCBIcon from "../../../../assets/svgs/JCB.svg";
import MaestroIcon from "../../../../assets/svgs/Maestro.svg";
import Mastercardicon from "../../../../assets/svgs/Mastercard.svg";
import VisaIcon from "../../../../assets/svgs/Visa.svg";
import Checkicon from "../../../../assets/svgs/check_green.svg";
import editIcon from "../../../../assets/svgs/edit.svg";
import InfoIcon from "../../../../assets/svgs/info_black.svg";
import trashIcon from "../../../../assets/svgs/trash.svg";
import CancellationModal from "../../../../components/accommodation/cancellationModal";
import UpdatePricePerNightModal from "../../../../components/accommodation/updatePricePerNightModal";
import AreYouSureModal from "../../../../components/common/areYouSureModal";
import CustomDateTimePicker from "../../../../components/custom/CustomDateTimePicker";
import LoadingScreen from "../../../../components/custom/LoadingScreen";
import { RInputDeclare } from "../../../../components/custom/RInputDeclare";
import accommodationService from "../../../../services/accommodationService";
import { createUUID, isEmpty } from "../../../../utils/common";
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil';

export const pricingAndCalendarFormText = (text) =>
	t(`accommodation.rental_unit.show.pricingAndCalendarForm.${text}`)

const PricingAndCalendarForm = ({ data, onChange, rentalUnitId, currency }) => {
	const [editModal, setEditModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedCardPreferences, setSelectedCardPreferences] = useState([])
	const [pricingPerNights, setPricingPerNights] = useState([])
	const [updatingCardPreferences, setUpdatingCardPreferences] = useState(false)
	const [updatingCashCardCapability, setUpdatingCashCardCapability] =
		useState(false)
	const [updatingPricePerNight, setUpdatingPricePerNight] = useState(false)
	const [updatingCalendarCapability, setUpdatingCalendarCapability] =
		useState(false)
	const [currentPricePerNight, setCurrentPricePerNight] = useState({})
	const [isSureModalOpen, setIsSureModalOpen] = useState(false)
	const _curPricePerNight = useRef(null)
	const [showPricePerNightModal, setShowPricePerNightModal] = useState(false)

	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		loadRegisterConfig()
	}, [])

	useEffect(() => {
		setPricingPerNights(data?.price_per_nights)
		cashCardCapabilityFormikProps.setFieldValue(
			'card_capability',
			data?.card_capability
		)
		cashCardCapabilityFormikProps.setFieldValue(
			'cash_capability',
			data?.cash_capability
		)
		calendarCapabilityFormikProps.setFieldValue(
			'booking_acceptance_date',
			data?.booking_acceptance_date
		)
		calendarCapabilityFormikProps.setFieldValue(
			'booking_acceptance_type',
			data?.booking_acceptance_type
		)
		if (data?.card_preferences) {
			setSelectedCardPreferences(
				data?.card_preferences.map((item) => item?.card_type) ?? []
			)
		}
	}, [data])

	const loadRegisterConfig = () => {}

	const currencySymbols = {
		ALL: 'Lek',
		USD: '$',
		EUR: '€',
		// Add other currencies and their symbols here
	}

	const getCurrencySymbol = (currencyCode) => {
		return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
	}

	const cashCardCapabilityFormikProps = useFormik({
		initialValues: { card_capability: false, cash_capability: false },
		validationSchema: yup.object().shape({
			card_capability: yup.boolean(),
		}),
		onSubmit: (values) => onSubmitCashCardCapability(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const calendarCapabilityFormikProps = useFormik({
		initialValues: {
			booking_acceptance_date: null,
			booking_acceptance_type: 'asap',
		},
		validationSchema: yup.object().shape({
			booking_acceptance_date: yup.string() || null,
		}),
		onSubmit: (values) => onSubmitCalendarCapability(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const pricePerNightFormikProps = useFormik({
		initialValues: {
			nr_guests: null,
			discount: null,
			price: null,
		},
		validationSchema: yup.object().shape({
			nr_guests: yup.number().required('Guests nr is required'),
			price: yup.number().required('Price is required'),
		}),
		onSubmit: (values) => onSubmitPricePerNight(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const onDeletePricePerNight = (e, item) => {
		setCurrentPricePerNight(item.id)
		setIsSureModalOpen(true)
	}

	const onEditPricePerNight = (e, item) => {
		_curPricePerNight.current = item
		setShowPricePerNightModal(true)
	}

	const onHandleCardPreferenceChange = (checked, value) => {
		// bind for checked
		let tmp = Object.assign([], selectedCardPreferences)
		if (checked == true) {
			tmp.push(value)
			setSelectedCardPreferences(tmp)
		} else {
			var index = tmp.indexOf(value)
			if (index !== -1) {
				tmp.splice(index, 1)
				setSelectedCardPreferences(tmp)
			}
		}
	}

	const onSubmitCashCardCapability = (values) => {
		setUpdatingCashCardCapability(true)
		const details = {
			type: 'cash_card_capability',
			card_capability: values.card_capability,
			cash_capability: values.cash_capability,
		}
		accommodationService
			.UpdateReantalUnitPricingCalendar(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'pricing calendar',
				})
				setUpdatingCashCardCapability(false)
				NotificationManager.success(
					'Card capability has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=5`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'pricing calendar',
				})
				setUpdatingCashCardCapability(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitPricingCalendar')
			})
	}

	const onSubmitCalendarCapability = (values) => {
		setUpdatingCalendarCapability(true)
		const details = {
			type: 'booking_acceptance_date',
			booking_acceptance_date:
				values.booking_acceptance_type === 'asap'
					? null
					: values.booking_acceptance_date,
		}
		accommodationService
			.UpdateReantalUnitPricingCalendar(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'pricing calendar',
				})
				setUpdatingCalendarCapability(false)
				NotificationManager.success(
					'Booking acceptance date has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=5`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'pricing calendar',
				})
				setUpdatingCalendarCapability(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitPricingCalendar')
			})
	}

	const onSubmitPricePerNight = (values) => {
		setUpdatingPricePerNight(true)
		const details = {
			type: 'price_per_night',
			nr_guests: values.nr_guests,
			discount: values.discount,
			price: values.price,
		}
		accommodationService
			.UpdateReantalUnitPricingCalendar(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'pricing calendar',
				})
				setUpdatingPricePerNight(false)
				NotificationManager.success(
					'Price Per Night has been added successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=5`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'pricing calendar',
				})
				setUpdatingPricePerNight(false)
				NotificationManager.error(
					error.error || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitPricingCalendar')
			})
	}

	const saveCardPreferences = () => {
		setUpdatingCardPreferences(true)
		const details = {
			type: 'card_preferences',
			card_preferences: selectedCardPreferences,
		}
		accommodationService
			.UpdateReantalUnitPricingCalendar(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'pricing calendar',
				})
				setUpdatingCardPreferences(false)
				NotificationManager.success(
					'Card preferences has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=5`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('Reantal Unit', {
					action_category: 'update',
					action_outcome: 'fail',
					interaction_element: 'pricing calendar',
				})
				setUpdatingCardPreferences(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitPricingCalendar')
			})
	}

	if (loading) {
		return <LoadingScreen />
	}

	return (
		<>
			<Card>
				<CardContent className="!p-0">
					<div className="w-full rounded-4xl p-6 pt-2 gap-10">
						<div className="relative started-form">
							<div>
								<p className={'text-base text-gray5 my-4'}>
									<span className="text-black1 text-20 font-bold">
										{pricingAndCalendarFormText('guest_payment_options_title')}
									</span>
								</p>
								<div className="flex flex-row gap-12">
									<div className="w-[60%] ">
										<div className="p-[22px] rounded-t-[10px] border-[#E0E0E0] border-[1px]">
											<p className="text-black1 text-18 font-semibold leading-[35px]">
												{pricingAndCalendarFormText('card_payments_question')}
											</p>
											<RadioGroup
												value={
													cashCardCapabilityFormikProps.values.card_capability
												}
												onChange={(e) => {
													cashCardCapabilityFormikProps.setFieldValue(
														'card_capability',
														e.target.value
													)
												}}
											>
												<FormControlLabel
													value={1}
													control={<Radio className="border-[#DDD]" />}
													label={pricingAndCalendarFormText('yes')}
												/>
												<FormControlLabel
													value={0}
													control={<Radio className="border-[#DDD]" />}
													label={pricingAndCalendarFormText('no')}
												/>
											</RadioGroup>

											<p className="text-black1 text-18 font-semibold mt-4 leading-[35px]">
												{pricingAndCalendarFormText('cash_payments_question')}
											</p>
											<RadioGroup
												value={
													cashCardCapabilityFormikProps.values.cash_capability
												}
												onChange={(e) => {
													cashCardCapabilityFormikProps.setFieldValue(
														'cash_capability',
														e.target.value
													)
												}}
											>
												<FormControlLabel
													value={1}
													control={<Radio className="border-[#DDD]" />}
													label={pricingAndCalendarFormText('yes')}
												/>
												<FormControlLabel
													value={0}
													control={<Radio className="border-[#DDD]" />}
													label={pricingAndCalendarFormText('no')}
												/>
											</RadioGroup>
											<div className="flex flex-row justify-between mt-4 h-[48px] gap-8">
												<button
													type="submit"
													className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
													onClick={() => {
														cashCardCapabilityFormikProps.handleSubmit()
													}}
												>
													{updatingCashCardCapability ? (
														<CircularProgress size={16} />
													) : (
														pricingAndCalendarFormText('update')
													)}
												</button>
											</div>
										</div>
										<div className="p-[22px] rounded-b-[10px] border-[#E0E0E0] border-[1px]">
											<p className="text-black1 text-18 font-semibold leading-[35px]">
												{pricingAndCalendarFormText('card_preferences_title')}
											</p>
											<FormGroup>
												<FormControlLabel
													control={
														<div className="flex items-center mr-3">
															<Checkbox
																onChange={(e) =>
																	onHandleCardPreferenceChange(
																		e.target.checked,
																		'American Express'
																	)
																}
																checked={selectedCardPreferences.includes(
																	'American Express'
																)}
															/>
															<img
																src={AmexIcon}
																className="w-[38px] h-[24px]"
															/>
														</div>
													}
													label={pricingAndCalendarFormText(
														'card_preference_amex'
													)}
												/>
												<FormControlLabel
													control={
														<div className="flex items-center mr-3">
															<Checkbox
																onChange={(e) =>
																	onHandleCardPreferenceChange(
																		e.target.checked,
																		'Visa'
																	)
																}
																checked={selectedCardPreferences.includes(
																	'Visa'
																)}
															/>
															<img
																src={VisaIcon}
																className="w-[38px] h-[24px]"
															/>
														</div>
													}
													label={pricingAndCalendarFormText(
														'card_preference_visa'
													)}
												/>
												<FormControlLabel
													control={
														<div className="flex items-center mr-3">
															<Checkbox
																onChange={(e) =>
																	onHandleCardPreferenceChange(
																		e.target.checked,
																		'Mastercard'
																	)
																}
																checked={selectedCardPreferences.includes(
																	'Mastercard'
																)}
															/>
															<img
																src={Mastercardicon}
																className="w-[38px] h-[24px]"
															/>
														</div>
													}
													label={pricingAndCalendarFormText(
														'card_preference_mastercard'
													)}
												/>
												<FormControlLabel
													control={
														<div className="flex items-center mr-3">
															<Checkbox
																onChange={(e) =>
																	onHandleCardPreferenceChange(
																		e.target.checked,
																		'JCB'
																	)
																}
																checked={selectedCardPreferences.includes(
																	'JCB'
																)}
															/>
															<img
																src={JCBIcon}
																className="w-[38px] h-[24px]"
															/>
														</div>
													}
													label={pricingAndCalendarFormText(
														'card_preference_jcb'
													)}
												/>
												<FormControlLabel
													control={
														<div className="flex items-center mr-3">
															<Checkbox
																onChange={(e) =>
																	onHandleCardPreferenceChange(
																		e.target.checked,
																		'Maestro'
																	)
																}
																checked={selectedCardPreferences.includes(
																	'Maestro'
																)}
															/>
															<img
																src={MaestroIcon}
																className="w-[38px] h-[24px]"
															/>
														</div>
													}
													label={pricingAndCalendarFormText(
														'card_preference_maestro'
													)}
												/>
											</FormGroup>
											<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
												<button
													type="submit"
													className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
													onClick={() => {
														saveCardPreferences()
													}}
												>
													{updatingCardPreferences ? (
														<CircularProgress size={16} />
													) : (
														pricingAndCalendarFormText('update')
													)}
												</button>
											</div>
										</div>
									</div>

									<div className="w-[40%] flex flex-col gap-12">
										<div className="relative rounded-[10px]  border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
											<div className="absolute left-[22px] top-[22px]">
												<img className="w-8 h-8" src={InfoIcon} />
											</div>

											<h4 className="text-black1 text-18 font-bold leading-7">
												{pricingAndCalendarFormText(
													'streamlining_transactions_title'
												)}
											</h4>
											<p className="text-16 text-[#666A73] font-normal leading-7">
												{pricingAndCalendarFormText(
													'streamlining_transactions_description'
												)}
											</p>
										</div>
									</div>
								</div>
							</div>

							<hr className="mt-4 mb-8" />

							<div>
								<p className={'text-base text-gray5 my-4 '}>
									<span className="text-black1 text-20 font-bold">
										{pricingAndCalendarFormText('price_per_night_title')}
									</span>
								</p>
								<div className="flex flex-row gap-12">
									<div className="w-[60%] ">
										<div className="p-[22px] rounded-t-[10px] border-[#E0E0E0] border-[1px]">
											<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
												{pricingAndCalendarFormText('price_per_night_question')}{' '}
												{currency
													? '(' + getCurrencySymbol(currency) + ')'
													: ''}
											</p>

											<TableContainer component={Paper}>
												<Table
													sx={{ minWidth: 150 }}
													aria-label="simple table"
													className="text-center"
												>
													<TableBody>
														<TableRow key={createUUID()} className="bg-gray7">
															<TableCell
																align="left"
																className="!text-gray-500 font-bold"
															>
																{pricingAndCalendarFormText('occupancy')}
															</TableCell>
															<TableCell
																align="left"
																className="!text-gray-500"
															>
																{pricingAndCalendarFormText('discount')}
															</TableCell>
															<TableCell
																align="left"
																className="!text-gray-500"
															>
																{pricingAndCalendarFormText('price')}
															</TableCell>
															<TableCell
																align="right"
																className="!text-gray-500"
															>
																{pricingAndCalendarFormText('actions')}
															</TableCell>
														</TableRow>
														{(pricingPerNights ? pricingPerNights : []).map(
															(row, index) => (
																<TableRow key={createUUID()}>
																	<TableCell
																		align="left"
																		className="!text-gray-500"
																	>
																		{row.nr_guests}{' '}
																		{pricingAndCalendarFormText('guests')}
																	</TableCell>
																	<TableCell
																		align="left"
																		className="!text-gray-500"
																	>
																		{row.discount ?? 0} %
																	</TableCell>
																	<TableCell
																		align="left"
																		className="!text-gray-500"
																	>
																		{row.price}{' '}
																		{currency
																			? '' + getCurrencySymbol(currency) + ''
																			: ''}
																	</TableCell>
																	<TableCell
																		align="right"
																		className="!flex flex-row items-center"
																	>
																		<IconButton
																			color="secondary"
																			className="h-12"
																			onClick={(e) =>
																				onDeletePricePerNight(e, row)
																			}
																		>
																			<img src={trashIcon} alt="trash" />
																		</IconButton>
																		<IconButton
																			color="secondary"
																			onClick={(e) =>
																				onEditPricePerNight(e, row)
																			}
																		>
																			<img src={editIcon} alt="edit" />
																		</IconButton>
																	</TableCell>
																</TableRow>
															)
														)}
													</TableBody>
												</Table>
											</TableContainer>
										</div>

										<div
											style={{
												padding: '22px',
												paddingTop: '5px',
												marginTop: '10px',
												paddingBottom: '5px',
											}}
											className={'border-[#E0E0E0] border-[1px]'}
										>
											<p className="text-black1 text-18 font-semibold leading-[35px] p-0 mt-4">
												{pricingAndCalendarFormText(
													'pricing_customizations_title'
												)}
											</p>
											<div className="flex flex-col">
												<RInputDeclare
													value={'Nr of guests'}
													className="!mt-4 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													type="number"
													size="small"
													value={pricePerNightFormikProps.values.nr_guests}
													onChange={(e) => {
														pricePerNightFormikProps.setFieldValue(
															'nr_guests',
															e.target.value
														)
													}}
												/>
												{!isEmpty(
													pricePerNightFormikProps.errors.nr_guests
												) && (
													<div className="text-red-600 text-14 mt-2">
														{pricePerNightFormikProps.errors.nr_guests}
													</div>
												)}

												<RInputDeclare
													value={'Discount (%)'}
													className="!mt-4 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													type="number"
													size="small"
													value={pricePerNightFormikProps.values.discount}
													onChange={(e) => {
														pricePerNightFormikProps.setFieldValue(
															'discount',
															e.target.value
														)
													}}
												/>

												<RInputDeclare
													value={'Price'}
													className="!mt-4 !mb-2 !text-black1 font-medium text-base"
												/>
												<TextField
													required
													size="small"
													type="number"
													value={pricePerNightFormikProps.values.price}
													onChange={(e) => {
														pricePerNightFormikProps.setFieldValue(
															'price',
															e.target.value
														)
													}}
												/>
												{!isEmpty(pricePerNightFormikProps.errors.price) && (
													<div className="text-red-600 text-14 mt-2">
														{pricePerNightFormikProps.errors.price}
													</div>
												)}
											</div>
											<div className="flex flex-row justify-between mt-4 mb-6 h-[48px] gap-8">
												<button
													type="submit"
													className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
													onClick={() => {
														pricePerNightFormikProps.handleSubmit()
													}}
												>
													{updatingPricePerNight ? (
														<CircularProgress size={16} />
													) : (
														pricingAndCalendarFormText('save')
													)}
												</button>
											</div>
										</div>

										<div className="p-[22px] rounded-b-[10px] border-[#E0E0E0] border-[1px]">
											<p className="text-black1 text-18 font-semibold leading-[35px] py-2">
												{pricingAndCalendarFormText('commission_text')}
											</p>
											<div className="flex flex-col gap-4 text-18 py-2">
												<div className="flex gap-4">
													<img src={Checkicon} />
													<p className="text-[#707070]">
														{pricingAndCalendarFormText('commission_help_text')}
													</p>
												</div>
												<div className="flex gap-4">
													<img src={Checkicon} />
													<p className="text-[#707070]">
														{pricingAndCalendarFormText(
															'commission_booking_text'
														)}
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="w-[40%] flex flex-col gap-12">
										<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
											<div className="absolute left-[22px] top-[22px]">
												<img className="w-8 h-8" src={InfoIcon} />
											</div>

											<h4 className="text-black1 text-18 font-bold leading-7">
												{pricingAndCalendarFormText('determining_value_title')}
											</h4>
											<p className="text-16 text-[#666A73] font-normal leading-7">
												{pricingAndCalendarFormText(
													'determining_value_description'
												)}
											</p>
										</div>
									</div>
								</div>
							</div>

							<hr className="my-4" />
							<div>
								<p className={'text-base text-gray5 mb-4'}>
									<span className="text-black1 text-20 font-bold">
										{pricingAndCalendarFormText('calendar_title')}
									</span>
								</p>
								<div className="flex flex-row gap-12">
									<div className="w-[60%]">
										<div className="p-[22px] relative rounded-[10px] border-[#E0E0E0] border-[1px]">
											<p className="text-black1 text-18 font-semibold leading-[35px]">
												{pricingAndCalendarFormText('calendar_question')}
											</p>
											<RadioGroup
												value={
													calendarCapabilityFormikProps.values
														.booking_acceptance_type
												}
												onChange={(e) => {
													calendarCapabilityFormikProps.setFieldValue(
														'booking_acceptance_type',
														e.target.value
													)
												}}
											>
												<FormControlLabel
													value={1}
													control={
														<Radio className="border-[#DDD]" value={'asap'} />
													}
													label={pricingAndCalendarFormText('asap')}
												/>
												<FormControlLabel
													value={0}
													control={
														<Radio
															className="border-[#DDD]"
															value={'specific_date'}
														/>
													}
													label={pricingAndCalendarFormText('specific_date')}
												/>
											</RadioGroup>
											{calendarCapabilityFormikProps.values
												.booking_acceptance_type !== 'asap' && (
												<CustomDateTimePicker
													onlyDate={true}
													value={
														calendarCapabilityFormikProps.values
															.booking_acceptance_date
													}
													onChange={(e) => {
														calendarCapabilityFormikProps.setFieldValue(
															'booking_acceptance_date',
															e
														)
													}}
												/>
											)}
										</div>

										<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
											<button
												type="submit"
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												onClick={() => {
													calendarCapabilityFormikProps.handleSubmit()
												}}
											>
												{updatingCalendarCapability ? (
													<CircularProgress size={16} />
												) : (
													pricingAndCalendarFormText('update')
												)}
											</button>
										</div>
									</div>

									<div className="w-[40%] flex flex-col gap-12">
										<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
											<div className="absolute left-[22px] top-[22px]">
												<img className="w-8 h-8" src={InfoIcon} />
											</div>
											<h4 className="text-black1 text-18 font-bold leading-7">
												{pricingAndCalendarFormText('setting_availability_title')}
											</h4>
											<p className="text-16 text-[#666A73] font-normal leading-7">
												{pricingAndCalendarFormText('setting_availability_description')}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
			{editModal && (
				<CancellationModal
					showModal={editModal}
					data={data}
					rentalUnitId={rentalUnitId}
					onClose={(refresh) => {
						setEditModal(false)
					}}
				/>
			)}
			{isSureModalOpen && (
				<AreYouSureModal
					title="Delete Price per night option"
					content="Are you sure you want to delete this price option?"
					firstButton={'No, go back'}
					secondButton={'Yes, I am'}
					showModal={isSureModalOpen}
					onClose={() => setIsSureModalOpen(false)}
					onSubmit={() =>
						accommodationService
							.PricePerNightDelete(currentPricePerNight, rentalUnitId)
							.then(() => {
								identifyVenue(short_code)
								trackEvent('Reantal Unit', {
									action_category: 'delete',
									action_outcome: 'success',
									interaction_element: 'pricing calendar',
								})
								NotificationManager.success(
									'Price per night deleted successfully'
								)
								setTimeout(() => {
									navigate(
										`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=5`
									)
									navigate(0)
								}, 1000)
							})
							.catch((e) => {
								identifyVenue(short_code)
								trackEvent('Reantal Unit', {
									action_category: 'delete',
									action_outcome: 'fail',
									interaction_element: 'pricing calendar',
								})
								NotificationManager.error('Failed', '')
							})
					}
				/>
			)}
			{showPricePerNightModal && (
				<UpdatePricePerNightModal
					data={_curPricePerNight.current}
					showModal={showPricePerNightModal}
					onClose={() => setShowPricePerNightModal(false)}
					rentalUnitId={rentalUnitId}
				/>
			)}
		</>
	)
}

export default PricingAndCalendarForm;
