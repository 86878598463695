import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import { boxStyle } from "../../constants/types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import CustomIconButton from '../common/IconButton'

import infoIcon from "../../assets/svgs/info_white.svg";

export default function AreYouSureModal({ showModal, title, content, firstButton, secondButton, onClose, onSubmit }) {
	return (
		<div>
			<Modal open={showModal} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={{...boxStyle, width: 450}} className="outline-none">
					<CustomIconButton
						icon={infoIcon} title={`${title}`}
					/>
          <div className="px-6 mb-4">
            <span className="text-gray5 text-sm">
             {content}
            </span>
          </div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6 text-sm">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{firstButton}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => {
							onClose()
							onSubmit()
						  }}
						>
							{secondButton}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	);
}
