import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import { boxStyle } from '../../constants/types'
import GuestService from '../../services/guestService'
import WaitlistService from '../../services/waitlistService'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import saleIcon from '../../assets/svgs/sale_white.svg'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import SelectItems from '../SelectItems'
import ConfirmationFooter from '../footer/ConfirmationFooter'

const getTranslation = (text) => t(`components.waitlist.newWaitlist.${text}`)

export default function NewWaitlistModal({
	showModal,
	onConfirm,
	onClose,
	data,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [guest, setGuest] = useState(0)
	const [estimate, setEstimate] = useState(0)
	const [isVip, setVip] = useState(false)
	const [isRegular, setRegular] = useState(false)
	const { short_code } = useSelector((state) => state.app)
	const [guestOptions, setGuestOptions] = useState([])

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			setGuest(0)
			setEstimate(0)
			setVip(false)
			setRegular(false)
			GuestService.GetGuestsWithoutTable().then((res) => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'guest options',
				})
				setGuestOptions(
					res.data.map((item) => {
						return {
							value: item.id,
							label: item.name,
						}
					})
				)
			})
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (guest && estimate) {
			WaitlistService.NewWaitlist({
				guest_id: guest,
				estimated_wait_time: estimate,
				is_vip: isVip,
				is_regular: isRegular,
			})
				.then((res) => {
					NotificationManager.success('Success')
					identifyVenue(short_code)
					trackEvent('Create Waitlist', {
						action_category: 'creation',
						action_outcome: 'success',
						interaction_element: 'waitlist',
					})
					onConfirm()
					onClose()
				})
				.catch((e) => {
					NotificationManager.error('Failed')
					identifyVenue(short_code)
					trackEvent('Create Waitlist', {
						action_category: 'creation',
						action_outcome: 'failed',
						interaction_element: 'waitlist',
					})
				})
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="schedule-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-4 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={saleIcon} alt={getTranslation('iconAltText')} />
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{getTranslation('newWaitlistTitle')}
							</label>
						</div>
					</div>
					<div className="flex flex-col px-024 mb-4">
						<RInputDeclare
							value={getTranslation('guestLabel')}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							error={checkValid && !guest}
							select
							size="small"
							value={guest}
							onChange={(event) => setGuest(event.target.value)}
						>
							{SelectItems(guestOptions)}
						</TextField>

						<RInputDeclare
							value={getTranslation('estimateWaitTimeLabel')}
							className="!mt-0 !mb-2 !mt-6 !text-black1 font-medium text-base"
						/>
						<TextField
							required
							error={checkValid && !estimate}
							id="filled-required"
							label={getTranslation('estimateWaitTimePlaceholder')}
							size="small"
							type="number"
							value={estimate}
							onChange={(e) => setEstimate(e.target.value)}
						/>

						<RInputDeclare value={getTranslation('space')} />
						<p>
							<Checkbox
								value={isVip}
								onChange={(e) => {
									setVip(e.target.checked)
								}}
								sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
							/>
							{getTranslation('isVipLabel')}
						</p>
						<p>
							<Checkbox
								value={isRegular}
								onChange={(e) => setRegular(e.target.checked)}
								sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
							/>
							{getTranslation('isRegularLabel')}
						</p>
					</div>

					<ConfirmationFooter onClose={onClose} onSubmit={onSubmit} />
				</Box>
			</Modal>
		</div>
	)
}
