import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import plusIcon from '../../../assets/svgs/plus.svg'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import ShippingZoneModal from '../../../components/store/shppingZoneModal'
import retailService from '../../../services/retailService'

export const storeShippingText = (text) =>
	t(`account.storeSettings.storeShipping.${text}`)

const StoreShipping = ({ data, onRefresh }) => {
	const [isModalVisible, setModalVisible] = useState(false)
	const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleEdit = (row) => {
		setSelectedRow(row)
		setModalVisible(true)
	}

	const handleDelete = (row) => {
		setSelectedRow(row)
		setDeleteModalVisible(true)
	}

	const columns = [
		{
			field: 'zone_name',
			label: storeShippingText('zone_name'),
			align: 'left',
		},
		{ field: 'region', label: storeShippingText('region'), align: 'left' },
		{
			field: 'shipping_method_desc',
			label: storeShippingText('shipping_methods'),
			align: 'left',
		},
		{
			field: 'actions',
			label: storeShippingText('actions'),
			align: 'right',
		},
	]

	return (
		<div className="w-full">
			<Card>
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
						<div className="flex flex-col items-start max-w-[70%]">
							<span className="text-black1 text-xl font-medium">
								{storeShippingText('shipping_zones_title')}
							</span>
							<p className="text-black1 text-16 font-medium">
								{storeShippingText('shipping_zones_description')}
							</p>
						</div>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
							onClick={() => {
								setModalVisible(true)
								setSelectedRow(null)
							}}
						>
							<img src={plusIcon} alt="plus" className="mr-2" />
							<span>{storeShippingText('add_shipping_zone_button')}</span>
						</div>
					</div>
					<GenericTable
						data={data.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)}
						columns={columns}
						onEdit={handleEdit}
						onDelete={handleDelete}
					/>
					<GenericPagination
						count={data.length}
						page={page}
						rowsPerPage={rowsPerPage}
						onPageChange={(event, newPage) => setPage(newPage)}
						onRowsPerPageChange={(event) =>
							setRowsPerPage(parseInt(event.target.value, 10))
						}
						setPage={setPage}
					/>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
							{storeShippingText('no_shipping_zones_found')}
						</div>
					)}
				</CardContent>
				<ShippingZoneModal
					data={selectedRow}
					showModal={isModalVisible}
					onClose={(refresh) => {
						setModalVisible(false)
						setSelectedRow(null)
						if (refresh) {
							onRefresh()
						}
					}}
				/>
				<AreYouSureModal
					title={storeShippingText('delete_shipping_zone_title')}
					content={storeShippingText('delete_shipping_zone_content')}
					firstButton={storeShippingText('no_cancel')}
					secondButton={storeShippingText('yes_i_am')}
					showModal={isDeleteModalVisible}
					onClose={() => setDeleteModalVisible(false)}
					onSubmit={() => {
						retailService.deleteShippingZone(selectedRow.zone_id).then(() => {
							NotificationManager.success('Successfully deleted')
							setSelectedRow(null)
							onRefresh()
						})
					}}
				/>
			</Card>
		</div>
	)
}

export default StoreShipping
