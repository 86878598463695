import React, { useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  CloseOutlined,
  ContentCopyOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  ImageList,
  ImageListItem,
  Modal,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import * as yup from 'yup';
import { ImgDragUpload } from '../../../components/common/ImgDragUpload';
import CustomAutocompleteWithAdd from '../../../components/custom/CustomAutocompleteWithAdd/CustomAutocompleteWithAdd';
import CustomSelect from '../../../components/custom/CustomSelect';
import CustomTimePicker from '../../../components/custom/CustomTimePicker/CustomTimePicker';
import LoadingScreen from '../../../components/custom/LoadingScreen';
import { RInputDeclare } from '../../../components/custom/RInputDeclare';
import SportsVenueEmptyData from '../../../components/shared/sportsEmptyData';
import VenueService from '../../../services/venuService';
import { getDateTimeFromTimeSpan, isEmpty } from '../../../utils/common';
import { KEYS } from '../../../utils/storage';

import { t } from 'i18next';
import Cookies from 'universal-cookie';
import AssistantButton from '../../../components/assistantButton';
import { boxStyle } from '../../../constants/types';
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot';
import BrandCustomize from '../../account/venueWebProfile/brandCustomize';
import { productText } from '../../menu/products';

export const golfFieldsText = (text) => t(`events.fields.${text}`)

// import './index.css';

const golfStyleOptions = [
	{
		label: 'Beginer',
		value: 'Beginer',
	},
	{
		label: 'Intermediate',
		value: 'Intermediate',
	},
	{
		label: 'Expert',
		value: 'Expert',
	},
]
const paymentOptions = [
	{ id: 1, name: 'Visa' },
	{ id: 2, name: 'MasterCard' },
	{ id: 3, name: 'Cash' },
	{ id: 4, name: 'Scant to pay' },
]

const golfFacility = [
	{ id: 1, name: 'Clubhouse' },
	{ id: 2, name: 'Pro Shop' },
	{ id: 3, name: 'Driving Range' },
]

const GolfFields = () => {
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const validator = yup.object().shape({
		neighborhood: yup
			.string()
			.trim()
			.required('Neighborhood is required')
			.max(72, 'Max exceeded')
			.min(2, 'Min not met'),
		golf_style: yup.string().trim().required('Golf Style is required'),
		parking_details: yup
			.string()
			.trim()
			.required('Parking details is required')
			.max(72, 'Max exceeded'),
		field_m2: yup
			.string()
			.trim()
			.required('M2 is required')
			.max(72, 'Max exceeded'),
		description: yup
			.string()
			.trim()
			.required('Description is required')
			.max(500, 'Max exceeded')
			.min(5, 'Min not met'),
		additional: yup
			.string()
			.trim()
			.required('Additional is required')
			.max(250, 'Max exceeded'),
		main_tag: yup
			.string()
			.trim()
			.required('Main Tag is required')
			.max(72, 'Max exceeded'),
		payment_options: yup
			.array()
			.required()
			.min(1, 'Please select payment type'),
		tags: yup.array().required().min(1, 'Please select tag'),
		equipment_type:
			venueType == 'gym'
				? yup.array().required().min(1, 'Please select equipment type')
				: yup.array().notRequired(),
		amenities:
			venueType == 'gym'
				? yup.array().required().min(1, 'Please select amenity type')
				: yup.array().notRequired(),
		facility_types:
			venueType == 'bowling'
				? yup.array().required().min(1, 'Please select facility type')
				: yup.array().notRequired(),
		lanes: yup.string(),
		has_reservation: yup.boolean(),
		facilities:
			venueType == 'golf_venue'
				? yup.array().required().min(1, 'Please select facility type')
				: yup.array().notRequired(),
		nr_holes: yup.string(),
		offers_restaurant: yup.boolean(),
		offers_bar: yup.boolean(),
		offers_snackbar: yup.boolean(),
	})

	const api_type = 'golf'
	const formikProps = useFormik({
		initialValues: {
			neighborhood: '',
			golf_style: '',
			field_m2: '',
			parking_details: '',
			description: '',
			additional: '',
			main_tag: '',
			tags: [],
			payment_options: [],
			equipment_type: [],
			amenities: [],
			lanes: '',
			facility_types: [],
			has_reservation: false,
			facilities: [],
			nr_holes: [],
			offers_restaurant: false,
			offers_bar: false,
			offers_snackbar: false,
		},
		validationSchema: validator,
		onSubmit: (values) => onSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const [configured, setConfigured] = useState(false)
	const [loading, setLoading] = useState(true)
	const [tags, setTags] = useState([])
	const [images, setImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	])

	// checkbox
	const [monday, setMonday] = useState(false)
	const [tuesday, setTuesday] = useState(false)
	const [wendsday, setWendsday] = useState(false)
	const [thursday, setThursday] = useState(false)
	const [friday, setFriday] = useState(false)
	const [saturday, setSaturday] = useState(false)
	const [sunday, setSunday] = useState(false)

	// start times
	const [mondayStartTime, setmondayStartTime] = useState(new Date())
	const [tuesdayStartTime, settuesdayStartTime] = useState(new Date())
	const [wendsdayStartTime, setwendsdayStartTime] = useState(new Date())
	const [thursdayStartTime, setthursdayStartTime] = useState(new Date())
	const [fridayStartTime, setfridayStartTime] = useState(new Date())
	const [saturdayStartTime, setsaturdayStartTime] = useState(new Date())
	const [sundayStartTime, setsundayStartTime] = useState(new Date())

	// end time
	const [mondayEndTime, setmondayEndTime] = useState(new Date())
	const [tuesdayEndTime, settuesdayEndTime] = useState(new Date())
	const [wendsdayEndTime, setwendsdayEndTime] = useState(new Date())
	const [thursdayEndTime, setthursdayEndTime] = useState(new Date())
	const [fridayEndTime, setfridayEndTime] = useState(new Date())
	const [saturdayEndTime, setsaturdayEndTime] = useState(new Date())
	const [sundayEndTime, setsundayEndTime] = useState(new Date())

	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)
	const [whiteLabelUrl, setWhiteLabelUrl] = useState('')

	useEffect(() => {
		getDetails()
	}, [])

	const getDetails = () => {
		VenueService.CheckManageVenue(api_type)
			.then((res) => {
				setLoading(false)
				if (res.hasManaged) {
					setConfigured(true)
					formikProps.setValues(res.information.information)
					setTags(res.information.information.tags)
					setWhiteLabelUrl(res?.information?.url)
					res.information.availability.forEach((item) => {
						if (item.day_of_week == 'Monday') {
							setMonday(true)
							setmondayStartTime(getDateTimeFromTimeSpan(item.open_time))
							setmondayEndTime(getDateTimeFromTimeSpan(item.close_time))
						} else if (item.day_of_week == 'Tuesday') {
							setTuesday(true)
							settuesdayStartTime(getDateTimeFromTimeSpan(item.open_time))
							settuesdayEndTime(getDateTimeFromTimeSpan(item.close_time))
						} else if (item.day_of_week == 'Wednesday') {
							setWendsday(true)
							setwendsdayStartTime(getDateTimeFromTimeSpan(item.open_time))
							setwendsdayEndTime(getDateTimeFromTimeSpan(item.close_time))
						} else if (item.day_of_week == 'Thursday') {
							setThursday(true)
							setthursdayStartTime(getDateTimeFromTimeSpan(item.open_time))
							setthursdayEndTime(getDateTimeFromTimeSpan(item.close_time))
						} else if (item.day_of_week == 'Friday') {
							setFriday(true)
							setfridayStartTime(getDateTimeFromTimeSpan(item.open_time))
							setfridayEndTime(getDateTimeFromTimeSpan(item.close_time))
						} else if (item.day_of_week == 'Saturday') {
							setSaturday(true)
							setsaturdayStartTime(getDateTimeFromTimeSpan(item.open_time))
							setsaturdayEndTime(getDateTimeFromTimeSpan(item.close_time))
						} else if (item.day_of_week == 'Sunday') {
							setSunday(true)
							setsundayStartTime(getDateTimeFromTimeSpan(item.open_time))
							setsundayEndTime(getDateTimeFromTimeSpan(item.close_time))
						}
					})
					if (res.information.gallery.length > 0) {
						const tempImages = [...images]
						res.information.gallery.forEach((item, index) => {
							tempImages[index] = item
						})
						setImages(tempImages)
					}
				}
			})
			.catch((err) => {
				setLoading(false)
				throw new Error('Problem on CheckManageVenue for Golf')
			})
	}

	const onSubmit = (values) => {
		window.scrollTo(0, 0)

		const payload = {
			golf_data: { ...values },
			type: api_type,
		}

		setLoading(true)
		VenueService.UpdateVenueInformation(payload)
			.then((res) => {
				NotificationManager.success(
					'Golf information has been saved successfully'
				)
				setLoading(false)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				setLoading(false)
				throw new Error('Problem on UpdateVenueInformation')
			})
	}

	const onSubmitAvailibilty = () => {
		const payload = {
			type: api_type,
			availability: [],
		}
		if (monday)
			payload.availability.push({
				day_of_week: 'Monday',
				open_time: moment(mondayStartTime).format('HH:mm'),
				close_time: moment(mondayEndTime).format('HH:mm'),
			})
		if (tuesday)
			payload.availability.push({
				day_of_week: 'Tuesday',
				open_time: moment(tuesdayStartTime).format('HH:mm'),
				close_time: moment(tuesdayEndTime).format('HH:mm'),
			})
		if (wendsday)
			payload.availability.push({
				day_of_week: 'Wednesday',
				open_time: moment(wendsdayStartTime).format('HH:mm'),
				close_time: moment(wendsdayEndTime).format('HH:mm'),
			})
		if (thursday)
			payload.availability.push({
				day_of_week: 'Thursday',
				open_time: moment(thursdayStartTime).format('HH:mm'),
				close_time: moment(thursdayEndTime).format('HH:mm'),
			})
		if (friday)
			payload.availability.push({
				day_of_week: 'Friday',
				open_time: moment(fridayStartTime).format('HH:mm'),
				close_time: moment(fridayEndTime).format('HH:mm'),
			})
		if (saturday)
			payload.availability.push({
				day_of_week: 'Saturday',
				open_time: moment(saturdayStartTime).format('HH:mm'),
				close_time: moment(saturdayEndTime).format('HH:mm'),
			})
		if (sunday)
			payload.availability.push({
				day_of_week: 'Sunday',
				open_time: moment(sundayStartTime).format('HH:mm'),
				close_time: moment(sundayEndTime).format('HH:mm'),
			})

		setLoading(true)
		VenueService.UpdateVenueAvailability(payload)
			.then((res) => {
				NotificationManager.success(
					'Golf Availability has been saved successfully'
				)
				setLoading(false)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				setLoading(false)
				throw new Error('Problem on UpdateVenueAvailability')
			})
	}

	const onUploadImage = (image, index) => {
		let formData
		formData = new FormData()
		formData.append('type', 'gallery')
		formData.append('facility_type', api_type)
		if (image?.type === 'file' && image?.url != null) {
			formData.append('photo', image.file)
		}
		setLoading(true)
		VenueService.uploadFacilitiesPhoto(formData)
			.then((res) => {
				// hope response has the new image, or at least id
				getDetails()
				NotificationManager.success('Image has been uploaded successfully')
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
				throw new Error('Problem uploading image')
			})
	}

	const onCancelImage = (index) => {
		setLoading(true)
		VenueService.deleteFacilitiesPhoto(images[index].photo_id)
			.then((res) => {
				setLoading(false)
				if (images[index]) {
					const tempImages = [...images]
					tempImages[index] = null
					setImages(tempImages)
				}
			})
			.catch((err) => {
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Unable do delete Photo')
				throw new Error('Unable do delete Photo')
			})
	}

	const onCopyText = ({ url, label }) => {
		if (url) {
			navigator.clipboard.writeText(url)
			NotificationManager.success(
				`${label} is copied successfully and can be shared everywhere`
			)
		}
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'additional_golf_course_information'
		if (aiField === 'Description') {
			askFor = 'golf_course_description'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)

		if (aiField === 'Description') {
			formikProps.setFieldValue('description', generated)
		}

		if (aiField === 'Additional information') {
			formikProps.setFieldValue('additional', generated)
		}

		setAiQuery('')
		setGenerated('')
	}

	if (loading) {
		return <LoadingScreen />
	}
	return (
		<div
			className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card>
				<div className="flex flex-row justify-between justify-center items-center w-full p-2">
					<p
						style={{ padding: '20px 15px 20px 15px' }}
						className="text-black1 font-medium text-[20px]"
					>
						{golfFieldsText('golfWebProfile')}
					</p>
					{whiteLabelUrl ? (
						<div className="flex flex-row justify-start items-center px-2">
							<div className="!text-black1 font-medium text-base">
								<a
									className="text-black1 text-base"
									href={whiteLabelUrl}
									target="_blank"
									rel="noopener noreferrer"
								>
									{whiteLabelUrl}
								</a>
							</div>
							<div
								className="ml-4 cursor-pointer"
								onClick={() =>
									onCopyText({ url: whiteLabelUrl, label: 'Link' })
								}
							>
								<ContentCopyOutlined />
							</div>
						</div>
					) : null}
				</div>
				<CardContent style={{ background: '#f6f6f8' }}>
					<div className="relative started-form">
						<p>{golfFieldsText('completeYourGolfProfile')}</p>
						<p>{golfFieldsText('provideInformation')}</p>
					</div>
				</CardContent>
			</Card>

			{configured ? (
				<>
					<Card className="mt-6">
						<BrandCustomize />
					</Card>
					<Card className="mt-6">
						<p
							style={{ padding: '20px 15px 20px 15px' }}
							className="text-black1 font-medium text-[20px]"
						>
							{golfFieldsText('generalInformation')}
						</p>
						<CardContent style={{ background: '#f6f6f8' }}>
							<div className="flex flex-col">
								<div className="grid md:grid-cols-2 grid-cols-1 gap-8">
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('nrOfHoles')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.nr_holes}
											error={formikProps.errors.nr_holes}
											placeholder={golfFieldsText('holes')}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											style={{ background: '#fff' }}
											onChange={(e) => {
												formikProps.setFieldValue('nr_holes', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.nr_holes) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.nr_holes}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('golfStyle')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.golf_style}
											error={formikProps.errors.golf_style}
											placeholder={golfFieldsText('golfStyle')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue('golf_style', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.golf_style) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.golf_style}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('space')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.field_m2}
											error={formikProps.errors.field_m2}
											placeholder={golfFieldsText('space')}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											style={{ background: '#fff' }}
											onChange={(e) => {
												formikProps.setFieldValue('field_m2', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.field_m2) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.field_m2}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('paymentOptions')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={paymentOptions}
											value={
												formikProps.values.payment_options === null ||
												formikProps.values.payment_options.length === 0
													? []
													: paymentOptions.filter(
															(item) =>
																formikProps.values.payment_options.findIndex(
																	(a) => a === item.name
																) !== -1
													  )
											}
											isMultiple={true}
											className={' !z-[5] !border-gray6 !h-10'}
											style={{ background: '#fff' }}
											placeholder={golfFieldsText('paymentOptions')}
											renderRow={(item) => <p>{item.name}</p>}
											renderValue={(item) => <span>{item?.name}</span>}
											handleChange={(v) => {
												let tmp = formikProps.values.payment_options.slice(0)
												tmp.push(v.name)
												formikProps.setFieldValue('payment_options', tmp)
											}}
											onRemoveItem={(v) => {
												let tmp = formikProps.values.payment_options.slice(0)
												let index = tmp.findIndex((t) => t === v.name)
												if (index !== -1) {
													tmp.splice(index, 1)
												}
												formikProps.setFieldValue('payment_options', tmp)
											}}
										/>
										{!isEmpty(formikProps.errors.payment_options) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.payment_options}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('neighborhood')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.neighborhood}
											error={formikProps.errors.neighborhood}
											placeholder={golfFieldsText('neighborhood')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue(
													'neighborhood',
													e.target.value
												)
											}}
										/>
										{!isEmpty(formikProps.errors.neighborhood) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.neighborhood}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('parkingDetails')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.parking_details}
											error={formikProps.errors.parking_details}
											placeholder={golfFieldsText('parkingDetails')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue(
													'parking_details',
													e.target.value
												)
											}}
										/>
										{!isEmpty(formikProps.errors.parking_details) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.parking_details}
											</div>
										)}
									</div>

									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('mainTag')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											value={formikProps.values.main_tag}
											error={formikProps.errors.main_tag}
											placeholder={golfFieldsText('mainTag')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue('main_tag', e.target.value)
											}}
										/>
										{!isEmpty(formikProps.errors.main_tag) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.main_tag}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('tags')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>

										<CustomAutocompleteWithAdd
											values={tags}
											selectedValues={formikProps.values.tags}
											onRemoveItem={(v) => {
												let tmp = formikProps.values.tags.slice(0)
												let index = tmp.findIndex((t) => t === v)
												if (index !== -1) {
													tmp.splice(index, 1)
												}
												formikProps.setFieldValue('tags', tmp)
											}}
											handleChange={(v) => {
												let tmp = formikProps.values.tags.slice(0)
												tmp.push(v)
												formikProps.setFieldValue('tags', tmp)
											}}
										/>
										{!isEmpty(formikProps.errors.tags) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.tags}
											</div>
										)}
									</div>

									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('facilities')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<CustomSelect
											values={golfFacility}
											value={
												formikProps.values.facilities === undefined ||
												formikProps.values.facilities === null ||
												formikProps.values.facilities.length === 0
													? []
													: golfFacility.filter(
															(item) =>
																formikProps.values.facilities.findIndex(
																	(a) => a === item.name
																) !== -1
													  )
											}
											isMultiple={true}
											className={' !z-[5] !border-gray6 !h-10'}
											style={{ background: '#fff' }}
											placeholder={golfFieldsText('facilityTypes')}
											renderRow={(item) => <p>{item.name}</p>}
											renderValue={(item) => <span>{item?.name}</span>}
											handleChange={(v) => {
												let tmp =
													formikProps.values.facilities === undefined
														? []
														: formikProps.values.facilities.slice(0)
												tmp.push(v.name)
												formikProps.setFieldValue('facilities', tmp)
											}}
											onRemoveItem={(v) => {
												let tmp =
													formikProps.values.facilities === undefined
														? []
														: formikProps.values.facilities.slice(0)
												let index = tmp.findIndex((t) => t === v.id)
												if (index !== -1) {
													tmp.splice(index, 1)
												}
												formikProps.setFieldValue('facilities', tmp)
											}}
										/>
									</div>

									<div className="grid grid-cols-3 items-end">
										<div className="flex align-middle">
											<RInputDeclare
												value={golfFieldsText('offersRestaurant')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.offers_restaurant}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'offers_restaurant',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
										<div className="flex align-middle">
											<RInputDeclare
												value={golfFieldsText('offersBar')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.offers_bar}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'offers_bar',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
										<div className="flex align-middle">
											<RInputDeclare
												value={golfFieldsText('offersSnackbar')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={formikProps.values.offers_snackbar}
												style={{ width: 'fit-content' }}
												onChange={(e) =>
													formikProps.setFieldValue(
														'offers_snackbar',
														e.target.checked
													)
												}
												className="mt-2"
											/>
										</div>
									</div>

									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('description')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											size="small"
											maxRows={5}
											minRows={5}
											multiline
											value={formikProps.values.description}
											error={formikProps.errors.description}
											placeholder={golfFieldsText('description')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue('description', e.target.value)
											}}
										/>
										<div className="flex flex-row justify-end mt-2">
											<AssistantButton
												onAction={() => handleOpenAIModal('Description')}
											/>
										</div>
										{!isEmpty(formikProps.errors.description) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.description}
											</div>
										)}
									</div>
									<div className="flex flex-col">
										<RInputDeclare
											value={golfFieldsText('additionalInformation')}
											className="!mt-0 !mb-2 !text-black1 font-medium text-base"
										/>
										<TextField
											required
											maxRows={5}
											minRows={5}
											multiline
											size="small"
											value={formikProps.values.additional}
											error={formikProps.errors.additional}
											placeholder={golfFieldsText('additionalInformation')}
											style={{ background: '#fff' }}
											sx={{
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid #d9d9d9!important',
												},
											}}
											onChange={(e) => {
												formikProps.setFieldValue('additional', e.target.value)
											}}
										/>
										<div className="flex flex-row justify-end mt-2">
											<AssistantButton
												onAction={() =>
													handleOpenAIModal('Additional information')
												}
											/>
										</div>
										{!isEmpty(formikProps.errors.additional) && (
											<div className="text-red-600 text-12 mt-2">
												{formikProps.errors.additional}
											</div>
										)}
									</div>
								</div>
								<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
									<button className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1">
										{golfFieldsText('clear')}
									</button>
									<button
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={formikProps.handleSubmit}
									>
										{golfFieldsText('update')}
									</button>
								</div>
							</div>
						</CardContent>
					</Card>

					<Card className="mt-6">
						<p
							style={{ padding: '20px 15px' }}
							className="text-black1 font-medium text-[20px]"
						>
							{golfFieldsText('golfAvailability')}
						</p>
						<CardContent style={{ background: '#f6f6f8' }}>
							<div className="flex flex-col">
								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('monday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={monday}
												onChange={(e) => setMonday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={mondayStartTime}
												onChange={setmondayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={mondayEndTime}
												onChange={setmondayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('friday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={friday}
												onChange={(e) => setFriday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={fridayStartTime}
												onChange={setfridayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={fridayEndTime}
												onChange={setfridayEndTime}
											/>
										</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('tuesday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={tuesday}
												onChange={(e) => setTuesday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={tuesdayStartTime}
												onChange={settuesdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={tuesdayEndTime}
												onChange={settuesdayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('saturday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={saturday}
												onChange={(e) => setSaturday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={saturdayStartTime}
												onChange={setsaturdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={saturdayEndTime}
												onChange={setsaturdayEndTime}
											/>
										</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('wednesday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={wendsday}
												onChange={(e) => setWendsday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={wendsdayStartTime}
												onChange={setwendsdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={wendsdayEndTime}
												onChange={setwendsdayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('sunday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={sunday}
												onChange={(e) => setSunday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={sundayStartTime}
												onChange={setsundayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={sundayEndTime}
												onChange={setsundayEndTime}
											/>
										</div>
									</div>
								</div>

								<div className="grid md:grid-cols-2 grid-cols-1 gap-8 py-2">
									<div className="flex flex-row justify-start center-align p-2 gap-8">
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('thursday')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<Checkbox
												checked={thursday}
												onChange={(e) => setThursday(e.target.checked)}
												className="mt-2"
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('openTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={thursdayStartTime}
												onChange={setthursdayStartTime}
											/>
										</div>
										<div className="flex flex-col">
											<RInputDeclare
												value={golfFieldsText('endTime')}
												className="!mt-0 !mb-2 !text-black1 font-medium text-base"
											/>
											<CustomTimePicker
												classes="bg-white"
												value={thursdayEndTime}
												onChange={setthursdayEndTime}
											/>
										</div>
									</div>
									<div className="flex flex-col justify-start center-align p-2">
										<div className="flex flex-row justify-center align-center h-[48px] mt-4">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
												onClick={onSubmitAvailibilty}
											>
												{golfFieldsText('update')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>

					<Card className="mt-6">
						<p
							style={{ padding: '20px 15px' }}
							className="text-black1 font-medium text-[20px]"
						>
							{golfFieldsText('golfGallery')}
						</p>
						<CardContent style={{ background: '#f6f6f8' }}>
							<div className="relative started-form">
								<div className="flex flex-col h-full mt-0 py-4">
									<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
										<ImageList variant="quilted" cols={6}>
											{images.map((item, index) => (
												<ImageListItem
													key={'img_uploader' + index}
													className="pl-2 pb-2"
												>
													<ImgDragUpload
														backgroundColor={'#fff'}
														image={item ? { url: item?.photo_path } : null}
														onCancel={() => onCancelImage(index)}
														onChange={(img) => onUploadImage(img, index)}
														label={golfFieldsText('uploadLabel')}
														classes="max-h-[190px] p-2 mb-2 mt-2"
													/>
												</ImageListItem>
											))}
										</ImageList>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>
				</>
			) : (
				<div className="border border-b-0 mb-10">
					<SportsVenueEmptyData onAction={() => setConfigured(true)} />
				</div>
			)}
			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{golfFieldsText('aiAssistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									// placeholder={golfFieldsText(placeholder[aiField])}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>{golfFieldsText('aiAssistantNote')}</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-transparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												golfFieldsText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setGenerated(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{productText("add_to")} {aiField }
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}

export default GolfFields
