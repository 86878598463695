import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	Checkbox,
	CircularProgress,
	IconButton,
	MenuItem,
	Modal,
	TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { productText } from '.'
import uploadIcon from '../../../assets/svgs/upload.svg'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'
import MenuService from '../../../services/menuService'
import { isEmpty } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'


const dummyData = [
	{
		value: 1,
		label: 'Template 1',
	},
	{
		value: 2,
		label: 'Template 2',
	},
]

function BulkImportModal({ showImportModal, setShowImportModal }) {
	const { short_code } = useSelector((state) => state.app)

	const bulkImportUploadModal = useFormik({
		initialValues: {
			file: null,
			parent_category_id: null,
			template_id: null,
			category_id: null,
			no_category: true,
		},
		validationSchema: yup.object().shape({
			file: yup.string().trim().required(productText('file_field_required')),
		}),
		onSubmit: (values) => availabilityModalSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})
	const navigate = useNavigate()
	const [categories, setCategories] = useState([])
	const [templates, setTemplates] = useState(dummyData)
	const [subCategories, setSubCategories] = useState([])
	const [uploadingProducts, setUploadingProducts] = useState(false)

	const availabilityModalSubmit = (values) => {
		setUploadingProducts(true)
		const details = {
			file: values.file,
			parent_category_id: values.no_category ? null : values.parent_category_id,
			category_id: values.no_category ? null : values.category_id,
			no_category: values.no_category,
		}

		MenuService.bulkImportUploadProducts(details)
			.then(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venues',
				})
				setShowImportModal(false)
				NotificationManager.success(
					productText('products_imported_successfully'),
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(0)
				}, 3000)

				setUploadingProducts(false)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venues',
				})
				setUploadingProducts(false)

				if (typeof err?.error === 'string') {
					toast.error(err?.error, {
						style: {
							backgroundColor: '#ff0000ee', // green color for background
							color: '#FFFFFF', // white color for text
						},
						duration: 5000,
					})

					throw new Error(`${productText('file_upload_error')} ${err?.error}`)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						toast.error(firstErrorMessage || t('common.error_generic'), {
							style: {
								backgroundColor: '#ff0000ee', // green color for background
								color: '#FFFFFF', // white color for text
							},
							duration: 5000,
						})

						throw new Error(
							`${productText('file_upload_error')} ${
								firstErrorMessage || t('common.error_generic')
							}`
						)
					} else {
						toast.error(t('common.error_generic'), {
							style: {
								backgroundColor: '#ff0000ee', // green color for background
								color: '#FFFFFF', // white color for text
							},
							duration: 5000,
						})
						throw new Error(
							`${productText('file_upload_error')}:` + t('common.error_generic')
						)
					}
				}
			})
	}
	const [showUpload, setShowUpload] = useState(false)
	const fileInputRef = useRef(null)
	const handleFileChange = (event) => {
		bulkImportUploadModal.setFieldValue('file', event.target.files[0])
		setShowUpload(true)
	}
	const handleIconClick = () => {
		fileInputRef.current.click()
	}
	const handleCloseModel = () => {
		setShowImportModal(false)
	}

	useEffect(() => {
		loadCategories()
		identifyVenue(short_code)
		trackEvent('Bulk Import', {
			action_category: 'click_modal',
			action_outcome: 'opened',
			interaction_element: 'modal',
		})
	}, [])

	const loadCategories = () => {
		MenuService.getCategories()
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
				const parentCategories = (res?.categories || []).filter(
					(category) => !category?.parent?.id
				)
				const subFinalCategories = (res?.categories || []).filter(
					(category) => category.parent && category.parent.id !== null
				)

				setCategories(parentCategories)
				setSubCategories(subFinalCategories) // Assuming you have another state variable for subcategories
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'categories',
				})
			})
	}

	return (
		<>
			<div>
				<Modal
					open={showImportModal}
					onClose={handleCloseModel}
					className="category-modal"
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={boxStyle} className="outline-none h-auto min-h-[8rem]">
						<div className="flex flex-row items-center justify-between gap-3 px-024 pb-016 pt-024">
							<div className="flex flex-row items-center gap-3">
								<div className="p-2 rounded-full bg-gray7">
									<div className="p-2 rounded-full bg-black1">
										<img src={uploadIcon} alt="icon" />
									</div>
								</div>
								<label className="font-semibold text-20 text-black1">
									{productText('bulk_import_products')}
								</label>
							</div>
							<CloseIcon
								onClick={handleCloseModel}
								style={{ cursor: 'pointer' }}
							/>
						</div>
						<div className="gap-4 px-6">
							{/* Existing error message display */}
							<div className="mt-0 text-base text-black1">
								{productText('bulk_import_instructions')}
							</div>
							<div className="mt-2 mb-6 text-blue-600 text-base">
								<a
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										color: 'rgb(61, 30, 89)',
									}}
									href="/bulk-import-products.csv"
									download="bulk-import-products.csv"
								>
									{productText('download_example_csv')}
								</a>
							</div>
						</div>
						<div className="grid grid-cols-2 items-center mb-4 gap-4 px-6">
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={productText('select_category')}
									className="!mt-4 !mb-2 !text-black1 text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									select
									disabled={bulkImportUploadModal.values.no_category}
									value={bulkImportUploadModal.values.parent_category_id}
									onChange={(e) => {
										bulkImportUploadModal.setFieldValue(
											'parent_category_id',
											e.target.value
										)
										bulkImportUploadModal.setFieldValue('category_id', null)
									}}
								>
									{categories.map((option) => (
										<MenuItem key={option.id} value={option.id}>
											{option.title}
										</MenuItem>
									))}
								</TextField>
							</div>
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={productText('select_sub_category')}
									className="!mt-4 !mb-2 !text-black1 text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									select
									disabled={bulkImportUploadModal.values.no_category}
									value={bulkImportUploadModal.values.category_id}
									onChange={(e) =>
										bulkImportUploadModal.setFieldValue(
											'category_id',
											e.target.value
										)
									}
								>
									{subCategories
										.filter(
											(option) =>
												option.parent.id ===
												bulkImportUploadModal.values.parent_category_id
										)
										.map((option) => (
											<MenuItem key={option.id} value={option.id}>
												{option.title}
											</MenuItem>
										))}
								</TextField>
							</div>
							<div className="flex flex-col col-span-2 w-full">
								<RInputDeclare
									value={productText('select_template')}
									className="!mt-4 !mb-2 !text-black1 text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									select
									value={
										bulkImportUploadModal?.values?.bulkImportUploadModal?.values
											?.template_id
									}
									onChange={(e) => {
										bulkImportUploadModal.setFieldValue(
											'bulkImportUploadModal.template_id',
											e.target.value
										)
									}}
								>
									{templates.map((option) => (
										<MenuItem key={option.id} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</div>
						</div>

						<div className="flex flex-row justify-start mb-4 items-center  px-4">
							<Checkbox
								checked={bulkImportUploadModal.values.no_category}
								onChange={(e) =>
									bulkImportUploadModal.setFieldValue(
										'no_category',
										e.target.checked
									)
								}
							/>
							<span>{productText('uncategorized_for_now')}</span>
						</div>
						<div className="flex flex-row justify-between gap-4 px-6">
							<input
								type="file"
								ref={fileInputRef}
								style={{ display: 'none' }}
								onChange={handleFileChange}
								accept="text"
							/>
							<div className="h-[6.5rem] w-full flex flex-row justify-between gap-4">
								<div
									className={`h-full w-full flex flex-col cursor-pointer justify-center !pt-5 !pb-3 items-center relative border border-gray-300 rounded-lg`}
								>
									<IconButton
										className="!bg-black1 p-2"
										onClick={handleIconClick}
									>
										<img
											src={uploadIcon}
											alt="icon"
											style={{ width: '24px', height: '24px' }}
										/>
									</IconButton>
									<span className="py-2 text-base text-center text-black1">
										{bulkImportUploadModal.values.file
											? bulkImportUploadModal.values.file.name
											: productText('upload_csv_file')}
									</span>
								</div>
							</div>
						</div>

						<div className="gap-4 px-6">
							<>
								{!bulkImportUploadModal.values.no_category &&
									!isEmpty(bulkImportUploadModal.errors.file) && (
										<div className="mt-2 text-red-600 text-12">
											{bulkImportUploadModal.errors.file}
										</div>
									)}
							</>
						</div>
						<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
							<button
								onClick={bulkImportUploadModal.handleSubmit}
								disabled={uploadingProducts}
								className="flex items-center justify-center h-full font-bold text-white rounded-lg bg-cyan1 grow"
							>
								{uploadingProducts ? (
									<CircularProgress size={16} />
								) : (
									productText('Upload')
								)}
							</button>
						</div>
					</Box>
				</Modal>
				<Toaster position="top-right" />
			</div>
		</>
	)
}

export default BulkImportModal
