import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TshirtIcon from '../assets/svgs/Bg.svg'
import TrashIcon from '../assets/svgs/trash-outline.svg'
import UserIcon from '../assets/svgs/users.svg'
import { deletFromRetailCart, getRetailCart } from '../utils/storage'

const SidebarPOSRetail = (props) => {
	let navigate = useNavigate()

	const { updateCart, setUpdateCart } = props
	const { short_code } = useSelector((state) => state.app)

	const [flag, setFlag] = useState(false)

	const [cart, setCart] = useState([])

	let totalCost = 0

	cart?.forEach((item) => {
		totalCost += item.quantity * item.price
	})

	// Iterate through the array and calculate the total cost

	useEffect(() => {
		setCart(getRetailCart())
		setUpdateCart(false)

		// eslint-disable-next-line
	}, [updateCart])


	const deleteFromCart = (id) => {
		deletFromRetailCart(id)

		setUpdateCart(true)
	}

	const redirectToPay = () => {
		navigate(`/${short_code}/admin/pos/retail/pay`)
	}

	const currencySymbols = {
		ALL: 'Lek',
		USD: '$',
		EUR: '€',
		// Add other currencies and their symbols here
	}

	const getCurrencySymbol = (currencyCode) => {
		return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
	}
	return (
		<div className="p-6 h-[100vh] bg-[#240b3b] relative min-w-[409px]">
			<div className="flex md:hidden cursor-pointer pb-4 border-b  border-[#393C49] items-center gap-2 ">
				<span className="text-white">
					<ChevronLeftOutlinedIcon />
				</span>
				<span
					className={`text-[18px] md:text-[20px] w-full text-center md:text-start text-white font-semibold `}
				>
					New Order #34562
				</span>
			</div>
			<p className="hidden md:block text-white text-20 font-semibold leading-[140%]">
				New Order #34562
			</p>
			{/*<div className="flex gap-2 py-4  border-[#393C49] border-t-[1px]">*/}
			{/*	<button className="bg-white rounded-[5px] items-center justify-between flex flex-1 py-[7px] px-[12px] text-[#240b3b] text-14 font-semibold leading-[140%] ">*/}
			{/*		<div className="align-middle">*/}
			{/*			<img src={UserIcon} className="inline mr-2" />*/}
			{/*			<span className="">Add a customer</span>*/}
			{/*		</div>*/}
			{/*		<span>→</span>*/}
			{/*	</button>*/}
			{/*</div>*/}
			<div>
				<div className={`border-[#393C49]  py-4 overflow-auto h-[75vh]`}>
					<div className="flex flex-col justify-between">
						{cart?.map((item, index) => {
							return (
								<div className="border-y-[1px] border-[#393C49] py-2">
									<div className="flex justify-between flex-row items-center">
										<div className="flex justify-start py-2  ">
											<div className="w-[71px] h-[71px] text-16 bg-[#F7F8F9] font-semibold align-middle rounded-[4px] mr-2">
												<img src={TshirtIcon} />
											</div>
											<div className="flex flex-col justify-start h-full">
												<p className="text-white text-16 font-medium">
													{item?.title}
												</p>
												<p className="text-[#ABBBC2] text-14 font-medium">
													{item?.quantity} X {getCurrencySymbol(item?.currency)}
													{item?.price}
												</p>
											</div>
										</div>
										<p className="text-16 font-medium leading-[140%] text-white ">
											{getCurrencySymbol(item?.currency)}
											{item?.quantity * item?.price}
										</p>
									</div>
									<div className="flex justify-between items-center gap-4 py-2">
										<input
											placeholder="Add note"
											className="flex flex-1 text-white border-[1px] bg-transparent border-[#E4E4E4] rounded-[8px] p-[14px]"
										/>
										<button
											className=" bg-transparent border-[1px] border-[#E4E4E4] rounded-[8px] p-[14px]"
											onClick={() => {
												deleteFromCart(item?.id)
											}}
										>
											<img src={TrashIcon} />
										</button>
									</div>
								</div>
							)
						})}

						<div className="py-2">
							<div className="flex justify-between py-2">
								<p className="text-[#E5EAFF] text-14 font-normal ">Discount</p>
								<p className="text-white text-14 font-medium leading-[130%]">
									{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}0
								</p>
							</div>
							<div className="flex justify-between py-2">
								<p className="text-[#E5EAFF] text-14 font-normal ">Sub total</p>
								<p className="text-white text-14 font-medium leading-[130%]">
									{getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}{totalCost}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full absolute bottom-6 px-6 right-0 grid grid-cols-1 gap-3">
				{/*<button className=" bg-white rounded-[8px] p-[14px]  text-center    text-[#1F1D2B] text-14 font-semibold leading-[140%] ">*/}
				{/*	Save Cart*/}
				{/*</button>*/}
				<button
					className=" bg-[#027A48] rounded-[8px] p-[14px]  text-center    text-white text-14 font-semibold leading-[140%] "
					onClick={() => {
						redirectToPay()
					}}
				>
					Charge {getCurrencySymbol(cart?.length ? cart[0]?.currency : 'USD')}{totalCost}
				</button>
			</div>
		</div>
	)
}

export default SidebarPOSRetail
