import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { t } from 'i18next'
import creditIcon from '../../assets/svgs/credit-card.svg'
import retailService from '../../services/retailService'
import CustomFormInput from '../custom/CustomInput/custom-form-input'
import GenericModal from '../generic-modal'

const getTranslation = (text) => t(`components.store.supplierModal.${text}`)

export default function SupplierModal({ showModal, data, onClose }) {
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState({})

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
			} else {
				setState({})
			}
		}
	}, [showModal])

	const onSubmit = () => {
		if (!state?.name) {
			return
		}
		let payload = {
			id: data?.id,
			name: state.name,
		}
		if (data?.id) {
			setLoading(true)
			retailService
				.udpateSupplier(payload)
				.then(({ data }) => {
					setLoading(false)
					onClose(true)
					NotificationManager.success(
						data?.message ?? 'Supplier is saved successfully'
					)
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(err?.message ?? 'Something went wrong!')
				})
		} else {
			setLoading(true)
			retailService
				.createSupplier(payload)
				.then(({ data }) => {
					setLoading(false)
					onClose(true)
					NotificationManager.success(
						data?.message ?? 'Supplier is saved successfully'
					)
				})
				.catch((err) => {
					setLoading(false)
					NotificationManager.error(err?.message ?? 'Something went wrong!')
				})
		}
	}

	return (
		<GenericModal
			{...{ onClose, onSubmit, loading }}
			open={showModal}
			saveText={
				data?.id != null ? getTranslation('update') : getTranslation('create')
			}
			title={
				data?.id != null
					? getTranslation('editSupplier')
					: getTranslation('addSupplier')
			}
			icon={creditIcon}
			children={
				<CustomFormInput
					title={getTranslation('supplierName')}
					required={true}
					value={state?.name}
					placeholder={getTranslation('enterSupplierName')}
					onChange={(e) => setState({ ...state, name: e.target.value })}
				/>
			}
		/>
	)
}
