import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { boxStyle } from '../../constants/types'
import InventoryService from '../../services/inventoryService'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import locationIcon from '../../assets/svgs/location_ano.svg'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { inventoryText } from '../../views/inventory'

const ProductQuantity = ({ data, onChange }) => {
	return (
		<div className="flex flex-row items-center w-full mt-4 ">
			<div className="flex flex-col justify-start items-center flex-1">
				<label className="text-black text-18 w-full">{data?.name}</label>
			</div>
			<div className="flex flex-col justify-start items-center w-[180px] ml-2">
				<TextField
					required
					label=""
					size="small"
					type="number"
					value={data?.quantity}
					className="w-full"
					onChange={(e) => onChange({ ...data, quantity: e.target.value })}
					placeholder="Enter quantity"
				/>
			</div>
		</div>
	)
}

export default function InventoryUpdateModal({
	showModal,
	inventory_id,
	data,
	onClose,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState(data?.label)
	const [products, setProducts] = useState(data?.products || [])

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (
			products.findIndex(
				(item) => item.quantity == null || item.quantity <= 0
			) !== -1
		) {
			return NotificationManager.info(inventoryText('enter_quantity_info'))
		}

		let payload = {
			label: name,
			products: products.map((p) => ({
				product_id: p.id,
				quantity: p.quantity,
			})),
		}
		setLoading(true)
		InventoryService.updateInventory(inventory_id, payload)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'update inventory',
				})
				setLoading(false)
				onClose(true)
				NotificationManager.success(
					data?.message ?? inventoryText('inventory_saved_successfully')
				)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'update',
					action_outcome: 'success',
					interaction_element: 'update inventory',
				})
				setLoading(false)
				NotificationManager.error(err?.message ?? t('common.error_generic'))
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={locationIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{inventoryText('update_inventory')}
						</label>
					</div>
					<div className="flex flex-col px-024 mb-4">
						<RInputDeclare
							value={inventoryText('inventory_label')}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							required
							size="small"
							value={name}
							placeholder={inventoryText('inventory_text')}
							onChange={(e) => setName(e.target.value)}
						/>

						<RInputDeclare
							value={inventoryText('products')}
							className="!mt-6 !mb-2 !text-black1 font-medium text-base"
						/>
						{products.map((product, index) => (
							<ProductQuantity
								key={product.id}
								data={product}
								onChange={(data) => {
									let tmp = products.slice(0)
									tmp[index] = { ...data }
									setProducts(tmp)
								}}
							/>
						))}
					</div>
					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{inventoryText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								inventoryText('update')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
