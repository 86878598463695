import { Card, CardContent, Checkbox, CircularProgress, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router";
import { productText } from '../../../views/menu/products'
import { RInputDeclare } from "../../custom/RInputDeclare";

const ProductInventory = ({ data, product_id, suppliers, onSubmitForm }) => {
	const [state, setState] = useState(null)
	const [supplier, setSupplier] = useState(null)
	const [manageStock, setManageStock] = useState(null)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (data) {
			setState(data)
			setSupplier(data?.supplier_id)
			setManageStock(data?.manage_stock)
		}
	}, data)

	const onSubmit = () => {
		onSubmitForm(state)
	}
	return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-2 gap-10">
					<div className="items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-xl font-medium">
							{productText('Inventory')}
						</span>
						<p className="text-black1 text-16 font-medium mt-1">
							{productText('manage_stock_sku_details')}
						</p>
					</div>
					<div className="relative started-form">
						<div className="flex flex-col py-4 px-5 overflow-auto">
							<div className="md:col-span-6 flex flex-col">
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('sku')}
										className="!mt-0 !mb-2 !text-black1 text-base"
									/>
									<TextField
										required
										label=""
										size="small"
										placeholder={productText('enter_sku')}
										value={state?.sku}
										onChange={(e) =>
											setState({ ...state, sku: e.target.value })
										}
									/>
								</div>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('article_no_optional')}
										className="!mt-4 !mb-2 !text-black1 text-base"
									/>
									<TextField
										required
										label=""
										size="small"
										placeholder={productText('enter_article_no')}
										value={state?.article_no}
										onChange={(e) =>
											setState({ ...state, article_no: e.target.value })
										}
									/>
								</div>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('stock_management')}
										className="!mt-4 !mb-2 !text-black1 text-base"
									/>
									<div className="flex flex-row justify-start align-center gap-4">
										<Checkbox
											checked={state?.manage_stock === 1 ?? manageStock === 1}
											style={{ width: 'fit-content' }}
											onChange={(e) =>
												setState({
													...state,
													manage_stock: e.target.checked ? 1 : 0,
												})
											}
										/>
										<span className="mt-2 text-14 text-black1 text-base">
											{productText('track_stock_quantity')}
										</span>
									</div>
								</div>

								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('quantity')}
										className="mt-4 text-base !text-black1 mt-7 !mb-2"
									/>
									<TextField
										required
										label=""
										size="small"
										placeholder={productText('enter_quantity')}
										value={state?.stock_quantity}
										type="number"
										onChange={(e) =>
											setState({ ...state, stock_quantity: e.target.value })
										}
									/>
								</div>
								{/* Additional sections */}
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('select_supplier')}
										className="!mt-4 !mb-2 !text-black1 text-base"
									/>
									<TextField
										id="outlined-select"
										size="small"
										select
										value={state?.supplier_id ?? supplier}
										onChange={(e) =>
											setState({ ...state, supplier_id: e.target.value })
										}
									>
										{suppliers.map((option) => (
											<MenuItem key={option.id} value={option.id}>
												{option.name}
											</MenuItem>
										))}
									</TextField>
								</div>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('low_stock_threshold')}
										className="mt-4 text-base !text-black1 mt-7 !mb-2"
									/>
									<TextField
										required
										label=""
										size="small"
										placeholder={productText('enter_low_stock_threshold')}
										value={state?.low_stock_threshold}
										type="number"
										onChange={(e) =>
											setState({
												...state,
												low_stock_threshold: e.target.value,
											})
										}
									/>
								</div>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('sold_individually')}
										className="!mt-4 !mb-2 !text-black1 text-base"
									/>
									<div className="flex flex-row justify-start align-center gap-4">
										<Checkbox
											checked={state?.sold_individually === 1}
											style={{ width: 'fit-content' }}
											onChange={(e) =>
												setState({
													...state,
													sold_individually: e.target.checked ? 1 : 0,
												})
											}
										/>
										<span className="mt-2 text-14 text-black1 text-base">
											{productText('limit_purchase')}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-row justify-end w-full mt-8 h-11 gap-4">
							<button
								className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
								onClick={() => navigate(-1)}
							>
								{productText('cancel')}
							</button>
							<button
								className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
								onClick={onSubmit}
							>
								{loading ? (
									<CircularProgress size={16} />
								) : (
									`${
										product_id != null
											? productText('update')
											: productText('create')
									}`
								)}
							</button>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

export default ProductInventory;