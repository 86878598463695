import { Card, CardContent, TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { NotificationManager } from 'react-notifications'
import checkIcon from '../../../assets/svgs/check_gray.svg'
import closeIcon from '../../../assets/svgs/close_gray.svg'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import TableEmptyData from '../../../components/table/TableEmptyData'
import StaffService from '../../../services/staffService'
import { createUUID } from '../../../utils/common'

export const scheduleListText = (text) => t(`employees.scheduleList.${text}`)

const ScheduleListScreen = () => {
	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [loaded, setLoaded] = useState(false)

	const [isAreYouSureConfirmModalOpen, setAreYouSureConfirmModalOpen] =
		useState(false)
	const [isAreYouSureCancelModalOpen, setAreYouSureCancelModalOpen] =
		useState(false)
	const [selectedTimeSchedule, setTimeScheduleSelected] = useState({})

	useEffect(() => {
		readAllData()
	}, [])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const readAllData = () => {
		StaffService.GetScheduleRequests()
			.then((res) => {
				setLoaded(true)
				setData(res.schedule_time_off)
			})
			.catch((err) => {
				setLoaded(true)
			})
	}

	const onApproveTORequest = (item) => {
		setTimeScheduleSelected(item)
		setAreYouSureConfirmModalOpen(true)
	}

	const onRejectTORequest = (item) => {
		setTimeScheduleSelected(item)
		setAreYouSureCancelModalOpen(true)
	}
	return (
		<>
			{loaded ? (
				<div className="h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{scheduleListText('timeOffRequests')}
									</span>
								</div>
							</div>
							{data.length ? (
								<div className="flex flex-col overflow-auto">
									<TableContainer component={Paper} className="mt-4">
										<Table
											sx={{ minWidth: 650 }}
											aria-label="simple table"
											className="text-center"
										>
											<TableHead className="bg-gray7">
												<TableRow key={createUUID()}>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{scheduleListText('id')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="left"
													>
														{scheduleListText('name')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{scheduleListText('date')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{scheduleListText('startTime')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{scheduleListText('endTime')}
													</TableCell>
													<TableCell
														className="!text-gray5 !left !font-medium !text-base w-16"
														align="left"
													>
														{scheduleListText('actions')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{data.map((item, index) => (
													<TableRow key={createUUID()}>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base"
														>
															{item.id}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base"
														>
															{item.employee.name}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base"
														>
															{item.date}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base"
														>
															{item.start_time}
														</TableCell>
														<TableCell
															align="left"
															className="!py-3 !text-gray5 !text-left !text-base"
														>
															{item.end_time}
														</TableCell>
														<TableCell
															align="left"
															className="!text-gray-500 !text-left"
														>
															<div className="flex flex-row items-center gap-4">
																{item.status === 'pending' && (
																	<>
																		<img
																			src={checkIcon}
																			style={{ cursor: 'pointer' }}
																			alt={scheduleListText('approve')}
																			onClick={() => onApproveTORequest(item)}
																		/>
																		<img
																			src={closeIcon}
																			style={{ cursor: 'pointer' }}
																			alt={scheduleListText('reject')}
																			onClick={() => onRejectTORequest(item)}
																		/>
																	</>
																)}
																{item.status === 'approved' && (
																	<span
																		className={`text-green2 text-base px-3 py-1 rounded-full`}
																	>
																		{scheduleListText('approved')}
																	</span>
																)}
																{item.status === 'declined' && (
																	<span
																		className={`text-red2 text-base px-3 py-1 rounded-full`}
																	>
																		{scheduleListText('declined')}
																	</span>
																)}
															</div>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component="div"
										count={data.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData content={scheduleListText('request')} />
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}

			{isAreYouSureConfirmModalOpen && (
				<AreYouSureModal
					title={scheduleListText('approve_request')}
					content={scheduleListText('approve_request_confirm')}
					firstButton={scheduleListText('no_go_back')}
					secondButton={scheduleListText('yes_confirm')}
					showModal={isAreYouSureConfirmModalOpen}
					onClose={() => setAreYouSureConfirmModalOpen(false)}
					onSubmit={() =>
						StaffService.ApproveTOSchedule({
							employee_id: selectedTimeSchedule.employee_id,
							schedule_id: selectedTimeSchedule.id,
							approved: '1',
						})
							.then(() => {
								readAllData()
								NotificationManager.success(
									scheduleListText('confirmed_success')
								)
							})
							.catch(() => {
								NotificationManager.error(scheduleListText('operation_failed'))
							})
					}
				/>
			)}

			{isAreYouSureCancelModalOpen && (
				<AreYouSureModal
					title={scheduleListText('decline_request')}
					content={scheduleListText('decline_request_confirm')}
					firstButton={scheduleListText('no_go_back')}
					secondButton={scheduleListText('yes_confirm')}
					showModal={isAreYouSureCancelModalOpen}
					onClose={() => setAreYouSureCancelModalOpen(false)}
					onSubmit={() =>
						StaffService.CancelTOSchedule({
							employee_id: selectedTimeSchedule.employee_id,
							schedule_id: selectedTimeSchedule.id,
							approved: '2',
						})
							.then(() => {
								readAllData()
								NotificationManager.success(
									scheduleListText('declined_success')
								)
							})
							.catch(() => {
								NotificationManager.error(scheduleListText('operation_failed'))
							})
					}
				/>
			)}
		</>
	)
}

export default ScheduleListScreen
