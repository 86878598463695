import { useMemo } from "react";

export const useXAxisTopSettings = (
  dataKey = "name",
  type = "category",
  tickLine
) => {
  const xAxis = useMemo(() => {
    return {
      type: type,
      dataKey: dataKey,
      baseFrequency: 5,
      axisLine: false,
      tickLine: tickLine,
      padding: { left: 20, right: 10 },
      height: 35,
      label: {
        position: "top",
        fontSize: 10,
      },
    };
  }, [dataKey, type, tickLine]);
  return xAxis;
};
