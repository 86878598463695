import { TextareaAutosize } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import saleIcon from '../../../assets/svgs/sale_white.svg'
import { ImgDragUpload } from '../../../components/common/ImgDragUpload'
import CustomFormInput from '../../../components/custom/CustomInput/custom-form-input'
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import GenericModal from '../../../components/generic-modal'
import brandsService from '../../../services/brands-service'
import { isEmpty } from '../../../utils/common'

export const brandModalText = (text) => t(`components.brandModal.${text}`)

const BrandModal = ({
	open,
	onClose,
	brandDetails,
	setBrandDetails,
	fetchBrands,
	brands = [],
}) => {
	const [loading, setLoading] = useState(false)
	const [title, setTitle] = useState(brandDetails?.title || '')
	const [description, setDescription] = useState(
		brandDetails?.description || ''
	)
	const [url, setUrl] = useState(brandDetails?.url || '')
	const [total_stock, setTotalStock] = useState(brandDetails?.total_stock ?? 0)
	const [image, setImage] = useState(null)
	const [white_logo, setWhiteLogo] = useState(null)
	const [parent_id, setParentId] = useState(brandDetails?.parent?.id ?? -1)

	useEffect(() => {
		if (brandDetails) {
			setTitle(brandDetails.title)
			setDescription(brandDetails.description)
			setTotalStock(brandDetails.total_stock ?? 0)
			setUrl(brandDetails.url)
			setParentId(brandDetails?.parent?.id ?? -1)
			if (brandDetails?.logo_path) {
				setImage({
					type: 'url',
					url: isEmpty(brandDetails?.logo_path)
						? null
						: brandDetails?.logo_path,
				})
			}
			if (brandDetails?.white_logo_path) {
				setWhiteLogo({
					type: 'url',
					url: isEmpty(brandDetails?.white_logo_path)
						? null
						: brandDetails?.white_logo_path,
				})
			}
		} else {
			setTitle('')
			setDescription('')
			setUrl('')
			setTotalStock(0)
			setImage(null)
			setWhiteLogo(null)
			setParentId(-1)
		}
	}, [brandDetails])

	const formBrandData = () => {
		const formData = new FormData()
		formData.append('title', title)
		formData.append('description', description)
		formData.append('url', url)
		formData.append('total_stock', total_stock)
		formData.append('parent_id', parent_id)
		if (image) {
			formData.append('image', image.file)
		}
		if (white_logo) {
			formData.append('white_logo', white_logo.file)
		}
		return formData
	}

	const createBrand = () => {
		if (!title || title.length === 0) {
			NotificationManager.warning('Title is required!')
		} else {
			setLoading(true)

			brandsService
				.createBrands(formBrandData())
				.then(() => {
					NotificationManager.success(
						'Brand has been created successfully',
						'Success',
						3000
					)
					setLoading(false)
					onClose()
					setBrandDetails(undefined)
					fetchBrands()
				})
				.catch((error) => {
					setLoading(false)
					NotificationManager.error(
						error.message || 'Something went wrong!',
						'Error',
						3000
					)
				})
		}
	}

	const updateBrand = (id) => {
		if (!title || title.length === 0) {
			NotificationManager.warning('Title is required!')
		} else {
			setLoading(true)

			brandsService
				.updateBrands(id, formBrandData())
				.then(() => {
					NotificationManager.success(
						'Brand has been updated successfully',
						'Success',
						3000
					)
					setLoading(false)
					onClose()
					setBrandDetails(undefined)
					fetchBrands()
				})
				.catch((error) => {
					setLoading(false)
					NotificationManager.error(
						error.message || 'Something went wrong!',
						'Error',
						3000
					)
				})
		}
	}

	const onSubmit = () => {
		if (brandDetails) {
			updateBrand(brandDetails.id)
		} else {
			createBrand()
		}
	}

	const brandsOptions = brands.map((item) => ({
		value: item.id,
		label: item.title,
	}))

	const children = (
		<div className="flex flex-col pt-0 mt-2 mb-4">
			<CustomFormInput
				title={brandModalText('title')}
				required={true}
				value={title}
				placeholder={brandModalText('enter_title')}
				onChange={(e) => setTitle(e.target.value)}
			/>

			<RInputDeclare
				value={brandModalText('description')}
				className="!mt-4 !mb-2 !text-black1 font-medium text-base"
			/>
			<TextareaAutosize
				minRows={3}
				value={description}
				placeholder={brandModalText('enter_description')}
				onChange={(e) => setDescription(e.target.value)}
				className="border outline-none p-2"
			/>
			<div className="grid md:grid-cols-2 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
				<CustomFormInput
					title={brandModalText('url')}
					required={true}
					value={url}
					placeholder={brandModalText('enter_url')}
					onChange={(e) => setUrl(e.target.value)}
				/>
				<CustomFormInput
					title={brandModalText('total_stock')}
					type="number"
					value={total_stock}
					placeholder={brandModalText('enter_total_stock')}
					onChange={(e) => setTotalStock(e.target.value)}
				/>
			</div>
			<div className="grid md:grid-cols-2 grid-cols-1 flex-col  bg-white rounded-3xl gap-3 md:gap-6 mb-4">
				<div className="flex flex-col">
					<RInputDeclare
						value={brandModalText('logo')}
						className="!mt-4 !mb-2 !text-black1 font-medium text-base"
					/>
					<div className="md:col-span-5 justify-start items-start h-full">
						<ImgDragUpload image={image} onChange={setImage} />
					</div>
				</div>
				<div className="flex flex-col">
					<RInputDeclare
						value={brandModalText('white_logo')}
						className="!mt-4 !mb-2 !text-black1 font-medium text-base"
					/>
					<div className="md:col-span-5 justify-start items-start h-full">
						<ImgDragUpload image={white_logo} onChange={setWhiteLogo} />
					</div>
				</div>
			</div>

			<CustomFormSelect
				title={brandModalText('parent_optional')}
				value={parent_id}
				setOptionValue={setParentId}
				options={brandsOptions}
				placeholder="Choose brand"
			/>
		</div>
	)

	return (
		<GenericModal
			{...{ open, onClose, onSubmit, children, loading }}
			icon={saleIcon}
			title={
				brandDetails
					? brandModalText('edit_brand')
					: brandModalText('new_brand')
			}
		/>
	)
}

export default BrandModal
