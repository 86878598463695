import { Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomLineChart from '../../../../components/Charts/custom-line-chart'
import TableEmptyData from '../../../../components/table/TableEmptyData'
import { peakHoursData } from './data'

export default function PeakHoursAnalysis() {
	const [data, setData] = useState([])

	useEffect(() => {
		loadData()
	}, [])

	const loadData = () => {
		setData(
			peakHoursData.map((item) => ({
				...item,
				value: Math.max(item.avgGuests, item.revenue),
				name: item.hour,
			}))
		)
	}

	const lineChartList = [
		{ dataKey: 'avgGuests', name: 'Avg. Guests', color: '#8884d8' },
		{ dataKey: 'revenue', name: 'Revenue', color: '#82ca9d' },
	]

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="flex justify-between items-center pl-6 pr-3 py-4">
					<div className="flex items-center">
						<span className="text-xl font-medium">Peak Hours Analysis</span>
						<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
							{data.length} hours
						</span>
					</div>
				</div>
				{data.length > 0 ? (
					<CustomLineChart
						data={data}
						dataKey="hour"
						lineChartList={lineChartList}
						showLegend={true}
						height={300}
						showTooltip={false}
					/>
				) : (
					<div className="border border-x-0 border-b-0 mb-10">
						<TableEmptyData content="peak hours analysis" />
					</div>
				)}
			</CardContent>
		</Card>
	)
}
