import {
	faChevronDown as iconDown,
	faChevronUp as iconUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const SidebarHeader = ({
	visibleMenu,
	serviceInfo,
	venue_type,
	restaurant_list,
	venue_name,
	setVisible,
	visible,
	cookieBasicInfo,
	handleSwitchVenue,
	isVisionTrack,
}) => {
	const onSwitchVenue = (item) =>
		handleSwitchVenue(
			item.venue_type.short_name,
			item.short_code,
			item.id,
			item.name,
			item.reservation_start_time,
			item.reservation_end_time,
			item.allow_reservation_from,
			item.has_hotel_restaurant,
			item.loyalty_programs[0]?.id,
			item.plan_id,
			item.currency,
			item.subscription.features,
			item.subscription.is_trial_mode,
			item.subscription.show_upgrade_from_trial,
			item.subscription.plan
		)

	return (
		<div className="bg-[#240B3B] w-full py-6">
			<div className={`relative w-[220px] flex mx-4`}>
				<div
					className={`bg-white flex justify-center items-center rounded w-8 h-8 ${
						visibleMenu && !isVisionTrack
							? 'ml-5'
							: isVisionTrack
							? 'ml-1'
							: 'ml-3'
					}`}
				>
					<img
						src={serviceInfo[venue_type ? venue_type : 'restaurant'].darkIcon}
						alt="service"
						className="w-5 h-5"
					/>
				</div>
				{visibleMenu && restaurant_list && restaurant_list.length > 1 && (
					<div
						className={`absolute bottom-0 -right-4 bg-[#3D1E59] ml-3 h-[33px] w-[170px] flex items-center rounded-[5px] mt-4 cursor-pointer px-3 text-white text-14 font-semibold justify-between`}
						onClick={() => setVisible((v) => !v)}
					>
						<span>{venue_name}</span>
						<FontAwesomeIcon
							icon={visible ? iconUp : iconDown}
							className="fa-xs"
						/>
					</div>
				)}
				{visibleMenu && restaurant_list && restaurant_list.length === 1 && (
					<div
						className={`absolute bottom-0 -right-4 bg-[#3D1E59] ml-3 h-[33px] w-[170px] flex items-center rounded-[5px] mt-4 cursor-pointer px-3 text-white text-14 font-semibold justify-center`}
					>
						<span>{cookieBasicInfo?.name}</span>
					</div>
				)}
				{visible && (
					<div
						style={{ height: '300px', overflowY: 'scroll' }}
						className="fixed flex flex-col top-36 left-[77px] rounded-lg bg-white shadow-xl py-4 gap-2 w-[185px]"
					>
						{restaurant_list &&
							restaurant_list.length > 1 &&
							restaurant_list.map((item, index) => (
								<div
									className="flex flex-row items-center cursor-pointer hover:bg-gray1 px-2.5 py-1"
									onClick={() => onSwitchVenue(item)}
								>
									<div
										key={index}
										className="flex justify-center items-center bg-black1 rounded-full h-8 w-8 mr-2"
									>
										<img
											src={serviceInfo[item.venue_type.short_name].icon}
											alt="service"
											className="w-4 h-4"
										/>
									</div>
									<span className={'text-14 text-black1'}>{item.name}</span>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	)
}

export default SidebarHeader
