import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select'
import GenericModal from '../../../components/generic-modal'
import MarketingService from '../../../services/marketingService'

export const referralModalText = (text) =>
	t(`marketing.referrals.ManageReferrals.${text}`)

const staticOptions = [
	referralModalText('Credits'),
	referralModalText('Balance'),
]

export default function ManageReferrals({
	showModal,
	onClose,
	useReferralsfor,
	subscriptionPlan,
}) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedOption, setSelectedOption] = useState(
		referralModalText('Balance')
	)

	useEffect(() => {
		if (showModal) {
			setSelectedOption(
				useReferralsfor === 'wallet_balance'
					? referralModalText('Balance')
					: referralModalText('Credits')
			)
			if (subscriptionPlan.name !== 'Discover') {
				staticOptions.splice(0, 1)
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		setLoading(true)
		try {
			MarketingService.UpdateReferralCreditDirection({ option: selectedOption })
				.then((res) => {
					NotificationManager.success(referralModalText('successfully_updated'))
					onClose(true)
					setLoading(false)
				})
				.catch((res) => {
					NotificationManager.error(
						'Failed',
						referralModalText('error_updating')
					)
					setLoading(false)
				})
		} catch (error) {
			NotificationManager.error('')
		} finally {
			setLoading(false)
		}
	}

	const handleChangeFeature = (value) => {
		setSelectedOption(value.target.value)
	}

	const children = (
		<div className="flex flex-col pb-0 mb-4">
			<>
				<div className="flex flex-row items-center">
					{referralModalText('configure_referral_earnings')}
				</div>
				<p>
					<b>{referralModalText('credits_title')}</b>:{' '}
					{referralModalText('credits_description')}
				</p>
				<p>
					<b>{referralModalText('balance_title')}</b>:{' '}
					{referralModalText('balance_description')}
				</p>

				<CustomFormSelect
					onChange={handleChangeFeature}
					value={selectedOption}
					placeholder={referralModalText('choose')}
					options={staticOptions.map((item) => ({
						label: item,
						value: item,
					}))}
					title={referralModalText('convert_to')}
				/>
			</>
		</div>
	)

	return (
		<GenericModal
			{...{ onClose, onSubmit, children, loading }}
			open={visible}
			title={referralModalText('convert_referrals')}
		/>
	)
}
