import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CustomSelect from '../../../components/custom/CustomSelect'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'

import { CreditCard } from '@mui/icons-material'
import { t } from 'i18next'

export const convertCreditsText = (text) =>
	t(`settings.featuresByPlan.ConvertCredits.${text}`)

export default function ConvertCredits({ showModal, onClose }) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		if (showModal) {
		}
		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const onSubmit = () => {}

	const handleChangeFeature = (value, index) => {}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-2 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 text-white p-2 rounded-full">
								<CreditCard />
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{convertCreditsText('convert_credits')}
							</label>
						</div>
					</div>
					<div className="flex flex-col pb-0 p-024 mb-4">
						<>
							<RInputDeclare
								value={convertCreditsText('feature')}
								className="!mt-0 !mb-2 !text-black1 font-medium"
							/>
							<CustomSelect
								values={[
									'Streamlined Reservations',
									'Inventory Management',
									'Staff Management',
									'Guests Management',
								]}
								// values={}
								// value={}
								placeholder={convertCreditsText('select_feature')}
								height={'!h-10'}
								className={'!h-10 border !border-gray6'}
								handleChange={handleChangeFeature}
							/>
						</>

						<RInputDeclare
							value={convertCreditsText('amount')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							type="number"
							size="small"
							placeholder={convertCreditsText('enter_amount')}
							className="!text-slate-700"
							onChange={(e) => console.log('here')}
						/>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{convertCreditsText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								convertCreditsText('convert')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
