import { useEffect, useState } from "react";

// import { tableColors } from "../../../constants/types";
import { BsCircleHalf, BsFillCheckCircleFill } from 'react-icons/bs';
import HexaImg from "../../assets/images/tables/hex-table.png";
import OctaImg from "../../assets/images/tables/octa-table.png";
import TriImg from "../../assets/images/tables/tri-table.png";
import UImg from '../../assets/images/tables/u-table.png';
export function RenderTable({
    isSelected,
    num,
    content,
    statusDescription,
    isHChair,
    vertical,
    shape,
    onClick,
    location_id,
    diningTime,
    amount,
    reservations,
    showTime,
    showAmount,
    showReservations
}) {


    const [selected, setSelected] = useState(isSelected);
    const [topNum, setTopNum] = useState(0);
    const [bottomNum, setBottomNum] = useState(0);
    const [leftNum, setLeftNum] = useState(0)
    const [rightNum, setRightNum] = useState(0)
    const [showModal, setShowModal] = useState(false)

    const arrangeChairs = (chairNumber) => {
        if (chairNumber % 2 === 0) {
            if (shape === 'round' || shape === 'hexagonal' || shape === 'octagonal') {
                if (chairNumber === 4) {
                    setTopNum(1); setBottomNum(1); setLeftNum(1); setRightNum(1);
                    return
                } else {
                    setTopNum(1);
                    setBottomNum(1)
                    return
                }
            }
            if (shape === 'square') {
                if (chairNumber === 8) {
                    setTopNum(2); setBottomNum(2); setLeftNum(2); setRightNum(2);
                    return
                } else if (chairNumber === 6) {
                    setTopNum(2); setBottomNum(2); setLeftNum(2);
                    return
                } else {
                    setTopNum(chairNumber / 2); setBottomNum(chairNumber / 2)
                    return
                }
            }
            if (shape === "rectangular") {
                if (chairNumber === 14) {
                    setTopNum(5);
                    setBottomNum(5)
                    setLeftNum(2);
                    setRightNum(2)
                    return
                } else {
                    setTopNum(chairNumber / 2 - 1);
                    setBottomNum(chairNumber / 2 - 1);
                    setLeftNum(1);
                    setRightNum(1)
                    return
                }
            }
            if (shape === "oval") {
                if (chairNumber === 10) {
                    setTopNum(4);
                    setBottomNum(4)
                    setLeftNum(1)
                    setRightNum(1)
                    return

                } else {

                    setTopNum(chairNumber / 2);
                    setBottomNum(chairNumber / 2)
                    return
                }
            }
            if (shape === "conference") {
                if (chairNumber === 12) {
                    setTopNum(chairNumber / 2 - 1);
                    setBottomNum(chairNumber / 2 - 1)
                    setLeftNum(1)
                    setRightNum(1)
                    return
                } else {
                    setTopNum(chairNumber / 2);
                    setBottomNum(chairNumber / 2)
                    return
                }
            }
            if (shape === 'picnic' || shape === 'booths') {
                setTopNum(1); setBottomNum(1)
                return
            }
            if (shape === 'bar') {
                setTopNum(chairNumber)
                return
            }
            if (shape === 'triangle') {
                if (chairNumber === 2) {
                    setLeftNum(1); setRightNum(1);
                    return

                }
                if (chairNumber === 4) {
                    setLeftNum(1); setRightNum(1);
                    setTopNum(1); setBottomNum(1);
                    return

                }
            }
            if (shape === 'u-shape') {
                if (chairNumber === 10) {
                    setTopNum(6); setLeftNum(2); setRightNum(2); return
                } else if (chairNumber === 8) {
                    setTopNum(4);
                    setLeftNum(2);
                    setRightNum(2);
                    return
                } else if (chairNumber === 6) {
                    setTopNum(4);
                    setLeftNum(1)
                    setRightNum(1)
                    return
                } else if (chairNumber === 4) {
                    setTopNum(2)
                    setRightNum(1);
                    setLeftNum(1);
                    return
                } else if (chairNumber === 2) {
                    setTopNum(2)
                    return
                }
            }

        } if (chairNumber === 1) {
            if (shape === 'triangle') setBottomNum(1)
            else setTopNum(1)
            return
        } else {
            if (shape === 'round' || shape === 'hexagonal' || shape === 'octagonal') {
                setTopNum((chairNumber - 1) / 2);
                setBottomNum((chairNumber - 1) / 2);
                setLeftNum(1)
                return
            }
            if (shape === 'square') {
                if (chairNumber === 7) {
                    setTopNum(2); setBottomNum(2); setLeftNum(2); setRightNum(1);
                    return

                } else if (chairNumber === 5) {
                    setTopNum(2); setBottomNum(1); setLeftNum(1); setRightNum(1)
                    return

                } else {
                    setTopNum(1); setBottomNum(1); setLeftNum(1);
                    return

                }
            }
            if (shape === "rectangular") {
                if (chairNumber === 13) {
                    setTopNum(5); setBottomNum(5); setLeftNum(2); setRightNum(1)
                    return

                } else if (chairNumber === 11) {
                    setTopNum(5); setBottomNum(5); setLeftNum(1)
                    return
                }
                else {
                    setTopNum((chairNumber - 1) / 2)
                    setBottomNum((chairNumber - 1) / 2)
                    setLeftNum(1)
                    return
                }

            }
            if (shape === "oval") {
                if (chairNumber === 9) {
                    setTopNum(4); setBottomNum(4)
                    setLeftNum(1)
                    return

                } else {
                    setTopNum((chairNumber - 1) / 2);
                    setBottomNum((chairNumber - 1) / 2);
                    setLeftNum(1);
                    return
                }
            }
            if (shape === "conference") {
                if (chairNumber === 11) {
                    setTopNum(5); setBottomNum(5)
                    setLeftNum(1)
                    return
                } else {
                    setTopNum((chairNumber - 1) / 2);
                    setBottomNum((chairNumber - 1) / 2);
                    setLeftNum(1);
                    return
                }
            }
            if (shape === 'picnic' || shape === 'booths') {
                setTopNum(1);
                return
            }
            if (shape === 'bar') {
                setTopNum(chairNumber)
                return
            }
            if (shape === 'triangle') {
                setLeftNum(1); setRightNum(1); setBottomNum(1)
                return
            }
            if (shape === 'u-shape') {
                if (chairNumber === 9) {
                    setTopNum(5); setLeftNum(2); setRightNum(2); return
                } else if (chairNumber === 7) {
                    setTopNum(5);
                    setLeftNum(1);
                    setRightNum(1);
                    return
                } else if (chairNumber === 5) {
                    setTopNum(3);
                    setLeftNum(1)
                    setRightNum(1)
                    return
                } else if (chairNumber === 3) {
                    setTopNum(1)
                    setRightNum(1);
                    setLeftNum(1);
                    return
                } else if (chairNumber === 1) {
                    setTopNum(1)
                    return
                }
            }
        }
    }
    const getStatusNumber = (status) => {
        if (status === 'reserved') {
					return 1
				} else if (status === 'not available') {
					return 2
				}
        return 0;
    };

    const status = getStatusNumber(statusDescription);

    const tableColors = [
        { bgColor: "#D9F6DA", textColor: "#8cf58f" }, // available
        { bgColor: "#FEE8D9", textColor: "#fcc39d" }, // Reserved
        { bgColor: "#EDEAFF", textColor: "#d3ccff" }, // not available
    ];
    useEffect(() => {
        arrangeChairs(num)
    }, []);


    if (shape === 'oval' || shape === 'conference') {
        return (
            <div
                className="flex mb-[20px] mr-[12px] min-w-[300px]"
                style={{
                    transform: vertical ? "rotate(90deg)" : "",
                    paddingTop: vertical ? "20px" : "",
                }}
            >
                <div className="flex items-center justify-center mb-[12px] gap-3">
                    {isHChair ? (
                        <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px]" />
                    ) : (
                        ""
                    )}
                </div>

                <div className="flex flex-col flex-1 items-center justify-center mb-[12px]">
                    <div className="flex w-full mb-[12px] gap-3 justify-evenly px-6">
                        {Array.from(Array(topNum).keys()).map((item, i) => {
                            return (
                                <div
                                    className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] "
                                    key={i}
                                    style={{ width: "17%", minWidth: "40px" }}
                                />
                            );
                        })}
                    </div>
                    <div className="flex w-full gap-3">
                        <div className="flex flex-col h-full w-[14px] justify-evenly items-center">
                            {Array.from(Array(leftNum).keys()).map((item, i) => {
                                return (
                                    <div
                                        className="w-full border border-[#d1cfcf] bg-white rounded-[30px] min-h-[40px]"
                                        key={i}
                                    // style={{ width: "17%", minWidth: "40px" }}
                                    />
                                );
                            })}
                        </div>
                        <div
                            className={`h-[120px] flex-1 rounded-[130px] w-[300px] border ${selected
                                ? `border-[${tableColors[status].bgColor}] border-[3px]`
                                : "border-[#border-[#F9F7F7]]"
                                } bg-white  flex items-center justify-center `}
                            style={{
                                // width: "100%",
                                borderColor: selected
                                    ? tableColors[status].textColor
                                    : "#border-[#F9F7F7]",
                            }}
                            onClick={() => {
                                if (onClick) {
                                    onClick(!isSelected, content);
                                    if (showTime || showAmount || showReservations)

                                        setShowModal(!showModal)
                                    setSelected(!selected);
                                }
                            }}
                        >
                            <div
                                className="h-[85px] w-[85px] rounded-[45px] align-middle font-bold"
                                style={{
                                    backgroundColor: tableColors[status].bgColor,
                                    fontWeight: "bold",
                                    // color: stateColors[status].textColor,
                                }}
                            >
                                {content}
                            </div>
                            {
                                showReservations && <div className=" p-1 flex absolute top-2 z-10  right-2"><BsFillCheckCircleFill style={{ color: 'green', fontSize: '20px' }} /></div>
                            }
                            {
                                !showReservations && showAmount && <div className=" p-1 flex absolute top-2 right-2"><BsCircleHalf style={{ color: 'green', fontSize: '20px' }} /></div>
                            }
                            {showModal && <div className="absolute   p-4 flex flex-col align-middle bg-white shadow-lg border-[1px] border-[#e4e4e4] rounded-12">
                                {showTime && <p>{diningTime}</p>}
                                {showAmount && <p>{amount}</p>}
                                {showReservations && <p>Reservations:{reservations}</p>}
                            </div>}
                        </div>
                        <div className="flex flex-col h-full justify-evenly items-center w-[14px]">
                            {Array.from(Array(rightNum).keys()).map((item, i) => {
                                return (
                                    <div
                                        className="w-full border border-[#d1cfcf] bg-white rounded-[30px] min-h-[40px]"
                                        key={i}
                                    // style={{ width: "17%", minWidth: "40px" }}
                                    />
                                );
                            })}
                        </div>

                    </div>
                    <div className="flex w-full mt-[12px] justify-evenly px-6 gap-3">
                        {Array.from(Array(bottomNum).keys()).map((item, i) => {
                            return (
                                <div
                                    className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] "
                                    key={i}
                                    style={{ width: "17%", minWidth: "40px" }}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="flex items-center justify-center mb-[12px]">
                    {isHChair ? (
                        <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
                    ) : (
                        ""
                    )}
                </div>

            </div>
        );
    }

    if (shape === 'u-shape' || shape === "horseshoe") {
        return (
            <div
                className="flex mb-[20px] mr-[12px] min-w-[300px]"
                style={{
                    transform: vertical ? "rotate(90deg)" : "",
                    paddingTop: vertical ? "20px" : "",
                }}
            >
                <div className="flex items-center justify-center mb-[12px] gap-3">
                    {isHChair ? (
                        <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px]" />
                    ) : (
                        ""
                    )}
                </div>

                <div className="flex flex-col flex-1 items-center justify-center mb-[12px]">
                    <div className="flex w-full mb-[12px] gap-3 justify-evenly px-6">
                        {Array.from(Array(topNum).keys()).map((item, i) => {
                            return (
                                <div
                                    className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] "
                                    key={i}
                                    style={{ width: "17%", minWidth: "40px" }}
                                />
                            );
                        })}
                    </div>
                    <div className="flex w-full gap-3">
                        <div className="flex flex-col h-full w-[14px] justify-evenly items-center">
                            {Array.from(Array(leftNum).keys()).map((item, i) => {
                                return (
                                    <div
                                        className="w-full border border-[#d1cfcf] bg-white rounded-[30px] min-h-[40px]"
                                        key={i}
                                    // style={{ width: "17%", minWidth: "40px" }}
                                    />
                                );
                            })}
                        </div>
                        <div className="relative align-middle w-full h-[120px]">

                            <img alt="as" src={UImg}
                                className={`  w-full h-full  ${selected
                                    ? `border-[${tableColors[status].bgColor}] border-[3px]`
                                    : "border-[#border-[#F9F7F7]]"
                                    }`}
                                style={{
                                    borderColor: selected
                                        ? tableColors[status].textColor
                                        : "#border-[#F9F7F7]",
                                }}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(!isSelected, content);
                                        if (showTime || showAmount || showReservations)

                                            setShowModal(!showModal)
                                        setSelected(!selected);
                                    }
                                }}
                            />
                            <div
                                className={`absolute  rounded-[45px] align-middle font-bold                                      mt-0 h-[85px] w-[85px]
                                        `}
                                style={{
                                    backgroundColor: tableColors[status].bgColor,
                                    fontWeight: "bold",
                                    // color: stateColors[status].textColor,
                                }}
                            >
                                {content}
                            </div>
                            {
                                showReservations && <div className=" p-1 flex absolute top-2 z-10  right-2"><BsFillCheckCircleFill style={{ color: 'green', fontSize: '20px' }} /></div>
                            }
                            {
                                !showReservations && showAmount && <div className=" p-1 flex absolute top-2 right-2"><BsCircleHalf style={{ color: 'green', fontSize: '20px' }} /></div>
                            }
                            {showModal && <div className="absolute   p-4 flex flex-col align-middle bg-white shadow-lg border-[1px] border-[#e4e4e4] rounded-12">
                                {showTime && <p>{diningTime}</p>}
                                {showAmount && <p>{amount}</p>}
                                {showReservations && <p>Reservations:{reservations}</p>}
                            </div>}
                        </div>
                        <div className="flex flex-col h-full justify-evenly items-center w-[14px]">
                            {Array.from(Array(rightNum).keys()).map((item, i) => {
                                return (
                                    <div
                                        className="w-full border border-[#d1cfcf] bg-white rounded-[30px] min-h-[40px]"
                                        key={i}
                                    // style={{ width: "17%", minWidth: "40px" }}
                                    />
                                );
                            })}
                        </div>

                    </div>
                    <div className="flex w-full mt-[12px] justify-evenly px-6 gap-3">
                        {Array.from(Array(bottomNum).keys()).map((item, i) => {
                            return (
                                <div
                                    className="h-[14px] border border-[#d1cfcf] bg-white rounded-[30px] "
                                    key={i}
                                    style={{ width: "17%", minWidth: "40px" }}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="flex items-center justify-center mb-[12px]">
                    {isHChair ? (
                        <div className="h-[50px] w-[14px] border border-[#d1cfcf] bg-white rounded-[30px] mr-[12px]" />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
    return (
        <div
            className="relative flex mb-[20px] mr-[12px] min-w-[300px] align-middle"
            style={{
                transform: vertical ? "rotate(90deg)" : "",
                // paddingTop: vertical ? "20px" : "",
            }}
        >
            <div className="flex flex-col flex-1 items-center h-full  justify-start mb-[12px]">
                <div className={`flex w-full mb-[12px] justify-evenly ${shape === 'square ' ? 'px-20' : 'px-7'} gap-3`}>
                    {Array.from(Array(topNum).keys()).map((item, i) => {
                        return (
                            <div
                                className={`h-[14px] border border-[#d1cfcf] bg-white rounded-[30px]
                                    ${shape === 'picnic' ? 'min-w-full'
                                        : (shape === 'hexagonal' || shape === 'triangle' || shape === 'octagonal') ? 'min-w-[80px]' : 'min-w-[40px]'}
                                    `}
                                key={i}
                            // style={{ width: "17%", minWidth: "40px" }}
                            />
                        );
                    })}
                </div>
                <div className="flex  gap-3">
                    <div className="flex flex-col h-full w-[14px] justify-evenly items-center">
                        {Array.from(Array(leftNum).keys()).map((item, i) => {
                            return (
                                <div
                                    className={`w-full border border-[#d1cfcf] bg-white rounded-[30px] 
                                    ${(shape === 'hexagonal' || shape === 'octagonal' || shape === 'triangle') ? "min-h-[80px]" : 'min-h-[40px]'}
                                    `}
                                    key={i}
                                // style={{ width: "17%", minWidth: "40px" }}
                                />
                            );
                        })}
                    </div>
                    {
                        (shape === 'hexagonal' || shape === 'octagonal' || shape === 'triangle') ?
                            <div className="relative align-middle w-[140px] h-[140px]">

                                <img alt="as" src={shape === 'hexagonal' ? HexaImg : shape === 'octagonal' ? OctaImg : TriImg}
                                    className={`                            ${selected
                                        ? `border-[${tableColors[status].bgColor}] border-[3px]`
                                        : "border-[#border-[#F9F7F7]]"
                                        }`}
                                    style={{
                                        // width: "100%",
                                        borderColor: selected
                                            ? tableColors[status].textColor
                                            : "#border-[#F9F7F7]",
                                    }}
                                    onClick={() => {
                                        if (onClick) {
                                            onClick(!isSelected, content);
                                            if (showTime || showAmount || showReservations)
                                                setShowModal(!showModal)
                                            setSelected(!selected);
                                        }
                                    }}
                                />
                                <div
                                    className={`absolute  rounded-[45px] align-middle font-bold
                                        ${shape === 'triangle' ? 'mt-7 h-[60px] w-[60px]' : 'mt-0 h-[85px] w-[85px]'}
                                        `}
                                    style={{
                                        backgroundColor: tableColors[status].bgColor,
                                        fontWeight: "bold",
                                        // color: stateColors[status].textColor,
                                    }}
                                >
                                    {content}
                                </div>
                                {
                                    showReservations && <div className=" p-1 flex absolute top-2 z-10  right-2"><BsFillCheckCircleFill style={{ color: 'green', fontSize: '20px' }} /></div>
                                }
                                {
                                    !showReservations && showAmount && <div className=" p-1 flex absolute top-2 right-2"><BsCircleHalf style={{ color: 'green', fontSize: '20px' }} /></div>
                                }
                                {showModal && <div className="absolute   p-4 flex flex-col align-middle bg-white shadow-lg border-[1px] border-[#e4e4e4] rounded-12">
                                    {showTime && <p>{diningTime}</p>}
                                    {showAmount && <p>{amount}</p>}
                                    {showReservations && <p>Reservations:{reservations}</p>}
                                </div>}
                            </div>
                            :
                            <div
                                className={`relative h-[120px] flex-1 border 
                            ${shape === 'round' ? 'rounded-[120px]' : 'rounded-[16px]'}
                            ${selected
                                        ? `border-[${tableColors[status].bgColor}] border-[3px]`
                                        : "border-[#border-[#F9F7F7]]"
                                    } bg-white  flex items-center justify-center
                            ${shape === 'square' ? 'w-[150px]' : shape === 'round' ? 'w-[120px]' : 'w-[300px]'}
                                
                                `}
                                style={{
                                    // width: "100%",
                                    borderColor: selected
                                        ? tableColors[status].textColor
                                        : "#border-[#F9F7F7]",
                                }}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(!isSelected, content);
                                        if (showTime || showAmount || showReservations)

                                            setShowModal(!showModal)
                                        setSelected(!selected);
                                    }
                                }}
                            >

                                <div
                                    className="h-[85px] w-[85px] rounded-[45px] align-middle font-bold"
                                    style={{
                                        backgroundColor: tableColors[status].bgColor,
                                        fontWeight: "bold",
                                        // color: stateColors[status].textColor,
                                    }}
                                >
                                    {content}
                                </div>
                                {
                                    showReservations && <div className=" p-1 flex absolute top-2 z-10  right-2"><BsFillCheckCircleFill style={{ color: 'green', fontSize: '20px' }} /></div>
                                }
                                {
                                    !showReservations && showAmount && <div className=" p-1 flex absolute top-2 right-2"><BsCircleHalf style={{ color: 'green', fontSize: '20px' }} /></div>
                                }
                                {showModal && <div className="absolute   p-4 flex flex-col align-middle bg-white shadow-lg border-[1px] border-[#e4e4e4] rounded-12">
                                    {showTime && <p>{diningTime}</p>}
                                    {showAmount && <p>{amount}</p>}
                                    {showReservations && <p>Reservations:{reservations}</p>}
                                </div>}
                            </div>
                    }

                    <div className="flex flex-col h-full justify-evenly items-center w-[14px]">
                        {Array.from(Array(rightNum).keys()).map((item, i) => {
                            return (
                                <div
                                    className={`w-full border border-[#d1cfcf] bg-white rounded-[30px] 
                                    ${(shape === 'hexagonal' || shape === 'octagonal' || shape === 'triangle') ? "min-h-[80px]" : 'min-h-[40px]'}
                                    `}
                                    key={i}
                                // style={{ width: "17%", minWidth: "40px" }}
                                />
                            );
                        })}
                    </div>

                </div>
                <div className={`flex w-full mt-[12px] justify-evenly ${shape === 'square ' ? 'px-20' : 'px-7'} gap-3`}>
                    {Array.from(Array(bottomNum).keys()).map((item, i) => {
                        return (
                            <div
                                className={`h-[14px] border border-[#d1cfcf] bg-white rounded-[30px]
                              ${shape === 'picnic' ? 'min-w-full'
                                        : (shape === 'hexagonal' || shape === 'triangle' || shape === 'octagonal') ? 'min-w-[80px]' : 'min-w-[40px]'}
                                    `}
                                key={i}
                            />
                        );
                    })}
                </div>
            </div>

        </div >
    );
}
