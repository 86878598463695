import React from "react";
import { EditOutlined } from "@mui/icons-material";
import { RInputDeclare } from "../../../../components/custom/RInputDeclare";
import { useState } from "react";
import CustomDateTimePicker from "../../../../components/custom/CustomDateTimePicker";
import { MenuItem, TextField } from "@mui/material";
import { useEffect } from "react";
import pdfIcon from "../../../../assets/svgs/pdf.svg";
import Svg_download from "../../../../assets/svgs/download.svg";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// const statusList = [
//     { title: 'Processing', value: 'Processing' },
//     { title: 'On Hold', value: 'On Hold' },
//     { title: 'Cancelled', value: 'Cancelled' },
// ];
export function OrderGeneralBlock({ data, onUpdateOrderStatus }) {
    const [created, setCreated] = useState(new Date());
    const [status, setStatus] = useState('');
    const [customer, setCustomer] = useState("Guest");
    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        if (data?.status) {
            setStatus(data?.status)
            switch (data?.status) {
                case "new":
                    setStatusList([
                        { title: 'New', value: 'new' },
                        { title: 'Processing', value: 'processing' },
                        { title: 'Cancelled', value: 'order_cancelled' },
                    ])
                    break;
                case "processing":
                    setStatusList([
                        { title: 'Processing', value: 'processing' },
                        { title: 'Cancelled', value: 'order_cancelled' },
                        { title: 'On Delivery', value: 'order_on_delivery' },
                        { title: 'Order Completed', value: 'order_completed' },
                    ])
                    break;
                case "order_on_delivery":
                    setStatusList([
                        { title: 'On Delivery', value: 'order_on_delivery' },
                        { title: 'Order Completed', value: 'order_completed' },
                        { title: 'Cancelled', value: 'order_cancelled' },
                    ])
                    break;
                case "order_cancelled":
                    setStatusList([
                        { title: 'Cancelled', value: 'order_cancelled' },
                    ])
                    break;
                case "order_completed":
                    setStatusList([
                        { title: 'Order Completed', value: 'order_completed' },
                    ])
                    break;
                default:
                    break;
            }
        } else {
            setStatus(null);
        }
    }, [data])
    return (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center mb-0">
                    <p className=' text-black1 text-lg font-medium'>General</p>
                </div>
                <RInputDeclare value={`Date Created`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    value={data?.order_summary?.created_at}
                    disabled={true}
                >
                </TextField>
                <RInputDeclare value={`Status`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    select
                    value={status}
                    onChange={event => {
                        setStatus(event.target.value);
                        onUpdateOrderStatus(event.target.value)
                    }}
                >
                    {statusList.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.title}
                        </MenuItem>
                    ))}
                </TextField>
                <RInputDeclare value={`Customer`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    value={data?.customer?.name}
                    disabled={true}
                    onChange={event => {
                        setCustomer(event.target.value)
                    }}
                >
                </TextField>
                {!data?.is_for_self &&
                    <>
                        <RInputDeclare value={`Order for:`} className="!text-gray5 !mb-0 font-medium text-base"/>
                        <TextField
                            id="outlined-select"
                            size="small"
                            value={data?.order_for}
                            disabled={true}
                        >

                        </TextField>
                    </>
                }
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col border rounded-lg mt-4">
                    <div className="flex flex-row justify-between items-center border-b py-2 px-4">
                        <p className=" text-black1 text-lg font-medium">Delivery Address</p>
                    </div>
                    <TableBody className="w-full">
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Customer Name
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.customer?.name ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Customer Email
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.customer?.email ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Customer Phone
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.customer?.phone ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Address
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.delivery_address?.address_line1 ?? '-'} {data?.delivery_address?.address_line2 ?? ''}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                State
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.delivery_address?.state ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                City
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.delivery_address?.city ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Postal code
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.delivery_address?.postcode ?? '-'}
                            </TableCell>
                        </TableRow>
                        {data?.hospital_room_id && <TableRow className="!flex w-100">
                            <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                Hospital Room ID
                            </TableCell>
                            <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                {data?.hospital_room_id ?? '-'}
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </div>



            </div>
            <div className="flex flex-col">
            </div>
        </div>
    );
}
