import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faCircleCheck as iconCheck } from "@fortawesome/free-solid-svg-icons";

import Svg_ubereats from '../../../assets/svgs/ubereats.svg';
import Svg_chevron_right from '../../../assets/svgs/chevron-right.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Svg_cancel from "../../../assets/svgs/cancel.svg";

export default function UberEatsBlock() {
    const navigate = useNavigate();
    const { short_code } = useSelector(state => state.app);

    const onAction = () => {
        navigate(`/${short_code}/admin/integrations/delivery-and-pickup/ubereats`);
    };

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row items-center p-0 mb-024'>
                <label className='font-medium text-lg text-black1'>UberEats Orders</label>
            </div>
            <div className='flex flex-col items-start p-0 w-full h-full bg-white rounded-12'>
                <div className='flex flex-col items-start flex-1 w-full shadow-lg_2 bg-white rounded-12 px-024 pt-024 pb-016'>
                    <div className='flex flex-col justify-center items-start p-0'>
                        <img src={Svg_ubereats} className="w-20 h-20 mb-4" alt="" />
                        <div className='flex flex-row items-center p-0 h-6 mt-2'>
                            {/*<FontAwesomeIcon icon={iconCheck} color='#73CB50' size='lg' className='pr-012' />*/}
                            {/*<label className='text-sm pl-08 text-green1'>Connected</label>*/}
                            <img className='pr-012' src={Svg_cancel} alt="" />
                            <label className='text-14 pl-08 text-red1'>Disconnected</label>
                        </div>
                        <label className='font-medium text-base mt-2'>
                            Connect your restaurant with&nbsp;
                            <span className='font-bold'>Ubereats</span>
                        </label>
                    </div>
                </div>
                <div
                    className='flex flex-row justify-between px-024 py-016 w-full h-[52px] cursor-pointer'
                    onClick={() => onAction()}
                >
                    <label className='text-black1 font-medium text-base'>View detail</label>
                    <img src={Svg_chevron_right} alt='' />
                </div>
            </div>
        </div>
    )
}
