import React from 'react';

import { t } from 'i18next'
import aiIcon from '../../assets/svgs/ai.svg'
import { RInputDeclare } from '../custom/RInputDeclare';

export const assistantButtonText = (text) =>
	t(`components.assistantButton.view.${text}`)

const AssistantButton = ({ onAction = () => {} }) => {
  return (
    <div className="flex flex-row items-center text-right gap-1 margin px-2 py-2 border border-green5 rounded" onClick={() => onAction()}>
      <img src={aiIcon} alt="icon" width={16} />
      <RInputDeclare value={"VB AI Assistant"} className="!mt-0 !mb-0 !text-green5 font-medium text-xs cursor-pointer" />
    </div>
  )
}

export default AssistantButton;
