import { Card, CardContent } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import LoadingScreen from '../../../components/custom/LoadingScreen'
import MarketingService from '../../../services/marketingService'
import CampaignModal from './CampaignModal'
import CampaignViewModal from './CampaignViewModal'

import { t } from 'i18next'
import { NotificationManager } from 'react-notifications'
import plusIcon from '../../../assets/svgs/plus.svg'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import { productText } from '../../menu/products'

import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import TableEmptyData from '../../../components/table/TableEmptyData'

export const campaignsText = (text) => t(`marketing.campaigns.index.${text}`)

const Campaigns = () => {
	const _curCampaign = useRef(null)

	const [campaignData, setCampaignData] = useState([])
	const [promotionData, setPromotionData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [viewModal, setViewModal] = useState(false)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [selected, setSelected] = useState('')
	const [deleteOpen, setDeleteModalOpen] = useState(false)

	const [loaded, setLoaded] = useState(false)
	const [callEnd, setCallEnd] = useState({ promo: false, discount: false })

	useEffect(() => {
		refreshData()
	}, [])

	useEffect(() => {
		if (callEnd.promo) {
			setLoaded(true)
		}
	}, [callEnd])

	const refreshData = () => {
		MarketingService.GetPromotions()
			.then((res) => {
				setCallEnd((v) => ({ ...v, promo: true }))
				setPromotionData(res.data)
			})
			.catch((err) => {
				setCallEnd((v) => ({ ...v, promo: true }))
			})

		MarketingService.GetCampaigns()
			.then((res) => {
				setCampaignData(res.data)
			})
			.catch((err) => {
				setCallEnd((v) => ({ ...v, promo: true }))
			})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onDelete = (id) => {
		setSelected(id)
		setDeleteModalOpen(true)
	}

	const columns = [
		{ label: campaignsText('campaigns-list-table-header-no'), field: 'no' },
		{
			label: campaignsText('campaigns-list-table-header-title'),
			field: 'title',
		},
		{
			label: campaignsText('campaigns-list-table-header-description'),
			field: 'description',
		},
		{ label: campaignsText('campaigns-list-table-header-type'), field: 'type' },
		{
			label: campaignsText('campaigns-list-table-header-promotion'),
			field: 'promotion',
		},
		{
			label: campaignsText('campaigns-list-table-header-target'),
			field: 'target',
		},
		{
			label: campaignsText('campaigns-list-table-header-actions'),
			field: 'actions',
		},
	]

	const mappedData = campaignData.map((row, index) => ({
		...row,
		no: index + 1,
		promotion: `ID#${row.promotion?.id}`,
	}))

	const handleAction = () => {
		setDeleteModalOpen(false)
		MarketingService.DeleteCampaign(selected)
			.then(({ data }) => {
				refreshData()
				NotificationManager.success(
					data?.message ?? campaignsText('campaign_deleted_successfully')
				)
			})
			.catch((err) => {
				NotificationManager.error(err?.message ?? t('common.error_generic'))
			})
	}

	return (
		<div>
			<div className="flex flex-col h-full">
				{loaded ? (
					<div className="flex flex-col p-024  overflow-auto">
						<Card className="max-h-[80vh]">
							<CardContent className="!p-0 max-h-[80vh] overflow-auto">
								<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{campaignsText('title')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{campaignData.length} {campaignsText('length')}
										</span>
									</div>
									<div className="flex flex-row justify-start items-center gap-4">
										<div
											className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
											onClick={() => {
												_curCampaign.current = null
												setShowModal(true)
											}}
										>
											<img src={plusIcon} alt="plus" className="mr-2" />
											<span>{campaignsText('new-campaign')}</span>
										</div>
									</div>
								</div>
								{campaignData.length ? (
									<>
										<GenericTable
											data={mappedData}
											columns={columns}
											onEdit={(row) => {
												_curCampaign.current = row
												setShowModal(true)
											}}
											onDelete={onDelete}
										/>
										<GenericPagination
											count={campaignData.length}
											page={page}
											rowsPerPage={rowsPerPage}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											setPage={setPage}
										/>
									</>
								) : (
									<TableEmptyData
										content="campaigns"
										onAction={() => {
											_curCampaign.current = null
											setShowModal(true)
										}}
										btnLabel={campaignsText('new-campaign')}
									/>
								)}
							</CardContent>
						</Card>
					</div>
				) : (
					<LoadingScreen />
				)}
				{showModal && (
					<CampaignModal
						data={_curCampaign.current}
						showModal={showModal}
						promotions={
							promotionData.length
								? promotionData
										.filter((item) => item.status === 1)
										.map((item) => `ID#${item.id}`)
								: []
						}
						onClose={(refresh) => {
							if (refresh === true) {
								refreshData()
							}
							setShowModal(false)
						}}
					/>
				)}
				{viewModal && (
					<CampaignViewModal
						data={_curCampaign.current}
						showModal={viewModal}
						onClose={() => setViewModal(false)}
					/>
				)}
				{deleteOpen && (
					<AreYouSureModal
						title={campaignsText('delete_campaign')}
						content={campaignsText('are_you_sure_delete_campaign')}
						firstButton={productText('no_go_back')}
						secondButton={productText('yes_i_am')}
						showModal={deleteOpen}
						onClose={() => setDeleteModalOpen(false)}
						onSubmit={handleAction}
					/>
				)}
			</div>
		</div>
	)
}

export default Campaigns
