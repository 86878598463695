import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { boxStyle } from '../../constants/types'
import StaffService from '../../services/staffService'

import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '../common/IconButton'
import { RInputDeclare } from '../custom/RInputDeclare'

import { t } from 'i18next'
import calendarIcon from '../../assets/svgs/calendar_white.svg'

export const calculatePayrollModalText = (text) =>
	t(`components.employee.CalculatePayrollModal.${text}`)

export default function CalculatePayrollModal({
	showModal,
	onClose,
	employeeId,
}) {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	const monthOptions = []
	const yearOptions = []
	for (let i = 0; i < months.length; i++) {
		monthOptions.push({ value: i + 1, label: months[i] })
	}
	let curYear = moment().year()
	for (let i = curYear - 4; i <= curYear; i++) {
		yearOptions.push({ value: i, label: i.toString() })
	}

	const [visible, setVisible] = useState(false)
	const [month, setMonth] = useState(1)
	const [year, setYear] = useState(curYear)
	const [hoursWorked, setHoursWorked] = useState(0)
	const [overtimeWorked, setOvertimeWorked] = useState(0)
	const [bonus, setBonus] = useState(0)
	const [deduction, setDeduction] = useState(0)
	const [checkValid, setCheckValid] = useState(false)

	useEffect(() => {
		setVisible(showModal)
		setCheckValid(false)
		if (showModal) {
			loadData()
		}
	}, [showModal])

	const loadData = () => {
		setHoursWorked(0)
		setOvertimeWorked(0)
		setBonus(0)
		setDeduction(0)
	}

	const onSubmit = () => {
		if (
			!year ||
			!month ||
			hoursWorked <= 0 ||
			!overtimeWorked <= 0 ||
			bonus <= 0 ||
			deduction <= 0
		) {
			setCheckValid(true)
			return
		}

		let payload = {
			employee_id: parseInt(employeeId),
			month: month.label,
			year: year.value,
			hours_worked: parseFloat(hoursWorked),
			overtime_hours: parseFloat(overtimeWorked),
			bonus: parseFloat(bonus),
			deduction: parseFloat(deduction),
		}

		// setData
		StaffService.PayrollCalculate(payload)
			.then((res) => {
				NotificationManager.success('Payroll added successfully', '')
				setVisible(false)
			})
			.catch((e) => {
				NotificationManager.error('Failed', '')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<IconButton
						icon={calendarIcon}
						title={calculatePayrollModalText('calculate_payroll')}
					/>
					<div className="flex flex-col px-024 overflow-auto">
						<div className="flex flex-row gap-2">
							<RInputDeclare
								value={calculatePayrollModalText('select_year')}
								className="text-base !text-black1 font-medium !mb-2"
							/>
							<RInputDeclare
								value={calculatePayrollModalText('required')}
								className="text-base !mb-2"
							/>
						</div>
						<TextField
							id="outlined-select"
							select
							size="small"
							value={year}
							onChange={(event) => setYear(event.target.value)}
						>
							{yearOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<div className="flex flex-row gap-2 mt-3">
							<RInputDeclare
								value={calculatePayrollModalText('select_month')}
								className="text-base !text-black1 font-medium !mb-2"
							/>
							<RInputDeclare
								value={calculatePayrollModalText('required')}
								className="text-base !mb-2"
							/>
						</div>
						<TextField
							id="outlined-select"
							select
							size="small"
							value={month}
							onChange={(event) => setMonth(event.target.value)}
						>
							{monthOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<div className="flex flex-row gap-2 mt-3">
							<RInputDeclare
								value={calculatePayrollModalText('hours_worked')}
								className="text-base !text-black1 font-medium !mb-2"
							/>
							<RInputDeclare
								value={calculatePayrollModalText('required')}
								className="text-base !mb-2"
							/>
						</div>
						<TextField
							required
							error={checkValid && !hoursWorked}
							id="filled-required"
							size="small"
							type="number"
							value={hoursWorked}
							onChange={(e) => setHoursWorked(e.target.value)}
							placeholder={calculatePayrollModalText('enter_worked_hours')}
						/>

						<div className="flex flex-row gap-2 mt-3">
							<RInputDeclare
								value={calculatePayrollModalText('overtime_hours')}
								className="text-base !text-black1 font-medium !mb-2"
							/>
							<RInputDeclare
								value={calculatePayrollModalText('required')}
								className="text-base !mb-2"
							/>
						</div>
						<TextField
							required
							error={checkValid && !overtimeWorked}
							id="filled-required"
							size="small"
							type="number"
							value={overtimeWorked}
							onChange={(e) => setOvertimeWorked(e.target.value)}
							placeholder={calculatePayrollModalText('enter_overtime_hours')}
						/>

						<div className="flex flex-row gap-2 mt-3">
							<RInputDeclare
								value={calculatePayrollModalText('bonus')}
								className="text-base !text-black1 font-medium !mb-2"
							/>
							<RInputDeclare
								value={calculatePayrollModalText('required')}
								className="text-base !mb-2"
							/>
						</div>
						<TextField
							required
							error={checkValid && !bonus}
							id="filled-required"
							size="small"
							type="number"
							value={bonus}
							onChange={(e) => setBonus(e.target.value)}
							placeholder={calculatePayrollModalText('enter_bonus')}
						/>

						<div className="flex flex-row gap-2 mt-3">
							<RInputDeclare
								value={calculatePayrollModalText('deduction')}
								className="text-base !text-black1 font-medium !mb-2"
							/>
							<RInputDeclare
								value={calculatePayrollModalText('required')}
								className="text-base !mb-2"
							/>
						</div>
						<TextField
							required
							error={checkValid && !deduction}
							id="filled-required"
							size="small"
							type="number"
							value={deduction}
							onChange={(e) => setDeduction(e.target.value)}
							placeholder={calculatePayrollModalText('enter_deduction')}
						/>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-8 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{calculatePayrollModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{calculatePayrollModalText('continue')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
