import {
	API_MENU_ATTRIBUTES,
	API_MENU_ATT_VARIATION,
	API_MENU_CATS, API_MENU_CREATE,
	API_MENU_PRODUCTS,
	API_MENU_RETAIL_INVENTORIES,
	API_MENU_RETAIL_INVENTORIES_ACTIVITY,
	API_MENU_RETAIL_INVENTORY,
	API_MENU_UPLOAD_PHOTO,
	API_MENU_VARIATIONS,
	API_TRY_HOME
} from '../constants/API'
import apiFactory from '../utils/apiFactory'

const getCategories = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_CATS}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeCategory = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_CATS}`,
		method: 'POST',
	}
	return apiFactory(api, { category: payload })
}

const delCategory = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_CATS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const getProducts = async (query = '', short_code) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS}`,
		query: query,
		method: 'GET',
	}
	return apiFactory(api, {}, {}, short_code)
}

const getProductDetails = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS}/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeProduct = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_MENU_PRODUCTS}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const bulkImportUploadProducts = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_MENU_PRODUCTS}/bulk-import`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const delProduct = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const storeMenu = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: API_MENU_CREATE,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const createTryAtHome = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: API_TRY_HOME,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const getVariationsAtProduct = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATT_VARIATION}/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeProductInventory = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_RETAIL_INVENTORY}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const createAttribute = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const deleteAttribute = async (id, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api, payload)
}

const createVariation = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_VARIATIONS}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const deleteVariation = async (id, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_VARIATIONS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api, payload)
}

const uploadPhoto = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_MENU_UPLOAD_PHOTO}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const getRetailInventories = async (query = '') => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_RETAIL_INVENTORIES}`,
		query: query,
		method: 'GET',
	}
	return apiFactory(api)
}

const getRetailInventoriesActivity = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_RETAIL_INVENTORIES_ACTIVITY}/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}


export default {
	getCategories,
	storeCategory,
	delCategory,
	getProducts,
	getProductDetails,
	storeProduct,
	delProduct,
	storeMenu,
	storeProductInventory,
	createAttribute,
	deleteAttribute,
	createVariation,
	deleteVariation,
	getVariationsAtProduct,
	uploadPhoto,
	getRetailInventories,
	getRetailInventoriesActivity,
	bulkImportUploadProducts,
	createTryAtHome,
}
