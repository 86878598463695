import { Box, Card, CardContent, Tab, Tabs } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import FeedbackButtons from '../../../components/feedback'
import { isAccommodation } from '../../../helpers/venueHelpers'
import TemplateService from '../../../services/templateService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import { Automatic } from './Automatic'
import { AutomaticReplies } from './AutomaticReplies'
import { MessageTemplates } from './MessageTemplates'

export const guestsText = (text) => t(`guests.communicationPreferences.view.${text}`)

const CommunicationPreferencesScreen = () => {
	const [loaded, setLoaded] = useState(true)
	const [index, setIndex] = useState(0)

	const cookies = new Cookies()

	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const { short_code } = useSelector((state) => state.app)

	const [automaticReplies, setAutomaticReplies] = useState([
		{
			id: null,
			title: guestsText('preArrivalTitle'),
			description: guestsText('preArrivalDescription'),
			value: 'pre-arrival',
			template_id: null,
		},
		{
			id: null,
			title: guestsText('inPlaceTitle'),
			description: guestsText('inPlaceDescription'),
			value: 'in-place',
			template_id: null,
		},
		{
			id: null,
			title: guestsText('postReservationTitle'),
			description: guestsText('postReservationDescription'),
			value: 'post-reservation',
			template_id: null,
		},
	])

	const [automaticBookingReplies, setAutomaticBookingReplies] = useState([
		{
			id: null,
			title: guestsText('bookingConfirmationTitle'),
			description: guestsText('bookingConfirmationDescription'),
			value: 'booking-confirmation',
			template_id: null,
		},
		{
			id: null,
			title: guestsText('duringStayTitle'),
			description: guestsText('duringStayDescription'),
			value: 'during-stay',
			template_id: null,
		},
		{
			id: null,
			title: guestsText('postStayTitle'),
			description: guestsText('postStayDescription'),
			value: 'post-stay',
			template_id: null,
		},
	])

	const [automaticBooking] = useState([
		{
			id: null,
			modalTitle: guestsText('manageUpgradesAutomation'),
			title: guestsText('upgradesTitle'),
			description: guestsText('upgradesDescription'),
			optionLabel: guestsText('upgradeTypeLabel'),
			options: [{ value: 'Room upgrade', title: guestsText('roomUpgrade') }],
			template_id: null,
		},
		{
			id: null,
			modalTitle: guestsText('manageOffersPromotionsAutomation'),
			title: guestsText('offersPromotionsTitle'),
			description: guestsText('offersPromotionsDescription'),
			optionLabel: guestsText('offersPromotionsLabel'),
			options: [
				{
					value: 'Offers on room service',
					title: guestsText('offersOnRoomService'),
				},
				{
					value: 'Discounts for events',
					title: guestsText('discountsForEvents'),
				},
				{
					value: 'Other promotions',
					title: guestsText('otherPromotions'),
				},
			],
			template_id: null,
		},
		{
			id: null,
			modalTitle: guestsText('manageSuggestionsAutomation'),
			title: guestsText('suggestionsTitle'),
			description: guestsText('suggestionsDescription'),
			value: 'post-stay',
			optionLabel: guestsText('typeOfSuggestionLabel'),
			options: [{ value: 'Late checkout', title: guestsText('lateCheckout') }],
			template_id: null,
		},
	])

	const [messageTemplates, setMessageTemplates] = useState([])

	useEffect(() => {
		getPageDetails()
	}, [])

	const getPageDetails = () => {
		setLoaded(false)
		TemplateService.GetAllMessageTemplates()
			.then((res) => {
				setLoaded(true)
				setMessageTemplates(res)
				identifyVenue(short_code)
				trackEvent('Communication Preferences', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch((err) => {
				setLoaded(true)
				NotificationManager.error(
					err?.message ?? 'Failed to get message templates'
				)
				identifyVenue(short_code)
				trackEvent('Communication Preferences', {
					action_category: 'load',
					action_outcome: 'failed',
					interaction_element: 'communication',
				})
			})

		TemplateService.GetAllAutomaticReplies()
			.then((res) => {
				if (isAccommodation(venueType)) {
					let automatic = automaticBookingReplies
					res.forEach((item) => {
						automaticBookingReplies.forEach((u, index) => {
							if (item.reply_type == u.value) {
								automaticBookingReplies[index].id = item.id
								automaticBookingReplies[index].template_id = item.template_id
							}
						})
					})
					setAutomaticBookingReplies(automatic)
				} else {
					let automatic = automaticReplies
					res.forEach((item) => {
						automaticReplies.forEach((u, index) => {
							if (item.reply_type == u.value) {
								automaticReplies[index].id = item.id
								automaticReplies[index].template_id = item.template_id
							}
						})
					})
					setAutomaticReplies(automatic)
				}
				identifyVenue(short_code)
				trackEvent('Template Automatic Replies', {
					action_category: 'load',
					action_outcome: 'success',
					interaction_element: 'screen',
				})
			})
			.catch((err) => {
				NotificationManager.error(
					err?.message ?? 'Failed to get automatic replies'
				)
			})
	}

	const onTabClicked = (event, index) => {
		setIndex(index)
	}

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{guestsText('communicationPreferencesTitle')}
									</span>
								</div>
								<div
									style={{ display: 'flex', gap: '16px', alignItems: 'center' }}
								>
									<FeedbackButtons type={guestsText('communicationPreferencesTitle')} />
								</div>
							</div>

							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2 mt-0 mb-2">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-16 font-medium">
										{guestsText('communicationPreferencesDescription')}
									</span>
								</div>
							</div>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									value={index}
									onChange={onTabClicked}
									className="pt-0 pr-4 pl-4 pb-2"
									textColor="inherit"
									TabIndicatorProps={{
										style: {
											backgroundColor: '#666A73',
										},
									}}
								>
									<Tab label={guestsText('messageTemplatesTabLabel')} />
									<Tab label={guestsText('automaticRepliesTabLabel')} />
									{isAccommodation(venueType) && (
										<Tab label={guestsText('automaticTabLabel')} />
									)}
								</Tabs>
								<Panel value={index} index={0}>
									<MessageTemplates
										data={messageTemplates}
										onRefresh={() => getPageDetails()}
									/>
								</Panel>
								<Panel value={index} index={1}>
									{isAccommodation(venueType) ? (
										<AutomaticReplies
											data={automaticBookingReplies}
											templates={messageTemplates}
											onRefresh={() => getPageDetails()}
										/>
									) : (
										<AutomaticReplies
											data={automaticReplies}
											templates={messageTemplates}
											onRefresh={() => getPageDetails()}
										/>
									)}
								</Panel>
								{isAccommodation(venueType) && (
									<Panel value={index} index={2}>
										<Automatic
											data={automaticBooking}
											templates={messageTemplates}
											onRefresh={() => getPageDetails()}
										/>
									</Panel>
								)}
							</Box>
						</CardContent>
					</Card>
				</div>
			) : null}
		</div>
	)
}

const Panel = (props) => (
	<div
		hidden={props.value !== props.index}
		className="row-span-2 bg-white rounded-12 m-4 border border-gray-200"
	>
		<div>{props.children}</div>
	</div>
)

export default CommunicationPreferencesScreen
