import { CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import AccommodationVacationRentalWhiteIcon from '../../assets/svgs/accommodation-vacation-white.svg'
import aiIcon from '../../assets/svgs/ai.svg'
import { boxStyle } from '../../constants/types'
import AccommodationService from '../../services/accommodationService'
import { vbAssistantAskQuestion } from '../../store/actions/chatbot'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'
import { productText } from '../../views/menu/products'
import { RInputDeclare } from '../custom/RInputDeclare'

export const rentalShowModalText = (text) =>
	t(`accommodation.rental_unit.show.modal.${text}`)

export default function RentalUnitShowModal({ showModal, data, onClose }) {
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const [name, setName] = useState('')
	const [about, setAbout] = useState('')
	const [about_space, setAboutSpace] = useState('')
	const [about_guest_access, setAboutGuestAccess] = useState('')

	const [aiVisible, setAiVisible] = useState(false)
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [generated, setGenerated] = useState()
	const { short_code } = useSelector((state) => state.app)
	const [generating, setGenerating] = useState(false)

	const placeholder = {
		About: rentalShowModalText('provide_overview'),
		Space: rentalShowModalText('provide_description'),
		'Guest Access': rentalShowModalText('provide_guest_access'),
	}

	const preResponses = {
		About: rentalShowModalText('pre_response_about'),
		Space: rentalShowModalText('pre_response_space'),
		'Guest Access': rentalShowModalText('pre_response_guest_access'),
	}

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				// do nothing for now
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (!about || !name) {
			return
		}

		let payload = {
			name,
			about,
			about_guest_access,
			about_space,
		}
		setLoading(true)
		AccommodationService.CreateRentalUnit(payload)
			.then(({ data }) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Create Rental Unit', {
					action_category: 'creation',
					action_outcome: 'success',
					interaction_element: 'rental_unit',
				})
				onClose(true)
				NotificationManager.success(
					data?.message ?? rentalShowModalText('rental_created')
				)
			})
			.catch((err) => {
				setLoading(false)
				identifyVenue(short_code)
				trackEvent('Create Rental Unit', {
					action_category: 'creation',
					action_outcome: 'failed',
					interaction_element: 'rental_unit',
				})
				NotificationManager.error(err?.message ?? t('common.error_generic'))
				throw new Error(rentalShowModalText('problem_rental_show'))
			})
	}

	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'guest_access_content'
		if (aiField === 'About') {
			askFor = 'rental_unit_about_content'
		}
		if (aiField === 'Space') {
			askFor = 'rental_unit_space_content'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || t('common.error_generic')
					)
				} else {
					NotificationManager.error(t('common.error_generic'))
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		switch (aiField) {
			case 'About':
				setAbout(generated)
				break
			case 'Space':
				setAboutSpace(generated)
				break
			case 'Guest Access':
				setAboutGuestAccess(generated)
				break
			default:
				break
		}
		setAiQuery('')
		setGenerated('')
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-010 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img
									src={AccommodationVacationRentalWhiteIcon}
									alt={rentalShowModalText('icon')}
								/>
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{data?.id != null
								? rentalShowModalText('edit')
								: rentalShowModalText('add')}{' '}
							{rentalShowModalText('rental_unit')}
						</label>
					</div>
					<div className="flex flex-row items-center px-024 pb-010 mt-2 gap-3">
						{rentalShowModalText('rental_unit_initiation')}
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col">
								<RInputDeclare
									value={rentalShowModalText('name')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									value={name}
									placeholder={rentalShowModalText('enter_name')}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>

							<div className="flex flex-col">
								<RInputDeclare
									value={rentalShowModalText('about_required')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									value={about}
									placeholder={rentalShowModalText('about_rental_unit')}
									multiline
									minRows={5}
									onChange={(e) => setAbout(e.target.value)}
								/>
								<div className="flex flex-row justify-end mt-2">
									<div
										className="flex flex-row items-center text-right gap-1 margin px-2 py-2 border border-green5 rounded"
										onClick={() => handleOpenAIModal('About')}
									>
										<img
											src={aiIcon}
											alt={rentalShowModalText('icon')}
											width={16}
										/>
										<RInputDeclare
											value={rentalShowModalText('vb_ai_assistant')}
											className="!mt-0 !mb-0 !text-green5 font-medium text-xs cursor-pointer"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-col">
								<RInputDeclare
									value={rentalShowModalText('space_optional')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									value={about_space}
									placeholder={rentalShowModalText('about_space')}
									multiline
									minRows={5}
									onChange={(e) => setAboutSpace(e.target.value)}
								/>
								<div className="flex flex-row justify-end mt-2">
									<div
										className="flex flex-row items-center text-right gap-1 margin px-2 py-2 border border-green5 rounded"
										onClick={() => handleOpenAIModal('Space')}
									>
										<img
											src={aiIcon}
											alt={rentalShowModalText('icon')}
											width={16}
										/>
										<RInputDeclare
											value={rentalShowModalText('vb_ai_assistant')}
											className="!mt-0 !mb-0 !text-green5 font-medium text-xs cursor-pointer"
										/>
									</div>
								</div>
							</div>

							<div className="flex flex-col">
								<RInputDeclare
									value={rentalShowModalText('guest_access_optional')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									value={about_guest_access}
									placeholder={rentalShowModalText('about_guest_access')}
									multiline
									minRows={5}
									onChange={(e) => setAboutGuestAccess(e.target.value)}
								/>
								<div className="flex flex-row justify-end mt-2">
									<div
										className="flex flex-row items-center text-right gap-1 margin px-2 py-2 border border-green5 rounded"
										onClick={() => handleOpenAIModal('Guest Access')}
									>
										<img
											src={aiIcon}
											alt={rentalShowModalText('icon')}
											width={16}
										/>
										<RInputDeclare
											value={rentalShowModalText('vb_ai_assistant')}
											className="!mt-0 !mb-0 !text-green5 font-medium text-xs cursor-pointer"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{rentalShowModalText('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : data?.id != null ? (
								rentalShowModalText('update')
							) : (
								rentalShowModalText('create')
							)}
						</button>
					</div>
				</Box>
			</Modal>

			<Modal
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				className="class-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center justify-between px-024 pb-010 pt-024 gap-3">
						<label className="text-20 font-semibold text-black1">
							{rentalShowModalText('vb_ai_assistant')}
						</label>
						<CloseOutlined onClick={() => setAiVisible(false)} />
					</div>

					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<div className="grid md:grid-cols-1 grid-cols-1 gap-8">
							<div className="flex flex-col gap-6">
								<TextField
									required
									size="small"
									value={aiQuery}
									placeholder={placeholder[aiField]}
									multiline
									minRows={3}
									onChange={(e) => setAiQuery(e.target.value)}
								/>
								<div className="flex flex-row justify-between">
									<div className="flex flex-row items-center gap-2">
										<InfoOutlined className="text-green5" />
										<span>
											{rentalShowModalText('the_more_details_the_better')}
										</span>
									</div>
									<div className="flex flex-row justify-end w-max gap-4">
										<span
											className="rounded-lg cursor-pointer font-bold bg-tranparent text-green5 grow h-full flex justify-center items-center px-3 py-2 border border-green5 rounded"
											onClick={generating ? null : () => handleGenerate()}
										>
											{generating ? (
												<CircularProgress
													sx={{
														'& .MuiCircularProgress-svg': { color: '#009688' },
													}}
													size={16}
												/>
											) : (
												rentalShowModalText('generate')
											)}
										</span>
									</div>
								</div>
							</div>
							<hr />
							{generated && (
								<div className="flex flex-col gap-6">
									<TextField
										required
										size="small"
										value={generated}
										placeholder={aiField}
										multiline
										minRows={5}
										onChange={(e) => setAboutSpace(e.target.value)}
									/>
									<div className="flex justify-end">
										<div className="flex justify-end w-max">
											<button
												className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
												onClick={() => handleSendReport()}
											>
												{productText('add_to')} {aiField}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
