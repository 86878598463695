import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-clock/dist/Clock.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-time-picker/dist/TimePicker.css'
import icon from '../../assets/svgs/v2-seating-arrangements-white.svg'
import { boxStyle } from '../../constants/types'
import TableService from '../../services/tableService'
import { RInputDeclare } from '../custom/RInputDeclare'

const getTranslation = (text) =>
	t(`components.table.EditSeatingArrangement.${text}`)

export default function EditSeatingArrangement({
	showModal,
	onConfirm,
	onClose,
	data,
	refresh,
}) {
	const [visible, setVisible] = useState(false)
	const [checkValid, setCheckValid] = useState(false)
	const [table, setTable] = useState(0)
	const [tableOptions, setTableOptions] = useState([])

	useEffect(() => {
		if (showModal) {
			// initialize
			setCheckValid(false)
			let payload = {
				start_time: moment(data.start_time).format('YYYY-MM-DD 10:00:00'),
				end_time: moment(data.end_time).format('YYYY-MM-DD 23:59:00'),
			}
			TableService.GetAvailables(payload).then((res) => {
				if (res.data) {
					setTableOptions(
						res.data.map((item) => {
							return {
								value: item.id,
								label: item.name,
							}
						})
					)
					setTable(data?.table_id)
				}
			})
		}
		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const onSubmit = () => {
		if (table) {
			let payload = {
				id: data.id,
				table_id: table,
			}
			TableService.UpdateSeatingArrangements(payload)
				.then((res) => {
					NotificationManager.success('Updated successfully', '')
					refresh()
				})
				.catch((e) => {
					NotificationManager.error('Failed', '')
				})

			onClose()
		} else {
			setCheckValid(true)
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div
								className="bg-black1 p-2 rounded-full"
								style={{ width: '50px' }}
							>
								<img src={icon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold">
							{getTranslation('updateSeatingArrangement')}
						</label>
					</div>
					<div className="flex flex-col p-024 overflow-auto max-h-[70vh]">
						<RInputDeclare
							value={getTranslation('toNewTable')}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							error={checkValid && !table}
							select
							required
							value={table}
							onChange={(event) => {
								setTable(event.target.value)
							}}
						>
							{tableOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-lg bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{getTranslation('confirm')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
