export const gridItems = [
	{
		label: 'S',
		grid: 4,
	},
	{
		label: 'M',
		grid: 3,
	},
	{
		label: 'L',
		grid: 2,
	},
]
