import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import CustomIconButton from '../../components/common/IconButton'
import { boxStyle } from '../../constants/types'

import infoIcon from '../../assets/svgs/info_white.svg'
import { productText } from '../../views/menu/products'

export default function ConfirmDeleteTemplateModal({ showModal, onClose, onSubmit }) {
	return (
		<div>
			<Modal
				open={showModal}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 450 }} className="outline-none">
					<CustomIconButton
						icon={infoIcon}
						title={`Confirm delete message template`}
					/>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6 text-sm">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{productText('no_go_back')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => {
								onSubmit()
							}}
						>
							{productText('yes_i_am')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
