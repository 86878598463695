import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import moment from 'moment';
import React from 'react';
import { MessageText, utils } from '../react-web-gifted-chat';
const { isSameDay, isSameUser } = utils;
const DEFAULT_OPTION_TITLES = ['Copy div', 'Cancel'];

class MessageBubble extends React.Component {
  constructor() {
    super(...arguments);
    this.onLongPress = (e) => {
			if (e) {
				e.preventDefault()
				e.stopPropagation()
				var out = { x: 0, y: 0 }
				if (
					e.type == 'touchstart' ||
					e.type == 'touchmove' ||
					e.type == 'touchend' ||
					e.type == 'touchcancel'
				) {
					if (e.nativeEvent) {
						var touch =
							e.nativeEvent.touches[0] || e.nativeEvent.changedTouches[0]
						out.x = touch.pageX
						out.y = touch.pageY
					}
				} else if (
					e.type == 'mousedown' ||
					e.type == 'mouseup' ||
					e.type == 'mousemove' ||
					e.type == 'mouseover' ||
					e.type == 'mouseout' ||
					e.type == 'mouseenter' ||
					e.type == 'mouseleave'
				) {
					out.x = e.clientX
					out.y = e.clientY
				}
				this.setState({
					contextMenu:
						this.state.contextMenu == null
							? {
									mouseX: out.x + 2,
									mouseY: out.y - 6,
							  }
							: null,
				})
			}
			const { currentMessage } = this.props
			if (this.props.onLongPress) {
				this.props.onLongPress(currentMessage)
			}
		}
    this.onPressMsg = () => {
      const { currentMessage } = this.props;
      this.backCount++;

      if (this.backCount == 2) {
        this.backCount = 0;
        clearTimeout(this.backTimer);
        if (this.props.onDoublePress) {
          this.props.onDoublePress(currentMessage);
        }
      } else {
        this.backTimer = setTimeout(() => {
          this.backCount = 0;
          if (this.props.onPressMsg) {
            this.props.onPressMsg(currentMessage);
          }
        }, 400);
      }
    };
    this.onSelectOption = (option) => {
      if (this.props.onSelectOption) {
        this.props.onSelectOption(option);
      }
    };


    this.state = {
      value: 0,
      isPlaying: false,
      contextMenu: null
    };
  }

  getMaxContentWidth() {
    return Math.min(window.innerWidth, 450) - 130;
  }

  isToNext() {
    const { currentMessage, nextMessage, position } = this.props;
    return (
      currentMessage &&
      nextMessage &&
      position &&
      isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage)
    );
  }

  isToPrevious() {
    const { currentMessage, previousMessage, position } = this.props;
    return (
      currentMessage &&
      previousMessage &&
      position &&
      isSameUser(currentMessage, previousMessage) &&
      isSameDay(currentMessage, previousMessage)
    );
  }

  styledBubbleToNext() {
    const { position, containerToNextStyle } = this.props;
    if (!this.isToNext()) {
      return {
        ...styles[position].containerToNext,
        ...(containerToNextStyle && containerToNextStyle[position])
      };
    }
    return null;
  }

  styledBubbleToPrevious() {
    const { position, containerToPreviousStyle } = this.props;
    if (!this.isToPrevious()) {
      return {
        ...styles[position].containerToPrevious,
        ...(containerToPreviousStyle && containerToPreviousStyle[position])
      };
    }
    return null;
  }

  renderReply() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.reply && currentMessage.reply.user) {
      return (
        <div className={'align-col-middle'} style={styles.content.replyMsg}>
          <div style={styles.content.replyUserName}>
            {currentMessage.reply.user.username || currentMessage.reply.user.full_name}
          </div>
          <div style={styles.content.replyText}>{currentMessage.reply.text}</div>
        </div>
      );
    }
    return null;
  }


  renderMessageText() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.text) {

      return (
        <MessageText
          {...this.props}
          containerStyle={{
            left: { marginTop: 0 },
            right: { marginTop: 0 }
          }}
          textStyle={{
            left: {
              color: '#222',
              fontFamily: '500',
              fontSize: 15,
              lineHeight: '21px'
            },
            right: {
              color: '#fff',
              fontFamily: '500',
              fontSize: 15,
              lineHeight: '21px'
            }
          }}
          linkStyle={{
            left: { color: '#23cbd8' },
            right: { color: 'white', textDecorationLine: 'none' },
          }}
          customTextStyle={{ fontSize: 15, lineHeight: '21px'}}
        />
      );
    }
    return null;
  }

  renderMessageOptions() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.answered == false && currentMessage.options?.length > 0) {
      return (
        <div className='flex flex-row w-full                                                                                                                          flex-wrap'>
          {
            currentMessage.options.map((option, index) =>
                  <div
                    key={index}
                    className={`mr-0 mb-1 text-14 p-3 px-5 border-[#555] bg-[#efefef] hover:bg-[#e0e0e0] cursor-pointer w-full font-medium text-green5 ${index === currentMessage.options.length -1 && 'rounded-ee-2xl'}`}
                    onClick={() => this.onSelectOption(option)}
                  >
                    {option}
                  </div>
                            )
          }
        </div>
      );
    }
    else if (currentMessage && currentMessage.answered == false && currentMessage.question?.answers?.length > 0) {
      return (
        <div className='flex flex-row w-full flex-wrap pr-3 justify-end items-center'>
          {/* <div className=' bg-[#efefef] text-14 p-2 mb-2 mr-2 rounded-2xl text-black1'>{currentMessage.question?.text}</div> */}
          {
            currentMessage.question.answers.map((option, index) =>
              <div
                  key={index}
                  className={`mr-0 mb-1 text-14 p-3 px-5 border-[#555] bg-[#efefef] hover:bg-[#e0e0e0] cursor-pointer w-full font-medium text-green5 ${index === currentMessage.question?.answers?.length -1 && 'rounded-ee-2xl'}`}
                  // className=' mr-2 pr-3 mb-1 text-[13px] p-2 rounded-2xl border-[#555] border-[1px] cursor-pointer'
                onClick={() => this.onSelectOption(option)}
              >
                {option}
              </div>
          )
          }
        </div>
      );
    }

    return null;
  }

  renderTicks() {
    const { currentMessage, renderTicks, user } = this.props;
    if (renderTicks && currentMessage) {
      return renderTicks(currentMessage);
    }
    if (currentMessage && user && currentMessage.user && currentMessage.user.id !== user.id) {
      return null;
    }
    if (
      currentMessage &&
      (currentMessage.sent || currentMessage.received || currentMessage.pending)
    ) {
      return (
        <div style={styles.content.tickView}>
          {!!currentMessage.sent && (
            <div style={{ ...styles.content.tick, ...this.props.tickStyle }}>✓</div>
          )}
          {!!currentMessage.received && (
            <div style={{ ...styles.content.tick, ...this.props.tickStyle }}>✓</div>
          )}
          {!!currentMessage.pending && (
            <div style={{ ...styles.content.tick, ...this.props.tickStyle }}>🕓</div>
          )}
        </div>
      );
    }
    return null;
  }

  renderTime() {
    const { currentMessage, nextMessage } = this.props;
    if (currentMessage && currentMessage.createdAt) {
      if (nextMessage && nextMessage.user && currentMessage.user.id == nextMessage.user.id) {
        return null;
      }
      return (
        <div style={{ ...styles.content.usernameView, marginTop: 6 }}>
          <div style={{ fontSize: 11, fontFamily: '500', color: '#6c6c6c' }}>
            {moment(currentMessage.createdAt).format('LT')}
          </div>
        </div>
      );
    }
    return null;
  }

  renderUsername(position) {
    const { currentMessage, previousMessage, user, isGroup } = this.props;
    if (this.props.renderUsernameOnMessage && currentMessage) {
      if ((user && currentMessage.user.id === user.id) || !isGroup) {
        return null;
      }
      if (
        previousMessage &&
        previousMessage.user &&
        currentMessage.user.id == previousMessage.user.id
      ) {
        return null;
      }
      return (
        <div style={styles.content.usernameView}>
          <div className=' text-primary1' style={{ ...styles.content.username, ...this.props.usernameStyle }}>
            {currentMessage.user.username || currentMessage.user.full_name}
          </div>
        </div>
      );
    }
    return null;
  }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props);
    }
    return null;
  }


  renderBubbleContent() {
          return (
        <div
          onContextMenu={(event) => {
            event.preventDefault();
            if (event.clientX && event.clientY) {
              this.setState({
                contextMenu: (
                  this.state.contextMenu == null
                    ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6
                    }
                    :
                    null
                )
              });
            }
          }}
          className={'align-col-left'}>
              {this.renderReply()}
              {this.renderMessageText()}
                  </div>
      );
      }

  getWrapperStyle() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.reply && currentMessage.reply.user) {
      return {
        paddingTop: 14
      };
    }
    if (
      currentMessage &&
      !currentMessage.reply &&
      !currentMessage.audio &&
      currentMessage.images &&
      currentMessage.images.length > 0 &&
      currentMessage.text
    ) {
      return {
        paddingTop: 10
      };
    }
    if (
      currentMessage &&
      !currentMessage.reply &&
      !currentMessage.images &&
      !currentMessage.audio &&
      currentMessage.text
    ) {
      return {
        borderRadius: 60
      };
    }
    return null;
  }

  likeHandler = () => {
    this.onSelectOption('👍')
  }

  dislikeHandler = () => {
    this.onSelectOption('👎')
  }
  render() {
    const { currentMessage, position, containerStyle, wrapperStyle, renderAvatarOnTop, user } = this.props;
    const { loading } = this.props
    const messageToCompare = renderAvatarOnTop
      ? this.props.nextMessage
      : this.props.previousMessage;
    return (
      <div
        className={`grid grid-cols-1 max-w-[80%]`}
        style={{ ...styles[position].container, ...(containerStyle && containerStyle[position]) }}>

        {!isSameDay(this.props.currentMessage, messageToCompare) &&
          <span className='text-xs text-gray-400 mb-5 ml-3 text-center w-full'>
            {moment(this.props.currentMessage.createdAt).format('dddd, D MMMM YYYY')}
          </span>
        }
        {this.renderUsername(position)}
        {position === 'left'
          && !isSameUser(this.props.currentMessage, messageToCompare)
          && !isSameDay(this.props.currentMessage, messageToCompare)
          && <span className='font-bold mb-1'>VB Assistant</span>}
        {currentMessage && currentMessage.emoji && currentMessage.emoji.length > 0 ? (
          <div style={styles.content.emoji}>
            {currentMessage.emoji.map((item) => item.code).join('')}
          </div>
        ) : (
          <div className={`w-full ${position === 'left' ? 'mr6' : 'ml6 flex justify-end'}`}>
            <div
                className={`mb-1 !w-max max-w-full !pl-2.5 ${this.props.currentMessage.options && this.props.currentMessage.options.length > 0 ? '!rounded-t-2xl !rounded-b-none' : `!rounded-2xl ${position === 'left' ? '!rounded-es-none' : '!rounded-ee-none'} w-max`}`}
              style={{
                ...styles[position].wrapper,
                ...(wrapperStyle && wrapperStyle[position]),
                ...this.styledBubbleToPrevious(),
                ...this.styledBubbleToNext(),
                ...this.getWrapperStyle(),
              }}
              // onClick={this.onPressMsg}
              // onLongPress={this.onLongPress}
              {...this.props.touchableProps}>
                {this.renderBubbleContent()}
            </div>
          </div>
        )}
        {/* {this.renderTime()} */}
        {/* <div className='flex flex-col'> */}
        <div className='!w-full grid grid-cols-1'>
          {this.renderMessageOptions()}
          {/* {this.} */}
          {!(this.renderMessageOptions()) && <>
            {((!currentMessage?.question?.answers) && (!currentMessage?.answered) && (currentMessage?.option_required) && (currentMessage?.show_feedback)) &&
              <div className="!w-full flex justify-end">
                <div className="!w-[16rem] flex items-end mt-[-14px] justify-between my-2 text-black text-[16px] font-semibold">
                  <span className="pb-1">
                    Was This helpful?
                  </span>
                  <div className="border-4 border-white py-2 px-4 rounded-full bg-gray-100 shadow flex gap-3 divide-x divide-black">
                    <div className="currsor-pointer">
                      <ThumbUpOutlinedIcon className="cursor-pointer" size={25} onClick={this.likeHandler} />
                    </div>
                    <div className="currsor-pointer ps-3">
                      <ThumbDownOffAltOutlinedIcon size={25} className="cursor-pointer" onClick={this.dislikeHandler} />
                    </div>
                  </div>
                </div>
              </div>}
          </>
          }
        </div>
        {/*  {this.renderTicks()} */}
      </div>
    );
  }
}

const styles = {
  left: {
    container: {
      flex: 1,
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    msgWrapper: { marginRight: 60, },
    wrapper: {
      borderRadius: 28,
      backgroundColor: '#efefef',
      marginRight: 8,
      minHeight: 20,
      justifyContent: 'flex-end',
      paddingLeft: 18,
      paddingRight: 18,
      paddingTop: 14,
      paddingBottom: 14
    },
    containerToNext: {
      borderTopLeftRadius: 3
    },
    containerToPrevious: {
      borderTopLeftRadius: 3
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  },
  right: {
    container: {
      flex: 1,
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    msgWrapper: { marginLeft: 60, },
    wrapper: {
      borderRadius: 28,
      backgroundColor: '#240b3b',
      marginLeft: 8,
      minHeight: 20,
      justifyContent: 'flex-end',
      paddingLeft: 18,
      paddingRight: 18,
      paddingTop: 14,
      paddingBottom: 14
    },
    containerToNext: {
      borderBottomRightRadius: 3
    },
    containerToPrevious: {
      borderBottomRightRadius: 3
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  content: {
    tick: {
      fontSize: 11
      // backgroundColor: Color.backgroundTransparent,
      // color: Color.white,
    },
    tickView: {
      flexDirection: 'row',
      marginRight: 10
    },
    username: {
      top: -3,
      left: 0,
      fontSize: 11,
      fontFamily: '500',
      backgroundColor: 'transparent',
    },
    usernameView: {
      flexDirection: 'row',
      marginHorizontal: 10
    },
    replyMsg: {
      alignItems: 'flex-start',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 20,
      backgroundColor: '#fff'
    },
    replyUserName: { fontSize: 13, color: '#f00' },
    replyText: {
      marginTop: 6,
      fontSize: 13,
      fontFamily: '500',
      color: '#222'
    },
    emoji: { fontSize: 24 }
  }
};

export default MessageBubble;
