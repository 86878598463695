import {
    Card,
    CircularProgress,
    TextField
} from "@mui/material";
import React, { useState } from "react";


import { Box } from '@mui/system';
import { t } from 'i18next';
import { BlockPicker } from 'react-color'; // You might need to install react-color package
import VenueBrandProfileService from '../../services/venueBrandProfileService';
import CodeStyledText from './CodeStyledText';

export default function ButtonStyleConfig({ btnData }) {
	const [isSaving, setIsSaving] = useState(false)
	const [btnText, setBtnText] = useState(
		btnData.venue_brand_profile_customizations?.find(
			(i) => i.customization_key === 'text'
		)?.customization_value ?? btnData?.default_name
	)
	const [btnColor, setBtnColor] = useState(
		btnData.venue_brand_profile_customizations?.find(
			(i) => i.customization_key === 'color'
		)?.customization_value ?? '#240b3b'
	)
	const [btnTextColor, setBtnTextColor] = useState(
		btnData.venue_brand_profile_customizations?.find(
			(i) => i.customization_key === 'text_color'
		)?.customization_value ?? '#ffffff'
	)

	const onUpdate = () => {
		setIsSaving(true)
		VenueBrandProfileService.update({
			id: btnData?.id,
			element_name: btnData?.element_name,
			data: [
				{ key: 'text', value: btnText },
				{ key: 'color', value: btnColor },
				{ key: 'text_color', value: btnTextColor },
			],
		})
			.then(() => {
				setIsSaving(false)
			})
			.catch(() => {
				setIsSaving(false)
			})
	}

	const onClear = () => {
		setBtnText(btnData?.default_name)
		setBtnColor('#240b3b')
		setBtnTextColor('#ffffff')
	}

	return (
		<Box className="flex flex-col overflow-auto" sx={{ pt: 3 }}>
			<Card sx={{ p: 3 }}>
				<p className={'text-base text-gray5 mb-2'}>
					<span className="text-black1 text-xl font-medium">
						<CodeStyledText text={'`' + btnData?.element_name + '`'} />
					</span>
				</p>
				<span className="text-gray5 text-16 font-medium">
					{btnData?.description}
				</span>
				{/* Real-Time Button Preview */}
				<Box sx={{ mb: 2, mt: 2 }}>
					<span className="!text-gray5 text-18 !text-left !text-base !font-bold">
						{btnData?.element_name == 'Footer' && 'Footer Preview: '}
						{btnData?.element_name == 'Button' && 'Button Preview: '}
						{btnData?.element_name == 'Tags' && 'Button Preview: '}
						{btnData?.element_name == 'OutOfStock' && 'Button Preview: '}
						{btnData?.element_name == 'SubscribeBtn' && 'Button Preview: '}
						{btnData?.element_name == 'ContactFormLeftBlock' && 'Block Preview'}
						{btnData?.element_name == 'ContactFormTopLabel' && 'Block Preview'}
						{btnData?.element_name == 'ContactFormBtn' && 'Button Preview'}
						{btnData?.element_name == 'AllButtons' && 'Button Preview'}
						{btnData?.element_name == 'CartPlusButton' && 'Button Preview'}
						{btnData?.element_name == 'YourCart' && 'Button Preview'}
						{btnData?.element_name == 'AddToCart' && 'Button Preview'}
					</span>
					<div
						className={
							'px-4 py-3 mt-2 text-white rounded-lg cursor-pointer ' +
							(btnData?.element_name == 'Footer' ? 'w-full' : '')
						}
						style={{
							backgroundColor: btnColor,
							color: btnTextColor,
							display: 'table',
						}}
					>
						<span>
							{btnData?.element_name == 'CartPlusButton' ? '+' : btnText}
						</span>
					</div>
				</Box>
				{btnData?.element_name != 'AllButtons' &&
					btnData?.element_name != 'Footer' &&
					btnData?.element_name != 'Tags' &&
					btnData?.element_name != 'TimeSuggested' &&
					btnData?.element_name != 'CartPlusButton' &&
					btnData?.element_name != 'ContactFormLeftBlock' &&
					btnData?.element_name != 'ContactFormTopLabel' &&
					btnData?.element_name != 'ContactFormBtn' && (
						<Box sx={{ mb: 2, display: 'grid' }}>
							<span className="!text-gray5 text-18 !text-left !text-base !font-bold">
								Text Preview:
							</span>
							{/* Text Customization Input */}
							<TextField
								size="small"
								sx={{ mb: 2, mt: 1 }}
								value={btnText}
								onChange={(e) => setBtnText(e.target.value)}
							/>
						</Box>
					)}

				{/* Color Pickers */}
				<Box sx={{ display: 'flex', gap: 2 }}>
					<Box sx={{ flex: 1 }}>
						<span className="!text-gray5  text-18 !text-left !text-base !font-bold">
							{btnData?.element_name == 'Footer' ? 'Footer' : 'Button'} Color:
						</span>
						<div className={'mt-4'}>
							<BlockPicker
								color={btnColor}
								onChangeComplete={(color) => setBtnColor(color.hex)}
							/>
						</div>
					</Box>
					{btnData?.element_name != 'AllButtons' &&
						btnData?.element_name != 'Footer' &&
						btnData?.element_name != 'Tags' &&
						// && btnData?.element_name != 'TimeSuggested'
						btnData?.element_name != 'CartPlusButton' && (
							<Box sx={{ flex: 1 }}>
								<span className="!text-gray5  text-18 !text-left !text-base !font-bold">
									Text Color:
								</span>
								<div className={'mt-4'}>
									<BlockPicker
										color={btnTextColor}
										onChangeComplete={(color) => setBtnTextColor(color.hex)}
									/>
								</div>
							</Box>
						)}
				</Box>
				<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
					<button
						type="button"
						className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClear}
					>
						{t('hotel.eventHall.list.clear')}
					</button>
					<button
						type="submit"
						className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onUpdate}
					>
						{isSaving ? <CircularProgress size={16} /> : 'Update'}
					</button>
				</div>
			</Card>
		</Box>
	)
}
