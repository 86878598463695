import {
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
} from '@mui/material'
import React, { useState } from 'react'
import saleIcon from '../../../assets/svgs/sale_white.svg'
import CustomIconButton from '../../../components/common/IconButton'
import { boxStyle } from '../../../constants/types'
import { ingredientModalText } from '../../ingredient/add-edit-ingredient'

const options = [
	{ label: 'Simple', value: 'simple' },
	{ label: 'Composed', value: 'composed' },
	{ label: 'With Variations', value: 'with_variations' },
]

const ChooseProductType = ({
	open,
	onClose,
	onCreate,
	onComposedCreate,
	onVariationsCreate,
}) => {
	const [type, setType] = useState('simple')
	const [loading, setLoading] = useState(false)

	const onClick = () => {
		setLoading(true)
		if (type === 'simple') {
			onCreate()
			setLoading(false)
		} else if (type === 'composed') {
			onComposedCreate()
			setLoading(false)
		} else if (type === 'with_variations') {
			onVariationsCreate()
		}
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="category-modal"
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={boxStyle} className="outline-none">
				<CustomIconButton icon={saleIcon} title={'Choose product type'} />
				<div className="grid gap-5 pt-0 p-024 mb-4">
					<FormControl fullWidth size="small">
						<InputLabel>Select type</InputLabel>
						<Select
							value={type}
							onChange={(e) => setType(e.target.value)}
							label="Select type"
						>
							{options.map((item) => (
								<MenuItem key={item.value} value={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className="flex flex-row justify-between space-x-4 h-[48px] px-024 mb-4">
					<button
						className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
						onClick={onClose}
					>
						{ingredientModalText('cancel')}
					</button>
					<button
						className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
						onClick={onClick}
					>
						{loading ? (
							<CircularProgress size={16} />
						) : (
							ingredientModalText('save')
						)}
					</button>
				</div>
			</Box>
		</Modal>
	)
}

export default ChooseProductType
