import { CircularProgress } from '@mui/material'
import React from 'react'
import { devicesViewText } from '../../../views/track-devices/devices'

const FooterButtons = ({
	onCancel,
	onSubmit,
	saveText = devicesViewText('save'),
	cancelText = devicesViewText('cancel'),
	loading = false,
	disableSave = false,
	height = 'h-full'
}) => {
	return (
		<div className="flex justify-between space-x-4 h-[48px] my-4">
			<button
				className={`border border-gray6 rounded-lg font-bold bg-white grow  flex justify-center items-center text-cyan1 ${height}`}
				onClick={onCancel}
			>
				{cancelText}
			</button>
			<button
				className={`rounded-md font-bold bg-cyan1 grow flex justify-center items-center text-white ${height}`}
				onClick={onSubmit}
				disabled={disableSave}
			>
				{loading ? <CircularProgress size={16} /> : saveText}
			</button>
		</div>
	)
}

export default FooterButtons
