import moment from 'moment'

export const columns = (reservationsText) => [
	{ field: 'table', label: reservationsText('table'), align: 'left' },
	{ field: 'guest', label: reservationsText('guest'), align: 'left' },
	{ field: 'date', label: reservationsText('date'), align: 'left' },
	{ field: 'startTime', label: reservationsText('startTime'), align: 'left' },
	{ field: 'endTime', label: reservationsText('endTime'), align: 'right' },
	{
		field: 'insertionType',
		label: reservationsText('insertionType'),
		align: 'right',
	},
	{ field: 'source', label: reservationsText('source'), align: 'right' },
	{ field: 'notes', label: reservationsText('notes'), align: 'right' },
	{ field: 'actions', label: reservationsText('actions'), align: 'right' },
]

export const reservationData = (data = [], page = 0, rowsPerPage = 10) => {
	return data
		.map((reservation) => ({
			...reservation,
			table: reservation?.table?.number,
			guest: reservation?.guest?.name,
			date: moment(reservation?.date).format('YYYY-MM-DD'),
			startTime: reservation?.start_time
				? moment(reservation?.start_time).format('HH:mm')
				: 'N/A',
			endTime: reservation?.end_time
				? moment(reservation?.end_time).format('HH:mm')
				: 'N/A',
			insertionType: reservation?.insertion_type,
			source: reservation?.source,
			notes: reservation?.notes,
			is_confirmed: Number(reservation?.confirmed) === 1,
			is_cancelled: Number(reservation?.confirmed) === 2,
		}))
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
}
