import React, { useEffect } from "react";
import ScrollToTop from "../components/Hooks/scrolltoTop";
import NavbarKDS from "./NavbarKDS";
// import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import "./index.css";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../store/actions/auth";
import Footer from "./Footer";

export default function LayoutKDS() {
  return (
    <div className="flex flex-col w-full h-full">
      <NavbarKDS />
      <div className=" bg-[#F9F7F7] h-full">
        <Outlet />
      </div>
    </div>
  );
}
