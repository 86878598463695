import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from 'react-router-dom'
import plusIcon from '../../assets/svgs/plus.svg'
import LoadingScreen from '../../components/custom/LoadingScreen'
import GenericPagination from '../../components/generic-table/generic-pagination'
import GenericTable from '../../components/generic-table/generic-table'
import AssignGuestModal from '../../components/table/assignGuestModal'
import MergeConfirmModal from '../../components/table/mergeConfirmModal'
import SplitModal from '../../components/table/splitModal'
import TableEmptyData from '../../components/table/TableEmptyData'
import TableModal from '../../components/table/tableModal'
import TableService from '../../services/tableService'

export const tableListViewText = (text) => t(`tables.TableListView.${text}`)

export function TableListScreen() {
	const navigate = useNavigate()

	const [newModal, setNewModal] = useState(false)
	const [assignModal, setAssignModal] = useState(false)
	const [mergeModal, setMergeModal] = useState(false)
	const [splitModal, setSplitModal] = useState(false)
	const [data, setData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const [selectingMerge, setSelectingMerge] = useState(false)
	const [selectingSplit, setSelectingSplit] = useState(false)
	const [loaded, setLoaded] = useState(false)

	const [selection, setSelection] = useState([])

	useEffect(() => {
		setSelection([])
		refreshData()
	}, [])

	const refreshData = () => {
		TableService.GetAll()
			.then((res) => {
				setLoaded(true)
				setData(res?.tables)
			})
			.catch((e) => {
				setLoaded(true)
			})
	}

	const handlePositionView = () => {
		navigate('../table/tables')
	}

	const handleTableDetail = (item) => {
		navigate(`./${item.id}`)
	}

	const handleMergeTable = async () => {
		if (selectingMerge) {
			if (selection.length) {
				setMergeModal(true)
			} else {
				setSelectingMerge(false)
			}
		} else {
			setSelectingMerge(true)
			setSelection([])
		}
	}
	const confirmMergeTable = (newTableName) => {
		NotificationManager.success('Success', newTableName)
		setSelectingMerge(false)
	}

	const toggleSelection = (id) => {
		if (Array.isArray(id)) {
			setSelection(id)
		} else {
			if (selection.find((o) => o === id) === undefined) {
				setSelection(selection.concat([id]))
			} else {
				setSelection(selection.filter((o) => o !== id))
			}
		}
	}

	const handleSplitTable = () => {
		if (selectingSplit) {
			if (selection.length === 1) {
				setSplitModal(true)
			} else if (!selection.length) {
				setSelectingSplit(false)
			} else {
				NotificationManager.warning('Please choose one table', '', 2000)
			}
		} else {
			setSelectingSplit(true)
			setSelection([])
		}
	}
	const confirmSplitTable = () => {
		NotificationManager.success('Success')
		setSelectingSplit(false)
	}

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const columns = [
		{ field: 'id', label: tableListViewText('nr', 'Nr') },
		{ field: 'name', label: tableListViewText('name', 'Name') },
		{ field: 'size', label: tableListViewText('size', 'Size') },
		{
			field: 'seats',
			label: tableListViewText('nr_of_seats', 'Nr of Seats'),
		},
		{ field: 'location', label: tableListViewText('location', 'Location') },
		{ field: 'shape', label: tableListViewText('shape', 'Shape') },
		{ field: 'actions', label: 'Actions' },
	]

	const tableData = data
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		.map((row, index) => ({
			...row,
			id: index + 1,
		}))

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex justify-between items-center px-6 py-5">
								<div className="flex items-center">
									<span className="text-black1 text-xl font-medium">
										{tableListViewText('table_management', 'Table Management')}
									</span>
									<span
										className="border text-black1 px-2 py-1 rounded-lg ml-2 font-medium cursor-pointer"
										onClick={handlePositionView}
									>
										{tableListViewText('position_view', 'Position View')}
									</span>
								</div>
								<div className="flex items-center gap-4">
									<div
										className="bg-black1 flex px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setAssignModal(true)}
									>
										<span>
											{tableListViewText('assign_guests', 'Assign Guests')}
										</span>
									</div>
									<div
										className="bg-black1 flex px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={handleMergeTable}
									>
										<span>
											{tableListViewText('merge_table', 'Merge Table')}
										</span>
									</div>
									<div
										className="bg-black1 flex px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={handleSplitTable}
									>
										<span>
											{tableListViewText('split_table', 'Split a Table')}
										</span>
									</div>
									<div
										className="bg-black1 flex px-4 py-2 text-white rounded-lg cursor-pointer"
										onClick={() => setNewModal(true)}
									>
										<img src={plusIcon} alt="search" className="mr-2" />
										<span>{tableListViewText('new_table', 'New Table')}</span>
									</div>
								</div>
							</div>
							{data.length ? (
								<div className="flex flex-col overflow-auto">
									<GenericTable
										data={tableData}
										columns={columns}
										className="mt-4"
										onView={(row) => {
											if (!selectingMerge && !selectingSplit) {
												handleTableDetail(row)
											}
										}}
										onSelectRow={toggleSelection}
										selectedRows={selection}
										showCheckbox={true}
									/>
									<GenericPagination
										count={data.length}
										page={page}
										rowsPerPage={rowsPerPage}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										setPage={setPage}
										className="!p-2"
									/>
									{!data.length && (
										<div className="mt-[60px] align-middle text-gray-500">
											{tableListViewText('no_data', 'NO DATA')}
										</div>
									)}
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content="table"
										btnLabel={tableListViewText('new_table', 'New Table')}
										onAction={() => setNewModal(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
			<TableModal
				showModal={newModal}
				onClose={() => setNewModal(false)}
				onRefresh={refreshData}
			/>
			<AssignGuestModal
				showModal={assignModal}
				onClose={() => setAssignModal(false)}
				onRefresh={refreshData}
			/>
			<MergeConfirmModal
				showModal={mergeModal}
				onClose={() => setMergeModal(false)}
				onConfirm={confirmMergeTable}
				data={selection}
				refresh={refreshData}
			/>
			<SplitModal
				showModal={splitModal}
				onClose={() => setSplitModal(false)}
				onConfirm={confirmSplitTable}
				data={selection}
				refresh={refreshData}
			/>
		</div>
	)
}
