import { ContentCopyOutlined } from '@mui/icons-material'
import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import FeedbackButtons from '../../../components/feedback'
import retailService from '../../../services/retailService'
import Brands, { brandText } from '../../brands'
import StoreCustomize from './storeCustomize'
import StoreEmails from './storeEmails'
import StoreGeneral from './storeGeneral'
import StorePayments from './storePayments'
import StoreShipping from './storeShipping'
import StoreSuppliers from './storeSuppliers'

export const storeSettingsText = (text) =>
	t(`account.storeSettings.view.${text}`)

const StoreSettingsScreen = () => {
	const [data, setData] = useState({})
	const [storeEmails, setStoreEmails] = useState([])
	const [storeShippingZones, setStoreShippingZones] = useState([])
	const [storeSuppliers, setStoreSuppliers] = useState([])
	const [whiteLabelUrl, setWhiteLabelUrl] = useState('')
	const [loaded, setLoaded] = useState(true)
	const [index, setIndex] = useState(0)
	useEffect(() => {
		getPageDetails()
	}, [])

	const getPageDetails = () => {
		setLoaded(false)
		retailService
			.getStoreSettings()
			.then((res) => {
				setLoaded(true)
				setData(res.store_settings)
				setWhiteLabelUrl(res?.url)
				setStoreEmails(res.store_settings.email ?? [])
				setStoreSuppliers(res.store_settings.suppliers)
				setStoreShippingZones(res.store_settings.shipping_zones)
			})
			.catch((err) => {
				setLoaded(true)
				NotificationManager.error(
					err?.message ?? 'Failed to get store setings detail'
				)
			})
	}

	const onSubmitGeneral = (payload) => {
		setLoaded(false)
		const details = {
			currency: payload.currency,
			enable_coupon: payload.enable_coupon,
			selling_countries: payload.selling_countries,
			selling_location: payload.selling_location,
			shipping_location: payload.shipping_location,
			shipping_countries: payload.shipping_countries,
			payment_options: payload.payment_options,
			tags: payload.tags,
			neighborhood: payload.neighborhood,
			description: payload.description,
			additional: payload.additional,
			main_tag: payload.main_tag,
		}

		retailService
			.saveStoreSettings(details)
			.then((data) => {
				setLoaded(true)
				getPageDetails()
				NotificationManager.success(
					'Profile has been updated successfully',
					'Success',
					3000
				)
			})
			.catch((error) => {
				setLoaded(true)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on saveStoreSettings')
			})
	}

	const onTabClicked = (event, index) => {
		setIndex(index)
	}

	const onCopyText = ({ url, label }) => {
		if (url) {
			navigator.clipboard.writeText(url)
			NotificationManager.success(
				`${label} is copied successfully and can be shared everywhere`
			)
		}
	}

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-xl font-medium">
										{storeSettingsText('store_settings')}
									</span>
								</div>
								<div
									style={{ display: 'flex', gap: '16px', alignItems: 'center' }}
								>
									<FeedbackButtons type={storeSettingsText('store_settings')} />
								</div>
							</div>
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-2 mt-0 mb-2">
								<div className="flex flex-row items-center">
									<span className="text-black1 text-16 font-medium">
										{storeSettingsText('tailor_essentials')}
									</span>
								</div>
								{whiteLabelUrl ? (
									<div className="flex flex-row justify-start items-center px-2">
										<div className="!text-green5 font-medium text-base">
											<a
												className="text-green5 text-base"
												href={whiteLabelUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												{whiteLabelUrl}
											</a>
										</div>
										<div
											className="ml-4 cursor-pointer text-green5"
											onClick={() =>
												onCopyText({ url: whiteLabelUrl, label: 'Link' })
											}
										>
											<ContentCopyOutlined />
										</div>
									</div>
								) : null}
							</div>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs
									value={index}
									onChange={onTabClicked}
									className="pt-0 pr-4 pl-4 pb-2"
									textColor="inherit"
									TabIndicatorProps={{
										style: {
											backgroundColor: '#666A73',
										},
									}}
								>
									<Tab label={storeSettingsText('general_tab')} />
									<Tab label={storeSettingsText('suppliers_tab')} />
									<Tab label={storeSettingsText('payments_tab')} />
									<Tab label={storeSettingsText('emails_tab')} />
									<Tab label={storeSettingsText('shipping_tab')} />
									<Tab label={storeSettingsText('customize_store_tab')} />
									<Tab label={brandText('Brands')} />
								</Tabs>

								<Panel value={index} index={0}>
									<StoreGeneral data={data} onSubmitForm={onSubmitGeneral} />
								</Panel>
								<Panel value={index} index={1}>
									<StoreSuppliers
										data={storeSuppliers}
										onRefresh={getPageDetails}
									/>
								</Panel>
								<Panel value={index} index={2}>
									<StorePayments data={data} onRefresh={getPageDetails} />
								</Panel>
								<Panel value={index} index={3}>
									<StoreEmails
										recipient={data.new_order_email_recipient}
										onRefresh={getPageDetails}
									/>
								</Panel>
								<Panel value={index} index={4}>
									<StoreShipping
										data={storeShippingZones}
										onRefresh={getPageDetails}
									/>
								</Panel>
								<Panel value={index} index={5}>
									<StoreCustomize />
								</Panel>
								<Panel value={index} index={6}>
									<Brands />
								</Panel>
							</Box>
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}

const Panel = (props) => (
	<div
		hidden={props.value !== props.index}
		className="row-span-2 bg-white rounded-12 m-4 border border-gray-200"
	>
		<div>{props.children}</div>
	</div>
)

export default StoreSettingsScreen
