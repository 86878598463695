import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import { SALARY_FREQUENCYS, boxStyle } from '../../constants/types'
import StaffService from '../../services/staffService'

import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { validateEmailAddress } from '../../utils/common'
import CustomIconButton from '../common/IconButton'
import { RInputDeclare } from '../custom/RInputDeclare'

import {
	ROLE_GOLF_MANAGER,
	ROLE_GOLF_OWNER,
	ROLE_HOTEL_MANAGER,
	ROLE_HOTEL_OWNER,
	ROLE_HOTEL_Restaurant_Manager,
	ROLE_MANAGER,
	ROLE_OWNER,
	ROLE_RETAIL_MANAGER, ROLE_RETAIL_OWNER, ROLE_VACATION_RENTAL_MANAGER, ROLE_VACATION_RENTAL_OWNER
} from '../../constants'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import customerIcon from '../../assets/svgs/customer_white.svg'

export const employeeModalText = (text) =>
	t(`components.employee.EmployeeModal.${text}`)

const FREQUENCY_UNITS = [
	{ value: 'hours', label: 'Hours' },
	{ value: 'days', label: 'Days' },
	{ value: 'weeks', label: 'Weeks' },
	{ value: 'months', label: 'Months' },
	{ value: 'years', label: 'Years' },
	// Add more units as needed
]

export default function EmployeeModal({
	showModal,
	data,
	roles,
	employees,
	onClose,
}) {
	const { auth } = useSelector((state) => state)
	const [visible, setVisible] = useState(showModal)
	const [checkValid, setCheckValid] = useState(false)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [salary, setSalary] = useState(0)

	const [venue, setVenue] = useState({})
	const [role, setRole] = useState({})
	const [owner, setOwner] = useState({})
	const [manager, setManager] = useState({})
	const [customFrequencyNumber, setCustomFrequencyNumber] = useState('')
	const [customFrequencyUnit, setCustomFrequencyUnit] = useState('')

	const [salaryFrequency, setSalaryFrequency] = useState('daily')

	const [roleOptions, setRoleOptions] = useState([])
	const [ownerOptions, setOwnerOptions] = useState([])
	const [managerOptions, setManagerOptions] = useState([])
	const [venueOptions, setVenueOptions] = useState([])

	const [isCustom, setIsCustom] = useState(false)
	const [customRole, setCustomRole] = useState('')

	const loggedInUserRole = useMemo(() => {
		if (auth && auth.user && auth.user.employee) {
			return auth.user.employee.find((item) => item.role_id === 2)
		}
	}, [auth])

	const onRoleChange = (event) => {
		const selectedRoleId = event.target.value
		setRole(selectedRoleId)
		const isCustomRole =
			roleOptions.find((option) => option.value === selectedRoleId)?.label ===
			'Custom'

		setIsCustom(isCustomRole)
	}

	useEffect(() => {
		setVisible(showModal)
		if (showModal) {
			setCheckValid(false)

			const idsToRemove = [2, 5, 13, 18, 25]
			let newRoles = roles.filter((obj) => !idsToRemove.includes(obj.id))
			setRoleOptions(
				newRoles.map((role) => {
					return { value: role.id, label: role.name }
				})
			)
			setManagerOptions(
				employees
					.filter(
						(employee) =>
							employee.role_id === 1 ||
							employee.role_id === 6 ||
							employee.role_id === 9 ||
							employee.role_id === 14 ||
							employee.role_id === 19 ||
							employee.role_id === 26
					)
					.map((manager) => {
						return { value: manager.id, label: manager.name }
					})
			)
			setOwnerOptions(
				employees
					.filter(
						(employee) =>
							employee.role_id === 2 ||
							employee.role_id === 5 ||
							employee.role_id === 13 ||
							employee.role_id === 18 ||
							employee.role_id === 25
					)
					.map((owner) => {
						return { value: owner.id, label: owner.name }
					})
			)

			setName(data?.name || '')
			setEmail(data?.email || '')

			setRole(data?.role_id || 1)
			setManager(data?.manager_id || 0)
			setOwner(data?.owner_id || 0)

			setSalary(data?.salary || 0)
			setSalaryFrequency(data?.salary_frequency || 'daily')

			setCustomFrequencyUnit(data?.frequency_unit)
			setCustomFrequencyNumber(data?.frequency_number)
		}

		if (auth && auth.user && auth.user.restaurants) {
			setVenueOptions(
				auth.user.restaurants.map((item) => {
					return { value: item.id, label: item.name }
				})
			)
		}

		// eslint-disable-next-line
	}, [showModal, auth])

	const onSubmit = () => {
		if (
			!name ||
			!email ||
			!validateEmailAddress(email) ||
			// (!data?.id ? password.length < 6 : '') ||
			!salary ||
			!role ||
			!venue ||
			!salaryFrequency ||
			(isCustom && !customRole) ||
			(salaryFrequency === 'custom' &&
				(!customFrequencyNumber || !customFrequencyUnit))
			// ||
			// (role !== ROLE_MANAGER && role !== ROLE_OWNER && !manager) ||
			// (role !== ROLE_HOTEL_MANAGER && role !== ROLE_HOTEL_OWNER && !manager) ||
			// (role !== ROLE_HOTEL_Restaurant_Manager && role !== ROLE_HOTEL_OWNER && !manager) ||
			// (role !== ROLE_GOLF_MANAGER && role !== ROLE_GOLF_OWNER && !manager) ||
			// (role === ROLE_MANAGER && !owner) ||
			// (role === ROLE_HOTEL_MANAGER && !owner) ||
			// (role === ROLE_HOTEL_Restaurant_Manager && !owner) ||
			// (role === ROLE_GOLF_MANAGER && !owner)
		) {
			NotificationManager.warning('Please fill all required fields')
			setCheckValid(true)
			return
		}

		let empData = {
			id: data?.id,
			name: name,
			email: email,
			salary: salary,
			salary_frequency: salaryFrequency,
			// employee_password: password,

			...(salaryFrequency === 'custom' && {
				frequency_number: customFrequencyNumber,
				frequency_unit: customFrequencyUnit,
			}),

			...(isCustom && {
				custom_role: customRole,
			}),
		}

		if (role) {
			empData.role_id = role
		}
		if (manager) {
			empData.manager_id = manager
		}
		if (venue) {
			empData.venue_id = venue
		}
		if (owner) {
			empData.owner_id = owner
		}

		let promise = null
		if (data?.id == null) {
			promise = StaffService.Create(empData)
		} else {
			promise = StaffService.Update(empData)
		}

		promise
			.then((res) => {
				if (data?.id == null) {
					NotificationManager.success(
						'Employee created successfully',
						'Success',
						3000
					)
				} else {
					NotificationManager.success(
						'Employee updated successfully',
						'Success',
						3000
					)
				}

				onClose(false)
			})
			.catch((err) => {
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
				throw new Error('Problem on creating an employee')
			})
	}

	const getOwnerClass = (role) => {
		return (
			role === ROLE_OWNER ||
			role === ROLE_HOTEL_OWNER ||
			role === ROLE_GOLF_OWNER
		)
	}

	const getManagerClass = (role) => {
		return (
			role === ROLE_OWNER ||
			role === ROLE_MANAGER ||
			role === ROLE_HOTEL_OWNER ||
			role === ROLE_HOTEL_MANAGER ||
			role === ROLE_HOTEL_Restaurant_Manager ||
			role === ROLE_GOLF_OWNER ||
			role === ROLE_GOLF_MANAGER ||
			role === ROLE_RETAIL_OWNER ||
			role === ROLE_RETAIL_MANAGER ||
			role === ROLE_VACATION_RENTAL_OWNER ||
			role === ROLE_VACATION_RENTAL_MANAGER
		)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 500 }} className="outline-none">
					<CustomIconButton
						icon={customerIcon}
						title={
							data?.id
								? employeeModalText('edit_employee')
								: employeeModalText('new_employee')
						}
					/>
					<div className="flex flex-col p-024 overflow-auto max-h-[60vh]">
						<RInputDeclare
							className="!mb-2 !mt-0 text-base !text-black1 font-medium"
							value={employeeModalText('full_name')}
						/>
						<TextField
							required
							error={checkValid && !name}
							id="filled-required"
							label={employeeModalText('required')}
							value={name}
							size="small"
							onChange={(e) => setName(e.target.value)}
							placeholder={employeeModalText('enter_name')}
						/>

						<RInputDeclare
							className="!mt-6 !mb-2 text-base !text-black1 font-medium"
							value={employeeModalText('email')}
						/>
						<TextField
							required
							error={checkValid && !validateEmailAddress(email)}
							id="filled-required"
							size="small"
							label={employeeModalText('required')}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder={employeeModalText('enter_email')}
						/>

						<RInputDeclare
							className="!mt-6 !mb-2 text-base !text-black1 font-medium"
							value={employeeModalText('select_role')}
						/>
						<TextField
							id="outlined-select"
							error={checkValid && !role}
							select
							size="small"
							value={role}
							onChange={onRoleChange}
						>
							{roleOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						{loggedInUserRole && loggedInUserRole.role_id === ROLE_OWNER && (
							<>
								<RInputDeclare
									className="!mt-6 !mb-2 text-base !text-black1 font-medium"
									value={employeeModalText('select_venue')}
								/>
								<TextField
									id="outlined-select"
									error={checkValid && !venue}
									select
									size="small"
									value={venue}
									onChange={(value) => setVenue(value.target.value)}
								>
									{venueOptions.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</>
						)}

						{isCustom && (
							<>
								<RInputDeclare
									className="!mt-6 !mb-2 text-base !text-black1 font-medium"
									value={employeeModalText('enter_custom_role')}
								/>
								<TextField
									id="custom-role"
									size="small"
									value={customRole}
									onChange={(e) => setCustomRole(e.target.value)}
									placeholder={employeeModalText('enter_custom_role')}
								/>
							</>
						)}

						<RInputDeclare
							value={employeeModalText('select_manager')}
							className={`!mt-6 !mb-2 text-base !text-black1 font-medium ${getManagerClass(
								role
							)}`}
						/>
						<TextField
							className={getManagerClass(role)}
							id="outlined-select"
							error={checkValid && !manager}
							select
							size="small"
							value={manager}
							onChange={(event) => setManager(event.target.value)}
						>
							{managerOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare
							value={employeeModalText('select_owner')}
							visible={role !== ROLE_OWNER}
							className={`!mt-6 !mb-2 text-base !text-black1 font-medium ${getOwnerClass(
								role
							)}`}
						/>
						<TextField
							id="outlined-select"
							className={getOwnerClass(role)}
							error={checkValid && !owner}
							select
							size="small"
							value={owner}
							onChange={(event) => setOwner(event.target.value)}
							visible={role !== ROLE_OWNER}
						>
							{ownerOptions.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare
							className="!mt-6 !mb-2 text-base !text-black1 font-medium"
							value={employeeModalText('salary')}
						/>
						<TextField
							required
							error={checkValid && !salary}
							id="filled-required"
							type="number"
							size="small"
							value={salary}
							onChange={(e) => setSalary(e.target.value)}
							placeholder={employeeModalText('enter_salary')}
						/>

						<RInputDeclare
							className="!mt-6 !mb-2 text-base !text-black1 font-medium"
							value={employeeModalText('select_salary_frequency')}
						/>
						<TextField
							id="outlined-select"
							select
							size="small"
							value={salaryFrequency}
							onChange={(event) => setSalaryFrequency(event.target.value)}
						>
							{SALARY_FREQUENCYS.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						{salaryFrequency === 'custom' && (
							<>
								<RInputDeclare
									className="!mt-6 !mb-2 text-base !text-black1 font-medium"
									value={employeeModalText('custom_frequency_number')}
								/>
								<TextField
									id="custom-frequency-number"
									type="number"
									size="small"
									value={customFrequencyNumber}
									onChange={(e) => setCustomFrequencyNumber(e.target.value)}
									placeholder={employeeModalText('enter_frequency_number')}
								/>

								<RInputDeclare
									className="!mt-6 !mb-2 text-base !text-black1 font-medium"
									value={employeeModalText('custom_frequency_unit')}
								/>
								<TextField
									id="custom-frequency-unit"
									select
									size="small"
									value={customFrequencyUnit}
									onChange={(event) =>
										setCustomFrequencyUnit(event.target.value)
									}
								>
									{FREQUENCY_UNITS.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</>
						)}
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{employeeModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{data?.id
								? employeeModalText('update')
								: employeeModalText('confirm')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
