import CloseIcon from '@mui/icons-material/Close'
import { Box, CircularProgress, IconButton, Modal } from '@mui/material'
import { useFormik } from 'formik'
import { t } from 'i18next'
import React, { useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import uploadIcon from '../../../assets/svgs/upload.svg'
import { boxStyle } from '../../../constants/types'
import SalesService from '../../../services/salesService'
import { isEmpty } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import SelectPeriod from './select-period'

export const storeBulkImportText = (text) =>
	t(`account.store.storeBulkImport.${text}`)

export const periods = [
	{
		label: '1 month',
		value: '1_month',
	},
	{
		label: '3 months',
		value: '3_month',
	},
	{
		label: '6 months',
		value: '6_month',
	},
	{
		label: '12 months',
		value: '12_month',
	},
]

export const years = [
	{
		label: '2023',
		value: '2023',
	},
	{
		label: '2024',
		value: '2024',
	},
]

export const months = [
	{
		label: 'January',
		value: '1',
	},
	{
		label: 'February',
		value: '2',
	},
	{
		label: 'March',
		value: '3',
	},
	{
		label: 'April',
		value: '4',
	},
	{
		label: 'May',
		value: '5',
	},
	{
		label: 'June',
		value: '6',
	},
	{
		label: 'July',
		value: '7',
	},
	{
		label: 'August',
		value: '8',
	},
	{
		label: 'September',
		value: '9',
	},
	{
		label: 'October',
		value: '10',
	},
	{
		label: 'November',
		value: '11',
	},
	{
		label: 'December',
		value: '12',
	},
]

function StoreBulkImportModal({ showImportModal, setShowImportModal, data }) {
	const navigate = useNavigate()
	const [uploadingProducts, setUploadingProducts] = useState(false)
	const [period, setPeriod] = useState()
	const [year, setYear] = useState()
	const [month, setMonth] = useState()
	const fileInputRef = useRef(null)
	const { short_code } = useSelector((state) => state.app)

	const bulkImportUploadModal = useFormik({
		initialValues: {
			file: null,
		},
		validationSchema: yup.object().shape({
			file: yup.string().trim().required('The file field is required.'),
		}),
		onSubmit: (values) => availabilityModalSubmit(values),
		validateOnChange: false,
		validateOnBlur: true,
		validateOnMount: false,
	})

	const availabilityModalSubmit = (values) => {
		setUploadingProducts(true)
		const details = {
			file: values.file,
			period,
			start_month: month,
			year,
		}

		SalesService.bulkImportSalesIntegrations(details)
			.then(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venues',
				})
				setShowImportModal(false)
				NotificationManager.success(
					'Products imported successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(0)
				}, 3000)

				setUploadingProducts(false)
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'venues',
				})
				setUploadingProducts(false)
				toast.error('There is an error occurred with file Upload', {
					style: {
						backgroundColor: '#ff0000ee', // green color for background
						color: '#FFFFFF', // white color for text
					},
					duration: 5000,
				})
				throw new Error('There is an error occurred with file Upload')
			})
	}

	const handleFileChange = (event) =>
		bulkImportUploadModal.setFieldValue('file', event.target.files[0])

	const handleIconClick = () => fileInputRef.current.click()

	const handleCloseModel = () => setShowImportModal(false)

	return (
		<div>
			<Modal
				open={showImportModal}
				onClose={handleCloseModel}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none h-auto min-h-[8rem]">
					<div className="flex flex-row items-center justify-between gap-3 px-024 pb-016 pt-024">
						<div className="flex flex-row items-center gap-3">
							<div className="p-2 rounded-full bg-gray7">
								<div className="p-2 rounded-full bg-black1">
									<img src={uploadIcon} alt="icon" />
								</div>
							</div>
							<label className="font-semibold text-20 text-black1">
								{storeBulkImportText('import_sales')}
							</label>
						</div>
						<CloseIcon
							onClick={handleCloseModel}
							style={{ cursor: 'pointer' }}
						/>
					</div>
					<div className="gap-4 px-6">
						<div className="mt-0 text-base text-black1">
							{storeBulkImportText('import_sales_description')}
						</div>
						<div className="mt-2 mb-6 text-blue-600 text-base">
							<a
								style={{
									fontWeight: 'bold',
									textDecoration: 'underline',
									color: 'rgb(61, 30, 89)',
								}}
								href="/bulk-import-sales.csv"
								download="bulk-import-sales.csv"
							>
								{storeBulkImportText('download_csv')}
							</a>
						</div>
						<SelectPeriod
							period={period}
							periods={periods}
							setPeriod={setPeriod}
							className="pb-5"
							year={year}
							setYear={setYear}
							years={years}
							setMonth={setMonth}
							month={month}
							months={months}
						/>
					</div>
					<div className="flex flex-row justify-between gap-4 px-6">
						<input
							type="file"
							ref={fileInputRef}
							style={{ display: 'none' }}
							onChange={handleFileChange}
							accept="text"
						/>
						<div className="h-[6.5rem] w-full flex flex-row justify-between gap-4">
							<div
								className={`h-full w-full flex flex-col cursor-pointer justify-center !pt-5 !pb-3 items-center relative border border-gray-300 rounded-lg`}
								onClick={handleIconClick}
							>
								<IconButton
									className="!bg-black1 p-2"
									onClick={handleIconClick}
								>
									<img
										src={uploadIcon}
										alt="icon"
										style={{ width: '24px', height: '24px' }}
									/>
								</IconButton>
								<span className="py-2 text-base text-center text-black1">
									{bulkImportUploadModal.values.file
										? bulkImportUploadModal.values.file.name
										: storeBulkImportText('upload_csv_file')}
								</span>
							</div>
						</div>
					</div>
					<div className="gap-4 px-6">
						{!isEmpty(bulkImportUploadModal.errors.file) && (
							<div className="mt-2 text-red-600 text-12">
								{bulkImportUploadModal.errors.file}
							</div>
						)}
					</div>
					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							onClick={bulkImportUploadModal.handleSubmit}
							disabled={uploadingProducts}
							className="flex items-center justify-center h-full font-bold text-white rounded-lg bg-cyan1 grow"
						>
							{uploadingProducts ? (
								<CircularProgress size={16} />
							) : (
								storeBulkImportText('upload')
							)}
						</button>
					</div>
				</Box>
			</Modal>
			<Toaster position="top-right" />
		</div>
	)
}

export default StoreBulkImportModal
