import React from 'react';
import { useNavigate } from 'react-router-dom';

import { t } from 'i18next'
import { useSelector } from 'react-redux';
import Svg_cancel from "../../../assets/svgs/cancel.svg";
import Svg_chevron_right from '../../../assets/svgs/chevron-right.svg'
import shopifyIcon from '../../../assets/svgs/shopify.svg'

export const shopifyBlockText = (text) =>
	t(`account.storeIntegrations.shopifyBlock.${text}`)

export default function ShopifyBlock() {
    const navigate = useNavigate();
    const { short_code } = useSelector(state => state.app);

    const onAction = () => {
        navigate(`/${short_code}/admin/settings/store-integrations/shopify`);
    };

    return (
			<div className="flex flex-col">
				<div className="flex flex-row items-center p-0 mb-024">
					<label className="font-medium text-lg text-black1">
						{shopifyBlockText('shopify_integration')}
					</label>
				</div>
				<div className="flex flex-col items-start p-0 w-full h-full bg-white rounded-12">
					<div className="flex flex-col items-start flex-1 w-full shadow-lg_2 bg-white rounded-12 px-024 pt-024 pb-016">
						<div className="flex flex-col justify-center items-start p-0">
							<img src={shopifyIcon} className="w-20 h-20 mb-4" alt="" />
							<div className="flex flex-row items-center p-0 h-6 mt-2">
								<img className="pr-012" src={Svg_cancel} alt="" />
								<label className="text-14 pl-08 text-red1">
									{shopifyBlockText('disconnected')}
								</label>
							</div>
							<label className="font-medium text-base mt-2">
								{shopifyBlockText('expand_online_presence')}
							</label>
						</div>
					</div>
					<div
						className="flex flex-row justify-between px-024 py-016 w-full h-[52px] cursor-pointer"
						onClick={() => onAction()}
					>
						<label className="text-black1 font-medium text-base">
							{shopifyBlockText('view_detail')}
						</label>
						<img src={Svg_chevron_right} alt="" />
					</div>
				</div>
			</div>
		)
}
