import apiFactory from "../utils/apiFactory";
import { API_PRIORITIZE_GUEST, API_UPDATE_WAIT_TIME, API_WAITLIST, API_WAITLIST_GUEST } from "../constants/API";

export default class WaitlistService {
  static GetAll = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_WAITLIST}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static PrioritizeGuest = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_PRIORITIZE_GUEST}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static GetGuestHistory = async (index) => {
    let api = {
      content_type: "application/json",
      url: `${API_WAITLIST_GUEST}/${index}/history`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static NewWaitlist = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_WAITLIST}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static UpdateWaitlist = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_WAITLIST}`,
      method: "PUT",
    };
    return apiFactory(api, payload);
  };
  static UpdateWaitTime = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_UPDATE_WAIT_TIME}/${payload.id}`,
      method: "PUT",
    };
    return apiFactory(api, payload);
  };
}
