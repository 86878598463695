import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { NotificationManager } from 'react-notifications'
import icon from '../../assets/svgs/table_white.svg'
import TableService from '../../services/tableService.js'
import CustomFormInput from '../custom/CustomInput/custom-form-input.jsx'
import CustomFormSelect from '../custom/CustomSelect/custom-form-select.jsx'
import GenericModal from '../generic-modal/index.jsx'

const getTranslation = (text) => t(`components.table.moveTableModal.${text}`)

export default function MoveTableModal({ showModal, onClose, data }) {
	const [visible, setVisible] = useState(showModal)
	const [checkValid, setCheckValid] = useState(false)

	const [to, setTo] = useState(0)
	const [locationOptions, setLocationOptions] = useState([])

	useEffect(() => {
		setVisible(showModal)
		if (showModal) {
			setCheckValid(false)
			setTo(data.id)

			TableService.GetLocations().then((res) => {
				let newOptions = res.map((item) => {
					return {
						value: item.id,
						label: item.name,
					}
				})

				if (data.locationForMove) {
					let index = newOptions.findIndex(
						(item) => item.value == data.locationForMove.id
					)
					if (index != -1) {
						newOptions.splice(index, 1)
					}
				}
				setLocationOptions(newOptions)
			})
		}
		// eslint-disable-next-line
	}, [showModal])

	const onSubmit = () => {
		if (to) {
			let payload = {
				table_id: data.id,
				new_location: to,
			}
			TableService.MoveTable(payload)
				.then((res) => {
					NotificationManager.success('Table moved successfully')
					onClose(true)
				})
				.catch((e) => {
					NotificationManager.error('Failed')
				})
		} else {
			setCheckValid(true)
		}
	}

	const children = (
		<div className="flex flex-col overflow-auto max-h-[70vh]">
			<CustomFormInput
				value={data.location ?? getTranslation('noLocation')}
				disabled={true}
				title={getTranslation('moveTableFrom')}
			/>

			<CustomFormSelect
				setOptionValue={setTo}
				title={getTranslation('to')}
				required={true}
				value={to}
				options={locationOptions}
			/>
		</div>
	)

	return (
		<GenericModal
			{...{ onClose, onSubmit, children, icon }}
			open={visible}
			saveText={getTranslation('confirm')}
			title={`${getTranslation('moveTable')} ${data.number}`}
		/>
	)
}
