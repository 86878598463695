import {
	Box,
	Checkbox,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { productText } from '.'
import creditIcon from '../../../assets/svgs/credit-card.svg'
import { boxStyle } from '../../../constants/types'
import { createUUID } from '../../../utils/common'

export default function WooCommerceModal({ showModal, onClose }) {
	const [visible, setVisible] = useState(false)

	const [productList, setProductList] = useState([
		{
			id: 1,
			vb_p_name: 'VB Product1',
			woocommerce_p_name: 'Woocommerce Product1',
			synced: false,
			unified_id: '#1',
		},
		{
			id: 2,
			vb_p_name: 'VB Product2',
			woocommerce_p_name: 'Woocommerce Product2',
			synced: true,
			unified_id: '#2',
		},
		{
			id: 3,
			vb_p_name: 'VB Product3',
			woocommerce_p_name: 'Woocommerce Product3',
			synced: false,
			unified_id: '#3',
		},
		{
			id: 4,
			vb_p_name: 'VB Product4',
			woocommerce_p_name: 'Woocommerce Product4',
			synced: true,
			unified_id: '#4',
		},
		{
			id: 5,
			vb_p_name: 'VB Product5',
			woocommerce_p_name: 'Woocommerce Product5',
			synced: true,
			unified_id: '#5',
		},
	])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const handleChange = (product_id) => {
		setProductList((v) =>
			v.map((item) => {
				if (item.id === product_id) {
					return { ...item, synced: !item.synced }
				} else {
					return item
				}
			})
		)
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={creditIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{productText('products_synced_woocommerce')}
						</label>
					</div>
					<div className="flex flex-col pt-0 px-024">
						{productList.length && (
							<>
								<div className="flex flex-col overflow-auto max-h-[70vh] border">
									<TableContainer component={Paper}>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead className="bg-gray7">
												<TableRow key={createUUID()}>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{productText('vb_p_name')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{productText('woocommerce_p_name')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{productText('synced')}
													</TableCell>
													<TableCell
														className="!text-gray5 !text-left !font-medium !text-base"
														align="right"
													>
														{productText('unified_id')}
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{productList
													.slice(
														page * rowsPerPage,
														page * rowsPerPage + rowsPerPage
													)
													.map((row, index) => (
														<TableRow key={index}>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.vb_p_name}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.woocommerce_p_name}
															</TableCell>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																<Checkbox
																	checked={row.synced}
																	onChange={() => handleChange(row.id)}
																/>
															</TableCell>
															<TableCell
																align="left"
																className="!py-3.5 !text-gray5 !text-left !text-base"
															>
																{row.unified_id}
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
								<TablePagination
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={productList.length}
									rowsPerPage={rowsPerPage}
									page={page}
									className="!p-2"
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</>
						)}
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1 w-20"
							onClick={onClose}
						>
							{productText('close')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
