import {
	Card,
	CardContent,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '../../../../assets/svgs/info_black.svg'
import LoadingScreen from '../../../../components/custom/LoadingScreen'
import accommodationService from '../../../../services/accommodationService'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'

export const facilitiesFormText = (text) =>
	t(`accommodation.rental_unit.show.facilitiesForm.${text}`)

const FacilitiesForm = ({ data, rentalUnitId, facilities }) => {
	const [loading, setLoading] = useState(false)
	const [selectedFacilities, setSelectedFacilities] = useState([])
	const [updating, setUpdating] = useState(false)

	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	useEffect(() => {
		if (data?.facilities) {
			setSelectedFacilities(data?.facilities.map((item) => item?.id) ?? [])
		}
	}, [facilities])

	const onHandleFacilityChecked = (value, categoryId) => {
		// bind for checked
		let tmp = Object.assign([], selectedFacilities)
		if (value === true) {
			tmp.push(categoryId)
			setSelectedFacilities(tmp)
		} else {
			var index = tmp.indexOf(categoryId)
			if (index !== -1) {
				tmp.splice(index, 1)
				setSelectedFacilities(tmp)
			}
		}
	}

	const onSubmit = (values) => {
		setUpdating(true)
		const details = {
			type: 'facilities',
			facilities: selectedFacilities,
		}
		accommodationService
			.UpdateReantalUnitFacilities(details, rentalUnitId)
			.then((data) => {
				identifyVenue(short_code)
				trackEvent('GetRentalUnits', {
					action_category: 'retrieve',
					action_outcome: 'success',
					interaction_element: 'page open',
				})
				setUpdating(false)
				NotificationManager.success(
					'Facilities has been updated successfully',
					'Success',
					3000
				)
				setTimeout(() => {
					navigate(`/${short_code}/admin/rental/unit/${rentalUnitId}?tab=2`)
					navigate(0)
				}, 1000)
			})
			.catch((error) => {
				identifyVenue(short_code)
				trackEvent('GetRentalUnits', {
					action_category: 'retrieve',
					action_outcome: 'fail',
					interaction_element: 'page open',
				})
				setUpdating(false)
				NotificationManager.error(
					error.message || 'Something went wrong!',
					'Error',
					3000
				)
				throw new Error('Problem on UpdateReantalUnitFacilities')
			})
	}

	if (loading) {
		return <LoadingScreen />
	}

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-6 pt-2 gap-10">
					<div className="relative started-form">
						<div>
							<p className="text-base text-gray5 my-4">
								<span className="text-black1 text-20 font-bold">
									{facilitiesFormText('amenities_title')}
								</span>
							</p>
						</div>
						<div className="flex flex-row gap-12">
							<div className="w-[60%]">
								{Object.entries(facilities).map(
									([categoryName, items], index) => (
										<div
											key={index}
											className={`p-[22px] ${
												index === Object.entries(facilities).length - 1
													? 'rounded-b-[10px]'
													: ''
											} border-[#E0E0E0] border-[1px]`}
										>
											<p className="text-black1 text-18 font-semibold leading-[35px]">
												{categoryName}
											</p>
											<FormGroup>
												{items.map((item) => {
													return (
														<FormControlLabel
															key={item.name}
															control={
																<Checkbox
																	onChange={(e) =>
																		onHandleFacilityChecked(
																			e.target.checked,
																			item.id
																		)
																	}
																	checked={selectedFacilities.includes(item.id)}
																	value={selectedFacilities.includes(item.id)}
																/>
															}
															label={item.name}
														/>
													)
												})}
											</FormGroup>
										</div>
									)
								)}
								<div className="flex flex-row justify-between mt-8 h-[48px] gap-8">
									<button
										type="submit"
										className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
										onClick={onSubmit}
									>
										{updating ? (
											<CircularProgress size={16} />
										) : (
											facilitiesFormText('update')
										)}
									</button>
								</div>
							</div>
							<div className="w-[40%] flex flex-col gap-12">
								<div className="relative rounded-[10px] border-[#E0E0E0] border-[1px] pl-[72px] pr-[22px] py-[22px]">
									<div className="absolute left-[22px] top-[22px]">
										<img className="w-8 h-8" src={InfoIcon} alt="icon" />
									</div>
									<h4 className="text-black1 text-18 font-bold leading-7">
										{facilitiesFormText('highlighting_facilities')}
									</h4>
									<p className="text-16 text-[#666A73] font-normal leading-7">
										{facilitiesFormText('highlighting_facilities_description')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}

export default FacilitiesForm;
