import apiFactory from "../utils/apiFactory";
import { API_LOGIN, API_LOGOUT, API_USER_PROFILE } from "../constants/API";

export default class LoginService {
  static login = async (email, password) => {
    let api = {
      content_type: "application/json",
      url: `${API_LOGIN}`,
      method: "POST",
    };
    return apiFactory(api, { email, password });
  };

  static logout = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_LOGOUT}`,
      method: "POST",
    };
    return apiFactory(api);
  };

  static getUserProfile = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_USER_PROFILE}/profile`,
      method: "GET",
    };
    return apiFactory(api);
  };


  static requestChangeEmail = (payload) => {
    return new Promise(async (resolve, reject) => {
      apiFactory({ content_type: "application/json", url: `${API_USER_PROFILE}/request-change-email`, method: 'post' }, payload)
        .then(async (data) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
    });
  };

  static verifyChangeEmail = (payload) => {
    return new Promise(async (resolve, reject) => {
      apiFactory({ content_type: "application/json", url: `${API_USER_PROFILE}/verify-change-email`, method: 'post' }, payload)
        .then(async (data) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
    });
  };

  static reqestChangePass = (payload) => {
    return new Promise(async (resolve, reject) => {
      apiFactory({ content_type: "application/json", url: `${API_USER_PROFILE}/change-pass`, method: 'post' }, payload)
        .then(async (data) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
    });
  };

}
