import React, { useEffect, useMemo, useState } from 'react';
import { arrowDown, filterIcon } from '../../views/guests/guests/icons';

const SelectFilter = ({
	toggleDropdown,
	dropdownOpen = false,
	items = [],
	selectedItem,
	defaultIcon, 
	onChange,
	className = 'w-full',
	showArrowDown = true
}) => {
	const [isOpen, setIsOpen] = useState(dropdownOpen);

	useEffect(() => {
		setIsOpen(dropdownOpen);
	}, [dropdownOpen])
	
	const handleToggleDropdown = ()=> {
		setIsOpen(!isOpen);
		toggleDropdown?.();
	}

	const isDropDownOpen = useMemo(()=>isOpen, [isOpen])

	return (
		<div
			className={`col-span-2 relative select gap-2 h-10 ${className}`}
			onClick={handleToggleDropdown}
		>
			{defaultIcon ? <span>{defaultIcon}</span> : <span>{selectedItem?.icon ?? filterIcon}</span>}
			<span className="cell-text w-fit text-[15px] whitespace-nowrap">{selectedItem.label != null ? selectedItem.label : selectedItem}</span>
			{showArrowDown && <span>{arrowDown}</span>}
			{isDropDownOpen && (
				<div className="absolute top-10 left-0 w-full bg-white z-[9999] shadow-lg border max-h-[300px] overflow-auto !items-center ">
					{items.map((item, index) => (
						<div
							key={index}
							className="dropdown-item cell-text flex gap-2 whitespace-nowrap !justify-start !items-center"
							onClick={() => onChange(item)}
						>
							{item?.icon} {item.label != null ? item.label : item}
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default SelectFilter
