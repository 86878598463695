import {
  Card,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from 'react'

import { t } from 'i18next'
import LoadingScreen from '../../../components/custom/LoadingScreen'

export const customersText = (text) => t(`customers.ev_customers.${text}`)

const EvCustomers = () => {
	const [loading, setLoading] = useState(false)

	useEffect(() => {}, [])

	if (loading) {
		return <LoadingScreen />
	}
	return (
		<div className="flex flex-col h-full">
			<div className="flex flex-col h-full p-6">
				<Card>
					<CardContent className="!p-0">
						<div className="flex flex-row justify-between items-center pl-4 pt-4 mb-4 pr-4">
							<div className="flex flex-row items-center">
								<span className="text-black1 text-xl pt-2 font-medium">
									{customersText('all_customers')}
								</span>
							</div>
							<div className="h-8 !w-40"></div>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default EvCustomers;
