import React from 'react'

const SidebarLink = ({
	item,
	onBarClick,
	selBar,
	visibleMenu,
	isVisionTrack,
	sideBarLinks = [],
	selMenu,
	onMenuClick,
}) => {
	return (
		<>
			<div
				onClick={() => onBarClick(item)}
				key={item.key}
				className={`${
					selBar === item.key
						? 'menu_item cursor-pointer px-4 py-3 my-2 border border-y-0 border-r-0 border-gray6 text-white'
						: 'menu_item cursor-pointer px-4 py-2 my-2 rounded-[5px] text-[#E1E1E1]'
				} ${isVisionTrack ? 'mx-3' : 'mx-4'}`}
			>
				<div className=" text-16 font-bold flex gap-2">
					{selBar === item.key ? (
						<img
							src={item.imgActive}
							alt="menu"
							className="min-w-[24px] w-6 h-6"
						/>
					) : (
						<img src={item.img} alt="menu" className="min-w-[24px] w-6 h-6" />
					)}

					{visibleMenu && !isVisionTrack && item.text}
				</div>
			</div>
			<div className="px-4 bg-[#4F2F6B] w-full">
				{selBar === item.key &&
					sideBarLinks
						.find((o) => o.key === selBar)
						?.menus.map((subItem) => {
							return (
								<div
									className={`menu_item cursor-pointer px-4 py-1 my-2  text-14 font-normal flex gap-2 ${
										selMenu === subItem.key ? 'text-white' : 'text-[#E1E1E1]'
									}`}
									key={subItem.key}
									onClick={() => onMenuClick(subItem)}
								>
									{selMenu === subItem.key ? (
										<img
											src={subItem.imgActive}
											alt="menu"
											className="min-w-[24px] w-6 h-6"
										/>
									) : (
										<img
											src={subItem.img}
											alt="menu"
											className="min-w-[24px] w-6 h-6"
										/>
									)}

									{visibleMenu && !isVisionTrack && subItem.text}
								</div>
							)
						})}
			</div>
		</>
	)
}

export default SidebarLink
